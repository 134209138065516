import { createFeatureSelector, createSelector } from '@ngrx/store';

import { OrganizationSetupState } from './organization-setup.state';

export const getOrganizationSetupState = createFeatureSelector<OrganizationSetupState>(
  'organizationSetup'
);

export const selectOrganizationSetup = createSelector(
  getOrganizationSetupState,
  (state) => state.organizationSetup
);
