import { BehaviorSubject, Observable } from 'rxjs';

import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { translate } from '@jsverse/transloco';

import {
  LoadingDialogComponent,
  LoadingDialogData,
} from '@arrivage-components/loading-dialog/loading-dialog.component';
import { LoadingFeedback } from '@arrivage-components/loading-dialog/loading-feedback.model';

@Injectable({
  providedIn: 'root',
})
export class MainPageLoadService {
  private isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  constructor(public dialog: MatDialog) {}

  /**
   * Start loading
   * @param message
   * @param progress
   * @returns Always returns a MatDialogRef when a message is provided
   */
  start(
    message?: LoadingFeedback,
    progress?: { progressValue: number; totalValue: number }
  ): void | MatDialogRef<LoadingDialogComponent, any> {
    this.isLoading$.next(true);
    if (message) {
      const loadingDialog = this.dialog.open<
        LoadingDialogComponent,
        LoadingDialogData
      >(LoadingDialogComponent, {
        data: {
          message: translate('components.loading-dialog-feedback.' + message),
          progress: progress
            ? {
                progressValue: progress.progressValue,
                totalValue: progress.totalValue,
              }
            : null,
        },
        width: '200px',
      });
      loadingDialog.disableClose = true;
      return loadingDialog;
    }
  }

  close(matDialogRef: MatDialogRef<LoadingDialogComponent, any>) {
    this.isLoading$.next(false);
    matDialogRef.close();
  }

  end() {
    this.isLoading$.next(false);
    this.dialog.closeAll();
  }

  isLoading(): Observable<boolean> {
    return this.isLoading$.asObservable();
  }
}
