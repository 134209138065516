import _ from 'lodash';

import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as DeliverySelectors from '@arrivage-distribution/vendor/store/vendor-delivery.selectors';
import * as OfferSelectors from '@arrivage-offers/vendor/store/offers.selectors';
import { PurchaseOrderTotalPipe } from '@arrivage-pipes/purchase-order-total-pipe/purchase-order-total.pipe';
import { PurchaseOrdersUtils } from '@arrivage-purchase-orders/common/util/purchase-orders.utils';
import { selectCurrentUpdatedRelationshipInfo } from '@arrivage-relationship/common/store/relationship-infos.selectors';
import { selectCurrentRelationId } from '@arrivage-relationship/common/store/relationship.selectors';
import { State as RootState } from '@arrivage-store/state';
import {
  Delivery,
  Money,
  Offer,
  PurchaseOrder,
  PurchaseOrderStatus,
  RelationshipInfo,
  WithId,
} from '@arrivage/model/dist/src/model';

import { PurchaseReportLine } from '../../../purchase-report/common/model/purchase-report.model';
import {
  PurchaseOrderCreationCustomerInfo,
  PurchaseOrderCreationStoreInfo,
  PurchaseOrderDashboardMetrics,
  PurchaseOrderEditionStoreInfo,
} from '../../common/model/purchase-orders.model';
import {
  PurchaseOrdersSelectors,
  createSelectors as createCommonPurchaseOrderSelectors,
} from '../../common/store/base-purchase-orders.selectors';
import { VendorPurchaseOrdersState } from './vendor-purchase-orders.state';

export const getPurchaseOrderVendorState =
  createFeatureSelector<VendorPurchaseOrdersState>('vendorPurchaseOrders');

export const VendorPurchaseOrdersSelectors: PurchaseOrdersSelectors =
  createCommonPurchaseOrderSelectors(getPurchaseOrderVendorState);

const selectSalesReportFromPurchaseOrderCallback = (
  purchaseOrders: (PurchaseOrder & WithId)[]
) => {
  if (purchaseOrders) {
    const salesReportLines = purchaseOrders
      .filter((purchaseOrder) =>
        PurchaseOrdersUtils.isDeliveredAndConfirmedOrCompleted(purchaseOrder)
      )
      .map((purchaseOrder) => {
        return purchaseOrder.orderItems.map((orderItem) => {
          return {
            purchaseOrder: purchaseOrder,
            orderItem: orderItem,
          } as PurchaseReportLine;
        });
      });
    return _.flatMap(salesReportLines);
  }
};

export const selectSalesReportFromAllPurchaseOrderBySentDateRange =
  createSelector<RootState, [(PurchaseOrder & WithId)[]], PurchaseReportLine[]>(
    VendorPurchaseOrdersSelectors.selectAllPurchaseOrdersBySentDateRange,
    selectSalesReportFromPurchaseOrderCallback
  );

export const selectSalesReportFromAllPurchaseOrderByDeliveryOrPickupDateRange =
  createSelector<RootState, [(PurchaseOrder & WithId)[]], PurchaseReportLine[]>(
    VendorPurchaseOrdersSelectors.selectAllPurchaseOrdersByDeliveryOrPickupDateRange,
    selectSalesReportFromPurchaseOrderCallback
  );

export const selectOfferFromPurchaseOrder = createSelector<
  RootState,
  [PurchaseOrder & WithId, Dictionary<Offer & WithId>],
  Offer & WithId
>(
  VendorPurchaseOrdersSelectors.selectActiveItem,
  OfferSelectors.selectEntities,
  (po, o) => {
    if (po && o) {
      return o[po.offerId];
    }
  }
);

export const getCreatePurchaseOrderInfo = createSelector<
  RootState,
  [Offer & WithId, (Delivery & WithId)[]],
  PurchaseOrderCreationStoreInfo
>(
  OfferSelectors.selectActiveItem,
  DeliverySelectors.selectAll,
  (offer, deliveries) => {
    if (offer && deliveries) {
      const creationInfo: PurchaseOrderCreationStoreInfo = {
        offer: offer,
        deliveries: deliveries,
      };
      return creationInfo;
    }
  }
);

export const selectCreatePurchaseOrderInfoForRelationship = createSelector<
  RootState,
  [PurchaseOrderCreationStoreInfo, RelationshipInfo & WithId],
  PurchaseOrderCreationStoreInfo
>(
  getCreatePurchaseOrderInfo,
  selectCurrentUpdatedRelationshipInfo,
  (purchaseOrderInfo, relationshipInfo) => {
    if (purchaseOrderInfo && relationshipInfo) {
      const creationInfo: PurchaseOrderCreationStoreInfo = {
        ...purchaseOrderInfo,
        deliveries: [...purchaseOrderInfo.deliveries],
        assignedDelivery:
          purchaseOrderInfo.deliveries.find(
            (delivery) => delivery.id === relationshipInfo?.deliveryId
          ) || null,
      };
      return creationInfo;
    }
  }
);

export const selectCustomerInfo = createSelector<
  RootState,
  [RelationshipInfo & WithId, string],
  PurchaseOrderCreationCustomerInfo
>(
  selectCurrentUpdatedRelationshipInfo,
  selectCurrentRelationId,
  (relationshipInfo, relationshipId) => {
    if (relationshipInfo && relationshipId) {
      return {
        customer: {
          ...relationshipInfo,
          organizationId: relationshipInfo.organizationId,
        },
        relationshipId: relationshipId,
      };
    }
  }
);

export const getEditPurchaseOrderInfo = createSelector<
  RootState,
  [PurchaseOrder & WithId, (Delivery & WithId)[]],
  PurchaseOrderEditionStoreInfo
>(
  VendorPurchaseOrdersSelectors.selectActiveItem,
  DeliverySelectors.selectAll,
  (po, deliveries) => {
    if (po && deliveries) {
      return {
        purchaseOrder: po,
        currentDelivery: deliveries.find((x) => x.id === po.delivery?.id),
      };
    }
  }
);

export const getPurchaseOrderDashboardMetrics = createSelector<
  RootState,
  [(PurchaseOrder & WithId)[]],
  PurchaseOrderDashboardMetrics
>(
  VendorPurchaseOrdersSelectors.selectSubmittedOrWaitingForApprovalPurchaseOrders,
  (purchaseOrders) => {
    const purchaseOrdersToConfirm = purchaseOrders.filter(
      (po) =>
        po.status === PurchaseOrderStatus.SUBMITTED ||
        po.status === PurchaseOrderStatus.MODIFIEDBYCUSTOMER
    );
    const purchaseOrdersWaitingForApproval = purchaseOrders.filter(
      (po) => po.status === PurchaseOrderStatus.MODIFIEDBYVENDOR
    );
    return {
      nbPurchaseOrdersToConfirm: {
        nb: purchaseOrdersToConfirm.length,
        total: Money.sum(
          purchaseOrdersToConfirm.map((po) =>
            new PurchaseOrderTotalPipe().transform(po)
          )
        ),
      },
      nbPurchaseOrdersWaitingForCustomerApproval: {
        nb: purchaseOrdersWaitingForApproval.length,
        total: Money.sum(
          purchaseOrdersWaitingForApproval.map((po) =>
            new PurchaseOrderTotalPipe().transform(po)
          )
        ),
      },
    };
  }
);

export const selectPurchaseOrderByRelationshipFromPurchaseOrdersBySentDateRange =
  createSelector<
    RootState,
    [(PurchaseOrder & WithId)[]],
    Dictionary<(PurchaseOrder & WithId)[]>
  >(
    VendorPurchaseOrdersSelectors.selectLoadedBySentDateRange,
    (purchaseOrders) => _.groupBy(purchaseOrders, 'relationshipId')
  );

export const selectPurchaseOrderByRelationshipFromPurchaseOrdersByDeliveryOrPickupDateRange =
  createSelector<
    RootState,
    [(PurchaseOrder & WithId)[]],
    Dictionary<(PurchaseOrder & WithId)[]>
  >(
    VendorPurchaseOrdersSelectors.selectLoadedByDeliveryOrPickupDateRange,
    (purchaseOrders) => _.groupBy(purchaseOrders, 'relationshipId')
  );
