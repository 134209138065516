import _ from 'lodash';

import { createFeatureSelector } from '@ngrx/store';

import { createSelectors } from '@arrivage-store/generators';
import { ContactRequest } from '@arrivage/model/dist/src/model';

import { adapter } from './received-contact-requests.reducer';
import {
  ReceivedContactRequestState,
  State,
} from './received-contact-requests.state';

export const getContactRequestState = createFeatureSelector<ReceivedContactRequestState>(
  'receivedContactRequests'
);

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
  connecting,
  connected,
  connectState,
  queryFailure,
  addFailure,
  setFailure,
  updateFailure,
  removeFailure,
  getById,
  selectActiveItem,
  isLoadingActiveItem,
  selectActiveItemState,
} = createSelectors<ContactRequest, State>(adapter, getContactRequestState);
