import { Link } from 'src/app/util/links.model';

import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-navigation-link',
  templateUrl: './navigation-link.component.html',
  styleUrls: ['./navigation-link.component.scss'],
})
export class NavigationLinkComponent {
  @Input()
  isTopLink: boolean;

  @Input()
  linkDefinition: Link;

  @Input()
  activeOnExactMatch = false;

  @Input()
  badgeText: string;

  @Input()
  indicator: boolean;

  @Input()
  isExpanded: boolean;

  @Output()
  click = new EventEmitter<void>();

  constructor() {}

  onClick() {
    this.click.emit();
  }
}
