import { Injectable } from '@angular/core';

import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { SnackbarService } from '@arrivage-snackbar/snackbar.service';
import { EntityFeedback } from '@arrivage-store/feedback/feedback-params.model';
import { createBaseEffects } from '@arrivage-store/generators';

import { ProducerService } from '../services/producer.service';
import * as producersActions from './producers.actions';
import * as producersSelectors from './producers.selectors';
import { ProducersState, State } from './producers.state';

interface ProducerFeedback extends EntityFeedback {
  remove_producers: string;
  send_producers_offers_and_updated_emails: string;
  send_producer_sales_report: string;
}

export const ProducerFeedback: ProducerFeedback = {
  add: 'add_producer',
  update: 'update_producer',
  get_active_item: 'get_active_producer',
  query: 'query_all_producers',
  remove: 'remove_producer',
  remove_producers: 'remove_producers',
  send_producers_offers_and_updated_emails:
    'send_producers_offers_and_updated_emails',
  send_producer_sales_report: 'send_producer_sales_report',
};

@Injectable()
export class ProducersEffects {
  query$;
  add$;
  update$;
  remove$;
  getActiveItem$;
  displayQueryFailure$;
  displayGetActiveItemFailure$;

  constructor(
    private actions$: Actions,
    private store: Store<State & ProducersState>,
    private producerService: ProducerService,
    snackbarService: SnackbarService
  ) {
    ({
      query: this.query$,
      add: this.add$,
      update: this.update$,
      remove: this.remove$,
      getActiveItem: this.getActiveItem$,
      displayQueryFailure: this.displayQueryFailure$,
      displayGetActiveItemFailure: this.displayGetActiveItemFailure$,
    } = createBaseEffects(
      this.actions$,
      this.store,
      producersActions,
      producersSelectors,
      this.producerService,
      ProducerFeedback,
      snackbarService
    ));
  }
}
