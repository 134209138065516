import { from, Observable } from 'rxjs';

import { Injectable } from '@angular/core';
import {
  collection,
  collectionData,
  deleteDoc,
  doc,
  docSnapshots,
  Firestore,
  query,
  setDoc,
} from '@angular/fire/firestore';

import { map } from 'rxjs/operators';

import {
  OrganizationAuthorizations,
  WithId,
} from '@arrivage/model/dist/src/model/';
import { CollectionNames } from '@arrivage/model/dist/src/utils';

@Injectable({
  providedIn: 'root',
})
export class OrganizationAuthorizationsService {
  public static readonly COLLECTION =
    CollectionNames.ORGANIZATION_AUTHORIZATIONS_COLLECTION;

  constructor(private firestore: Firestore) {}

  getAuthorizations(
    organizationId: string
  ): Observable<OrganizationAuthorizations> {
    return docSnapshots(
      doc(
        this.firestore,
        OrganizationAuthorizationsService.COLLECTION,
        organizationId
      )
    ).pipe(
      map((d) => {
        if (d.exists()) {
          const data = d.data();
          return { ...data } as OrganizationAuthorizations;
        } else {
          return null;
        }
      })
    );
  }

  listAllOrganizationAuthorizations(): Observable<
    (OrganizationAuthorizations & WithId)[]
  > {
    return from(
      collectionData(
        query(
          collection(
            this.firestore,
            OrganizationAuthorizationsService.COLLECTION
          )
        ),
        { idField: 'id' }
      )
    ).pipe(
      map((d) =>
        d.map((data) => {
          return data as OrganizationAuthorizations & WithId;
        })
      )
    );
  }

  approveOrganization(organizationId: string): Promise<void> {
    return setDoc(
      doc(
        this.firestore,
        OrganizationAuthorizationsService.COLLECTION,
        organizationId
      ),
      { approved: true }
    );
  }

  revokeOrganization(organizationId: string): Promise<void> {
    return deleteDoc(
      doc(
        this.firestore,
        OrganizationAuthorizationsService.COLLECTION,
        organizationId
      )
    );
  }
}
