import { Observable } from 'rxjs';

import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';

import * as RelationshipInfoActions from '@arrivage-relationship/common/store/relationship-infos.actions';
import {
  selectAll,
  selectAllCustomersAndHubs,
  selectAllCustomersAndHubsEmails,
  selectAllCustomersAndHubsLabels,
  selectAllHubs,
  selectAllPaymentTermsByRelationshipId,
  selectAllProviders,
  selectAllProvidersLabels,
  selectAllRelationshipInfosByOrganizationId,
  selectAllRelationshipInfosByRelationshipId,
  selectAllUpdatedCustomersAndHubs,
  selectAllUpdatedCustomersAndHubsEmails,
  selectAllUpdatedCustomersAndHubsLabels,
  selectAllUpdatedHubs,
  selectAllUpdatedPaymentTermsByRelationshipId,
  selectAllUpdatedProviders,
  selectAllUpdatedProvidersLabels,
  selectAllUpdatedRelationshipInfos,
  selectAllUpdatedRelationshipInfosByOrganizationId,
  selectAllUpdatedRelationshipInfosByRelationshipId,
  selectCurrentRelationshipInfo,
  selectCurrentUpdatedRelationshipInfo,
} from '@arrivage-relationship/common/store/relationship-infos.selectors';
import * as RelationshipActions from '@arrivage-relationship/common/store/relationship.actions';
import * as RelationshipSelectors from '@arrivage-relationship/common/store/relationship.selectors';
import { EntitiesFacade } from '@arrivage-store/api/entities.facade';
import { State } from '@arrivage-store/state';
import {
  AddCustomerToRelationshipRequestData,
  CreateCustomerProviderRelationshipRequestData,
  CreateHubProducerRelationshipRequestData,
} from '@arrivage/model/dist/src/cloud-functions-api';
import {
  Relationship,
  RelationshipInfo,
  WithId,
} from '@arrivage/model/dist/src/model';

import { PaymentTerms } from '../model/relationship.model';
import { getById } from '../store/relationship.selectors';
import { RelationshipsApiService } from './relationship-api.service';

@Injectable({
  providedIn: 'root',
})
export class RelationshipFacade extends EntitiesFacade<Relationship, State> {
  constructor(
    store: Store<State>,
    private relationshipApi: RelationshipsApiService
  ) {
    super(store, RelationshipActions, RelationshipSelectors);
  }

  loadRelationships() {
    this.store.dispatch(RelationshipActions.query());
    this.store.dispatch(RelationshipInfoActions.query());
  }

  getRelationById(relationId: string): Observable<Relationship & WithId> {
    return this.store.select(getById(relationId));
  }

  getAllRelationshipInfos(
    updated: boolean = true
  ): Observable<(RelationshipInfo & WithId)[]> {
    return updated
      ? this.store.select(selectAllUpdatedRelationshipInfos)
      : this.store.select(selectAll);
  }

  getCurrentRelationshipInfo(
    updated: boolean = true
  ): Observable<RelationshipInfo & WithId> {
    return updated
      ? this.store.select(selectCurrentUpdatedRelationshipInfo)
      : this.store.select(selectCurrentRelationshipInfo);
  }

  getAllProviders(
    updated: boolean = true
  ): Observable<(RelationshipInfo & WithId)[]> {
    return updated
      ? this.store.select(selectAllUpdatedProviders)
      : this.store.select(selectAllProviders);
  }

  getAllHubs(
    updated: boolean = true
  ): Observable<(RelationshipInfo & WithId)[]> {
    return updated
      ? this.store.select(selectAllUpdatedHubs)
      : this.store.select(selectAllHubs);
  }

  getAllCustomersAndHubs(
    updated: boolean = true
  ): Observable<(RelationshipInfo & WithId)[]> {
    return updated
      ? this.store.select(selectAllUpdatedCustomersAndHubs)
      : this.store.select(selectAllCustomersAndHubs);
  }

  getAllRelationshipInfosByOrganizationId(updated: boolean = true): Observable<{
    [organizationId: string]: RelationshipInfo & WithId;
  }> {
    return updated
      ? this.store.select(selectAllUpdatedRelationshipInfosByOrganizationId)
      : this.store.select(selectAllRelationshipInfosByOrganizationId);
  }

  getAllRelationshipInfosByRelationshipId(updated: boolean = true): Observable<{
    [relationshipId: string]: RelationshipInfo & WithId;
  }> {
    return updated
      ? this.store.select(selectAllUpdatedRelationshipInfosByRelationshipId)
      : this.store.select(selectAllRelationshipInfosByRelationshipId);
  }

  getAllPaymentTermsByRelationshipId(
    updated: boolean = true
  ): Observable<PaymentTerms> {
    return updated
      ? this.store.select(selectAllUpdatedPaymentTermsByRelationshipId)
      : this.store.select(selectAllPaymentTermsByRelationshipId);
  }

  getAllProvidersLabels(updated: boolean = true): Observable<string[]> {
    return updated
      ? this.store.select(selectAllUpdatedProvidersLabels)
      : this.store.select(selectAllProvidersLabels);
  }

  getAllCustomersAndHubsLabels(updated: boolean = true): Observable<string[]> {
    return updated
      ? this.store.select(selectAllUpdatedCustomersAndHubsLabels)
      : this.store.select(selectAllCustomersAndHubsLabels);
  }

  getAllCustomersAndHubsEmails(updated: boolean = true): Observable<string[]> {
    return updated
      ? this.store.select(selectAllUpdatedCustomersAndHubsEmails)
      : this.store.select(selectAllCustomersAndHubsEmails);
  }

  addRelationshipInfo(relationshipInfo: RelationshipInfo): Promise<string> {
    return new Promise((resolve, reject) => {
      this.store.dispatch(
        RelationshipInfoActions.add({
          record: relationshipInfo,
          confirmation: {
            resolve: resolve,
            reject: reject,
          },
        })
      );
    });
  }

  updateRelationshipInfo(
    relationshipInfo: Partial<RelationshipInfo> & WithId
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      this.store.dispatch(
        RelationshipInfoActions.update({
          record: relationshipInfo,
          confirmation: {
            resolve: resolve,
            reject: reject,
          },
        })
      );
    });
  }

  updateManyRelationshipInfos(
    relationshipInfos: (Partial<RelationshipInfo> & WithId)[]
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      this.store.dispatch(
        RelationshipInfoActions.updateMany({
          records: relationshipInfos,
          confirmation: {
            resolve: resolve,
            reject: reject,
          },
        })
      );
    });
  }

  deleteRelationshipInfo(relationshipInfoId: string): Promise<string> {
    return new Promise((resolve, reject) => {
      this.store.dispatch(
        RelationshipInfoActions.remove({
          id: relationshipInfoId,
          confirmation: {
            resolve: resolve,
            reject: reject,
          },
        })
      );
    });
  }

  addCustomerToRelationship(
    requestData: AddCustomerToRelationshipRequestData
  ): Promise<string> {
    return this.relationshipApi.addCustomerToRelationship(requestData);
  }

  createCustomerProviderRelationship(
    requestData: CreateCustomerProviderRelationshipRequestData
  ): Promise<string> {
    return this.relationshipApi.createCustomerProviderRelationship(requestData);
  }

  createHubProducerRelationship(
    requestData: CreateHubProducerRelationshipRequestData
  ): Promise<string> {
    return this.relationshipApi.createHubProducerRelationship(requestData);
  }
}
