import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { UppercaseFirstPipe } from './uppercase-first.pipe';

@NgModule({
  declarations: [UppercaseFirstPipe],
  imports: [CommonModule],
  exports: [UppercaseFirstPipe],
})
export class UppercaseFirstPipeModule {}
