import { from } from 'rxjs';

import { Injectable } from '@angular/core';
import { Functions, httpsCallable } from '@angular/fire/functions';

import { map, retryWhen, take } from 'rxjs/operators';

import { genericRetryStrategy } from '@arrivage-util/api.utils';
import { SlackPostMessageRequestData } from '@arrivage/model/dist/src/cloud-functions-api';

@Injectable({
  providedIn: 'root',
})
export class SlackApiService {
  private readonly postToSlackCloudFunction = httpsCallable<
    SlackPostMessageRequestData,
    void
  >(this.functions, 'postToSlack');

  constructor(private functions: Functions) {}

  postToSlack(request: SlackPostMessageRequestData) {
    return from(this.postToSlackCloudFunction(request))
      .pipe(
        retryWhen(genericRetryStrategy()),
        take(1),
        map((r) => r.data)
      )
      .toPromise();
  }
}
