<article fxLayoutGap="16px">
  <ng-container *transloco="let t">
    <mat-table [dataSource]="dataSource">
      <ng-container matColumnDef="format">
        <mat-header-cell *matHeaderCellDef fxFlex="40%">{{
          t('offerItems.format')
        }}</mat-header-cell>
        <mat-cell
          *matCellDef="let row; dataSource: dataSource"
          class="medium-cell"
          fxLayoutAlign="start center"
          fxLayoutGap="16px"
          fxFlex="40%"
        >
          <ng-container
            [ngTemplateOutlet]="format"
            [ngTemplateOutletContext]="{ row: row }"
          >
          </ng-container>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="price">
        <mat-header-cell *matHeaderCellDef fxLayoutAlign="end center">{{
          t('offerItems.format-price')
        }}</mat-header-cell>
        <mat-cell
          *matCellDef="let row; dataSource: dataSource"
          fxLayoutAlign="end center"
        >
          <div class="align-end">
            <ng-container
              [ngTemplateOutlet]="price"
              [ngTemplateOutletContext]="{ row: row }"
            >
            </ng-container>
          </div>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="unitPrice">
        <mat-header-cell *matHeaderCellDef>{{
          t('offerItems.format-unit-price')
        }}</mat-header-cell>
        <mat-cell
          *matCellDef="let row; dataSource: dataSource"
          fxLayoutAlign="end center"
        >
          <ng-container
            [ngTemplateOutlet]="unitPrice"
            [ngTemplateOutletContext]="{ row: row }"
          >
          </ng-container>
        </mat-cell>
      </ng-container>

      <mat-header-row
        *matHeaderRowDef="displayedColumns"
        class="headers"
      ></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>

    <!-- FORMAT TEMPLATE -->
    <ng-template #format let-row="row">
      <div>
        <img
          class="image-avatar"
          [src]="
            row?.format?.photoUrl ||
            product.mainPhotoUrl ||
            DEFAULT_PRODUCT_PICTURE(product.department)
          "
          onerror="this.style.display='none'"
        />
      </div>
      <div
        [fxLayout]="isMediumOrSmallScreen ? 'column' : 'row'"
        fxLayoutAlign="center start"
        [fxLayoutGap]="isMediumOrSmallScreen ? '4px' : '16px'"
      >
        <div fxLayout="column">
          <span class="text-m">{{ row.format | displayFormat }}</span>
          <small *ngIf="row.format.adjustedPrice" class="text-light">
            {{ t('offerItems.final-price-vary-notice') }}
          </small>
        </div>
        <div fxLayout="row" fxLayoutGap="4px" fxLayoutGap.gt-md="8px">
          <app-label
            *ngIf="row.regularPrice && !row.isUrgent"
            [type]="'warn'"
            [label]="t('offerItems.savings-label')"
            [small]="isMediumOrSmallScreen ? true : false"
          ></app-label>
          <app-label
            *ngIf="row.isUrgent"
            [type]="'warn'"
            [label]="t('offerItems.is-urgent-label')"
            [small]="isMediumOrSmallScreen ? true : false"
          ></app-label>
        </div>
      </div>
    </ng-template>

    <!-- PRICE TEMPLATE -->
    <ng-template #price let-row="row">
      <div fxLayout="column" class="bold">
        <app-format-price
          [price]="row.price"
          [regularPrice]="row.regularPrice"
          [format]="row.format"
        ></app-format-price>
      </div>
    </ng-template>

    <!-- UNIT PRICE TEMPLATE -->
    <ng-template #unitPrice let-row="row">
      <div fxLayout="column" fxLayoutAlign="center end">
        <div [class.text-color-error]="row.regularPrice" class="bold">
          <span>{{
            row.price
              | displayUnitPrice: row.format.quantity
              | displayMoneyAmount
              | translocoCurrency: 'symbol' : {} : row.price.currency
          }}</span>
          <span *ngIf="row.format.sellingUnit"
            >/{{ row.format.sellingUnit | displayAsPriceUnit }}</span
          >
        </div>
        <section
          *ngIf="row.quantity"
          fxLayout="row wrap"
          fxLayoutGap="2px"
          fxLayoutAlign="end"
        >
          <span>{{ t('offerItems.stock') }} :</span>
          <span class="bold"> {{ row.quantity || 0 | number }}</span>
        </section>
      </div>
    </ng-template>
  </ng-container>
</article>
