import { firstValueFrom } from 'rxjs';

import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from '@angular/router';

import { map } from 'rxjs/operators';

import { Store } from '@ngrx/store';

import * as fromContext from '@arrivage-store/context/context.selectors';
import { State } from '@arrivage-store/state';

@Injectable()
export class UserGuard {
  constructor(private store: Store<State>, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return firstValueFrom(
      this.store.select(fromContext.getUser).pipe(map((u) => !!u))
    );
  }
}
