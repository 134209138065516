import { from, of } from 'rxjs';

import { Injectable } from '@angular/core';

import { catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { reportError } from '@arrivage-sentry/report-error';
import { SnackbarService } from '@arrivage-snackbar/snackbar.service';
import { getOrganization } from '@arrivage-store/context/context.selectors';
import { EntityFeedback } from '@arrivage-store/feedback/feedback-params.model';
import { createBaseEffects } from '@arrivage-store/generators';

import { PriceListItemService } from '../../services/price-list/price-list-item.service';
import * as priceListItemAction from './price-list-item.actions';
import * as selectors from './price-list-item.selectors';
import { State } from './price-list-item.state';

export const PriceListItemFeedback: EntityFeedback = {
  update: 'update_price_list_item',
  // error only
  query: 'query_price_list_item',

  //
  get_active_item: '',
  add: '',
  remove: '',
};

@Injectable()
export class PriceListItemEffects {
  query$;
  add$;
  set$;
  update$;
  remove$;
  displayQueryFailure$;

  constructor(
    private actions$: Actions,
    private store: Store<State>,
    private service: PriceListItemService,
    private snackbarService: SnackbarService
  ) {
    ({
      query: this.query$,
      add: this.add$,
      set: this.set$,
      update: this.update$,
      remove: this.remove$,
      displayQueryFailure: this.displayQueryFailure$,
    } = createBaseEffects(
      this.actions$,
      this.store,
      priceListItemAction,
      selectors,
      this.service,
      PriceListItemFeedback,
      this.snackbarService
    ));
  }

  updateMany$ = createEffect(() =>
    this.actions$.pipe(
      ofType(priceListItemAction.updateMany),
      withLatestFrom(this.store.select(getOrganization)),
      mergeMap(([update, organization]) => {
        return from(
          this.service.updateMany(organization.id, update.records)
        ).pipe(
          map(() => {
            update.confirmation.resolve();
            return priceListItemAction.updateManySuccess();
          }),
          catchError((error) => {
            reportError(error);
            update.confirmation.reject(error);
            return of(
              priceListItemAction.updateManyFailure({
                errorMessage: error,
              })
            );
          })
        );
      })
    )
  );
}
