import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class HubspotService {
  constructor() {}

  private email: string;

  identify(email: string) {
    if (!this.getWindow().HubSpotConversations) {
      return;
    }

    this.email = email;
    if (this.getWindow().HubSpotConversations) {
      this.onConversationsAPIReady();
    } else {
      this.getWindow().hsConversationsOnReady = [this.onConversationsAPIReady];
    }
  }

  /**
   * See https://community.hubspot.com/t5/APIs-Integrations/How-to-identify-a-contact-in-the-chat/m-p/272830/highlight/true#M24773
   */
  private onConversationsAPIReady() {
    const _hsq = (this.getWindow()._hsq = this.getWindow()._hsq || []);
    _hsq.push([
      'identify',
      {
        email: this.email,
      },
    ]);
    _hsq.push(['trackPageView']);
    setTimeout(() => {
      this.getWindow().HubSpotConversations.widget.refresh();
    }, 1000);
  }

  private getWindow(): any {
    return window as any;
  }
}
