import { Observable, combineLatest, of } from 'rxjs';

import { Injectable } from '@angular/core';
import {
  Firestore,
  collection,
  collectionData,
  doc,
  docData,
  docSnapshots,
  query,
  updateDoc,
  where,
} from '@angular/fire/firestore';

import { map } from 'rxjs/operators';

import { ConversationUtils } from '@arrivage-conversations/utils/conversations.utils';
import {
  Organization,
  VendorProfile,
  WithId,
} from '@arrivage/model/dist/src/model/';
import { CollectionNames } from '@arrivage/model/dist/src/utils';

@Injectable({
  providedIn: 'root',
})
export class OrganizationService {
  public static readonly ORGANIZATIONS_COLLECTION =
    CollectionNames.ORGANIZATION_COLLECTION;

  constructor(private firestore: Firestore) {}

  getOrganization(orgId: string): Observable<(Organization & WithId) | null> {
    return docSnapshots(
      doc(this.firestore, OrganizationService.ORGANIZATIONS_COLLECTION, orgId)
    ).pipe(
      map((p) => {
        if (p.data()) {
          const data = p.data();
          const id = p.id;
          return { ...data, id } as Organization & WithId;
        } else {
          return null;
        }
      })
    );
  }

  listAllOrganizations(): Observable<(Organization & WithId)[]> {
    return collectionData(
      collection(this.firestore, OrganizationService.ORGANIZATIONS_COLLECTION),
      { idField: 'id' }
    ).pipe(
      map((d) =>
        d.map((data) => {
          return data as Organization & WithId;
        })
      )
    );
  }

  listAllVendorOrganizations(): Observable<(Organization & WithId)[]> {
    return collectionData(
      query(
        collection(
          this.firestore,
          OrganizationService.ORGANIZATIONS_COLLECTION
        ),
        where('isSeller', '==', true)
      ),
      { idField: 'id' }
    ).pipe(
      map((d) =>
        d.map((data) => {
          return data as Organization & WithId;
        })
      )
    );
  }

  getOrganizationsVendorProfile(
    orgIds: string[]
  ): Observable<{ [organizationId: string]: VendorProfile | null }> {
    if (orgIds?.length > 0) {
      const organizationsVendorProfile$ = orgIds.map((orgId) => {
        return docData(
          doc(
            this.firestore,
            OrganizationService.ORGANIZATIONS_COLLECTION,
            orgId
          )
        ).pipe(
          map((o) => {
            return {
              id: orgId,
              vendorProfile: o['vendorProfile'] ?? null,
            };
          })
        );
      });

      return combineLatest(organizationsVendorProfile$).pipe(
        map((result) =>
          result.reduce(function (map, obj) {
            map[obj.id] = obj.vendorProfile;
            return map;
          }, {})
        )
      );
    }

    return of({});
  }

  getOrgasInfo(
    orgasId: string[]
  ): Observable<ConversationUtils.OrganizationInfoMap> {
    const observablesOrgasInfo = orgasId.map((orgaId) =>
      docData(
        doc(
          this.firestore,
          OrganizationService.ORGANIZATIONS_COLLECTION,
          orgaId
        )
      ).pipe(
        map((x) => {
          return {
            id: orgaId,
            info: { name: x['name'], logoUrl: x['logoUrl'] },
          };
        })
      )
    );

    return combineLatest(observablesOrgasInfo).pipe(
      map((result) =>
        result.reduce(function (map, obj) {
          map[obj.id] = obj.info;
          return map;
        }, {})
      )
    );
  }

  updateOrganization(org: Partial<Organization>, id: string): Promise<void> {
    return updateDoc(
      doc(this.firestore, OrganizationService.ORGANIZATIONS_COLLECTION, id),
      org
    );
  }
}
