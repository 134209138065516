import { EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Action, createReducer } from '@ngrx/store';

import {
  createInitialState,
  createEntityReducer,
} from '@arrivage-store/generators';
import { Pickup, WithId } from '@arrivage/model/dist/src/model';

import * as PickupActions from './pickup.actions';
import { PickupState } from './pickup.state';

export const adapter: EntityAdapter<Pickup & WithId> = createEntityAdapter<
  Pickup & WithId
>();

export const initialState: PickupState = createInitialState(adapter);

const pickupReducer = createReducer(
  initialState,
  ...createEntityReducer(PickupActions, adapter)
);

export function reducer(state: PickupState | undefined, action: Action) {
  return pickupReducer(state, action);
}
