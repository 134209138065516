import { Pipe, PipeTransform } from '@angular/core';

import { DateUtils } from '@arrivage-util/date.utils';

@Pipe({
  name: 'countDaysBetween',
})
export class CountDaysBetweenPipe implements PipeTransform {
  transform(
    value: Date | number,
    isRound: boolean = true,
    ...args: any[]
  ): number {
    if (value) {
      return DateUtils.countDaysBetween(value, true, isRound);
    }
    return null;
  }
}
