import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';

import { TranslocoModule } from '@jsverse/transloco';

import { LabelModule } from '@arrivage-components/label/label.module';

import { AppUtilsModule } from '../app-utils.module';
import { MaterialModule } from '../material.module';
import { ConservationComponent } from './conservation/conservation.component';
import { DeliveryDayComponent } from './delivery-day/delivery-day.component';
import { DepartmentComponent } from './department/department.component';

@NgModule({
  declarations: [
    DepartmentComponent,
    DeliveryDayComponent,
    ConservationComponent,
  ],
  imports: [
    CommonModule,
    TranslocoModule,
    MaterialModule,
    LabelModule,
    AppUtilsModule,
    FlexLayoutModule,
  ],
  exports: [DepartmentComponent, DeliveryDayComponent, ConservationComponent],
})
export class I18nModule {}
