import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { PickupEffects } from './pickup.effects';
import { reducer } from './pickup.reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('pickup', reducer),
    EffectsModule.forFeature([PickupEffects]),
  ],
})
export class PickupStoreModule {}
