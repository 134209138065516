import { Action, createReducer, on } from '@ngrx/store';

import * as organizationSetupActions from './organization-setup.actions';
import { OrganizationSetupState } from './organization-setup.state';

export const initialState: OrganizationSetupState = {
  loaded: false,
  organizationSetup: undefined,
  error: undefined,
};

const organizationSetupReducer = createReducer(
  initialState,
  on(organizationSetupActions.query, (state) => {
    return {
      ...state,
      loaded: false,
      organizationSetup: undefined,
      error: undefined,
    };
  }),
  on(organizationSetupActions.querySuccess, (state, { organizationSetup }) => {
    return {
      ...state,
      loaded: true,
      organizationSetup: organizationSetup,
      error: undefined,
    };
  }),
  on(organizationSetupActions.queryFailure, (state, { errorMessage }) => {
    return {
      ...state,
      loaded: false,
      organizationSetup: undefined,
      error: errorMessage,
    };
  }),
  on(organizationSetupActions.update, (state) => {
    return {
      ...state,
      error: undefined,
      updateFailure: undefined,
    };
  }),
  on(
    organizationSetupActions.updateFailure,
    (state, { organizationSetup, errorMessage }) => {
      return {
        ...state,
        error: errorMessage,
        updateFailure: organizationSetup,
      };
    }
  )
);

export function reducer(
  state: OrganizationSetupState | undefined,
  action: Action
) {
  return organizationSetupReducer(state, action);
}
