import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';

import { filter, map } from 'rxjs/operators';

import { ContextFacade } from '@arrivage-store/api/context.facade';

@Injectable({
  providedIn: 'root',
})
export class CustomerGuard  {
  constructor(private contextFacade: ContextFacade, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.contextFacade.getOrganization().pipe(
      filter((x) => !!x),
      map((organization) =>
        organization.isBuyer ? true : this.router.parseUrl('/')
      )
    );
  }
}
