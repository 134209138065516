<ng-container
  *transloco="let t; scope: 'purchaseOrders'; read: 'purchaseOrders'"
>
  <section
    class="mat-dialog-title no-space-top"
    fxLayout="row"
    fxLayoutAlign="space-between center"
  >
    <h2 class="text-xl">
      {{ t('dialog-confirmation.confirm-delivery-title') }}
    </h2>
    <button mat-icon-button mat-dialog-close type="button" tabindex="-1">
      <mat-icon>close</mat-icon>
    </button>
  </section>

  <mat-card-content>
    <div class="mat-dialog-content" fxLayout="column">
      @if (data?.length > 1) {
        <mat-radio-group
          fxLayout="column"
          fxLayoutGap="2rem"
          class="radio-group"
          value="purchaseOrdersDeliveryDate"
          [required]="true"
          (change)="onRadioButtonChange($event)"
        >
          <mat-radio-button
            value="purchaseOrdersDeliveryDate"
            [checked]="true"
            >{{
              t('dialog-confirmation.purchase-order-delivery-date')
            }}</mat-radio-button
          >
          <mat-radio-button value="newDate">{{
            t('dialog-confirmation.new-delivery-date')
          }}</mat-radio-button>
        </mat-radio-group>
      }

      <mat-form-field appearance="fill">
        <mat-label>{{
          t('dialog-confirmation.mark-as-delivered-date')
        }}</mat-label>
        <input
          matInput
          required
          [formControl]="deliveryDateControl"
          [matDatepicker]="picker"
          [max]="TODAY"
        />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
        <mat-error>{{ t('dialog-confirmation.date-error') }}</mat-error>
      </mat-form-field>
    </div>
  </mat-card-content>
  <mat-dialog-actions align="end">
    <button mat-stroked-button mat-dialog-close type="button">
      {{ t('dialog-confirmation.cancel') }}
    </button>
    <button
      mat-raised-button
      data-testid="mark-as-delivered-confirm-button-test-id"
      type="submit"
      color="accent"
      (click)="confirmDelivery()"
    >
      {{ t('dialog-confirmation.confirm') }}
    </button>
  </mat-dialog-actions>
</ng-container>
