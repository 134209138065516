<ng-container *transloco="let t; read: 'components.share-buttons'">
  <button
    *ngIf="!isNavLink; else navLink"
    mat-icon-button
    [customTooltip]="t('title')"
    (click)="
      canDefaultShare && isMobileOrTablet ? onDefaultShare() : onCustomShare()
    "
  >
    <mat-icon>share</mat-icon>
  </button>

  <ng-template #navLink>
    <a
      mat-list-item
      class="bold"
      (click)="
        canDefaultShare && isMobileOrTablet ? onDefaultShare() : onCustomShare()
      "
    >
      <mat-icon [ngClass]="{ 'navigation-icon': isExpanded }">share</mat-icon>
      <div *ngIf="isExpanded">{{ t('nav-link') }}</div>
    </a>
  </ng-template>
</ng-container>
