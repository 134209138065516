import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { PremiumAccountEffects } from './premium-accounts.effects';
import { reducer } from './premium-accounts.reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('premiumAccount', reducer),
    EffectsModule.forFeature([PremiumAccountEffects]),
  ],
})
export class PremiumAccountStoreModule {}
