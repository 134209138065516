import { endOfDay, isEqual, isPast } from 'date-fns';

import { DeliveryUtils } from '@arrivage-distribution/common/utils/delivery.utils';
import { PickupUtils } from '@arrivage-distribution/vendor/utils/pickup.utils';
import { CustomerInvoiceFacade } from '@arrivage-invoices/customer/api/customer-invoice.facade';
import { VendorInvoiceFacade } from '@arrivage-invoices/vendor/api/vendor-invoice.facade';
import { PurchaseOrderTotalPipe } from '@arrivage-pipes/purchase-order-total-pipe/purchase-order-total.pipe';
import { reportError } from '@arrivage-sentry/report-error';
import { DateUtils } from '@arrivage-util/date.utils';
import { LangUtils } from '@arrivage-util/lang.utils';
import {
  Delivery,
  Invoice,
  LocalDate,
  Message,
  MessagesSource,
  OfferItem,
  OrderItem,
  Pickup,
  PurchaseOrder,
  PurchaseOrderState,
  PurchaseOrderStatus,
  WithId,
} from '@arrivage/model/dist/src/model';
import { Money } from '@arrivage/model/dist/src/model/money';
import { BillingUtils } from '@arrivage/model/dist/src/utils';
import _ from 'lodash';
import {
  OrderItemWithCorrespondingOfferItem,
  OrderItemWithCorrespondingOfferItemForUpdate,
  PurchaseOrderCreationFormInfo,
  WithUpdatedPrice,
} from '../model/purchase-orders.model';

export namespace PurchaseOrdersUtils {
  export const allStatusesOpen: PurchaseOrderStatus[] = [
    PurchaseOrderStatus.SUBMITTED,
    PurchaseOrderStatus.CONFIRMED,
    PurchaseOrderStatus.MODIFIEDBYCUSTOMER,
    PurchaseOrderStatus.MODIFIEDBYVENDOR,
  ];

  export const allStatusesClosed: PurchaseOrderStatus[] = [
    PurchaseOrderStatus.EXPIRED,
    PurchaseOrderStatus.CANCELLEDBYVENDOR,
    PurchaseOrderStatus.COMPLETED,
    PurchaseOrderStatus.CANCELLEDBYCUSTOMER,
  ];

  export function sortStatus(status: PurchaseOrderStatus): number {
    switch (status) {
      case PurchaseOrderStatus.NOT_VERIFIED:
        return 0;
      case PurchaseOrderStatus.SUBMITTED:
        return 1;
      case PurchaseOrderStatus.MODIFIEDBYVENDOR:
        return 2;
      case PurchaseOrderStatus.MODIFIEDBYCUSTOMER:
        return 3;
      case PurchaseOrderStatus.CONFIRMED:
        return 4;
      case PurchaseOrderStatus.COMPLETED:
        return 5;
      case PurchaseOrderStatus.CANCELLEDBYVENDOR:
        return 6;
      case PurchaseOrderStatus.CANCELLEDBYCUSTOMER:
        return 7;
      case PurchaseOrderStatus.EXPIRED:
        return 8;
    }
  }

  export function sortIsLate(date: Date): number {
    return !date ? 2 : isPast(endOfDay(date)) ? 0 : 1;
  }

  export function sortBoolean(bool: boolean): number {
    if (LangUtils.nullOrUndefined(bool)) {
      return 2;
    }
    return bool ? 0 : 1;
  }

  export function customNaturalSort(data: PurchaseOrder & WithId): string {
    return (
      '' +
      PurchaseOrdersUtils.sortStatus(data.status) +
      PurchaseOrdersUtils.sortIsLate(
        data.deliveryDate ?? PickupUtils.getPickupOptionDate(data.pickup)
      ) +
      PurchaseOrdersUtils.sortBoolean(!data.delivered) +
      PurchaseOrdersUtils.sortBoolean(!data.invoicePaid)
    );
  }

  export function changePurchaseOrdersStateAndStatus(
    value: PurchaseOrder,
    newStatus: PurchaseOrderStatus
  ): PurchaseOrder {
    const state = getState(newStatus, value);

    return {
      ...value,
      status: newStatus,
      state: state,
    };
  }

  export function getState(
    newStatus: PurchaseOrderStatus,
    purchaseOrder: PurchaseOrder
  ): PurchaseOrderState {
    if (allStatusesOpen.includes(newStatus)) {
      return PurchaseOrderState.OPEN;
    }

    if (
      newStatus === PurchaseOrderStatus.COMPLETED &&
      (!purchaseOrder.delivered || !purchaseOrder.invoicePaid)
    ) {
      return PurchaseOrderState.OPEN;
    }

    return PurchaseOrderState.CLOSED;
  }

  export function isLate(purchaseOrder: PurchaseOrder): boolean {
    const excludedStatus = [
      PurchaseOrderStatus.CANCELLEDBYCUSTOMER,
      PurchaseOrderStatus.CANCELLEDBYVENDOR,
      PurchaseOrderStatus.EXPIRED,
    ];
    const isPastDeliveryDate =
      isPast(endOfDay(purchaseOrder.deliveryDate)) && !purchaseOrder.delivered;
    const isPastPickupDate =
      isPast(PickupUtils.getPickupOptionDate(purchaseOrder.pickup, 'to')) &&
      !purchaseOrder.delivered;

    return (
      !excludedStatus.includes(purchaseOrder.status) &&
      (isPastDeliveryDate || isPastPickupDate)
    );
  }

  export function lastDelivery(
    purchaseOrders: (PurchaseOrder & WithId)[]
  ): number {
    const now = new Date().getTime();
    if (purchaseOrders.length > 0) {
      return Math.max(
        ...purchaseOrders.map((po) => {
          return po?.delivered < now ? po.delivered : 0;
        })
      );
    }
    return 0;
  }

  export function generatePurchaseOrderMessage(
    purchaseOrder: PurchaseOrder & WithId,
    senderOrganizationId: string,
    senderUserId: string,
    text: string
  ): Message {
    return {
      source: MessagesSource.PURCHASE_ORDER,
      sourceId: purchaseOrder.id,
      relationshipId: purchaseOrder.relationshipId,
      sentAt: new Date(Date.now()),
      text: text,
      organizationIdSender: senderOrganizationId,
      userIdSender: senderUserId,
      processed: false,
      isRead: false,
      skipMail: false,
    };
  }

  export function generateNewOrModifiedPurchaseOrderMessage(
    purchaseOrder: PurchaseOrder & WithId,
    senderOrganizationId: string,
    senderUserId: string,
    text: string
  ): Message {
    return {
      ...generatePurchaseOrderMessage(
        purchaseOrder,
        senderOrganizationId,
        senderUserId,
        text
      ),
      skipMail: true,
    };
  }

  export function getPurchaseOrderTotalAmount(
    pOrders: (PurchaseOrder & WithId)[]
  ): Money {
    const defaultValue = Money.fromDecimal(0, Money.CANADIAN_DOLLARS);

    return pOrders.reduce(
      (acc, po) =>
        isPurchaseOrderCancelled(po.status)
          ? acc
          : Money.sum([acc, new PurchaseOrderTotalPipe().transform(po)]),
      defaultValue
    );
  }

  export function getPurchaseOrderTotalOwed(
    pOrders: (PurchaseOrder & WithId)[]
  ): Money {
    if (pOrders) {
      return {
        amount: pOrders.reduce((sum, purchaseOrder) => {
          if (
            purchaseOrder.invoicePaid ||
            isPurchaseOrderCancelled(purchaseOrder.status)
          ) {
            return sum;
          } else {
            return (
              sum + new PurchaseOrderTotalPipe().transform(purchaseOrder).amount
            );
          }
        }, 0),
        currency: pOrders[0].orderItems[0].price.currency,
      };
    }
  }

  /**
   * Get the total amount of all purchase orders without the delivery fee
   * @param purchaseOrders
   * @returns
   */
  export function getPurchaseOrdersTotalAmountWithoutDeliveryFee(
    purchaseOrders: (PurchaseOrder & WithId)[]
  ): Money {
    const defaultValue = Money.fromDecimal(0, Money.CANADIAN_DOLLARS);

    return purchaseOrders.reduce((acc, po) => {
      return Money.sum([
        BillingUtils.computeTaxAndTotal(
          po.orderItems.map((oi) => ({
            price: oi.price,
            quantity: oi.requestedQuantity,
            applicableTaxes: oi.format.applicableTaxes,
          })),
          po.taxSettings,
          null, // delivery is set to null because we don't need to compute the delivery fee
          po.volumeDiscounts,
          po.discounts,
          po.clientDiscount
        ).total,
        acc,
      ]);
    }, defaultValue);
  }

  export function isPurchaseOrderCancelled(
    status: PurchaseOrderStatus
  ): boolean {
    return (
      status === PurchaseOrderStatus.CANCELLEDBYCUSTOMER ||
      status === PurchaseOrderStatus.CANCELLEDBYVENDOR
    );
  }

  export function getValidPurchaseOrderCount(
    pOrders: (PurchaseOrder & WithId)[]
  ): number {
    if (pOrders) {
      return pOrders.reduce((sum, purchaseOrder) => {
        if (isPurchaseOrderCancelled(purchaseOrder.status)) {
          return sum;
        } else {
          return sum + 1;
        }
      }, 0);
    }
  }

  /**
   * Determines whether or not a purchase order's associated invoice can be deleted.
   *
   * @param purchaseOrder purchase order on which to perform invoice deletion
   * @returns boolean
   */
  export function canDeleteOrReplaceInvoice(
    purchaseOrder: PurchaseOrder
  ): boolean {
    return (
      !purchaseOrder?.invoicePaid &&
      (!!purchaseOrder.externalInvoiceUrl || !!purchaseOrder.invoiceId)
    );
  }

  export async function getPurchaseOrderInvoice(
    purchaseOrder: PurchaseOrder,
    invoiceFacade: CustomerInvoiceFacade | VendorInvoiceFacade
  ): Promise<Invoice & WithId> {
    try {
      return await invoiceFacade.loadAndGetActiveItem(purchaseOrder.invoiceId);
    } catch (e) {
      reportError(e);
    }
  }

  export function isDeliveredAndConfirmedOrCompleted(
    purchaseOrder: PurchaseOrder
  ): boolean {
    return (
      (purchaseOrder.status === PurchaseOrderStatus.CONFIRMED ||
        purchaseOrder.status === PurchaseOrderStatus.COMPLETED) &&
      !!purchaseOrder.delivered
    );
  }

  export function getDeliveryDateInfo(purchaseOrder: PurchaseOrder) {
    return purchaseOrder.pickup
      ? DateUtils.formatDate(LocalDate.toDate(purchaseOrder.pickup.selectedDay))
      : DateUtils.formatDate(purchaseOrder.deliveryDate);
  }

  export function getOrderItemsWithCorrespondingOfferItems(
    orderItems: OrderItem[],
    offerItems: OfferItem[]
  ): OrderItemWithCorrespondingOfferItem[] {
    return orderItems.map((orderItem) => {
      return {
        orderItem: orderItem,
        correspondingOfferItem: offerItems.find(
          (offerItem) => offerItem.format.id === orderItem.format.id
        ),
      };
    });
  }

  export function getOrderItemsWithCorrespondingOfferItemsForUpdate(
    orderItems: OrderItem[],
    offerItems: OfferItem[],
    orderItemsBeforeUpdate: OrderItem[]
  ): OrderItemWithCorrespondingOfferItemForUpdate[] {
    return orderItems.map((orderItem) => {
      return {
        orderItem: orderItem,
        correspondingOfferItem: offerItems.find(
          (offerItem) => offerItem.format.id === orderItem.format.id
        ),
        orderItemBeforeUpdate: orderItemsBeforeUpdate.find(
          (orderItemBeforeUpdate) =>
            orderItemBeforeUpdate.format.id === orderItem.format.id
        ),
      };
    });
  }

  export function canOrderFromUpdatedStock(
    orderItemsWithCorrespondingOfferItems: OrderItemWithCorrespondingOfferItem[]
  ): boolean {
    return _.every(
      orderItemsWithCorrespondingOfferItems,
      (orderItemWithCorrespondingOfferItem) => {
        if (!orderItemWithCorrespondingOfferItem.correspondingOfferItem)
          return false;

        return PurchaseOrdersUtils.hasEnoughStock(
          orderItemWithCorrespondingOfferItem.orderItem.requestedQuantity,
          orderItemWithCorrespondingOfferItem.correspondingOfferItem.quantity
        );
      }
    );
  }

  export function canOrderFromUpdatedStockForUpdate(
    orderItemsWithCorrespondingOfferItemForUpdate: OrderItemWithCorrespondingOfferItemForUpdate[]
  ): boolean {
    return _.every(
      orderItemsWithCorrespondingOfferItemForUpdate,
      (orderItemWithCorrespondingOfferItemForUpdate) => {
        return PurchaseOrdersUtils.hasEnoughStock(
          orderItemWithCorrespondingOfferItemForUpdate.orderItem
            .requestedQuantity,
          orderItemWithCorrespondingOfferItemForUpdate.correspondingOfferItem
            ?.quantity ?? 0,
          orderItemWithCorrespondingOfferItemForUpdate.orderItemBeforeUpdate
            ?.requestedQuantity ?? 0
        );
      }
    );
  }

  export function hasEnoughStock(
    quantityRequested: number,
    stockFromUpdatedOffer: number,
    quantityRequestedBeforeUpdate: number = 0
  ): boolean {
    return (
      quantityRequested <= quantityRequestedBeforeUpdate + stockFromUpdatedOffer
    );
  }

  export function getOrderItemsOutOfStockOrDeleted(
    orderItemsWithCorrespondingOfferItems: OrderItemWithCorrespondingOfferItem[]
  ): OrderItem[] {
    const itemsWithNoMoreStockOrThatWereDeleted: OrderItem[] = [];

    orderItemsWithCorrespondingOfferItems.forEach(
      (orderItemWithCorrespondingOfferItem) => {
        if (!orderItemWithCorrespondingOfferItem.correspondingOfferItem) {
          itemsWithNoMoreStockOrThatWereDeleted.push(
            orderItemWithCorrespondingOfferItem.orderItem
          );
        } else if (
          orderItemWithCorrespondingOfferItem.correspondingOfferItem
            .quantity === 0
        ) {
          itemsWithNoMoreStockOrThatWereDeleted.push(
            orderItemWithCorrespondingOfferItem.orderItem
          );
        }
      }
    );

    return itemsWithNoMoreStockOrThatWereDeleted;
  }

  export function hasPriceOfOrderedItemsChanged(
    orderItemsWithCorrespondingOfferItems: OrderItemWithCorrespondingOfferItem[]
  ): boolean {
    return _.some(
      orderItemsWithCorrespondingOfferItems,
      (orderItemWithCorrespondingOfferItem) => {
        if (!orderItemWithCorrespondingOfferItem.correspondingOfferItem)
          return true;

        return PurchaseOrdersUtils.hasPriceChanged(
          orderItemWithCorrespondingOfferItem.orderItem,
          orderItemWithCorrespondingOfferItem.correspondingOfferItem
        );
      }
    );
  }

  export function hasPriceChanged(
    orderItem: OrderItem,
    updatedOfferItem: OfferItem
  ): boolean {
    // If promo has been removed or added
    if (
      (orderItem.regularPrice && !updatedOfferItem.regularPrice) ||
      (!orderItem.regularPrice && updatedOfferItem.regularPrice)
    )
      return true;

    // If there is a promo before in the orderItem and after in the updatedOfferItem but are not the same price
    if (
      orderItem.regularPrice &&
      updatedOfferItem.regularPrice &&
      !Money.equals(orderItem.price, updatedOfferItem.price)
    )
      return true;

    // If the prices has changed when there are no promo
    if (!Money.equals(orderItem.price, updatedOfferItem.price)) return true;

    return false;
  }

  export function getOrderItemsWithChangedPrice(
    orderItemsWithCorrespondingOfferItems: OrderItemWithCorrespondingOfferItem[]
  ): (OrderItem & WithUpdatedPrice)[] {
    return _.reduce(
      orderItemsWithCorrespondingOfferItems,
      (acc, orderItemWithCorrespondingOfferItem) => {
        if (!orderItemWithCorrespondingOfferItem.correspondingOfferItem)
          return acc;

        if (
          PurchaseOrdersUtils.hasPriceChanged(
            orderItemWithCorrespondingOfferItem.orderItem,
            orderItemWithCorrespondingOfferItem.correspondingOfferItem
          )
        ) {
          return [
            ...acc,
            {
              ...orderItemWithCorrespondingOfferItem.orderItem,
              updatedPrice:
                orderItemWithCorrespondingOfferItem.correspondingOfferItem
                  .price,
            },
          ];
        } else {
          return acc;
        }
      },
      []
    );
  }

  export function hasExceedDeadlineToOrder(
    infoFromForm: PurchaseOrderCreationFormInfo,
    upToDateDeliveries: (Delivery & WithId)[],
    upToDatePickup: (Pickup & WithId)[],
    purchaseOrderBeforeUpdate?: PurchaseOrder & WithId
  ): boolean {
    if (
      purchaseOrderBeforeUpdate &&
      purchaseOrderBeforeUpdate.delivery &&
      isEqual(purchaseOrderBeforeUpdate.deliveryDate, infoFromForm.deliveryDate)
    )
      return false;

    if (infoFromForm.delivery) {
      const correspondingUpdatedDelivery = upToDateDeliveries.find(
        (delivery) => delivery.id === infoFromForm.delivery.id
      );

      if (!correspondingUpdatedDelivery) return true;

      return !DeliveryUtils.isOpenDeliveryDate(
        correspondingUpdatedDelivery,
        infoFromForm.deliveryDate
      );
    } else if (infoFromForm.pickup) {
      const correspondingUpToDatePickup = upToDatePickup.find(
        (pickup) => pickup.id === infoFromForm.pickup.id
      );

      if (!correspondingUpToDatePickup) return true;

      const pickupDate = PickupUtils.getPickupOptionDate({
        selectedDay: infoFromForm.pickup.selectedDay,
        selectedTime: infoFromForm.pickup.selectedTime,
      });

      if (!pickupDate) return true;

      return !PickupUtils.isOpenPickupDate(
        correspondingUpToDatePickup,
        pickupDate
      );
    }

    return false;
  }
}
