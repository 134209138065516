import { NgModule } from '@angular/core';

import { BlurOnWheelInputNumberDirectiveModule } from '@arrivage-directives/blur-on-wheel-input-number-directive/blur-on-wheel-input-number-directive.module';
import { CustomTooltipDirectiveModule } from '@arrivage-directives/custom-tooltip-directive/custom-tooltip-directive.module';
import { LazyLoadImageDirectiveModule } from '@arrivage-directives/lazy-load-image-directive/lazy-load-image-directive.module';
import { MarkFormTouchedDirectiveModule } from '@arrivage-directives/mark-form-touched-directive/mark-form-touched-directive.module';

@NgModule({
  imports: [
    LazyLoadImageDirectiveModule,
    MarkFormTouchedDirectiveModule,
    BlurOnWheelInputNumberDirectiveModule,
    CustomTooltipDirectiveModule,
  ],
  exports: [
    LazyLoadImageDirectiveModule,
    MarkFormTouchedDirectiveModule,
    BlurOnWheelInputNumberDirectiveModule,
    CustomTooltipDirectiveModule,
  ],
})
export class AppUtilsModule {}
