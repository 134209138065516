import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { FormControl, UntypedFormBuilder } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';

import { PublishInvoiceForm } from '@arrivage-invoices/common/model/invoice.model';
import { TypedFormConserveArray } from '@arrivage-util/form/forms.model';
import { Invoice, WithId } from '@arrivage/model/dist/src/model';

export interface PublishInvoiceDialogResponse {
  shouldSendInvoice: boolean;
  message: string;
}

@Component({
  selector: 'app-publish-invoice-dialog',
  templateUrl: './publish-invoice-dialog.component.html',
  styleUrls: ['./publish-invoice-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PublishInvoiceDialogComponent {
  form: TypedFormConserveArray<PublishInvoiceForm> = this.fb.group({
    message: [null],
  });

  sendInvoice = true;

  static openDialog(dialog: MatDialog, data?: Invoice & WithId) {
    return dialog.open<PublishInvoiceDialogComponent, Invoice & WithId>(
      PublishInvoiceDialogComponent,
      {
        data: data,
        width: '600px',
      }
    );
  }

  constructor(
    private fb: UntypedFormBuilder,
    public dialogRef: MatDialogRef<
      PublishInvoiceDialogComponent,
      PublishInvoiceDialogResponse
    >,
    @Inject(MAT_DIALOG_DATA) public data?: Invoice & WithId
  ) {}

  get messageControl(): FormControl<string> {
    return this.form.controls.message;
  }

  publish() {
    this.dialogRef.close({
      shouldSendInvoice: this.sendInvoice,
      message: this.messageControl.value ?? '',
    });
  }

  onSendInvoiceChange(checked: boolean) {
    this.sendInvoice = checked;
    if (!this.sendInvoice) {
      this.messageControl.setValue('');
      this.messageControl.disable();
    } else {
      this.messageControl.enable();
    }
  }
}
