import { createAction, props } from '@ngrx/store';

import { Confirmation, generateActions } from '@arrivage-store/generators';
import { PriceListItem, WithId } from '@arrivage/model/dist/src/model';

const featureName = 'PriceListItem';

export const {
  query,
  connected,
  add,
  added,
  set,
  update,
  modified,
  remove,
  removed,
  queryFailure,
  addSuccess,
  addFailure,
  setSuccess,
  setFailure,
  updateSuccess,
  updateFailure,
  removeSuccess,
  removeFailure,
  getActiveItem,
  getActiveItemFailure,
  getActiveItemSuccess,
} = generateActions<PriceListItem>(featureName);

export const updateMany = createAction(
  `[${featureName}] Update Many`,
  props<{
    records: (Partial<PriceListItem> & WithId)[];
    confirmation: Confirmation<void, any>;
  }>()
);

export const updateManySuccess = createAction(
  `[${featureName}] Update Many Success`
);

export const updateManyFailure = createAction(
  `[${featureName}] Update Many Failure`,
  props<{
    errorMessage: string;
  }>()
);
