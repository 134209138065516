import { EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { createReducer, Action } from '@ngrx/store';

import {
  createEntityReducer,
  createInitialState,
} from '@arrivage-store/generators';
import { Product, WithId } from '@arrivage/model/dist/src/model';

import * as ProductsActions from './products.actions';
import { ProductsState } from './products.state';

export const adapter: EntityAdapter<Product & WithId> = createEntityAdapter<
  Product & WithId
>();

export const initialState: ProductsState = createInitialState(adapter);

const productsReducer = createReducer(
  initialState,
  ...createEntityReducer(ProductsActions, adapter)
);

export function reducer(state: ProductsState | undefined, action: Action) {
  return productsReducer(state, action);
}
