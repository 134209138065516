import { EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on, Action } from '@ngrx/store';

import { createInitialState } from '@arrivage-store/generators';
import { Organization, WithId } from '@arrivage/model/dist/src/model';

import {
  getOrganization,
  getOrganizationSuccess,
  getOrganizationFailure,
} from './organization.actions';
import { OrganizationState } from './organization.state';

export const adapter: EntityAdapter<
  Organization & WithId
> = createEntityAdapter<Organization & WithId>();

const initialState: OrganizationState = createInitialState(adapter, {
  currentOrganizationSelected: undefined,
  isLoadingOrganizationSelected: false,
  hasLoadedOrganizationSelected: false,
});

const organizationReducer = createReducer(
  initialState,
  on(getOrganization, (state: OrganizationState) => {
    return {
      ...state,
      currentOrganizationSelected: undefined,
      isLoadingOrganizationSelected: true,
      hasLoadedOrganizationSelected: false,
    };
  }),
  on(getOrganizationSuccess, (state: OrganizationState, organization) => {
    return {
      ...state,
      currentOrganizationSelected: organization.organization,
      isLoadingOrganizationSelected: false,
      hasLoadedOrganizationSelected: true,
    };
  }),
  on(getOrganizationFailure, (state: OrganizationState, error) => {
    return {
      ...state,
      currentOrganizationSelected: undefined,
      isLoadingOrganizationSelected: false,
      hasLoadedOrganizationSelected: false,
    };
  })
);

export function reducer(state: OrganizationState | undefined, action: Action) {
  return organizationReducer(state, action);
}
