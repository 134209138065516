import { EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import { createInitialState } from '@arrivage-store/generators';
import { Invoice, WithId } from '@arrivage/model/dist/src/model';

import { createCommonInvoiceReducer } from '../../common/store/generator';
import * as actions from './customer-invoices.actions';
import { CustomerInvoicesState } from './customer-invoices.state';

export const adapter: EntityAdapter<Invoice & WithId> = createEntityAdapter<
  Invoice & WithId
>();

export const initialState: CustomerInvoicesState = createInitialState(adapter, {
  dateRange: undefined,
  byDateRange: {
    items: [],
    isLoading: false,
    error: undefined,
  },
  loadingPaymentMethods: false,
  paymentMethods: undefined,
  paymentMethodsError: undefined,
  loadingRelatedInvoices: false,
  relatedInvoices: undefined,
  relatedInvoicesError: undefined,
  processingPayment: false,
  paymentResponse: undefined,
  paymentError: undefined,
});

const invoicesReducer = createReducer(
  initialState,
  ...createCommonInvoiceReducer<CustomerInvoicesState>(
    actions.CustomerInvoicesActions,
    adapter
  ),
  on(actions.getPaymentMethods, (state: CustomerInvoicesState) => {
    return {
      ...state,
      loadingPaymentMethods: true,
      paymentMethods: undefined,
      paymentMethodsError: undefined,
    };
  }),
  on(
    actions.getPaymentMethodsSuccess,
    (state: CustomerInvoicesState, action) => {
      return {
        ...state,
        loadingPaymentMethods: false,
        paymentMethods: action.paymentMethodsResponse,
        paymentMethodsError: undefined,
      };
    }
  ),
  on(
    actions.getPaymentMethodsFailure,
    (state: CustomerInvoicesState, action) => {
      return {
        ...state,
        loadingPaymentMethods: false,
        paymentMethods: undefined,
        paymentMethodsError: action.error,
      };
    }
  ),
  on(actions.getRelatedInvoices, (state: CustomerInvoicesState) => {
    return {
      ...state,
      loadingRelatedInvoices: true,
      relatedInvoices: undefined,
      relatedInvoicesError: undefined,
    };
  }),
  on(
    actions.getRelatedInvoicesSuccess,
    (state: CustomerInvoicesState, action) => {
      return {
        ...state,
        loadingRelatedInvoices: false,
        relatedInvoices: action.relatedInvoicesResponse,
        relatedInvoicesError: undefined,
      };
    }
  ),
  on(
    actions.getRelatedInvoicesFailure,
    (state: CustomerInvoicesState, action) => {
      return {
        ...state,
        loadingRelatedInvoices: false,
        relatedInvoices: undefined,
        relatedInvoicesError: action.error,
      };
    }
  )
);

export function reducer(
  state: CustomerInvoicesState | undefined,
  action: Action
) {
  return invoicesReducer(state, action);
}
