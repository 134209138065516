import _ from 'lodash';
import { from, of } from 'rxjs';

import { Injectable } from '@angular/core';

import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { ProductService } from '@arrivage-products/services/product.service';
import { reportError } from '@arrivage-sentry/report-error';
import { IdService } from '@arrivage-services/id.service';
import { PictureService } from '@arrivage-services/picture.service';
import { SnackbarService } from '@arrivage-snackbar/snackbar.service';
import { getOrganization } from '@arrivage-store/context/context.selectors';
import { EntityFeedback } from '@arrivage-store/feedback/feedback-params.model';
import { createBaseEffects } from '@arrivage-store/generators';
import { FileStorage } from '@arrivage/model/dist/src/model/fileStorage';

import * as productsActions from '../store/products.actions';
import {
  addFailure,
  addWithPicture,
  updateFailure,
  updateSuccess,
  updateWithPicture,
} from '../store/products.actions';
import * as productsSelectors from '../store/products.selectors';
import { ProductsState, State } from './products.state';

interface ProductFeedback extends EntityFeedback {
  update_with_picture: string;
  add_with_picture: string;
  duplicate: string;
  duplicate_product_with_format: string;
}

export const ProductFeedback: ProductFeedback = {
  update: 'update_product',
  update_with_picture: 'update_product_with_picture',
  add: 'add_product',
  add_with_picture: 'add_product_with_picture',
  remove: 'remove_product',
  duplicate: 'duplicate_product',
  duplicate_product_with_format: 'duplicate_product_with_format',
  //Error only
  get_active_item: 'get_active_product',
  query: 'query_all_products',
};
@Injectable()
export class ProductsEffects {
  query$;
  add$;
  update$;
  remove$;
  getActiveItem$;
  displayQueryFailure$;
  displayGetActiveItemFailure$;

  constructor(
    private actions$: Actions,
    private store: Store<State & ProductsState>,
    private productService: ProductService,
    private pictureService: PictureService,
    private idService: IdService,
    snackbarService: SnackbarService
  ) {
    ({
      query: this.query$,
      add: this.add$,
      update: this.update$,
      remove: this.remove$,
      getActiveItem: this.getActiveItem$,
      displayQueryFailure: this.displayQueryFailure$,
      displayGetActiveItemFailure: this.displayGetActiveItemFailure$,
    } = createBaseEffects(
      this.actions$,
      this.store,
      productsActions,
      productsSelectors,
      this.productService,
      ProductFeedback,
      snackbarService
    ));
  }

  updateWithPicture$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateWithPicture),
      withLatestFrom(this.store.select(getOrganization)),
      switchMap(([update, organization]) => {
        let updateFilesUrl =
          update.record.product?.filesUrl?.length > 0
            ? _.filter(update.record.product?.filesUrl, (f) =>
                f.url.includes('firebasestorage')
              )
            : [];

        const newPictureFile = update.record.newPictureFile
          ? this.addWithNewPictureFile(
              update.record.newPictureFile,
              organization.id
            )
          : update.record.newPictureFile === null
            ? Promise.resolve('null')
            : Promise.resolve('undefined');

        const newFiles =
          update.record.filesToAdd && update.record.filesToAdd.length > 0
            ? this.addWithNewFiles(update.record.filesToAdd, organization.id)
            : (Promise.resolve([]) as Promise<FileStorage[]>);

        return from(
          Promise.all([newPictureFile, newFiles]).then(async () => {
            const mainPhotoUrl = await newPictureFile;
            const filesUrl = await newFiles;

            const newProduct = {
              ...update.record.product,
            };

            switch (mainPhotoUrl) {
              case 'undefined':
                break;
              case 'null':
                newProduct.mainPhotoUrl = null;
                break;
              default:
                newProduct.mainPhotoUrl = mainPhotoUrl;
                if (newProduct.photoUrl) {
                  newProduct.photoUrl = [...newProduct.photoUrl, mainPhotoUrl];
                } else {
                  newProduct.photoUrl = [mainPhotoUrl];
                }
                break;
            }

            if (filesUrl.length > 0) {
              if (updateFilesUrl.length > 0) {
                for (const file of filesUrl) {
                  updateFilesUrl.push(file);
                }
              } else {
                updateFilesUrl = filesUrl;
              }
            }

            newProduct.filesUrl = updateFilesUrl;

            return this.productService.update(
              organization.id,
              newProduct.id,
              newProduct
            );
          })
        ).pipe(
          map(() => {
            update.confirmation.resolve(update.record.product.id);
            return updateSuccess({ id: update.record.product.id });
          }),
          catchError((error) => {
            update.confirmation.reject(error);
            return of(
              updateFailure({
                record: update.record.product,
                errorMessage: error,
              })
            );
          })
        );
      })
    )
  );

  addWithPicture$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addWithPicture),
      withLatestFrom(this.store.select(getOrganization)),
      switchMap(([add, organization]) => {
        const newPictureFile = add.record.newPictureFile
          ? this.addWithNewPictureFile(
              add.record.newPictureFile,
              organization.id
            )
          : Promise.resolve('');

        const newFiles =
          add.record.newFiles.length > 0
            ? this.addWithNewFiles(add.record.newFiles, organization.id)
            : (Promise.resolve([]) as Promise<FileStorage[]>);

        return from(
          Promise.all([newPictureFile, newFiles]).then(async () => {
            const mainPhotoUrl = await newPictureFile;
            const filesUrl = await newFiles;

            const newProduct = {
              ...add.record.product,
            };

            if (mainPhotoUrl) {
              newProduct.mainPhotoUrl = mainPhotoUrl;
              if (newProduct.photoUrl) {
                newProduct.photoUrl.push(mainPhotoUrl);
              } else {
                newProduct.photoUrl = [mainPhotoUrl];
              }
            }

            if (filesUrl.length > 0) {
              newProduct.filesUrl = filesUrl;
            }

            return this.productService.create(organization.id, newProduct);
          })
        ).pipe(
          map((idProduct) => {
            add.confirmation.resolve(idProduct);
            return updateSuccess({ id: idProduct });
          }),
          catchError((e) => {
            reportError(e);
            add.confirmation.reject(e);
            return of(
              addFailure({
                record: add.record.product,
                errorMessage: e,
              })
            );
          })
        );
      })
    )
  );

  private async addWithNewPictureFile(
    newPictureFile: Blob,
    organizationId: string
  ): Promise<string> {
    return await this.pictureService.uploadPicture(
      newPictureFile,
      'organizations/' +
        organizationId +
        '/products/' +
        this.idService.createId()
    );
  }

  private async addWithNewFiles(
    newFiles: Blob[],
    organizationId: string
  ): Promise<FileStorage[]> {
    return Promise.all(
      newFiles.map(async (f) => {
        const url = await this.pictureService.uploadPicture(
          f,
          'organizations/' +
            organizationId +
            '/products/' +
            this.idService.createId()
        );
        return { type: f.type, url: url };
      })
    );
  }
}
