import * as _ from 'lodash';
import { from, merge, Observable, pipe } from 'rxjs';

import {
  addDoc,
  collection,
  collectionChanges,
  collectionData,
  deleteDoc,
  doc,
  docSnapshots,
  DocumentData,
  DocumentSnapshot,
  Firestore,
  FirestoreDataConverter,
  getDocs,
  limit,
  query,
  QueryConstraint,
  setDoc,
  updateDoc,
  writeBatch,
} from '@angular/fire/firestore';

import { filter, map, take } from 'rxjs/operators';

import { removeIdFromRecord } from '@arrivage-store/generators';
import { WithId } from '@arrivage/model/dist/src/model';

import {
  EMPTY_COLLECTION_CHANGE_ACTION,
  EntityChangeAction,
  toEntityChangeActions,
} from './organization-entity.service';

export interface PathSpec {
  collection: string;
  id?: string;
}

export abstract class BaseService<T> {
  constructor(protected firestore: Firestore) {}

  protected _create(pathSpec: PathSpec[], record: T): Promise<string> {
    try {
      return addDoc(
        collection(this.firestore, this.buildPath(pathSpec)),
        record
      ).then((ref) => ref.id);
    } catch (e) {
      return Promise.reject(e);
    }
  }

  protected _set(
    pathSpec: PathSpec[],
    recordId: string,
    record: T
  ): Promise<void> {
    try {
      return setDoc(
        doc(this.firestore, this.buildPath(pathSpec), recordId),
        record
      );
    } catch (e) {
      return Promise.reject(e);
    }
  }

  protected _update(
    pathSpec: PathSpec[],
    recordId: string,
    record: Partial<T>
  ): Promise<void> {
    try {
      return updateDoc(
        doc(this.firestore, this.buildPath(pathSpec), recordId),
        record as Partial<DocumentData>
      );
    } catch (e) {
      return Promise.reject(e);
    }
  }

  protected _updateMany(
    pathSpec: PathSpec[],
    records: (Partial<T> & WithId)[]
  ) {
    const batch = writeBatch(this.firestore);
    _.forEach(records, (r) =>
      batch.update(
        doc(this.firestore, this.buildPath(pathSpec), r.id),
        removeIdFromRecord(r) as Partial<DocumentData>
      )
    );
    return batch.commit();
  }

  protected _get(
    pathSpec: PathSpec[],
    id: string,
    dataMapper: (data) => T = (data) => data,
    noCache: boolean = false
  ): Observable<T & WithId> {
    return docSnapshots(doc(this.firestore, this.buildPath(pathSpec), id)).pipe(
      filter((snapshot) => (noCache ? !snapshot.metadata.fromCache : true)),
      this.fromDocumentSnapshot(dataMapper)
    );
  }

  protected _connect(
    pathSpec: PathSpec[],
    dataMapper: (data) => T = (data) => data,
    converter: FirestoreDataConverter<T>,
    ...filters: QueryConstraint[]
  ): Observable<EntityChangeAction<T & WithId>[]> {
    return merge(
      // connect for entity changes
      collectionChanges(
        query(
          converter
            ? collection(
                this.firestore,
                this.buildPath(pathSpec)
              ).withConverter(converter)
            : collection(this.firestore, this.buildPath(pathSpec)),
          ...filters
        )
      ).pipe(toEntityChangeActions(dataMapper)),
      // since getting entity changes doesn't allow us to detect empty collections,
      // we query a second time with a limit 1/take 1 to check if the collection is empty
      // and trigger a empty collection action if that is the case
      from(
        getDocs(
          filters && filters.length > 0
            ? query(
                collection(this.firestore, this.buildPath(pathSpec)),
                ...filters,
                limit(1)
              )
            : query(
                collection(this.firestore, this.buildPath(pathSpec)),
                limit(1)
              )
        )
      ).pipe(
        take(1),
        map((d) => {
          if (d.empty) {
            return [EMPTY_COLLECTION_CHANGE_ACTION];
          } else {
            // not empty, the entity change query will return data so do nothing here
            return [];
          }
        })
      )
    );
  }

  protected _list(
    pathSpec: PathSpec[],
    dataMapper: (data) => T = (data) => data,
    ...filters: QueryConstraint[]
  ): Observable<(T & WithId)[]> {
    return collectionData(
      query(collection(this.firestore, this.buildPath(pathSpec)), ...filters),
      { idField: 'id' }
    ).pipe(
      map((actions) => {
        return actions.map((a) => {
          const data = dataMapper(a);
          return { ...data } as T & WithId;
        });
      })
    );
  }

  protected _delete(pathSpec: PathSpec[], recordId: string): Promise<void> {
    return deleteDoc(doc(this.firestore, this.buildPath(pathSpec), recordId));
  }

  private buildPath(pathSpec: PathSpec[]): string {
    const fragments = _.map(
      pathSpec,
      (s) => s.collection + (s.id ? '/' + s.id : '')
    );
    return _.join(fragments, '/');
  }

  private fromDocumentSnapshot<D>(
    dataMapper: (data: any) => D = (data) => data
  ) {
    return pipe(
      map((snapshot: DocumentSnapshot<any>) => {
        if (snapshot.exists()) {
          return {
            id: snapshot.id,
            ...dataMapper(snapshot.data() as D),
          };
        }
        return undefined;
      })
    );
  }
}
