import { addDays, startOfDay } from 'date-fns';
import _ from 'lodash';
import { firstValueFrom } from 'rxjs';

import { Injectable } from '@angular/core';

import { take } from 'rxjs/operators';

import { CustomerInvoiceService } from '@arrivage-invoices/customer/services/customer-invoice.service';
import { VendorInvoiceService } from '@arrivage-invoices/vendor/services/vendor-invoice.service';
import { RelationshipInfosService } from '@arrivage-services/relationship-infos/relationship-infos.service';
import { LangUtils } from '@arrivage-util/lang.utils';
import {
  Invoice,
  InvoiceStatus,
  Organization,
  OrganizationSettings,
  PaymentType,
  RelationshipInfo,
  WithId,
} from '@arrivage/model/dist/src/model';

import { InvoicesUtils } from '../util/invoices.utils';
import { InvoiceApiService } from './invoice-api.service';

@Injectable({
  providedIn: 'root',
})
export class InvoiceLogicService {
  constructor(
    private invoiceApi: InvoiceApiService,
    private vendorInvoiceService: VendorInvoiceService,
    private customerInvoiceService: CustomerInvoiceService,
    private relationshipInfoService: RelationshipInfosService
  ) {}

  cancelInvoice(
    organizationId: string,
    invoice: Invoice & WithId
  ): Promise<void> {
    if (
      invoice.status === InvoiceStatus.DRAFT ||
      InvoicesUtils.isPublished(invoice.status)
    ) {
      return this.vendorInvoiceService.update(organizationId, invoice.id, {
        status: InvoiceStatus.CANCELED,
        history: {
          ...invoice.history,
          canceledDate: new Date(),
        },
      });
    } else {
      return Promise.resolve();
    }
  }

  resetInvoice(
    organizationId: string,
    invoice: Invoice & WithId,
    organizationSettings: OrganizationSettings
  ): Promise<void> {
    if (invoice.status !== InvoiceStatus.CANCELED) return Promise.resolve();

    const updatedInvoice: Invoice = _.cloneDeep(invoice);
    updatedInvoice.status = InvoiceStatus.DRAFT;

    delete updatedInvoice.history.canceledDate;
    if (updatedInvoice.history.sentDate) {
      delete updatedInvoice.history.sentDate;
    }
    if (updatedInvoice.history.publishDate) {
      delete updatedInvoice.history.publishDate;
    }
    if (updatedInvoice.pdf) {
      delete updatedInvoice.pdf;
    }

    updatedInvoice.paymentMethods = organizationSettings.sales.paymentMethods;
    updatedInvoice.paymentInstructions =
      organizationSettings.sales.paymentInstructions;

    return this.vendorInvoiceService.set(
      organizationId,
      invoice.id,
      updatedInvoice
    );
  }

  markAsPaymentEmitted(
    organizationId: string,
    invoice: Invoice & WithId
  ): Promise<void> {
    if (InvoicesUtils.isPublished(invoice.status)) {
      return this.vendorInvoiceService.update(organizationId, invoice.id, {
        paymentType: PaymentType.OFFLINE,
        status: InvoiceStatus.PAYMENT_EMITTED,
        history: {
          ...invoice.history,
          paymentEmittedDate: new Date(),
        },
      });
    } else {
      return Promise.resolve();
    }
  }

  /**
   * Load invoices according to a purchase order id list
   * @param purchaseOrderIds
   * @returns An array of Invoice & WithId
   */
  async getRelatedInvoices(
    purchaseOrderIds: string[]
  ): Promise<(Invoice & WithId)[]> {
    return await firstValueFrom(
      this.customerInvoiceService.listRelatedInvoices(purchaseOrderIds)
    );
  }

  async markInvoiceAsPaid(
    organizationId: string,
    invoice: Invoice & WithId
  ): Promise<void> {
    if (
      invoice.status === InvoiceStatus.DRAFT ||
      InvoicesUtils.isPublished(invoice.status) ||
      invoice.status === InvoiceStatus.PAYMENT_EMITTED
    ) {
      const update: Partial<Invoice> = {
        status: InvoiceStatus.PAID,
        history: {
          ...invoice.history,
          paidDate: new Date(),
        },
      };

      return this.vendorInvoiceService
        .update(organizationId, invoice.id, update)
        .then(async () => {
          const pdfDataUpdate: Partial<Invoice> = {
            pdf: {
              link: await this.invoiceApi.generateInvoice(invoice.id),
              creationDate: new Date(),
            },
          };
          return this.vendorInvoiceService.update(
            organizationId,
            invoice.id,
            pdfDataUpdate
          );
        });
    } else {
      return Promise.resolve();
    }
  }

  updateInvoice(
    organization: Organization & WithId,
    invoice: Invoice & WithId
  ): Promise<void> {
    switch (invoice.status) {
      case InvoiceStatus.DRAFT:
        return this.vendorInvoiceService.set(
          organization.id,
          invoice.id,
          invoice
        );
      case InvoiceStatus.PUBLISHED:
      case InvoiceStatus.SENT:
        const modifiedInvoice = _.cloneDeep(invoice);
        // set the status back to DRAFT, reinitialize sent date and pdf link
        modifiedInvoice.status = InvoiceStatus.DRAFT;
        delete modifiedInvoice.history.sentDate;
        delete modifiedInvoice.history.publishDate;
        delete modifiedInvoice.pdf;

        return this.vendorInvoiceService.set(
          organization.id,
          invoice.id,
          modifiedInvoice
        );
      default:
        return Promise.reject(`Can not update a invoice in ${invoice.status}`);
    }
  }

  async sendInvoice(
    organization: Organization & WithId,
    invoice: Invoice & WithId,
    organizationSettings: OrganizationSettings,
    messageToCustomers: string
  ): Promise<string> {
    // do not send invoice if cancelled invoice
    if (invoice.status === InvoiceStatus.CANCELED) {
      return Promise.resolve(
        `Cannot send an invoice in the ${invoice.status} status.`
      );
    }

    const invoiceUpdate = {};
    if (!invoice.dueDate) {
      // compute the due date based on the terms in settings and relationship info
      const relationshipInfo = await this.relationshipInfoService
        .getRelationshipInfoByRelationshipId(
          invoice.relationshipId,
          organization.id
        )
        .pipe(take(1))
        .toPromise();

      const paymentTerms = LangUtils.nullOrUndefined(invoice.paymentTermsInDays)
        ? this.getPaymentTermsInDays(organizationSettings, relationshipInfo)
        : invoice.paymentTermsInDays;

      // set the due date according to deliveryDate, if not, use today
      const dueDate = addDays(
        startOfDay(invoice.deliveryDate ?? new Date()),
        paymentTerms
      );
      invoiceUpdate['dueDate'] = dueDate;
    }

    // set the sent date
    invoiceUpdate['history.sentDate'] = new Date();

    await this.vendorInvoiceService.update(
      organization.id,
      invoice.id,
      invoiceUpdate
    );

    const pdfData: Invoice['pdf'] = {
      link: await this.invoiceApi.generateInvoice(invoice.id),
      creationDate: new Date(),
    };

    const invoiceWithLink: Invoice & WithId = {
      ...invoice,
      pdf: pdfData,
    };

    return this.invoiceApi
      .sendInvoice(organization, invoiceWithLink, messageToCustomers)
      .then((data) => {
        return this.vendorInvoiceService
          .update(organization.id, invoice.id, {
            pdf: pdfData,
          })
          .then((d) => pdfData.link);
      });
  }

  async publishInvoice(
    organization: Organization & WithId,
    invoice: Invoice & WithId
  ): Promise<string> {
    // do not publish invoice if already cancelled invoice
    if (invoice.status === InvoiceStatus.CANCELED) {
      return Promise.resolve(
        `Cannot publish an invoice in the ${invoice.status} status.`
      );
    }

    // set the publish date
    await this.vendorInvoiceService.update(organization.id, invoice.id, {
      status:
        invoice.status === InvoiceStatus.DRAFT ||
        invoice.status === InvoiceStatus.SENT
          ? InvoiceStatus.PUBLISHED
          : invoice.status,
      'history.publishDate': new Date(),
    } as any as Partial<Invoice>);

    return Promise.resolve(`Invoice successfully published`);
  }

  async generatePDF(invoiceId: string, organizationId: string) {
    const url = await this.invoiceApi.generateInvoice(invoiceId);
    const pdfData = {
      link: url,
      creationDate: new Date(),
    };
    const update: Partial<Invoice> = {
      pdf: pdfData,
    };

    await this.vendorInvoiceService.update(organizationId, invoiceId, update);

    return url;
  }

  async generateMultiplePDF(invoicesId: string[]) {
    return await this.invoiceApi.generateMultipleInvoices(invoicesId);
  }

  getPaymentTermsInDays(
    organizationSettings: OrganizationSettings,
    relationshipInfo: RelationshipInfo
  ): number {
    if (relationshipInfo.paymentTermsInDays) {
      return relationshipInfo.paymentTermsInDays;
    } else if (
      organizationSettings.sales &&
      organizationSettings.sales.paymentTermsInDays
    ) {
      return organizationSettings.sales.paymentTermsInDays;
    }
    return 0;
  }
}
