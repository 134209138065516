import { Observable } from 'rxjs';

import { Firestore, QueryConstraint, Timestamp } from '@angular/fire/firestore';

import { BaseService } from '@arrivage-services/base.service';
import {
  EntityChangeAction,
  OrganizationEntityService,
} from '@arrivage-services/organization-entity.service';
import { ContactRequest, WithId } from '@arrivage/model/dist/src/model';
import { CollectionNames } from '@arrivage/model/dist/src/utils';

export abstract class BaseContactRequestService
  extends BaseService<ContactRequest>
  implements OrganizationEntityService<ContactRequest>
{
  static readonly CONTACT_REQUEST_COLLECTION =
    CollectionNames.CONTACT_REQUESTS_COLLECTION;

  private readonly PATH = [
    { collection: BaseContactRequestService.CONTACT_REQUEST_COLLECTION },
  ];

  private static convertTimestampFields(
    firebaseContactRequest: any
  ): ContactRequest {
    if (!firebaseContactRequest) {
      return undefined;
    }

    const contactRequest: ContactRequest = {
      ...firebaseContactRequest,
    };

    if (
      firebaseContactRequest.history &&
      firebaseContactRequest.history.creationDate
    ) {
      firebaseContactRequest.history.creationDate = (
        firebaseContactRequest.history.creationDate as any as Timestamp
      ).toDate();
    }
    if (
      firebaseContactRequest.history &&
      firebaseContactRequest.history.acceptationDate
    ) {
      contactRequest.history.acceptationDate = (
        firebaseContactRequest.history.acceptationDate as any as Timestamp
      ).toDate();
    }
    if (
      firebaseContactRequest.history &&
      firebaseContactRequest.history.declinedDate
    ) {
      contactRequest.history.declinedDate = (
        firebaseContactRequest.history.declinedDate as any as Timestamp
      ).toDate();
    }
    return contactRequest;
  }

  constructor(
    firestore: Firestore,
    private connectFilter: (string) => QueryConstraint[]
  ) {
    super(firestore);
  }

  connect(
    organizationId: string
  ): Observable<EntityChangeAction<ContactRequest>[]> {
    return this._connect(
      this.PATH,
      this.toContactRequest,
      null,
      ...this.connectFilter(organizationId)
    );
  }

  create(organizationId: string, record: ContactRequest): Promise<string> {
    return this._create(this.PATH, record);
  }

  get(id: string): Observable<ContactRequest & WithId> {
    return this._get(this.PATH, id, this.toContactRequest);
  }

  set(
    organizationId: string,
    recordId: string,
    record: ContactRequest
  ): Promise<void> {
    throw new Error('Method not implemented.');
  }

  update(
    organizationId: string,
    recordId: string,
    record: Partial<ContactRequest>
  ): Promise<void> {
    return this._update(this.PATH, recordId, record);
  }

  remove(organizationId: string, recordId: string): Promise<void> {
    throw new Error('Method not implemented.');
  }

  private toContactRequest(doc: any): ContactRequest {
    return {
      ...BaseContactRequestService.convertTimestampFields(doc),
    };
  }
}
