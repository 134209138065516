import { of } from 'rxjs';

import { Injectable } from '@angular/core';

import { catchError, map, switchMap } from 'rxjs/operators';

import { Actions, createEffect, ofType } from '@ngrx/effects';

import { reportError } from '@arrivage-sentry/report-error';

import { PremiumAccountService } from '../services/premium-account.service';
import {
  getPremiumAccount,
  getPremiumAccountFailure,
  getPremiumAccountSuccess,
} from './premium-accounts.actions';

@Injectable()
export class PremiumAccountEffects {
  constructor(
    private actions$: Actions,
    private premiumAccountService: PremiumAccountService
  ) {}

  getPremiumAccountEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(getPremiumAccount),
      switchMap((action) => {
        return this.premiumAccountService.getPremiumAccount(action.id).pipe(
          map((a) => getPremiumAccountSuccess({ premiumAccount: a })),
          catchError((e) => {
            reportError(e);
            return of(getPremiumAccountFailure({ error: e }));
          })
        );
      })
    );
  });
}
