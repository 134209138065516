import {
  Directive,
  EventEmitter,
  Input,
  Optional,
  Output,
} from '@angular/core';

export interface FormDirtiness {
  [id: string]: boolean;
}

@Directive({
  selector: '[appFormIsDirty]',
})
export class FormIsDirtyDirective {
  private formsDirtiness: FormDirtiness;

  @Output()
  isDirtyChanges = new EventEmitter<boolean>();

  constructor() {
    this.formsDirtiness = {};
  }

  onDirtyChanges() {
    this.isDirtyChanges.emit(Object.values(this.formsDirtiness).some((f) => f));
  }

  registerForm(id: string, dirty: boolean) {
    this.formsDirtiness[id] = dirty;
  }

  setDirtiness(id: string, dirty: boolean) {
    this.formsDirtiness[id] = dirty;
    this.onDirtyChanges();
  }
}

@Directive({
  selector: '[appRegisterForm]',
})
export class RegisterFormDirective {
  private _parent: FormIsDirtyDirective;
  private _appRegisterForm: string;

  @Input()
  set appRegisterForm(id: string) {
    this._appRegisterForm = id;
    if (this._parent) {
      this._parent.registerForm(id, false);
    }
  }

  @Input()
  set checkDirty(value: boolean) {
    if (this._parent && this._appRegisterForm) {
      // the value we get is from the template when it renders
      // since progagating the dirtiness flag will most likely update other parts of the UI
      // we need to make that update asynchronous otherwise angular won't like it
      // and trigger Expression has changed after it was checked (Default) or won't update
      // the related UI part (OnPush)
      setTimeout(() => {
        this._parent.setDirtiness(this._appRegisterForm, value);
      });
    }
  }

  constructor(@Optional() parent: FormIsDirtyDirective) {
    if (parent) {
      this._parent = parent;
    }
  }
}
