import { Observable } from 'rxjs';

import { Injectable } from '@angular/core';
import { Firestore } from '@angular/fire/firestore';

import { BaseService, PathSpec } from '@arrivage-services/base.service';
import { UserSettings } from '@arrivage/model/dist/src/model';
import { CollectionNames } from '@arrivage/model/dist/src/utils';

@Injectable({
  providedIn: 'root',
})
export class UserSettingsService extends BaseService<UserSettings> {
  static readonly USER_COLLECTION = CollectionNames.USER_COLLECTION;
  static readonly USER_SETTINGS_COLLECTION_NAME =
    CollectionNames.USER_SETTINGS_COLLECTION;
  static readonly USER_SETTINGS_ID = 'USER_SETTINGS';

  constructor(firestore: Firestore) {
    super(firestore);
  }

  get(userId: string): Observable<UserSettings> {
    return this._get(this.pathSpec(userId), 'USER_SETTINGS');
  }

  update(
    userId: string,
    settingsId: string,
    record: Partial<UserSettings>
  ): Promise<void> {
    return this._update(this.pathSpec(userId), settingsId, record);
  }

  private pathSpec(userId: string): PathSpec[] {
    return [
      {
        collection: UserSettingsService.USER_COLLECTION,
        id: userId,
      },
      {
        collection: UserSettingsService.USER_SETTINGS_COLLECTION_NAME,
      },
    ];
  }
}
