<ng-container
  *transloco="let t; scope: 'purchaseReport'; read: 'purchaseReport'"
>
  <div class="mat-dialog-content" fxLayoutGap="4rem" fxLayout="column">
    <h2 class="mat-dialog-title space-bottom">
      {{ t('filters-dialog.title') }}
    </h2>
    <div
      class="mat-typography mat-dialog-content no-bold"
      fxLayoutGap="2rem"
      fxLayout="column"
    >
      <div>
        <span>{{ t('filters-dialog.message.first-part') }}</span>
        <span class="bold">{{ t('filters-dialog.message.second-part') }}</span>
        <span>{{ t('filters-dialog.message.third-part') }}</span>
      </div>

      <app-filtered-date-range-picker
        [pageName]="'filtered-date-range-picker-dialog'"
        [dateRangePickerLabel]="t('filters.order-period')"
        [defaultDateRangeOption]="DEFAULT_DATE_RANGE_OPTION"
        [defaultDateType]="DEFAULT_DATE_TYPE"
        [fillSpace]="true"
        (dateRangeChanges)="onDateRangeChanges($event)"
        (dateTypeChanges)="onDateTypeChanges($event)"
      ></app-filtered-date-range-picker>
    </div>
  </div>
  <mat-dialog-actions align="end">
    <button mat-button [mat-dialog-close]="false">
      {{ t('filters-dialog.cancel-button') }}
    </button>

    <button mat-raised-button color="accent" (click)="confirm()">
      <span>{{ t('filters-dialog.confirm-button') }}</span>
    </button>
  </mat-dialog-actions>
</ng-container>
