import { addYears, startOfToday } from 'date-fns';

import { Component, Inject } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { Translatable } from '@arrivage/model/dist/src/model';

export interface OfferExpirationDialogComponentData {
  expirationDate: Date;
  offerMessage?: Translatable;
}

@Component({
  selector: 'app-offer-expiration-dialog',
  templateUrl: './offer-expiration-dialog.component.html',
  styleUrls: ['./offer-expiration-dialog.component.scss'],
})
export class OfferExpirationDialogComponent {
  minDate = startOfToday();
  maxDate = addYears(this.minDate, 1);

  form = this.fb.group({
    expirationDate: [
      this.data ? this.data.expirationDate : null,
      Validators.required,
    ],
  });

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: OfferExpirationDialogComponentData,
    public dialogRef: MatDialogRef<OfferExpirationDialogComponent>,
    private fb: UntypedFormBuilder
  ) {}

  shareOffer() {
    if (this.form.valid) {
      this.dialogRef.close({
        expirationDate: this.form.get('expirationDate').value,
      });
    }
  }
}
