import { TranslocoModule } from '@jsverse/transloco';
import { MaterialModule } from 'src/app/material.module';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';

import { UnsavedChangesDialogComponent } from './unsaved-changes-dialog.component';

@NgModule({
  declarations: [UnsavedChangesDialogComponent],
  imports: [CommonModule, MaterialModule, FlexLayoutModule, TranslocoModule],
  exports: [UnsavedChangesDialogComponent],
})
export class UnsavedChangesDialogModule {}
