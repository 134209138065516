import { ShareButtonsConfig } from 'ngx-sharebuttons';
import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import { ShareIconsModule } from 'ngx-sharebuttons/icons';
import { AppUtilsModule } from 'src/app/app-utils.module';
import { MaterialModule } from 'src/app/material.module';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';

import { TranslocoModule } from '@jsverse/transloco';

import { ShareButtonsDialogComponent } from './share-buttons-dialog/share-buttons-dialog.component';
import { ShareButtonsComponent } from './share-buttons.component';

export const customShareButtonsConfig: ShareButtonsConfig = {
  include: ['sms', 'email', 'whatsapp', 'messenger', 'copy'],
  gaTracking: true,
  autoSetMeta: true,
};

@NgModule({
  declarations: [ShareButtonsComponent, ShareButtonsDialogComponent],
  imports: [
    CommonModule,
    MaterialModule,
    AppUtilsModule,
    FlexLayoutModule,
    ShareButtonsModule,
    ShareIconsModule,
    TranslocoModule,
  ],
  exports: [ShareButtonsComponent],
})
export class AppShareButtonsModule {}
