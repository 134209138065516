import {
  subDays,
  isAfter,
  isBefore,
  isWithinInterval,
  subMonths,
  endOfDay,
  areIntervalsOverlapping,
  clamp,
  isValid,
  startOfDay,
  addDays,
} from 'date-fns';
import _ from 'lodash';

export namespace DateRangeLogic {
  const INITIAL_SPAN_DAY = 30;
  const today = new Date(Date.now());

  export const INITIAL_DATE_RANGE: Interval = {
    start: startOfDay(subDays(today, INITIAL_SPAN_DAY)),
    end: endOfDay(today),
  };

  export const NEXT_90_DAYS_DATE_RANGE: Interval = {
    start: startOfDay(today),
    end: endOfDay(addDays(today, 90)),
  };

  export const LAST_30_DAYS_DATE_RANGE: Interval = {
    start: startOfDay(subDays(today, 30)),
    end: endOfDay(today),
  };

  export const LAST_60_DAYS_DATE_RANGE: Interval = {
    start: startOfDay(subDays(today, 60)),
    end: endOfDay(today),
  };

  export const LAST_90_DAYS_DATE_RANGE: Interval = {
    start: startOfDay(subDays(today, 90)),
    end: endOfDay(today),
  };

  export const LAST_6_MONTHS_DATE_RANGE: Interval = {
    start: startOfDay(subMonths(today, 6)),
    end: endOfDay(today),
  };

  export const LAST_12_MONTHS_DATE_RANGE: Interval = {
    start: startOfDay(subMonths(today, 12)),
    end: endOfDay(today),
  };

  export function getNewDateRange(dates: {
    currentDateRange: Interval;
    newDateRange: Interval;
  }): Interval {
    let interval: Interval;
    if (dates.currentDateRange && dates.newDateRange) {
      const dateRangeInsideCurrent =
        isWithinInterval(dates.newDateRange.start, dates.currentDateRange) &&
        isWithinInterval(dates.newDateRange.end, dates.currentDateRange);

      if (!dateRangeInsideCurrent) {
        interval = getDateExpansion(dates.currentDateRange, dates.newDateRange);
      }
    } else if (dates.newDateRange) {
      interval = dates.newDateRange;
    }
    return interval;
  }

  function getDateExpansion(
    currentDateRange: Interval,
    newDateRange: Interval
  ): Interval {
    const newInterval: Interval = {
      start: undefined,
      end: undefined,
    };
    if (isBefore(newDateRange.start, currentDateRange.start)) {
      newInterval.start = newDateRange.start;
    } else {
      newInterval.start = currentDateRange.start;
    }
    if (isAfter(newDateRange.end, currentDateRange.end)) {
      newInterval.end = newDateRange.end;
    } else {
      newInterval.end = currentDateRange.end;
    }
    return newInterval;
  }

  /**
   * Get missing date ranges to query.
   * @param storedDateRanges date ranges stored in the database
   * @param newDateRange date range selected by the user
   * @returns the missing date ranges to query
   */
  export function getDateRangesToQuery(
    storedDateRanges: Interval[],
    newDateRange: Interval
  ): Interval[] {
    if (!isIntervalValid(newDateRange)) return [];
    if (!storedDateRanges) return [newDateRange];
    return invertDateRanges(storedDateRanges, newDateRange);
  }

  /**
   * Format date ranges:
   *   - filter out invalid date ranges
   *   - sort by start date
   *   - merge overlapping date ranges
   * @param {Interval[]} dateRanges
   * @returns {Interval[]} formatted date ranges
   */
  export function formatDateRanges(dateRanges: Interval[]): Interval[] {
    return _(dateRanges)
      .filter((dateRange) => {
        return isIntervalValid(dateRange);
      })
      .sort((a, b) => {
        return isBefore(a.start, b.start) ? -1 : 1;
      })
      .reduce((formattedDateRanges: Interval[], currentDateRange: Interval) => {
        if (formattedDateRanges.length === 0) return [currentDateRange];
        const previousDateRange = formattedDateRanges.pop();
        if (
          areIntervalsOverlapping(previousDateRange, currentDateRange, {
            inclusive: true,
          }) &&
          !isContained(currentDateRange, previousDateRange)
        ) {
          formattedDateRanges.push({
            start: previousDateRange.start,
            end: currentDateRange.end,
          });
        } else if (
          !areIntervalsOverlapping(previousDateRange, currentDateRange, {
            inclusive: true,
          })
        ) {
          formattedDateRanges.push(previousDateRange, currentDateRange);
        } else {
          formattedDateRanges.push(previousDateRange);
        }
        return formattedDateRanges;
      }, []);
  }

  /**
   * Clamp date ranges inside a given interval.
   *
   * Example:
   *   dateRanges: |--------|    |-----|      |-----|
   *   interval:        |-----------------|
   *
   *   clamped:         |---|    |-----|
   *
   * @param {Interval[]} dateRanges
   * @param {Interval} interval
   * @returns {Interval[]} clamped date ranges
   */
  export function clampDateRanges(
    dateRanges: Interval[],
    interval: Interval
  ): Interval[] {
    return _(formatDateRanges(dateRanges))
      .map((dateRange) => ({
        start: clamp(dateRange.start, interval),
        end: clamp(dateRange.end, interval),
      }))
      .filter((dateRange) => {
        return isIntervalValid(dateRange);
      })
      .value();
  }

  /**
   * Invert date ranges inside a given interval.
   *
   * Example:
   *   dateRanges: |--------|    |-----|      |-----|
   *   interval:        |-----------------|
   *
   *   clamped:         |---|    |-----|
   *
   *   inverted:            |----|     |--|
   *
   * @param {Interval[]} dateRanges
   * @param {Interval} interval
   * @returns {Interval[]} inverted date ranges
   */
  export function invertDateRanges(
    dateRanges: Interval[],
    interval: Interval
  ): Interval[] {
    const clampedDateRanges = clampDateRanges(dateRanges, interval);

    if (clampedDateRanges.length === 0) return [interval];

    const invertedDateRanges: Interval[] = [];

    // if first date range starts after interval start
    if (isAfter(clampedDateRanges[0].start, interval.start)) {
      invertedDateRanges.push({
        start: interval.start,
        end: clampedDateRanges[0].start,
      });
    }

    // invert date ranges inside interval
    for (let i = 0; i < clampedDateRanges.length - 1; i++) {
      invertedDateRanges.push({
        start: clampedDateRanges[i].end,
        end: clampedDateRanges[i + 1].start,
      });
    }

    // if last date range ends before interval end
    if (isBefore(clampedDateRanges.slice(-1)[0].end, interval.end)) {
      invertedDateRanges.push({
        start: clampedDateRanges.slice(-1)[0].end,
        end: interval.end,
      });
    }

    return invertedDateRanges;
  }

  /**
   * Verify if the dates of an interval are valid and if the start date is before the end date.
   * @param {Interval} interval
   * @returns {boolean}
   */
  export function isIntervalValid(interval: Interval): boolean {
    return (
      !!interval &&
      isValid(interval.start) &&
      isValid(interval.end) &&
      isBefore(interval.start, interval.end)
    );
  }

  /**
   * Determines if an interval is contained into another
   * @param {Interval} firstInterval
   * @param {Interval} secondInterval
   * @returns {boolean}
   */
  export function isContained(
    containedInterval: Interval,
    containerInterval: Interval
  ): boolean {
    return (
      isWithinInterval(containedInterval.start, containerInterval) &&
      isWithinInterval(containedInterval.end, containerInterval)
    );
  }
}
