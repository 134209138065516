import { createAction, props } from '@ngrx/store';

import { CreditAccount, WithId } from '@arrivage/model/dist/src/model';

export const query = createAction(`[CreditAccount] Query`);
export const querySuccess = createAction(
  `[CreditAccount] Query success`,
  props<{ creditAccount: CreditAccount & WithId }>()
);
export const queryFailure = createAction(
  `[CreditAccount] Query failure`,
  props<{ errorMessage: string }>()
);
