import _ from 'lodash';
import { of } from 'rxjs';

import { Injectable } from '@angular/core';

import {
  exhaustMap,
  takeUntil,
  mergeMap,
  map,
  catchError,
  filter,
  switchMap,
  pairwise,
  startWith,
} from 'rxjs/operators';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { Logout } from '@arrivage-auth/store/auth.actions';
import { reportError } from '@arrivage-sentry/report-error';
import { DeliveryZonesService } from '@arrivage-services/delivery-zones.service';
import { getOrganization } from '@arrivage-store/context/context.selectors';
import { State } from '@arrivage-store/state';
import { Organization, WithId } from '@arrivage/model/dist/src/model';

import { PublicOffersService } from '../services/public-offers.service';
import * as actions from './search.actions';
import { SearchState } from './search.state';

@Injectable()
export class SearchEffects {
  query$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.query),
      exhaustMap(() =>
        this.store
          .select(getOrganization)
          .pipe(startWith(undefined as Organization & WithId))
      ),
      pairwise(),
      filter(([before, after]) => this.organizationDataChanged(before, after)),
      map(([, after]) => actions.load({ organization: after }))
    )
  );

  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.load),
      switchMap((o) => {
        if (
          !o.organization.location ||
          !o.organization.customerOrganizationType
        ) {
          // no location or no customer type, no public search data
          return of(actions.connected());
        }

        const zones = _.map(
          this.deliveryZoneService.findZonesFor(o.organization.location),
          (z) => z.code
        );

        if (!zones || zones.length === 0) {
          // no zone, no data
          return of(actions.connected());
        }

        return this.service
          .connect(o.organization.customerOrganizationType, zones)
          .pipe(
            takeUntil(this.actions$.pipe(ofType(Logout))),
            mergeMap((changes) => {
              return changes;
            }),
            map((action) => {
              switch (action.type) {
                case 'empty':
                  return actions.connected();
                case 'added':
                  return actions.added({
                    records: action.entities,
                  });
                case 'modified':
                  return actions.modified({
                    records: action.entities,
                  });
                case 'removed':
                  return actions.removed({
                    records: action.entities,
                  });
              }
            }),
            catchError((e) => {
              reportError(e);
              return of(actions.queryFailure(e));
            })
          );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private store: Store<State & SearchState>,
    private service: PublicOffersService,
    private deliveryZoneService: DeliveryZonesService
  ) {}

  organizationDataChanged(
    before: Organization & WithId,
    after: Organization & WithId
  ) {
    return before === undefined
      ? after !== undefined
      : before.customerOrganizationType !== after.customerOrganizationType ||
          !_.isEqual(before.location, after.location);
  }
}
