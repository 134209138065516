import { Observable } from 'rxjs';

import { Injectable } from '@angular/core';
import { Firestore } from '@angular/fire/firestore';

import { BaseService, PathSpec } from '@arrivage-services/base.service';
import {
  EntityChangeAction,
  OrganizationEntityService,
} from '@arrivage-services/organization-entity.service';
import { Product, WithId } from '@arrivage/model/dist/src/model';
import { CollectionNames } from '@arrivage/model/dist/src/utils';

@Injectable({
  providedIn: 'root',
})
export class ProductService
  extends BaseService<Product>
  implements OrganizationEntityService<Product>
{
  static readonly ORGANIZATIONS_COLLECTION =
    CollectionNames.ORGANIZATION_COLLECTION;
  static readonly PRODUCTS_COLLECTION = CollectionNames.PRODUCT_COLLECTION;

  constructor(firestore: Firestore) {
    super(firestore);
  }

  get(organizationId: string, recordId: string): Observable<Product & WithId> {
    return this._get(this.pathSpec(organizationId), recordId);
  }

  create(organizationId: string, product: Product): Promise<string> {
    return this._create(this.pathSpec(organizationId), product);
  }

  set(
    organizationId: string,
    recordId: string,
    record: Product
  ): Promise<void> {
    return this._set(this.pathSpec(organizationId), recordId, record);
  }

  update(
    organizationId: string,
    productId: string,
    product: Partial<Product>
  ): Promise<void> {
    return this._update(this.pathSpec(organizationId), productId, product);
  }

  connect(organizationId: string): Observable<EntityChangeAction<Product>[]> {
    return this._connect(this.pathSpec(organizationId), (d) => d, null);
  }

  list(organizationId: string): Observable<(Product & WithId)[]> {
    return this._list(this.pathSpec(organizationId));
  }

  remove(organizationId: string, productId: string): Promise<void> {
    return this._delete(this.pathSpec(organizationId), productId);
  }

  private pathSpec(organizationId: string): PathSpec[] {
    return [
      {
        collection: ProductService.ORGANIZATIONS_COLLECTION,
        id: organizationId,
      },
      { collection: ProductService.PRODUCTS_COLLECTION },
    ];
  }
}
