import { Observable } from 'rxjs';

import { Injectable } from '@angular/core';

import { Dictionary } from '@ngrx/entity';
import { Store } from '@ngrx/store';

import { AnalyticsFacade } from '@arrivage-analytics/api/analytics.facade';
import { CustomerAccess } from '@arrivage-customer-access/customer-access.model';
import { EntitiesFacade } from '@arrivage-store/api/entities.facade';
import { State } from '@arrivage-store/state';
import {
  CreatePriceListRequestData,
  UpdatePriceListRequestData
} from '@arrivage/model/dist/src/cloud-functions-api';
import { Offer, PriceList, WithId } from '@arrivage/model/dist/src/model';

import { PriceListsDashboardMetrics } from '../model/price-list.model';
import * as actions from '../store/price-list.actions';
import * as selectors from '../store/price-list.selectors';
import { PriceListApiService } from './price-list.api.service';

@Injectable({
  providedIn: 'root',
})
export class PriceListFacade extends EntitiesFacade<PriceList, State> {
  constructor(
    store: Store<State>,
    private priceListApi: PriceListApiService,
    private analytics: AnalyticsFacade
  ) {
    super(store, actions, selectors);
  }

  override addItem(item: PriceList): Promise<string> {
    throw new Error('Use createPriceList instead');
  }

  override removeItem(itemId: string): Promise<string> {
    throw new Error('Use deletePriceList instead');
  }

  createPriceList(
    organizationId: string,
    priceList: PriceList
  ): Promise<string> {
    return this.priceListApi
      .createPriceList(organizationId, priceList)
      .then((id) => {
        this.analytics.logCreatePriceList();
        return id;
      });
  }

  createPriceListFromForm(
    createPriceListRequestData: CreatePriceListRequestData
  ): Promise<string> {
    return this.priceListApi
      .createPriceListFromForm(createPriceListRequestData)
      .then((id) => {
        this.analytics.logCreatePriceList();
        return id;
      });
  }

  updatePriceListFromForm(
    updatePriceRequestData: UpdatePriceListRequestData
  ): Promise<string> {
    return this.priceListApi
      .updatePriceListFromForm(updatePriceRequestData)
      .then((id) => {
        this.analytics.logUpdatePriceList();
        return id;
      });
  }

  deletePriceList(organizationId: string, priceListId: string): Promise<void> {
    return this.priceListApi
      .deletePriceList(organizationId, priceListId)
      .then(() => {
        this.analytics.logDeletePriceList();
      });
  }

  duplicatePriceList(
    organizationId: string,
    priceList: PriceList,
    priceListIdToDuplicate: string
  ): Promise<string> {
    return this.priceListApi
      .duplicatePriceList(organizationId, priceList, priceListIdToDuplicate)
      .then((id) => {
        this.analytics.logDuplicatePriceList();
        return id;
      });
  }

  getCurrentPriceListOffer(): Observable<Offer & WithId> {
    return this.store.select(selectors.getCurrentPriceListOffer);
  }

  getCurrentPriceListCustomersAccess(
    priceListId: string
  ): Observable<CustomerAccess.Customers> {
    return this.store.select(
      selectors.getCurrentPriceListCustomersAccess(priceListId)
    );
  }

  getAllPriceListCustomersAccess(): Observable<
    Dictionary<CustomerAccess.Customers & WithId>
  > {
    return this.store.select(selectors.getAllPriceListCustomersAccess);
  }

  getOfferByPriceList(): Observable<Dictionary<Offer & WithId>> {
    return this.store.select(selectors.getOfferByPriceList);
  }

  getPriceListFromOffer(): Observable<PriceList & WithId> {
    return this.store.select(selectors.getPriceListFromOffer);
  }

  getPriceListsMetrics(): Observable<PriceListsDashboardMetrics> {
    return this.store.select(selectors.getPriceListsMetrics);
  }
}
