import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { map, filter } from 'rxjs/operators';

import { ContextFacade } from '@arrivage-store/api/context.facade';

@Injectable({
  providedIn: 'root',
})
export class VendorGuard  {
  constructor(private contextFacade: ContextFacade, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.contextFacade.getOrganization().pipe(
      filter((x) => !!x),
      map((organization) =>
        organization.isSeller ? true : this.router.parseUrl('/')
      )
    );
  }
}
