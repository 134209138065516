import { TRANSLOCO_SCOPE, TranslocoModule } from '@jsverse/transloco';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';

import { AppUtilsModule } from '../app-utils.module';
import { MaterialModule } from '../material.module';
import { SnackbarErrorComponent } from './snackbar-error/snackbar-error.component';
import { SnackbarSuccessComponent } from './snackbar-success/snackbar-success.component';
import { CustomSnackbarComponent } from './custom-snackbar/custom-snackbar.component';

@NgModule({
  declarations: [
    SnackbarSuccessComponent,
    SnackbarErrorComponent,
    CustomSnackbarComponent,
  ],
  imports: [
    CommonModule,
    FlexLayoutModule,
    MaterialModule,
    TranslocoModule,
    AppUtilsModule,
  ],
  providers: [{ provide: TRANSLOCO_SCOPE, useValue: 'feedback' }],
})
export class SnackbarModule {}
