import { Observable } from 'rxjs';

import { Injectable } from '@angular/core';
import {
  DocumentData,
  Firestore,
  QueryDocumentSnapshot,
  SnapshotOptions,
  arrayUnion,
  doc,
  serverTimestamp,
  updateDoc,
  where,
} from '@angular/fire/firestore';

import { reportError } from '@arrivage-sentry/report-error';
import { BaseService, PathSpec } from '@arrivage-services/base.service';
import {
  EntityChangeAction,
  OrganizationEntityService,
} from '@arrivage-services/organization-entity.service';
import { DateUtils } from '@arrivage-util/date.utils';
import {
  Conversation,
  ViewedByInfo,
  WithId,
} from '@arrivage/model/dist/src/model';
import { CollectionNames } from '@arrivage/model/dist/src/utils';

@Injectable({
  providedIn: 'root',
})
export class ConversationService
  extends BaseService<Conversation>
  implements OrganizationEntityService<Conversation>
{
  static readonly CONVERSATION_COLLECTION =
    CollectionNames.CONVERSATION_COLLECTION;

  static readonly converter = {
    toFirestore(conversation: Conversation): DocumentData {
      return conversation;
    },
    fromFirestore(
      snapshot: QueryDocumentSnapshot<Conversation>,
      options: SnapshotOptions
    ): Conversation {
      try {
        const data = snapshot.data(options);
        const convertedViewedBy: {
          [organizationId: string]: ViewedByInfo;
        } = {};
        for (const key in data.viewedBy) {
          if (key) {
            convertedViewedBy[key] = {
              ...data.viewedBy[key],
              viewedOn: DateUtils.toDate(data.viewedBy[key].viewedOn),
            };
          }
        }
        const conversation = {
          ...data,
          lastMessage: data.lastMessage
            ? {
                ...data.lastMessage,
                sentAt: DateUtils.toDate(data.lastMessage.sentAt),
              }
            : null,
          viewedBy: convertedViewedBy,
        } as Conversation;
        return conversation;
      } catch (e) {
        reportError(e);
      }
    },
  };

  constructor(firestore: Firestore) {
    super(firestore);
  }

  connect(
    organizationId: string
  ): Observable<EntityChangeAction<Conversation>[]> {
    return this._connect(
      this.pathSpec(),
      (d) => d,
      ConversationService.converter,
      where('participants', 'array-contains', organizationId)
    );
  }

  create(organizationId: string, record: Conversation): Promise<string> {
    return this._create(this.pathSpec(), record);
  }

  set(
    organizationId: string,
    recordId: string,
    record: Conversation
  ): Promise<void> {
    return this._set(this.pathSpec(), recordId, record);
  }

  update(
    organizationId: string,
    recordId: string,
    record: Partial<Conversation>
  ): Promise<void> {
    return this._update(this.pathSpec(), recordId, record);
  }

  remove(organizationId: string, recordId: string): Promise<void> {
    throw new Error('Method not implemented.');
  }

  get(
    organizationId: string,
    recordId: string
  ): Observable<Conversation & WithId> {
    return this._get(this.pathSpec(), recordId);
  }

  private pathSpec(): PathSpec[] {
    return [
      {
        collection: ConversationService.CONVERSATION_COLLECTION,
      },
    ];
  }

  updateUserListInConversation(conversationId: string, usersId: string[]) {
    return updateDoc(
      doc(
        this.firestore,
        ConversationService.CONVERSATION_COLLECTION,
        conversationId
      ),
      { usersId: usersId }
    );
  }

  addUserInConversations(conversationId: string, userId: string) {
    return updateDoc(
      doc(
        this.firestore,
        ConversationService.CONVERSATION_COLLECTION,
        conversationId
      ),
      { usersId: arrayUnion(userId) }
    );
  }

  updateConversationViewedByInfo(
    conversationId: string,
    organizationId: string
  ) {
    try {
      const viewedByInfo: ViewedByInfo = {
        nbOfUnreadMessages: 0,
        viewedOn: serverTimestamp() as unknown as Date,
      };
      return updateDoc(
        doc(
          this.firestore,
          ConversationService.CONVERSATION_COLLECTION,
          conversationId
        ),
        { ['viewedBy.' + organizationId]: viewedByInfo }
      );
    } catch (e) {
      return Promise.reject(e);
    }
  }
}
