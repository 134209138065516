import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { AppUtilsModule } from '../app-utils.module';
import { UserSettingsStoreModule } from './store/user-settings-store.module';

@NgModule({
  declarations: [],
  imports: [CommonModule, UserSettingsStoreModule, AppUtilsModule],
})
export class UserSettingsModule {}
