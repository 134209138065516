import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { tap } from 'rxjs/operators';

import { Actions, createEffect, ofType } from '@ngrx/effects';

import * as actions from './router.actions';

@Injectable()
export class RouterEffects {
  constructor(private actions$: Actions, private router: Router) {}

  errorSnackBar = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actions.navigateTo),
        tap((navigateTo) => {
          if (navigateTo.commands?.length === 1) {
            this.router.navigateByUrl(navigateTo.commands[0]);
          } else {
            this.router.navigate(navigateTo.commands);
          }
        })
      ),
    { dispatch: false }
  );
}
