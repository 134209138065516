import _ from 'lodash';

import { createFeatureSelector, createSelector } from '@ngrx/store';

import { createSelectors } from '@arrivage-store/generators';
import {
  WithId,
  ContactRequest,
  ContactRequestStatus,
} from '@arrivage/model/dist/src/model';

import { adapter } from './sent-contact-requests.reducer';
import { SentContactRequestState, State } from './sent-contact-requests.state';

export const getContactRequestState = createFeatureSelector<SentContactRequestState>(
  'sentContactRequests'
);

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
  connecting,
  connected,
  connectState,
  queryFailure,
  addFailure,
  setFailure,
  updateFailure,
  removeFailure,
  getById,
  selectActiveItem,
  isLoadingActiveItem,
  selectActiveItemState,
} = createSelectors<ContactRequest, State>(adapter, getContactRequestState);

export const getByRelationshipId: (
  State
) => { [index: string]: (ContactRequest & WithId)[] } = createSelector(
  selectAll,
  (contactRequests) =>
    _.groupBy(
      _.filter(
        contactRequests,
        (c) => c.status === ContactRequestStatus.PENDING
      ),
      'relationshipId'
    )
);

export const getPendingContactRequestsByOrganizationId: (
  State
) => { [index: string]: ContactRequest & WithId } = createSelector(
  selectAll,
  (contactRequests) =>
    _.keyBy(
      _.filter(
        contactRequests,
        (c) =>
          c.status === ContactRequestStatus.PENDING &&
          !!c.recipientOrganizationId
      ),
      'recipientOrganizationId'
    )
);

export const getActiveContactRequest = createSelector(
  getContactRequestState,
  (contactRequestState) => contactRequestState.activeContactRequest
);
