import _ from 'lodash';

import {
  Component,
  EventEmitter,
  Input,
  Output,
  SecurityContext,
} from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

import { SnackbarService } from '@arrivage-snackbar/snackbar.service';

@Component({
  selector: 'app-upload-files',
  templateUrl: './upload-files.component.html',
  styleUrls: ['./upload-files.component.scss'],
})
export class UploadFilesComponent {
  private _displayedFiles: { type: string; url: string | SafeUrl }[] = [];
  private _fileTypeAccepted: ('pdf' | 'image')[] = [];

  newFiles: { file: File; url: string | SafeUrl }[] = [];
  typeAccepted: string = '';

  @Input()
  set displayedFiles(value: { type: string; url: string | SafeUrl }[]) {
    if (value) {
      this._displayedFiles = [...value];
      this.newFiles = [];
    }
  }

  get displayedFiles(): { type: string; url: string | SafeUrl }[] {
    return this._displayedFiles;
  }

  @Input()
  set fileTypeAccepted(value: ('pdf' | 'image')[]) {
    if (value) {
      this._fileTypeAccepted = value;
      this.typeAccepted = value
        .map((type) => {
          switch (type) {
            case 'pdf':
              return 'application/pdf';
            case 'image':
              return 'image/*';
          }
        })
        .join();
    }
  }

  get fileTypeAccepted(): ('pdf' | 'image')[] {
    return this._fileTypeAccepted;
  }

  @Input()
  editable: boolean = true;

  @Output()
  filesChanges = new EventEmitter<void>();

  constructor(
    private sanitizer: DomSanitizer,
    private snackbarService: SnackbarService
  ) {}

  isPdf(selectedFile: { type: string; url: string | SafeUrl }) {
    return selectedFile.type.includes('pdf');
  }

  addFile(fileToAdd: { file: File; url: string | SafeUrl }) {
    this.displayedFiles.push({ type: fileToAdd.file.type, url: fileToAdd.url });
    this.newFiles.push(fileToAdd);
    this.filesChanges.emit();
  }

  deleteFile(selectedFile: { type: string; url: string | SafeUrl }) {
    this.displayedFiles = _.remove(this.displayedFiles, (f) => {
      return f !== selectedFile;
    });

    const url =
      typeof selectedFile.url === 'string' || selectedFile.url instanceof String
        ? selectedFile.url
        : this.sanitizer.sanitize(SecurityContext.URL, selectedFile.url);

    if (url.includes('blob')) {
      this.newFiles = _.remove(this.newFiles, (f) => {
        return f.url !== selectedFile.url;
      });
    }
    this.filesChanges.emit();
  }

  onFileUpload(event: any): void {
    const selectedFile = event.target.files[0] as File;

    if (selectedFile) {
      if (
        this.fileTypeAccepted
          .map((type) => {
            return selectedFile.type.includes(type);
          })
          .some((x) => x)
      ) {
        if (event) {
          const url = this.sanitizer.bypassSecurityTrustUrl(
            URL.createObjectURL(selectedFile)
          );
          this.addFile({ file: selectedFile, url: url });
        }
      } else {
        this.snackbarService.showError('unsupported_file_type');
      }
      event.target.value = null;
    }
  }

  getNewFiles(): File[] {
    return this.newFiles.length > 0
      ? this.newFiles.map((f) => {
          return f.file;
        })
      : [];
  }
}
