import { Observable } from 'rxjs';
import { DateUtils } from 'src/app/util/date.utils';

import { Injectable } from '@angular/core';
import { Firestore, where } from '@angular/fire/firestore';

import { DeliveryUtils } from '@arrivage-distribution/common/utils/delivery.utils';
import { BaseService, PathSpec } from '@arrivage-services/base.service';
import {
  EntityChangeAction,
  OrganizationEntityService,
} from '@arrivage-services/organization-entity.service';
import { Offer, WithId } from '@arrivage/model/dist/src/model';
import { CollectionNames } from '@arrivage/model/dist/src/utils';

@Injectable({
  providedIn: 'root',
})
export abstract class OfferService
  extends BaseService<Offer>
  implements OrganizationEntityService<Offer>
{
  static OFFERS_COLLECTION = CollectionNames.OFFERS_COLLECTION;

  public static convertTimestampFields<T extends Offer>(firestoreData: T): T {
    if (!firestoreData) {
      return firestoreData;
    }

    const offer: T = {
      ...firestoreData,
    };
    if (firestoreData.lastUpdateTime) {
      offer.lastUpdateTime = DateUtils.toDate(firestoreData.lastUpdateTime);
    }
    if (firestoreData.expirationDate) {
      offer.expirationDate = DateUtils.toDate(firestoreData.expirationDate);
    }
    if (firestoreData.forDeliveryBy) {
      offer.forDeliveryBy = DateUtils.toDate(firestoreData.forDeliveryBy);
    }
    if (firestoreData.vendorPublicDeliveries) {
      offer.vendorPublicDeliveries = offer.vendorPublicDeliveries.map(
        (delivery) => DeliveryUtils.convertTimestampFields(delivery)
      );
    }

    return offer;
  }

  constructor(firestore: Firestore) {
    super(firestore);
  }

  get(
    organizationId: string,
    id: string,
    noCache: boolean = false
  ): Observable<Offer & WithId> {
    return this._get(
      this.pathSpec(),
      id,
      OfferService.convertTimestampFields,
      noCache
    );
  }

  list(organizationId: string): Observable<(Offer & WithId)[]> {
    return this._list(
      this.pathSpec(),
      OfferService.convertTimestampFields,
      where('vendor.organizationId', '==', organizationId)
    );
  }

  connect(organizationId: string): Observable<EntityChangeAction<Offer>[]> {
    throw new Error('Method not implemented.');
  }

  create(organizationId: string, record: Offer): Promise<string> {
    throw new Error('Method not implemented.');
  }

  set(organizationId: string, recordId: string, record: Offer): Promise<void> {
    throw new Error('Method not implemented.');
  }

  update(
    organizationId: string,
    recordId: string,
    record: Partial<Offer>
  ): Promise<void> {
    throw new Error('Method not implemented.');
  }

  remove(organizationId: string, recordId: string): Promise<void> {
    throw new Error('Method not implemented.');
  }

  protected pathSpec(): PathSpec[] {
    return [{ collection: OfferService.OFFERS_COLLECTION }];
  }
}
