import { OutputFormat } from 'ngx-image-cropper/lib/interfaces/cropper-options.interface';

export namespace PictureUtils {
  export function base64ToFile(base64: string, originalFile: File): File {
    const arr = base64.split(',');
    const match = arr[0].match(/:(.*?);/);
    const mime = match ? match[1] : null;
    const validBase64 = arr[1];
    if (validBase64) {
      const int8Array = Uint8Array.from(atob(validBase64), (c) =>
        c.charCodeAt(0)
      );
      const file = new File([int8Array], originalFile.name, {
        type: mime,
      });
      return file;
    } else {
      return originalFile;
    }
  }

  export function extractPictureType(mime: string): OutputFormat {
    switch (mime) {
      case 'image/png':
        return 'png';
      case 'image/jpeg':
        return 'jpeg';
      default:
        return 'jpeg';
    }
  }
}
