import { AppUtilsModule } from 'src/app/app-utils.module';
import { MaterialModule } from 'src/app/material.module';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';

import { TranslocoModule } from '@jsverse/transloco';

import { SystemMessageModule } from '@arrivage-components/system-message/system-message.module';

import { HeaderComponent } from '../../header/header.component';
import { LayoutWithHeaderComponent } from './layout-with-header.component';

@NgModule({
  declarations: [LayoutWithHeaderComponent, HeaderComponent],
  imports: [
    CommonModule,
    MaterialModule,
    FlexLayoutModule,
    TranslocoModule,
    SystemMessageModule,
    AppUtilsModule,
  ],
  exports: [LayoutWithHeaderComponent, HeaderComponent],
})
export class LayoutWithHeaderModule {}
