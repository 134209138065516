import { createAction, props } from '@ngrx/store';

import { Confirmation, generateActions } from '@arrivage-store/generators';
import { Format, WithId } from '@arrivage/model/dist/src/model';

export const {
  query,
  connected,
  add,
  added,
  set,
  modified,
  update,
  remove,
  removed,
  queryFailure,
  addSuccess,
  addFailure,
  setSuccess,
  setFailure,
  updateSuccess,
  updateFailure,
  removeSuccess,
  removeFailure,
  getActiveItem,
  getActiveItemFailure,
  getActiveItemSuccess,
} = generateActions<Format>('Formats');

export const updateWithPicture = createAction(
  '[Format - UpdateFormatWith Picture] UpdateFormat With Picture',
  props<{
    record: { format: Partial<Format> & WithId; newPictureFile: Blob };
    confirmation: Confirmation<string, any>;
  }>()
);
