import { Observable } from 'rxjs';

import { Injectable } from '@angular/core';
import { Firestore, QueryConstraint, where } from '@angular/fire/firestore';

import { Relationship, WithId } from '@arrivage/model/dist/src/model';
import { CollectionNames } from '@arrivage/model/dist/src/utils';

import { BaseService, PathSpec } from '../base.service';
import {
  EntityChangeAction,
  OrganizationEntityService,
} from '../organization-entity.service';

@Injectable({
  providedIn: 'root',
})
export class RelationshipService
  extends BaseService<Relationship>
  implements OrganizationEntityService<Relationship>
{
  static readonly RELATIONSHIPS_COLLECTION =
    CollectionNames.RELATIONSHIP_COLLECTION;

  constructor(firestore: Firestore) {
    super(firestore);
  }

  get(
    organizationId: string,
    recordId: string
  ): Observable<Relationship & WithId> {
    return this._get(this.pathSpec(), recordId);
  }

  connect(
    organizationId: string
  ): Observable<EntityChangeAction<Relationship>[]> {
    return this._connect(
      this.pathSpec(),
      (d) => d,
      null,
      where('participants', 'array-contains', organizationId)
    );
  }

  list(
    organizationId: string,
    ...filters: QueryConstraint[]
  ): Observable<(Relationship & WithId)[]> {
    return this._list(
      this.pathSpec(),
      (d) => d,
      where('participants', 'array-contains', organizationId)
    );
  }

  create(organizationId: string, record: Relationship): Promise<string> {
    throw new Error('Method not implemented.');
  }

  set(
    organizationId: string,
    recordId: string,
    record: Relationship
  ): Promise<void> {
    throw new Error('Method not implemented.');
  }

  update(
    organizationId: string,
    recordId: string,
    record: Partial<Relationship>
  ): Promise<void> {
    throw new Error('Method not implemented.');
  }

  remove(organizationId: string, recordId: string): Promise<void> {
    throw new Error('Method not implemented.');
  }

  protected pathSpec(): PathSpec[] {
    return [{ collection: RelationshipService.RELATIONSHIPS_COLLECTION }];
  }
}
