import { createAction, props } from '@ngrx/store';

import {
  Member,
  Organization,
  User,
  WithId,
} from '@arrivage/model/dist/src/model';

import { Confirmation } from '../generators';
import { AccountCreationInfo } from './account-create.model';

export interface LoadContextData {
  userId: string;
  activeMembershipId?: string;
  organizationOverride?: string;
}

export const LoadContext = createAction(
  `[Context] LoadContext`,
  props<{
    loadContextData: LoadContextData;
  }>()
);

export const LoadSelectedOrganization = createAction(
  `[Context] LoadSelectedOrganization`,
  props<{
    membership: Member & WithId;
  }>()
);

export const LoadNewOrganization = createAction(
  `[Context] LoadNewOrganization`,
  props<{
    membership: Member & WithId;
  }>()
);

export const LoadConnectedUser = createAction(
  `[Context] LoadConnectedUser`,
  props<{
    userId: string;
  }>()
);

export const SetConnectedUser = createAction(
  `[Context] SetConnectedUser`,
  props<{
    user: User & WithId;
  }>()
);

export const SetConnectedUserFailure = createAction(
  `[Context] SetConnectedUserFailure`,
  props<{
    message: string;
  }>()
);

export const LoadConnectedUserMemberships = createAction(
  `[Context] LoadConnectedUserMemberships`,
  props<{
    userId: string;
  }>()
);

export const SetConnectedUserMemberships = createAction(
  `[Context] SetConnectedUserMemberships`,
  props<{
    memberships: (Member & WithId)[];
  }>()
);

export const SetConnectedUserMembershipsFailure = createAction(
  `[Context] SetConnectedUserMembershipsFailure`,
  props<{
    message: string;
  }>()
);

export const LoadConnectedUserActiveMembership = createAction(
  `[Context] LoadConnectedUserActiveMembership`,
  props<{
    membership: Member & WithId;
  }>()
);

export const SetConnectedUserActiveMembership = createAction(
  `[Context] SetConnectedUserActiveMembership`,
  props<{
    membership: Member & WithId;
  }>()
);

export const SetConnectedUserActiveMembershipFailure = createAction(
  `[Context] SetConnectedUserActiveMembershipFailure`,
  props<{
    message: string;
  }>()
);

export const LoadConnectedUserActiveOrganization = createAction(
  `[Context] LoadConnectedUserActiveOrganization`,
  props<{
    organizationId: string;
  }>()
);

export const SetConnectedUserActiveOrganization = createAction(
  `[Context] SetConnectedUserActiveOrganization`,
  props<{
    organization: Organization & WithId;
    zones: string[];
  }>()
);

export const SetConnectedUserActiveOrganizationFailure = createAction(
  `[Context] SetConnectedUserActiveOrganizationFailure`,
  props<{
    message: string;
  }>()
);

export const LoadContextSuccess = createAction(`[Context] LoadContextSuccess`);

export const LoadContextFailure = createAction(
  `[Context] LoadContextFailure`,
  props<{
    message: string;
  }>()
);

export const AddUserInfo = createAction(
  `[Context] AddUserInfo`,
  props<{
    accountCreationInfo: AccountCreationInfo;
  }>()
);

export const AddUserInfoSuccess = createAction(`[Context] AddUserInfoSuccess`);

export const AddUserInfoFailure = createAction(
  `[Context] AddUserInfoFailure`,
  props<{
    message: string;
  }>()
);

export const updateOrganization = createAction(
  '[Organization - Update Organization] Update Organization',
  props<{
    record: {
      organization: Partial<Organization> & WithId;
      newPictureFile?: File;
      newLogoFile?: File;
      newPermits?: File[];
      newBannerFile?: File;
    };
    confirmation: Confirmation<string, any>;
  }>()
);

export const updateOrganizationSuccess = createAction(
  '[Organization - Update Organization success] Update Organization success',
  props<{
    id: string;
  }>()
);

export const updateOrganizationFailure = createAction(
  '[Organization - Update Organization failure] Update Organization failure',
  props<{
    errorMessage: string;
  }>()
);

export const updateUser = createAction(
  '[User - Update User] Update User',
  props<{
    record: {
      user: Partial<User> & WithId;
      newPictureFile: File;
    };
    confirmation: Confirmation<string, any>;
  }>()
);

export const updateUserSuccess = createAction(
  '[User - Update User success] Update User success',
  props<{
    id: string;
  }>()
);

export const updateUserFailure = createAction(
  '[User - Update User failure] Update User failure',
  props<{
    errorMessage: string;
  }>()
);
