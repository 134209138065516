import { Component, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'app-share-buttons-dialog',
  templateUrl: './share-buttons-dialog.component.html',
  styleUrls: ['./share-buttons-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShareButtonsDialogComponent {
  constructor() {}
}
