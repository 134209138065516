import _ from 'lodash';
import { ICreatePDF } from 'pdfmake-wrapper';
import { ProducerSalesReportCsvData } from 'src/app/purchase-report/common/model/purchase-report.model';
import { ProducerSalesReportCsvExportService } from 'src/app/purchase-report/services/producer-sales-report-csv-export.service';

import { translate } from '@jsverse/transloco';

import { Dictionary } from '@ngrx/entity';

import { FilteredDateRangePickerDialogResponse } from '@arrivage-components/filtered-date-range-picker-dialog/filtered-date-range-picker-dialog.component';
import { LoadingFeedback } from '@arrivage-components/loading-dialog/loading-feedback.model';
import { LanguageService } from '@arrivage-language/service/language.service';
import {
  PriceListCSVExportData,
  PriceListItemLine,
  PriceListItemLineWithPriceList,
} from '@arrivage-price-lists/model/price-list.model';
import { PriceListCsvExportService } from '@arrivage-price-lists/services/price-list-csv-export.service';
import { PriceListUtils } from '@arrivage-price-lists/utils/price-list.utils';
import { ProducersApiService } from '@arrivage-producers/api/producers.api.service';
import { ProducersFacade } from '@arrivage-producers/api/producers.facade';
import {
  ProducerOfferPreviewOutput,
  SendProducerOfferResponseData,
  SendSalesReportDialogResponse,
  SendSalesReportRecipient,
} from '@arrivage-producers/model/producers.model';
import { ProducerFeedback } from '@arrivage-producers/store/producers.effects';
import { OrderItemsByProducer } from '@arrivage-scheduled-deliveries/common/model/scheduled-deliveries.model';
import { ScheduledDeliveriesUtils } from '@arrivage-scheduled-deliveries/common/utils/scheduled-deliveries.utils';
import { reportError } from '@arrivage-sentry/report-error';
import { MainPageLoadService } from '@arrivage-services/main-page-load.service';
import { PdfService } from '@arrivage-services/pdf.service';
import { PDFCreatedInfo } from '@arrivage-services/pdf/model/pdf.model';
import { ScheduledDeliveryPdfService } from '@arrivage-services/pdf/scheduled-delivery-pdf/scheduled-delivery-pdf.service';
import { SnackbarService } from '@arrivage-snackbar/snackbar.service';
import { FileType } from '@arrivage-util/attachment.model';
import { DataGroupByUtils } from '@arrivage-util/data-group-by.utils';
import { LangUtils } from '@arrivage-util/lang.utils';
import {
  SendProducerOfferRequestData,
  SendProducersSalesReportRequestData,
} from '@arrivage/model/dist/src/cloud-functions-api';
import {
  CommissionType,
  Organization,
  OrganizationSettings,
  PriceList,
  Producer,
  PurchaseOrder,
  WithId,
} from '@arrivage/model/dist/src/model';

export namespace ProducerUtils {
  export function getCommissionPercentageValue(commission: {
    value: number;
    type: CommissionType;
  }) {
    if (!commission) return null;

    /**
     * // TODO
     * Percentage is the only type for now : WEB-3047
     */
    switch (commission.type) {
      case CommissionType.PERCENTAGE:
      default:
        return commission.value;
    }
  }

  /**
   * get a dictionary of sorted `orderItems` by category and producer, grouped by producer
   * @param purchaseOrders
   * @param producers Array of producers sorted the way you want to display them
   * @returns
   */
  export function getOrderItemsByProducerIds(
    purchaseOrders: (PurchaseOrder & WithId)[],
    sortedProducers: (Producer & WithId)[] = []
  ): _.Dictionary<OrderItemsByProducer> {
    const producerIds = _.map(sortedProducers, 'id');

    const organizationName = purchaseOrders[0]?.vendor.name;

    const orderItemsWithCustomers =
      ScheduledDeliveriesUtils.createOrderItemsWithCustomers(purchaseOrders);

    const filteredOrderItemsWithCustomers =
      ScheduledDeliveriesUtils.filterOrderItemsWithCustomersByProducer(
        orderItemsWithCustomers,
        producerIds
      );

    const orderItemsWithCustomerLines =
      ScheduledDeliveriesUtils.createOrderItemsWithCustomerLines(
        filteredOrderItemsWithCustomers
      );

    const orderItemsByProducerIds =
      ScheduledDeliveriesUtils.createOrderItemsByProducerIds(
        orderItemsWithCustomerLines,
        organizationName
      );

    return orderItemsByProducerIds;
  }

  /**
   * get the active priceListItemLines of a producer with its price list data
   * @param allPriceLists - all price lists of the organization
   * @param priceListItemLinesByPriceList
   * @param producer - the producer to whom you want to get their priceListItemLinesWithPriceList
   * @returns
   */
  export function getProducerActivePriceListItemLinesWithPriceList(
    allPriceLists: (PriceList & WithId)[],
    priceListItemLinesByPriceList: Dictionary<PriceListItemLine[]>,
    producer: Producer & WithId
  ): PriceListItemLineWithPriceList[] {
    const producerPriceListItemLinesWithPriceList =
      ProducerUtils.getProducerPriceListItemLinesWithPriceList(
        allPriceLists,
        priceListItemLinesByPriceList,
        producer
      );

    const activePriceListItemLinesWithPriceList =
      producerPriceListItemLinesWithPriceList.filter(
        (priceListItem) => priceListItem.priceListItem.isActive
      );

    return activePriceListItemLinesWithPriceList;
  }

  /**
   * get all active priceListItemLines of all producers with its price list data
   * @param allPriceLists - all price lists of the organization
   * @param priceListItemLinesByPriceList
   * @param producers - the producers to whom you want to get their priceListItemLinesWithPriceList
   * @returns
   */
  export function getAllProducersActivePriceListItemLinesWithPriceList(
    allPriceLists: (PriceList & WithId)[],
    priceListItemLinesByPriceList: Dictionary<PriceListItemLine[]>,
    producers: (Producer & WithId)[]
  ): PriceListItemLineWithPriceList[] {
    return _.flatMap(producers, (producer) =>
      ProducerUtils.getProducerActivePriceListItemLinesWithPriceList(
        allPriceLists,
        priceListItemLinesByPriceList,
        producer
      )
    );
  }

  /**
   * Function with sendOfferDialog to preview the producer offer PDF or CSV
   * @param allPriceLists - all price lists of the organization
   * @param priceListItemLinesByPriceList
   * @param producerOfferPreviewOutput - response from the dialog when clicking on preview PDF or CSV.
   * @param activeOrganization - the HUB organization
   * @param languageService
   * @param pdfService
   * @param priceListCSVExportService
   */
  export async function manageProducerOfferPreview(
    allPriceLists: (PriceList & WithId)[],
    priceListItemLinesByPriceList: Dictionary<PriceListItemLine[]>,
    producerOfferPreviewOutput: ProducerOfferPreviewOutput,
    activeOrganization: Organization & WithId,
    languageService: LanguageService,
    pdfService: PdfService,
    priceListCSVExportService: PriceListCsvExportService
  ) {
    const producersActivePriceListItemLinesWithPriceList =
      ProducerUtils.getAllProducersActivePriceListItemLinesWithPriceList(
        allPriceLists,
        priceListItemLinesByPriceList,
        producerOfferPreviewOutput.producers
      );

    if (producerOfferPreviewOutput.typeOfPreview === FileType.PDF) {
      const priceListItemLinesGroupedByProducerByPriceListByCategory =
        DataGroupByUtils.groupByProducerByPriceListAndByCategory(
          producersActivePriceListItemLinesWithPriceList,
          languageService
        );

      const producersOfferPdf = await pdfService.generateProducersPriceListsPdf(
        priceListItemLinesGroupedByProducerByPriceListByCategory,
        activeOrganization,
        producerOfferPreviewOutput.producers,
        true
      );

      producersOfferPdf.open();
    } else {
      const priceListsCSVExportData: PriceListCSVExportData[] =
        PriceListUtils.getPriceListArrayForCsvExport(
          producersActivePriceListItemLinesWithPriceList
        );

      /**
       * If producers.length === 0 it means we are in the case of a preview of one particular producer
       * so we can add the producer name to the csv file name. If there are more than one producer it
       * means that we are in the case of the overall preview with multiple producer so we use a generic
       * CSV file name
       */
      const CSVName: string =
        producerOfferPreviewOutput.producers.length === 0
          ? translate(
              'producers.send-producer-offer-dialog.producer-csv-file-name',
              {
                producerName: producerOfferPreviewOutput.producers[0].name,
              }
            )
          : translate(
              'producers.send-producer-offer-dialog.producers-csv-file-name'
            );

      await priceListCSVExportService.downloadCSVExport(
        priceListsCSVExportData,
        CSVName
      );
    }
  }

  /**
   * Function that returns the sendOfferRequestData for the sendProducerOffer CF
   * @param producerActivePriceListItemLinesWithPriceList - all active priceListItemLines with their priceList of the producer
   * @param producer - the producer
   * @param activeOrganization - the HUB organization
   * @param producerOfferDialogResponseData - the response from the sendOfferDialog
   * @param languageService
   * @param pdfService
   * @param priceListCSVExportService
   * @returns
   */
  export async function getSendProducerOfferRequestData(
    producerActivePriceListItemLinesWithPriceList: PriceListItemLineWithPriceList[],
    producer: Producer & WithId,
    activeOrganization: Organization & WithId,
    producerOfferDialogResponseData: SendProducerOfferResponseData,
    languageService: LanguageService,
    pdfService: PdfService,
    priceListCSVExportService: PriceListCsvExportService
  ): Promise<SendProducerOfferRequestData> {
    const recipient: SendProducerOfferRequestData = {
      producer: producer,
      organization: activeOrganization,
      messageToProducer: producerOfferDialogResponseData.sendOfferMessage,
      pdfBase64: null,
      csvBase64: null,
    };

    if (
      producerOfferDialogResponseData.includedDocuments.includes(FileType.PDF)
    ) {
      const priceListItemLinesWithPriceListGroupByProducerGroupByPriceListByCategory =
        DataGroupByUtils.groupByProducerByPriceListAndByCategory(
          producerActivePriceListItemLinesWithPriceList,
          languageService
        );

      const pdf = await pdfService.generateProducersPriceListsPdf(
        priceListItemLinesWithPriceListGroupByProducerGroupByPriceListByCategory,
        activeOrganization,
        [producer],
        true
      );

      const pdfBase64 = await pdfService.getBase64(pdf);

      recipient.pdfBase64 = pdfBase64;
    }

    if (
      producerOfferDialogResponseData.includedDocuments.includes(
        FileType.CSV
      ) &&
      producerActivePriceListItemLinesWithPriceList.length > 0
    ) {
      const priceListsCSVExportData: PriceListCSVExportData[] =
        PriceListUtils.getPriceListArrayForCsvExport(
          producerActivePriceListItemLinesWithPriceList
        );

      const csv = await priceListCSVExportService.exportCsv(
        priceListsCSVExportData
      );

      const csvBase64 = await priceListCSVExportService.getBase64(csv);

      recipient.csvBase64 = csvBase64;
    }

    return recipient;
  }

  export async function generateProducersSalesReportPDF(
    scheduledDeliveryPdfService: ScheduledDeliveryPdfService,
    filteredDateRange: FilteredDateRangePickerDialogResponse,
    purchaseOrders: (PurchaseOrder & WithId)[],
    organizationSettings: OrganizationSettings,
    producers: (Producer & WithId)[] = []
  ): Promise<ICreatePDF> {
    const commissionPercentage = ProducerUtils.getCommissionPercentageValue(
      organizationSettings.producers?.commission
    );

    const sortedProducers = LangUtils.normalizedSortBy(
      producers,
      (producer) => producer.name
    );

    const orderItemsByProducerIds = ProducerUtils.getOrderItemsByProducerIds(
      purchaseOrders,
      sortedProducers
    );

    try {
      const result: PDFCreatedInfo =
        await scheduledDeliveryPdfService.generateProducersSalesReport(
          orderItemsByProducerIds,
          filteredDateRange.dateRange,
          filteredDateRange.dateType,
          {
            commissionPercentage,
            producers: sortedProducers,
          }
        );

      return result.pdf;
    } catch (e) {
      reportError(e);
    }
  }

  export async function getPdfBase64ByProducer(
    pdfService: PdfService,
    scheduledDeliveryPdfService: ScheduledDeliveryPdfService,
    filteredDateRange: FilteredDateRangePickerDialogResponse,
    purchaseOrders: (PurchaseOrder & WithId)[],
    organizationSettings: OrganizationSettings,
    producers: (Producer & WithId)[] = []
  ): Promise<{ [producerId: string]: string }> {
    const pdfBase64ByProducer: { [producerId: string]: string } = {};

    const commissionPercentage = ProducerUtils.getCommissionPercentageValue(
      organizationSettings.producers?.commission
    );

    const sortedProducers = LangUtils.normalizedSortBy(
      producers,
      (producer) => producer.name
    );

    const orderItemsByProducerIds = ProducerUtils.getOrderItemsByProducerIds(
      purchaseOrders,
      sortedProducers
    );

    try {
      const operations = producers.map(async (producer) => {
        const pdfInfo =
          await scheduledDeliveryPdfService.generateProducersSalesReport(
            orderItemsByProducerIds,
            filteredDateRange.dateRange,
            filteredDateRange.dateType,
            {
              commissionPercentage,
              producers: [producer],
            }
          );
        const pdfBase64 = await pdfService.getBase64(pdfInfo.pdf);
        pdfBase64ByProducer[producer.id] = pdfBase64;
      });

      await Promise.all(operations);

      return pdfBase64ByProducer;
    } catch (e) {
      reportError(e);
    }
  }

  export async function downloadProducersSalesReportCSV(
    producerSalesReportCSVExportService: ProducerSalesReportCsvExportService,
    purchaseOrders: (PurchaseOrder & WithId)[],
    producers: (Producer & WithId)[] = []
  ) {
    const file_producer_name =
      producers.length === 1 ? producers[0].name + '_' : '';

    const producersById: _.Dictionary<Producer & WithId> = producers.reduce(
      (acc, producer) => {
        acc[producer.id] = producer;
        return acc;
      },
      {}
    );

    const producerSalesReportCsvData: ProducerSalesReportCsvData[] =
      purchaseOrders
        .flatMap((purchaseOrder) => {
          return purchaseOrder.orderItems.map((orderItem) => {
            const producer = producersById[orderItem.product.producerId];
            if (producer) {
              return {
                orderItem,
                purchaseOrder,
                producerName: producer.name,
              };
            } else return;
          });
        })
        .filter(Boolean); // Filter out undefined elements (no producer)

    try {
      await producerSalesReportCSVExportService.downloadCSVExport(
        producerSalesReportCsvData,
        file_producer_name +
          translate(
            'producers.send-producers-sales-report-dialog.producer-sales-report-csv-file-name'
          )
      );
    } catch (e) {
      reportError(e);
    }
  }

  export async function getCsvBase64ByProducer(
    producerSalesReportCSVExportService: ProducerSalesReportCsvExportService,
    purchaseOrders: (PurchaseOrder & WithId)[],
    producers: (Producer & WithId)[] = []
  ): Promise<{ [producerId: string]: string }> {
    const csvBase64ByProducer: { [producerId: string]: string } = {};

    const producersById: _.Dictionary<Producer & WithId> = producers.reduce(
      (acc, producer) => {
        acc[producer.id] = producer;
        return acc;
      },
      {}
    );

    const producerSalesReportCsvDataByProducer: _.Dictionary<
      ProducerSalesReportCsvData[]
    > = {};

    purchaseOrders
      .flatMap((purchaseOrder) => {
        return purchaseOrder.orderItems.map((orderItem) => {
          const producer = producersById[orderItem.product.producerId];
          if (producer) {
            if (producerSalesReportCsvDataByProducer[producer.id]) {
              producerSalesReportCsvDataByProducer[producer.id].push({
                orderItem,
                purchaseOrder,
                producerName: producer.name,
              });
            } else {
              producerSalesReportCsvDataByProducer[producer.id] = [
                {
                  orderItem,
                  purchaseOrder,
                  producerName: producer.name,
                },
              ];
            }
          }
        });
      })
      .filter(Boolean); // Filter out undefined elements (no producer)

    try {
      const operations = producers.map(async (producer) => {
        // If producer has no sales during this period, do not generate csv
        if (producerSalesReportCsvDataByProducer[producer.id]?.length) {
          const csvString = await producerSalesReportCSVExportService.exportCsv(
            producerSalesReportCsvDataByProducer[producer.id]
          );

          const csvBase64 =
            await producerSalesReportCSVExportService.getBase64(csvString);
          csvBase64ByProducer[producer.id] = csvBase64;
        } else {
          csvBase64ByProducer[producer.id] = null;
        }
      });

      await Promise.all(operations);

      return csvBase64ByProducer;
    } catch (e) {
      reportError(e);
    }
  }

  export async function getProducersRecipientsForProducersSalesReport(
    response: SendSalesReportDialogResponse,
    pdfService: PdfService,
    scheduledDeliveryPdfService: ScheduledDeliveryPdfService,
    producerSalesReportCSVExportService: ProducerSalesReportCsvExportService,
    filteredDateRange: FilteredDateRangePickerDialogResponse,
    purchaseOrders: (PurchaseOrder & WithId)[],
    organizationSettings: OrganizationSettings
  ): Promise<SendSalesReportRecipient[]> {
    const recipients: SendSalesReportRecipient[] = [];

    const documentsData = {
      salesReportsPdfByProducer: {},
      salesReportsCsvByProducer: {},
    };

    const includePDF = response.includedDocuments.includes(FileType.PDF);
    const includeCSV = response.includedDocuments.includes(FileType.CSV);

    if (includePDF) {
      // Convert PDF to base64 by producer
      documentsData.salesReportsPdfByProducer =
        await ProducerUtils.getPdfBase64ByProducer(
          pdfService,
          scheduledDeliveryPdfService,
          filteredDateRange,
          purchaseOrders,
          organizationSettings,
          response.producers
        );
    }

    if (includeCSV) {
      // Convert CSV to base64 by producer
      documentsData.salesReportsCsvByProducer =
        await ProducerUtils.getCsvBase64ByProducer(
          producerSalesReportCSVExportService,
          purchaseOrders,
          response.producers
        );
    }

    // Send sales report emails
    response.producers.forEach((producer) => {
      if (producer.isSelected && producer.email) {
        recipients.push({
          name: producer.name,
          email: producer.email,
          pdfBase64: includePDF
            ? documentsData.salesReportsPdfByProducer[producer.id]
            : null,
          csvBase64: includeCSV
            ? documentsData.salesReportsCsvByProducer[producer.id]
            : null,
          message: response.message?.trim() || null,
        });
      }
    });

    return recipients;
  }

  export async function updateProducersEmails(
    updatedProducers: (Producer & WithId)[],
    allProducers: (Producer & WithId)[],
    producersFacade: ProducersFacade,
    snackbarService: SnackbarService,
    action: string
  ) {
    const operations = _.map(updatedProducers, (updatedProducer) => {
      const existingProducer = allProducers.find((existingProd) => {
        return existingProd.id === updatedProducer.id;
      });
      if (
        existingProducer &&
        existingProducer?.email !== updatedProducer.email
      ) {
        producersFacade.updateProducer(updatedProducer);
      }
    });

    try {
      await Promise.all(operations);
      snackbarService.showSuccess(action);
    } catch (e) {
      snackbarService.showError(action);
      reportError(e);
    }
  }

  export function getProducerPriceListItemLinesWithPriceList(
    priceLists: (PriceList & WithId)[],
    priceListItemLinesByPriceListId: Dictionary<PriceListItemLine[]>,
    producer: Producer & WithId
  ): PriceListItemLineWithPriceList[] {
    if (
      priceLists.length > 0 &&
      Object.keys(priceListItemLinesByPriceListId).length > 0 &&
      producer
    ) {
      return _.flatMap(priceLists, (priceList) => {
        const filteredPriceListItemLinesByProducer = _.filter(
          priceListItemLinesByPriceListId[priceList.id],
          (priceListItemLine) => {
            return priceListItemLine.product?.producerId === producer.id;
          }
        );

        return _.map(
          filteredPriceListItemLinesByProducer,
          (priceListItemLine) => {
            return {
              ...priceListItemLine,
              ...priceList,
            };
          }
        );
      });
    }
  }
  export function sendProducerSalesReports(
    request: SendProducersSalesReportRequestData,
    snackbarService: SnackbarService,
    mainPageLoadService: MainPageLoadService,
    producersApiService: ProducersApiService
  ) {
    mainPageLoadService.start(LoadingFeedback.ON_SEND_PRODUCER_SALES_REPORT);
    const action = ProducerFeedback.send_producer_sales_report;

    producersApiService
      .sendProducersSalesReport(request)
      .then(() => {
        snackbarService.showSuccess(action);
      })
      .catch((e) => {
        reportError(e);
        snackbarService.showError(action);
      })
      .finally(() => {
        mainPageLoadService.end();
      });
  }
}
