import { AppUtilsModule } from 'src/app/app-utils.module';
import { MaterialModule } from 'src/app/material.module';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { OptionSelectInputComponent } from './option-select-input.component';

@NgModule({
  declarations: [OptionSelectInputComponent],
  imports: [CommonModule, ReactiveFormsModule, MaterialModule, AppUtilsModule],
  exports: [OptionSelectInputComponent],
})
export class OptionSelectInputModule {}
