import { from, Observable } from 'rxjs';

import { Injectable } from '@angular/core';
import {
  collection,
  collectionData,
  doc,
  docData,
  Firestore,
  query,
  setDoc,
} from '@angular/fire/firestore';

import { map } from 'rxjs/operators';

import { CreditAccountsService } from '@arrivage-credit-accounts/services/credit-accounts.service';
import { PremiumAccountFeedback } from '@arrivage-premium-accounts/model/feedback';
import { reportError } from '@arrivage-sentry/report-error';
import { SnackbarService } from '@arrivage-snackbar/snackbar.service';
import { PremiumAccount, WithId } from '@arrivage/model/dist/src/model';
import { CollectionNames } from '@arrivage/model/dist/src/utils';

@Injectable({
  providedIn: 'root',
})
export class PremiumAccountService {
  public static readonly COLLECTION =
    CollectionNames.PREMIUM_ACCOUNTS_COLLECTION;

  constructor(
    private firestore: Firestore,
    private creditAccountService: CreditAccountsService,
    private snackbarService: SnackbarService
  ) {}

  getPremiumAccount(organizationId: string): Observable<PremiumAccount> {
    return docData(
      doc(this.firestore, PremiumAccountService.COLLECTION, organizationId)
    ).pipe(
      map((d) => {
        if (d) {
          return { ...d } as PremiumAccount;
        } else {
          return null;
        }
      })
    );
  }

  listAllPremiumAccounts(): Observable<(PremiumAccount & WithId)[]> {
    return from(
      collectionData(
        query(collection(this.firestore, PremiumAccountService.COLLECTION)),
        { idField: 'id' }
      )
    ).pipe(
      map((d) =>
        d.map((data) => {
          return data as PremiumAccount & WithId;
        })
      )
    );
  }

  async upgradeToPremium(organizationId: string) {
    try {
      await this.creditAccountService.update(organizationId, {
        orderServiceFeesInPercent: 0,
      });
      await setDoc(
        doc(this.firestore, PremiumAccountService.COLLECTION, organizationId),
        { isPremium: true }
      );
    } catch (e) {
      reportError(e);
      this.snackbarService.showError(PremiumAccountFeedback.upgrade_to_premium);
    }
  }

  async downgradeToBasic(organizationId: string) {
    try {
      await this.creditAccountService.update(organizationId, {
        orderServiceFeesInPercent: null,
      });

      await setDoc(
        doc(this.firestore, PremiumAccountService.COLLECTION, organizationId),
        { isPremium: false }
      );
    } catch (e) {
      reportError(e);
      this.snackbarService.showError(PremiumAccountFeedback.downgrade_to_basic);
    }
  }
}
