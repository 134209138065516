import _ from 'lodash';
import { of } from 'rxjs';

import { Injectable } from '@angular/core';

import { exhaustMap, takeUntil, map, catchError } from 'rxjs/operators';

import { Actions, createEffect, ofType } from '@ngrx/effects';

import { AdminOfferService } from '@arrivage-admin/services/admin-offer.service';
import { Logout } from '@arrivage-auth/store/auth.actions';
import { OrganizationService } from '@arrivage-organization/services/organization.service';
import { OrganizationAuthorizationsService } from '@arrivage-organization/services/permissions.service';
import { PremiumAccountService } from '@arrivage-premium-accounts/services/premium-account.service';
import { reportError } from '@arrivage-sentry/report-error';

import * as actions from './admin.actions';

@Injectable()
export class AdminEffects {
  queryOrganizations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.queryOrganizations),
      exhaustMap(() => {
        return this.organizationService.listAllOrganizations().pipe(
          takeUntil(this.actions$.pipe(ofType(Logout))),
          map((action) => {
            return actions.queryOrganizationsSuccess({
              organizations: action,
            });
          }),
          catchError((e) => {
            reportError(e);
            return of(actions.queryOrganizationsFailure(e));
          })
        );
      })
    )
  );

  queryOrganizationAuthorizations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.queryAuthorizations),
      exhaustMap(() => {
        return this.organizationAuthorizationsService
          .listAllOrganizationAuthorizations()
          .pipe(
            takeUntil(this.actions$.pipe(ofType(Logout))),
            map((action) => {
              return actions.queryAuthorizationsSuccess({
                authorizations: action,
              });
            }),
            catchError((e) => {
              reportError(e);
              return of(actions.queryAuthorizationsFailure(e));
            })
          );
      })
    )
  );

  queryPremiumAccounts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.queryPremiumAccounts),
      exhaustMap(() => {
        return this.premiumAccountsService.listAllPremiumAccounts().pipe(
          takeUntil(this.actions$.pipe(ofType(Logout))),
          map((action) => {
            return actions.queryPremiumAccountsSuccess({
              premiumAccounts: action,
            });
          }),
          catchError((e) => {
            reportError(e);
            return of(actions.queryPremiumAccountsFailure(e));
          })
        );
      })
    )
  );

  queryOffers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.queryOffers),
      exhaustMap(() => {
        return this.adminOfferService.listAllActiveOffers().pipe(
          takeUntil(this.actions$.pipe(ofType(Logout))),
          map((action) => {
            return actions.queryOffersSuccess({
              offers: action,
            });
          }),
          catchError((e) => {
            reportError(e);
            return of(actions.queryOffersFailure(e));
          })
        );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private organizationService: OrganizationService,
    private organizationAuthorizationsService: OrganizationAuthorizationsService,
    private premiumAccountsService: PremiumAccountService,
    private adminOfferService: AdminOfferService
  ) {}
}
