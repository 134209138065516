import { EntityFeedback } from '@arrivage-store/feedback/feedback-params.model';

export interface OfferFeedback {
  availabilityUpdateToTrue: string;
  availabilityUpdateToFalse: string;
  shareOffer: string;
  shareOfferTest: string;
  previewOfferPdf: string;
}

export const OfferFeedback: EntityFeedback & OfferFeedback = {
  update: 'update_offer',
  availabilityUpdateToTrue: 'update_offer_availability_to_true',
  availabilityUpdateToFalse: 'update_offer_availability_to_false',
  shareOffer: 'share_offer',
  shareOfferTest: 'share_offer_test',
  previewOfferPdf: 'preview_offer_pdf',
  //error only
  query: 'query_offers',
  get_active_item: 'get_active_offer',
  add: '',
  remove: '',
};
