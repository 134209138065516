import * as _ from 'lodash';

import { createFeatureSelector } from '@ngrx/store';

import { Conversation } from '@arrivage/model/dist/src/model';

import { adapter } from './conversations.reducer';
import { ConversationsState, State } from './conversations.state';
import { createSelectors } from '@arrivage-store/generators';

export const getConversationState = createFeatureSelector<ConversationsState>(
  'conversations'
);

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
  connecting,
  connected,
  connectState,
  queryFailure,
  addFailure,
  setFailure,
  updateFailure,
  removeFailure,
  getById,
  selectActiveItem,
  isLoadingActiveItem,
  selectActiveItemState,
} = createSelectors<Conversation, State>(adapter, getConversationState);
