import * as _ from 'lodash';

import { createFeatureSelector, createSelector } from '@ngrx/store';

import { createSelectors } from '@arrivage-store/generators';
import { Notification, WithId } from '@arrivage/model/dist/src/model';

import { adapter } from './notifications.reducer';
import { State } from './notifications.state';
import { NotificationState } from './notifications.state';

export const getNotificationState =
  createFeatureSelector<NotificationState>('notifications');

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
  connecting,
  connected,
  connectState,
  queryFailure,
  addFailure,
  setFailure,
  updateFailure,
  removeFailure,
  getById,
  selectActiveItem,
  isLoadingActiveItem,
  selectActiveItemState,
} = createSelectors<Notification, State>(adapter, getNotificationState);

export const selectAllOrderByDate = createSelector<
  State,
  [(Notification & WithId)[]],
  (Notification & WithId)[]
>(selectAll, (notifications) =>
  _.orderBy(notifications, (notification) => notification.date, 'desc')
);
