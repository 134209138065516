import { Observable } from 'rxjs';

import { Injectable } from '@angular/core';
import { Firestore, where } from '@angular/fire/firestore';

import { BaseService, PathSpec } from '@arrivage-services/base.service';
import {
  EntityChangeAction,
  OrganizationEntityService,
} from '@arrivage-services/organization-entity.service';
import { Pickup, WithId } from '@arrivage/model/dist/src/model';

@Injectable({
  providedIn: 'root',
})
export class PickupService
  extends BaseService<Pickup>
  implements OrganizationEntityService<Pickup>
{
  static readonly PICKUPS_COLLECTION = 'pickups';

  constructor(firestore: Firestore) {
    super(firestore);
  }

  get(organizationId: string, recordId: string): Observable<Pickup & WithId> {
    return this._get(this.pathSpec(), recordId);
  }

  create(organizationId: string, priceList: Pickup): Promise<string> {
    return this._create(this.pathSpec(), priceList);
  }

  set(organizationId: string, recordId: string, record: Pickup): Promise<void> {
    return this._set(this.pathSpec(), recordId, record);
  }

  update(
    organizationId: string,
    pickupId: string,
    pickup: Partial<Pickup>
  ): Promise<void> {
    return this._update(this.pathSpec(), pickupId, pickup);
  }

  connect(organizationId: string): Observable<EntityChangeAction<Pickup>[]> {
    return this._connect(
      this.pathSpec(),
      (d) => d,
      null,
      where('organizationId', '==', organizationId)
    );
  }

  list(organizationId: string): Observable<(Pickup & WithId)[]> {
    return this._list(
      this.pathSpec(),
      (d) => d,
      where('organizationId', '==', organizationId)
    );
  }

  remove(organizationId: string, pickupId: string): Promise<void> {
    return this._delete(this.pathSpec(), pickupId);
  }

  private pathSpec(): PathSpec[] {
    return [{ collection: PickupService.PICKUPS_COLLECTION }];
  }
}
