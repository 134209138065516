import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  deleteObject,
  getDownloadURL,
  getStorage,
  ref,
  uploadBytesResumable,
} from '@angular/fire/storage';

import { take } from 'rxjs/operators';

import { environment } from '@arrivage-environments/environment';
import { reportError } from '@arrivage-sentry/report-error';
import { Department } from '@arrivage/model/dist/src/model';

@Injectable({
  providedIn: 'root',
})
export class PictureService {
  public static readonly DEFAULT_PHOTO_URL = environment.default_photo_url;
  public static readonly DEFAULT_COVER_PHOTO_URL =
    'assets/images/backgrounds/cover-photo.webp';

  private readonly storageRef = getStorage();

  constructor(private httpClient: HttpClient) {}

  static defaultProductPicture(productDepartment: Department): string {
    switch (productDepartment) {
      case Department.FRUITS: {
        return 'assets/images/products/fruits.png';
      }
      case Department.VEGETABLES: {
        return 'assets/images/products/vegetables.png';
      }
      case Department.SALADS_HERBS_SPROUTS_FLOWERS: {
        return 'assets/images/products/salads-herbs-flowers.png';
      }
      case Department.CULTIVATED_MUSHROOMS: {
        return 'assets/images/products/mushrooms.png';
      }
      case Department.MEAT_AND_POULTRY: {
        return 'assets/images/products/meat-poultry.png';
      }
      case Department.DELICATESSEN: {
        return 'assets/images/products/delicatessen.png';
      }
      case Department.CHEESE: {
        return 'assets/images/products/cheese.png';
      }
      case Department.BREAD: {
        return 'assets/images/products/bread.png';
      }
      case Department.PASTRY: {
        return 'assets/images/products/pastry.png';
      }
      case Department.OILS_AND_VINEGARS: {
        return 'assets/images/products/oils-vinegars.png';
      }
      case Department.CONDIMENTS_SPICES: {
        return 'assets/images/products/condiments-spices.png';
      }
      case Department.DAIRY_EGGS: {
        return 'assets/images/products/dairy-eggs.png';
      }
      case Department.CEREALS_FLOUR_LEGUMES: {
        return 'assets/images/products/cereals-flour-legumes.png';
      }
      case Department.FISH: {
        return 'assets/images/products/fish.png';
      }
      case Department.SEAWEED_AND_SEAFOOD: {
        return 'assets/images/products/seaweed-seafood.png';
      }
      case Department.ALCOHOL_FREE_DRINK:
      case Department.ALCOHOL_BELOW_18_DRINK:
      case Department.ALCOHOL_ABOVE_18_DRINK: {
        return 'assets/images/products/drinks.png';
      }
      case Department.WILD_PRODUCTS_NUTS:
      case Department.PROCESSED_PRODUCTS:
      case Department.SYRUPS_AND_MAPLE_PRODUCTS:
      case Department.HONEY:
      case Department.NON_FOOD_PRODUCTS: {
        return 'assets/images/products/processed-products-syrups-honey-non-food.png';
      }
      default: {
        return 'assets/images/products/processed-products-syrups-honey-non-food.png';
      }
    }
  }

  static defaultVendor() {
    return 'assets/images/vendors/vendor-default.png';
  }

  static defaultBuyer() {
    return 'assets/images/buyers/buyer-default.png';
  }

  uploadPicture(newPicture: Blob, path: string): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      const newPicRef = ref(this.storageRef, path);
      const uploadTask = uploadBytesResumable(newPicRef, newPicture);

      uploadTask.on(
        'state_changed',
        () => {},
        (error) => {
          reject(error);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((url) => {
            resolve(url);
          });
        }
      );
    });
  }

  deletePicture(imageUrl: string) {
    return deleteObject(ref(this.storageRef, imageUrl));
  }

  async getPictureBlob(imageUrl: string): Promise<Blob> {
    try {
      return this.httpClient
        .get(imageUrl, { responseType: 'blob' })
        .pipe(take(1))
        .toPromise();
    } catch (e) {
      reportError(e);
      return null;
    }
  }
}
