<ng-container *transloco="let t; scope: 'priceLists'; read: 'priceLists'">
  <h2 class="mat-dialog-title space-bottom text-xl">
    {{ t('share-to-customers-dialog.title') }}
  </h2>
  <div class="mat-dialog-content">
    <mat-card-content>
      <form [formGroup]="form">
        <mat-form-field appearance="outline">
          <mat-label>{{
            t('share-to-customers-dialog.message-label')
          }}</mat-label>
          <textarea
            cdkTextareaAutosize
            matInput
            rows="4"
            formControlName="message"
          ></textarea>
          <mat-hint>{{ t('share-to-customers-dialog.message-hint') }}</mat-hint>
        </mat-form-field>
      </form>
    </mat-card-content>
  </div>

  <div fxLayout="column">
    <app-system-message
      *ngIf="!isMoreThanHour()"
      fxFlex
      type="warning"
      [message]="
        t('share-to-customers-dialog.share-to-selected-customer-less-an-hour')
      "
    >
    </app-system-message>

    <!-- Boutons -->
    <mat-dialog-actions align="end">
      <button mat-raised-button [mat-dialog-close]="false">
        {{ t('share-to-customers-dialog.cancel') }}
      </button>
      <button mat-raised-button color="accent" (click)="share()">
        {{ t('share-to-customers-dialog.share') }}
      </button>
    </mat-dialog-actions>
  </div>
</ng-container>
