import { Observable } from 'rxjs';

import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';

import { getPremiumAccount } from '@arrivage-premium-accounts/store/premium-accounts.selectors';
import { State } from '@arrivage-store/state';
import { PremiumAccount } from '@arrivage/model/dist/src/model';

@Injectable({
  providedIn: 'root',
})
export class PremiumAccountFacade {
  private premiumAccount$: Observable<PremiumAccount>;

  constructor(private store: Store<State>) {
    this.premiumAccount$ = this.store.select(getPremiumAccount);
  }

  getPremiumAccount(): Observable<PremiumAccount> {
    return this.premiumAccount$;
  }
}
