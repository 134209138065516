import * as _ from 'lodash';

import { createFeatureSelector, createSelector } from '@ngrx/store';

import { createSelectors } from '@arrivage-store/generators';
import { Product } from '@arrivage/model/dist/src/model';

import { adapter } from './products.reducer';
import { ProductsState, State } from './products.state';

export const getProductState = createFeatureSelector<ProductsState>('products');

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
  connecting,
  connected,
  connectState,
  queryFailure,
  addFailure,
  setFailure,
  updateFailure,
  removeFailure,
  getById,
  selectActiveItem,
  isLoadingActiveItem,
  selectActiveItemState,
} = createSelectors<Product, State>(adapter, getProductState);

export const selectAllProductCategories = createSelector(
  selectAll,
  (products) =>
    _.map(
      _.uniqBy(
        products.filter((p) => p.category),
        (p) => p.category.original
      ),
      (p) => p.category
    )
);

export const selectAllProductSubCategories = createSelector(
  selectAll,
  (products) =>
    _.map(
      _.uniqBy(
        products.filter((p) => p.subCategory),
        (p) => p.subCategory.original
      ),
      (p) => p.subCategory
    )
);

export const selectAllProductTypes = createSelector(selectAll, (products) =>
  _.map(
    _.uniqBy(
      products.filter((p) => p.type),
      (p) => p.type.original
    ),
    (p) => p.type
  )
);

export const selectAllProducers = createSelector(selectAll, (products) =>
  _.map(
    _.uniqBy(
      products.filter((p) => p.resellingFor),
      (p) => p.resellingFor
    ),
    (p) => p.resellingFor
  )
);
