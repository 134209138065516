import { from } from 'rxjs';

import { Injectable } from '@angular/core';
import { Functions, httpsCallable } from '@angular/fire/functions';

import { map, retryWhen, take } from 'rxjs/operators';

import { genericRetryStrategy } from '@arrivage-util/api.utils';
import { ShareOfferRequestData } from '@arrivage/model/dist/src/cloud-functions-api/offers-api';

@Injectable({
  providedIn: 'root',
})
export class VendorOffersApiService {
  private readonly shareOfferCloudFunction = httpsCallable<
    ShareOfferRequestData,
    void
  >(this.functions, 'shareOffer');

  constructor(private functions: Functions) {}

  shareOffer(shareOfferRequestData: ShareOfferRequestData): Promise<void> {
    return from(this.shareOfferCloudFunction(shareOfferRequestData))
      .pipe(
        retryWhen(genericRetryStrategy()),
        take(1),
        map((r) => r.data)
      )
      .toPromise();
  }
}
