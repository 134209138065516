import { from } from 'rxjs';

import { Injectable } from '@angular/core';
import {
  collection,
  Firestore,
  getDocs,
  limit,
  query,
  where,
} from '@angular/fire/firestore';

import { map, take } from 'rxjs/operators';

import { PurchaseOrderState } from '@arrivage/model/dist/src/model';

import { BasePurchaseOrderService } from './../../common/services/base-purchase-order.service';
import { TransactionDocumentIdFields } from './../../common/services/purchase-orders-id-fields';

@Injectable({
  providedIn: 'root',
})
export class VendorPurchaseOrderService extends BasePurchaseOrderService {
  constructor(firestore: Firestore) {
    super(firestore, TransactionDocumentIdFields.ID_FIELDS.VENDOR);
  }

  hasOpenPurchaseOrders(
    organizationId: string,
    deliveryId: string
  ): Promise<boolean> {
    const queryConstraints = [
      where('vendor.organizationId', '==', organizationId),
      where('state', '==', PurchaseOrderState.OPEN),
      where('delivery.id', '==', deliveryId),
      limit(1),
    ];
    return from(
      getDocs(
        query(
          collection(
            this.firestore,
            VendorPurchaseOrderService.PURCHASE_ORDERS_COLLECTION
          ),
          ...queryConstraints
        )
      )
    )
      .pipe(
        map((q) => !q.empty),
        take(1)
      )
      .toPromise();
  }
}
