import { Component } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';

import { LanguageService } from '../../service/language.service';

@Component({
  selector: 'app-language-selector',
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.scss'],
})
export class LanguageSelectorComponent {
  allLanguages = this.languageService.getAllLanguages();
  currentLanguage$ = this.languageService.languageChanges$;

  constructor(private languageService: LanguageService) {}

  changeLanguage(change: MatSelectChange) {
    this.languageService.changeLanguage(change.value);
  }
}
