<mat-card *ngIf="errorCode$ | async as errorCode" appearance="raised">
  <mat-card-header>
    <mat-card-title> Error: {{ errorCode }} </mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <ng-container *transloco="let t; read: 'errors.page'">
      {{ t(errorCode) }}
    </ng-container>
  </mat-card-content>
</mat-card>
