import { Injectable } from '@angular/core';
import { Firestore, where } from '@angular/fire/firestore';

import { ContactRequestStatus } from '@arrivage/model/dist/src/model/contact-request';

import { BaseContactRequestService } from '../../common/service/base-contact-requests.service';

@Injectable({
  providedIn: 'root',
})
export class SentContactRequestService extends BaseContactRequestService {
  constructor(firestore: Firestore) {
    super(firestore, (organizationId: string) => {
      return [
        where('requestingOrganization.id', '==', organizationId),
        where('status', '==', ContactRequestStatus.PENDING),
      ];
    });
  }
}
