import { EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Action, createReducer } from '@ngrx/store';

import { RelationshipInfo, WithId } from '@arrivage/model/dist/src/model';

import {
  createEntityReducer,
  createInitialState,
} from '../../../store/generators';
import * as relationshipInfosActions from './relationship-infos.actions';
import { RelationshipInfosState } from './relationship-infos.state';

export const adapter: EntityAdapter<RelationshipInfo & WithId> =
  createEntityAdapter<RelationshipInfo & WithId>();

export const initialState: RelationshipInfosState = createInitialState(adapter);

const relationshipInfosReducer = createReducer(
  initialState,
  ...createEntityReducer(relationshipInfosActions, adapter)
);

export function reducer(
  state: RelationshipInfosState | undefined,
  action: Action
) {
  return relationshipInfosReducer(state, action);
}
