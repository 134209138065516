import * as fromRoot from '@arrivage-store/state';

import { AuthUser } from '../model/auth-data.model';

export interface AuthState {
  isSignInInProgress: boolean;
  user: AuthUser;
  errorCode: string;
  triggeredlogout: boolean;
}

export const defaultState: AuthState = {
  isSignInInProgress: false,
  user: undefined,
  errorCode: undefined,
  triggeredlogout: false,
};

export interface State extends fromRoot.State {
  auth: AuthState;
}
