import { TranslocoModule } from '@jsverse/transloco';
import { MaterialModule } from 'src/app/material.module';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';

import { TagsAutoInputComponent } from './tags-auto-input.component';

@NgModule({
  declarations: [TagsAutoInputComponent],
  imports: [
    CommonModule,
    MaterialModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    TranslocoModule,
  ],
  exports: [TagsAutoInputComponent],
})
export class TagsAutoInputModule {}
