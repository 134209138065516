import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'app-label-not-local',
  templateUrl: './label-not-local.component.html',
  styleUrls: ['./label-not-local.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LabelNotLocalComponent {
  @Input()
  small = false;

  constructor() {}
}
