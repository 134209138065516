<ng-container
  *transloco="let t; scope: 'organization'"
  appFormIsDirty
  (isDirtyChanges)="onDirtyChanges($event)"
>
  <form
    fxLayout="column"
    fxLayoutGap="16px"
    appRegisterForm="form"
    [checkDirty]="form.dirty"
    [formGroup]="form"
    (ngSubmit)="onSubmit()"
    *transloco="let g"
  >
    <!-- MARK: COMMON SECTION
     -->
    <section fxLayout="column" fxLayoutGap="30px">
      <section fxLayout="column">
        <article fxLayoutAlign="start center" fxLayoutGap="10px">
          <h2 class="bold">
            {{
              organization.isSeller
                ? t('organization.settings.sales-contact')
                : t('organization.settings.orders-contact')
            }}
          </h2>
          <mat-icon
            class="material-icons-outlined"
            [customTooltip]="
              organization.isSeller
                ? t('organization.settings.sales-contact-help')
                : t('organization.settings.orders-contact-help')
            "
            >help_outline</mat-icon
          >
        </article>
        <!-- CONTACT DETAILS -->
        <mat-card appearance="raised">
          <mat-card-content>
            <app-contact-info-form
              #contactInfoForm
              [parentForm]="form"
              [requireAtLeastOnePhoneNumber]="true"
              [isOrganizationContactForm]="true"
              [contactInfo]="organization.contactInfo"
              (mainEmailChange)="onMainEmailChange($event)"
            >
            </app-contact-info-form>

            <!-- MARK: DELIVERY INSTRUCTION FOR BUYER
             -->
            <div *ngIf="organization.isBuyer" fxLayout="column">
              <div fxLayoutAlign="start center" fxLayoutGap="10px">
                <h3 class="bold">
                  {{ t('organization.settings.delivery-instruction-title') }}
                </h3>
                <mat-icon
                  class="material-icons-outlined icon-ms"
                  [customTooltip]="
                    t('organization.settings.delivery-instruction-description')
                  "
                  >help_outline</mat-icon
                >
              </div>

              <mat-form-field appearance="outline">
                <mat-label>{{
                  t('organization.settings.delivery-instruction-placeholder')
                }}</mat-label>
                <textarea
                  matInput
                  type="text"
                  formControlName="deliveryInstructions"
                  data-testid="delivery-instructions-input-test"
                ></textarea>
              </mat-form-field>
            </div>
          </mat-card-content>
        </mat-card>
      </section>

      <!-- MARK: SELLER SECTION
       -->
      <ng-container *ngIf="organization.isSeller">
        <section fxLayout="column">
          <h2 class="bold">{{ t('organization.settings.seller') }}</h2>
          <div fxLayout="column" fxLayoutGap="20px">
            <!-- SALES SETTINGS -->
            <mat-card appearance="raised">
              <mat-card-content formGroupName="purchaseOrderForm">
                <h3 class="bold">
                  {{ t('organization.settings.purchase-order') }}
                </h3>
                <section fxLayout="column" fxLayoutGap="20px">
                  <!-- MARK: AUTO-APPROVE
                   -->
                  <section fxLayout="row" fxLayoutAlign="space-between center">
                    <mat-checkbox
                      id="autoApprovePurchaseOrder"
                      data-testid="auto-approve-checkbox-testid"
                      formControlName="autoApprovePurchaseOrder"
                    >
                      {{ t('organization.settings.auto-approve') }}
                    </mat-checkbox>
                  </section>
                  <!--MARK: PURCHASE ORDER PREFIX
                  -->
                  <section fxLayout="row" fxLayoutAlign="space-between center">
                    <div>
                      <p>
                        {{ t('organization.settings.purchase-order-prefix') }}:
                      </p>
                    </div>

                    <section fxLayout="column">
                      <mat-form-field
                        class="purchase-order-prefix"
                        fxLayout="column"
                      >
                        <mat-label>{{
                          t('organization.settings.purchase-order-prefix')
                        }}</mat-label>
                        <input
                          matInput
                          type="text"
                          id="purchaseOrderPrefix"
                          data-testid="purchase-order-prefix-testid"
                          formControlName="purchaseOrderPrefix"
                        />
                      </mat-form-field>
                      <div class="description">
                        (ex:
                        {{
                          form.get('purchaseOrderForm.purchaseOrderPrefix')
                            .value
                        }}123456-7890)
                      </div>
                    </section>
                  </section>
                </section>
              </mat-card-content>
            </mat-card>

            <mat-card appearance="raised">
              <!-- MARK: TAXES
               -->
              <mat-card-content>
                <div fxLayout="row" fxLayoutAlign="space-between center">
                  <h3 class="bold">
                    {{ t('organization.settings.taxes') }}
                  </h3>
                  <button
                    mat-button
                    type="button"
                    class="mat-elevation-z3"
                    (click)="onAddTax()"
                  >
                    {{ t('organization.settings.add-tax-button') }}
                  </button>
                </div>

                <mat-list
                  *ngIf="
                    organizationSettings.sales?.taxes?.length !== 0;
                    else noSalesTax
                  "
                >
                  <mat-list-item
                    *ngFor="
                      let tax of organizationSettings.sales?.taxes;
                      trackBy: trackByIdNameIndex;
                      let i = index;
                      let last = last
                    "
                  >
                    <article matListItemTitle>
                      <span
                        >{{ tax.tax.name | translate }} ({{
                          tax.tax.rate
                        }}%)</span
                      >
                    </article>
                    <article matListItemLine>
                      <span class="label">
                        {{ t('organization.settings.tax-number') }} :
                      </span>
                      <ng-container *ngIf="tax.taxNumber; else noTaxNumber"
                        >{{ tax.taxNumber }}
                      </ng-container>
                      <ng-template #noTaxNumber>
                        {{ t('organization.settings.no-tax-number') }}
                      </ng-template>
                    </article>
                    <button
                      matListItemMeta
                      mat-icon-button
                      data-testid="tax-delete-button-test-id"
                      type="button"
                      [customTooltip]="
                        t('organization.settings.delete-tax-button')
                      "
                      (click)="onDeleteTax(i)"
                    >
                      <mat-icon color="warn">delete</mat-icon>
                    </button>
                    <button
                      matListItemMeta
                      mat-icon-button
                      type="button"
                      [customTooltip]="
                        t('organization.settings.edit-tax-button')
                      "
                      (click)="onEditTax(i)"
                    >
                      <mat-icon>edit</mat-icon>
                    </button>

                    <mat-divider></mat-divider>
                  </mat-list-item>
                </mat-list>
                <ng-template #noSalesTax>
                  {{ t('organization.settings.no-tax') }}
                </ng-template>
              </mat-card-content>

              <mat-card-content>
                <h3 class="bold">
                  {{ t('organization.settings.delivery-fee-applicable-taxes') }}
                </h3>
                <div fxLayout="row" fxLayoutAlign="space-between center">
                  <p>
                    {{
                      t(
                        'organization.settings.delivery-fee-applicable-taxes-notice'
                      )
                    }}:
                  </p>
                  <div>
                    <mat-form-field>
                      <mat-select
                        multiple
                        data-testid="tax-select-id"
                        formControlName="deliveryFeeApplicableTaxes"
                      >
                        <mat-option
                          *ngIf="
                            !organizationSettings.sales?.taxes ||
                              organizationSettings.sales?.taxes.length === 0;
                            else hasDatas
                          "
                          [disabled]="true"
                        >
                          {{ t('components.data-selector.no-data') }}
                        </mat-option>
                        <ng-template #hasDatas>
                          <mat-option
                            *ngFor="
                              let tax of organizationSettings.sales?.taxes
                            "
                            [value]="tax.taxId"
                          >
                            {{ tax.tax.name | translate }}
                          </mat-option>
                        </ng-template>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
              </mat-card-content>
            </mat-card>

            <!--MARK: INVOICING
            -->
            <mat-card appearance="raised">
              <mat-card-content
                formGroupName="invoiceForm"
                fxLayout="column"
                fxLayoutGap="2rem"
              >
                <!-- TITLE -->
                <h3 class="bold">
                  {{ t('organization.settings.invoicing') }}
                </h3>

                <!-- ISSUER INFORMATION -->
                <section fxLayout="column" fxLayoutAlign="start start">
                  <p>
                    {{ t('organization.settings.invoicing-coordinates') }}
                  </p>
                  <section
                    fxLayout="row wrap"
                    fxLayoutAlign="space-between"
                    class="invoicing-information-section"
                  >
                    <article
                      fxLayout="column"
                      fxLayoutAlign="start left"
                      class="invoicing-name-and-contact-article"
                    >
                      <mat-form-field>
                        <mat-label>{{
                          t('organization.settings.invoice-legal-name')
                        }}</mat-label>
                        <input
                          matInput
                          type="text"
                          formControlName="invoicingLegalName"
                        />
                      </mat-form-field>
                      <article
                        fxLayout="column"
                        fxLayoutAlign="start left"
                        class="contact-form-article"
                      >
                        <app-contact-info-form
                          #invoicingContactForm
                          class="contact-form"
                          [requireAtLeastOnePhoneNumber]="true"
                          [isOrganizationContactForm]="true"
                          [contactInfo]="
                            organizationSettings.sales?.invoicing
                              ?.invoicingContactInformation ??
                            organization.contactInfo
                          "
                        >
                        </app-contact-info-form>
                      </article>
                    </article>

                    <article
                      fxLayout="column"
                      fxLayoutAlign="start left"
                      class="address-form-article"
                    >
                      <app-address-form
                        #invoicingAddressForm
                        class="address-form"
                        [address]="
                          organizationSettings?.sales?.invoicing
                            ?.invoicingAddress ?? organization.address
                        "
                      ></app-address-form>
                    </article>
                  </section>
                </section>

                <!-- MARK: PAYMENT TERMS
                 -->
                <section class="thin-paragraphs">
                  <div
                    fxLayout="row"
                    fxLayoutAlign="start center"
                    fxLayoutGap="1rem"
                  >
                    <p>
                      {{ t('organization.settings.payment-terms') }}
                    </p>
                    <mat-icon
                      [customTooltip]="
                        t('organization.settings.payment-terms-description')
                      "
                      >help_outline</mat-icon
                    >
                  </div>
                  <mat-form-field class="small-input">
                    <mat-label>{{
                      t('organization.settings.payment-terms-placeholder')
                    }}</mat-label>
                    <input
                      name="payment-terms"
                      required
                      data-testid="payment-terms-test-id"
                      matInput
                      type="number"
                      min="0"
                      max="9999"
                      formControlName="paymentTermsInDays"
                    />
                    <p matTextSuffix>
                      {{ t('organization.settings.payment-terms-day-suffix') }}
                    </p>
                    <mat-error *ngIf="hasPaymentTermsMinError">{{
                      t('organization.settings.payment-terms-day-min-error')
                    }}</mat-error>
                    <mat-error *ngIf="hasPaymentTermsIntegerError">{{
                      t('organization.settings.payment-terms-day-integer-error')
                    }}</mat-error>
                  </mat-form-field>
                </section>

                <!--MARK: PAYMENT METHODS
                -->
                <section class="thin-paragraphs">
                  <p>
                    {{ t('organization.settings.payment-methods') }}
                  </p>
                  <mat-form-field class="payment-methods">
                    <mat-label>{{
                      t('organization.settings.payment-methods-placeholder')
                    }}</mat-label>
                    <mat-select
                      formControlName="paymentMethods"
                      [multiple]="true"
                      data-testid="payment-methods-select-testid"
                    >
                      <mat-option
                        *ngFor="let paymentMethod of PAYMENT_METHODS"
                        [value]="paymentMethod"
                      >
                        {{ t('enums.payment-methods.' + paymentMethod) }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </section>

                <!--MARK: PAYMENT INSTRUCTIONS
                -->
                <section class="form thin-paragraphs">
                  <p>
                    {{ t('organization.settings.payment-instructions') }}
                  </p>
                  <p class="description">
                    {{ t('organization.settings.payment-instructions-help') }}
                  </p>
                  <mat-form-field class="full-width" appearance="outline">
                    <textarea
                      matInput
                      formControlName="paymentInstructions"
                      data-testid="payment-instructions-input-test"
                    >
                    </textarea>
                  </mat-form-field>
                </section>

                <!-- INVOICE FOOTER -->
                <section class="form thin-paragraphs">
                  <p>
                    {{ t('organization.settings.invoice-footer-message') }}
                  </p>
                  <p class="description">
                    {{ t('organization.settings.invoice-footer-help') }}
                  </p>
                  <mat-form-field class="full-width" appearance="outline">
                    <textarea
                      matInput
                      formControlName="invoiceFooter"
                      data-testid="invoice-footer-test-id"
                    >
                    </textarea>
                  </mat-form-field>
                </section>
              </mat-card-content>
            </mat-card>
          </div>
        </section>

        <!--MARK: PRODUCERS
        -->
        <section fxLayout="column">
          <h2 class="bold">
            {{ t('organization.settings.producer-settings-title') }}
          </h2>

          <mat-card appearance="raised">
            <mat-card-content
              class="form thin-paragraphs"
              formGroupName="producerForm"
            >
              <!-- GENERAL MESSAGE FOR PRODUCER -->
              <section>
                <h3 class="bold">
                  {{ t('organization.settings.producer-message-title') }}
                </h3>
                <p class="description">
                  {{ t('organization.settings.producer-message-description') }}
                </p>

                <mat-form-field class="full-width" appearance="outline">
                  <textarea
                    matInput
                    formControlName="generalMessageForProducers"
                  ></textarea>
                </mat-form-field>
              </section>

              <!-- GENERAL COMMISSION FOR PRODUCER -->
              <section>
                <h3 class="bold">
                  {{ t('organization.settings.commission-title') }}
                </h3>
                <p class="description">
                  {{ t('organization.settings.commission-description') }}
                </p>

                <mat-form-field class="small-input">
                  <mat-label>{{
                    t('organization.settings.commission-label')
                  }}</mat-label>
                  <input
                    matInput
                    type="number"
                    min="0"
                    max="100"
                    data-testid="general-commission-testid"
                    formControlName="generalCommissionForProducers"
                  />
                  <p matTextSuffix="">%</p>
                  <mat-error *ngIf="hasGeneralCommissionMinError">{{
                    t('organization.settings.commission-min-error')
                  }}</mat-error>
                  <mat-error *ngIf="hasGeneralCommissionMaxError">{{
                    t('organization.settings.commission-max-error')
                  }}</mat-error>
                </mat-form-field>
              </section>
            </mat-card-content>
          </mat-card>
        </section>
      </ng-container>

      <!-- MARK: COMMON LEGAL INFO
       -->
      <section fxLayout="column" class="input legal-section">
        <h2 class="bold">{{ t('organization.settings.legal-section') }}</h2>
        <mat-card appearance="raised">
          <mat-card-content fxLayout="column" fxLayoutGap="3rem">
            <div fxLayout="column" fxLayoutGap="2rem">
              <div
                fxLayout="row"
                fxLayoutAlign="space-between center"
                fxLayoutGap="32px"
              >
                <mat-form-field>
                  <mat-label>{{
                    t('organization.settings.legal-name')
                  }}</mat-label>
                  <input
                    matInput
                    formControlName="legalName"
                    data-testid="legal-name-input-test"
                  />
                </mat-form-field>
              </div>
              <div
                fxLayout="row"
                fxLayoutAlign="space-between center"
                fxLayoutGap="32px"
              >
                <mat-form-field>
                  <mat-label>{{ t('organization.settings.neq') }}</mat-label>
                  <input
                    matInput
                    type="text"
                    formControlName="neq"
                    data-testid="neq-input-test"
                  />
                </mat-form-field>
              </div>

              <mat-form-field *ngIf="organization.isSeller">
                <mat-label>{{ t('organization.settings.nim') }}</mat-label>
                <input
                  matInput
                  formControlName="nim"
                  data-testid="nim-input-test"
                />
              </mat-form-field>
            </div>
          </mat-card-content>
        </mat-card>
      </section>
      <!-- MARK: COMMUNICATION PREFERENCES
        -->
      <section fxLayout="column">
        <h2 class="bold">
          {{ t('organization.settings.preferences') }}
        </h2>
        <mat-card appearance="raised">
          <mat-card-content formGroupName="preferencesForm">
            <section
              fxLayout="column"
              fxLayoutAlign="start start"
              fxLayoutGap="1em"
            >
              <mat-form-field class="language-section__selector">
                <mat-label>{{ t('organization.settings.language') }}</mat-label>
                <mat-select
                  formControlName="language"
                  data-testid="language-select-testid"
                >
                  <mat-option
                    *ngFor="let language of ALL_LANGUAGES"
                    [value]="language"
                  >
                    {{ ALL_LANGUAGES_FULL_NAME[language] }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </section>

            <ng-container *ngIf="organization.isBuyer">
              <h3
                class="bold"
                fxLayout="row"
                fxLayoutGap="1rem"
                fxLayoutAlign="start center"
              >
                <span>{{
                  t('organization.settings.communications-preferences-title')
                }}</span
                ><mat-icon
                  class="material-icons-outlined"
                  [customTooltip]="
                    g('help-box.preferences-organization-settings-page')
                  "
                  >help_outline</mat-icon
                >
              </h3>
              <small>{{
                t('organization.settings.communications-preferences-subtitle')
              }}</small>
              <app-communication-preference-table
                [emailsOptions]="communicationPreferencesEmailsOptions$ | async"
                [customerOrganization]="organization"
                [customerOrganizationSettings]="organizationSettings"
                [mainEmail]="mainEmail"
                [parentForm]="form"
                (newEmail)="onAddNewEmail($event)"
                (deleteEmail)="onDeleteEmail($event)"
                (selectedEmailsPreferences)="
                  onSelectedEmailsPreferences($event)
                "
              ></app-communication-preference-table>
            </ng-container>
          </mat-card-content>
        </mat-card>
      </section>
    </section>

    <ng-container *ngTemplateOutlet="buttons"></ng-container>
    <ng-template #buttons>
      <section
        fxLayout="row"
        fxLayout.lt-sm="column"
        fxLayoutAlign="end"
        fxLayoutAlign.lt-sm="center none"
        fxLayoutGap="20px"
        fxLayoutGap.lt-sm="1rem"
      >
        <app-system-message
          *ngIf="shouldShowFormErrorMessage"
          fxFlex
          type="error"
          data-testid="submit-button-test"
          [message]="g('form-error-message')"
        >
        </app-system-message>
        <article fxLayoutAlign="end" fxLayoutGap="8px">
          <button mat-raised-button type="button" (click)="onCancel()">
            {{ t('organization.settings.cancel') }}
          </button>
          <button
            data-testid="submit-button-id"
            mat-raised-button
            color="accent"
            type="submit"
          >
            {{ t('organization.settings.save') }}
          </button>
        </article>
      </section>
    </ng-template>
  </form>
</ng-container>
