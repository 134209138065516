import { Interval } from 'date-fns';
import _ from 'lodash';
import { combineLatest, map, Observable, of } from 'rxjs';

import {
  Firestore,
  orderBy,
  QueryFieldFilterConstraint,
  QueryOrderByConstraint,
  Timestamp,
  where,
} from '@angular/fire/firestore';

import { BaseService, PathSpec } from '@arrivage-services/base.service';
import {
  EntityChangeAction,
  OrganizationEntityService,
} from '@arrivage-services/organization-entity.service';
import { Invoice, InvoiceStatus, WithId } from '@arrivage/model/dist/src/model';
import { CollectionNames } from '@arrivage/model/dist/src/utils';

export class BaseInvoiceService
  extends BaseService<Invoice>
  implements OrganizationEntityService<Invoice>
{
  static readonly INVOICES_COLLECTION = CollectionNames.INVOICE_COLLECTION;

  protected static readonly ALL_INVOICES_STATUSES: InvoiceStatus[] =
    Object.values(InvoiceStatus);

  static convertTimestampFields(firebaseInvoice: any): Invoice {
    if (!firebaseInvoice) {
      return undefined;
    }

    const invoice: Invoice & WithId = {
      ...firebaseInvoice,
    };
    if (firebaseInvoice.dueDate) {
      invoice.dueDate = (firebaseInvoice.dueDate as any as Timestamp).toDate();
    }
    if (firebaseInvoice.deliveryDate) {
      const d = (firebaseInvoice.deliveryDate as any as Timestamp).toDate();
      invoice.deliveryDate = new Date(
        d.getUTCFullYear(),
        d.getUTCMonth(),
        d.getUTCDate()
      );
    }
    if (firebaseInvoice.history && firebaseInvoice.history.creationDate) {
      invoice.history.creationDate = (
        firebaseInvoice.history.creationDate as any as Timestamp
      ).toDate();
    }
    if (firebaseInvoice.history && firebaseInvoice.history.sentDate) {
      invoice.history.sentDate = (
        firebaseInvoice.history.sentDate as any as Timestamp
      ).toDate();
    }
    if (firebaseInvoice.history && firebaseInvoice.history.publishDate) {
      invoice.history.publishDate = (
        firebaseInvoice.history.publishDate as any as Timestamp
      ).toDate();
    }
    if (firebaseInvoice.history && firebaseInvoice.history.paidDate) {
      invoice.history.paidDate = (
        firebaseInvoice.history.paidDate as any as Timestamp
      ).toDate();
    }
    if (firebaseInvoice.history && firebaseInvoice.history.canceledDate) {
      invoice.history.canceledDate = (
        firebaseInvoice.history.canceledDate as any as Timestamp
      ).toDate();
    }
    return invoice;
  }

  constructor(
    firestore: Firestore,
    protected organizationIdField: string,
    protected openedStatus: InvoiceStatus[],
    protected visibleStatuses: InvoiceStatus[],
    protected dateFieldToQuery: string
  ) {
    super(firestore);
  }

  connect(organizationId: string): Observable<EntityChangeAction<Invoice>[]> {
    return this._connect(
      this.pathSpec(),
      BaseInvoiceService.convertTimestampFields,
      null,
      where(this.organizationIdField, '==', organizationId),
      where('status', 'in', this.openedStatus)
    );
  }

  list(
    organizationId: string,
    dateRange?: Interval,
    status?: InvoiceStatus
  ): Observable<(Invoice & WithId)[]> {
    const queryConstraints: (
      | QueryFieldFilterConstraint
      | QueryOrderByConstraint
    )[] = [where(this.organizationIdField, '==', organizationId)];

    if (dateRange) {
      queryConstraints.push(
        where(this.dateFieldToQuery, '>=', dateRange.start)
      );
      queryConstraints.push(where(this.dateFieldToQuery, '<=', dateRange.end));
    }

    if (status) {
      queryConstraints.push(where('status', '==', status));
    } else {
      queryConstraints.push(where('status', 'in', this.visibleStatuses));
    }

    queryConstraints.push(orderBy(this.dateFieldToQuery, 'desc'));

    return this._list(
      this.pathSpec(),
      BaseInvoiceService.convertTimestampFields,
      ...queryConstraints
    );
  }

  listRelatedInvoices(
    purchaseOrderIds: string[]
  ): Observable<(Invoice & WithId)[]> {
    if (purchaseOrderIds.length) {
      const purchaseOrderIdsChunk = _.chunk(purchaseOrderIds, 30);
      const queryResults: Observable<(Invoice & WithId)[]>[] =
        purchaseOrderIdsChunk.map((ids) => {
          return this._list(
            this.pathSpec(),
            BaseInvoiceService.convertTimestampFields,
            where('history.publishDate', '>', new Date(0)),
            where('purchaseOrderId', 'in', ids)
          );
        });

      return combineLatest(queryResults).pipe(
        map((results) => _.flatten(results))
      );
    } else {
      return of([]);
    }
  }

  create(organizationId: string, invoice: Invoice): Promise<string> {
    return this._create(this.pathSpec(), invoice);
  }

  get(organizationId: string, invoiceId: string): Observable<Invoice & WithId> {
    return this._get(
      this.pathSpec(),
      invoiceId,
      BaseInvoiceService.convertTimestampFields
    );
  }

  set(
    organizationId: string,
    recordId: string,
    record: Invoice
  ): Promise<void> {
    return this._set(this.pathSpec(), recordId, record);
  }

  update(
    organizationId: string,
    invoiceId: string,
    invoice: Partial<Invoice>
  ): Promise<void> {
    return this._update(this.pathSpec(), invoiceId, invoice);
  }

  remove(organizationId: string, invoiceId: string): Promise<void> {
    return this._delete(this.pathSpec(), invoiceId);
  }

  protected pathSpec(): PathSpec[] {
    return [{ collection: BaseInvoiceService.INVOICES_COLLECTION }];
  }
}
