<mat-card-content *transloco="let t; scope: 'offers'; read: 'offers'">
  <h2 class="mat-dialog-title space-bottom text-xl">
    {{ t('dialog-expiration-offer.title') }}
  </h2>
  <div class="mat-dialog-content" fxLayout="column" fxLayoutGap="20px">
    <app-system-message
      type="info"
      [message]="t('dialog-expiration-offer.expiration-date-notice')"
    ></app-system-message>
    <ng-container [formGroup]="form">
      <mat-form-field class="expiration-date-input">
        <mat-label>
          {{ t('dialog-expiration-offer.label-expiration') }}</mat-label
        >
        <input
          matInput
          formControlName="expirationDate"
          [min]="minDate"
          [max]="maxDate"
          [matDatepicker]="expirationDate"
          [placeholder]="t('dialog-expiration-offer.placeholder-expiration')"
          (click)="expirationDate.open()"
        />
        <mat-error>{{
          t('dialog-expiration-offer.error-expiration')
        }}</mat-error>
        <mat-datepicker-toggle
          matSuffix
          [for]="expirationDate"
        ></mat-datepicker-toggle>
        <mat-datepicker #expirationDate></mat-datepicker>
      </mat-form-field>
    </ng-container>
  </div>
  <mat-dialog-actions class="no-space-bottom" align="end">
    <button mat-button [mat-dialog-close]="false">
      {{ t('dialog-expiration-offer.cancel') }}
    </button>
    <button mat-raised-button color="accent" (click)="shareOffer()">
      {{ t('dialog-expiration-offer.confirm') }}
    </button>
  </mat-dialog-actions>
</mat-card-content>
