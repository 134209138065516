import { SelectionModel } from '@angular/cdk/collections';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';

import { AddEmailDialogComponent } from '@arrivage-components/add-email-dialog/add-email-dialog.component';
import {
  Organization,
  OrganizationSettings,
  RelationshipInfo,
  WithId,
} from '@arrivage/model/dist/src/model';

export interface SelectedEmailsPreferences {
  priceListEmailsPreference?: string[];
  purchaseOrderEmailsPreference?: string[];
  invoicingEmailsPreference?: string[];
}

export interface AddEmailDialogData {
  existingEmails: string[];
}

@Component({
  selector: 'app-communication-preference-table',
  templateUrl: './communication-preference-table.component.html',
  styleUrls: ['./communication-preference-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommunicationPreferenceTableComponent implements OnInit {
  @Input()
  relationshipInfo?: RelationshipInfo & WithId;

  @Input()
  customerOrganizationSettings?: OrganizationSettings & WithId;

  @Input()
  customerOrganization?: Organization & WithId;

  @Input()
  mainEmail: string;

  @Input()
  set emailsOptions(emails: string[]) {
    if (emails) {
      this._emailsOptions = emails;
      this.dataSource.data = emails;
    }
  }

  get emailsOptions() {
    return this._emailsOptions;
  }

  @Input()
  buyerHasAccount: boolean;

  @Input()
  parentForm: UntypedFormGroup;

  @Output()
  newEmail = new EventEmitter<string>();

  @Output()
  deleteEmail = new EventEmitter<string>();

  @Output()
  selectedEmailsPreferences = new EventEmitter<SelectedEmailsPreferences>();

  _emailsOptions: string[];

  dataSource: MatTableDataSource<string> = new MatTableDataSource([]);
  displayedColumns: string[] = [
    'email',
    'priceListEmailSelect',
    'purchaseOrderEmailSelect',
    'invoicingEmailSelect',
    'deleteEmail',
  ];

  priceListEmailsSelection = new SelectionModel<string>(true, []);
  purchaseOrderEmailsSelection = new SelectionModel<string>(true, []);
  invoicingEmailsSelection = new SelectionModel<string>(true, []);

  constructor(private dialog: MatDialog) {}

  ngOnInit(): void {
    this.initializePreferencesTable();
    this.emitSelectedEmailsPreference();
    this.setEmailPreferencesChangedFlag();
  }

  selectionChange(
    emailSelected: string,
    selectionModel: SelectionModel<string>
  ) {
    this.setEmailPreferencesChanged();
    selectionModel.toggle(emailSelected);
    this.emitSelectedEmailsPreference();
  }

  isMainEmail(email: string): boolean {
    if (!this.mainEmail) return false;
    return this.mainEmail === email;
  }

  isMainEmailValid(): boolean {
    return this.mainEmail && this.mainEmail.length > 0;
  }

  onAddEmail() {
    const dialogRef = this.dialog.open<
      AddEmailDialogComponent,
      AddEmailDialogData
    >(AddEmailDialogComponent, {
      width: '600px',
      data: { existingEmails: this.emailsOptions },
    });
    dialogRef.afterClosed().subscribe((emailEntered) => {
      if (emailEntered) {
        this.setEmailPreferencesChanged();

        this.newEmail.emit(emailEntered);
      }
    });
  }

  onDeleteEmail(email: string) {
    if (this.priceListEmailsSelection.isSelected(email)) {
      this.priceListEmailsSelection.toggle(email);
    }
    if (this.purchaseOrderEmailsSelection.isSelected(email)) {
      this.purchaseOrderEmailsSelection.toggle(email);
    }
    if (this.invoicingEmailsSelection.isSelected(email)) {
      this.invoicingEmailsSelection.toggle(email);
    }

    this.setEmailPreferencesChanged();

    this.emitSelectedEmailsPreference();

    this.deleteEmail.emit(email);
  }

  cancelChanges() {
    this.priceListEmailsSelection.clear();
    this.purchaseOrderEmailsSelection.clear();
    this.invoicingEmailsSelection.clear();
    this.resetEmailPreferencesChanged();
    this.initializePreferencesTable();
    this.emitSelectedEmailsPreference();
  }

  private initializePreferencesTable() {
    if (this.relationshipInfo) {
      this.relationshipInfo?.priceListEmailsPreference?.forEach(
        (emailPreference) =>
          this.priceListEmailsSelection.toggle(emailPreference)
      );
      this.relationshipInfo?.purchaseOrderEmailsPreference?.forEach(
        (emailPreference) =>
          this.purchaseOrderEmailsSelection.toggle(emailPreference)
      );
      const invoicingEmails = Organization.getInvoicingEmails(
        this.relationshipInfo.invoicingContactInfo
      );
      invoicingEmails.forEach((emailPreference) =>
        this.invoicingEmailsSelection.toggle(emailPreference)
      );
    }

    if (this.customerOrganizationSettings) {
      this.customerOrganizationSettings.preferences?.emailsPreferences?.priceListPreference?.forEach(
        (email) => this.priceListEmailsSelection.toggle(email)
      );
      this.customerOrganizationSettings.preferences?.emailsPreferences?.purchaseOrderPreference?.forEach(
        (email) => this.purchaseOrderEmailsSelection.toggle(email)
      );
      this.customerOrganizationSettings.preferences?.emailsPreferences?.invoicePreference?.forEach(
        (email) => this.invoicingEmailsSelection.toggle(email)
      );
    }
  }

  emitSelectedEmailsPreference() {
    this.selectedEmailsPreferences.emit({
      priceListEmailsPreference: this.priceListEmailsSelection.selected,
      purchaseOrderEmailsPreference: this.purchaseOrderEmailsSelection.selected,
      invoicingEmailsPreference: this.invoicingEmailsSelection.selected,
    });
  }

  //adding a form control only to be able to mark the form as dirty when the preferences are changed for the parent form
  private setEmailPreferencesChangedFlag() {
    this.parentForm.addControl(
      '_communicationPreferencesChanged',
      new FormControl(false)
    );
  }

  private get communicationPreferencesChanged() {
    return this.parentForm.get('_communicationPreferencesChanged');
  }

  private setEmailPreferencesChanged() {
    this.communicationPreferencesChanged.markAsDirty();
  }

  private resetEmailPreferencesChanged() {
    this.communicationPreferencesChanged.markAsPristine();
  }
}
