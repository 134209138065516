<ng-container *transloco="let t; scope: 'products'; read: 'products'">
  <div class="mat-dialog-content space-top">
    <mat-card-content fxLayout="column" fxLayoutGap="2rem">
      <app-product-format-info
        [product]="data.product"
        [offerItems]="data.offerItems"
        [isExtraSmallScreen]="data.isExtraSmallScreen"
        [showPriceAndStock]="data.showPriceAndStock"
        [orderItem]="data.orderItem"
      ></app-product-format-info>
    </mat-card-content>
  </div>
  <mat-dialog-actions align="end">
    <button mat-stroked-button mat-dialog-close>
      {{ t('product-info-dialog.close') }}
    </button>
  </mat-dialog-actions>
</ng-container>
