import { EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { createReducer, Action, on } from '@ngrx/store';

import {
  createInitialState,
  createEntityReducer,
} from '@arrivage-store/generators';
import { Delivery, WithId } from '@arrivage/model/dist/src/model';

import * as DeliveryActions from './customer-delivery.actions';
import { CustomerDeliveryState } from './customer-delivery.state';

export const adapter: EntityAdapter<Delivery & WithId> = createEntityAdapter<
  Delivery & WithId
>();
export const initialState: CustomerDeliveryState = createInitialState(adapter, {
  organizationDeliveries: undefined,
  isLoadingOrganizationDeliveries: false,
  loadingOrganizationDeliveriesError: undefined,
  relationshipDeliveries: undefined,
  isLoadingRelationshipDeliveries: false,
  loadingRelationshipDeliveriesError: undefined,
});

const customerDeliveryReducer = createReducer(
  initialState,
  ...createEntityReducer(DeliveryActions, adapter),
  on(
    DeliveryActions.loadRelationshipDeliveries,
    (state: CustomerDeliveryState) => {
      return {
        ...state,
        relationshipDeliveries: undefined,
        isLoadingRelationshipDeliveries: true,
        loadingRelationshipDeliveriesError: false,
      };
    }
  ),
  on(
    DeliveryActions.loadRelationshipDeliveriesSuccess,
    (state: CustomerDeliveryState, deliveries) => {
      return {
        ...state,
        relationshipDeliveries: deliveries.deliveries,
        isLoadingRelationshipDeliveries: false,
        loadingRelationshipDeliveriesError: false,
      };
    }
  ),
  on(
    DeliveryActions.loadRelationshipDeliveriesFailure,
    (state: CustomerDeliveryState) => {
      return {
        ...state,
        relationshipDeliveries: undefined,
        isLoadingRelationshipDeliveries: false,
        loadingRelationshipDeliveriesError: true,
      };
    }
  )
);

export function reducer(
  state: CustomerDeliveryState | undefined,
  action: Action
) {
  return customerDeliveryReducer(state, action);
}
