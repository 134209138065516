import { TranslocoModule, TRANSLOCO_SCOPE } from '@jsverse/transloco';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { AppUtilsModule } from '../app-utils.module';
import { MaterialModule } from '../material.module';
import { PageErrorRoutingModule } from './page-error-routing.module';
import { PageErrorComponent } from './page-error.component';

@NgModule({
  declarations: [PageErrorComponent],
  exports: [PageErrorComponent],
  providers: [{ provide: TRANSLOCO_SCOPE, useValue: 'errors' }],
  imports: [
    MaterialModule,
    TranslocoModule,
    CommonModule,
    PageErrorRoutingModule,
    AppUtilsModule,
  ],
})
export class PageErrorModule {}
