import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { RelationshipInfosEffects } from './relationship-infos.effects';
import { reducer as relationshipInfoReducer } from './relationship-infos.reducer';
import { RelationshipsEffects } from './relationship.effects';
import { reducer as relationshipReducer } from './relationship.reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('relationships', relationshipReducer),
    StoreModule.forFeature('relationshipInfos', relationshipInfoReducer),
    EffectsModule.forFeature([RelationshipInfosEffects, RelationshipsEffects]),
  ],
})
export class RelationshipStoreModule {}
