<ng-container *transloco="let t; scope: 'feedback'; read: 'feedback.success'">
  <section
    fxLayout="row"
    fxLayoutAlign="space-between center"
    fxLayoutGap="5rem"
    fxLayoutGap.lt-sm="2rem"
  >
    <span matSnackBarLabel class="text-m">
      {{ t(data.message, data.messageParameters) }}
    </span>
    <button matSnackBarAction mat-icon-button (click)="dismiss()">
      <mat-icon class="material-icons-outlined">cancel</mat-icon>
    </button>
  </section>
</ng-container>
