import { of } from 'rxjs';

import { Injectable } from '@angular/core';

import { catchError, map, switchMap } from 'rxjs/operators';

import { Actions, createEffect, ofType } from '@ngrx/effects';

import { reportError } from '@arrivage-sentry/report-error';

import { OrganizationAuthorizationsService } from '../services/permissions.service';
import {
  getAuthorizations,
  getAuthorizationsFailure,
  getAuthorizationsSuccess,
} from './organization-authorizations.actions';

@Injectable()
export class OrganizationAuthorizationsEffects {
  constructor(
    private actions$: Actions,
    private organizationAuthorizationsService: OrganizationAuthorizationsService
  ) {}

  getOrganizationEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(getAuthorizations),
      switchMap((action) => {
        return this.organizationAuthorizationsService
          .getAuthorizations(action.id)
          .pipe(
            map((a) => getAuthorizationsSuccess({ authorizations: a })),
            catchError((e) => {
              reportError(e);
              return of(getAuthorizationsFailure({ error: e }));
            })
          );
      })
    );
  });
}
