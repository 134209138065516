import { Observable } from 'rxjs';

import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';

import { AnalyticsFacade } from '@arrivage-analytics/api/analytics.facade';
import { DeliveryByRelationshipId } from '@arrivage-relationship/common/model/relationship.model';
import { EntitiesFacade } from '@arrivage-store/api/entities.facade';
import { Delivery, RelationshipInfo, WithId } from '@arrivage/model/dist/src/model';

import * as actions from '../store/vendor-delivery.actions';
import * as selectors from '../store/vendor-delivery.selectors';
import { State } from '../store/vendor-delivery.state';
import { DeliveryApiService } from './delivery-api.service';

@Injectable({
  providedIn: 'root',
})
export class VendorDeliveryFacade extends EntitiesFacade<Delivery, State> {
  constructor(
    store: Store<State>,
    private deleteDeliveryApi: DeliveryApiService,
    private analytics: AnalyticsFacade
  ) {
    super(store, actions, selectors);
  }

  override async addItem(item: Delivery): Promise<string> {
    const id = await super.addItem(item);
    this.analytics.logCreateDelivery();
    return id;
  }

  override removeItem(itemId: string): Promise<string> {
    throw new Error('Unsupported, use deleteDelivery');
  }

  async deleteDelivery(organizationId: string, deliveryId: string) {
    await this.deleteDeliveryApi.deleteDelivery({
      organizationId: organizationId,
      deliveryId: deliveryId,
    });
    this.analytics.logDeleteDelivery();
  }

  getAllRelationsDeliveries(): Observable<DeliveryByRelationshipId> {
    return this.store.select(selectors.selectAllRelationsDeliveries);
  }

  getRelationshipInfosByDelivery(): Observable<{
    [deliveryId: string]: (RelationshipInfo & WithId)[];
  }> {
    return this.store.select(selectors.selectRelationshipInfoByDeliveryId);
  }
}
