import { Observable } from 'rxjs';

import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';

import { State } from '@arrivage-store/state';
import { Notification, WithId } from '@arrivage/model/dist/src/model';

import { query, update } from '../store/notifications.actions';
import { selectAllOrderByDate } from '../store/notifications.selectors';

@Injectable({ providedIn: 'root' })
export class NotificationFacade {
  private notifications$: Observable<(Notification & WithId)[]>;

  constructor(private store: Store<State>) {
    this.notifications$ = this.store.select(selectAllOrderByDate);
  }

  loadNotifications() {
    this.store.dispatch(query());
  }

  getAll(): Observable<(Notification & WithId)[]> {
    return this.notifications$;
  }

  markAsRead(notificationId: string) {
    return new Promise((resolve, reject) => {
      this.store.dispatch(
        update({
          record: { id: notificationId, isRead: true },
          confirmation: {
            resolve: resolve,
            reject: reject,
          },
        })
      );
    });
  }
}
