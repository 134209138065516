import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { SentContactRequestsEffects } from './sent-contact-requests.effects';
import { reducer } from './sent-contact-requests.reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('sentContactRequests', reducer),
    EffectsModule.forFeature([SentContactRequestsEffects]),
  ],
})
export class SentContactRequestsStoreModule {}
