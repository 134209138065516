import {
  Department,
  Format,
  InventoryItem,
  PriceList,
  PriceListItem,
  Product,
  WithId,
} from '@arrivage/model/dist/src/model';

export interface PriceListItemLine {
  inventoryItem: InventoryItem & WithId;
  priceListItem: PriceListItem & WithId;
  product: Product & WithId;
  format: Format & WithId;
}

export type PriceListItemLineWithPriceList = PriceListItemLine &
  (PriceList & WithId);

export interface PriceListsDashboardMetrics {
  nbPriceLists: number;
  nbActiveOffers: number;
  nbOffersExpiredSoon: number;
}

export interface PriceListFilters {
  productNameFilter: string;
  departmentFilter: Department[];
  categoryFilter: string;
  shouldShowNonIncludedItems: boolean;
}

export interface PriceListCSVExportData {
  priceList: PriceList & WithId;
  priceListItemLines: PriceListItemLine[];
}

export enum DatesAvailability {
  AVAILABLE = 'available',
  NONE = 'none',
  NONE_WITH_DELAY = 'none_with_delay',
}
