import { Observable } from 'rxjs';

import { Injectable } from '@angular/core';
import { Firestore } from '@angular/fire/firestore';

import { BaseService, PathSpec } from '@arrivage-services/base.service';
import {
  EntityChangeAction,
  OrganizationEntityService,
} from '@arrivage-services/organization-entity.service';
import { Delivery, WithId } from '@arrivage/model/dist/src/model';
import { CollectionNames } from '@arrivage/model/dist/src/utils';

import { DeliveryUtils } from '../utils/delivery.utils';

@Injectable({
  providedIn: 'root',
})
export class DeliveryService
  extends BaseService<Delivery>
  implements OrganizationEntityService<Delivery>
{
  static readonly DELIVERIES_COLLECTION = CollectionNames.DELIVERIES_COLLECTION;

  protected static convertTimestampFields(record: any): Delivery {
    return DeliveryUtils.convertTimestampFields(record);
  }

  constructor(firestore: Firestore) {
    super(firestore);
  }

  get(organizationId: string, recordId: string): Observable<Delivery & WithId> {
    throw new Error('Method not implemented.');
  }

  connect(organizationId: string): Observable<EntityChangeAction<Delivery>[]> {
    throw new Error('Method not implemented.');
  }

  create(organizationId: string, record: Delivery): Promise<string> {
    throw new Error('Method not implemented.');
  }

  set(
    organizationId: string,
    recordId: string,
    record: Delivery
  ): Promise<void> {
    throw new Error('Method not implemented.');
  }

  update(
    organizationId: string,
    recordId: string,
    record: Partial<Delivery>
  ): Promise<void> {
    throw new Error('Method not implemented.');
  }

  remove(organizationId: string, recordId: string): Promise<void> {
    throw new Error('Method not implemented.');
  }

  protected pathSpec(): PathSpec[] {
    return [{ collection: DeliveryService.DELIVERIES_COLLECTION }];
  }
}
