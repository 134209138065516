import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'kebabCase',
})
export class KebabCasePipe implements PipeTransform {
  transform(value: string): string {
    return value
      .trim()
      .replace(/([a-z])([A-Z])/g, '$1-$2') // aA -> a-A
      .replace(/[\s_./\\]+/g, '-')
      .toLowerCase();
  }
}
