import { from } from 'rxjs';

import { Injectable } from '@angular/core';
import { Functions, httpsCallable } from '@angular/fire/functions';

import { map, retryWhen, take } from 'rxjs/operators';

import { genericRetryStrategy } from '@arrivage-util/api.utils';
import {
  AcceptContactRequest,
  DeclineContactRequest,
} from '@arrivage/model/dist/src/cloud-functions-api';

@Injectable({
  providedIn: 'root',
})
export class ContactRequestsApi {
  private readonly acceptContactRequestCloudFunction = httpsCallable<
    AcceptContactRequest,
    void
  >(this.functions, 'acceptContactRequest');
  private readonly declineContactRequestCloudFunction = httpsCallable<
    DeclineContactRequest,
    void
  >(this.functions, 'declineContactRequest');

  constructor(private functions: Functions) {}

  acceptContactRequest(id: string): Promise<void> {
    return from(
      this.acceptContactRequestCloudFunction({ contactRequestId: id })
    )
      .pipe(
        retryWhen(genericRetryStrategy()),
        take(1),
        map((r) => r.data)
      )
      .toPromise();
  }

  declineContactRequest(id: string): Promise<void> {
    return from(
      this.declineContactRequestCloudFunction({ contactRequestId: id })
    )
      .pipe(
        retryWhen(genericRetryStrategy()),
        take(1),
        map((r) => r.data)
      )
      .toPromise();
  }
}
