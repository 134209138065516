import { AppUtilsModule } from 'src/app/app-utils.module';
import { MaterialModule } from 'src/app/material.module';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { TranslocoModule } from '@jsverse/transloco';
import { TranslocoLocaleModule } from '@jsverse/transloco-locale';

import { DataSelectorModule } from '@arrivage-components/data-selector/data-selector.module';
import { OptionSelectInputModule } from '@arrivage-components/option-select-input/option-select-input.module';
import { PurchaseOrderStatusChipModule } from '@arrivage-components/purchase-order-status-chip/purchase-order-status-chip.module';
import { DisplayMoneyAmountPipeModule } from '@arrivage-pipes/display-money-amount-pipe/display-money-amount-pipe.module';
import { PurchaseOrderTotalPipeModule } from '@arrivage-pipes/purchase-order-total-pipe/purchase-order-total-pipe.module';

import { AdminPurchaseOrdersTableComponent } from './admin-purchase-orders-table/admin-purchase-orders-table.component';
import { CreditAdjustementFormComponent } from './credit-adjustement-form/credit-adjustement-form.component';
import { DailyReportComponent } from './daily-report/daily-report.component';
import { LastAccessTimeComponent } from './last-access-time/last-access-time.component';
import { OfferVisibilitySelectorComponent } from './offer-visibility-selector/offer-visibility-selector.component';
import { OrganizationSelectorComponent } from './organization-selector/organization-selector.component';
import { OrganizationsManagementFiltersComponent } from './organizations-management-filters/organizations-management-filters.component';
import { ReportLineComponent } from './report-line/report-line.component';

@NgModule({
  declarations: [
    OrganizationSelectorComponent,
    CreditAdjustementFormComponent,
    ReportLineComponent,
    DailyReportComponent,
    OrganizationsManagementFiltersComponent,
    OfferVisibilitySelectorComponent,
    LastAccessTimeComponent,
    AdminPurchaseOrdersTableComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MaterialModule,
    FormsModule,
    FlexLayoutModule,
    TranslocoModule,
    TranslocoLocaleModule,
    RouterModule,
    AppUtilsModule,
    DataSelectorModule,
    PurchaseOrderStatusChipModule,
    OptionSelectInputModule,
    DisplayMoneyAmountPipeModule,
    PurchaseOrderTotalPipeModule,
  ],
  exports: [
    OrganizationSelectorComponent,
    CreditAdjustementFormComponent,
    DailyReportComponent,
    OrganizationsManagementFiltersComponent,
    OfferVisibilitySelectorComponent,
    LastAccessTimeComponent,
    AdminPurchaseOrdersTableComponent,
  ],
})
export class AdminComponentsModule {}
