<ng-container *transloco="let t; read: 'upload-picture'">
  <div class="upload-picture-component">
    <div fxLayoutAlign="center center">
      @if (pictureUrl) {
        <div
          class="direct-edit-container"
          (click)="isReadOnly || !allowDirectEdit ? null : fileInput.click()"
          data-testid="upload-picture-direct-edit-test-id"
        >
          <img
            mat-button
            [matMenuTriggerFor]="isReadOnly || allowDirectEdit ? null : menu"
            [src]="pictureUrl"
            [class]="imageCssClass ? imageCssClass : 'default'"
            [class.clickable]="!isReadOnly"
          />
          @if (!isReadOnly) {
            <mat-icon class="product-edit-pencil">mode_edit</mat-icon>
          }
        </div>
      } @else if (!isReadOnly) {
        <button
          type="button"
          mat-icon-button
          [ngClass]="{
            default: !allowDirectEdit,
            'product-direct-edit': allowDirectEdit
          }"
          (click)="fileInput.click()"
        >
          <mat-icon class="icon-display">add_photo_alternate</mat-icon>
        </button>
      } @else if (isReadOnly) {
        <div
          fxLayout="column"
          fxLayoutAlign="center center"
          fxLayoutGap="2rem"
          class="no-image-container"
        >
          <mat-icon class="material-symbols-outlined no-photo-icon">
            hide_image
          </mat-icon>
          @if (noPictureReadOnlyText) {
            <span>{{ noPictureReadOnlyText }}</span>
          } @else {
            <span>{{ t('no-image') }}</span>
          }
        </div>
      }
    </div>

    <mat-menu #menu>
      <button type="button" mat-menu-item (click)="fileInput.click()">
        <mat-icon>change_circle</mat-icon>
        <span>{{ t('change-button') }}</span>
      </button>
      <button type="button" mat-menu-item (click)="deletePhoto()">
        <mat-icon>delete</mat-icon>
        <span>{{ t('delete-button') }}</span>
      </button>
    </mat-menu>

    <form [formGroup]="form">
      <input
        #fileInput
        id="file-input"
        type="file"
        accept="image/*"
        formControlName="fileInput"
        (change)="onPictureChange($event)"
      />
      <small *ngIf="showError" class="error">
        {{ t('image-unsupported') }}
      </small>
    </form>
  </div>
</ng-container>
