import { EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Action, createReducer } from '@ngrx/store';

import {
  createInitialState,
  createEntityReducer,
} from '@arrivage-store/generators';
import { PriceList, WithId } from '@arrivage/model/dist/src/model';

import * as PriceListAction from './price-list.actions';
import { PriceListState } from './price-list.state';

export const adapter: EntityAdapter<PriceList & WithId> = createEntityAdapter<
  PriceList & WithId
>();

export const initialState: PriceListState = createInitialState(adapter);

const priceListReducer = createReducer(
  initialState,
  ...createEntityReducer(PriceListAction, adapter)
);

export function reducer(state: PriceListState | undefined, action: Action) {
  return priceListReducer(state, action);
}
