import { Subscription } from 'rxjs';

import { Injectable, OnDestroy } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';

import { LanguageService } from '@arrivage-language/service/language.service';

@Injectable({
  providedIn: 'root',
})
export class MatDynamicPaginatorIntl
  extends MatPaginatorIntl
  implements OnDestroy {
  readonly CONFIG_MAP = {
    ['fr-CA']: {
      firstPageLabel: 'Première page',
      itemsPerPageLabel: 'Items par page',
      nextPageLabel: 'Prochaine page',
      previousPageLabel: 'Page précédente',
      lastPageLabel: 'Dernière page',
      zeroRangeLabelFormat: (length: number) => `0 de ${length}`,
      ofRangeLabelFormat: (
        startIndex: number,
        endIndex: number,
        length: number
      ) => `${startIndex + 1} – ${endIndex} de ${length}`,
    },
    ['en-CA']: {
      firstPageLabel: 'First page',
      itemsPerPageLabel: 'Items per page',
      nextPageLabel: 'Next page',
      previousPageLabel: 'Previous page',
      lastPageLabel: 'Last page',
      zeroRangeLabelFormat: (length: number) => `0 of ${length}`,
      ofRangeLabelFormat: (
        startIndex: number,
        endIndex: number,
        length: number
      ) => `${startIndex + 1} – ${endIndex} of ${length}`,
    },
  };

  private languageSubscription: Subscription;

  constructor(private languageService: LanguageService) {
    super();
    this.languageSubscription = this.languageService.localeChanges$.subscribe(
      () => {
        this.firstPageLabel = this.getConfig().firstPageLabel;
        this.itemsPerPageLabel = this.getConfig().itemsPerPageLabel;
        this.nextPageLabel = this.getConfig().nextPageLabel;
        this.previousPageLabel = this.getConfig().previousPageLabel;
        this.lastPageLabel = this.getConfig().lastPageLabel;
        this.changes.next();
      }
    );
  }

  ngOnDestroy(): void {
    if (this.languageSubscription) {
      this.languageSubscription.unsubscribe();
    }
  }

  override getRangeLabel = (page, pageSize, length) => {
    if (length === 0 || pageSize === 0) {
      return this.getConfig().zeroRangeLabelFormat(length);
    }
    length = Math.max(length, 0);
    const startIndex = page * pageSize;
    const endIndex =
      startIndex < length
        ? Math.min(startIndex + pageSize, length)
        : startIndex + pageSize;
    return this.getConfig().ofRangeLabelFormat(startIndex, endIndex, length);
  };

  private getConfig() {
    return (
      this.CONFIG_MAP[this.languageService.getCurrentLocale()] ||
      this.CONFIG_MAP['en-CA']
    );
  }
}
