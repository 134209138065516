import { ImageCroppedEvent } from 'ngx-image-cropper';
import { OutputFormat } from 'ngx-image-cropper/lib/interfaces/cropper-options.interface';

import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { reportError } from '@arrivage-sentry/report-error';

export interface PictureCropperEditorDialogData {
  imageChangedEvent: any;
  outputType: OutputFormat;
  unsupportedFormat: boolean;
  maintainAspectRatio: boolean;
  aspectRatio: number;
}

@Component({
  selector: 'app-picture-cropper-editor-dialog',
  templateUrl: './picture-cropper-editor-dialog.component.html',
  styleUrls: ['./picture-cropper-editor-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PictureCropperEditorDialogComponent {
  croppedImage: any = '';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: PictureCropperEditorDialogData,
    public dialogRef: MatDialogRef<PictureCropperEditorDialogComponent>
  ) {}

  cancel() {
    this.dialogRef.close();
  }

  upload() {
    this.dialogRef.close(this.croppedImage);
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }

  loadImageFailed() {
    this.data.unsupportedFormat = true;
    reportError(new Error('Unsupported File Format'));
  }
}
