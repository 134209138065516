import { Injectable } from '@angular/core';

import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { RelationshipService } from '@arrivage-services/relationship/relationship.service';
import { SnackbarService } from '@arrivage-snackbar/snackbar.service';
import { EntityFeedback } from '@arrivage-store/feedback/feedback-params.model';
import { createBaseEffects } from '@arrivage-store/generators';

import * as relationshipsActions from './relationship.actions';
import * as relationshipsSelectors from './relationship.selectors';
import { State } from './relationship.state';

export const RelationshipInfoFeedback: EntityFeedback = {
  add: 'add_relationship_infos',
  update: 'update_relationship_infos',
  remove: 'remove_relationship_infos',
  query: 'query_relationships_infos',
  get_active_item: 'get_active_relationship_info',
};

export interface RelationshipEntityFeedback extends EntityFeedback {
  add_customer_to_relationship: string;
  adding_producer_to_favorite_in_progress: string;
  adding_producer_to_favorite_success: string;
  // Error only
  no_customer_in_relationship: string;
  adding_producer_to_favorite_error: string;
}

export const RelationshipFeedback: RelationshipEntityFeedback = {
  add: 'add_relationship',
  update: 'update_relationship',
  remove: 'remove_relationship',
  query: 'query_relationships',
  get_active_item: 'get_active_relationship',
  add_customer_to_relationship: 'add_customer_to_relationship',
  no_customer_in_relationship: 'no_customer_in_relationship',
  adding_producer_to_favorite_in_progress:
    'adding_producer_to_favorite_in_progress',
  adding_producer_to_favorite_success: 'adding_producer_to_favorite_success',
  adding_producer_to_favorite_error: 'adding_producer_to_favorite_error',
};

@Injectable()
export class RelationshipsEffects {
  query$;
  displayQueryFailure$;
  getActiveItem$;
  displayGetActiveItemFailure$;

  constructor(
    private actions$: Actions,
    private store: Store<State>,
    private service: RelationshipService,
    private snackbarService: SnackbarService
  ) {
    ({
      query: this.query$,
      displayQueryFailure: this.displayQueryFailure$,
      displayGetActiveItemFailure: this.displayGetActiveItemFailure$,
      getActiveItem: this.getActiveItem$,
    } = createBaseEffects(
      this.actions$,
      this.store,
      relationshipsActions,
      relationshipsSelectors,
      this.service,
      RelationshipFeedback,
      this.snackbarService
    ));
  }
}
