<ng-container *transloco="let t; scope: 'priceLists'; read: 'priceLists'">
  <form [formGroup]="form" autocomplete="off">
    <h2 class="mat-dialog-title no-space-top space-bottom text-xl">
      {{ t('dialog-form.title') }}
    </h2>
    <div class="mat-typography mat-dialog-content">
      <mat-form-field appearance="outline">
        <mat-label> {{ t('dialog-form.label-name') }}</mat-label>
        <input
          data-testid="price-list-name-id"
          required
          matInput
          placeholder="{{ t('dialog-form.placeholder-name') }}"
          formControlName="name"
        />
      </mat-form-field>
    </div>
    <mat-dialog-actions class="no-space-top" align="end">
      <button mat-button type="button" [mat-dialog-close]="false">
        {{ t('cancel') }}
      </button>
      <button
        data-testid="save-button-id"
        mat-raised-button
        type="submit"
        color="accent"
        (click)="onOkClick()"
      >
        {{
          data?.name
            ? t('rename-price-list')
            : t('dialog-form.create-price-list')
        }}
      </button>
    </mat-dialog-actions>
  </form>
</ng-container>
