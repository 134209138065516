import { from, Observable } from 'rxjs';

import { Injectable } from '@angular/core';
import { Firestore } from '@angular/fire/firestore';

import { InvoiceStatus } from '@arrivage/model/dist/src/model';

import {
  generateSequenceNumber,
  INVOICE_PREFIX,
} from '@arrivage/model/dist/src/utils/sequence-number-extractor.utils';

import { BaseInvoiceService } from '../../common/services/base-invoice.service';
import { InvoicesIdFields } from '../../common/services/invoices-id-fields';

@Injectable({
  providedIn: 'root',
})
export class VendorInvoiceService extends BaseInvoiceService {
  constructor(firestore: Firestore) {
    super(
      firestore,
      InvoicesIdFields.ID_FIELDS_ORGANIZATIONS.VENDOR,
      [InvoiceStatus.DRAFT, InvoiceStatus.SENT, InvoiceStatus.PAYMENT_EMITTED],
      BaseInvoiceService.ALL_INVOICES_STATUSES,
      'history.creationDate'
    );
  }

  getNextInvoiceNumber(): Observable<string> {
    const date = new Date();
    const invoiceNumber = generateSequenceNumber(date, INVOICE_PREFIX);
    return from(invoiceNumber);
  }
}
