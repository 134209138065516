import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormGroup,
} from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';

import { Invoice, WithId } from '@arrivage/model/dist/src/model';

export interface SendInvoiceDialogResponse {
  message: string;
}

@Component({
  selector: 'app-send-invoice-dialog',
  templateUrl: './send-invoice-dialog.component.html',
  styleUrls: ['./send-invoice-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SendInvoiceDialogComponent {
  form: UntypedFormGroup = this.fb.group({
    message: [null],
  });

  static openDialog(dialog: MatDialog, data?: Invoice & WithId) {
    return dialog.open<SendInvoiceDialogComponent, Invoice & WithId>(
      SendInvoiceDialogComponent,
      {
        data: data,
        width: '600px',
      }
    );
  }

  constructor(
    private fb: UntypedFormBuilder,

    public dialogRef: MatDialogRef<
      SendInvoiceDialogComponent,
      SendInvoiceDialogResponse
    >,
    @Inject(MAT_DIALOG_DATA) public data?: Invoice & WithId
  ) {}

  get messageControl(): AbstractControl {
    return this.form.get('message');
  }

  send() {
    this.dialogRef.close({
      message: this.messageControl.value ?? '',
    });
  }
}
