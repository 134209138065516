import { createAction, props } from '@ngrx/store';

import { Confirmation } from '@arrivage-store/generators';

import { AuthUser } from '../model/auth-data.model';

export const ResetError = createAction(`[Auth] Reset Error`);

export const Login = createAction(
  `[Auth] Login`,
  props<{
    username: string;
    password: string;
  }>()
);

export const LoginFailure = createAction(
  `[Auth] LoginFailure`,
  props<{
    errorCode: string;
  }>()
);

export const Signup = createAction(
  `[Auth] Signup`,
  props<{
    username: string;
    password: string;
  }>()
);

export const SignInSuccess = createAction(
  `[Auth] SignInSuccess`,
  props<{
    user: AuthUser;
  }>()
);

export const SignupFailure = createAction(
  `[Auth] SignupFailure`,
  props<{
    errorCode: string;
  }>()
);

export const GoogleSignIn = createAction(`[Auth] GoogleSignIn`);

export const GoogleSignInFailure = createAction(
  `[Auth] GoogleSignInFailure`,
  props<{
    errorCode: string;
  }>()
);

export const RequestPassword = createAction(
  `[Auth] RequestPassword`,
  props<{
    email: string;
  }>()
);

export const RequestPasswordFailure = createAction(
  `[Auth] RequestPasswordFailure`
);

export const Logout = createAction(
  `[Auth] Logout`,
  props<{
    confirmation?: Confirmation<void, any>;
  }>()
);

export const LogoutSuccess = createAction(`[Auth] LogoutSuccess`);

export const LogoutFailure = createAction(`[Auth] LogoutFailure`);

export const SetAuthUser = createAction(
  `[Auth] SetAuthUser`,
  props<{
    user: AuthUser;
  }>()
);

export const UnsetAuthUser = createAction(`[Auth] UnsetAuthUser`);
