import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'app-custom-text',
  templateUrl: './custom-text.component.html',
  styleUrls: ['./custom-text.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomTextComponent {
  @Input()
  text: string;

  @Input()
  additionalClasses: string;

  @Input()
  customTooltip: string;

  @Input()
  tooltipPosition: 'top' | 'right' | 'bottom' | 'left' = 'top';

  @Input()
  nbLine: number = 1;

  @Input()
  tooltip: 'enabled' | 'overflowOnly' | 'disabled' = 'enabled';

  @Input()
  bold: boolean = false;

  @Input()
  italic: boolean = false;

  @ViewChild('customTextElement')
  customTextElement: ElementRef<HTMLSpanElement>;

  isOverflowing(): boolean {
    if (!this.customTextElement) return false;
    const span = this.customTextElement.nativeElement;

    return (
      span.offsetHeight < span.scrollHeight ||
      span.offsetWidth < span.scrollWidth
    );
  }

  get tooltipDisabled() {
    switch (this.tooltip) {
      case 'enabled':
        return false;
      case 'overflowOnly':
        return !this.isOverflowing();
      case 'disabled':
        return true;
      default:
        return true;
    }
  }

  constructor() {}
}
