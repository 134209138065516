import { createAction, props } from '@ngrx/store';

import {
  PurchaseOrderActions,
  generatePurchaseOrderActions,
} from '@arrivage-purchase-orders/common/store/base-purchase-orders.actions';
import { Confirmation } from '@arrivage-store/generators';
import { PurchaseOrder, WithId } from '@arrivage/model/dist/src/model';

const featureName = 'vendorPurchaseOrders';

export const VendorPurchaseOrdersActions: PurchaseOrderActions =
  generatePurchaseOrderActions(featureName);

export const updateWithExternalFile = createAction(
  `[${featureName}] Update Purchase Order With Picture`,
  props<{
    record: {
      purchaseOrder: Partial<PurchaseOrder> & WithId;
      newExternalInvoice?: File;
      newExternalDeliveryNote?: File;
    };
    confirmation: Confirmation<string, any>;
  }>()
);
