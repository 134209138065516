<ng-container *transloco="let t">
  <section fxLayout="column" [fxLayoutAlign]="align === 'end' ? 'end end' : ''">
    <article
      fxLayout="row wrap"
      fxLayoutGap="5px"
      [fxLayoutAlign]="align === 'end' ? 'end center' : 'start center'"
    >
      @if (!!regularPrice) {
        <mat-icon class="material-icons-outlined icon-xs">discount</mat-icon>
      }

      <div
        [class.text-color-error]="!!regularPrice"
        [class.bold]="!!regularPrice"
      >
        <span
          [class.text-m]="size === 'small'"
          [class.text-l]="size === 'large'"
          >{{
            price
              | displayMoneyAmount
              | translocoCurrency: 'symbol' : {} : price.currency
          }}</span
        >
        @if (format?.applicableTaxes?.length > 0) {
          <small
            class="label"
            [class.text-m]="size === 'small'"
            [class.text-l]="size === 'large'"
            >+tx</small
          >
        }
      </div>
    </article>

    @if (!!regularPrice) {
      <small
        fxLayout="row wrap"
        fxLayoutGap="5px"
        [fxLayoutAlign]="align === 'end' ? 'end center' : 'start center'"
      >
        <div>(-{{ reducedPricePercentage }}%)</div>

        <div class="regular-price">
          <span
            [class.text-m]="size === 'small'"
            [class.text-l]="size === 'large'"
            >{{
              regularPrice
                | displayMoneyAmount
                | translocoCurrency: 'symbol' : {} : regularPrice.currency
            }}</span
          >
          @if (format?.applicableTaxes?.length > 0) {
            <small
              class="label"
              [class.text-m]="size === 'small'"
              [class.text-l]="size === 'large'"
              >+tx</small
            >
          }
        </div>
      </small>
    }
  </section>
</ng-container>
