import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { KebabCasePipe } from './kebab-case.pipe';

@NgModule({
  declarations: [KebabCasePipe],
  imports: [CommonModule],
  exports: [KebabCasePipe],
})
export class KebabCaseModule {}
