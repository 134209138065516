import { EntityAdapter } from '@ngrx/entity';
import { on } from '@ngrx/store';

import { createEntityReducer } from '@arrivage-store/generators';
import { PurchaseOrder, WithId } from '@arrivage/model/dist/src/model';

import { PurchaseOrderActions } from './base-purchase-orders.actions';
import { BasePurchaseOrderState } from './base-purchase-orders.state';

export function createCommonPurchaseOrderReducer<
  S extends BasePurchaseOrderState,
>(
  actions: PurchaseOrderActions,
  adapter: EntityAdapter<PurchaseOrder & WithId>
): any[] {
  return [
    ...createEntityReducer(actions, adapter),
    on(actions.queryByDateRange, (state: S, _p) => {
      return {
        ...state,
        dateRangeLoadingCollectionState: {
          ...state.dateRangeLoadingCollectionState,
          isLoading: true,
          error: undefined,
        },
      };
    }),
    on(actions.queryByDateRangeSuccess, (state: S, p) => {
      return {
        ...state,
        currentDateRange: p.newDateRange,
        storedDateRanges: p.newDateRangesToStore,
        dateRangeLoadingCollectionState: {
          ...state.dateRangeLoadingCollectionState,
          items: p.items,
          isLoading: false,
          error: undefined,
        },
      };
    }),
    on(actions.queryByDateRangeFailure, (state: S, p) => {
      return {
        ...state,
        dateRangeLoadingCollectionState: {
          ...state.dateRangeLoadingCollectionState,
          items: {
            sent: [],
            deliveryOrPickup: [],
          },
          isLoading: false,
          error: p.error,
        },
      };
    }),
    on(actions.querySubPurchaseOrdersByDateRange, (state: S, _p) => {
      return {
        ...state,
        subPurchaseOrdersByDateRange: {
          ...state.subPurchaseOrdersByDateRange,
          isLoading: true,
          error: undefined,
        },
      };
    }),
    on(actions.querySubPurchaseOrdersByDateRangeSuccess, (state: S, p) => {
      return {
        ...state,
        subPurchaseOrdersCurrentDateRange: p.newSubPurchaseOrderDateRange,
        subPurchaseOrdersStoredDateRanges:
          p.newSubPurchaseOrderDateRangesToStore,
        subPurchaseOrdersByDateRange: {
          ...state.subPurchaseOrdersByDateRange,
          items: p.items,
          isLoading: false,
          error: undefined,
        },
      };
    }),
    on(actions.querySubPurchaseOrdersByDateRangeFailure, (state: S, p) => {
      return {
        ...state,
        subPurchaseOrdersByDateRange: {
          ...state.subPurchaseOrdersByDateRange,
          items: {
            sent: [],
            deliveryOrPickup: [],
          },
          isLoading: false,
          error: p.error,
        },
      };
    }),
  ];
}
