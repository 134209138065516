import { TranslocoModule } from '@jsverse/transloco';

import { LayoutModule } from '@angular/cdk/layout';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { CloneDataPipeModule } from '@arrivage-pipes/clone-data-pipe/clone-data-pipe.module';
import { LayoutWithHeaderModule } from '@arrivage-templates/layouts/layout-with-header/layout-with-header.module';

import { MaterialModule } from '../material.module';
import { OrganizationSetttingsComponentModule } from './components/organization-setttings-component.module';
import { OrganizationSettingsRoutingModule } from './organization-settings-routing.module';
import { OrganizationSettingsPageComponent } from './page/organization-settings-page.component';
import { OrganizationSettingsEffects } from './store/organization-settings.effects';
import { reducer as organizationSettingsReducer } from './store/organization-settings.reducer';

@NgModule({
  declarations: [OrganizationSettingsPageComponent],
  imports: [
    CommonModule,
    MaterialModule,
    FlexLayoutModule,
    LayoutModule,
    FormsModule,
    ReactiveFormsModule,
    OrganizationSetttingsComponentModule,
    OrganizationSettingsRoutingModule,
    CloneDataPipeModule,
    StoreModule.forFeature('organizationSettings', organizationSettingsReducer),
    EffectsModule.forFeature([OrganizationSettingsEffects]),
    LayoutWithHeaderModule,
    TranslocoModule,
  ],
})
export class OrganizationSettingsModule {}
