import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { VendorPurchaseOrdersEffects } from './vendor-purchase-orders.effects';
import { reducer } from './vendor-purchase-orders.reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('vendorPurchaseOrders', reducer),
    EffectsModule.forFeature([VendorPurchaseOrdersEffects]),
  ],
})
export class VendorPurchaseOrdersStoreModule {}
