import { differenceInCalendarDays } from 'date-fns';
import _ from 'lodash';

import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';

import { CustomerAccess } from '@arrivage-customer-access/customer-access.model';
import {
  selectActiveItem as offerActiveItem,
  selectAll as selectAllOffers,
} from '@arrivage-offers/vendor/store/offers.selectors';
import { selectAllUpdatedCustomersAndHubs } from '@arrivage-relationship/common/store/relationship-infos.selectors';
import { createSelectors } from '@arrivage-store/generators';
import { selectRouteParam } from '@arrivage-store/routing/router-state.selectors';
import { State as RootState } from '@arrivage-store/state';
import {
  Offer,
  PriceList,
  RelationshipInfo,
  WithId,
} from '@arrivage/model/dist/src/model';

import { PriceListsDashboardMetrics } from '../model/price-list.model';
import { adapter } from './price-list.reducer';
import { PriceListState, State } from './price-list.state';

export const getPriceListState =
  createFeatureSelector<PriceListState>('priceList');

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
  connecting,
  connected,
  connectState,
  queryFailure,
  addFailure,
  setFailure,
  updateFailure,
  removeFailure,
  getById,
  selectActiveItem,
  isLoadingActiveItem,
  selectActiveItemState,
} = createSelectors<PriceList, State>(adapter, getPriceListState);

export const getCurrentPriceListId = createSelector(
  selectRouteParam('priceListId'),
  (priceListId) => priceListId
);

export const getCurrentPriceListCustomersAccess = (priceListId: string) =>
  createSelector<
    RootState,
    [(RelationshipInfo & WithId)[]],
    CustomerAccess.Customers
  >(selectAllUpdatedCustomersAndHubs, (customers) => {
    return {
      allowed: customers.filter((c) => {
        return c.priceListId === priceListId;
      }),
      notAllowed: customers.filter((c) => {
        return c.priceListId !== priceListId;
      }),
    };
  });

export const getAllPriceListCustomersAccess = createSelector<
  RootState,
  [(RelationshipInfo & WithId)[], (PriceList & WithId)[]],
  Dictionary<CustomerAccess.Customers & WithId>
>(selectAllUpdatedCustomersAndHubs, selectAll, (customers, priceLists) => {
  return _.keyBy(
    priceLists.map((priceList) => {
      return {
        allowed: customers.filter((c) => {
          return c.priceListId === priceList.id;
        }),
        notAllowed: customers.filter((c) => {
          return c.priceListId !== priceList.id;
        }),
        id: priceList.id,
      };
    }),
    'id'
  );
});

export const getCurrentPriceListOffer = createSelector<
  RootState,
  [(Offer & WithId)[], string],
  Offer & WithId
>(selectAllOffers, getCurrentPriceListId, (offers, priceListId) => {
  return _.keyBy(offers, 'priceListId')[priceListId];
});

export const getOfferByPriceList = createSelector(selectAllOffers, (offers) => {
  return _.keyBy(offers, 'priceListId');
});

export const getPriceListFromOffer = createSelector<
  RootState,
  [Offer & WithId, Dictionary<PriceList & WithId>],
  PriceList & WithId
>(offerActiveItem, selectEntities, (offer, priceLists) => {
  return priceLists[offer.priceListId];
});

export const getPriceListsMetrics = createSelector<
  RootState,
  [(Offer & WithId)[], (PriceList & WithId)[]],
  PriceListsDashboardMetrics
>(selectAllOffers, selectAll, (offers, priceLists) => {
  const activeOffers = offers.filter((o) => o.isActive);
  const nbOffersExpiredSoon = activeOffers.filter(
    (o) =>
      o.expirationDate &&
      differenceInCalendarDays(o.expirationDate, new Date(Date.now())) < 7
  ).length;
  return {
    nbPriceLists: priceLists.length,
    nbActiveOffers: activeOffers.length,
    nbOffersExpiredSoon: nbOffersExpiredSoon,
  };
});
