/**
 * Test group enum. A or B.
 */
export enum TestGroup {
  A = 'A',
  B = 'B',
}

/**
 * Returns a test group based on the organization id (A or B)
 * with a 50/50 split.
 * @param organizationId
 * @returns
 */
export function setTestGroupFromOrganizationId(organizationId: string): string {
  const hashValue = hashString(organizationId);
  return Math.abs(hashValue) % 2 === 0 ? TestGroup.A : TestGroup.B;
}

/**
 * Returns a hash value for a given string.
 * @param str
 * @returns
 */
function hashString(str: string): number {
  return (
    Array.from(str).reduce((hash, char) => {
      return (hash << 5) - hash + char.charCodeAt(0);
    }, 0) | 0
  );
}
