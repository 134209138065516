import { Pipe, PipeTransform } from '@angular/core';

import { Money } from '@arrivage/model/dist/src/model';

@Pipe({
  name: 'displayUnitPrice',
})
export class DisplayUnitPricePipe implements PipeTransform {
  transform(value: Money, quantity: number): Money {
    return Money.divide(value, quantity || 1);
  }
}
