import * as _ from 'lodash';
import { Subscription } from 'rxjs';

import {
  Pipe,
  PipeTransform,
  ChangeDetectorRef,
  OnDestroy,
} from '@angular/core';

import { LanguageService } from '@arrivage-language/service/language.service';
import { Translatable } from '@arrivage/model/dist/src/model';

@Pipe({
  name: 'translate',
  pure: false,
})
export class TranslatePipe implements PipeTransform, OnDestroy {
  private subscription: Subscription;

  constructor(
    private languageService: LanguageService,
    private cdr: ChangeDetectorRef
  ) {
    this.subscription = this.languageService.localeChanges$.subscribe(() => {
      this.cdr.markForCheck();
    });
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  transform(value: string | Translatable, ...args: any[]): any {
    if (!value) {
      return '';
    } else if (typeof value === 'string') {
      return value;
    } else {
      return this.languageService.getText(value);
    }
  }
}
