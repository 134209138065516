import { Component, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatCheckbox } from '@angular/material/checkbox';

export interface DialogConfirmationData {
  title: string;
  message: string;
  additionalValidation?: {
    message: string;
  };
  confirmationForDeleting?: boolean;
}

export interface DialogConfirmationAnswer {
  additionalValidation: true;
}

@Component({
  templateUrl: './dialog-confirmation.component.html',
  styleUrls: ['./dialog-confirmation.component.scss'],
})
export class DialogConfirmationComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogConfirmationData,
    public dialogRef: MatDialogRef<
      DialogConfirmationComponent,
      false | { additionalValidation: boolean }
    >
  ) {}

  @ViewChild(MatCheckbox)
  checkbox;

  confirm() {
    const additionalValidation = this.checkbox ? this.checkbox.checked : false;

    this.dialogRef.close({ additionalValidation: additionalValidation });
  }
}
