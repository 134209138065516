import { Dictionary } from 'lodash';
import { Observable } from 'rxjs';
import {
  AdminLinks,
  CommonLinks,
  CustomerLinks,
  HubCustomerLinks,
  VendorLinks,
} from 'src/app/util/links.model';

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';

import { AuthUser } from '@arrivage-auth/model/auth-data.model';
import { CustomerAccess } from '@arrivage-customer-access/customer-access.model';
import { OfferUtils } from '@arrivage-offers/utils/offers.utils';
import { SellerAccountSetupService } from '@arrivage-organization-setup/services/seller-account-setup.service';
import { SaveWithPictureData } from '@arrivage-organization/pages/organization-update-page/organization-update-page.component';
import { PriceListUtils } from '@arrivage-price-lists/utils/price-list.utils';
import { PurchaseOrderDashboardMetrics } from '@arrivage-purchase-orders/common/model/purchase-orders.model';
import { DeliveryByRelationshipId } from '@arrivage-relationship/common/model/relationship.model';
import { LocalStorageService } from '@arrivage-services/local-storage.service';
import { PictureService } from '@arrivage-services/picture.service';
import { SnackbarService } from '@arrivage-snackbar/snackbar.service';
import { ContextFacade } from '@arrivage-store/api/context.facade';
import { ContextFeedback } from '@arrivage-store/context/context.effect';
import {
  Conversation,
  CreditAccount,
  Delivery,
  Offer,
  Organization,
  OrganizationAccountSetup,
  PremiumAccount,
  PriceList,
  User,
  WithId,
} from '@arrivage/model/dist/src/model';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
})
export class SidenavComponent implements OnInit {
  readonly OfferUtils = OfferUtils;
  readonly PriceListUtils = PriceListUtils;

  AdminLinks = AdminLinks;
  CommonLinks = CommonLinks;
  VendorLinks = VendorLinks;
  CustomerLinks = CustomerLinks;
  HubCustomerLinks = HubCustomerLinks;

  organizationSetup$: Observable<OrganizationAccountSetup>;

  routeLinks = {
    'offers-section': [
      VendorLinks.Inventory,
      VendorLinks.PriceLists,
      VendorLinks.DisplayedOffers,
      VendorLinks.Distribution,
    ],
    'sales-section': [
      VendorLinks.PurchaseOrders,
      VendorLinks.Invoices,
      VendorLinks.ScheduleDeliveries,
      VendorLinks.SalesReport,
    ],
    'purchase-section': [
      HubCustomerLinks.PurchaseOrders,
      HubCustomerLinks.ScheduleDeliveries,
      HubCustomerLinks.Invoices,
      HubCustomerLinks.BuyingReport,
    ],
    'account-section': [CommonLinks.Settings, VendorLinks.CreditAccount],
  };

  @Input()
  authUser: AuthUser;

  @Input()
  user: User & WithId;

  @Input()
  activeOrganization: Organization & WithId;

  @Input()
  creditAccount: CreditAccount;

  @Input()
  premiumAccount: PremiumAccount;

  @Input()
  isMediumOrSmaller: boolean;

  @Input()
  customerPurchaseOrderMetrics: PurchaseOrderDashboardMetrics;

  @Input()
  vendorPurchaseOrderMetrics: PurchaseOrderDashboardMetrics;

  @Input()
  offerByPriceList: Dictionary<Offer & WithId>;

  @Input()
  priceLists: (PriceList & WithId)[];

  @Input()
  deliveries: (Delivery & WithId)[];

  @Input()
  conversations: (Conversation & WithId)[];

  @Input()
  customersAccessByPriceList: Dictionary<CustomerAccess.Customers & WithId> =
    {};

  @Input()
  deliveryByRelationship: DeliveryByRelationshipId;

  @Input()
  allOrganizations: (Organization & WithId)[];

  isExpanded: boolean = true;

  @Output()
  expandedSideNav = new EventEmitter<boolean>();

  @Output()
  closeSidenav = new EventEmitter<void>();

  constructor(
    private sellerAccountSetupService: SellerAccountSetupService,
    private router: Router,
    private localStorageService: LocalStorageService,
    private contextFacade: ContextFacade,
    private snackbarService: SnackbarService
  ) {
    this.organizationSetup$ =
      this.sellerAccountSetupService.sellerAccountSetup$;
  }
  readonly DEFAULT_VENDOR_PICTURE = PictureService.defaultVendor();
  private profilRegEx = /\/organization\/[a-zA-Z0-9]+\/edit/i;

  ngOnInit() {
    this.isExpanded =
      this.getSidenavState() == null ? true : this.getSidenavState();
    this.expandedSideNav.emit(this.isExpanded);
  }

  get isPremiumAccount(): boolean {
    return this.premiumAccount?.isPremium;
  }

  get isVendorPurchaseOrdersNeedAttention(): boolean {
    return this.vendorPurchaseOrderMetrics?.nbPurchaseOrdersToConfirm.nb > 0;
  }

  get isCustomerPurchaseOrdersNeedAttention(): boolean {
    return this.customerPurchaseOrderMetrics?.nbPurchaseOrdersToConfirm.nb > 0;
  }

  get conversationsNeedAttention(): boolean {
    return (
      this.conversations?.filter((c) =>
        c?.viewedBy
          ? c.viewedBy[this.activeOrganization.id]?.nbOfUnreadMessages > 0
          : false
      ).length > 0
    );
  }

  get nbOfUnreadConversations(): number {
    return this.conversations?.filter(
      (c) => c.viewedBy[this.activeOrganization.id]?.nbOfUnreadMessages > 0
    ).length;
  }

  onClose() {
    if (this.isMediumOrSmaller) {
      this.closeSidenav.emit();
    }
  }

  isSetupCompleted(setup: OrganizationAccountSetup) {
    return this.sellerAccountSetupService.isSetupCompleted(setup);
  }

  expandSidenav() {
    this.isExpanded = !this.isExpanded;

    this.setSidenavState(this.isExpanded);
    this.expandedSideNav.emit(this.isExpanded);
  }

  isActivePanel(panel: string): boolean {
    if (
      panel === 'account-section' &&
      this.router.url.match(this.profilRegEx)
    ) {
      return true;
    }

    return (
      this.routeLinks[panel].filter(
        (link) =>
          this.router.url.startsWith(link.URL[0]) ||
          link.URL[0].match(this.profilRegEx)
      ).length > 0
    );
  }

  async handleLogoEdit(value: { file: File; pictureUrl: string | SafeUrl }) {
    const action = ContextFeedback.update_organization;
    const updatedOrganization: {
      organization: Partial<Organization> & WithId;
      pictureData?: SaveWithPictureData;
    } = {
      organization: this.activeOrganization,
      pictureData: {
        organizationId: this.activeOrganization.id,
        logo: value.file,
      },
    };
    await this.contextFacade
      .onUpdateOrganization(updatedOrganization)
      .then((_) => this.snackbarService.showSuccess(action))
      .catch((_) => this.snackbarService.showError(action));
  }

  onViewOrganizationData(organizationId: string) {
    // save the view as in local storage
    this.localStorageService.saveOrganizationOverride(organizationId);
    // reload the window for the change to take effect
    window.location.reload();
  }

  getSidenavState(): boolean {
    return this.localStorageService.sidenavState;
  }

  setSidenavState(isExpanded: boolean) {
    this.localStorageService.sidenavState = isExpanded;
  }
}
