import _ from 'lodash';

import { translate } from '@jsverse/transloco';

import { SortOption } from '@arrivage-components/search-sort/search-sort.component';
import { LanguageService } from '@arrivage-language/service/language.service';
import {
  PriceListItemLine,
  PriceListItemLineWithPriceList,
} from '@arrivage-price-lists/model/price-list.model';
import {
  Conservation,
  LocalDate,
  OfferItem,
  OrderItem,
  OrganizationSummary,
  Product,
  Translatable,
} from '@arrivage/model/dist/src/model';

import { PurchaseReportLine } from '../purchase-report/common/model/purchase-report.model';
import { LangUtils } from './lang.utils';

export interface GroupData<T> {
  groupName: string;
  data: T[];
}
export interface GroupedByProducer<T> extends GroupData<T> {}
export interface GroupedByCategory<T> extends GroupData<T> {}
export interface GroupedByFormat<T> extends GroupData<T> {}
export interface GroupedByPriceList<T> extends GroupData<T> {}

export namespace DataGroupByUtils {
  // Group By PRODUCT CATEGORY
  export function groupByProductCategory<T>(
    data: T[],
    languageService: LanguageService
  ): GroupData<T>[] {
    const groupedData = _.orderBy(
      _.groupBy(data, (d) => {
        const category = languageService.getText(extractProductCategoryFrom(d));
        return category ? category.toLowerCase() : category;
      }),
      (d) => {
        if (d.length > 0) {
          return languageService
            .getText(extractProductCategoryFrom(d[0]))
            ?.toLowerCase();
        }
      }
    );
    const groupedDataByCategory: GroupData<T>[] = groupedData.map((gd) => {
      return {
        groupName:
          gd.length > 0
            ? languageService.getText(extractProductCategoryFrom(gd[0]))
            : '',
        data: _.orderBy(gd, (d) =>
          languageService.getText(extractProductNameFrom(d))?.toLowerCase()
        ),
      };
    });

    if (groupedDataByCategory.findIndex((gd) => gd.groupName === '') !== -1) {
      const notCategorized = groupedDataByCategory.splice(
        groupedDataByCategory.findIndex((gd) => gd.groupName === ''),
        1
      );
      groupedDataByCategory.push(notCategorized[0]);
    }

    return LangUtils.normalizedSortBy(groupedDataByCategory, (g) => {
      return g.groupName;
    });
  }

  export function isCategoryEqual(
    category: Translatable,
    categoryToCompare: string,
    languageService: LanguageService
  ): boolean {
    return (
      languageService.getText(category).toLowerCase() ===
      categoryToCompare.toLowerCase()
    );
  }

  // Group By PRODUCT <OPTION>
  export function groupByProduct<T>(
    data: T[],
    languageService: LanguageService,
    sortOption: SortOption,
    organizationName?: string
  ): GroupData<T>[] {
    const groupedData = _.orderBy(
      _.groupBy(data, (d) => {
        const option = extractProductOptionFrom(
          d,
          languageService,
          sortOption,
          organizationName
        );
        return option ? option.toLowerCase() : option;
      }),
      (d) => {
        if (d.length > 0) {
          return extractProductOptionFrom(
            d[0],
            languageService,
            sortOption,
            organizationName
          )?.toLowerCase();
        }
      }
    ).map((gd) => {
      return {
        groupName:
          gd.length > 0
            ? extractProductOptionFrom(
                gd[0],
                languageService,
                sortOption,
                organizationName
              )
            : '',
        data: gd,
      };
    });

    if (groupedData.findIndex((gd) => gd.groupName === '') !== -1) {
      const notCategorized = groupedData.splice(
        groupedData.findIndex((gd) => gd.groupName === ''),
        1
      );
      groupedData.push(notCategorized[0]);
    }

    return LangUtils.normalizedSortBy(groupedData, (g) => g.groupName);
  }

  export function groupByProductName<T>(
    data: T[],
    languageService: LanguageService
  ): GroupData<T>[] {
    return _(data)
      .groupBy('product.id')
      .map((d) => {
        return {
          groupName: languageService.getText(extractProductCategoryFrom(d[0])),
          data: d,
        };
      })
      .value();
  }

  export function groupByFormat<T>(data: T[]): GroupData<T>[] {
    return _(data)
      .groupBy('format.id')
      .map((d) => {
        return {
          groupName: extractFormatIdFrom(d[0]),
          data: d,
        };
      })
      .value();
  }

  export function groupByPriceListAndByCategory(
    data: PriceListItemLineWithPriceList[],
    languageService: LanguageService
  ): GroupedByPriceList<GroupedByCategory<PriceListItemLineWithPriceList>>[] {
    return _(data)
      .groupBy('name')
      .map((priceListItemLinesWithPriceList, groupName) => {
        return {
          groupName: groupName,
          data: DataGroupByUtils.groupByProductCategory(
            priceListItemLinesWithPriceList,
            languageService
          ),
        };
      })
      .value();
  }

  export function groupByProducerByPriceListAndByCategory(
    data: PriceListItemLineWithPriceList[],
    languageService: LanguageService
  ): GroupedByProducer<
    GroupedByPriceList<GroupedByCategory<PriceListItemLineWithPriceList>>
  >[] {
    return _(data)
      .groupBy('product.resellingFor')
      .map(
        (
          priceListItemLinesWithPriceList: PriceListItemLineWithPriceList[],
          groupName: string
        ) => {
          return {
            groupName: groupName,
            data: DataGroupByUtils.groupByPriceListAndByCategory(
              priceListItemLinesWithPriceList,
              languageService
            ),
          };
        }
      )
      .value();
  }

  export function groupByProducerAndByCategory<T>(
    data: T[],
    languageService: LanguageService
  ): GroupedByProducer<GroupedByCategory<T>>[] {
    const groupedData: GroupedByProducer<GroupedByCategory<T>>[] = _(data)
      .groupBy('product.resellingFor')
      .map((items, groupName) => {
        return {
          groupName: groupName,
          data: DataGroupByUtils.groupByProductCategory(items, languageService),
        };
      })
      .value();

    // order producers alphabetically, then put our own products first
    return _.orderBy(
      groupedData,
      [
        (producer) => producer.groupName.toLowerCase() !== 'null',
        (producer) => producer.groupName.toLowerCase(),
      ],
      ['asc']
    );
  }

  function extractFormatIdFrom<T>(data: T): string {
    if (isOfOfferItemOrPriceListItemLine(data)) {
      return data.format.id;
    }
  }

  // Product Category UTILS
  function extractProductCategoryFrom<T>(data: T): Translatable {
    if (isSalesReportLine(data)) {
      return data.orderItem.product.category;
    }

    if (isOfOfferItemOrPriceListItemLine(data)) {
      return data.product.category;
    }

    if (isOfProduct(data)) {
      return data.category;
    }
  }

  // Product <OPTION> UTILS
  function extractProductOptionFrom<T>(
    data: T,
    languageService: LanguageService,
    option: SortOption,
    organizationName?: string
  ): string {
    let dataProductOption = '';
    if (option === 'buyer' && isOrderItemWithCustomer(data)) {
      dataProductOption = data.customer.name;
    } else if (isOfOfferItemOrPriceListItemLine(data) && organizationName) {
      dataProductOption = offerItemOrPriceListItemLineSwitch(
        data,
        organizationName,
        option,
        languageService
      );
    } else if (isSalesReportLine(data)) {
      dataProductOption = salesReportSwitch(data, option, languageService);
    }
    return dataProductOption;
  }

  function extractProductNameFrom<T>(data: T): Translatable {
    if (isOfOfferItemOrPriceListItemLine(data)) {
      return data.product.name;
    }

    if (isOfProduct(data)) {
      return data.name;
    }

    if (isSalesReportLine(data)) {
      return data.orderItem.product.name;
    }
  }

  function isOfOfferItemOrPriceListItemLine(
    record: any
  ): record is OfferItem | PriceListItemLine {
    if ('product' in record) {
      return true;
    }
    return false;
  }

  function isSalesReportLine(record: any): record is PurchaseReportLine {
    return 'orderItem' in record && 'purchaseOrder' in record;
  }

  function isOfProduct(record: any): record is Product {
    if (
      'name' in record &&
      'type' in record &&
      'category' in record &&
      'subCategory' in record &&
      'isAvailable' in record &&
      'department' in record &&
      'isCertifiedOrganic' in record
    ) {
      return true;
    }
    return false;
  }

  function isOrderItemWithCustomer(
    record: any
  ): record is OrderItem & { customer: OrganizationSummary } {
    return 'customer' in record;
  }

  function offerItemOrPriceListItemLineSwitch(
    data: OfferItem | PriceListItemLine,
    organizationName: string,
    option: string,
    languageService: LanguageService
  ) {
    switch (option) {
      case 'category':
        return languageService.getText(data.product.category);
      case 'department':
        const departmentKey = `enums.department.${data.product.department}`;
        return translate<string>(departmentKey);
      case 'resellingFor':
        return data.product?.resellingFor ?? `${organizationName}`;
      case 'conservation':
        const conservationKey =
          data.product?.conservation ?? Conservation.NON_REFRIGERATED;
        return translate<string>('enums.conservation', {
          conservation: conservationKey,
        });
      case 'product':
        return languageService.getText(data.product.name);
    }
  }

  function salesReportSwitch(
    data: PurchaseReportLine,
    option: string,
    languageService: LanguageService
  ) {
    switch (option) {
      case 'category':
        return (
          languageService.getText(data.orderItem.product.category) ||
          translate<string>('purchaseReport.not-categorized')
        );
      case 'department':
        const departmentKey = `enums.department.${data.orderItem.product.department}`;
        return translate<string>(departmentKey);

      case 'product':
        return languageService.getText(data.orderItem.product.name);
      case 'buyer':
        return data.purchaseOrder.customer.name;
      case 'resellingFor':
        return (
          data.orderItem.product.resellingFor || data.purchaseOrder.vendor.name
        );
      case 'vendor':
        return data.purchaseOrder.vendor.name;

      default:
        return (
          data.purchaseOrder.deliveryDate ||
          LocalDate.toDate(data.purchaseOrder.pickup?.selectedDay)
        ).toDateString();
    }
  }
}
