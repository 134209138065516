import { combineLatest, Observable } from 'rxjs';

import { BreakpointObserver } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';

import { map } from 'rxjs/operators';

import { CustomBreakpoints } from '@arrivage-styles/custom-breakpoints.enum';

// TODO WEB-3480 : rename small to extraSmall and betweenSmallAndMedium to small
// https://arrivage.atlassian.net/browse/WEB-3480
export interface ScreenSize {
  small: boolean;
  betweenSmallAndMedium: boolean;
  medium: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class ScreenSizeService {
  private isScreenExtraSmall$: Observable<boolean>;
  private isScreenSmall$: Observable<boolean>;
  private isMediumOrSmall$: Observable<boolean>;

  private screenSizeObserver$: Observable<ScreenSize>;

  constructor(private breakpointObserver: BreakpointObserver) {
    this.isScreenExtraSmall$ = this.breakpointObserver
      .observe([CustomBreakpoints.IS_EXTRA_SMALL])
      .pipe(map((result) => result.matches));
    this.isScreenSmall$ = this.breakpointObserver
      .observe([CustomBreakpoints.IS_SMALL])
      .pipe(map((result) => result.matches));
    this.isMediumOrSmall$ = this.breakpointObserver
      .observe([CustomBreakpoints.IS_MEDIUM_OR_SMALLER])
      .pipe(map((result) => result.matches));
    this.screenSizeObserver$ = combineLatest([
      this.isScreenExtraSmall$,
      this.isScreenSmall$,
      this.isMediumOrSmall$,
    ]).pipe(
      map(([small, betweenSmallAndMedium, medium]) => {
        return {
          small: small,
          betweenSmallAndMedium: betweenSmallAndMedium,
          medium: medium,
        };
      })
    );
  }

  getIsScreenExtraSmall(): Observable<boolean> {
    return this.isScreenExtraSmall$;
  }

  getIsScreenSmall(): Observable<boolean> {
    return this.isScreenSmall$;
  }

  getIsScreenMediumOrSmall(): Observable<boolean> {
    return this.isMediumOrSmall$;
  }

  getScreenSizeObserver(): Observable<ScreenSize> {
    return this.screenSizeObserver$;
  }
}
