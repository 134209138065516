import { Injectable } from '@angular/core';

import { filter, tap, withLatestFrom } from 'rxjs/operators';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import {
  getOrganization,
  getUser,
} from '@arrivage-store/context/context.selectors';
import { State } from '@arrivage-store/state';

import { SlackApiService } from '../api/slack.api.service';
import * as actions from './slack.actions';

@Injectable()
export class SlackEffects {
  constructor(
    private actions$: Actions,
    private store: Store<State>,
    private slackApiService: SlackApiService
  ) {}

  postToSlack$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actions.postToSlack),
        withLatestFrom(
          this.store.select(getOrganization),
          this.store.select(getUser)
        ),
        filter(([, organization, user]) => !!organization && !!user),
        tap(async ([request, organization, user]) => {
          await this.slackApiService.postToSlack({
            ...request.request,
            organization: organization,
            user: user,
          });
        })
      ),
    { dispatch: false }
  );
}
