<a
  *transloco="let t; read: 'navigation'"
  routerLinkActive="active"
  [routerLink]="linkDefinition.URL"
  [routerLinkActiveOptions]="{ exact: activeOnExactMatch }"
  [class.bold]="isTopLink"
  [class.sub-link]="!isTopLink"
  [customTooltip]="t('links.' + linkDefinition.name)"
  [tooltipPosition]="'right'"
  [tooltipDisabled]="isExpanded"
  (click)="onClick()"
>
  <div fxLayout="row" fxLayoutAlign="start center">
    <mat-icon [ngClass]="{ 'navigation-icon': isExpanded }">
      {{ linkDefinition.icon }}
    </mat-icon>
    <span
      *ngIf="isExpanded"
      [matBadge]="badgeText"
      [matBadgeHidden]="!badgeText"
      matBadgeColor="warn"
      [matBadgeOverlap]="false"
      matBadgeSize="small"
    >
      {{ t('links.' + linkDefinition.name) }}
    </span>
    <mat-icon *ngIf="indicator" class="indicator" color="accent">
      fiber_manual_record
    </mat-icon>
  </div>
</a>
