import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';

export const openCloseAnimation = trigger('openCloseAnimation', [
  state(
    'close',
    style({ height: '0px', minHeight: '0', opacity: 0, overflow: 'hidden' })
  ),
  state('open', style({ height: '*', opacity: 1 })),
  transition('close => open', [animate('250ms ease-in')]),
  transition('open => close', [animate('250ms ease-out')]),
]);

export const fadeInOut = trigger('fadeInOut', [
  state('close', style({ opacity: 0, visibility: 'hidden' })),
  state('open', style({ opacity: 1 })),
  transition('close => open', [animate('250ms ease-in')]),
  transition('open => close', [animate('250ms ease-out')]),
]);
