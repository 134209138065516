import { TranslocoModule } from '@jsverse/transloco';
import { TranslocoLocaleModule } from '@jsverse/transloco-locale';
import { AppUtilsModule } from 'src/app/app-utils.module';
import { MaterialModule } from 'src/app/material.module';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';

import { LabelModule } from '../label/label.module';
import { DateListComponent } from './date-list.component';

@NgModule({
  declarations: [DateListComponent],
  imports: [
    CommonModule,
    AppUtilsModule,
    MaterialModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    TranslocoModule,
    TranslocoLocaleModule,
    LabelModule,
  ],
  exports: [DateListComponent],
})
export class DateListModule {}
