import { createFeatureSelector, createSelector } from '@ngrx/store';

import { UserSettingsState } from './user-settings.state';

export const getUserSettingsState = createFeatureSelector<UserSettingsState>(
  'userSettings'
);

export const selectUserSettings = createSelector(
  getUserSettingsState,
  (state) => state.userSettings
);
