<ng-container *transloco="let t; scope: 'invoices'; read: 'invoices'">
  <h2 class="mat-dialog-title space-bottom text-xl" data-testid="title-test-id">
    {{
      t('send-invoice-dialog.title', {
        customer: data?.customer?.name ?? 'undefined'
      })
    }}
  </h2>
  <div class="mat-dialog-content">
    <mat-card-content fxLayout="column" fxLayoutGap="1rem">
      <p class="description">
        <span
          *ngIf="!data?.history?.publishDate"
          data-testid="description-test-id"
          >{{
            t('send-invoice-dialog.send-to-customer-description', {
              customer: data?.customer?.name ?? 'undefined'
            }) + '. '
          }}
        </span>
        <span data-testid="confirmation-test-id">
          {{
            t('send-invoice-dialog.send-to-customer-confirmation', {
              customer: data?.customer?.name ?? 'undefined'
            })
          }}
        </span>
      </p>
    </mat-card-content>
    <mat-card-content>
      <form [formGroup]="form">
        <mat-form-field appearance="outline">
          <mat-label>{{ t('send-invoice-dialog.message-label') }}</mat-label>
          <textarea
            cdkTextareaAutosize
            matInput
            rows="4"
            formControlName="message"
          ></textarea>
          <mat-hint data-testid="hint-test-id">{{
            t('send-invoice-dialog.message-hint', {
              customer: data?.customer?.name ?? 'undefined'
            })
          }}</mat-hint>
        </mat-form-field>
      </form>
    </mat-card-content>
  </div>
  <!-- Boutons -->
  <mat-dialog-actions align="end">
    <button
      mat-raised-button
      [mat-dialog-close]="false"
      data-testid="cancel-button-test-id"
    >
      {{ t('send-invoice-dialog.cancel') }}
    </button>
    <button
      mat-raised-button
      color="accent"
      data-testid="send-button-test-id"
      (click)="send()"
    >
      {{ t('send-invoice-dialog.send') }}
    </button>
  </mat-dialog-actions>
</ng-container>
