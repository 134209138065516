import { MaterialModule } from 'src/app/material.module';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';

import { TranslocoModule } from '@jsverse/transloco';

import { DialogConfirmationComponent } from './dialog-confirmation.component';

@NgModule({
  declarations: [DialogConfirmationComponent],
  imports: [CommonModule, MaterialModule, TranslocoModule, FlexLayoutModule],
  exports: [DialogConfirmationComponent],
})
export class DialogConfirmationModule {}
