import { AppUtilsModule } from 'src/app/app-utils.module';
import { MaterialModule } from 'src/app/material.module';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';

import { TranslocoModule } from '@jsverse/transloco';
import { TranslocoLocaleModule } from '@jsverse/transloco-locale';

import { NotificationService } from '../../services/notifications.service';
import { NotifDropdownComponent } from './notif-dropdown.component';

@NgModule({
  declarations: [NotifDropdownComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    MaterialModule,
    TranslocoModule,
    TranslocoLocaleModule,
    AppUtilsModule,
  ],
  exports: [NotifDropdownComponent],
  providers: [NotificationService],
})
export class NotifDropdownModule {}
