import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { VendorDeliveryEffects } from './vendor-delivery.effects';
import { reducer } from './vendor-delivery.reducer';
import { VendorDeliveryStateName } from './vendor-delivery.state';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(VendorDeliveryStateName, reducer),
    EffectsModule.forFeature([VendorDeliveryEffects]),
  ],
})
export class VendorDeliveryStoreModule {}
