import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AdminGuard } from '@arrivage-guards/admin.guard';
import { AuthGuard } from '@arrivage-guards/auth.guard';
import { DirtyCheckGuard } from '@arrivage-guards/dirty-check.guard';

import { OrganizationSettingsPageComponent } from './page/organization-settings-page.component';

const routes: Routes = [
  {
    path: 'organizations/settings',
    component: OrganizationSettingsPageComponent,
    canActivate: [AuthGuard, AdminGuard],
    canDeactivate: [DirtyCheckGuard],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class OrganizationSettingsRoutingModule {}
