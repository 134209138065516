import { Observable } from 'rxjs';

import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';

import { Logout } from '@arrivage-auth/store/auth.actions';
import { SaveWithPictureData } from '@arrivage-organization/pages/organization-update-page/organization-update-page.component';
import {
  Member,
  Organization,
  User,
  WithId,
} from '@arrivage/model/dist/src/model';

import { updateOrganization } from '../context/context.actions';
import {
  getAllUserMemberships,
  getMembership,
  getOrganization,
  getOrganizationId,
  getUser,
  getUserId,
} from '../context/context.selectors';
import { State } from '../state';

@Injectable({
  providedIn: 'root',
})
export class ContextFacade {
  private organization$: Observable<Organization & WithId>;
  private organizationId$: Observable<string>;
  private user$: Observable<User & WithId>;
  private userId$: Observable<string>;
  private membership$: Observable<Member & WithId>;
  private allUserMemberships$: Observable<(Member & WithId)[]>;

  constructor(private store: Store<State>) {
    this.organization$ = this.store.select(getOrganization);
    this.organizationId$ = this.store.select(getOrganizationId);
    this.user$ = this.store.select(getUser);
    this.userId$ = this.store.select(getUserId);
    this.membership$ = this.store.select(getMembership);
    this.allUserMemberships$ = this.store.select(getAllUserMemberships);
  }

  getOrganization(): Observable<Organization & WithId> {
    return this.organization$;
  }

  getOrganizationId(): Observable<string> {
    return this.organizationId$;
  }

  getUser(): Observable<User & WithId> {
    return this.user$;
  }

  getUserId(): Observable<string> {
    return this.userId$;
  }

  onUpdateOrganization(value: {
    organization: Partial<Organization> & WithId;
    pictureData?: SaveWithPictureData;
  }): Promise<string> {
    return new Promise((resolve, reject) => {
      this.store.dispatch(
        updateOrganization({
          record: {
            organization: value.organization,
            newLogoFile: value.pictureData?.logo,
            newPictureFile: value.pictureData?.picture,
            newBannerFile: value.pictureData?.banner,
            newPermits: value.pictureData?.permits || [],
          },
          confirmation: {
            resolve: resolve,
            reject: reject,
          },
        }),
      );
    });
  }

  getMembership(): Observable<Member & WithId> {
    return this.membership$;
  }

  getAllUserMemberships(): Observable<(Member & WithId)[]> {
    return this.allUserMemberships$;
  }

  logout(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.store.dispatch(
        Logout({
          confirmation: {
            resolve: resolve,
            reject: reject,
          },
        }),
      );
    });
  }
}
