import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface UnsavedChangesDialogData {
  overrideLeaveTransKey: string;
}

@Component({
  selector: 'app-unsaved-changes-dialog',
  templateUrl: './unsaved-changes-dialog.component.html',
  styleUrls: ['./unsaved-changes-dialog.component.scss'],
})
export class UnsavedChangesDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: UnsavedChangesDialogData,
    public dialogRef: MatDialogRef<UnsavedChangesDialogComponent>
  ) {
    // disable closing the dialog by clicking outside of it or by pressing 'esc'
    dialogRef.disableClose = true;
  }

  stay() {
    this.dialogRef.close(false);
  }

  leave() {
    this.dialogRef.close(true);
  }
}
