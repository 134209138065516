<ng-container *transloco="let t">
  <div
    matSnackBarLabel
    fxLayout="row"
    fxLayoutAlign="start center"
    fxLayoutGap="1rem"
  >
    <mat-icon *ngIf="data.icon">
      {{ data.icon }}
    </mat-icon>
    <span class="text-m">
      {{ t(data.message, data.messageParameters) }}
    </span>
  </div>
</ng-container>
