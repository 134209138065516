import { createAction, props } from '@ngrx/store';

import { SlackPostMessageRequestData } from '@arrivage/model/dist/src/cloud-functions-api';

export const postToSlack = createAction(
  `[Slack] Post message`,
  props<{
    request: SlackPostMessageRequestData;
  }>()
);
