import {
  endOfDay,
  formatDistanceStrict,
  Interval,
  isBefore,
  isEqual,
  startOfDay,
} from 'date-fns';
import { combineLatest } from 'rxjs';

import {
  AfterContentInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';
import {
  DateRange,
  DefaultMatCalendarRangeStrategy,
  MAT_DATE_RANGE_SELECTION_STRATEGY,
} from '@angular/material/datepicker';
import { MatMenuTrigger } from '@angular/material/menu';

import { translate } from '@jsverse/transloco';

import { LanguageService } from '@arrivage-language/service/language.service';
import { DateRangeLogic } from '@arrivage-util/date-range/date-range.logic';
import { Language } from '@arrivage/model/dist/src/model';

export const DATE_RANGE_OPTIONS = [
  'last-30-days',
  'last-60-days',
  'last-90-days',
  'last-6-months',
  'last-12-months',
  'customized',
];
export type DateRangeOption = (typeof DATE_RANGE_OPTIONS)[number];

export interface DateRangePickerData {
  dateRangeValidity: boolean;
  dateRange: Interval;
  selectedOption: DateRangeOption;
}

@Component({
  selector: 'app-date-range-picker',
  templateUrl: './date-range-picker.component.html',
  styleUrls: ['./date-range-picker.component.scss'],
  providers: [
    {
      provide: MAT_DATE_RANGE_SELECTION_STRATEGY,
      useClass: DefaultMatCalendarRangeStrategy,
    },
  ],
})
export class DateRangePickerComponent implements AfterContentInit {
  @ViewChild('selectionOverlay') selectionOverlay: ElementRef;
  @ViewChild('menuTrigger') menuTrigger: MatMenuTrigger;

  private readonly LOCAL_STORAGE_SUFFIX =
    '.saved-date-range-picker-informations';
  private readonly LOCAL_STORAGE_DATE_RANGE_SUFFIX = '.date-range';
  private readonly LOCAL_STORAGE_SELECTED_OPTION_SUFFIX = '.selected-option';

  private readonly OPTION_HEIGHT = 48;

  private readonly DEFAULT_DATE_RANGE_OPTION = 'last-90-days';
  private readonly DEFAULT_DATE_RANGE = this.getDateRangeFromDateRangeOption(
    this.DEFAULT_DATE_RANGE_OPTION
  );

  readonly DATE_RANGE_OPTIONS = DATE_RANGE_OPTIONS;

  @Input()
  label = translate<string>('components.date-range-picker.label');

  @Input()
  customTooltipText: string;

  @Input()
  pageName?: string;

  @Input()
  dateRange: Interval;

  @Input()
  dateRangeOption: DateRangeOption;

  @Output()
  dateRangeEmitter: EventEmitter<DateRangePickerData> = new EventEmitter();

  selectedOption: DateRangeOption = 'last-90-days';

  range = this.fb.group({
    start: new Date(),
    end: new Date(),
  });

  startControl = this.range.controls['start'];
  endControl = this.range.controls['end'];

  currentLocale =
    this.languageService.getCurrentLanguage() === Language.FR
      ? 'fr-FR'
      : this.languageService.getCurrentLocale();

  selectedDateRange: DateRange<Date | number>;

  get dateRangeToString() {
    return this.selectedDateRange?.start && this.selectedDateRange?.end
      ? formatDistanceStrict(
          this.selectedDateRange.start,
          this.selectedDateRange.end,
          {
            locale: this.languageService.getCurrentLocaleDateFns(),
          }
        )
      : translate('components.date-range-picker.select-a-range');
  }

  public maxDate = new Date();

  constructor(
    private languageService: LanguageService,
    private fb: UntypedFormBuilder
  ) {
    combineLatest([
      this.startControl.valueChanges,
      this.endControl.valueChanges,
    ]).subscribe(([start, end]) => {
      this.selectedDateRange = new DateRange(start, end);
    });
    this.range.setValidators(this.intervalValidator());
  }

  ngAfterContentInit(): void {
    this.resetRange();
    this.applySelectedRange(true);
  }

  onCalendarChange(date: Date): void {
    this.updateSelectedOption('customized');
    if (
      this.selectedDateRange &&
      this.selectedDateRange.start &&
      date >= this.selectedDateRange.start &&
      !this.selectedDateRange.end
    ) {
      this.updateRange({ start: this.startControl.value, end: date });
    } else {
      this.updateRange({ start: date, end: null });
    }
  }

  onOptionSelectionChange(option: DateRangeOption) {
    this.updateRange(this.chooseDateRangeOption(option));
  }

  applySelectedRange(isFirstCall: boolean = false) {
    if (!this.range.valid && this.selectedOption === 'customized') return;
    if (!isFirstCall) this.range.markAsDirty();

    const dateRangePickerData: DateRangePickerData = {
      dateRangeValidity:
        this.selectedOption !== 'customized' ||
        (this.startControl.valid &&
          this.startControl.value &&
          this.endControl.valid &&
          this.endControl.value),
      dateRange: {
        start: startOfDay(new Date(this.startControl.value)),
        end: endOfDay(new Date(this.endControl.value)),
      },
      selectedOption: this.selectedOption,
    };

    this.saveValue(
      dateRangePickerData.dateRange,
      this.LOCAL_STORAGE_DATE_RANGE_SUFFIX
    );
    this.saveValue(
      dateRangePickerData.selectedOption,
      this.LOCAL_STORAGE_SELECTED_OPTION_SUFFIX
    );

    this.dateRangeEmitter.emit(dateRangePickerData);

    this.closeMenu();
  }

  resetRange() {
    const storedDateRange = this.retrieveDateRange();
    const storedDateRangeOption = this.retrieveDateRangeOption();
    if (storedDateRangeOption && storedDateRangeOption !== 'customized') {
      this.updateRange(this.chooseDateRangeOption(storedDateRangeOption));
    } else if (storedDateRange) {
      this.updateRange(storedDateRange);
      this.updateSelectedOption('customized');
    } else if (this.dateRange) {
      this.updateRange(this.dateRange);
      this.updateSelectedOption('customized');
    } else if (this.dateRangeOption) {
      this.updateRange(this.chooseDateRangeOption(this.dateRangeOption));
    } else {
      this.updateRange(this.DEFAULT_DATE_RANGE);
      this.updateSelectedOption(this.DEFAULT_DATE_RANGE_OPTION);
    }

    this.closeMenu();
  }

  closeMenu() {
    this.menuTrigger?.closeMenu();
  }

  updateSelectedOption(option: DateRangeOption) {
    this.selectedOption = option;
    this.translateSelectedOption(option);
  }

  private updateRange(value: Interval) {
    this.range.setValue(value);
  }

  private intervalValidator(): ValidatorFn {
    return (group: UntypedFormGroup): ValidationErrors | null => {
      const start = group.controls['start'];
      const end = group.controls['end'];
      if (start.value && end.value) {
        const isStartBeforeEnd = isBefore(start.value, end.value);
        const isStartEqualEnd = isEqual(start.value, end.value);
        if (!isStartBeforeEnd && !isStartEqualEnd) {
          return { endBeforeStart: true };
        } else {
          return null;
        }
      }
      return { invalidFormat: true };
    };
  }

  hasInvalidRangeError(): boolean {
    return this.range.dirty && this.range.hasError('endBeforeStart');
  }

  hasInvalidFormatError(): boolean {
    return this.range.dirty && this.range.hasError('invalidFormat');
  }

  private retrieveValue<T>(localStorageSuffix: string): T | null {
    if (!this.pageName) return null;

    const storedRawValue = localStorage.getItem(
      this.pageName + this.LOCAL_STORAGE_SUFFIX + localStorageSuffix
    );
    if (!storedRawValue) return null;

    let storedValue: T;
    try {
      storedValue = JSON.parse(storedRawValue) as T;
    } catch (e) {
      return null;
    }

    return storedValue ?? null;
  }

  private retrieveDateRange(): Interval | null {
    const storedDateRange = this.retrieveValue<Interval>(
      this.LOCAL_STORAGE_DATE_RANGE_SUFFIX
    );

    return storedDateRange
      ? {
          start: new Date(storedDateRange.start),
          end: new Date(storedDateRange.end),
        }
      : null;
  }

  private retrieveDateRangeOption(): DateRangeOption | null {
    const storedDateRangeOption = this.retrieveValue<DateRangeOption>(
      this.LOCAL_STORAGE_SELECTED_OPTION_SUFFIX
    );
    if (!storedDateRangeOption) return null;
    if (!DATE_RANGE_OPTIONS.includes(storedDateRangeOption)) return null;
    return storedDateRangeOption;
  }

  private saveValue<T>(value: T, localStorageSuffix: string) {
    if (!this.pageName) return;
    localStorage.setItem(
      this.pageName + this.LOCAL_STORAGE_SUFFIX + localStorageSuffix,
      JSON.stringify(value)
    );
  }

  private chooseDateRangeOption(value: DateRangeOption): Interval {
    this.updateSelectedOption(value);
    return this.getDateRangeFromDateRangeOption(value);
  }

  private getDateRangeFromDateRangeOption(value: DateRangeOption): Interval {
    switch (value) {
      case 'last-30-days':
        return DateRangeLogic.LAST_30_DAYS_DATE_RANGE;
      case 'last-60-days':
        return DateRangeLogic.LAST_60_DAYS_DATE_RANGE;
      case 'last-90-days':
        return DateRangeLogic.LAST_90_DAYS_DATE_RANGE;
      case 'last-6-months':
        return DateRangeLogic.LAST_6_MONTHS_DATE_RANGE;
      case 'last-12-months':
        return DateRangeLogic.LAST_12_MONTHS_DATE_RANGE;
      default:
        return DateRangeLogic.LAST_90_DAYS_DATE_RANGE;
    }
  }

  private translateSelectedOption(option: DateRangeOption) {
    if (this.selectionOverlay) {
      this.selectionOverlay.nativeElement.style.transform = `translateY(${
        this.DATE_RANGE_OPTIONS.indexOf(option) * this.OPTION_HEIGHT + 'px'
      })`;
    }
  }
}
