import { Observable } from 'rxjs';

import { Injectable } from '@angular/core';
import { Firestore } from '@angular/fire/firestore';

import { BaseService, PathSpec } from '@arrivage-services/base.service';
import { OrganizationAccountSetup } from '@arrivage/model/dist/src/model';
import { CollectionNames } from '@arrivage/model/dist/src/utils';

@Injectable({
  providedIn: 'root',
})
export class OrganizationSetupService extends BaseService<OrganizationAccountSetup> {
  static readonly ORGANIZATION_COLLECTION =
    CollectionNames.ORGANIZATION_COLLECTION;
  static readonly ORGANIZATION_ACCOUNT_SETUP_COLLECTION_NAME =
    CollectionNames.ORGANIZATION_ACCOUNT_SETUP_COLLECTION;
  static readonly ORGANIZATION_ACCOUNT_SETUP_ID = 'ACCOUNT_SETUP';

  constructor(firestore: Firestore) {
    super(firestore);
  }

  get(organizationId: string): Observable<OrganizationAccountSetup> {
    return this._get(
      this.pathSpec(organizationId),
      OrganizationSetupService.ORGANIZATION_ACCOUNT_SETUP_ID
    );
  }

  update(
    organizationId: string,
    record: Partial<OrganizationAccountSetup>
  ): Promise<void> {
    return this._update(
      this.pathSpec(organizationId),
      OrganizationSetupService.ORGANIZATION_ACCOUNT_SETUP_ID,
      record
    );
  }

  private pathSpec(organizationId: string): PathSpec[] {
    return [
      {
        collection: OrganizationSetupService.ORGANIZATION_COLLECTION,
        id: organizationId,
      },
      {
        collection:
          OrganizationSetupService.ORGANIZATION_ACCOUNT_SETUP_COLLECTION_NAME,
      },
    ];
  }
}
