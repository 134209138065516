import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ConvertLocalDatePipe } from './convert-localdate.pipe';

@NgModule({
  declarations: [ConvertLocalDatePipe],
  imports: [CommonModule],
  exports: [ConvertLocalDatePipe],
})
export class LocalDatePipeModule {}
