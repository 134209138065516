import _, { Dictionary } from 'lodash';
import { Observable, Subscription } from 'rxjs';

import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';

import { distinctUntilChanged, filter, map, take } from 'rxjs/operators';

import { Store } from '@ngrx/store';

import { AdminFacade } from '@arrivage-admin/api/admin.facade';
import { AuthUser } from '@arrivage-auth/model/auth-data.model';
import { getAuthUser } from '@arrivage-auth/store/auth.selectors';
import { ConversationFacade } from '@arrivage-conversations/api/conversation.facade';
import { CreditAccountsFacade } from '@arrivage-credit-accounts/api/credit-accounts.facade';
import { CustomerAccess } from '@arrivage-customer-access/customer-access.model';
import { PickupFacade } from '@arrivage-distribution/vendor/api/pickup.facade';
import { VendorDeliveryFacade } from '@arrivage-distribution/vendor/api/vendor-delivery.facade';
import { NotificationFacade } from '@arrivage-notifications/api/notifications.facade';
import { VendorOfferFacade } from '@arrivage-offers/vendor/api/vendor-offers.facade';
import { OrganizationSettingsFacade } from '@arrivage-organization-settings/api/organization-settings.facade';
import { PremiumAccountFacade } from '@arrivage-premium-accounts/api/premium-account.facade';
import { PriceListFacade } from '@arrivage-price-lists/api/price-list.facade';
import { PurchaseOrderDashboardMetrics } from '@arrivage-purchase-orders/common/model/purchase-orders.model';
import { CustomerPurchaseOrderFacade } from '@arrivage-purchase-orders/customer/api/customer-purchase-orders.facade';
import { VendorPurchaseOrderFacade } from '@arrivage-purchase-orders/vendor/api/vendor-purchase-orders.facade';
import { RelationshipFacade } from '@arrivage-relationship/common/api/relationship.facade';
import { DeliveryByRelationshipId } from '@arrivage-relationship/common/model/relationship.model';
import {
  ScreenSize,
  ScreenSizeService,
} from '@arrivage-services/screen-size.service';
import * as fromContext from '@arrivage-store/context/context.selectors';
import { State } from '@arrivage-store/state';
import {
  Conversation,
  CreditAccount,
  Delivery,
  Notification,
  Offer,
  Organization,
  OrganizationSettings,
  PremiumAccount,
  PriceList,
  User,
  WithId,
} from '@arrivage/model/dist/src/model';

@Component({
  selector: 'app-navigation',
  templateUrl: './page-navigation.component.html',
  styleUrls: ['./page-navigation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageNavigationComponent implements OnInit, OnDestroy {
  user$: Observable<User & WithId>;
  authUser$: Observable<AuthUser>;
  activeOrganization$: Observable<Organization & WithId>;
  settings$: Observable<OrganizationSettings>;
  notifications$: Observable<(Notification & WithId)[]>;
  screenSize$: Observable<ScreenSize>;

  customerPurchaseOrderMetrics$: Observable<PurchaseOrderDashboardMetrics>;
  vendorPurchaseOrderMetrics$: Observable<PurchaseOrderDashboardMetrics>;
  creditAccount$: Observable<CreditAccount>;
  premiumAccount$: Observable<PremiumAccount>;
  offerByPriceList$: Observable<Dictionary<Offer & WithId>>;
  priceLists$: Observable<(PriceList & WithId)[]>;
  deliveries$: Observable<(Delivery & WithId)[]>;
  conversations$: Observable<(Conversation & WithId)[]>;
  customersAccessByPriceList$: Observable<
    Dictionary<CustomerAccess.Customers & WithId>
  >;
  deliveryByRelationship$: Observable<DeliveryByRelationshipId>;
  organizations$: Observable<(Organization & WithId)[]>;

  private subscription: Subscription;
  private authUserSub: Subscription;

  constructor(
    private store: Store<State>,
    private settingsFacade: OrganizationSettingsFacade,
    private notificationFacade: NotificationFacade,
    private customerPurchaseOrderFacade: CustomerPurchaseOrderFacade,
    private vendorPurchaseOrderFacade: VendorPurchaseOrderFacade,
    private relationshipFacade: RelationshipFacade,
    private priceListFacade: PriceListFacade,
    private vendorOfferFacade: VendorOfferFacade,
    private deliveryFacade: VendorDeliveryFacade,
    private pickupFacade: PickupFacade,
    private conversationFacade: ConversationFacade,
    private creditAccountsFacade: CreditAccountsFacade,
    private premiumAccountFacade: PremiumAccountFacade,
    private screenSizeService: ScreenSizeService,
    private adminFacade: AdminFacade
  ) {
    this.screenSize$ = this.screenSizeService.getScreenSizeObserver();
    this.user$ = this.store.select(fromContext.getUser);
    this.authUser$ = this.store.select(getAuthUser);
    this.activeOrganization$ = this.store.select(fromContext.getOrganization);
    this.settings$ = this.settingsFacade.getOrganizationSettings();
    this.notifications$ = this.notificationFacade.getAll();
    this.offerByPriceList$ = this.priceListFacade.getOfferByPriceList();
    this.priceLists$ = this.priceListFacade
      .getAll()
      .pipe(distinctUntilChanged((prev, curr) => _.isEqual(prev, curr)));
    this.customersAccessByPriceList$ =
      this.priceListFacade.getAllPriceListCustomersAccess();
    this.deliveries$ = this.deliveryFacade.getAll();
    this.conversations$ = this.conversationFacade.getAll();
    this.deliveryByRelationship$ =
      this.deliveryFacade.getAllRelationsDeliveries();
    this.creditAccount$ = this.creditAccountsFacade.getCreditAccount();
    this.premiumAccount$ = this.premiumAccountFacade.getPremiumAccount();

    this.customerPurchaseOrderMetrics$ =
      this.customerPurchaseOrderFacade.getPurchaseOrderDashboardMetrics();
    this.vendorPurchaseOrderMetrics$ = this.vendorPurchaseOrderFacade
      .getPurchaseOrderDashboardMetrics()
      .pipe(distinctUntilChanged((prev, curr) => _.isEqual(prev, curr)));
    this.organizations$ = this.adminFacade
      .getAllOrganizations()
      .pipe(
        map((organizations) =>
          _.orderBy(organizations, (o) => o.name.toLowerCase())
        )
      );
  }

  ngOnInit() {
    this.subscription = this.activeOrganization$
      .pipe(
        filter((x) => !!x),
        take(1)
      )
      .subscribe((x) => {
        this.notificationFacade.loadNotifications();
        this.conversationFacade.loadAll();
        if (x.isSeller) {
          this.priceListFacade.loadAll();
          this.deliveryFacade.loadAll();
          this.pickupFacade.loadAll();
          this.vendorPurchaseOrderFacade.loadAll();
          this.relationshipFacade.loadRelationships();
          this.vendorOfferFacade.loadAll();
        }
        if (x.isBuyer) {
          this.customerPurchaseOrderFacade.loadAll();
        }
      });
    this.authUserSub = this.authUser$
      .pipe(
        filter((x) => !!x),
        take(1)
      )
      .subscribe((x: AuthUser) => {
        if (x.claims?.['admin']) {
          this.adminFacade.loadAllOrganizations();
        }
      });
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.authUserSub) {
      this.authUserSub.unsubscribe();
    }
  }

  markAsRead(notificationId: string) {
    this.notificationFacade.markAsRead(notificationId);
  }
}
