import { Injectable } from '@angular/core';
import {
  Analytics,
  getAnalytics,
  isSupported,
  logEvent,
  setUserProperties,
} from '@angular/fire/analytics';

import { map, tap } from 'rxjs/operators';

import { Actions, createEffect, ofType } from '@ngrx/effects';

import * as authActions from '@arrivage-auth/store/auth.actions';

import { setTestGroupFromOrganizationId } from '@arrivage-analytics/utils/test-group-assignment';
import * as actions from './analytics.actions';

@Injectable()
export class AnalyticsEffects {
  private analytics: Analytics;

  constructor(private actions$: Actions) {}

  logEvent$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actions.logEvent),
        tap(async (event) => {
          if (await isSupported()) {
            this.analytics = getAnalytics();
            logEvent(this.analytics, event.eventName, event.params);
          }
        })
      ),
    { dispatch: false }
  );

  setOrganizationProperties$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actions.setOrganizationProperties),
        tap(async (action) => {
          if (await isSupported()) {
            this.analytics = getAnalytics();
            setUserProperties(this.analytics, {
              organization_id: action.organization.id,
              seller: action.organization.isSeller,
              buyer: action.organization.isBuyer,
              buyer_organization_type: action.organization.isBuyer
                ? action.organization.customerOrganizationType
                : null,
              test_group: setTestGroupFromOrganizationId(
                action.organization.id
              ),
            });
          }
        })
      ),
    { dispatch: false }
  );

  login$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.Login),
      map((a) => actions.logEvent({ eventName: 'login' }))
    )
  );

  logout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.Logout),
      map((a) => actions.logEvent({ eventName: 'logout' }))
    )
  );
}
