import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { PictureService } from '@arrivage-services/picture.service';
import {
  Conservation,
  LocalIngredient,
  OfferItem,
  OrderItem,
  Product,
  WithId,
} from '@arrivage/model/dist/src/model';

@Component({
  selector: 'app-product-format-info',
  templateUrl: './product-format-info.component.html',
  styleUrls: ['./product-format-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductFormatInfoComponent {
  readonly DEFAULT_PRODUCT_PICTURE = PictureService.defaultProductPicture;
  readonly LOCAL_INGREDIENT = LocalIngredient;
  readonly CONSERVATION = Conservation;

  protected showImage: boolean = false;

  @Input({ required: true })
  product: Product & WithId;

  @Input({ required: false })
  currentFormatId: string;

  @Input({ required: false })
  offerItems: OfferItem[];

  @Input({ required: false })
  orderItem: OrderItem;

  @Input({ required: false })
  isExtraSmallScreen: boolean;

  @Input({ required: false })
  showPriceAndStock: boolean = true;

  constructor() {}

  get isFilesUrlAvailable(): boolean {
    return this.product.filesUrl?.length > 0;
  }

  get currentOfferItem(): OfferItem {
    return this.currentFormatId
      ? this.offerItems?.find(
          (offerItem) => offerItem.format.id === this.currentFormatId
        )
      : null;
  }

  get filteredOfferItems(): OfferItem[] {
    return this.currentFormatId
      ? this.offerItems?.filter(
          (offerItem) => offerItem.format.id !== this.currentFormatId
        )
      : this.offerItems;
  }
}
