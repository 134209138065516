import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface YesNoDialogData {
  title: string;
  message: string;
  canCancel?: boolean;
}

export type YesNoResponse = 'yes' | 'no' | null;

@Component({
  selector: 'app-yes-no-dialog',
  templateUrl: './yes-no-dialog.component.html',
  styleUrls: ['./yes-no-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class YesNoDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: YesNoDialogData,
    public dialogRef: MatDialogRef<YesNoDialogComponent>
  ) {
    // disable closing the dialog by clicking outside of it or by pressing 'esc'
    dialogRef.disableClose = true;

    data.canCancel = this.data.canCancel ?? true;
  }
}
