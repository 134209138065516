import { createAction, props } from '@ngrx/store';

import { Organization, WithId } from '@arrivage/model/dist/src/model';

const featureName = 'OrganizationAction';

export const getOrganization = createAction(`[${featureName}] getOrganization`);

export const getOrganizationSuccess = createAction(
  `[${featureName}] getOrganizationSuccess`,
  props<{
    organization: Organization & WithId;
  }>()
);

export const getOrganizationFailure = createAction(
  `[${featureName}] getOrganizationFailure`,
  props<{
    error: any;
  }>()
);
