<ng-container>
  <div
    class="mat-dialog-content space-top"
    *ngIf="!data.progress?.progressValue; else progressDialog"
    fxLayout="column"
    fxLayoutAlign="center center"
    fxLayoutGap="20px"
  >
    <mat-spinner diameter="75"></mat-spinner>
    <p fxFlex>{{ data.message }}</p>
  </div>

  <ng-template #progressDialog>
    <mat-dialog-content
      fxLayout="column"
      fxLayoutAlign="center center"
      fxLayoutGap="20px"
    >
      <mat-progress-spinner
        diameter="75"
        [value]="progressValueInPercentage"
      ></mat-progress-spinner>
      <p fxFlex>
        {{ data.progress?.progressValue | number }}/{{
          data.progress?.totalValue | number
        }}
      </p>
      <p fxFlex>{{ data.message }}</p>
    </mat-dialog-content>
  </ng-template>
</ng-container>
