import { Pipe, PipeTransform, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'sanitize',
})
export class SanitizePipe implements PipeTransform {
  constructor(protected sanitizer: DomSanitizer) {}

  transform(value: any, securityContext: number): string {
    switch (securityContext) {
      case SecurityContext.NONE:
        return this.sanitizer.sanitize(SecurityContext.NONE, value);
      case SecurityContext.HTML:
        return this.sanitizer.sanitize(SecurityContext.HTML, value);
      case SecurityContext.STYLE:
        return this.sanitizer.sanitize(SecurityContext.STYLE, value);
      case SecurityContext.SCRIPT:
        return this.sanitizer.sanitize(SecurityContext.SCRIPT, value);
      case SecurityContext.URL:
        return this.sanitizer.sanitize(SecurityContext.URL, value);
      case SecurityContext.RESOURCE_URL:
        return this.sanitizer.sanitize(SecurityContext.RESOURCE_URL, value);
      default:
        throw new Error(
          `Invalid security context specified: ${securityContext}`
        );
    }
  }
}
