import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';

import { OrganizationSettingsService } from '@arrivage-services/organization-settings/organization-settings.service';
import { createSelectors } from '@arrivage-store/generators';
import { WithId, OrganizationSettings } from '@arrivage/model/dist/src/model';

import { adapter } from './organization-settings.reducer';
import {
  OrganizationSettingsState,
  State,
} from './organization-settings.state';

export const getOrganizationSettingsState =
  createFeatureSelector<OrganizationSettingsState>('organizationSettings');

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
  connecting,
  connected,
  connectState,
  queryFailure,
  addFailure,
  setFailure,
  updateFailure,
  removeFailure,
  getById,
  selectActiveItem,
  isLoadingActiveItem,
  selectActiveItemState,
} = createSelectors<OrganizationSettings, State>(
  adapter,
  getOrganizationSettingsState
);

export const selectSettings = createSelector(
  selectEntities,
  (entities: Dictionary<OrganizationSettings & WithId>) => {
    return entities[OrganizationSettingsService.SETTINGS_ID];
  }
);
