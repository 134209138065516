import { FirestoreEntityState } from '@arrivage-store/generators';
import * as fromRoot from '@arrivage-store/state';
import { Delivery } from '@arrivage/model/dist/src/model';

export const VendorDeliveryStateName = 'vendorDeliveries';

export interface VendorDeliveryState extends FirestoreEntityState<Delivery> {}

export interface State extends fromRoot.State {
  [VendorDeliveryStateName]: VendorDeliveryState;
}
