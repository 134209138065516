import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { BlurOnWheelInputNumberDirective } from './blur-on-wheel-input-number.directive';

@NgModule({
  declarations: [BlurOnWheelInputNumberDirective],
  imports: [CommonModule],
  exports: [BlurOnWheelInputNumberDirective],
})
export class BlurOnWheelInputNumberDirectiveModule {}
