import { from, of } from 'rxjs';

import { Injectable } from '@angular/core';

import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { FormatService } from '@arrivage-formats/services/format.service';
import { IdService } from '@arrivage-services/id.service';
import { PictureService } from '@arrivage-services/picture.service';
import { SnackbarService } from '@arrivage-snackbar/snackbar.service';
import { getOrganization } from '@arrivage-store/context/context.selectors';
import { EntityFeedback } from '@arrivage-store/feedback/feedback-params.model';
import { createBaseEffects } from '@arrivage-store/generators';

import * as formatsActions from '../store/formats.actions';
import {
  updateFailure,
  updateSuccess,
  updateWithPicture,
} from '../store/formats.actions';
import * as formatsSelectors from '../store/formats.selectors';
import { FormatsState, State } from './formats.state';

interface FormatFeedback extends EntityFeedback {
  create: string;
  duplicate: string;
}

export const FormatFeedback: FormatFeedback = {
  query: 'query_formats',
  update: 'update_format',
  create: 'create_format',
  duplicate: 'duplicate_format',

  add: '',
  remove: 'delete_format',
  get_active_item: '',
};

@Injectable()
export class FormatsEffects {
  query$;
  set$;
  add$;
  update$;
  remove$;
  getActiveItem$;
  displayQueryFailure$;
  displayGetActiveItemFailure$;

  constructor(
    private actions$: Actions,
    private store: Store<State & FormatsState>,
    private formatService: FormatService,
    private snackbarService: SnackbarService,
    private pictureService: PictureService,
    private idService: IdService
  ) {
    ({
      query: this.query$,
      set: this.set$,
      add: this.add$,
      update: this.update$,
      remove: this.remove$,
      getActiveItem: this.getActiveItem$,
      displayQueryFailure: this.displayQueryFailure$,
      displayGetActiveItemFailure: this.displayGetActiveItemFailure$,
    } = createBaseEffects(
      this.actions$,
      this.store,
      formatsActions,
      formatsSelectors,
      this.formatService,
      FormatFeedback,
      this.snackbarService
    ));
  }

  updateWithPicture$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateWithPicture),
      withLatestFrom(this.store.select(getOrganization)),
      switchMap(([update, organization]) => {
        return from(
          this.pictureService
            .uploadPicture(
              update.record.newPictureFile,
              'organizations/' +
                organization.id +
                '/formats/' +
                this.idService.createId()
            )
            .then((url) => {
              const formatWithPicture = {
                ...update.record.format,
              };
              formatWithPicture.photoUrl = url;
              return this.formatService.update(
                organization.id,
                formatWithPicture.id,
                formatWithPicture
              );
            })
        ).pipe(
          map(() => {
            update.confirmation.resolve(update.record.format.id);
            return updateSuccess({ id: update.record.format.id });
          }),
          catchError((error) => {
            update.confirmation.reject(error);
            return of(
              updateFailure({
                record: update.record.format,
                errorMessage: error,
              })
            );
          })
        );
      })
    )
  );
}
