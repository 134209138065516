import { TranslocoModule } from '@jsverse/transloco';
import { AppUtilsModule } from 'src/app/app-utils.module';
import { MaterialModule } from 'src/app/material.module';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';

import { LoadingDialogComponent } from './loading-dialog.component';

@NgModule({
  declarations: [LoadingDialogComponent],
  imports: [
    CommonModule,
    MaterialModule,
    TranslocoModule,
    FlexLayoutModule,
    AppUtilsModule,
  ],
  exports: [LoadingDialogComponent],
})
export class LoadingDialogModule {}
