import { Action, createReducer } from '@ngrx/store';

import { createCommonPurchaseOrderReducer } from '@arrivage-purchase-orders/common/store/base-purchase-orders.reducer';
import { purchaseOrderAdapter } from '@arrivage-purchase-orders/common/store/base-purchase-orders.selectors';
import { createInitialState } from '@arrivage-store/generators';

import { VendorPurchaseOrdersActions } from './vendor-purchase-orders.actions';
import { VendorPurchaseOrdersState } from './vendor-purchase-orders.state';

export const initialState: VendorPurchaseOrdersState = createInitialState(
  purchaseOrderAdapter,
  {
    currentDateRange: undefined,
    storedDateRanges: [],
    dateRangeLoadingCollectionState: {
      items: {
        sent: [],
        deliveryOrPickup: [],
      },
      isLoading: false,
      error: undefined,
    },
    subPurchaseOrdersCurrentDateRange: undefined,
    subPurchaseOrdersStoredDateRanges: [],
    subPurchaseOrdersByDateRange: {
      items: {
        sent: [],
        deliveryOrPickup: [],
      },
      isLoading: false,
      error: undefined,
    },
  }
);

const purchaseOrdersReducer = createReducer(
  initialState,
  ...createCommonPurchaseOrderReducer<VendorPurchaseOrdersState>(
    VendorPurchaseOrdersActions,
    purchaseOrderAdapter
  )
);

export function reducer(
  state: VendorPurchaseOrdersState | undefined,
  action: Action
) {
  return purchaseOrdersReducer(state, action);
}
