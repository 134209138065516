import { of } from 'rxjs';

import { Injectable } from '@angular/core';
import { FirebaseError } from '@angular/fire/app';

import {
  map,
  catchError,
  filter,
  withLatestFrom,
  exhaustMap,
  takeUntil,
} from 'rxjs/operators';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { Logout } from '@arrivage-auth/store/auth.actions';
import { reportError } from '@arrivage-sentry/report-error';
import { getOrganization } from '@arrivage-store/context/context.selectors';

import { CreditAccountsService } from '../services/credit-accounts.service';
import * as creditAccountActions from '../store/credit-account.actions';
import { CreditAccountState, State } from './credit-account.state';

@Injectable()
export class CreditAccountEffects {
  query$ = createEffect(() =>
    this.actions$.pipe(
      ofType(creditAccountActions.query),
      withLatestFrom(this.store.select(getOrganization)),
      map(([, organization]) => organization),
      filter((organization) => !!organization),
      exhaustMap((organization) =>
        this.creditAccountsService.get(organization.id).pipe(
          takeUntil(this.actions$.pipe(ofType(Logout))),
          map((ca) => {
            return creditAccountActions.querySuccess({ creditAccount: ca });
          }),
          catchError((e: FirebaseError) => {
            reportError(e);
            return of(
              creditAccountActions.queryFailure({ errorMessage: e.message })
            );
          })
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private store: Store<State & CreditAccountState>,
    private creditAccountsService: CreditAccountsService
  ) {}
}
