import { Observable } from 'rxjs';

import { Injectable } from '@angular/core';
import { Firestore, where } from '@angular/fire/firestore';

import { EntityChangeAction } from '@arrivage-services/organization-entity.service';
import { Offer } from '@arrivage/model/dist/src/model';

import { OfferService } from '../../common/services/offers.service';

@Injectable({
  providedIn: 'root',
})
export class VendorOfferService extends OfferService {
  constructor(firestore: Firestore) {
    super(firestore);
  }

  override connect(organizationId: string): Observable<EntityChangeAction<Offer>[]> {
    return this._connect(
      this.pathSpec(),
      OfferService.convertTimestampFields,
      null,
      where('vendor.organizationId', '==', organizationId)
    );
  }

  override create(organizationId: string, record: Offer): Promise<string> {
    return this._create(this.pathSpec(), record);
  }

  override update(
    organizationId: string,
    recordId: string,
    record: Partial<Offer>
  ): Promise<void> {
    return this._update(this.pathSpec(), recordId, record);
  }
}
