import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  AbstractControl,
  FormGroupDirective,
  NgForm,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';

import { phoneNumberValidator } from '@arrivage-validators/custom-validators';
import { PHONE_PATTERN_CA } from '@arrivage-validators/validators.global';
import { ContactInfo } from '@arrivage/model/dist/src/model';

const AtLeastOnePhoneNumberValidator: ValidatorFn = (fg: UntypedFormGroup) => {
  const phone = fg.get('phone').value;
  if (phone) {
    return null;
  }
  return { phone: true };
};

class PhoneFieldErrorStateMatcher {
  isErrorState(
    control: UntypedFormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    return (
      form &&
      (form.hasError('phone') || control.invalid) &&
      (form.touched || form.submitted)
    );
  }
}

@Component({
  selector: 'app-contact-info-form',
  templateUrl: './contact-info-form.component.html',
  styleUrls: [
    '../../styles/forms.style.scss',
    './contact-info-form.component.scss',
  ],
})
export class ContactInfoFormComponent implements OnInit {
  readonly ControlName = 'contactInfoForm';
  readonly baseEmailValidators = Validators.compose([
    Validators.required,
    Validators.email,
  ]);

  private _contactInfo: ContactInfo;
  private _userPartialInfo: { firstName: string; lastName: string };
  private _additionalEmailValidator: {
    fn: (control: AbstractControl) => string | null;
    errorLabel: string;
  } = null;
  phoneFieldErrorStateMatcher = new PhoneFieldErrorStateMatcher();

  form = this.fb.group({
    phone: [null, phoneNumberValidator(PHONE_PATTERN_CA)],
    email: [null, this.baseEmailValidators],
    lastName: [null],
    firstName: [null],
  });

  hasChanged = false;

  @Input()
  requireAtLeastOnePhoneNumber = false;

  @Input()
  isOrganizationContactForm: boolean;

  @Input()
  readonly: boolean = false;

  @Input()
  isExtraSmallScreen: boolean;

  @Input()
  displayContactInfoName = true;
  @Input()
  set userPartialInfo(value: { firstName: string; lastName: string }) {
    if (value) {
      this._userPartialInfo = value;
      this.fillForm();
    }
  }

  get userPartialInfo() {
    return this._userPartialInfo;
  }

  @Input()
  set contactInfo(value: ContactInfo) {
    this._contactInfo = value;
    this.fillForm();
  }

  get contactInfo() {
    return this._contactInfo;
  }

  @Input()
  set parentForm(value: UntypedFormGroup) {
    if (value) {
      value.setControl(this.ControlName, this.form);
    }
  }

  @Input()
  set emailAdditionalValidation(additionalValidation: {
    fn: (control: AbstractControl) => string | null;
    errorLabel: string;
  }) {
    this._additionalEmailValidator = additionalValidation;
    if (additionalValidation) {
      this.form.get('email').setValidators(
        Validators.compose([
          this.baseEmailValidators,
          (control: AbstractControl) => {
            const additionalValidationError = additionalValidation.fn(control);
            if (additionalValidationError) {
              return { additionalValidationError: additionalValidationError };
            }
            return null;
          },
        ])
      );
    } else {
      this.form.get('email').setValidators(this.baseEmailValidators);
    }

    this.form.updateValueAndValidity();
  }

  get emailAdditionalValidation(): {
    fn: (control: AbstractControl) => string | null;
    errorLabel: string;
  } {
    return this._additionalEmailValidator;
  }

  @Output()
  mainEmailChange = new EventEmitter<string>();

  constructor(private fb: UntypedFormBuilder) {}

  ngOnInit(): void {
    if (this.requireAtLeastOnePhoneNumber) {
      this.form.setValidators(AtLeastOnePhoneNumberValidator);
    }
    if (this.readonly) {
      this.form.disable();
    }
  }

  fillForm() {
    this.form.reset();
    if (this.contactInfo) {
      this.form.patchValue(this.contactInfo, { emitEvent: false });
    }

    if (this.userPartialInfo) {
      this.form.get('firstName').setValue(this.userPartialInfo.firstName);
      this.form.get('lastName').setValue(this.userPartialInfo.lastName);
    }
  }

  cancelChanges() {
    this.fillForm();
  }

  getRecord(): ContactInfo {
    this.form.markAllAsTouched();
    if (this.form.valid || this.readonly) {
      const contactInfoDataForm = this.form.value as ContactInfo;
      const contactInfo: ContactInfo = {
        email: this.form.get('email').value,
      };
      if (contactInfoDataForm.phone) {
        contactInfo.phone = contactInfoDataForm.phone.trim();
      }
      if (contactInfoDataForm.firstName) {
        contactInfo.firstName = contactInfoDataForm.firstName;
      }
      if (contactInfoDataForm.lastName) {
        contactInfo.lastName = contactInfoDataForm.lastName;
      }
      return contactInfo;
    }
    return undefined;
  }

  onEmailChange() {
    const email = this.form.get('email').value;
    this.mainEmailChange.emit(email);
  }
}
