import _ from 'lodash';

import { Injectable } from '@angular/core';
import { Firestore } from '@angular/fire/firestore';

import { InvoiceStatus } from '@arrivage/model/dist/src/model';

import { BaseInvoiceService } from '../../common/services/base-invoice.service';
import { InvoicesIdFields } from '../../common/services/invoices-id-fields';

@Injectable({
  providedIn: 'root',
})
export class CustomerInvoiceService extends BaseInvoiceService {
  private static readonly CUSTOMER_VISIBLE_INVOICE_STATUSES: InvoiceStatus[] =
    _.filter(
      BaseInvoiceService.ALL_INVOICES_STATUSES,
      (s) => s !== InvoiceStatus.DRAFT
    );

  constructor(firestore: Firestore) {
    super(
      firestore,
      InvoicesIdFields.ID_FIELDS_ORGANIZATIONS.CUSTOMER,
      [
        InvoiceStatus.PUBLISHED,
        InvoiceStatus.SENT,
        InvoiceStatus.PAYMENT_EMITTED,
      ],
      CustomerInvoiceService.CUSTOMER_VISIBLE_INVOICE_STATUSES,
      'history.publishDate'
    );
  }
}
