import { createFeatureSelector } from '@ngrx/store';

import { createSelectors } from '@arrivage-store/generators';
import { Offer } from '@arrivage/model/dist/src/model';

import { FEATURE_NAME } from './visible-offers-store.constants';
import { adapter } from './visible-offers.reducer';
import { State, VisibleOffersState } from './visible-offers.state';

export const getVisibleOffersState =
  createFeatureSelector<VisibleOffersState>(FEATURE_NAME);

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
  connecting,
  connected,
  connectState,
  queryFailure,
  addFailure,
  setFailure,
  updateFailure,
  removeFailure,
  getById,
  selectActiveItem,
  isLoadingActiveItem,
  selectActiveItemState,
} = createSelectors<Offer, State>(adapter, getVisibleOffersState);
