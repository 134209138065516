import { Observable } from 'rxjs';

import { Injectable } from '@angular/core';

import { map } from 'rxjs/operators';

import { Store } from '@ngrx/store';

import { OrganizationSettingsService } from '@arrivage-services/organization-settings/organization-settings.service';
import { State } from '@arrivage-store/state';
import { OrganizationSettings, WithId } from '@arrivage/model/dist/src/model';

import { query, remove, update } from '../store/organization-settings.actions';
import { selectSettings } from '../store/organization-settings.selectors';

@Injectable({
  providedIn: 'root',
})
export class OrganizationSettingsFacade {
  private organizationSettings$: Observable<OrganizationSettings & WithId>;

  constructor(
    private store: Store<State>,
    private organizationSettingsService: OrganizationSettingsService
  ) {
    this.organizationSettings$ = this.store.select(selectSettings);
  }

  loadOrganizationSettings() {
    this.store.dispatch(query());
  }

  getOrganizationSettings(): Observable<OrganizationSettings & WithId> {
    return this.organizationSettings$;
  }

  getOrganizationSettingsById(
    id: string
  ): Observable<OrganizationSettings & WithId> {
    return this.organizationSettingsService.get(id);
  }

  getCurrency(): Observable<string> {
    return this.organizationSettings$.pipe(
      map((settings) => settings.sales?.currency)
    );
  }

  updateOrganizationSettings(
    organizationSettings: Partial<OrganizationSettings> & WithId
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      this.store.dispatch(
        update({
          record: organizationSettings,
          confirmation: {
            resolve: resolve,
            reject: reject,
          },
        })
      );
    });
  }

  deleteOrganizationSettings(
    organizationSettings: OrganizationSettings & WithId
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      this.store.dispatch(
        remove({
          id: organizationSettings.id,
          confirmation: {
            resolve: resolve,
            reject: reject,
          },
        })
      );
    });
  }
}
