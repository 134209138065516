import { Observable } from 'rxjs';

import { Injectable } from '@angular/core';
import {
  Firestore,
  collection,
  collectionGroup,
  collectionSnapshots,
  deleteDoc,
  doc,
  docSnapshots,
  query,
  setDoc,
  where,
} from '@angular/fire/firestore';

import { map } from 'rxjs/operators';

import { Member, RoleType, WithId } from '@arrivage/model/dist/src/model';
import { CollectionNames } from '@arrivage/model/dist/src/utils';

import { OrganizationService } from './organization.service';

@Injectable({
  providedIn: 'root',
})
export class MemberService {
  static readonly MEMBERS_COLLECTION = CollectionNames.MEMBER_COLLECTION;

  constructor(private firestore: Firestore) {}

  getMember(
    organizationId: string,
    memberId: string
  ): Observable<(Member & WithId) | null> {
    return docSnapshots(
      doc(this.firestore, this.collectionPath(organizationId), memberId)
    ).pipe(
      map((document) => {
        if (document.exists()) {
          const data = document.data() as Member;
          const id = document.id;
          return { id: id, ...data };
        } else {
          return null;
        }
      })
    );
  }

  getUserMemberships(userId: string): Observable<(Member & WithId)[]> {
    return collectionSnapshots(
      query(
        collectionGroup(this.firestore, MemberService.MEMBERS_COLLECTION),
        where('userId', '==', userId)
      )
    ).pipe(
      map((members) =>
        members.map((p) => {
          const data = p.data();
          const id = p.id;
          return { id: id, ...data } as Member & WithId;
        })
      )
    );
  }

  getOrganizationMembers(
    organizationId: string
  ): Observable<(Member & WithId)[]> {
    return collectionSnapshots(
      collection(this.firestore, this.collectionPath(organizationId))
    ).pipe(
      map((members) =>
        members.map((p) => {
          const data = p.data();
          const id = p.id;
          return { id, ...data } as Member & WithId;
        })
      )
    );
  }

  /**
   * Create the member record for the organization.
   *
   * It is important that the document id is the user id for the security rules to work.
   */
  createMember(organizationId: string, member: Member) {
    return setDoc(
      doc(this.firestore, this.collectionPath(organizationId), member.userId),
      member
    );
  }

  updateMember(organizationId: string, member: Member & WithId): Promise<void> {
    return setDoc(
      doc(this.firestore, this.collectionPath(organizationId), member.id),
      {
        organizationId: member.organizationId,
        userId: member.userId,
        roles: member.roles,
      }
    );
  }

  isUserAdmin(orgId: string, userId: string): Observable<boolean> {
    return this.getUserMemberships(userId).pipe(
      map((members) => {
        let isUserAdmin = false;
        members
          .filter((member) => member.organizationId === orgId)
          .forEach((member) => {
            if (member.roles.find((role) => role === RoleType.ADMINISTRATOR)) {
              isUserAdmin = true;
            }
          });
        return isUserAdmin;
      })
    );
  }

  deleteMember(organizationId: string, memberId: string): Promise<void> {
    return deleteDoc(
      doc(this.firestore, this.collectionPath(organizationId), memberId)
    );
  }

  private collectionPath(organizationId: string) {
    return (
      OrganizationService.ORGANIZATIONS_COLLECTION +
      '/' +
      organizationId +
      '/' +
      MemberService.MEMBERS_COLLECTION
    );
  }
}
