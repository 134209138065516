import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { SearchEffects } from './search.effects';
import { reducer } from './search.reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('search', reducer),
    EffectsModule.forFeature([SearchEffects]),
  ],
})
export class SearchStoreModule {}
