import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { ExternalCustomerInfoEffects } from './external-store.effect';
import { reducer } from './external-store.reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('externalInfo', reducer),
    EffectsModule.forFeature([ExternalCustomerInfoEffects]),
  ],
})
export class ExternalStoreModule {}
