import _ from 'lodash';

import { createFeatureSelector } from '@ngrx/store';

import { createSelectors } from '@arrivage-store/generators';
import { Pickup } from '@arrivage/model/dist/src/model';

import { adapter } from './pickup.reducer';
import { PickupState, State } from './pickup.state';

export const getPickupState = createFeatureSelector<PickupState>('pickup');

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
  connecting,
  connected,
  connectState,
  queryFailure,
  addFailure,
  setFailure,
  updateFailure,
  removeFailure,
  getById,
  selectActiveItem,
  isLoadingActiveItem,
  selectActiveItemState,
} = createSelectors<Pickup, State>(adapter, getPickupState);
