import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { VendorInvoicesEffects } from './vendor-invoices.effects';
import { reducer } from './vendor-invoices.reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('vendorInvoices', reducer),
    EffectsModule.forFeature([VendorInvoicesEffects]),
  ],
})
export class VendorInvoiceStoreModule {}
