import { Observable } from 'rxjs';

import { Injectable } from '@angular/core';
import {
  doc,
  docSnapshots,
  Firestore,
  updateDoc,
} from '@angular/fire/firestore';

import { map } from 'rxjs/operators';

import { CreditAccount, WithId } from '@arrivage/model/dist/src/model';
import { CollectionNames } from '@arrivage/model/dist/src/utils';

@Injectable({
  providedIn: 'root',
})
export class CreditAccountsService {
  static readonly CREDIT_ACCOUNTS_COLLECTION_NAME =
    CollectionNames.CREDIT_ACCOUNTS_COLLECTION;

  constructor(private firestore: Firestore) {}

  get(organizationId: string): Observable<CreditAccount & WithId> {
    return docSnapshots(
      doc(
        this.firestore,
        CreditAccountsService.CREDIT_ACCOUNTS_COLLECTION_NAME,
        organizationId
      )
    ).pipe(
      map((d) => {
        if (d.exists()) {
          return { ...d.data(), id: organizationId } as CreditAccount & WithId;
        } else {
          return undefined;
        }
      })
    );
  }

  update(organizationId: string, data: Partial<CreditAccount>): Promise<void> {
    return updateDoc(
      doc(
        this.firestore,
        CreditAccountsService.CREDIT_ACCOUNTS_COLLECTION_NAME,
        organizationId
      ),
      data
    );
  }
}
