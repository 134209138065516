import _ from 'lodash';
import { from, of } from 'rxjs';

import { Injectable } from '@angular/core';

import { catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { VendorInvoiceService } from '@arrivage-invoices/vendor/services/vendor-invoice.service';
import { SnackbarService } from '@arrivage-snackbar/snackbar.service';
import { getOrganization } from '@arrivage-store/context/context.selectors';
import { State } from '@arrivage-store/state';

import { InvoiceLogicService } from '../../common/api/invoice-logic.service';
import { createBaseInvoiceEffects } from '../../common/store/generator';
import * as invoicesActions from './vendor-invoices.actions';
import { VendorInvoicesActions } from './vendor-invoices.actions';
import { VendorInvoicesSelectors } from './vendor-invoices.selectors';

@Injectable()
export class VendorInvoicesEffects {
  query$;
  displayQueryFailure$;

  add$;
  update$;
  remove$;

  getActiveItem$;
  displayGetActiveItemFailure$;

  queryByDateRange$;
  queryByDateRangeGuard$;
  displayQueryByDateRangeFailure$;
  constructor(
    private actions$: Actions,
    private store: Store<State>,
    private service: VendorInvoiceService,
    private invoiceLogic: InvoiceLogicService,
    private snackbarService: SnackbarService
  ) {
    ({
      query: this.query$,
      displayQueryFailure: this.displayQueryFailure$,

      add: this.add$,
      update: this.update$,
      remove: this.remove$,

      getActiveItem: this.getActiveItem$,
      displayGetActiveItemFailure: this.displayGetActiveItemFailure$,

      queryByDateRange: this.queryByDateRange$,
      queryByDateRangeGuard: this.queryByDateRangeGuard$,
      displayQueryByDateRangeFailure: this.displayQueryByDateRangeFailure$,
    } = createBaseInvoiceEffects(
      this.actions$,
      this.store,
      VendorInvoicesActions,
      VendorInvoicesSelectors,
      this.service,
      this.snackbarService
    ));
  }

  updateInvoice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invoicesActions.updateInvoice),
      withLatestFrom(this.store.select(getOrganization)),
      mergeMap(([action, organization]) => {
        return from(
          this.invoiceLogic.updateInvoice(organization, action.invoice)
        ).pipe(
          map(() => {
            action.confirmation.resolve(action.invoice.id);
            return invoicesActions.updateInvoiceSuccess();
          }),
          catchError((e) => {
            action.confirmation.reject(e);
            return of(invoicesActions.updateInvoiceFailure({ error: e }));
          })
        );
      })
    )
  );

  sendInvoice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invoicesActions.sendInvoice),
      mergeMap((context) => {
        return from(
          this.invoiceLogic.sendInvoice(
            context.record.organization,
            context.record.invoice,
            context.record.organizationSettings,
            context.record.messageToCustomers
          )
        ).pipe(
          map((success) => {
            context.confirmation.resolve(success);
            return invoicesActions.sendInvoiceSuccess();
          }),
          catchError((e) => {
            context.confirmation.reject(e);
            return of(invoicesActions.sendInvoiceFailure());
          })
        );
      })
    )
  );

  publishInvoice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invoicesActions.publishInvoice),
      mergeMap((context) => {
        return from(
          this.invoiceLogic.publishInvoice(
            context.record.organization,
            context.record.invoice
          )
        ).pipe(
          map((success) => {
            context.confirmation.resolve(success);
            return invoicesActions.publishInvoiceSuccess();
          }),
          catchError((e) => {
            context.confirmation.reject(e);
            return of(invoicesActions.publishInvoiceSuccess());
          })
        );
      })
    )
  );

  cancelInvoice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invoicesActions.cancelInvoice),
      mergeMap((context) => {
        return from(
          this.invoiceLogic.cancelInvoice(
            context.record.organizationId,
            context.record.invoice
          )
        ).pipe(
          map((_) => {
            context.confirmation.resolve('');
            return invoicesActions.cancelInvoiceSuccess();
          }),
          catchError((e) => {
            context.confirmation.reject(e);
            return of(invoicesActions.cancelInvoiceFailure());
          })
        );
      })
    )
  );

  resetInvoice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invoicesActions.resetInvoice),
      mergeMap((context) => {
        return from(
          this.invoiceLogic.resetInvoice(
            context.record.organizationId,
            context.record.invoice,
            context.record.organizationSettings
          )
        ).pipe(
          map(() => {
            context.confirmation.resolve('');
            return invoicesActions.resetInvoiceSuccess();
          }),
          catchError((e) => {
            context.confirmation.reject(e);
            return of(invoicesActions.resetInvoiceFailure());
          })
        );
      })
    )
  );

  markInvoiceAsPaid$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invoicesActions.markInvoiceAsPaid),
      mergeMap((context) => {
        return from(
          this.invoiceLogic.markInvoiceAsPaid(
            context.record.organizationId,
            context.record.invoice
          )
        ).pipe(
          map((_) => {
            context.confirmation.resolve('');
            return invoicesActions.markInvoiceAsPaidSuccess();
          }),
          catchError((e) => {
            context.confirmation.reject(e);
            return of(invoicesActions.markInvoiceAsPaidFailure());
          })
        );
      })
    )
  );

  markInvoiceAsExported$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invoicesActions.markInvoiceAsExported),
      mergeMap((context) => {
        const now = Date.now();
        const updates = _.map(context.invoiceIds, (id) =>
          this.service.update(context.organizationId, id, {
            quickbooksExportTimestamp: now,
          })
        );
        return from(Promise.all(updates)).pipe(
          map((_) => {
            context.confirmation.resolve();
            return invoicesActions.markInvoiceAsExportedSuccess();
          }),
          catchError((e) => {
            context.confirmation.reject(e);
            return of(invoicesActions.markInvoiceAsExportedFailure());
          })
        );
      })
    )
  );
}
