<section>
  <div fxLayoutAlign="center center">
    <mat-card appearance="raised" class="cards">
      <mat-card-header>
        <mat-card-title> Select your organization </mat-card-title>
      </mat-card-header>
      <mat-divider></mat-divider>
      <form [formGroup]="form">
        <mat-form-field>
          <mat-select formControlName="select">
            <mat-option
              *ngFor="let organization of allUserOrganizations$ | async"
              [value]="organization"
            >
              {{ organization.organizationName }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <button
          [disabled]="form.invalid"
          mat-raised-button
          type="submit"
          (click)="onSubmit()"
        >
          Go
        </button>
      </form>
    </mat-card>
  </div>
</section>
