import { combineLatest, Observable, Subscription } from 'rxjs';

import { Injectable, OnDestroy } from '@angular/core';

import { filter, takeUntil, tap } from 'rxjs/operators';

import { Store } from '@ngrx/store';

import { AnalyticsFacade } from '@arrivage-analytics/api/analytics.facade';
import { VendorDeliveryFacade } from '@arrivage-distribution/vendor/api/vendor-delivery.facade';
import { FormatFacade } from '@arrivage-formats/api/format.facade';
import { VendorOfferFacade } from '@arrivage-offers/vendor/api/vendor-offers.facade';
import { OrganizationSetupFacade } from '@arrivage-organization-setup/api/organization-setup.facade';
import { RelationshipFacade } from '@arrivage-relationship/common/api/relationship.facade';
import { ContextFacade } from '@arrivage-store/api/context.facade';
import { selectUrl } from '@arrivage-store/routing/router-state.selectors';
import { State } from '@arrivage-store/state';
import { OrganizationAccountSetup } from '@arrivage/model/dist/src/model';

@Injectable({
  providedIn: 'root',
})
export class SellerAccountSetupService implements OnDestroy {
  private _sellerAccountSetup$: Observable<OrganizationAccountSetup>;

  private _profileStep: Subscription;
  private _sellerInventoryStep: Subscription;
  private _sellerPriceListStep: Subscription;
  private _sellerDeliveryStep: Subscription;
  private _sellerCustomerStep: Subscription;
  private _sellerOfferStep: Subscription;

  constructor(
    private store: Store<State>,
    private contextFacade: ContextFacade,
    private organizationSetupFacade: OrganizationSetupFacade,
    private formatFacade: FormatFacade,
    private offerFacade: VendorOfferFacade,
    private deliveryFacade: VendorDeliveryFacade,
    private relationshipFacade: RelationshipFacade,
    private analyticsFacade: AnalyticsFacade
  ) {
    this._sellerAccountSetup$ =
      this.organizationSetupFacade.getOrganizationSetup();

    // PROFILE STEP
    this._profileStep = combineLatest([
      this._sellerAccountSetup$,
      this.contextFacade.getOrganizationId(),
      this.store.select(selectUrl),
    ])
      .pipe(
        takeUntil(
          this._sellerAccountSetup$.pipe(
            filter((setup) => !!setup && setup.profileStep?.completed)
          )
        ),
        filter(([setup]) => !!setup && !setup.profileStep?.completed),
        filter(
          ([setup, orgId, routerUrl]) =>
            routerUrl === `/organization/${orgId}/edit`
        ),
        tap(() => {
          this.organizationSetupFacade.updateOrganizationSetup({
            profileStep: {
              completed: true,
              completionTime: new Date().getTime(),
            },
          });
          this.analyticsFacade.logSetupStepCompleted('profile_step_completed');
        })
      )
      .subscribe();

    // INVENTORY STEP
    this._sellerInventoryStep = combineLatest([
      this._sellerAccountSetup$,
      this.formatFacade.getAll(),
    ])
      .pipe(
        takeUntil(
          this._sellerAccountSetup$.pipe(
            filter((setup) => !!setup && setup.sellerInventoryStep?.completed)
          )
        ),
        filter(([setup]) => !!setup && !setup.sellerInventoryStep?.completed),
        tap(() => {
          this.formatFacade.loadAll();
        }),
        filter(([setup, formats]) => formats?.length > 0),
        tap(() => {
          this.organizationSetupFacade.updateOrganizationSetup({
            sellerInventoryStep: {
              completed: true,
              completionTime: new Date().getTime(),
            },
          });
          this.analyticsFacade.logSetupStepCompleted(
            'seller_inventory_step_completed'
          );
        })
      )
      .subscribe();

    // PRICE LIST STEP
    this._sellerPriceListStep = combineLatest([
      this._sellerAccountSetup$,
      this.offerFacade.getAll(),
    ])
      .pipe(
        takeUntil(
          this._sellerAccountSetup$.pipe(
            filter((setup) => !!setup && setup.sellerPriceListStep?.completed)
          )
        ),
        filter(([setup]) => !!setup && !setup.sellerPriceListStep?.completed),
        tap(() => {
          this.offerFacade.loadAll();
        }),
        filter(
          ([setup, offers]) =>
            offers?.length > 0 &&
            offers.some((o) => o.isActive && o.offerItems.length > 0)
        ),
        tap(() => {
          this.organizationSetupFacade.updateOrganizationSetup({
            sellerPriceListStep: {
              completed: true,
              completionTime: new Date().getTime(),
            },
          });
          this.analyticsFacade.logSetupStepCompleted(
            'seller_price_list_step_completed'
          );
        })
      )
      .subscribe();

    // DELIVERY STEP
    this._sellerDeliveryStep = combineLatest([
      this._sellerAccountSetup$,
      this.deliveryFacade.getAll(),
    ])
      .pipe(
        takeUntil(
          this._sellerAccountSetup$.pipe(
            filter((setup) => !!setup && setup.sellerDeliveryStep?.completed)
          )
        ),
        filter(([setup]) => !!setup && !setup.sellerDeliveryStep?.completed),
        tap(() => {
          this.deliveryFacade.loadAll();
        }),
        filter(([setup, deliveries]) => deliveries?.length > 0),
        tap(() => {
          this.organizationSetupFacade.updateOrganizationSetup({
            sellerDeliveryStep: {
              completed: true,
              completionTime: new Date().getTime(),
            },
          });
          this.analyticsFacade.logSetupStepCompleted(
            'seller_delivery_step_completed'
          );
        })
      )
      .subscribe();

    // CUSTOMER STEP
    this._sellerCustomerStep = combineLatest([
      this._sellerAccountSetup$,
      this.relationshipFacade.getAllCustomersAndHubs(),
      this.offerFacade.getAll(),
    ])
      .pipe(
        takeUntil(
          this._sellerAccountSetup$.pipe(
            filter((setup) => !!setup && setup.sellerCustomerStep?.completed)
          )
        ),
        filter(([setup]) => !!setup && !setup.sellerCustomerStep?.completed),
        tap(() => {
          this.relationshipFacade.loadRelationships();
          this.offerFacade.loadAll();
        }),
        filter(
          ([setup, customers, offers]) =>
            customers?.length > 0 &&
            offers?.length > 0 &&
            offers.some(
              (o) => o.visibleForRelations?.length > 0 && !!o.lastSentTime
            )
        ),
        tap(([setup, customers, offers]) => {
          this.organizationSetupFacade.updateOrganizationSetup({
            sellerCustomerStep: {
              completed: true,
              completionTime: new Date().getTime(),
            },
          });
          this.analyticsFacade.logSetupStepCompleted(
            'seller_customer_step_completed'
          );
        })
      )
      .subscribe();

    // OFFER STEP
    this._sellerOfferStep = combineLatest([
      this._sellerAccountSetup$,
      this.offerFacade.getAll(),
      this.deliveryFacade.getAll(),
    ])
      .pipe(
        takeUntil(
          this._sellerAccountSetup$.pipe(
            filter((setup) => !!setup && setup.sellerOfferStep?.completed)
          )
        ),
        filter(([setup]) => !!setup && !setup.sellerOfferStep?.completed),
        tap(() => {
          this.offerFacade.loadAll();
          this.deliveryFacade.loadAll();
        }),
        filter(
          ([setup, offers, deliveries]) =>
            offers?.length > 0 &&
            offers.some((o) => o.publicVisibility?.length > 0) &&
            deliveries.some((d) => d.deliveryZoneCodes?.length > 0)
        ),
        tap(([setup, offers, deliveries]) => {
          this.organizationSetupFacade.updateOrganizationSetup({
            sellerOfferStep: {
              completed: true,
              completionTime: new Date().getTime(),
            },
          });
          this.analyticsFacade.logSetupStepCompleted(
            'seller_offer_step_completed'
          );
        })
      )
      .subscribe();
  }

  get sellerAccountSetup$(): Observable<OrganizationAccountSetup> {
    return this._sellerAccountSetup$;
  }

  ngOnDestroy(): void {
    if (this._profileStep) {
      this._profileStep.unsubscribe();
    }
    if (this._sellerInventoryStep) {
      this._sellerInventoryStep.unsubscribe();
    }
    if (this._sellerPriceListStep) {
      this._sellerPriceListStep.unsubscribe();
    }
    if (this._sellerDeliveryStep) {
      this._sellerDeliveryStep.unsubscribe();
    }
    if (this._sellerCustomerStep) {
      this._sellerCustomerStep.unsubscribe();
    }
    if (this._sellerOfferStep) {
      this._sellerOfferStep.unsubscribe();
    }
  }

  isSetupCompleted(setup: OrganizationAccountSetup): boolean {
    return (
      setup?.profileStep?.completed &&
      setup?.sellerInventoryStep?.completed &&
      setup?.sellerPriceListStep?.completed &&
      setup?.sellerDeliveryStep?.completed &&
      setup?.sellerCustomerStep?.completed &&
      setup?.sellerOfferStep?.completed
    );
  }
}
