import { from, of } from 'rxjs';

import { Injectable } from '@angular/core';

import {
  catchError,
  exhaustMap,
  map,
  mergeMap,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { CustomerInvoiceService } from '@arrivage-invoices/customer/services/customer-invoice.service';
import { reportError } from '@arrivage-sentry/report-error';
import { SnackbarService } from '@arrivage-snackbar/snackbar.service';
import { getOrganization } from '@arrivage-store/context/context.selectors';

import { InvoiceApiService } from '../../common/api/invoice-api.service';
import { InvoiceLogicService } from '../../common/api/invoice-logic.service';
import { createBaseInvoiceEffects } from '../../common/store/generator';
import * as actions from './customer-invoices.actions';
import { CustomerInvoicesSelectors } from './customer-invoices.selectors';
import { State } from './customer-invoices.state';

@Injectable()
export class CustomerInvoicesEffects {
  query$;
  displayQueryFailure$;

  add$;
  update$;
  remove$;

  getActiveItem$;
  displayGetActiveItemFailure$;

  queryByDateRange$;
  queryByDateRangeGuard$;
  displayQueryByDateRangeFailure$;

  constructor(
    private actions$: Actions,
    private store: Store<State>,
    private service: CustomerInvoiceService,
    private invoiceLogic: InvoiceLogicService,
    private invoiceApi: InvoiceApiService,
    private snackbarService: SnackbarService
  ) {
    ({
      query: this.query$,
      displayQueryFailure: this.displayQueryFailure$,

      add: this.add$,
      update: this.update$,
      remove: this.remove$,

      getActiveItem: this.getActiveItem$,
      displayGetActiveItemFailure: this.displayGetActiveItemFailure$,

      queryByDateRange: this.queryByDateRange$,
      queryByDateRangeGuard: this.queryByDateRangeGuard$,
      displayQueryByDateRangeFailure: this.displayQueryByDateRangeFailure$,
    } = createBaseInvoiceEffects(
      this.actions$,
      this.store,
      actions.CustomerInvoicesActions,
      CustomerInvoicesSelectors,
      this.service,
      this.snackbarService
    ));
  }

  paymentEmitted$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.paymentEmitted),
      withLatestFrom(this.store.select(getOrganization)),
      mergeMap(([context, organization]) => {
        return from(
          this.invoiceLogic.markAsPaymentEmitted(
            organization.id,
            context.invoice
          )
        ).pipe(
          map(() => {
            context.confirmation.resolve('');
            return actions.paymentEmittedSuccess();
          }),
          catchError((e) => {
            reportError(e);
            context.confirmation.reject(e);
            return of(actions.paymentEmittedFailure({ error: e }));
          })
        );
      })
    )
  );

  getRelatedInvoices$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.getRelatedInvoices),
      switchMap((context) => {
        return from(
          this.invoiceLogic.getRelatedInvoices(context.purchaseOrderIds)
        ).pipe(
          map((invoices) => {
            return actions.getRelatedInvoicesSuccess({
              relatedInvoicesResponse: invoices,
            });
          }),
          catchError((e) => {
            reportError(e);
            return of(
              actions.getRelatedInvoicesFailure({
                error: e,
              })
            );
          })
        );
      })
    )
  );

  loadPaymentMethods$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.getPaymentMethods),
      exhaustMap((context) => {
        return from(this.invoiceApi.getPaymentMethods()).pipe(
          map((response) => {
            return actions.getPaymentMethodsSuccess({
              paymentMethodsResponse: response,
            });
          }),
          catchError((e) => {
            reportError(e);
            return of(
              actions.getPaymentMethodsFailure({
                error: e,
              })
            );
          })
        );
      })
    )
  );
}
