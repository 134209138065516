<div
  *ngIf="label"
  class="text-s"
  [class.primary]="type === 'primary'"
  [class.accent]="type === 'accent'"
  [class.warn]="type === 'warn'"
  [class.organic]="type === 'organic'"
  [class.hub]="type === 'hub'"
  [class.imported-item]="type === 'imported-item'"
  [class.lighter-yellow-color]="type === 'lighterYellowColor'"
  [class.light-blue-color]="type === 'lightBlueColor'"
  [class.light-yellow-color]="type === 'lightYellowColor'"
  [class.light-green]="type === 'lightGreen'"
  [class.grey]="type === 'grey'"
  [class.light-grey]="type === 'light-grey'"
  [class.small]="small"
  [class.large]="large"
  [class.status]="statusTag"
  [class.twoLinesLabel]="twoLinesLabel"
>
  <img
    *ngIf="avatar"
    matChipAvatar
    class="img-icon"
    [src]="avatar.pictureUrl ? avatar.pictureUrl : DEFAULT_PHOTO_URL"
  />
  <mat-icon
    *ngIf="icon"
    matChipAvatar
    class="img-icon material-icons-outlined"
    >{{ icon }}</mat-icon
  >
  <span [class.twoLinesLabel]="twoLinesLabel">{{ label }}</span>
  <ng-content></ng-content>
</div>
