import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { UserSettingsEffects } from './user-settings.effects';
import { reducer } from './user-settings.reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('userSettings', reducer),
    EffectsModule.forFeature([UserSettingsEffects]),
  ],
})
export class UserSettingsStoreModule {}
