<ng-container *transloco="let t; scope: 'products'; read: 'products'">
  <section
    fxLayout="column"
    fxLayoutAlign="space-between"
    [class.full-height]="data.isExtraSmallScreen"
  >
    <div class="mat-dialog-content space-top">
      <mat-card-content fxLayout="column" fxLayoutGap="2rem">
        <app-product-format-info
          [product]="data.product"
          [currentFormatId]="data.currentFormatId"
          [offerItems]="data.offerItems"
          [isExtraSmallScreen]="data.isExtraSmallScreen"
          [showPriceAndStock]="data.showPriceAndStock"
        ></app-product-format-info>
      </mat-card-content>
    </div>
    <mat-dialog-actions align="end">
      <button mat-stroked-button mat-dialog-close>
        {{ t('product-info-dialog.close') }}
      </button>
    </mat-dialog-actions>
  </section>
</ng-container>
