import { GeoPoint } from '@angular/fire/firestore';

export namespace GeoPointUtils {
  interface SerializedGeoPoint {
    _lat: number;
    _long: number;
  }

  export function toGeoPoint(
    location: GeoPoint | SerializedGeoPoint | null
  ): GeoPoint {
    if (!location) {
      return null;
    }

    if (location instanceof GeoPoint) {
      return location;
    }

    if (
      (<SerializedGeoPoint>location)?._lat !== undefined &&
      (<SerializedGeoPoint>location)?._long !== undefined
    ) {
      return new GeoPoint(
        (<SerializedGeoPoint>location)._lat,
        (<SerializedGeoPoint>location)._long
      );
    }

    throw new Error('Unsupported geoPoint type');
  }
}
