import { Observable } from 'rxjs';

import { Injectable } from '@angular/core';
import { Firestore, orderBy, where } from '@angular/fire/firestore';

import { map } from 'rxjs/operators';

import { BasePurchaseOrderService } from '@arrivage-purchase-orders/common/services/base-purchase-order.service';
import { BaseService, PathSpec } from '@arrivage-services/base.service';
import {
  PurchaseOrder,
  PurchaseOrderState,
  WithId,
} from '@arrivage/model/dist/src/model';

@Injectable({
  providedIn: 'root',
})
export class AdminPurchaseOrdersService extends BaseService<PurchaseOrder> {
  isLoaded = false;
  purchaseOrders$: Observable<(PurchaseOrder & WithId)[]>;

  constructor(firestore: Firestore) {
    super(firestore);
  }

  getAllOpenPurchaseOrder(): Observable<(PurchaseOrder & WithId)[]> {
    if (!this.isLoaded) {
      this.isLoaded = true;
      this.purchaseOrders$ = this._list(
        this.pathSpec(),
        BasePurchaseOrderService.convertFields,
        where('state', '==', PurchaseOrderState.OPEN),
        orderBy('sentOn', 'desc')
      );
    }
    return this.purchaseOrders$;
  }

  getPurchaseOrderById(purchaseId: string): Observable<PurchaseOrder & WithId> {
    return this.purchaseOrders$.pipe(
      map((po) => po.find((p) => p.id === purchaseId))
    );
  }

  private pathSpec(): PathSpec[] {
    return [
      {
        collection: BasePurchaseOrderService.PURCHASE_ORDERS_COLLECTION,
      },
    ];
  }
}
