import {
  differenceInCalendarDays,
  endOfDay,
  isAfter,
  isBefore,
  isFuture,
  isToday,
  isValid,
  min,
  startOfDay,
} from 'date-fns';
import _ from 'lodash';

import { createEntityAdapter } from '@ngrx/entity';
import { createSelector } from '@ngrx/store';

import { TransactionDocumentsMetrics } from '@arrivage-components/metric/metrics.model';
import { PickupUtils } from '@arrivage-distribution/vendor/utils/pickup.utils';
import { PurchaseOrderTotalPipe } from '@arrivage-pipes/purchase-order-total-pipe/purchase-order-total.pipe';
import {
  ScheduledDelivery,
  ScheduledDeliveryDashboardMetrics,
} from '@arrivage-scheduled-deliveries/common/model/scheduled-deliveries.model';
import { ScheduledDeliveriesUtils } from '@arrivage-scheduled-deliveries/common/utils/scheduled-deliveries.utils';
import {
  createSelectors as createEntitySelectors,
  FirestoreEntitySelectors,
} from '@arrivage-store/generators';
import { selectRouteParams } from '@arrivage-store/routing/router-state.selectors';
import { State } from '@arrivage-store/state';
import { DateUtils } from '@arrivage-util/date.utils';
import {
  LocalDate,
  Money,
  PurchaseOrder,
  PurchaseOrderState,
  PurchaseOrderStatus,
  WithId,
} from '@arrivage/model/dist/src/model';

import { PurchaseOrdersUtils } from '../util/purchase-orders.utils';
import {
  BasePurchaseOrderState,
  PurchaseOrdersByDateRangeLoadingCollectionState,
  PurchaseOrdersByDateType,
} from './base-purchase-orders.state';

export const purchaseOrderAdapter = createEntityAdapter<
  PurchaseOrder & WithId
>();

export interface PurchaseOrdersSelectors
  extends FirestoreEntitySelectors<PurchaseOrder, State> {
  selectByRelationshipId: (
    relationshipId: string
  ) => (state: State) => (PurchaseOrder & WithId)[];
  selectPendingPurchaseOrders: (state: State) => (PurchaseOrder & WithId)[];
  selectNotDeliveredPurchaseOrders: (
    state: State
  ) => (PurchaseOrder & WithId)[];
  selectDeliveredPurchaseOrders: (state: State) => (PurchaseOrder & WithId)[];
  selectConfirmedPurchaseOrders: (state: State) => (PurchaseOrder & WithId)[];
  selectNotDeliveredMetrics: (state: State) => TransactionDocumentsMetrics;
  selectUnpaidMetrics: (state: State) => TransactionDocumentsMetrics;
  selectSubmittedPurchaseOrders: (state: State) => (PurchaseOrder & WithId)[];
  selectSubmittedMetrics: (state: State) => TransactionDocumentsMetrics;
  selectSubmittedOrWaitingForApprovalPurchaseOrders: (
    state: State
  ) => (PurchaseOrder & WithId)[];
  selectScheduledDeliveryMetrics: (
    state: State
  ) => ScheduledDeliveryDashboardMetrics;

  selectDateRangeCoveredByScheduledDeliveriesToDeliver: (
    state: State
  ) => Interval;
  selectScheduledDeliveriesByDateRange: (state: State) => ScheduledDelivery[];
  selectPurchaseOrdersNotApprovedByDateRange: (
    state: State
  ) => (PurchaseOrder & WithId)[];
  selectScheduledDeliveriesToDeliver: (state: State) => ScheduledDelivery[];
  selectScheduledDeliveriesDelivered: (state: State) => ScheduledDelivery[];

  selectScheduledDelivery: (state: State) => ScheduledDelivery | null;

  selectCurrentDateRange: (state: State) => Interval;
  selectSubPurchaseOrdersCurrentDateRange: (state: State) => Interval;
  selectStoredDateRanges: (state: State) => Interval[];
  selectSubPurchaseOrdersStoredDateRanges: (state: State) => Interval[];
  selectItems: (state: State) => PurchaseOrdersByDateType;
  selectSubItems: (state: State) => PurchaseOrdersByDateType;
  selectLoadedBySentDateRange: (state: State) => (PurchaseOrder & WithId)[];
  selectSubPurchaseOrdersBySentDateRange: (
    state: State
  ) => (PurchaseOrder & WithId)[];
  selectAllPurchaseOrdersBySentDateRange: (
    state: State
  ) => (PurchaseOrder & WithId)[];
  selectLoadedByDeliveryOrPickupDateRange: (
    state: State
  ) => (PurchaseOrder & WithId)[];
  selectSubPurchaseOrdersByDeliveryOrPickupDateRange: (
    state: State
  ) => (PurchaseOrder & WithId)[];
  selectAllPurchaseOrdersByDeliveryOrPickupDateRange: (
    state: State
  ) => (PurchaseOrder & WithId)[];
  selectLoadedDeliveredBySentDateRange: (
    state: State
  ) => (PurchaseOrder & WithId)[];
  selectLoadedDeliveredByDeliveryOrPickupDateRange: (
    state: State
  ) => (PurchaseOrder & WithId)[];
  selectIsLoadingByDateRange: (state: State) => boolean;
  selectByDateRangeState: (
    state: State
  ) => PurchaseOrdersByDateRangeLoadingCollectionState;
  selectPurchaseOrdersNotApproved: (state: State) => (PurchaseOrder & WithId)[];
}

export function createSelectors(
  featureSelector: (state) => BasePurchaseOrderState
): PurchaseOrdersSelectors {
  const selectCurrentDateRange = createSelector(
    featureSelector,
    (s) => s.currentDateRange
  );
  const selectSubPurchaseOrdersCurrentDateRange = createSelector(
    featureSelector,
    (s) => s.subPurchaseOrdersCurrentDateRange
  );
  const selectStoredDateRanges = createSelector(
    featureSelector,
    (s) => s.storedDateRanges
  );
  const selectSubPurchaseOrdersStoredDateRanges = createSelector(
    featureSelector,
    (s) => s.subPurchaseOrdersStoredDateRanges
  );

  const entitySelectors = createEntitySelectors<PurchaseOrder, State>(
    purchaseOrderAdapter,
    featureSelector
  );

  const selectPendingPurchaseOrders = createSelector(
    entitySelectors.selectAll,
    (purchaseOrders) =>
      purchaseOrders.filter((po) => po.state === PurchaseOrderState.OPEN)
  );

  const selectNotDeliveredPurchaseOrders = createSelector(
    entitySelectors.selectAll,
    (purchaseOrders) =>
      purchaseOrders.filter(
        (po) =>
          (po.status === PurchaseOrderStatus.CONFIRMED ||
            po.status === PurchaseOrderStatus.COMPLETED) &&
          !po.delivered
      )
  );

  const selectUnpaidPurchaseOrders = createSelector(
    entitySelectors.selectAll,
    (purchaseOrders) =>
      purchaseOrders.filter(
        (po) =>
          (po.status === PurchaseOrderStatus.CONFIRMED ||
            po.status === PurchaseOrderStatus.COMPLETED) &&
          !po.invoicePaid
      )
  );

  const selectDeliveredPurchaseOrders = createSelector(
    entitySelectors.selectAll,
    (purchaseOrders) =>
      purchaseOrders.filter(
        (po) =>
          (po.status === PurchaseOrderStatus.CONFIRMED ||
            po.status === PurchaseOrderStatus.COMPLETED) &&
          po.delivered
      )
  );

  const selectConfirmedPurchaseOrders = createSelector(
    entitySelectors.selectAll,
    (purchaseOrders) =>
      purchaseOrders.filter((po) => po.status === PurchaseOrderStatus.CONFIRMED)
  );

  const selectSubmittedPurchaseOrders = createSelector(
    entitySelectors.selectAll,
    (purchaseOrders) =>
      purchaseOrders.filter((po) => po.status === PurchaseOrderStatus.SUBMITTED)
  );

  const selectSubmittedOrWaitingForApprovalPurchaseOrders = createSelector(
    entitySelectors.selectAll,
    (purchaseOrders) =>
      purchaseOrders.filter(
        (po) =>
          po.status === PurchaseOrderStatus.SUBMITTED ||
          po.status === PurchaseOrderStatus.MODIFIEDBYCUSTOMER
      )
  );

  const selectItems = createSelector(
    featureSelector,
    (state) => state.dateRangeLoadingCollectionState.items
  );

  const selectSubItems = createSelector(
    featureSelector,
    (state) => state.subPurchaseOrdersByDateRange.items
  );

  const selectItemsBySentDateRange = createSelector(
    selectItems,
    (items) => items.sent
  );

  const selectSubItemsBySentDateRange = createSelector(
    selectSubItems,
    (items) => items.sent
  );

  const selectItemsByDeliveryOrPickupDateRange = createSelector(
    selectItems,
    (items) => items.deliveryOrPickup
  );

  const selectSubItemsByDeliveryOrPickupDateRange = createSelector(
    selectSubItems,
    (items) => items.deliveryOrPickup
  );

  const selectLoadedByDeliveryOrPickupDateRange = createSelector(
    selectItemsByDeliveryOrPickupDateRange,
    selectCurrentDateRange,
    (items, dateRange) =>
      filterPurchaseOrdersByDeliveryOrPickupDateRange(items, dateRange)
  );

  const selectSubPurchaseOrdersByDeliveryOrPickupDateRange = createSelector(
    selectSubItemsByDeliveryOrPickupDateRange,
    selectSubPurchaseOrdersCurrentDateRange,
    (items, dateRange) =>
      filterPurchaseOrdersByDeliveryOrPickupDateRange(items, dateRange)
  );

  // purchase orders that have been confirmed, that are not waiting for approval or cancelled
  const selectPurchaseOrdersByDateRangeForScheduledDeliveries = createSelector(
    selectLoadedByDeliveryOrPickupDateRange,
    (purchaseOrders) => {
      return purchaseOrders.filter(
        (purchaseOrder) =>
          purchaseOrder.status === PurchaseOrderStatus.COMPLETED ||
          purchaseOrder.status === PurchaseOrderStatus.CONFIRMED
      );
    }
  );

  // purchase orders that have been submitted or modified
  const selectPurchaseOrdersNotApprovedByDateRange = createSelector(
    selectLoadedByDeliveryOrPickupDateRange,
    (purchaseOrders) => {
      return purchaseOrders.filter(
        (purchaseOrder) =>
          purchaseOrder.status === PurchaseOrderStatus.SUBMITTED ||
          purchaseOrder.status === PurchaseOrderStatus.MODIFIEDBYCUSTOMER ||
          purchaseOrder.status === PurchaseOrderStatus.MODIFIEDBYVENDOR
      );
    }
  );

  const selectScheduledDeliveriesByDateRange = createSelector(
    selectPurchaseOrdersByDateRangeForScheduledDeliveries,
    extractScheduledDeliveriesFromPurchaseOrders
  );

  // purchase orders that are delivered and confirmed/completed
  const selectLoadedDeliveredByDeliveryOrPickupDateRange = createSelector(
    selectItemsByDeliveryOrPickupDateRange,
    selectCurrentDateRange,
    (items, dateRange) =>
      filterDeliveredPurchaseOrdersByDeliveryOrPickupDateRange(items, dateRange)
  );

  const selectLoadedDeliveredBySentDateRange = createSelector(
    selectItemsBySentDateRange,
    selectCurrentDateRange,
    (items, dateRange) =>
      filterDeliveredPurchaseOrdersBySentDateRange(items, dateRange)
  );

  const selectLoadedBySentDateRange = createSelector(
    selectItemsBySentDateRange,
    selectCurrentDateRange,
    (items, dateRange) => filterPurchaseOrdersBySentDateRange(items, dateRange)
  );

  const selectSubPurchaseOrdersBySentDateRange = createSelector(
    selectSubItemsBySentDateRange,
    selectSubPurchaseOrdersCurrentDateRange,
    (items, dateRange) => filterPurchaseOrdersBySentDateRange(items, dateRange)
  );

  return {
    ...entitySelectors,
    selectItems,
    selectSubItems,
    selectLoadedBySentDateRange: selectLoadedBySentDateRange,
    selectSubPurchaseOrdersBySentDateRange:
      selectSubPurchaseOrdersBySentDateRange,
    selectAllPurchaseOrdersBySentDateRange: createSelector(
      selectLoadedBySentDateRange,
      selectSubPurchaseOrdersBySentDateRange,
      (items, subItems) => [...items, ...subItems]
    ),
    selectLoadedByDeliveryOrPickupDateRange,
    selectSubPurchaseOrdersByDeliveryOrPickupDateRange,
    selectAllPurchaseOrdersByDeliveryOrPickupDateRange: createSelector(
      selectLoadedByDeliveryOrPickupDateRange,
      selectSubPurchaseOrdersByDeliveryOrPickupDateRange,
      (items, subItems) => [...items, ...subItems]
    ),
    selectLoadedDeliveredBySentDateRange,
    selectLoadedDeliveredByDeliveryOrPickupDateRange,
    selectIsLoadingByDateRange: createSelector(
      featureSelector,
      (s) => s.dateRangeLoadingCollectionState.isLoading
    ),
    selectDeliveredPurchaseOrders: selectDeliveredPurchaseOrders,
    selectCurrentDateRange: selectCurrentDateRange,
    selectSubPurchaseOrdersCurrentDateRange:
      selectSubPurchaseOrdersCurrentDateRange,
    selectStoredDateRanges: selectStoredDateRanges,
    selectSubPurchaseOrdersStoredDateRanges:
      selectSubPurchaseOrdersStoredDateRanges,
    selectByDateRangeState: createSelector(
      featureSelector,
      (s) => s.dateRangeLoadingCollectionState
    ),
    selectByRelationshipId: (relationshipId: string) =>
      createSelector(
        entitySelectors.selectAll,
        (items: (PurchaseOrder & WithId)[]) =>
          items.filter((x) => x.relationshipId === relationshipId)
      ),
    selectPendingPurchaseOrders: selectPendingPurchaseOrders,
    selectNotDeliveredPurchaseOrders: selectNotDeliveredPurchaseOrders,
    selectConfirmedPurchaseOrders: selectConfirmedPurchaseOrders,
    selectSubmittedPurchaseOrders: selectSubmittedPurchaseOrders,
    selectUnpaidMetrics: createSelector(
      selectUnpaidPurchaseOrders,
      extractPurchaseOrdersMetrics
    ),
    selectNotDeliveredMetrics: createSelector(
      selectNotDeliveredPurchaseOrders,
      extractPurchaseOrdersMetrics
    ),
    selectSubmittedMetrics: createSelector(
      selectSubmittedPurchaseOrders,
      extractPurchaseOrdersMetrics
    ),
    selectScheduledDeliveryMetrics: createSelector(
      selectNotDeliveredPurchaseOrders,
      extractScheduledDeliveryMetrics
    ),
    selectSubmittedOrWaitingForApprovalPurchaseOrders:
      selectSubmittedOrWaitingForApprovalPurchaseOrders,

    selectDateRangeCoveredByScheduledDeliveriesToDeliver: createSelector(
      selectNotDeliveredPurchaseOrders,
      (purchaseOrders) => {
        return purchaseOrders
          .map(
            (purchaseOrder) =>
              purchaseOrder.deliveryDate ??
              PickupUtils.getPickupOptionDate(purchaseOrder.pickup, 'to')
          )
          .reduce<Interval>(
            (acc, date) => {
              if (!acc.start || isBefore(date, acc.start))
                acc.start = startOfDay(date);
              if (!acc.end || isAfter(date, acc.end)) acc.end = endOfDay(date);
              return acc;
            },
            {
              start: null,
              end: null,
            }
          );
      }
    ),
    selectScheduledDeliveriesByDateRange,
    selectPurchaseOrdersNotApprovedByDateRange,
    selectScheduledDeliveriesToDeliver: createSelector(
      selectScheduledDeliveriesByDateRange,
      (scheduledDeliveries) =>
        scheduledDeliveries.filter(
          (scheduledDelivery) =>
            !scheduledDelivery.areAllPurchaseOrdersBeenDelivered
        )
    ),
    selectScheduledDeliveriesDelivered: createSelector(
      selectScheduledDeliveriesByDateRange,
      (scheduledDeliveries) =>
        scheduledDeliveries.filter(
          (scheduledDelivery) =>
            scheduledDelivery.areAllPurchaseOrdersBeenDelivered
        )
    ),
    /**
     * TODO WEB-3154
     * If a pickup and a delivery have the same id, and there are
     * some POs scheduled for the same day for both, it could be
     * a problem. This selector will return the first one found.
     */
    selectScheduledDelivery: createSelector(
      selectScheduledDeliveriesByDateRange,
      selectRouteParams,
      (scheduledDeliveries, params) => {
        const id = params['id'];
        const date =
          ScheduledDeliveriesUtils.extractDateFromRouteParams(params);
        if (!date) return null;
        return scheduledDeliveries.find(
          (scheduledDelivery) =>
            (
              scheduledDelivery.deliveryDate ?? scheduledDelivery.pickupDate
            ).getTime() === date.getTime() &&
            (scheduledDelivery.deliveryId ?? scheduledDelivery.pickupId) === id
        );
      }
    ),

    selectPurchaseOrdersNotApproved: createSelector(
      selectSubmittedOrWaitingForApprovalPurchaseOrders,
      selectRouteParams,
      (purchaseOrders, params) => {
        const id = params['id'];
        const date =
          ScheduledDeliveriesUtils.extractDateFromRouteParams(params);
        if (!date) return [];
        return purchaseOrders.filter((purchaseOrder) => {
          return (
            (
              purchaseOrder.deliveryDate ??
              PickupUtils.getPickupOptionDate(purchaseOrder.pickup, 'to')
            ).getTime() === date.getTime() &&
            (purchaseOrder.delivery?.id ?? purchaseOrder.pickup.id) === id
          );
        });
      }
    ),
  };
}

export function extractScheduledDeliveryMetrics(
  items: (PurchaseOrder & WithId)[]
): ScheduledDeliveryDashboardMetrics {
  const today = new Date(Date.now());
  const nbTotalDeliveries = items.length;
  const nbNextDeliveries = items.filter(
    (x) =>
      (isFuture(x.deliveryDate) || isToday(x.deliveryDate)) &&
      differenceInCalendarDays(x.deliveryDate, today) < 3
  ).length;
  const nbLateDeliveries = items.filter((x) =>
    PurchaseOrdersUtils.isLate(x)
  ).length;
  const nextDeliveryDate = min(
    items
      .filter((x) => isFuture(x.deliveryDate) || isToday(x.deliveryDate))
      .map((x) => x.deliveryDate)
  );
  if (!isValid(nextDeliveryDate)) {
    return {
      nbTotalDeliveries: nbTotalDeliveries,
      nbNextDeliveries: nbNextDeliveries,
      nbLateDeliveries: nbLateDeliveries,
      nextDeliveryDate: null,
    };
  }

  return {
    nbTotalDeliveries: nbTotalDeliveries,
    nbNextDeliveries: nbNextDeliveries,
    nbLateDeliveries: nbLateDeliveries,
    nextDeliveryDate: nextDeliveryDate,
  };
}

export function extractPurchaseOrdersMetrics(items: PurchaseOrder[]) {
  const totalDraft = Money.sum(
    _.map(items, (i) => new PurchaseOrderTotalPipe().transform(i))
  );

  return {
    nb: items.length,
    total: totalDraft,
  };
}

export function filterPurchaseOrdersByDateRange(
  purchaseOrders: (PurchaseOrder & WithId)[],
  dateRange: Interval,
  getDate: (purchaseOrder: PurchaseOrder) => Date
) {
  return purchaseOrders.filter((purchaseOrder) => {
    const date = getDate(purchaseOrder);
    return DateUtils.isDateInDateRange(date, dateRange);
  });
}

export function filterDeliveredPurchaseOrdersByDateRange(
  purchaseOrders: (PurchaseOrder & WithId)[],
  dateRange: Interval,
  getDate: (purchaseOrder: PurchaseOrder) => Date
) {
  return purchaseOrders.filter((purchaseOrder) => {
    const date = getDate(purchaseOrder);
    return (
      DateUtils.isDateInDateRange(date, dateRange) &&
      PurchaseOrdersUtils.isDeliveredAndConfirmedOrCompleted(purchaseOrder)
    );
  });
}

export function filterPurchaseOrdersBySentDateRange(
  purchaseOrders: (PurchaseOrder & WithId)[],
  dateRange: Interval
) {
  return filterPurchaseOrdersByDateRange(
    purchaseOrders,
    dateRange,
    (purchaseOrder) => purchaseOrder.sentOn
  );
}

export function filterPurchaseOrdersByDeliveryDateRange(
  purchaseOrders: (PurchaseOrder & WithId)[],
  dateRange: Interval
) {
  return filterPurchaseOrdersByDateRange(
    purchaseOrders,
    dateRange,
    (purchaseOrder) => purchaseOrder.deliveryDate
  );
}

export function filterPurchaseOrdersByPickupDateRange(
  purchaseOrders: (PurchaseOrder & WithId)[],
  dateRange: Interval
) {
  return filterPurchaseOrdersByDateRange(
    purchaseOrders,
    dateRange,
    (purchaseOrder) =>
      purchaseOrder.pickup?.selectedDay
        ? LocalDate.toDate(purchaseOrder.pickup?.selectedDay)
        : null
  );
}

export function filterPurchaseOrdersByDeliveryOrPickupDateRange(
  purchaseOrders: (PurchaseOrder & WithId)[],
  dateRange: Interval
) {
  return _.uniqBy(
    [
      ...filterPurchaseOrdersByDeliveryDateRange(purchaseOrders, dateRange),
      ...filterPurchaseOrdersByPickupDateRange(purchaseOrders, dateRange),
    ],
    (purchaseOrder) => purchaseOrder.id
  );
}

/**
 * Group all the POs by delivery date and delivery id, and pickup date and pickup id
 * to create a list of scheduled deliveries.
 * @param {(PurchaseOrder & WithId)[]} purchaseOrders
 * @returns {ScheduledDelivery[]}
 */
export function extractScheduledDeliveriesFromPurchaseOrders(
  purchaseOrders: (PurchaseOrder & WithId)[]
): ScheduledDelivery[] {
  // Filter out purchase orders without delivery
  const purchaseOrdersWithDelivery = purchaseOrders.filter(
    (purchaseOrder) => !!purchaseOrder.delivery && !!purchaseOrder.deliveryDate
  );

  // Group purchase orders by delivery date and delivery id
  const purchaseOrdersByDeliveryDateAndDeliveryId = _(
    purchaseOrdersWithDelivery
  ).groupBy(
    (purchaseOrder) =>
      purchaseOrder.delivery.id + purchaseOrder.deliveryDate.toISOString()
  );

  // Group purchase orders as scheduled deliveries
  const scheduledDeliveries = purchaseOrdersByDeliveryDateAndDeliveryId
    .mapValues((purchaseOrders) => {
      const deliveryId = purchaseOrders[0].delivery.id;
      const deliveryDate = purchaseOrders[0].deliveryDate;
      const areAllPurchaseOrdersBeenDelivered = _.every(
        purchaseOrders,
        (purchaseOrder) => !!purchaseOrder.delivered
      );
      return {
        deliveryId,
        deliveryDate,
        areAllPurchaseOrdersBeenDelivered,
        purchaseOrders,
      };
    })
    .values()
    .value();

  // Filter out purchase orders without pickup
  const purchaseOrdersWithPickup = purchaseOrders.filter(
    (purchaseOrder) => !!purchaseOrder.pickup
  );

  // Group purchase orders by pickup option
  const purchaseOrdersByPickupOptionAndPickupId = _(
    purchaseOrdersWithPickup
  ).groupBy(
    (purchaseOrder) =>
      purchaseOrder.pickup.id +
      PickupUtils.getPickupOptionString(purchaseOrder.pickup)
  );

  // Group purchase orders as scheduled deliveries
  const scheduledPickups = purchaseOrdersByPickupOptionAndPickupId
    .mapValues((purchaseOrders) => {
      const pickupId = purchaseOrders[0].pickup.id;
      const pickupDate = PickupUtils.getPickupOptionDate(
        purchaseOrders[0].pickup,
        'to'
      );
      const areAllPurchaseOrdersBeenDelivered = _.every(
        purchaseOrders,
        (purchaseOrder) => !!purchaseOrder.delivered
      );
      return {
        pickupId,
        pickupDate,
        areAllPurchaseOrdersBeenDelivered,
        purchaseOrders,
      };
    })
    .values()
    .value();

  // Return scheduled deliveries and pickups
  return [...scheduledDeliveries, ...scheduledPickups];
}

// delivered and confirmed/completed purchase orders

export function filterDeliveredPurchaseOrdersBySentDateRange(
  purchaseOrders: (PurchaseOrder & WithId)[],
  dateRange: Interval
) {
  return filterDeliveredPurchaseOrdersByDateRange(
    purchaseOrders,
    dateRange,
    (purchaseOrder) => purchaseOrder.sentOn
  );
}

export function filterDeliveredPurchaseOrdersByDeliveryDateRange(
  purchaseOrders: (PurchaseOrder & WithId)[],
  dateRange: Interval
) {
  return filterDeliveredPurchaseOrdersByDateRange(
    purchaseOrders,
    dateRange,
    (purchaseOrder) => purchaseOrder.deliveryDate
  );
}

export function filterDeliveredPurchaseOrdersByPickupDateRange(
  purchaseOrders: (PurchaseOrder & WithId)[],
  dateRange: Interval
) {
  return filterDeliveredPurchaseOrdersByDateRange(
    purchaseOrders,
    dateRange,
    (purchaseOrder) =>
      purchaseOrder.pickup?.selectedDay
        ? LocalDate.toDate(purchaseOrder.pickup?.selectedDay)
        : null
  );
}

export function filterDeliveredPurchaseOrdersByDeliveryOrPickupDateRange(
  purchaseOrders: (PurchaseOrder & WithId)[],
  dateRange: Interval
) {
  return _.uniqBy(
    [
      ...filterDeliveredPurchaseOrdersByDeliveryDateRange(
        purchaseOrders,
        dateRange
      ),
      ...filterDeliveredPurchaseOrdersByPickupDateRange(
        purchaseOrders,
        dateRange
      ),
    ],
    (purchaseOrder) => purchaseOrder.id
  );
}
