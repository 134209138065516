import { Observable, Subscription } from 'rxjs';

import { Injectable, OnDestroy } from '@angular/core';

import { filter, pairwise, withLatestFrom } from 'rxjs/operators';

import { Store } from '@ngrx/store';

import { State } from '@arrivage-store/state';

import { AuthUser } from '../model/auth-data.model';
import { Logout } from '../store/auth.actions';
import { getAuthUser, hasTriggeredLogout } from '../store/auth.selectors';

@Injectable({
  providedIn: 'root',
})
export class AuthFacade implements OnDestroy {
  private authUser$: Observable<AuthUser>;

  private subscription: Subscription;

  constructor(protected store: Store<State>) {
    this.authUser$ = this.store.select(getAuthUser);
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  monitorForcedLogout() {
    this.subscription = this.authUser$
      .pipe(
        pairwise(),
        withLatestFrom(this.store.select(hasTriggeredLogout)),
        filter(([[before, after], triggeredLogout]) => {
          // authUser existed, now it is undefined and the user did not logout explicitely
          return !!before && !after && !triggeredLogout;
        })
      )
      .subscribe(() => {
        console.log('Forced logout');
        this.store.dispatch(Logout({}));
      });
  }

  getAuthUser(): Observable<AuthUser> {
    return this.authUser$;
  }
}
