import { from } from 'rxjs';

import { Injectable } from '@angular/core';
import { Functions, httpsCallable } from '@angular/fire/functions';
import { getDownloadURL, ref, Storage } from '@angular/fire/storage';

import { map, retryWhen, take } from 'rxjs/operators';

import { genericRetryStrategy } from '@arrivage-util/api.utils';
import {
  createCreateInvoicePdfRequestData,
  CreateInvoiceFromPurchaseOrderRequest,
  CreateInvoicePdfRequestData,
  CreateInvoicePdfResponseData,
  DeleteInvoiceRequestData,
  InvoicePdfMode,
  SendInvoiceRequestData,
  SendInvoiceResponseData,
} from '@arrivage/model/dist/src/cloud-functions-api';
import { Invoice, Organization, WithId } from '@arrivage/model/dist/src/model';

@Injectable({
  providedIn: 'root',
})
export class InvoiceApiService {
  private readonly createPdfCloudFunction = httpsCallable<
    CreateInvoicePdfRequestData,
    CreateInvoicePdfResponseData
  >(this.functions, 'generateInvoicePdf');
  private readonly createMultiplePdfCloudFunction = httpsCallable<
    string[],
    CreateInvoicePdfResponseData
  >(this.functions, 'generateMultipleInvoicesPdf', { timeout: 310000 });
  private readonly sendInvoiceCloudFunction = httpsCallable<
    SendInvoiceRequestData,
    SendInvoiceResponseData
  >(this.functions, 'sendInvoiceEmail');
  private readonly deleteInvoiceCloudFunction = httpsCallable<
    DeleteInvoiceRequestData,
    void
  >(this.functions, 'deleteInvoice');
  private readonly createInvoiceFromPurchaseOrderCloudFunction = httpsCallable<
    CreateInvoiceFromPurchaseOrderRequest,
    string
  >(this.functions, 'createInvoiceFromPurchaseOrder');

  constructor(private functions: Functions, private storage: Storage) {}

  deleteInvoice(data: DeleteInvoiceRequestData): Promise<void> {
    return from(
      this.deleteInvoiceCloudFunction({
        organizationId: data.organizationId,
        invoiceId: data.invoiceId,
      })
    )
      .pipe(
        retryWhen(genericRetryStrategy()),
        take(1),
        map((r) => r.data)
      )
      .toPromise();
  }

  generateInvoice(invoiceId: string): Promise<string> {
    const data = createCreateInvoicePdfRequestData(
      invoiceId,
      InvoicePdfMode.FINAL
    );
    return this.generatePdf(data);
  }

  generateMultipleInvoices(invoicesId: string[]): Promise<string> {
    return from(this.createMultiplePdfCloudFunction(invoicesId))
      .pipe(retryWhen(genericRetryStrategy()), take(1))
      .toPromise()
      .then((response) => {
        const invoiceRef = ref(this.storage, response.data.invoiceUrl);
        return getDownloadURL(invoiceRef);
      });
  }

  generateDraftInvoice(invoiceId: string): Promise<string> {
    const data = createCreateInvoicePdfRequestData(
      invoiceId,
      InvoicePdfMode.DRAFT
    );
    return this.generatePdf(data);
  }

  async sendInvoice(
    organization: Organization & WithId,
    invoice: Invoice & WithId,
    messageToCustomers?: string
  ): Promise<string> {
    if (!invoice.pdf || !invoice.pdf.link) {
      return Promise.reject('Invoice PDF link not yet generated.');
    }
    return from(
      this.sendInvoiceCloudFunction({
        invoiceId: invoice.id,
        invoiceNumber: invoice.number,
        organizationName: organization.name,
        invoiceUrl: invoice.pdf.link,
        vendorOrganizationId: invoice.vendor.organizationId,
        customerOrganizationId: invoice.customer.organizationId || null,
        organizationLogo: organization.logoUrl || null,
        messageToCustomers: messageToCustomers ?? '',
      })
    )
      .pipe(retryWhen(genericRetryStrategy()), take(1))
      .toPromise()
      .then((data) => data.data.statusMessage);
  }

  async createInvoiceFromPurchaseOrder(purchaseOrderId): Promise<string> {
    return from(
      this.createInvoiceFromPurchaseOrderCloudFunction({
        purchaseOrderId: purchaseOrderId,
      })
    )
      .pipe(retryWhen(genericRetryStrategy()), take(1))
      .toPromise()
      .then((d) => d.data);
  }

  makePayment(invoice: Invoice, paymentMethod: any): Promise<any> {
    throw new Error('Not supported yet');
  }

  getPaymentMethods(): Promise<any> {
    throw new Error('Not supported yet');
  }

  // should be in another api service
  createAccountHolder(organization: Organization & WithId): Promise<any> {
    throw new Error('Not supported yet');
  }

  canOrganizationAcceptOnlinePayment(organizationId: string): Promise<boolean> {
    throw new Error('Not supported yet');
  }

  private async generatePdf(
    data: CreateInvoicePdfRequestData
  ): Promise<string> {
    return from(this.createPdfCloudFunction(data))
      .pipe(retryWhen(genericRetryStrategy()), take(1))
      .toPromise()
      .then((response) => {
        const invoiceRef = ref(this.storage, response.data.invoiceUrl);
        return getDownloadURL(invoiceRef);
      });
  }
}
