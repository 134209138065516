import { MaterialModule } from 'src/app/material.module';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';

import { TranslocoModule } from '@jsverse/transloco';

import { LabelModule } from '@arrivage-components/label/label.module';

import { PurchaseOrderStatusChipComponent } from './purchase-order-status-chip.component';

@NgModule({
  declarations: [PurchaseOrderStatusChipComponent],
  imports: [
    CommonModule,
    MaterialModule,
    FlexLayoutModule,
    TranslocoModule,
    LabelModule,
  ],
  exports: [PurchaseOrderStatusChipComponent],
})
export class PurchaseOrderStatusChipModule {}
