import { generateActions } from '@arrivage-store/generators';
import { OrganizationSettings } from '@arrivage/model/dist/src/model';

export const {
  query,
  connected,
  add,
  added,
  set,
  update,
  modified,
  remove,
  removed,
  queryFailure,
  addSuccess,
  addFailure,
  setSuccess,
  setFailure,
  updateSuccess,
  updateFailure,
  removeSuccess,
  removeFailure,
  getActiveItem,
  getActiveItemFailure,
  getActiveItemSuccess,
} = generateActions<OrganizationSettings>('OrganizationSettings');
