import { createReducer, Action, on } from '@ngrx/store';

import * as CreditAccountActions from './credit-account.actions';
import { CreditAccountState } from './credit-account.state';

export const initialState: CreditAccountState = {
  loaded: false,
  creditAccount: undefined,
  error: undefined,
};

const creditAccountReducer = createReducer(
  initialState,
  on(CreditAccountActions.query, (state) => {
    return {
      ...state,
      loaded: false,
      creditAccount: undefined,
      error: undefined,
    };
  }),
  on(CreditAccountActions.querySuccess, (state, { creditAccount }) => {
    return {
      ...state,
      loaded: true,
      creditAccount: creditAccount,
      error: undefined,
    };
  }),
  on(CreditAccountActions.queryFailure, (state, { errorMessage }) => {
    return {
      ...state,
      loaded: false,
      creditAccount: undefined,
      error: errorMessage,
    };
  })
);

export function reducer(state: CreditAccountState | undefined, action: Action) {
  return creditAccountReducer(state, action);
}
