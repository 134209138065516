import { filter, map } from 'rxjs';

import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from '@angular/router';

import { ContextFacade } from '@arrivage-store/api/context.facade';

@Injectable({ providedIn: 'root' })
export class HubGuard {
  constructor(
    private contextFacade: ContextFacade,
    private router: Router
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.contextFacade.getOrganization().pipe(
      filter((organization) => !!organization),
      map((organization) => organization.isHub || this.router.parseUrl('/'))
    );
  }
}
