import { EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { createReducer, Action } from '@ngrx/store';

import {
  createEntityReducer,
  createInitialState,
} from '@arrivage-store/generators';
import { InventoryItem, WithId } from '@arrivage/model/dist/src/model';

import * as InventoryActions from './inventory.actions';
import { InventoryState } from './inventory.state';

export const adapter: EntityAdapter<
  InventoryItem & WithId
> = createEntityAdapter<InventoryItem & WithId>();

export const initialState: InventoryState = createInitialState(adapter);

const inventoryReducer = createReducer(
  initialState,
  ...createEntityReducer<InventoryItem>(InventoryActions, adapter)
);

export function reducer(state: InventoryState | undefined, action: Action) {
  return inventoryReducer(state, action);
}
