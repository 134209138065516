import * as _ from 'lodash';
import { combineLatest, Observable } from 'rxjs';

import { Component, OnInit } from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  UntypedFormControl,
  Validators,
} from '@angular/forms';

import { switchMap, map } from 'rxjs/operators';

import { Store } from '@ngrx/store';

import { LoadSelectedOrganization } from '@arrivage-store/context/context.actions';
import * as fromContext from '@arrivage-store/context/context.selectors';
import { State } from '@arrivage-store/state';
import { Member, WithId } from '@arrivage/model/dist/src/model';

import { OrganizationService } from '../services/organization.service';

@Component({
  selector: 'app-organization-selection',
  templateUrl: './organization-selection.component.html',
  styleUrls: ['./organization-selection.component.scss'],
})
export class OrganizationSelectionComponent implements OnInit {
  allUserOrganizations$: Observable<
    {
      membership: Member & WithId;
      organizationId: string;
      organizationName: string;
    }[]
  >;

  form: UntypedFormGroup;

  constructor(
    private store: Store<State>,
    private formBuilder: UntypedFormBuilder,
    private organizationService: OrganizationService
  ) {}

  ngOnInit() {
    this.form = this.formBuilder.group({
      select: new UntypedFormControl('', {
        validators: [Validators.required],
      }),
    });

    this.allUserOrganizations$ = this.store
      .select(fromContext.getAllUserMemberships)
      .pipe(
        switchMap((memberships) => {
          const allOrganizations = _.map(memberships, (membership) =>
            this.organizationService
              .getOrganization(membership.organizationId)
              .pipe(
                map((org) => {
                  return {
                    membership: membership,
                    organizationId: org.id,
                    organizationName: org.name,
                  };
                })
              )
          );
          return combineLatest(allOrganizations);
        })
      );
  }

  onSubmit() {
    const membership = this.form.get('select').value.membership;
    this.store.dispatch(LoadSelectedOrganization({ membership: membership }));
  }
}
