import { Injectable } from '@angular/core';

import { map } from 'rxjs/operators';

import { LanguageService } from '@arrivage-language/service/language.service';
import { getMonth } from 'date-fns';

@Injectable({
  providedIn: 'root',
})
export class LogoService {
  arrivageWhiteLogoURL$ = this.languageService.languageChanges$.pipe(
    map(() => { return this.getSeasonalLogo() })
  );

  arrivageBlackLogoURL$ = this.languageService.languageChanges$.pipe(
    map(() => 'assets/images/arrivage-logo-black.png')
  );

  constructor(private languageService: LanguageService) { }

  getSeasonalLogo(): string {
    switch (getMonth(new Date()) + 1) {
      case 4:
        return 'assets/images/arrivage-easter.png'
      case 10:
        return 'assets/images/arrivage-halloween.png'
      case 12:
        return 'assets/images/arrivage-christmas.png'
      default:
        return 'assets/images/arrivage-logo-white.png'
    }
  }
}
