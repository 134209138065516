import { from, of } from 'rxjs';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { map, catchError, switchMap, tap, exhaustMap } from 'rxjs/operators';

import { Actions, ofType, createEffect } from '@ngrx/effects';

import { reportError } from '@arrivage-sentry/report-error';
import { LocalStorageService } from '@arrivage-services/local-storage.service';
import { showFeedback } from '@arrivage-store/feedback/feedback.actions';

import { AuthService } from '../services/auth.service';
import * as actions from './auth.actions';

interface AuthFeedback {
  request_password: string;
  login: string;
  signup: string;
  signin: string;
  logout: string;
}

export const AuthFeedback: AuthFeedback = {
  request_password: 'request_password',
  // ERROR ONLY:
  login: 'login',
  signup: 'signup',
  signin: 'signIn',
  logout: 'logout',
};
@Injectable({
  providedIn: 'root',
})
export class AuthEffects {
  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private localStoreService: LocalStorageService,
    private router: Router
  ) {}

  login$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.Login),
      switchMap((login) => {
        return from(
          this.authService.login({
            email: login.username,
            password: login.password,
          })
        ).pipe(
          map((user) => actions.SignInSuccess({ user: user })),
          catchError((error) => {
            return of(actions.LoginFailure({ errorCode: error.code }));
          })
        );
      })
    )
  );

  signup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.Signup),
      switchMap((signup) => {
        return from(
          this.authService.registerUser({
            email: signup.username,
            password: signup.password,
          })
        ).pipe(
          map((user) => actions.SignInSuccess({ user: user })),
          catchError((error) => {
            return of(actions.SignupFailure({ errorCode: error.code }));
          })
        );
      })
    )
  );

  googleSignIn$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.GoogleSignIn),
      switchMap(() =>
        from(this.authService.googleSignIn()).pipe(
          map((user) => actions.SignInSuccess({ user: user })),
          catchError((error) => {
            return of(actions.GoogleSignInFailure({ errorCode: error.code }));
          })
        )
      )
    )
  );

  requestPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.RequestPassword),
      switchMap((data) =>
        from(this.authService.requestPassword(data.email)).pipe(
          map((user) =>
            showFeedback({
              success: true,
              feedback: AuthFeedback.request_password,
            })
          ),
          catchError((error) =>
            of(
              actions.RequestPasswordFailure(),
              showFeedback({
                success: false,
                feedback: AuthFeedback.request_password,
              })
            )
          )
        )
      )
    )
  );

  logout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.Logout),
      exhaustMap((logout) => {
        this.localStoreService.clearContextDataFromLocalStorage();
        return from(this.authService.logout()).pipe(
          map((user) => {
            if (logout.confirmation) {
              logout.confirmation.resolve();
            }
            return actions.LogoutSuccess();
          }),
          catchError((e) => {
            reportError(e);
            if (logout.confirmation) {
              logout.confirmation.reject(e);
            }
            return of(actions.LogoutFailure());
          })
        );
      })
    )
  );

  onLogout$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actions.LogoutSuccess),
        tap(() => {
          // navigate to / to clear the initialUrl (see WEB-578)
          this.router.navigate(['/']).then(() => window.location.reload());
        })
      ),
    { dispatch: false }
  );
}
