import { enUS, frCA } from 'date-fns/locale';
import _ from 'lodash';
import { Observable } from 'rxjs';

import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { DateAdapter } from '@angular/material/core';

import { map } from 'rxjs/operators';

import { TranslocoService } from '@jsverse/transloco';

import {
  Language,
  Translatable,
  Translations,
} from '@arrivage/model/dist/src/model';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  private readonly LOCAL_STORAGE_KEY = 'language';
  private readonly regionDesignator = 'CA';

  localeChanges$: Observable<string>;
  languageChanges$: Observable<Language>;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private translocoService: TranslocoService,
    private dateAdaptor: DateAdapter<any>
  ) {
    this.localeChanges$ = this.translocoService.langChanges$;
    this.languageChanges$ = this.localeChanges$.pipe(
      map((locale) => this.localeToLanguage(locale))
    );
  }

  initFromLocalStorage() {
    const language = localStorage.getItem(this.LOCAL_STORAGE_KEY);
    if (language) {
      this.changeLanguage(this.toLanguage(language));
    } else {
      // default to FR
      this.changeLanguage(Language.FR);
    }
  }

  saveInLocalStorage() {
    localStorage.setItem(this.LOCAL_STORAGE_KEY, this.getCurrentLanguage());
  }

  setLanguage(language: string) {
    this.changeLanguage(this.toLanguage(language));
  }

  changeLanguage(language: Language) {
    this.setDocumentLanguage(language);
    const locale = language + '-' + this.regionDesignator;
    this.translocoService.setActiveLang(language + '-' + this.regionDesignator);
    this.dateAdaptor.setLocale(locale);
    this.saveInLocalStorage();
  }

  getAllLanguages(): Language[] {
    return _.map(this.translocoService.getAvailableLangs() as string[], (l) =>
      this.localeToLanguage(l)
    );
  }

  getCurrentLanguage(): Language {
    return this.localeToLanguage(this.translocoService.getActiveLang());
  }

  getCurrentLocale(): string {
    return this.translocoService.getActiveLang();
  }

  getCurrentLocaleDateFns(): Locale {
    const lang = this.getCurrentLanguage();
    switch (lang) {
      case Language.FR:
        return frCA;
      default:
        return enUS;
    }
  }

  getText(translatable: Translatable): string {
    return Translations.getText(translatable, this.getCurrentLanguage());
  }

  fromText(text: string, translations?: Translations): Translatable {
    return Translations.fromText(text, translations);
  }

  private setDocumentLanguage(lang: Language) {
    this.document.documentElement.lang = lang;
  }

  private localeToLanguage(locale: string): Language {
    const [lang, _region] = locale.split('-', 2);
    return this.toLanguage(lang);
  }

  private toLanguage(language: string): Language {
    switch (language) {
      case 'fr':
        return Language.FR;
      case 'es':
        return Language.ES;
      case 'en':
      default:
        return Language.EN;
    }
  }
}
