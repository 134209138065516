import { createAction, props } from '@ngrx/store';

import { Organization, WithId } from '@arrivage/model/dist/src/model';

export const logEvent = createAction(
  `[Analytics] Log Event`,
  props<{ eventName: string; params?: { [key: string]: any } }>()
);

export const setOrganizationProperties = createAction(
  `[Analytics] Set Organization Properties`,
  props<{ organization: Organization & WithId }>()
);
