import _ from 'lodash';

import { createEntityAdapter } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import {
  Offer,
  Organization,
  OrganizationAuthorizations,
  PremiumAccount,
  WithId,
} from '@arrivage/model/dist/src/model';

import {
  queryAuthorizations,
  queryAuthorizationsFailure,
  queryAuthorizationsSuccess,
  queryOffers,
  queryOffersFailure,
  queryOffersSuccess,
  queryOrganizations,
  queryOrganizationsFailure,
  queryOrganizationsSuccess,
  queryPremiumAccounts,
  queryPremiumAccountsFailure,
  queryPremiumAccountsSuccess,
} from './admin.actions';
import {
  AdminOffersState,
  AdminOrganizationAuthorizationsState,
  AdminOrganizationState,
  AdminPremiumAccountsState,
} from './admin.state';

// ORGANIZATIONS REDUCER

export const organizationsAdapter = createEntityAdapter<
  Organization & WithId
>();

export const initialOrganizationsState: AdminOrganizationState =
  organizationsAdapter.getInitialState({
    loaded: false,
    error: undefined,
  });

const organizationsReducer = createReducer(
  initialOrganizationsState,
  on(queryOrganizations, (state) => {
    if (state.loaded) {
      return state;
    }
    return {
      ...organizationsAdapter.removeAll(state),
      loaded: false,
      error: undefined,
    };
  }),
  on(queryOrganizationsSuccess, (state, { organizations }) => {
    return {
      ...organizationsAdapter.setAll(organizations, state),
      loaded: true,
      error: undefined,
    };
  }),
  on(queryOrganizationsFailure, (state, { errorMessage }) => {
    return {
      ...organizationsAdapter.removeAll(state),
      loaded: false,
      error: errorMessage,
    };
  })
);

export function adminOrganizationsReducer(
  state: AdminOrganizationState | undefined,
  action: Action
) {
  return organizationsReducer(state, action);
}

// AUTHORIZATIONS REDUCER

export const authorizationsAdapter = createEntityAdapter<
  OrganizationAuthorizations & WithId
>();

export const initialAuthorizationsState: AdminOrganizationAuthorizationsState =
  authorizationsAdapter.getInitialState({
    loaded: false,
    error: undefined,
  });

const authorizationsReducer = createReducer(
  initialAuthorizationsState,
  on(queryAuthorizations, (state) => {
    if (state.loaded) {
      return state;
    }
    return {
      ...authorizationsAdapter.removeAll(state),
      loaded: false,
      error: undefined,
    };
  }),
  on(queryAuthorizationsSuccess, (state, { authorizations }) => {
    return {
      ...authorizationsAdapter.setAll(authorizations, state),
      loaded: true,
      error: undefined,
    };
  }),
  on(queryAuthorizationsFailure, (state, { errorMessage }) => {
    return {
      ...authorizationsAdapter.removeAll(state),
      loaded: false,
      error: errorMessage,
    };
  })
);

export function adminAuthorizationsReducer(
  state: AdminOrganizationAuthorizationsState | undefined,
  action: Action
) {
  return authorizationsReducer(state, action);
}

// PREMIUM ACCOUNTS REDUCER

export const premiumAccountsAdapter = createEntityAdapter<
  PremiumAccount & WithId
>();

export const initialPremiumAccountsState: AdminPremiumAccountsState =
  premiumAccountsAdapter.getInitialState({
    loaded: false,
    error: undefined,
  });

const premiumAccountsReducer = createReducer(
  initialPremiumAccountsState,
  on(queryPremiumAccounts, (state) => {
    if (state.loaded) {
      return state;
    }
    return {
      ...premiumAccountsAdapter.removeAll(state),
      loaded: false,
      error: undefined,
    };
  }),
  on(queryPremiumAccountsSuccess, (state, { premiumAccounts }) => {
    return {
      ...premiumAccountsAdapter.setAll(premiumAccounts, state),
      loaded: true,
      error: undefined,
    };
  }),
  on(queryPremiumAccountsFailure, (state, { errorMessage }) => {
    return {
      ...premiumAccountsAdapter.removeAll(state),
      loaded: false,
      error: errorMessage,
    };
  })
);

export function adminPremiumAccountsReducer(
  state: AdminPremiumAccountsState | undefined,
  action: Action
) {
  return premiumAccountsReducer(state, action);
}

// OFFERS REDUCER

export const offersAdapter = createEntityAdapter<Offer & WithId>();

export const initialOffersState: AdminOffersState =
  offersAdapter.getInitialState({
    loaded: false,
    error: undefined,
  });

const offersReducer = createReducer(
  initialOffersState,
  on(queryOffers, (state) => {
    if (state.loaded) {
      return state;
    }
    return {
      ...offersAdapter.removeAll(state),
      loaded: false,
      error: undefined,
    };
  }),
  on(queryOffersSuccess, (state, { offers }) => {
    return {
      ...offersAdapter.setAll(offers, state),
      loaded: true,
      error: undefined,
    };
  }),
  on(queryOffersFailure, (state, { errorMessage }) => {
    return {
      ...offersAdapter.removeAll(state),
      loaded: false,
      error: errorMessage,
    };
  })
);

export function adminOffersReducer(
  state: AdminOffersState | undefined,
  action: Action
) {
  return offersReducer(state, action);
}
