<ng-container *transloco="let t; read: 'components.confirmation-dialog'">
  <section>
    <h2 class="mat-dialog-title space-bottom text-l">
      {{ data.title }}
    </h2>
    <div
      class="mat-typography mat-dialog-content text-align-justify"
      [ngClass]="{
        'content-with-additional-validation': data.additionalValidation
      }"
    >
      <p class="text-subtitle-1 light">{{ data.message }}</p>
      <section *ngIf="data.additionalValidation">
        <mat-checkbox data-testid="additional-validation-checkbox-test-id">
          {{ data.additionalValidation.message }}</mat-checkbox
        >
      </section>
    </div>
    <mat-dialog-actions align="end">
      <button
        data-testid="confirmation-cancel-button-test-id"
        mat-button
        [mat-dialog-close]="false"
      >
        {{ t('cancel-button') }}
      </button>
      <ng-container
        *ngIf="!data.confirmationForDeleting; else deleteButtonTemplate"
      >
        <button
          data-testid="confirmation-confirm-button-test-id"
          mat-raised-button
          color="accent"
          (click)="confirm()"
        >
          <span>{{ t('confirm-button') }}</span>
        </button>
      </ng-container>
      <ng-template #deleteButtonTemplate>
        <button
          data-testid="confirmation-confirm-button-test-id"
          mat-raised-button
          color="warn"
          (click)="confirm()"
        >
          <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="0.5em">
            <img
              *ngIf="data.confirmationForDeleting"
              src="assets/icons/delete_icon.svg"
            />
            <span>{{ t('delete-button') }}</span>
          </div>
        </button>
      </ng-template>
    </mat-dialog-actions>
  </section>
</ng-container>
