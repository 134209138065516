import { EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Action, createReducer } from '@ngrx/store';

import {
  createEntityReducer,
  createInitialState,
} from '@arrivage-store/generators';
import { Offer, WithId } from '@arrivage/model/dist/src/model';

import * as visibleOffersActions from './visible-offers.actions';
import { VisibleOffersState } from './visible-offers.state';

export const adapter: EntityAdapter<Offer & WithId> = createEntityAdapter<
  Offer & WithId
>();

export const initialState: VisibleOffersState = createInitialState(adapter);

const visibleOffersReducer = createReducer(
  initialState,
  ...createEntityReducer(visibleOffersActions, adapter)
);

export function reducer(state: VisibleOffersState | undefined, action: Action) {
  return visibleOffersReducer(state, action);
}
