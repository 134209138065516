import { Observable } from 'rxjs';

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router, UrlTree } from '@angular/router';

import { map } from 'rxjs/operators';

import { AuthService } from '@arrivage-auth/services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard  {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> {
    // Special case for authentication since this guard will be call quickly on refresh
    // before the auth state is set in the store
    return this.authService.isAuthenticated$.pipe(
      map((isAuth) =>
        isAuth ? true : this.router.parseUrl('/authentication/login')
      )
    );
  }
}
