import { Observable } from 'rxjs';

import { CdkCellDef } from '@angular/cdk/table';
import { Directive, Input } from '@angular/core';
import { MatCellDef, MatTableDataSource } from '@angular/material/table';

/**
 * **Usage:**
 *
 * On a `mat-cell` element, provide the following `*matCellDef`:
 * ```typescript
 * *matCellDef="let element; dataSource: <dataSource>"
 * ```
 * Where `<dataSource>` is the name of the property on the component that is the data source for the table.
 *
 * Following that, element will be typed to the type of the data source.
 */
@Directive({
  selector: '[matCellDef]',
  providers: [{ provide: CdkCellDef, useExisting: TypedMatCellDef }],
})
export class TypedMatCellDef<T> extends MatCellDef {
  @Input() matCellDefDataSource: T[] | Observable<T[]> | MatTableDataSource<T>;

  static ngTemplateContextGuard<T>(
    dir: TypedMatCellDef<T>,
    ctx: unknown
  ): ctx is { $implicit: T; index: number } {
    return true;
  }
}
