import { createAction, props } from '@ngrx/store';

import { RelationsLogic } from '@arrivage-relationship/common/model/relations-logic.model';

const featureName = 'ExternalCustomerInfoFeedback';

export const getExternalCustomerInfo = createAction(
  `[${featureName}] get External Customer Info`,
  props<{
    relationshipId: string;
    vendorOrganizationId: string;
  }>()
);

export const getExternalCustomerInfoSuccess = createAction(
  `[${featureName}] get External Customer Info Success`,
  props<{
    customerInfo: RelationsLogic.CustomerInfo;
  }>()
);

export const getExternalCustomerInfoFailure = createAction(
  `[${featureName}] get External Customer Info Failure`,
  props<{
    error: string;
  }>()
);
