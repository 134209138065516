import * as _ from 'lodash';

import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';

import { DeliveryByRelationshipId } from '@arrivage-relationship/common/model/relationship.model';
import { selectAllUpdatedCustomersAndHubs } from '@arrivage-relationship/common/store/relationship-infos.selectors';
import { createSelectors } from '@arrivage-store/generators';
import { State as RootState } from '@arrivage-store/state';
import {
  Delivery,
  RelationshipInfo,
  WithId,
} from '@arrivage/model/dist/src/model';

import { adapter } from './vendor-delivery.reducer';
import {
  State,
  VendorDeliveryState,
  VendorDeliveryStateName,
} from './vendor-delivery.state';

export const getVendorDeliveryState =
  createFeatureSelector<VendorDeliveryState>(VendorDeliveryStateName);

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
  connecting,
  connected,
  connectState,
  queryFailure,
  addFailure,
  setFailure,
  updateFailure,
  removeFailure,
  getById,
  selectActiveItem,
  isLoadingActiveItem,
  selectActiveItemState,
} = createSelectors<Delivery, State>(adapter, getVendorDeliveryState);

export const selectAllRelationsDeliveries = createSelector<
  RootState,
  [Dictionary<Delivery & WithId>, (RelationshipInfo & WithId)[]],
  DeliveryByRelationshipId
>(
  selectEntities,
  selectAllUpdatedCustomersAndHubs,
  (deliveries, relationshipInfos) => {
    const deliveryByRelationshipId: {
      [relationshipId: string]: Delivery & WithId;
    } = {};
    relationshipInfos.forEach((r) => {
      if (r.deliveryId) {
        deliveryByRelationshipId[r.relationshipId] = deliveries[r.deliveryId];
      }
    });
    return deliveryByRelationshipId;
  }
);

export const selectRelationshipInfoByDeliveryId = createSelector<
  RootState,
  [(Delivery & WithId)[], (RelationshipInfo & WithId)[]],
  { [deliveryId: string]: (RelationshipInfo & WithId)[] }
>(
  selectAll,
  selectAllUpdatedCustomersAndHubs,
  (deliveries, relationshipInfos) => {
    const relationshipInfoByDeliveryId: {
      [deliveryId: string]: (RelationshipInfo & WithId)[];
    } = {};
    deliveries.forEach((d) => {
      const associatedRelationshipInfos = _.filter(
        relationshipInfos,
        (ri) => !!ri.deliveryId && ri.deliveryId === d.id
      );
      relationshipInfoByDeliveryId[d.id] = associatedRelationshipInfos;
    });
    return relationshipInfoByDeliveryId;
  }
);
