import { createAction, props } from '@ngrx/store';

import { Confirmation } from '@arrivage-store/generators';
import { UserSettings, WithId } from '@arrivage/model/dist/src/model';

export const query = createAction(`[UserSettings] Query`);
export const querySuccess = createAction(
  `[UserSettings] Query Success`,
  props<{ userSettings: UserSettings }>()
);
export const queryFailure = createAction(
  `[UserSettings] Query Failure`,
  props<{ errorMessage: string }>()
);

export const update = createAction(
  `[UserSettings] Update`,
  props<{
    userSettings: Partial<UserSettings> & WithId;
    confirmation: Confirmation<string, any>;
  }>()
);
export const updateSuccess = createAction(
  `[UserSettings] Update Success`,
  props<{ userSettings: UserSettings }>()
);
export const updateFailure = createAction(
  `[UserSettings] Update Failure`,
  props<{
    userSettings: Partial<UserSettings> & WithId;
    errorMessage: string;
  }>()
);
