<ng-container
  *transloco="let t; scope: 'invoices'; read: 'invoices.publish-invoice-dialog'"
>
  <h2 class="mat-dialog-title space-bottom text-xl" data-testid="title-test-id">
    {{
      t('title', {
        customer: data?.customer?.name ?? 'undefined'
      })
    }}
  </h2>
  <div class="mat-dialog-content">
    <mat-card-content fxLayout="column" fxLayoutGap="1rem">
      <p class="description">
        <span data-testid="description-test-id">{{
          t('publish-to-customer-description', {
            customer: data?.customer?.name ?? 'undefined'
          })
        }}</span>
      </p>
      <div class="text-m" fxLayout="row" fxLayoutAlign="start center">
        <mat-checkbox
          data-testid="description-send-test-id"
          [checked]="sendInvoice"
          (change)="onSendInvoiceChange($event.checked)"
        >
          {{ t('and-send-to-customer-description') }}
        </mat-checkbox>
      </div>
    </mat-card-content>
    <mat-card-content>
      <form [formGroup]="form">
        <mat-form-field appearance="outline">
          <mat-label>{{ t('message-label') }}</mat-label>
          <textarea
            cdkTextareaAutosize
            matInput
            rows="4"
            formControlName="message"
          ></textarea>
          <mat-hint data-testid="hint-test-id">{{
            t('message-hint', {
              customer: data?.customer?.name ?? 'undefined'
            })
          }}</mat-hint>
        </mat-form-field>
      </form>
    </mat-card-content>
  </div>
  <!-- Boutons -->
  <mat-dialog-actions align="end">
    <button
      mat-raised-button
      [mat-dialog-close]="false"
      data-testid="cancel-button-test-id"
    >
      {{ t('cancel') }}
    </button>
    <button
      mat-raised-button
      color="accent"
      data-testid="publish-button-test-id"
      (click)="publish()"
    >
      {{ t('publish') }}
    </button>
  </mat-dialog-actions>
</ng-container>
