import { MaterialModule } from 'src/app/material.module';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { TranslocoModule } from '@jsverse/transloco';

import { AddEmailDialogComponent } from './add-email-dialog.component';

@NgModule({
  declarations: [AddEmailDialogComponent],
  imports: [
    CommonModule,
    MaterialModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    TranslocoModule,
  ],
  exports: [AddEmailDialogComponent],
})
export class AddEmailDialogModule {}
