<div
  fxLayout="row"
  fxLayoutAlign="center center"
  fxLayoutGap="1rem"
  *transloco="let t; scope: 'admin'; read: 'admin'"
>
  <mat-form-field appearance="outline" class="selector">
    <mat-label>{{
      currentOrganization
        ? t('view-as.title')
        : t('selector.organization-label')
    }}</mat-label>
    <mat-select
      #select
      (selectionChange)="onSelectionChange($event)"
      (openedChange)="input.focus()"
      (closed)="clearInput()"
      [(value)]="selected"
    >
      <form fxLayout="row" [formGroup]="form">
        <div
          fxLayout="row"
          fxLayoutAlign="start center"
          class="input-select-container"
        >
          <input
            #input
            formControlName="nameFilter"
            matInput
            type="text"
            autocomplete="off"
            (input)="search(form.controls['nameFilter'].value)"
            [placeholder]="
              t('organizations-management.filters.search-organization-label')
            "
            (keyup)="testAdmin($event)"
            (keydown)="$event.stopPropagation()"
          />
          <button
            *ngIf="form.controls['nameFilter'].value"
            mat-icon-button
            aria-label="Clear"
            (click)="clearInput(); input.focus()"
          >
            <mat-icon>close</mat-icon>
          </button>
        </div>
      </form>
      <mat-option
        class="bold"
        *ngIf="currentOrganization && !form.controls['nameFilter'].value"
        value="currentOrganization"
        >{{ currentOrganization.name }}</mat-option
      >
      <mat-option
        *ngFor="let organization of displayedOrganizations"
        [value]="organization.id"
      >
        {{ organization.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>
