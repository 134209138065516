import _ from 'lodash';

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationSequenceInitialUrlService {
  // Exclude login because we don't want to route to login after the login process is complete
  // Exclude onboarding because we never want to route to the onboarding page after a normal login or once the onboarding process is completed
  readonly excludedRoutes = ['503', 'authentication/login', 'onboarding'];
  private _initialUrl = '/';

  constructor() {}

  setInitialUrl(value: string) {
    if (value) {
      this._initialUrl = _.find(this.excludedRoutes, (r) => value.includes(r))
        ? '/'
        : value;
    } else {
      // default to / if empty or undefined
      this._initialUrl = '/';
    }
  }

  getInitialUrl(): string {
    return this._initialUrl;
  }

  resetInitialUrl() {
    this._initialUrl = '/';
  }
}
