import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { PriceListEffects } from './price-list.effects';
import { reducer } from './price-list.reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('priceList', reducer),
    EffectsModule.forFeature([PriceListEffects]),
  ],
})
export class PriceListStoreModule {}
