import { Action, createReducer } from '@ngrx/store';

import { createCommonPurchaseOrderReducer } from '@arrivage-purchase-orders/common/store/base-purchase-orders.reducer';
import { purchaseOrderAdapter } from '@arrivage-purchase-orders/common/store/base-purchase-orders.selectors';
import { createInitialState } from '@arrivage-store/generators';

import { CustomerPurchaseOrdersActions } from './customer-purchase-orders.actions';
import { CustomerPurchaseOrdersState } from './customer-purchase-orders.state';

export const initialState: CustomerPurchaseOrdersState = createInitialState(
  purchaseOrderAdapter,
  {
    currentDateRange: undefined,
    storedDateRanges: [],
    dateRangeLoadingCollectionState: {
      items: {
        sent: [],
        deliveryOrPickup: [],
      },
      isLoading: false,
      error: undefined,
    },
    subPurchaseOrdersCurrentDateRange: undefined,
    subPurchaseOrdersStoredDateRanges: [],
    subPurchaseOrdersByDateRange: {
      items: {
        sent: [],
        deliveryOrPickup: [],
      },
      isLoading: false,
      error: undefined,
    },
  }
);

const purchaseOrdersReducer = createReducer(
  initialState,
  ...createCommonPurchaseOrderReducer<CustomerPurchaseOrdersState>(
    CustomerPurchaseOrdersActions,
    purchaseOrderAdapter
  )
);

export function reducer(
  state: CustomerPurchaseOrdersState | undefined,
  action: Action
) {
  return purchaseOrdersReducer(state, action);
}
