import { from } from 'rxjs';

import { Injectable } from '@angular/core';
import { Functions, httpsCallable } from '@angular/fire/functions';

import { map, retryWhen, take } from 'rxjs/operators';

import { genericRetryStrategy } from '@arrivage-util/api.utils';
import { DeleteDeliveryRequestData } from '@arrivage/model/dist/src/cloud-functions-api';

@Injectable({ providedIn: 'root' })
export class DeliveryApiService {
  private readonly deleteDeliveryCloudFunction = httpsCallable<
    DeleteDeliveryRequestData,
    void
  >(this.functions, 'deleteDelivery');

  constructor(private functions: Functions) {}

  deleteDelivery(request: DeleteDeliveryRequestData): Promise<void> {
    return from(this.deleteDeliveryCloudFunction(request))
      .pipe(
        retryWhen(genericRetryStrategy()),
        take(1),
        map((r) => r.data)
      )
      .toPromise();
  }
}
