import _ from 'lodash';
import { Observable } from 'rxjs';

import { Injectable } from '@angular/core';
import {
  collection,
  collectionData,
  Firestore,
  query,
  where,
} from '@angular/fire/firestore';

import { map } from 'rxjs/operators';

import { OfferService } from '@arrivage-offers/common/services/offers.service';
import { Offer, WithId } from '@arrivage/model/dist/src/model';

@Injectable({
  providedIn: 'root',
})
export class AdminOfferService extends OfferService {
  constructor(firestore: Firestore) {
    super(firestore);
  }

  listAllActiveOffers(): Observable<(Offer & WithId)[]> {
    return collectionData(
      query(
        collection(this.firestore, OfferService.OFFERS_COLLECTION),
        where('isActive', '==', true)
      ),
      { idField: 'id' }
    ).pipe(
      map((offers) =>
        _.map(offers as (Offer & WithId)[], (o) =>
          OfferService.convertTimestampFields(o)
        )
      )
    );
  }
}
