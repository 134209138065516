import { subHours, formatDistanceToNow } from 'date-fns';

import { Pipe, PipeTransform } from '@angular/core';

import { LanguageService } from '@arrivage-language/service/language.service';

@Pipe({
  name: 'approximativeTimeRemaining',
})
export class ApproximativeTimeRemainingPipe implements PipeTransform {
  constructor(private languageService: LanguageService) {}
  transform(until: Date, leadTimeHours: number = 0): string {
    return until
      ? formatDistanceToNow(subHours(until, leadTimeHours), {
          locale: this.languageService.getCurrentLocaleDateFns(),
        })
      : '';
  }
}
