import { createFeatureSelector, createSelector } from '@ngrx/store';

import { CreditAccountState } from './credit-account.state';

export const getCreditAccountState = createFeatureSelector<CreditAccountState>(
  'creditAccount'
);

export const loaded = createSelector(getCreditAccountState, (state) => {
  return state.loaded;
});

export const selectCreditAccount = createSelector(
  getCreditAccountState,
  (state) => {
    return state.creditAccount;
  }
);

export const queryError = createSelector(getCreditAccountState, (state) => {
  return state.error;
});
