<span
  #customTextElement
  [customTooltip]="customTooltip || text"
  [tooltipDisabled]="tooltipDisabled"
  [tooltipPosition]="tooltipPosition"
  [class]="
    additionalClasses ? additionalClasses + ' custom-text' : 'custom-text'
  "
  [style.-webkit-line-clamp]="nbLine"
  [class.cursor-style]="isOverflowing()"
  [ngClass]="{
    bold: bold,
    'text-italic': italic
  }"
>
  {{ text }}
</span>
