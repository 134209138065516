import _ from 'lodash';

import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as FormatSelector from '@arrivage-formats/store/formats.selectors';
import {
  InventoryLine,
  ProductWithInventoryLines,
} from '@arrivage-inventory/model/inventory.model';
import * as InventoryItemSelector from '@arrivage-inventory/store/inventory.selectors';
import * as PriceListSelectors from '@arrivage-price-lists/store/price-list.selectors';
import * as ProductSelectors from '@arrivage-products/store/products.selectors';
import { createSelectors } from '@arrivage-store/generators';
import { State as RootState } from '@arrivage-store/state';
import {
  Format,
  InventoryItem,
  PriceList,
  PriceListItem,
  Product,
  WithId,
} from '@arrivage/model/dist/src/model';

import { PriceListItemLine } from '../model/price-list.model';
import { adapter } from './price-list-item.reducer';
import { PriceListItemState, State } from './price-list-item.state';
import { getCurrentPriceListId } from './price-list.selectors';

export const getPriceListItemState =
  createFeatureSelector<PriceListItemState>('priceListItem');

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
  connecting,
  connected,
  connectState,
  queryFailure,
  addFailure,
  setFailure,
  updateFailure,
  removeFailure,
  getById,
  selectActiveItem,
  isLoadingActiveItem,
  selectActiveItemState,
} = createSelectors<PriceListItem, State>(adapter, getPriceListItemState);

export const getCurrentPriceListItems = createSelector<
  RootState,
  [(PriceListItem & WithId)[], string],
  (PriceListItem & WithId)[]
>(selectAll, getCurrentPriceListId, (priceListItems, priceListId) => {
  return priceListItems.filter((x) => x.priceListId === priceListId);
});

export const getCurrentPriceListItemLines = createSelector<
  RootState,
  [
    (PriceListItem & WithId)[],
    Dictionary<InventoryItem & WithId>,
    Dictionary<Product & WithId>,
    Dictionary<Format & WithId>,
  ],
  PriceListItemLine[]
>(
  getCurrentPriceListItems,
  InventoryItemSelector.selectEntities,
  ProductSelectors.selectEntities,
  FormatSelector.selectEntities,
  (priceListItems, inventoryItems, products, formats) => {
    return priceListItems
      .filter(
        (priceListItem) => !!inventoryItems[priceListItem.inventoryItemId]
      )
      .map((priceListItem) => {
        const inventoryItem = inventoryItems[priceListItem.inventoryItemId];
        const product = products[inventoryItem.productId];
        const format = formats[inventoryItem.formatId];
        return {
          inventoryItem: inventoryItem,
          priceListItem: priceListItem,
          product: product,
          format: format,
        };
      });
  }
);

export const getAllPriceListItemLinesByPriceListId = createSelector<
  RootState,
  [
    (PriceListItem & WithId)[],
    Dictionary<InventoryItem & WithId>,
    Dictionary<Product & WithId>,
    Dictionary<Format & WithId>,
    Dictionary<PriceList & WithId>,
  ],
  Dictionary<PriceListItemLine[]>
>(
  selectAll,
  InventoryItemSelector.selectEntities,
  ProductSelectors.selectEntities,
  FormatSelector.selectEntities,
  PriceListSelectors.selectEntities,
  (priceListItems, inventoryItems, products, formats, priceLists) => {
    return _.groupBy(
      priceListItems
        .filter(
          (priceListItem) => !!inventoryItems[priceListItem.inventoryItemId]
        )
        .filter((priceListItem) => !!priceLists[priceListItem.priceListId])
        .map((priceListItem) => {
          const inventoryItem = inventoryItems[priceListItem.inventoryItemId];
          const product = products[inventoryItem.productId];
          const format = formats[inventoryItem.formatId];
          return {
            inventoryItem: inventoryItem,
            priceListItem: priceListItem,
            product: product,
            format: format,
          };
        }),
      'priceListItem.priceListId'
    );
  }
);

export const getAllPriceListItemActive = createSelector<
  RootState,
  [
    (PriceListItem & WithId)[],
    Dictionary<InventoryItem & WithId>,
    Dictionary<Product & WithId>,
    Dictionary<Format & WithId>,
  ],
  Dictionary<PriceListItemLine[]>
>(
  selectAll,
  InventoryItemSelector.selectEntities,
  ProductSelectors.selectEntities,
  FormatSelector.selectEntities,
  (priceListItems, inventoryItems, products, formats) => {
    return _.groupBy(
      priceListItems
        .filter(
          (priceListItem) =>
            !!inventoryItems[priceListItem.inventoryItemId] &&
            priceListItem.isActive
        )
        .map((priceListItem) => {
          const inventoryItem = inventoryItems[priceListItem.inventoryItemId];
          const product = products[inventoryItem.productId];
          const format = formats[inventoryItem.formatId];
          return {
            inventoryItem: inventoryItem,
            priceListItem: priceListItem,
            product: product,
            format: format,
          };
        }),
      'priceListItem.priceListId'
    );
  }
);

export const selectInventoryLines = createSelector<
  RootState,
  [
    Dictionary<Format & WithId>,
    (InventoryItem & WithId)[],
    (PriceListItem & WithId)[],
    (PriceList & WithId)[],
  ],
  InventoryLine[]
>(
  FormatSelector.selectEntities,
  InventoryItemSelector.selectAll,
  selectAll,
  PriceListSelectors.selectAll,
  (formatById, inventoryItems, priceListItems, priceLists) => {
    return inventoryItems
      .filter((inventoryItem) => !!formatById[inventoryItem.formatId])
      .map((inventoryItem) => {
        const sortedPriceLists = _.orderBy(priceLists, 'name');
        return {
          format: formatById[inventoryItem.formatId],
          inventoryItem: inventoryItem,
          priceListItemByPriceList: sortedPriceLists.reduce(
            (prev, priceList) => {
              const priceListItem: PriceListItem & WithId = priceListItems.find(
                (pli) =>
                  pli.priceListId === priceList.id &&
                  pli.inventoryItemId === inventoryItem.id
              );
              if (priceListItem) {
                return {
                  ...prev,
                  [priceList.id]: priceListItem,
                };
              }
            },
            {} as { [priceListId: string]: PriceListItem & WithId }
          ),
        };
      })
      .filter((a) => !!a.priceListItemByPriceList);
  }
);

export const selectProductsWithInventoryLines = createSelector<
  RootState,
  [InventoryLine[], (Product & WithId)[]],
  ProductWithInventoryLines[]
>(
  selectInventoryLines,
  ProductSelectors.selectAll,
  (inventoryLines, products) => {
    return products.map((product) => {
      return {
        product: product,
        inventoryLines: inventoryLines.filter(
          (f) => f.inventoryItem.productId === product.id
        ),
      };
    });
  }
);
