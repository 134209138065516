import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { ReceivedContactRequestsEffects } from './received-contact-requests.effects';
import { reducer } from './received-contact-requests.reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('receivedContactRequests', reducer),
    EffectsModule.forFeature([ReceivedContactRequestsEffects]),
  ],
})
export class ReceivedContactRequestsStoreModule {}
