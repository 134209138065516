import { fromEvent, merge, Observable, of } from 'rxjs';

import { Injectable } from '@angular/core';

import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class NetworkStatusService {
  private connected$: Observable<boolean>;

  constructor() {
    this.connected$ = merge(
      of(navigator.onLine),
      fromEvent(window, 'offline').pipe(map(() => false)),
      fromEvent(window, 'online').pipe(map(() => true))
    );
  }

  isConnected(): Observable<boolean> {
    return this.connected$;
  }
}
