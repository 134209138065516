import { Observable } from 'rxjs';

import { Injectable } from '@angular/core';
import { Firestore } from '@angular/fire/firestore';

import { BaseService, PathSpec } from '@arrivage-services/base.service';
import {
  EntityChangeAction,
  OrganizationEntityService,
} from '@arrivage-services/organization-entity.service';
import { InventoryItem, WithId } from '@arrivage/model/dist/src/model';
import { CollectionNames } from '@arrivage/model/dist/src/utils';

@Injectable({
  providedIn: 'root',
})
export class InventoryService
  extends BaseService<InventoryItem>
  implements OrganizationEntityService<InventoryItem>
{
  static readonly ORGANIZATIONS_COLLECTION =
    CollectionNames.ORGANIZATION_COLLECTION;
  static readonly INVENTORY_COLLECTION =
    CollectionNames.INVENTORY_ITEMS_COLLECTION;

  constructor(firestore: Firestore) {
    super(firestore);
  }

  get(
    organizationId: string,
    recordId: string
  ): Observable<InventoryItem & WithId> {
    throw new Error('Method not implemented.');
  }

  create(
    organizationId: string,
    inventoryItem: InventoryItem
  ): Promise<string> {
    return this._create(this.pathSpec(organizationId), inventoryItem);
  }

  set(
    organizationId: string,
    id: string,
    inventoryItem: InventoryItem
  ): Promise<void> {
    return this._set(this.pathSpec(organizationId), id, inventoryItem);
  }

  update(
    organizationId: string,
    inventoryItemId: string,
    inventoryItem: Partial<InventoryItem>
  ): Promise<void> {
    return this._update(
      this.pathSpec(organizationId),
      inventoryItemId,
      inventoryItem
    );
  }

  connect(
    organizationId: string
  ): Observable<EntityChangeAction<InventoryItem>[]> {
    return this._connect(this.pathSpec(organizationId), (d) => d, null);
  }

  list(organizationId: string): Observable<(InventoryItem & WithId)[]> {
    return this._list(this.pathSpec(organizationId));
  }

  remove(organizationId: string, inventoryItemId: string): Promise<void> {
    return this._delete(this.pathSpec(organizationId), inventoryItemId);
  }

  private pathSpec(organizationId: string): PathSpec[] {
    return [
      {
        collection: InventoryService.ORGANIZATIONS_COLLECTION,
        id: organizationId,
      },
      { collection: InventoryService.INVENTORY_COLLECTION },
    ];
  }
}
