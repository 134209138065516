<ng-container *transloco="let t; read: 'picture-cropper-editor'">
  <ng-container *ngIf="!data.unsupportedFormat; else unsupportedFile">
    <div class="mat-typography mat-dialog-content">
      <image-cropper
        [imageChangedEvent]="data.imageChangedEvent"
        [onlyScaleDown]="true"
        [maintainAspectRatio]="data.maintainAspectRatio ? true : false"
        [aspectRatio]="data.aspectRatio || 1"
        [format]="data.outputType"
        (imageCropped)="imageCropped($event)"
        (loadImageFailed)="loadImageFailed()"
      ></image-cropper>
    </div>
    <mat-dialog-actions align="end">
      <button mat-button (click)="cancel()">
        {{ t('cancel') }}
      </button>
      <button mat-raised-button color="accent" (click)="upload()">
        {{ t('upload') }}
      </button>
    </mat-dialog-actions>
  </ng-container>
  <ng-template #unsupportedFile>
    <div class="mat-typography mat-dialog-content">
      {{ t('unsupported-file') }}
    </div>
    <mat-dialog-actions align="end">
      <button mat-button (click)="cancel()">
        {{ t('ok') }}
      </button>
    </mat-dialog-actions>
  </ng-template>
</ng-container>
