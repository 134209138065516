import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';

import { map } from 'rxjs/operators';

import { AuthService } from '@arrivage-auth/services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class GuestGuard  {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    // If not authenticated, allow access, if authenticated, route to dashboard instead
    return this.authService.isNotAuthenticated$.pipe(
      map((notAuthenticated) =>
        notAuthenticated ? true : this.router.parseUrl('/')
      )
    );
  }
}
