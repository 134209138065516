import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatRadioChange } from '@angular/material/radio';

import {
  LocalDate,
  PurchaseOrder,
  WithId,
} from '@arrivage/model/dist/src/model';

export const DELIVERY_DIALOG_RESULT_ON_TIME = 'onTime';
export type DeliveryDialogResult = Date | typeof DELIVERY_DIALOG_RESULT_ON_TIME;

@Component({
  selector: 'app-confirm-delivery-dialog',
  templateUrl: './confirm-delivery-dialog.component.html',
  styleUrls: ['./confirm-delivery-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmDeliveryDialogComponent implements OnInit {
  readonly TODAY: Date = new Date();
  selectedRadio: 'newDate' | 'purchaseOrdersDeliveryDate' = null;
  deliveryDateControl: UntypedFormControl = this.fb.control(
    {
      value: null,
      disabled:
        this.data?.length > 1 ? this.selectedRadio !== 'newDate' : false,
    },
    Validators.required
  );

  static openDialog(dialog: MatDialog, data: (PurchaseOrder & WithId)[]) {
    return dialog.open<
      ConfirmDeliveryDialogComponent,
      (PurchaseOrder & WithId)[],
      DeliveryDialogResult
    >(ConfirmDeliveryDialogComponent, {
      data: data,
      width: '600px',
    });
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: (PurchaseOrder & WithId)[],
    private dialogRef: MatDialogRef<
      ConfirmDeliveryDialogComponent,
      DeliveryDialogResult
    >,
    private fb: UntypedFormBuilder
  ) {
    dialogRef.disableClose = true;
  }

  ngOnInit(): void {
    if (this.data?.length === 1) {
      this.deliveryDateControl.setValue(
        this.data[0].deliveryDate ??
          LocalDate.toDate(this.data[0].pickup?.selectedDay)
      );
    } else {
      this.selectedRadio = 'purchaseOrdersDeliveryDate';
      this.deliveryDateControl.setValue(new Date());
    }
  }

  onRadioButtonChange(change: MatRadioChange) {
    if (change.value) {
      this.selectedRadio = change.value;

      if (this.selectedRadio !== 'newDate') {
        this.deliveryDateControl.disable();
      } else {
        this.deliveryDateControl.enable();
      }
    }
  }

  confirmDelivery() {
    if (this.deliveryDateControl.enabled && this.deliveryDateControl.valid) {
      this.dialogRef.close(this.deliveryDateControl.value);
    } else if (this.selectedRadio === 'purchaseOrdersDeliveryDate') {
      this.dialogRef.close(DELIVERY_DIALOG_RESULT_ON_TIME);
    }
  }
}
