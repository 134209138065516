import _ from 'lodash';

import { createFeatureSelector, createSelector } from '@ngrx/store';

import { Invoice, Money, WithId } from '@arrivage/model/dist/src/model';

import {
  createSelectors as createCommonInvoiceSelectors,
  InvoiceSelectors,
} from '../../common/store/generator';
import { InvoicesUtils } from '../../common/util/invoices.utils';
import { PaymentMethodsState } from '../model/payment-methods-state.model';
import { RelatedInvoicesState } from '../model/related-invoices-state.model';
import { CustomerInvoicesState, State } from './customer-invoices.state';

export const getCustomerInvoiceState =
  createFeatureSelector<CustomerInvoicesState>('customerInvoices');

export const CustomerInvoicesSelectors: InvoiceSelectors =
  createCommonInvoiceSelectors(getCustomerInvoiceState);

export const selectUnpaidLate = createSelector(
  CustomerInvoicesSelectors.selectAll,
  (invoices) => invoices.filter((invoice) => InvoicesUtils.isLate(invoice))
);

export const selectPaymentMethodsState = createSelector<
  State,
  [CustomerInvoicesState],
  PaymentMethodsState
>(getCustomerInvoiceState, (s) => {
  return {
    isLoading: s.loadingPaymentMethods,
    paymentMethods: s.paymentMethods,
    error: s.paymentMethodsError,
  };
});

export const selectRelatedInvoicesState = createSelector<
  State,
  [CustomerInvoicesState],
  RelatedInvoicesState
>(getCustomerInvoiceState, (s) => {
  return {
    isLoading: s.loadingRelatedInvoices,
    relatedInvoices: s.relatedInvoices,
    error: s.relatedInvoicesError,
  };
});

export const getLoadingRelatedInvoices = createSelector<
  State,
  [CustomerInvoicesState],
  boolean
>(getCustomerInvoiceState, (s) => s.loadingRelatedInvoices);

export const getRelatedInvoices = createSelector<
  State,
  [CustomerInvoicesState],
  (Invoice & WithId)[]
>(getCustomerInvoiceState, (s) => (s.relatedInvoices ? s.relatedInvoices : []));

export const relatedInvoicesError = createSelector<
  State,
  [CustomerInvoicesState],
  any
>(getCustomerInvoiceState, (s) => s.relatedInvoicesError);

export const getLoadedPurchaseOrderIds = createSelector<
  State,
  [CustomerInvoicesState],
  string[]
>(getCustomerInvoiceState, (s) =>
  s.relatedInvoices ? _.map(s.relatedInvoices, 'purchaseOrderId') : []
);

export const selectInvoiceDashboardMetrics = createSelector(
  CustomerInvoicesSelectors.selectUnpaidMetrics,
  selectUnpaidLate,
  (unpaidMetrics, unpaidLateInvoices) => {
    const totalUnpaidLate = Money.sum(unpaidLateInvoices.map((i) => i.total));

    return {
      nbUnpaidInvoices: unpaidMetrics,
      nbUnpaidLateInvoices: {
        nb: unpaidLateInvoices.length,
        total: totalUnpaidLate,
      },
    };
  }
);
