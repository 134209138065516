import { MaterialModule } from 'src/app/material.module';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';

import { TranslocoModule } from '@jsverse/transloco';

import { DateRangePickerModule } from '@arrivage-components/date-range-picker/date-range-picker.module';
import { KebabCaseModule } from '@arrivage-pipes/kebab-case/kebab-case.module';

import { FilteredDateRangePickerComponent } from './filtered-date-range-picker.component';

@NgModule({
  declarations: [FilteredDateRangePickerComponent],
  imports: [
    CommonModule,
    MaterialModule,
    DateRangePickerModule,
    KebabCaseModule,
    TranslocoModule,
    FlexLayoutModule,
  ],
  exports: [FilteredDateRangePickerComponent],
})
export class FilteredDateRangePickerModule {}
