import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { VendorOfferEffects } from './offers.effect';
import { reducer } from './offers.reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('vendorOffer', reducer),
    EffectsModule.forFeature([VendorOfferEffects]),
  ],
})
export class VendorOfferStoreModule {}
