import { Observable } from 'rxjs';

import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';

import { ScreenSizeService } from '@arrivage-services/screen-size.service';

@Component({
  selector: 'app-layout-with-header',
  templateUrl: './layout-with-header.component.html',
  styleUrls: ['./layout-with-header.component.scss'],
})
export class LayoutWithHeaderComponent implements OnInit {
  isSmallOrMediumScreen$: Observable<boolean>;

  readonly facebook = '../../../../assets/images/facebook_logo_black.png';
  readonly instagram = '../../../../assets/images/instagram_logo_black.png';
  readonly linkedin = '../../../../assets/images/linkedin_logo_black.png';

  @Input()
  isHeaderSticky = false;

  @ViewChild('header') header: ElementRef;

  constructor(private screenSizeService: ScreenSizeService) {}

  ngOnInit(): void {
    this.isSmallOrMediumScreen$ =
      this.screenSizeService.getIsScreenMediumOrSmall();
  }

  get headerHeight(): number {
    return this.header?.nativeElement.clientHeight;
  }
}
