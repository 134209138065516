import { createReducer, on } from '@ngrx/store';

import * as ContextActions from './context.actions';
import { initialState } from './context.state';

export const _contextReducer = createReducer(
  initialState,
  on(ContextActions.LoadConnectedUser, (state, action) => {
    return Object.assign({}, state, {
      user: undefined,
      userLoaded: false,
      userLoadFailure: undefined,
    });
  }),
  on(ContextActions.SetConnectedUser, (state, action) => {
    return Object.assign({}, state, {
      user: action.user,
      userLoaded: true,
      userLoadFailure: undefined,
    });
  }),
  on(ContextActions.SetConnectedUserFailure, (state, action) => {
    return Object.assign({}, state, {
      user: undefined,
      userLoaded: false,
      userLoadFailure: action.message,
    });
  }),
  on(ContextActions.LoadConnectedUserMemberships, (state, action) => {
    return Object.assign({}, state, {
      allUserMemberships: undefined,
      allUserMembershipsLoaded: false,
      allUserMembershipsLoadFailure: undefined,
    });
  }),
  on(ContextActions.SetConnectedUserMemberships, (state, action) => {
    return Object.assign({}, state, {
      allUserMemberships: action.memberships,
      allUserMembershipsLoaded: true,
      allUserMembershipsLoadFailure: undefined,
    });
  }),
  on(ContextActions.LoadConnectedUserActiveMembership, (state, action) => {
    return Object.assign({}, state, {
      membership: undefined,
      membershipLoaded: false,
      membershipLoadFailure: undefined,
    });
  }),
  on(
    ContextActions.SetConnectedUserActiveMembershipFailure,
    (state, action) => {
      return Object.assign({}, state, {
        membership: undefined,
        membershipLoaded: false,
        membershipLoadFailure: action.message,
      });
    }
  ),
  on(ContextActions.SetConnectedUserActiveMembership, (state, action) => {
    return Object.assign({}, state, {
      membership: action.membership,
      membershipLoaded: true,
      allUserMembershipsLoadFailure: undefined,
    });
  }),
  on(
    ContextActions.SetConnectedUserActiveMembershipFailure,
    (state, action) => {
      return Object.assign({}, state, {
        membership: undefined,
        membershipLoaded: false,
        membershipLoadFailure: action.message,
      });
    }
  ),
  on(ContextActions.LoadConnectedUserActiveOrganization, (state, action) => {
    return Object.assign({}, state, {
      organization: undefined,
      organizationLoaded: false,
      organizationLoadFailure: undefined,
      organizationZones: [],
    });
  }),
  on(ContextActions.SetConnectedUserActiveOrganization, (state, action) => {
    return Object.assign({}, state, {
      organization: action.organization,
      organizationLoaded: true,
      organizationLoadFailure: undefined,
      organizationZones: action.zones,
    });
  }),
  on(
    ContextActions.SetConnectedUserActiveOrganizationFailure,
    (state, action) => {
      return Object.assign({}, state, {
        organization: undefined,
        organizationLoaded: true,
        organizationLoadFailure: action.message,
        organizationZones: [],
      });
    }
  )
);

export function contextReducer(state, action) {
  return _contextReducer(state, action);
}
