import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface LoadingDialogData {
  message: string;
  progress?: {
    progressValue: number;
    totalValue: number;
  };
}

@Component({
  selector: 'app-loading-dialog',
  templateUrl: './loading-dialog.component.html',
  styleUrls: ['./loading-dialog.component.scss'],
})
export class LoadingDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: LoadingDialogData,
    public dialogRef: MatDialogRef<LoadingDialogComponent>
  ) {}

  get progressValueInPercentage() {
    return Math.round(
      (this.data.progress?.progressValue * 100) / this.data.progress?.totalValue
    );
  }
}
