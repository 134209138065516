import {
  WithId,
  Organization,
  Member,
  User,
} from '@arrivage/model/dist/src/model';

export interface ContextState {
  user: User & WithId;
  userLoaded: boolean;
  userLoadFailure: string;
  allUserMemberships: (Member & WithId)[];
  allUserMembershipsLoaded: boolean;
  allUserMembershipsLoadFailure: string;
  membership: Member & WithId;
  membershipLoaded: boolean;
  membershipLoadFailure: string;
  organization: Organization & WithId;
  organizationLoaded: boolean;
  organizationLoadFailure: string;
  organizationZones: string[];
}

export const initialState: ContextState = {
  user: undefined,
  userLoaded: false,
  userLoadFailure: undefined,
  allUserMemberships: undefined,
  allUserMembershipsLoaded: false,
  allUserMembershipsLoadFailure: undefined,
  membership: undefined,
  membershipLoaded: false,
  membershipLoadFailure: undefined,
  organization: undefined,
  organizationLoaded: false,
  organizationLoadFailure: undefined,
  organizationZones: [],
};
