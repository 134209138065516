import * as _ from 'lodash';
import { Observable, pipe } from 'rxjs';

import { DocumentChange, DocumentData } from '@angular/fire/firestore';

import { map } from 'rxjs/operators';

import { WithId } from '@arrivage/model/dist/src/model';

export interface EntityChangeAction<T> {
  type: 'empty' | 'added' | 'modified' | 'removed';
  entities: (T & WithId)[];
}

export const EMPTY_COLLECTION_CHANGE_ACTION: EntityChangeAction<any> = {
  type: 'empty',
  entities: [],
};

export interface OrganizationEntityService<T> {
  connect(organizationId: string): Observable<EntityChangeAction<T>[]>;
  create(organizationId: string, record: T): Promise<string>;
  set(organizationId: string, recordId: string, record: T): Promise<void>;
  update(
    organizationId: string,
    recordId: string,
    record: Partial<T>
  ): Promise<void>;
  remove(organizationId: string, recordId: string): Promise<void>;
  get(organizationId: string, recordId: string): Observable<T & WithId>;
}

export function toEntityChangeActions<T>(
  dataMapper: (data) => T = (data) => data
) {
  return pipe(
    map((actions: DocumentChange<DocumentData>[]) => {
      const byTypes = _.groupBy(actions, 'type');

      const added = _.map(byTypes['added'], (d) => toEntity(d, dataMapper));
      const modified = _.map(byTypes['modified'], (d) =>
        toEntity(d, dataMapper)
      );
      const removed = _.map(byTypes['removed'], (d) => toEntity(d, dataMapper));

      const result: EntityChangeAction<T & WithId>[] = [];
      if (added.length) {
        result.push({ type: 'added', entities: added });
      }
      if (modified.length) {
        result.push({ type: 'modified', entities: modified });
      }
      if (removed.length) {
        result.push({ type: 'removed', entities: removed });
      }
      return result;
    })
  );
}

function toEntity<T>(
  docChangeAction: DocumentChange<DocumentData>,
  dataMapper: (data) => T = (data) => data
): T & WithId {
  return {
    ...dataMapper(docChangeAction.doc.data()),
    id: docChangeAction.doc.id,
  };
}
