<app-layout-with-header
  *transloco="let t; scope: 'organization'; read: 'organization'"
>
  <app-header [allowBack]="false" [title]="t('settings.title')"></app-header>

  <ng-container *ngIf="organization$ | async as organization">
    <ng-container *ngIf="organizationSettings$ | async as organizationSettings">
      <app-organization-settings-view
        [organization]="organization"
        [organizationSettings]="organizationSettings"
        [authUser]="authUser$ | async"
        (organizationChanges)="onUpdateOrganization($event)"
        (isDirtyChanges)="onDirtyChanges($event)"
      ></app-organization-settings-view>
    </ng-container>
  </ng-container>
</app-layout-with-header>
