import * as _ from 'lodash';

import { createFeatureSelector, createSelector } from '@ngrx/store';

import { createSelectors } from '@arrivage-store/generators';
import { Delivery } from '@arrivage/model/dist/src/model';

import { adapter } from './customer-delivery.reducer';
import {
  CustomerDeliveryState,
  CustomerDeliveryStateName,
  State,
} from './customer-delivery.state';

export const getCustomerDeliveryState =
  createFeatureSelector<CustomerDeliveryState>(CustomerDeliveryStateName);

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
  connecting,
  connected,
  connectState,
  queryFailure,
  addFailure,
  setFailure,
  updateFailure,
  removeFailure,
  getById,
  selectActiveItem,
  isLoadingActiveItem,
  selectActiveItemState,
} = createSelectors<Delivery, State>(adapter, getCustomerDeliveryState);

export const selectDeliveriesByOrganizationId = createSelector(
  selectAll,
  (deliveries) => _.groupBy(deliveries, 'organizationId')
);

export const selectRelationshipDeliveries = createSelector(
  getCustomerDeliveryState,
  (s) => s.relationshipDeliveries
);

export const selectIsRelationshipDeliveriesLoading = createSelector(
  getCustomerDeliveryState,
  (s) => s.isLoadingRelationshipDeliveries
);
export const selectLoadingRelationshipDeliveriesError = createSelector(
  getCustomerDeliveryState,
  (s) => s.loadingRelationshipDeliveriesError
);
