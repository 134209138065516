import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

import { HashMap } from '@jsverse/transloco';

@Component({
  selector: 'app-custom-snackbar',
  templateUrl: './custom-snackbar.component.html',
  styleUrls: ['../snackbar.style.scss', '../../styles/global/_colors.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomSnackbarComponent {
  constructor(
    @Inject(MAT_SNACK_BAR_DATA)
    public data: { message: string; icon?: string; messageParameters?: HashMap }
  ) {}
}
