<ng-container *transloco="let t; read: 'components.unsaved-changes-dialog'">
  <h2 class="mat-dialog-title space-bottom text-xxl">
    {{ t('title') }}
  </h2>
  <div class="mat-typography mat-dialog-content">
    <p>{{ t('notice') }}</p>
  </div>
  <mat-dialog-actions align="end">
    <button mat-stroked-button color="warn" (click)="leave()">
      {{
        data?.overrideLeaveTransKey ? t(data.overrideLeaveTransKey) : t('leave')
      }}
    </button>
    <button cdkFocusInitial mat-raised-button color="accent" (click)="stay()">
      {{ t('stay') }}
    </button>
  </mat-dialog-actions>
</ng-container>
