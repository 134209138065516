import { createAction, props } from '@ngrx/store';

import {
  Offer,
  Organization,
  OrganizationAuthorizations,
  PremiumAccount,
  WithId,
} from '@arrivage/model/dist/src/model';

export const queryOrganizations = createAction(`[Admin] Query Organizations`);
export const queryOrganizationsSuccess = createAction(
  `[Admin] Query Organizations Success`,
  props<{ organizations: (Organization & WithId)[] }>()
);
export const queryOrganizationsFailure = createAction(
  `[Admin] Query Organizations Failure`,
  props<{ errorMessage: string }>()
);

export const queryAuthorizations = createAction(`[Admin] Query Authorizations`);
export const queryAuthorizationsSuccess = createAction(
  `[Admin] Query Authorizations Success`,
  props<{ authorizations: (OrganizationAuthorizations & WithId)[] }>()
);
export const queryAuthorizationsFailure = createAction(
  `[Admin] Query Authorizations Failure`,
  props<{ errorMessage: string }>()
);

export const queryPremiumAccounts = createAction(
  `[Admin] Query Premium Accounts`
);
export const queryPremiumAccountsSuccess = createAction(
  `[Admin] Query Premium Accounts Success`,
  props<{ premiumAccounts: (PremiumAccount & WithId)[] }>()
);
export const queryPremiumAccountsFailure = createAction(
  `[Admin] Query Premium Accounts Failure`,
  props<{ errorMessage: string }>()
);

export const queryOffers = createAction(`[Admin] Query Offers`);
export const queryOffersSuccess = createAction(
  `[Admin] Query Offers Success`,
  props<{ offers: (Offer & WithId)[] }>()
);
export const queryOffersFailure = createAction(
  `[Admin] Query Offers Failure`,
  props<{ errorMessage: string }>()
);
