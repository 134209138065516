import _ from 'lodash';
import { of } from 'rxjs';
import { DateRangeLogic } from 'src/app/util/date-range/date-range.logic';

import {
  catchError,
  filter,
  map,
  switchMap,
  takeUntil,
  tap,
  withLatestFrom,
} from 'rxjs/operators';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import {
  ActionCreator,
  Store,
  createAction,
  createSelector,
  on,
  props,
} from '@ngrx/store';
import { TypedAction } from '@ngrx/store/src/models';

import { Logout } from '@arrivage-auth/store/auth.actions';
import { TransactionDocumentsMetrics } from '@arrivage-components/metric/metrics.model';
import { BaseInvoiceService } from '@arrivage-invoices/common/services/base-invoice.service';
import { reportError } from '@arrivage-sentry/report-error';
import { SnackbarService } from '@arrivage-snackbar/snackbar.service';
import { getOrganization } from '@arrivage-store/context/context.selectors';
import { EntityFeedback } from '@arrivage-store/feedback/feedback-params.model';
import {
  FirestoreEntityActions,
  FirestoreEntitySelectors,
  FirestoreEntityState,
  LoadingCollectionState,
  createBaseEffects,
  createEntityReducer,
  createSelectors as createEntitySelectors,
  generateActions,
} from '@arrivage-store/generators';
import { State } from '@arrivage-store/state';
import { Invoice, Money, WithId } from '@arrivage/model/dist/src/model';

import { InvoicesUtils } from '../util/invoices.utils';

export interface InvoiceFeedback extends EntityFeedback {
  send_invoice: string;
  publish_invoice: string;
  delete_invoice: string;
  batch_send_invoices: string;
  download_invoice: string;
  cancel_invoice: string;
  mark_as_paid: string;
  mark_as_unpaid: string;
  batch_mark_as_paid: string;
  connect_next_invoice_number: string;
  generate_invoice_pdf: string;
  query_by_date_range: string;
}

export const InvoiceFeedback: InvoiceFeedback = {
  update: 'update_invoice',
  add: 'add_invoice',
  remove: 'remove_invoice',
  query: 'query_all_invoices',
  get_active_item: 'query_single_invoice',
  send_invoice: 'send_invoice',
  publish_invoice: 'publish_invoice',
  delete_invoice: 'delete_invoice',
  batch_send_invoices: 'batch_send_invoices',
  download_invoice: 'download_invoice',
  cancel_invoice: 'cancel_invoice',
  mark_as_paid: 'mark_invoice_as_paid',
  mark_as_unpaid: 'mark_invoice_as_unpaid',
  batch_mark_as_paid: 'batch_mark_invoices_as_paid',
  connect_next_invoice_number: 'connect_next_invoice_number',
  generate_invoice_pdf: 'generate_invoice_pdf',
  query_by_date_range: 'query_invoices_by_date_range',
};
export interface BaseInvoiceState extends FirestoreEntityState<Invoice> {
  dateRange: Interval;
  byDateRange: LoadingCollectionState<Invoice & WithId>;
}

export const invoiceAdapter: EntityAdapter<Invoice & WithId> =
  createEntityAdapter<Invoice & WithId>();

export interface InvoiceSelectors
  extends FirestoreEntitySelectors<Invoice, State> {
  selectByRelationshipId: (
    relationshipId: string
  ) => (state: State) => (Invoice & WithId)[];
  selectDateRange: (state: State) => Interval;
  selectLoadedByDateRange: (state: State) => (Invoice & WithId)[];
  selectIsLoadingByDateRange: (state: State) => boolean;
  selectUnpaidMetrics: (state: State) => TransactionDocumentsMetrics;
  selectByDateRangeState: (
    state: State
  ) => LoadingCollectionState<Invoice & WithId>;
}

export function createSelectors(
  featureSelector: (state) => BaseInvoiceState
): InvoiceSelectors {
  const selectDateRange = createSelector(featureSelector, (s) => s.dateRange);
  const entitySelectors = createEntitySelectors<Invoice, State>(
    invoiceAdapter,
    featureSelector
  );
  const selectUnpaidInvoices = createSelector(
    entitySelectors.selectAll,
    (items) => items.filter((po) => InvoicesUtils.isPublished(po.status))
  );
  return {
    ...entitySelectors,
    selectLoadedByDateRange: createSelector(
      featureSelector,
      (s) => s.byDateRange.items
    ),
    selectIsLoadingByDateRange: createSelector(
      featureSelector,
      (s) => s.byDateRange.isLoading
    ),
    selectDateRange: selectDateRange,
    selectByDateRangeState: createSelector(
      featureSelector,
      (s) => s.byDateRange
    ),
    selectByRelationshipId: (relationshipId: string) =>
      createSelector(entitySelectors.selectAll, (items: (Invoice & WithId)[]) =>
        items.filter((x) => x.relationshipId === relationshipId)
      ),
    selectUnpaidMetrics: createSelector(
      selectUnpaidInvoices,
      extractInvoicesMetrics
    ),
  };
}

export function createCommonInvoiceReducer<S extends BaseInvoiceState>(
  actions: InvoiceActions,
  adapter: EntityAdapter<Invoice & WithId>
): any[] {
  return [
    ...createEntityReducer(actions, adapter),
    on(actions.queryByDateRange, (state: S, p) => {
      return {
        ...state,
        byDateRange: {
          ...state.byDateRange,
          items: [],
          isLoading: true,
          error: undefined,
        },
      };
    }),
    on(actions.queryByDateRangeSuccess, (state: S, p) => {
      return {
        ...state,
        dateRange: p.interval,
        byDateRange: {
          ...state.byDateRange,
          items: p.items,
          isLoading: false,
          error: undefined,
        },
      };
    }),
    on(actions.queryByDateRangeFailure, (state: S, p) => {
      return {
        ...state,
        byDateRange: {
          ...state.byDateRange,
          items: [],
          isLoading: false,
          error: p.error,
        },
      };
    }),
  ];
}
export interface InvoiceActions extends FirestoreEntityActions<Invoice> {
  queryByDateRangeGuard: ActionCreator<
    string,
    (props: { newDateRange: Interval }) => {
      newDateRange: Interval;
    } & TypedAction<string>
  >;
  queryByDateRange: ActionCreator<
    string,
    (props: {
      interval: Interval;
    }) => { interval: Interval } & TypedAction<string>
  >;
  queryByDateRangeSuccess: ActionCreator<
    string,
    (props: { items: (Invoice & WithId)[]; interval: Interval }) => {
      items: (Invoice & WithId)[];
      interval: Interval;
    } & TypedAction<string>
  >;
  queryByDateRangeFailure: ActionCreator<
    string,
    (props: { error: string }) => { error: string } & TypedAction<string>
  >;
}

export function generateInvoiceActions(featureName: string): InvoiceActions {
  return {
    ...generateActions(featureName),
    queryByDateRangeGuard: createAction(
      `[${featureName}] Query by date range Guard`,
      props<{ newDateRange: Interval }>()
    ),
    queryByDateRange: createAction(
      `[${featureName}] Query by date range`,
      props<{ interval: Interval }>()
    ),
    queryByDateRangeSuccess: createAction(
      `[${featureName}] Query by date range success`,
      props<{ items: (Invoice & WithId)[]; interval: Interval }>()
    ),
    queryByDateRangeFailure: createAction(
      `[${featureName}] Query by date range failure`,
      props<{ error: string }>()
    ),
  };
}
export function createBaseInvoiceEffects(
  actions$: Actions,
  store: Store<State>,
  invoiceActions: InvoiceActions,
  selectors: InvoiceSelectors,
  service: BaseInvoiceService,
  snackbarService: SnackbarService
) {
  const entityEffects = createBaseEffects(
    actions$,
    store,
    invoiceActions,
    selectors,
    service,
    InvoiceFeedback,
    snackbarService
  );

  return {
    ...entityEffects,
    queryByDateRangeGuard: createQueryByDateRangeGuardEffect(
      actions$,
      store,
      invoiceActions,
      selectors
    ),
    queryByDateRange: createQueryByDateRangeEffect(
      actions$,
      store,
      invoiceActions,
      service
    ),
    displayQueryByDateRangeFailure: createDisplayQueryByDateRangeFailureEffect(
      actions$,
      invoiceActions,
      InvoiceFeedback,
      snackbarService
    ),
  };
}

export function createQueryByDateRangeGuardEffect(
  actions$: Actions,
  store: Store<State>,
  actions: InvoiceActions,
  selectors: InvoiceSelectors
) {
  return createEffect(() =>
    actions$.pipe(
      ofType(actions.queryByDateRangeGuard),
      withLatestFrom(store.select(selectors.selectDateRange)),
      map(([action, currentDateRange]) => {
        return DateRangeLogic.getNewDateRange({
          currentDateRange: currentDateRange,
          newDateRange: action.newDateRange,
        });
      }),
      filter((newInterval) => !!newInterval),
      map((newInterval) => {
        return actions.queryByDateRange({ interval: newInterval });
      })
    )
  );
}

export function createQueryByDateRangeEffect(
  actions$: Actions,
  store: Store<State>,
  actions: InvoiceActions,
  service: BaseInvoiceService
) {
  return createEffect(() =>
    actions$.pipe(
      ofType(actions.queryByDateRange),
      withLatestFrom(store.select(getOrganization)),
      switchMap(([context, organization]) => {
        return service.list(organization.id, context.interval).pipe(
          takeUntil(actions$.pipe(ofType(Logout))),
          map((result) => {
            return actions.queryByDateRangeSuccess({
              items: result,
              interval: context.interval,
            });
          }),
          catchError((e) => {
            reportError(e);
            return of(actions.queryByDateRangeFailure(e));
          })
        );
      })
    )
  );
}

export function createDisplayQueryByDateRangeFailureEffect(
  actions$: Actions,
  actions: InvoiceActions,
  feedBackType: InvoiceFeedback,
  snackbarService: SnackbarService
) {
  return createEffect(
    () =>
      actions$.pipe(
        ofType(actions.queryByDateRangeFailure),
        tap((x) => snackbarService.showError(feedBackType.query_by_date_range))
      ),
    { dispatch: false }
  );
}
export function extractInvoicesMetrics(items: Invoice[]) {
  const totalDraft = Money.sum(_.map(items, (i) => i.total));

  return {
    nb: items.length,
    total: totalDraft,
  };
}
