import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';

import { AdminUtils } from '@arrivage-admin/utils/admin.utils';
import { LangUtils } from '@arrivage-util/lang.utils';
import { Organization, WithId } from '@arrivage/model/dist/src/model';

@Component({
  selector: 'app-organization-selector',
  templateUrl: './organization-selector.component.html',
  styleUrls: ['./organization-selector.component.scss'],
})
export class OrganizationSelectorComponent implements OnInit {
  private _sortedOrganizations: (Organization & WithId)[] = [];
  displayedOrganizations: (Organization & WithId)[] = [];
  selected = 'currentOrganization';

  form = this.fb.group({ nameFilter: [null] });

  @Input()
  set organizations(organizations: (Organization & WithId)[]) {
    this._sortedOrganizations = LangUtils.normalizedSortBy(
      organizations,
      (o) => o.name
    );
  }

  get organizations(): (Organization & WithId)[] {
    return this._sortedOrganizations.filter(
      (o) => o.id !== this.currentOrganization?.id
    );
  }

  @Input()
  currentOrganization?: Organization & WithId;

  @Output()
  selectOrganization = new EventEmitter<string>();

  constructor(private fb: UntypedFormBuilder) {}

  ngOnInit(): void {
    this.displayedOrganizations = this.organizations;
  }

  onSelectionChange(option: MatSelectChange) {
    if (option) {
      this.selectOrganization.emit(option.value);
    }
  }

  search(value: string) {
    this.displayedOrganizations = this.organizations.filter((orga) =>
      LangUtils.normalizedIncludes(orga.name, value)
    );
  }

  testAdmin(value: KeyboardEvent) {
    const searchInput = (value.target as HTMLInputElement).value;
    AdminUtils.isAdminCode(searchInput);
  }

  clearInput() {
    this.form.controls['nameFilter'].reset();
    this.search('');
  }
}
