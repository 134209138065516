import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-expansion-menu',
  templateUrl: './expansion-menu.component.html',
  styleUrls: ['./expansion-menu.component.scss'],
})
export class ExpansionMenuComponent {
  @Input()
  titleKey: string;

  @Input()
  indicator: boolean;

  @Input()
  isNavbarExpanded: boolean = true;

  @Input()
  isPanelExpanded: boolean = false;

  constructor() {}
}
