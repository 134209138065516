import { Injectable } from '@angular/core';

import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { SnackbarService } from '@arrivage-snackbar/snackbar.service';
import { EntityFeedback } from '@arrivage-store/feedback/feedback-params.model';
import { createBaseEffects } from '@arrivage-store/generators';

import { PriceListService } from '../../services/price-list/price-list.service';
import * as priceListAction from './price-list.actions';
import * as priceListSelectors from './price-list.selectors';
import { State } from './price-list.state';

interface PriceListFeedback extends EntityFeedback {
  duplicate: string;
  EXPORT_CSV_ERROR: string;
  SET_PUBLIC: string;
  SEND_PRODUCER_OFFER: string;
  SEND_PRODUCER_OFFER_ERROR: string;
}
export const PriceListFeedback: PriceListFeedback = {
  add: 'add_price_list',
  duplicate: 'duplicate_price_list',
  update: 'update_price_list',
  remove: 'remove_price_list',
  SET_PUBLIC: 'set_public',
  //error
  query: 'query_price_list',
  get_active_item: 'get_active_price_list',
  EXPORT_CSV_ERROR: 'export_csv',
  SEND_PRODUCER_OFFER: 'send_producer_offer',
  SEND_PRODUCER_OFFER_ERROR: 'send_producer_offer_error',
};

@Injectable()
export class PriceListEffects {
  query$;
  add$;
  set$;
  update$;
  remove$;

  getActiveItem$;
  displayQueryFailure$;

  displayGetActiveItemFailure$;

  constructor(
    private actions$: Actions,
    private store: Store<State>,
    private service: PriceListService,
    private snackbarService: SnackbarService
  ) {
    ({
      query: this.query$,
      add: this.add$,
      set: this.set$,
      update: this.update$,
      remove: this.remove$,
      getActiveItem: this.getActiveItem$,
      displayQueryFailure: this.displayQueryFailure$,
      displayGetActiveItemFailure: this.displayGetActiveItemFailure$,
    } = createBaseEffects(
      this.actions$,
      this.store,
      priceListAction,
      priceListSelectors,
      this.service,
      PriceListFeedback,
      this.snackbarService
    ));
  }
}
