import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { ConversationsEffects } from './conversations.effects';
import { reducer } from './conversations.reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('conversations', reducer),
    EffectsModule.forFeature([ConversationsEffects]),
  ],
})
export class ConversationStoreModule {}
