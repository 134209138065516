import { from } from 'rxjs';

import { Injectable } from '@angular/core';
import { Functions, httpsCallable } from '@angular/fire/functions';

import { map, retryWhen, take } from 'rxjs/operators';

import { genericRetryStrategy } from '@arrivage-util/api.utils';
import {
  CreatePriceListRequestData,
  DeletePriceListRequestData,
  DuplicatePriceListRequestData,
  UpdatePriceListRequestData,
} from '@arrivage/model/dist/src/cloud-functions-api';
import {
  CustomerOrganizationType,
  PriceList,
} from '@arrivage/model/dist/src/model';

export interface SetPublicPriceListsData {
  organizationId: string;
  changes: {
    priceListId: string | null;
    visibility: CustomerOrganizationType;
  }[];
}

@Injectable({
  providedIn: 'root',
})
export class PriceListApiService {
  private readonly createPriceListCloudFunction = httpsCallable<
    CreatePriceListRequestData,
    string
  >(this.functions, 'createPriceList');

  private readonly deletePriceListCloudFunction = httpsCallable<
    DeletePriceListRequestData,
    void
  >(this.functions, 'deletePriceList');

  private readonly duplicatePriceListCloudFunction = httpsCallable<
    DuplicatePriceListRequestData,
    string
  >(this.functions, 'duplicatePriceList');

  private readonly setPublicPriceListsCloudFunction = httpsCallable<
    SetPublicPriceListsData,
    void
  >(this.functions, 'setPublicPriceLists');

  private readonly updatePriceListCloudFunction = httpsCallable<
    UpdatePriceListRequestData,
    string
  >(this.functions, 'updatePriceList');

  constructor(private functions: Functions) {}

  createPriceList(
    organizationId: string,
    priceList: PriceList
  ): Promise<string> {
    return from(
      this.createPriceListCloudFunction({
        organizationId: organizationId,
        priceList: priceList,
      })
    )
      .pipe(
        retryWhen(genericRetryStrategy()),
        take(1),
        map((r) => r.data)
      )
      .toPromise();
  }

  createPriceListFromForm(
    createPriceListRequestData: CreatePriceListRequestData
  ): Promise<string> {
    return from(this.createPriceListCloudFunction(createPriceListRequestData))
      .pipe(
        retryWhen(genericRetryStrategy()),
        take(1),
        map((r) => r.data)
      )
      .toPromise();
  }

  /**
   * !IMPORTANT!
   *
   * The cloud function will update the related offers but assumes all price list items have been passed in the argument.
   * Make sure you pass all of the price list items, even those which haven't changed otherwise the offer will be incomplete.
   */
  updatePriceListFromForm(
    updatePriceListRequestData: UpdatePriceListRequestData
  ): Promise<string> {
    return from(this.updatePriceListCloudFunction(updatePriceListRequestData))
      .pipe(
        retryWhen(genericRetryStrategy()),
        take(1),
        map((r) => r.data)
      )
      .toPromise();
  }

  deletePriceList(organizationId: string, priceListId: string): Promise<void> {
    return from(
      this.deletePriceListCloudFunction({
        organizationId: organizationId,
        priceListId: priceListId,
      })
    )
      .pipe(
        retryWhen(genericRetryStrategy()),
        take(1),
        map((r) => r.data)
      )
      .toPromise();
  }

  duplicatePriceList(
    organizationId: string,
    priceList: PriceList,
    priceListIdToDuplicate: string
  ): Promise<string> {
    return from(
      this.duplicatePriceListCloudFunction({
        organizationId,
        priceList,
        priceListIdToDuplicate,
      })
    )
      .pipe(
        retryWhen(genericRetryStrategy()),
        take(1),
        map((r) => r.data)
      )
      .toPromise();
  }

  setPublicPriceLists(data: SetPublicPriceListsData) {
    return from(this.setPublicPriceListsCloudFunction(data))
      .pipe(
        retryWhen(genericRetryStrategy()),
        take(1),
        map((r) => r.data)
      )
      .toPromise();
  }
}
