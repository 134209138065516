import { Observable } from 'rxjs';

import { Injectable } from '@angular/core';
import { Firestore, where } from '@angular/fire/firestore';

import { EntityChangeAction } from '@arrivage-services/organization-entity.service';
import { Delivery, WithId } from '@arrivage/model/dist/src/model';

import { DeliveryService } from '../../common/services/delivery.service';

@Injectable({
  providedIn: 'root',
})
export class VendorDeliveryService extends DeliveryService {
  static readonly ORGANIZATIONS_COLLECTION = 'organizations';

  constructor(firestore: Firestore) {
    super(firestore);
  }

  override get(organizationId: string, recordId: string): Observable<Delivery & WithId> {
    return this._get(
      this.pathSpec(),
      recordId,
      DeliveryService.convertTimestampFields
    );
  }

  override create(organizationId: string, delivery: Delivery): Promise<string> {
    return this._create(this.pathSpec(), delivery);
  }

  override set(organizationId: string, id: string, delivery: Delivery): Promise<void> {
    return this._set(this.pathSpec(), id, delivery);
  }

  override update(
    organizationId: string,
    deliveryId: string,
    delivery: Partial<Delivery>
  ): Promise<void> {
    return this._update(this.pathSpec(), deliveryId, delivery);
  }

  override connect(organizationId: string): Observable<EntityChangeAction<Delivery>[]> {
    return this._connect(
      this.pathSpec(),
      DeliveryService.convertTimestampFields,
      null,
      where('organizationId', '==', organizationId)
    );
  }

  list(organizationId: string): Observable<(Delivery & WithId)[]> {
    return this._list(
      this.pathSpec(),
      DeliveryService.convertTimestampFields,
      where('organizationId', '==', organizationId)
    );
  }

  override remove(organizationId: string, deliveryId: string): Promise<void> {
    return this._delete(this.pathSpec(), deliveryId);
  }
}
