import { MaterialModule } from 'src/app/material.module';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { TranslocoModule } from '@jsverse/transloco';

import { AddEmailDialogModule } from '@arrivage-components/add-email-dialog/add-email-dialog.module';
import { OptionCreateInputModule } from '@arrivage-components/option-create-input/option-create-input.module';

import { CommunicationPreferenceTableComponent } from './communication-preference-table.component';

@NgModule({
  declarations: [CommunicationPreferenceTableComponent],
  imports: [
    CommonModule,
    MaterialModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    TranslocoModule,
    OptionCreateInputModule,
    AddEmailDialogModule,
  ],
  exports: [CommunicationPreferenceTableComponent],
})
export class CommunicationPreferenceTableModule {}
