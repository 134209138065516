<ng-container *transloco="let t; read: 'add-email-dialog'">
  <h2 class="mat-dialog-title text-xxl">{{ t('title') }}</h2>
  <div class="mat-typography mat-dialog-content space-top">
    <mat-form-field appearance="outline" fxFlexAlign="center center">
      <mat-label>{{ t('form-label') }}</mat-label>
      <input
        type="text"
        matInput
        data-testid="add-new-email-input-testid"
        [formControl]="addEmailForm"
      />
      <mat-error *ngIf="addEmailForm.hasError('email')">{{
        t('email-invalid')
      }}</mat-error>
      <mat-error *ngIf="addEmailForm.hasError('required')">{{
        t('email-required')
      }}</mat-error>
      <mat-error *ngIf="addEmailForm.hasError(ALREADY_EXISTS_ERROR)">{{
        t('email-already-exists')
      }}</mat-error>
    </mat-form-field>
  </div>
  <mat-dialog-actions align="end">
    <button mat-button type="button" [mat-dialog-close]="false">
      {{ t('cancel') }}
    </button>
    <button
      mat-raised-button
      type="submit"
      color="accent"
      data-testid="save-new-email-button-testid"
      (click)="onSubmit()"
    >
      {{ t('save') }}
    </button>
  </mat-dialog-actions>
</ng-container>
