import { Observable } from 'rxjs';

import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';

import { updateUser } from '@arrivage-store/context/context.actions';
import { getUser } from '@arrivage-store/context/context.selectors';
import { State } from '@arrivage-store/state';
import { User, WithId } from '@arrivage/model/dist/src/model';

import { SaveUserWithPictureData } from '../model/user-data.models';

@Injectable({
  providedIn: 'root',
})
export class UserFacade {
  private currentUser$: Observable<User & WithId>;

  constructor(private store: Store<State>) {
    this.currentUser$ = this.store.select(getUser);
  }

  getCurrentUser(): Observable<User & WithId> {
    return this.currentUser$;
  }

  updateUser(value: {
    user: Partial<User> & WithId;
    data?: SaveUserWithPictureData;
  }) {
    return new Promise((resolve, reject) => {
      this.store.dispatch(
        updateUser({
          record: {
            user: value.user,
            newPictureFile: value.data?.newPictureFile,
          },
          confirmation: {
            resolve: resolve,
            reject: reject,
          },
        })
      );
    });
  }
}
