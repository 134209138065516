import { from } from 'rxjs';

import { Injectable } from '@angular/core';
import { Functions, httpsCallable } from '@angular/fire/functions';

import { map, retryWhen } from 'rxjs/operators';

import { genericRetryStrategy } from '@arrivage-util/api.utils';
import {
  CreateFormatRequestData,
  DeleteFormatRequestData,
} from '@arrivage/model/dist/src/cloud-functions-api';

@Injectable({ providedIn: 'root' })
export class FormatApiService {
  private readonly createFormatCloudFunction = httpsCallable<
    CreateFormatRequestData,
    string
  >(this.functions, 'createFormat');
  private readonly deleteFormatCloudFunction = httpsCallable<
    DeleteFormatRequestData,
    string
  >(this.functions, 'deleteFormat');

  constructor(private functions: Functions) {}

  createFormat(request: CreateFormatRequestData) {
    return from(this.createFormatCloudFunction(request)).pipe(
      retryWhen(genericRetryStrategy()),
      map((r) => r.data)
    );
  }

  deleteFormat(request: DeleteFormatRequestData) {
    return from(this.deleteFormatCloudFunction(request)).pipe(
      retryWhen(genericRetryStrategy()),
      map((r) => r.data)
    );
  }
}
