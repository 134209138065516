import { ICreatePDF } from 'pdfmake-wrapper';

export interface PDFCreatedInfo {
  pdf: ICreatePDF;
  pdfName: string;
}

// Pdf types displayed to the user.
// DELIVERY_LIST is a pdf type created only to be sent in the e-mail to producers
// that is why it is not included in this enum
export enum ScheduledDeliveriesPdfType {
  PICK_LIST = 'pick_list',
  DELIVERY_SLIP = 'delivery_slip',
  PACK_LIST = 'pack_list',
  DELIVERY_REPORT = 'delivery_report',
}

export enum PurchaseOrderPdfType {
  PURCHASE_ORDER = 'purchase_order',
  INVOICE = 'invoice',
}

export enum OtherPdfType {
  // Pdf that may contain multiple ScheduledDeliveriesPdfType and that will be sent in the e-mail to producers
  DELIVERY_LIST = 'delivery_list',
}

export type PdfType =
  | ScheduledDeliveriesPdfType
  | PurchaseOrderPdfType
  | OtherPdfType;
