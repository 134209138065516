import { MaterialModule } from 'src/app/material.module';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';

import { TranslocoModule } from '@jsverse/transloco';

import { FilteredDateRangePickerModule } from '@arrivage-components/filtered-date-range-picker/filtered-date-range-picker.module';

import { FilteredDateRangePickerDialogComponent } from './filtered-date-range-picker-dialog.component';

@NgModule({
  declarations: [FilteredDateRangePickerDialogComponent],
  imports: [
    CommonModule,
    MaterialModule,
    TranslocoModule,
    FlexLayoutModule,
    FilteredDateRangePickerModule,
  ],
  exports: [FilteredDateRangePickerDialogComponent],
})
export class FilteredDateRangePickerDialogModule {}
