import * as _ from 'lodash';
import { Observable } from 'rxjs';

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { map } from 'rxjs/operators';

import { Store } from '@ngrx/store';

import * as fromContext from '@arrivage-store/context/context.selectors';
import { State } from '@arrivage-store/state';
import { RoleType } from '@arrivage/model/dist/src/model';

@Injectable({
  providedIn: 'root',
})
export class AdminGuard  {
  constructor(private store: Store<State>) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.store
      .select(fromContext.getMembership)
      .pipe(
        map((membership) =>
          membership ? membership.roles.includes(RoleType.ADMINISTRATOR) : false
        )
      );
  }
}
