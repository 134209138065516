import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

import { HashMap } from '@jsverse/transloco';

import { Errors } from '@arrivage-store/feedback/feedback-params.model';

import { CustomSnackbarComponent } from './custom-snackbar/custom-snackbar.component';
import { SnackbarErrorComponent } from './snackbar-error/snackbar-error.component';
import { SnackbarSuccessComponent } from './snackbar-success/snackbar-success.component';

@Injectable({
  providedIn: 'root',
})
export class SnackbarService {
  static DEFAULT_DURATION = 6000;

  constructor(private snackbar: MatSnackBar) {}

  showError(action: Errors | string, messageParameters?: HashMap) {
    this.snackbar.openFromComponent(SnackbarErrorComponent, {
      data: {
        message: action,
        close: () => {
          this.snackbar.dismiss();
        },
        messageParameters,
      },
      duration: SnackbarService.DEFAULT_DURATION,
      panelClass: ['snackbar-error'],
    });
  }

  showSuccess(action: string, messageParameters?: HashMap) {
    this.snackbar.openFromComponent(SnackbarSuccessComponent, {
      data: {
        message: action,
        close: () => {
          this.snackbar.dismiss();
        },
        messageParameters,
      },
      duration: SnackbarService.DEFAULT_DURATION,
      panelClass: ['snackbar-success'],
    });
  }

  showSnackbar(
    message: string,
    icon?: string,
    duration: number = SnackbarService.DEFAULT_DURATION,
    panelClass?: string[],
    messageParameters?: HashMap
  ) {
    this.snackbar.openFromComponent(CustomSnackbarComponent, {
      data: {
        message: message,
        icon: icon,
        messageParameters,
      },
      duration: duration ?? null,
      panelClass: panelClass,
    });
  }
}
