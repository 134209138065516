import { EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { createReducer, Action } from '@ngrx/store';

import {
  createEntityReducer,
  createInitialState,
} from '@arrivage-store/generators';
import { WithId } from '@arrivage/model/dist/src/model';
import { Delivery } from '@arrivage/model/dist/src/model';

import * as DeliveryActions from './vendor-delivery.actions';
import { VendorDeliveryState } from './vendor-delivery.state';

export const adapter: EntityAdapter<Delivery & WithId> = createEntityAdapter<
  Delivery & WithId
>();

export const initialState: VendorDeliveryState = createInitialState(adapter);

const deliveryReducer = createReducer(
  initialState,
  ...createEntityReducer(DeliveryActions, adapter)
);

export function reducer(
  state: VendorDeliveryState | undefined,
  action: Action
) {
  return deliveryReducer(state, action);
}
