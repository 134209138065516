import { generateActions } from '@arrivage-store/generators';
import { Offer } from '@arrivage/model/dist/src/model';

import { FEATURE_NAME } from './visible-offers-store.constants';

export const {
  query,
  connected,
  add,
  added,
  set,
  modified,
  update,
  remove,
  removed,
  queryFailure,
  addSuccess,
  addFailure,
  setSuccess,
  setFailure,
  updateSuccess,
  updateFailure,
  removeSuccess,
  removeFailure,
  getActiveItem,
  getActiveItemFailure,
  getActiveItemSuccess,
} = generateActions<Offer>(FEATURE_NAME);
