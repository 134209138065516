import { firstValueFrom, from } from 'rxjs';

import { Injectable } from '@angular/core';
import { Functions, httpsCallable } from '@angular/fire/functions';

import { map, retry, take } from 'rxjs/operators';

import { genericRetryStrategy } from '@arrivage-util/api.utils';
import {
  AddCustomerToRelationshipRequestData,
  CreateCustomerProviderRelationshipRequestData,
  CreateHubProducerRelationshipRequestData,
} from '@arrivage/model/dist/src/cloud-functions-api';

@Injectable({
  providedIn: 'root',
})
export class RelationshipsApiService {
  private readonly addCustomerToRelationshipCloudFunction = httpsCallable<
    AddCustomerToRelationshipRequestData,
    string
  >(this.functions, 'addCustomerToRelationship');

  private readonly createCustomerProviderRelationshipCloudFunction =
    httpsCallable<CreateCustomerProviderRelationshipRequestData, string>(
      this.functions,
      'createCustomerProviderRelationship'
    );

  private readonly createHubProducerRelationshipCloudFunction = httpsCallable<
    CreateHubProducerRelationshipRequestData,
    string
  >(this.functions, 'createHubProducerRelationship');

  constructor(private functions: Functions) {}

  addCustomerToRelationship(
    requestData: AddCustomerToRelationshipRequestData
  ): Promise<string> {
    return firstValueFrom(
      from(this.addCustomerToRelationshipCloudFunction(requestData)).pipe(
        retry({ delay: genericRetryStrategy() }),
        take(1),
        map((r) => r.data)
      )
    );
  }

  createCustomerProviderRelationship(
    requestData: CreateCustomerProviderRelationshipRequestData
  ): Promise<string> {
    return firstValueFrom(
      from(
        this.createCustomerProviderRelationshipCloudFunction(requestData)
      ).pipe(
        retry({ delay: genericRetryStrategy() }),
        take(1),
        map((r) => r.data)
      )
    );
  }

  createHubProducerRelationship(
    requestData: CreateHubProducerRelationshipRequestData
  ): Promise<string> {
    return firstValueFrom(
      from(this.createHubProducerRelationshipCloudFunction(requestData)).pipe(
        retry({ delay: genericRetryStrategy() }),
        take(1),
        map((r) => r.data)
      )
    );
  }
}
