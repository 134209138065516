import { EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import {
  createInitialState,
  createEntityReducer,
} from '@arrivage-store/generators';
import { WithId, ContactRequest } from '@arrivage/model/dist/src/model';

import * as actions from './received-contact-requests.actions';
import { ReceivedContactRequestState } from './received-contact-requests.state';

export const adapter: EntityAdapter<
  ContactRequest & WithId
> = createEntityAdapter<ContactRequest & WithId>();

export const initialState: ReceivedContactRequestState = createInitialState(
  adapter,
  {
    activeContactRequest: undefined,
    isLoadingActiveContactRequest: false,
    activeContactRequestError: undefined,
  }
);

const contactRequestsReducer = createReducer(
  initialState,
  ...createEntityReducer<ContactRequest>(actions, adapter),
  on(actions.getContactRequest, (state: ReceivedContactRequestState) => {
    return {
      ...state,
      activeContactRequest: undefined,
      isLoadingActiveContactRequest: true,
      activeContactRequestError: undefined,
    };
  }),
  on(
    actions.getContactRequestSuccess,
    (state: ReceivedContactRequestState, action) => {
      return {
        ...state,
        activeContactRequest: { ...action.contactRequest },
        isLoadingActiveContactRequest: false,
        activeContactRequestError: undefined,
      };
    }
  ),
  on(
    actions.getContactRequestFailure,
    (state: ReceivedContactRequestState, action) => {
      return {
        ...state,
        activeContactRequest: undefined,
        isLoadingActiveContactRequest: false,
        activeContactRequestError: action.error,
      };
    }
  )
);

export function reducer(
  state: ReceivedContactRequestState | undefined,
  action: Action
) {
  return contactRequestsReducer(state, action);
}
