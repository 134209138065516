import { Observable } from 'rxjs';

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { map } from 'rxjs/operators';

@Component({
  selector: 'app-page-error',
  templateUrl: './page-error.component.html',
  styleUrls: ['./page-error.component.scss'],
})
export class PageErrorComponent implements OnInit {
  constructor(private route: ActivatedRoute) {}
  errorCode$: Observable<string>;
  ngOnInit() {
    this.errorCode$ = this.route.data.pipe(map((x) => x['errorCode']));
  }
}
