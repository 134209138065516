import { TranslocoModule } from '@jsverse/transloco';
import { ImageCropperModule } from 'ngx-image-cropper';
import { AppUtilsModule } from 'src/app/app-utils.module';
import { MaterialModule } from 'src/app/material.module';

import { LayoutModule } from '@angular/cdk/layout';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { PictureCropperEditorDialogModule } from '../picture-cropper-editor-dialog/picture-cropper-editor-dialog.module';
import { UploadPictureComponent } from './upload-picture.component';

@NgModule({
  declarations: [UploadPictureComponent],
  imports: [
    CommonModule,
    MaterialModule,
    FlexLayoutModule,
    LayoutModule,
    FormsModule,
    ReactiveFormsModule,
    TranslocoModule,
    ImageCropperModule,
    PictureCropperEditorDialogModule,
    AppUtilsModule,
  ],
  exports: [UploadPictureComponent],
})
export class UploadPictureModule {}
