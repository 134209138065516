import { createFeatureSelector, createSelector } from '@ngrx/store';

import { createSelectors } from '@arrivage-store/generators';
import { selectRouteParam } from '@arrivage-store/routing/router-state.selectors';
import { Relationship } from '@arrivage/model/dist/src/model';

import { adapter } from './relationship.reducer';
import { RelationshipsState, State } from './relationship.state';

export const getRelationshipsState =
  createFeatureSelector<RelationshipsState>('relationships');

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
  connecting,
  connected,
  connectState,
  queryFailure,
  addFailure,
  setFailure,
  updateFailure,
  removeFailure,
  getById,
  selectActiveItem,
  isLoadingActiveItem,
  selectActiveItemState,
} = createSelectors<Relationship, State>(adapter, getRelationshipsState);

export const selectCurrentRelationId = createSelector(
  selectRouteParam('relationshipId'),
  (customerId) => {
    return customerId;
  }
);
