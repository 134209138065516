import {
  Directive,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  Renderer2,
} from '@angular/core';

@Directive({
  selector: '[customTooltip]',
})
export class CustomTooltipDirective implements OnDestroy {
  @Input('customTooltip') tooltipTitle: string;
  @Input() tooltipPosition: 'top' | 'right' | 'bottom' | 'left';
  @Input() tooltipDelay: number;
  @Input() tooltipDisabled: boolean = false;
  @Input() tooltipColor: string;
  @Input() tooltipWidth?: string = undefined;
  @Input() tooltipNormalWhiteSpace: boolean = false;

  tooltip: HTMLElement;
  offset = 10;

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngOnDestroy(): void {
    if (this.tooltip) {
      this.tooltip.remove();
    }
  }

  @HostListener('mouseenter')
  onMouseEnter() {
    if (!this.tooltip && this.tooltipTitle && !this.tooltipDisabled) {
      this.show();
    }
  }

  @HostListener('mouseleave') onMouseLeave() {
    if (this.tooltip) {
      this.hide();
    }
  }

  @HostListener('click') onClick() {
    if (this.tooltip) {
      this.tooltip.remove();
    }
  }

  show() {
    this.create();
    this.setPosition();
    this.renderer.addClass(this.tooltip, 'ng-tooltip-show');
  }

  hide() {
    this.renderer.removeClass(this.tooltip, 'ng-tooltip-show');
    window.setTimeout(() => {
      this.renderer.removeChild(document.body, this.tooltip);
      this.tooltip = null;
    }, this.tooltipDelay);
  }

  create() {
    this.tooltip = this.renderer.createElement('span');

    this.renderer.appendChild(
      this.tooltip,
      this.renderer.createText(this.tooltipTitle)
    );

    this.renderer.appendChild(document.body, this.tooltip);

    this.renderer.addClass(this.tooltip, 'ng-tooltip');
    this.renderer.addClass(this.tooltip, `ng-tooltip-${this.tooltipPosition}`);
    this.renderer.addClass(this.tooltip, `ng-tooltip-${this.tooltipColor}`);

    this.renderer.setStyle(
      this.tooltip,
      '-webkit-transition',
      `opacity ${this.tooltipDelay}ms`
    );
    this.renderer.setStyle(
      this.tooltip,
      '-moz-transition',
      `opacity ${this.tooltipDelay}ms`
    );
    this.renderer.setStyle(
      this.tooltip,
      '-o-transition',
      `opacity ${this.tooltipDelay}ms`
    );
    this.renderer.setStyle(
      this.tooltip,
      'transition',
      `opacity ${this.tooltipDelay}ms`
    );

    if (this.tooltipNormalWhiteSpace) {
      this.renderer.setStyle(this.tooltip, 'white-space', 'normal');
    }

    if (this.tooltipWidth) {
      this.renderer.setStyle(this.tooltip, 'min-width', this.tooltipWidth);
      this.renderer.setStyle(this.tooltip, 'max-width', this.tooltipWidth);
    }
  }

  setPosition() {
    const hostPos = this.el.nativeElement.getBoundingClientRect();

    const tooltipPos = this.tooltip.getBoundingClientRect();

    const scrollPos =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop ||
      0;

    let top, left;

    switch (this.tooltipPosition) {
      case 'top':
        top = hostPos.top - tooltipPos.height - this.offset;
        left = hostPos.left + (hostPos.width - tooltipPos.width) / 2;
        break;
      case 'right':
        top = hostPos.top + (hostPos.height - tooltipPos.height) / 2;
        left = hostPos.right + this.offset;
        break;
      case 'left':
        top = hostPos.top + (hostPos.height - tooltipPos.height) / 2;
        left = hostPos.left - tooltipPos.width - this.offset;
        break;
      default:
        top = hostPos.bottom + this.offset;
        left = hostPos.left + (hostPos.width - tooltipPos.width) / 2;
    }

    this.renderer.setStyle(this.tooltip, 'top', `${top + scrollPos}px`);
    this.renderer.setStyle(this.tooltip, 'left', `${left}px`);
  }
}
