import _ from 'lodash';

import { createFeatureSelector, createSelector } from '@ngrx/store';

import {
  authorizationsAdapter,
  offersAdapter,
  organizationsAdapter,
  premiumAccountsAdapter,
} from './admin.reducers';
import {
  AdminOffersState,
  AdminOrganizationAuthorizationsState,
  AdminOrganizationState,
  AdminPremiumAccountsState,
} from './admin.state';

// ORGANIZATIONS SELECTORS

export const getAdminOrganizationsState =
  createFeatureSelector<AdminOrganizationState>('adminOrganizations');

export const {
  selectIds: selectOrganizationIds,
  selectEntities: selectOrganizationEntities,
  selectAll: selectOrganizationAll,
  selectTotal: selectOrganizationTotal,
} = organizationsAdapter.getSelectors();

export const getAllOrganizations = createSelector(
  getAdminOrganizationsState,
  selectOrganizationAll
);

export const getAllActiveOrganizations = createSelector(
  getAllOrganizations,
  (organizations) => _.filter(organizations, (o) => o.isArchived !== true)
);

export const getAllSellers = createSelector(
  getAllActiveOrganizations,
  (organizations) => _.filter(organizations, (o) => o.isSeller)
);

export const getAllBuyers = createSelector(
  getAllActiveOrganizations,
  (organizations) => _.filter(organizations, (o) => o.isBuyer)
);

export const organizationsLoaded = createSelector(
  getAdminOrganizationsState,
  (state) => {
    return state.loaded;
  }
);

export const organizationsLoadingError = createSelector(
  getAdminOrganizationsState,
  (state) => {
    return state.error;
  }
);

// AUTHORIZATIONS SELECTORS

export const getAdminOrganizationAuthorizationsState =
  createFeatureSelector<AdminOrganizationAuthorizationsState>(
    'adminAuthorizations'
  );

export const {
  selectIds: selectOrganizationAuthorizationIds,
  selectEntities: selectOrganizationAuthorizationEntities,
  selectAll: selectOrganizationAuthorizationAll,
  selectTotal: selectOrganizationAuthorizationTotal,
} = authorizationsAdapter.getSelectors();

export const getAllAuthorizationsById = createSelector(
  getAdminOrganizationAuthorizationsState,
  selectOrganizationAuthorizationEntities
);

export const organizationAuthorizationsLoaded = createSelector(
  getAdminOrganizationAuthorizationsState,
  (state) => {
    return state.loaded;
  }
);

export const organizationAuthorizationsLoadingError = createSelector(
  getAdminOrganizationAuthorizationsState,
  (state) => {
    return state.error;
  }
);

// PREMIUM ACCOUNTS SELECTOR

export const getAdminPremiumAccountsState =
  createFeatureSelector<AdminPremiumAccountsState>('adminPremiumAccounts');

export const {
  selectIds: selectPremiumAccountIds,
  selectEntities: selectPremiumAccountEntities,
  selectAll: selectPremiumAccountAll,
  selectTotal: selectPremiumAccountTotal,
} = premiumAccountsAdapter.getSelectors();

export const getAllPremiumAccountById = createSelector(
  getAdminPremiumAccountsState,
  selectPremiumAccountEntities
);

export const premiumAccountsLoaded = createSelector(
  getAdminPremiumAccountsState,
  (state) => {
    return state.loaded;
  }
);

export const premiumAccountsLoadingError = createSelector(
  getAdminPremiumAccountsState,
  (state) => {
    return state.error;
  }
);

// OFFERS SELECTORS

export const getAdminOffersState =
  createFeatureSelector<AdminOffersState>('adminOffers');

export const {
  selectIds: selectOffersIds,
  selectEntities: selectOffersEntities,
  selectAll: selectOffersAll,
  selectTotal: selectOffersTotal,
} = offersAdapter.getSelectors();

export const getAllOffers = createSelector(
  getAdminOffersState,
  selectOffersAll
);

export const offersLoaded = createSelector(getAdminOffersState, (state) => {
  return state.loaded;
});

export const offersLoadingError = createSelector(
  getAdminOffersState,
  (state) => {
    return state.error;
  }
);
