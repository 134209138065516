import { EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import { createInitialState } from '@arrivage-store/generators';
import { Invoice, WithId } from '@arrivage/model/dist/src/model';

import { createCommonInvoiceReducer } from '../../common/store/generator';
import * as InvoicesActions from './vendor-invoices.actions';
import { VendorInvoicesActions } from './vendor-invoices.actions';
import { VendorInvoicesState } from './vendor-invoices.state';

export const adapter: EntityAdapter<Invoice & WithId> = createEntityAdapter<
  Invoice & WithId
>();

export const initialState: VendorInvoicesState = createInitialState(adapter, {
  dateRange: undefined,
  byDateRange: {
    items: [],
    isLoading: false,
    error: undefined,
  },
  sendingInvoice: false,
});

const invoicesReducer = createReducer(
  initialState,
  ...createCommonInvoiceReducer<VendorInvoicesState>(
    VendorInvoicesActions,
    adapter
  ),
  on(InvoicesActions.sendInvoice, (state: VendorInvoicesState) => {
    return {
      ...state,
      sendingInvoice: true,
    };
  }),
  on(InvoicesActions.sendInvoiceSuccess, (state: VendorInvoicesState) => {
    return {
      ...state,
      sendingInvoice: false,
    };
  }),
  on(InvoicesActions.sendInvoiceFailure, (state: VendorInvoicesState) => {
    return {
      ...state,
      sendingInvoice: false,
    };
  })
);

export function reducer(
  state: VendorInvoicesState | undefined,
  action: Action
) {
  return invoicesReducer(state, action);
}
