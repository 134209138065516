import { createFeatureSelector, createSelector } from '@ngrx/store';

import { AuthState } from './auth.state';

export const getAuthState = createFeatureSelector<AuthState>('auth');

export const isUserAuth = createSelector(
  getAuthState,
  (state: AuthState) => !!state.user
);

export const getAuthUser = createSelector(
  getAuthState,
  (state: AuthState) => state.user
);

export const isSignInInProgress = createSelector(
  getAuthState,
  (state: AuthState) => state.isSignInInProgress
);

export const authErrorCode = createSelector(
  getAuthState,
  (state: AuthState) => state.errorCode
);

export const hasTriggeredLogout = createSelector(
  getAuthState,
  (state: AuthState) => state.triggeredlogout
);
