import { of } from 'rxjs';

import { Injectable } from '@angular/core';

import {
  switchMap,
  map,
  catchError,
  withLatestFrom,
  takeUntil,
  filter,
} from 'rxjs/operators';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';

import { Logout } from '@arrivage-auth/store/auth.actions';
import { reportError } from '@arrivage-sentry/report-error';
import { showFeedback } from '@arrivage-store/feedback/feedback.actions';
import { selectRouteParam } from '@arrivage-store/routing/router-state.selectors';
import { State } from '@arrivage-store/state';

import { OrganizationService } from '../services/organization.service';
import {
  getOrganization,
  getOrganizationSuccess,
  getOrganizationFailure,
} from './organization.actions';

interface OrganizationFeedback {
  load_organization: string;
  query: string;
}

export const OrganizationFeedback: OrganizationFeedback = {
  load_organization: 'load_organization',
  query: 'query_organization',
};

@Injectable()
export class OrganizationEffects {
  constructor(
    private actions$: Actions,
    private store: Store<State>,
    private organizationService: OrganizationService
  ) {}

  getOrganizationEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(getOrganization),
      withLatestFrom(
        this.store.pipe(select(selectRouteParam('idOrganization')))
      ),
      filter(([organization, organizationId]) => !!organizationId),
      switchMap(([organization, organizationId]) => {
        return this.organizationService.getOrganization(organizationId).pipe(
          takeUntil(this.actions$.pipe(ofType(Logout))),
          map((o) => getOrganizationSuccess({ organization: o })),
          catchError((e) => {
            reportError(e);
            return of(
              getOrganizationFailure({ error: e }),
              showFeedback({
                success: false,
                feedback: OrganizationFeedback.query,
              })
            );
          })
        );
      })
    );
  });
}
