import _, { isEqual } from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { DialogUtils } from 'src/app/util/dialog.utils';
import { LangUtils } from 'src/app/util/lang.utils';

import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';

import { AuthUser } from '@arrivage-auth/model/auth-data.model';
import { AddressFormComponent } from '@arrivage-components/address-form/address-form.component';
import {
  CommunicationPreferenceTableComponent,
  SelectedEmailsPreferences,
} from '@arrivage-components/communication-preference-table/communication-preference-table.component';
import { ContactInfoFormComponent } from '@arrivage-components/contact-info-forms/contact-info-form.component';
import { FormDirtyViewComponent } from '@arrivage-forms/form-is-dirty/form-dirty.model';
import { LanguageService } from '@arrivage-language/service/language.service';
import { integerValidator } from '@arrivage-validators/custom-validators';
import {
  CommissionType,
  Language,
  Organization,
  OrganizationSettings,
  TaxSetting,
  WithId,
} from '@arrivage/model/dist/src/model';
import { PaymentMethod } from '@arrivage/model/dist/src/model/payment';

import { AddTaxDialogComponent } from '../add-tax-dialog/add-tax-dialog.component';

@Component({
  selector: 'app-organization-settings-view',
  templateUrl: './organization-settings-view.component.html',
  styleUrls: ['./organization-settings-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrganizationSettingsViewComponent extends FormDirtyViewComponent {
  readonly PAYMENT_METHODS = Object.values(PaymentMethod);
  readonly ALL_LANGUAGES = this.languageService.getAllLanguages();
  readonly ALL_LANGUAGES_FULL_NAME = {
    [Language.EN]: 'English',
    [Language.FR]: 'Français',
  };

  private readonly TAX_DIALOG_WIDTH = '300px';

  private _contactInfoForm: ContactInfoFormComponent;
  private _invoicingContactForm: ContactInfoFormComponent;
  private _invoicingAddressForm: AddressFormComponent;

  private _organization: Organization & WithId;
  private _organizationSettings: OrganizationSettings & WithId;

  taxes: TaxSetting[] = [];

  form = this.fb.group({});

  shouldShowFormErrorMessage = false;

  communicationPreferencesEmailsOptions$: BehaviorSubject<string[]> =
    new BehaviorSubject([]);

  mainEmail: string;
  otherEmails: string[] = [];
  selectedEmailsPreference: SelectedEmailsPreferences = {
    priceListEmailsPreference: [],
    purchaseOrderEmailsPreference: [],
    invoicingEmailsPreference: [],
  };

  @Input()
  set organization(value: Organization & WithId) {
    this._organization = value;

    this.mainEmail = value.contactInfo.email;

    if (this.organizationSettings) {
      this.initializeOtherEmailsAndPreferencesOptions();
      this.initializeForm();
      this.fillForm();
    }
  }

  get organization(): Organization & WithId {
    return this._organization;
  }

  @Input()
  set organizationSettings(value: OrganizationSettings & WithId) {
    this._organizationSettings = value;

    if (this.organization) {
      this.initializeOtherEmailsAndPreferencesOptions();
      this.initializeForm();
      this.fillForm();
    }
  }

  get organizationSettings(): OrganizationSettings & WithId {
    return this._organizationSettings;
  }

  @Input() authUser: AuthUser;

  @Output()
  organizationChanges = new EventEmitter<{
    organization: Partial<Organization> & WithId;
    organizationSettings: Partial<OrganizationSettings> & WithId;
  }>();

  @ViewChild('contactInfoForm')
  set contactForm(value: ContactInfoFormComponent) {
    if (value) {
      this._contactInfoForm = value;
      this.form.addControl('contactInfoForm', value.form);
    }
  }

  get contactForm(): ContactInfoFormComponent {
    return this._contactInfoForm;
  }

  @ViewChild('invoicingContactForm')
  set invoicingContactForm(value: ContactInfoFormComponent) {
    if (value) {
      this._invoicingContactForm = value;
      (this.invoiceFormControl as FormGroup).addControl(
        'invoicingContactForm',
        value.form
      );
    }
  }

  get invoicingContactForm(): ContactInfoFormComponent {
    return this._invoicingContactForm;
  }

  @ViewChild('invoicingAddressForm')
  set invoicingAddressForm(value: AddressFormComponent) {
    if (value) {
      this._invoicingAddressForm = value;
      (this.invoiceFormControl as FormGroup).addControl(
        'invoicingAddressForm',
        value.form
      );
    }
  }

  get invoicingAddressForm(): AddressFormComponent {
    return this._invoicingAddressForm;
  }

  @ViewChild(CommunicationPreferenceTableComponent)
  communicationPreferenceTable: CommunicationPreferenceTableComponent;

  constructor(
    private fb: UntypedFormBuilder,
    private dialog: MatDialog,
    private languageService: LanguageService
  ) {
    super();
  }

  get hasPaymentTermsMinError(): boolean {
    return this.paymentTermsInDaysControl.hasError('min');
  }

  get hasPaymentTermsIntegerError(): boolean {
    return this.paymentTermsInDaysControl.hasError('integer');
  }

  get hasGeneralCommissionMinError(): boolean {
    return this.generalCommissionForProducersControl.hasError('min');
  }

  get hasGeneralCommissionMaxError(): boolean {
    return this.generalCommissionForProducersControl.hasError('max');
  }

  saveTaxes() {
    const organizationSettings: Partial<OrganizationSettings> & WithId = {
      id: this.organizationSettings.id,
    };
    const organization: Partial<Organization> & WithId = {
      id: this.organization.id,
    };
    organizationSettings['sales.taxes'] = [...this.taxes];
    this.organizationChanges.emit({ organization, organizationSettings });
  }

  onAddTax() {
    this.dialog
      .open<AddTaxDialogComponent, TaxSetting, [boolean, TaxSetting]>(
        AddTaxDialogComponent,
        {
          width: this.TAX_DIALOG_WIDTH,
        }
      )
      .afterClosed()
      .subscribe(([valid, result]) => {
        if (valid) {
          this.taxes.push(result);
          this.saveTaxes();
        }
      });
  }

  onEditTax(index: number) {
    this.dialog
      .open<AddTaxDialogComponent, TaxSetting, [boolean, TaxSetting]>(
        AddTaxDialogComponent,
        {
          width: this.TAX_DIALOG_WIDTH,
          data: this.taxes[index],
        }
      )
      .afterClosed()
      .subscribe(([valid, result]) => {
        if (valid) {
          this.taxes[index] = result;
          this.saveTaxes();
        }
      });
  }

  onDeleteTax(index: number) {
    DialogUtils.openConfirmationDialog(
      this.dialog,
      'organization.dialog-confirmation.tax-suppression-title',
      'organization.dialog-confirmation.tax-suppression-message',
      { confirmationForDeleting: true }
    )
      .afterClosed()
      .subscribe((answer) => {
        if (answer) {
          this.taxes.splice(index, 1);
          this.saveTaxes();
        }
      });
  }

  onSubmit() {
    if (this.form.valid) {
      this.shouldShowFormErrorMessage = false;
      this.organizationChanges.emit(this.getOrganizationRecord());
      this.form.markAsPristine();
    } else {
      this.shouldShowFormErrorMessage = true;
    }
  }

  onCancel() {
    if (this.organization.isBuyer) {
      this.communicationPreferenceTable.cancelChanges();
    }
    this.shouldShowFormErrorMessage = false;
    this.initializeOtherEmailsAndPreferencesOptions();
    this.initializeForm();
    this.fillForm();
    this.form.markAsPristine();
  }

  onSelectedEmailsPreferences(emailsPreferences: SelectedEmailsPreferences) {
    this.selectedEmailsPreference = emailsPreferences;
  }

  onAddNewEmail(newEmail: string) {
    this.otherEmails = [...this.otherEmails, newEmail];
    this.communicationPreferencesEmailsOptions$.next([
      ...this.communicationPreferencesEmailsOptions$.value,
      newEmail,
    ]);
  }

  onDeleteEmail(deletedEmail: string) {
    this.otherEmails = this.otherEmails.filter(
      (email) => email !== deletedEmail
    );
    this.communicationPreferencesEmailsOptions$.next(
      this.communicationPreferencesEmailsOptions$.value.filter(
        (email) => email !== deletedEmail
      )
    );
  }

  onMainEmailChange(newMainEmail: string) {
    const previousEmail = this.mainEmail;
    this.mainEmail = newMainEmail;

    if (_.isEmpty(this.mainEmail)) {
      this.communicationPreferencesEmailsOptions$.next(
        this.communicationPreferencesEmailsOptions$.value.filter(
          (email) => email !== previousEmail
        )
      );
    } else {
      if (previousEmail === this.mainEmail) return;

      const isNewMainEmailPresentInArrayOption =
        this.communicationPreferencesEmailsOptions$.value.find(
          (email) => email === newMainEmail
        );

      if (isNewMainEmailPresentInArrayOption) {
        if (
          this.communicationPreferenceTable.priceListEmailsSelection.isSelected(
            newMainEmail
          )
        ) {
          this.communicationPreferenceTable.priceListEmailsSelection.toggle(
            newMainEmail
          );
        }

        if (
          this.communicationPreferenceTable.purchaseOrderEmailsSelection.isSelected(
            newMainEmail
          )
        ) {
          this.communicationPreferenceTable.purchaseOrderEmailsSelection.toggle(
            newMainEmail
          );
        }

        if (
          this.communicationPreferenceTable.invoicingEmailsSelection.isSelected(
            newMainEmail
          )
        ) {
          this.communicationPreferenceTable.invoicingEmailsSelection.toggle(
            newMainEmail
          );
        }
        this.communicationPreferenceTable.emitSelectedEmailsPreference();

        this.otherEmails = this.otherEmails.filter(
          (email) => email !== newMainEmail
        );
      }

      //filter out the new main email in case it already exists in the options
      const communicationPreferencesOptions = isNewMainEmailPresentInArrayOption
        ? [
            ...this.communicationPreferencesEmailsOptions$.value.filter(
              (email) => email !== newMainEmail
            ),
          ]
        : [...this.communicationPreferencesEmailsOptions$.value];

      if (previousEmail) {
        communicationPreferencesOptions[0] = newMainEmail;
        this.communicationPreferencesEmailsOptions$.next(
          communicationPreferencesOptions
        );
      } else {
        this.communicationPreferencesEmailsOptions$.next([
          newMainEmail,
          ...this.communicationPreferencesEmailsOptions$.value.filter(
            (email) => email !== newMainEmail
          ),
        ]);
      }
    }
  }

  private getOrganizationRecord(): {
    organization: Partial<Organization> & WithId;
    organizationSettings: Partial<OrganizationSettings> & WithId;
  } {
    const organizationSettings: Partial<OrganizationSettings> & WithId = {
      id: this.organizationSettings.id,
    };
    const organization: Partial<Organization> & WithId = {
      id: this.organization.id,
    };
    const contactFormRecord = this.contactForm.getRecord();
    if (!isEqual(this.organization.contactInfo, contactFormRecord)) {
      organization.contactInfo = contactFormRecord;
    }

    if (
      !_.isEqual(
        this.organizationSettings?.preferences?.language,
        this.languageControl.value
      )
    ) {
      organizationSettings['preferences.language'] = this.languageControl.value;
    }

    if (!isEqual(this.organization?.legalName, this.legalNameControl.value)) {
      organization.legalName = this.legalNameControl.value;
    }
    if (!isEqual(this.organization?.neq, this.neqControl.value)) {
      organization.neq = this.neqControl.value ? this.neqControl.value : null;
    }

    if (this.organization.isBuyer) {
      organization['contactInfo.otherEmails'] = [...this.otherEmails];

      organizationSettings[
        'preferences.emailsPreferences.priceListPreference'
      ] = this.selectedEmailsPreference.priceListEmailsPreference;

      organizationSettings[
        'preferences.emailsPreferences.purchaseOrderPreference'
      ] = this.selectedEmailsPreference.purchaseOrderEmailsPreference;

      organizationSettings['preferences.emailsPreferences.invoicePreference'] =
        this.selectedEmailsPreference.invoicingEmailsPreference;
      if (
        !_.isEqual(
          this.organizationSettings?.deliveries?.reception?.deliveryInstructions
            ?.original,
          this.deliveryInstructionsControl.value
        )
      ) {
        organizationSettings['deliveries.reception.deliveryInstructions'] = this
          .deliveryInstructionsControl.value
          ? this.languageService.fromText(
              this.deliveryInstructionsControl.value
            )
          : null;
      }
    }

    if (this.organization.isSeller) {
      if (
        !_.isEqual(
          this.organizationSettings?.sales?.deliveryFeeApplicableTaxes,
          _.compact(this.deliveryFeeApplicableTaxesControl.value)
        )
      ) {
        organizationSettings['sales.deliveryFeeApplicableTaxes'] = _.compact(
          this.deliveryFeeApplicableTaxesControl.value
        );
      }
      if (
        !_.isEqual(
          this.organizationSettings?.sales?.paymentTermsInDays,
          this.paymentTermsInDaysControl.value
        )
      ) {
        organizationSettings['sales.paymentTermsInDays'] =
          this.paymentTermsInDaysControl.value;
      }
      if (
        !_.isEqual(
          this.organizationSettings?.sales?.paymentMethods,
          this.paymentMethodsControl.value
        )
      ) {
        organizationSettings['sales.paymentMethods'] =
          this.paymentMethodsControl.value;
      }
      if (
        !_.isEqual(
          this.organizationSettings?.sales?.paymentInstructions?.original,
          this.paymentInstructionsControl.value
        )
      ) {
        organizationSettings['sales.paymentInstructions'] = this
          .paymentInstructionsControl.value
          ? this.languageService.fromText(this.paymentInstructionsControl.value)
          : null;
      }
      if (
        !_.isEqual(
          this.organizationSettings?.sales?.invoicing?.invoiceFooter,
          this.invoiceFooterControl.value
        )
      ) {
        organizationSettings['sales.invoicing.invoiceFooter'] =
          this.invoiceFooterControl.value;
      }
      if (
        !_.isEqual(
          this.organizationSettings?.preferences?.autoApprovePurchaseOrder,
          this.autoApprovePurchaseOrderControl.value
        )
      ) {
        organizationSettings['preferences.autoApprovePurchaseOrder'] =
          this.autoApprovePurchaseOrderControl.value;
      }

      if (
        !_.isEqual(
          this.organizationSettings?.preferences?.purchaseOrderPrefix,
          this.purchaseOrderPrefixControl.value
        )
      ) {
        organizationSettings['preferences.purchaseOrderPrefix'] =
          this.purchaseOrderPrefixControl.value;
      }

      const invoicingLegalName = this.invoicingLegalNameControl.value;
      const equalsLegalNameOrName = // priority to legalName
        (this.organization.legalName &&
          _.isEqual(invoicingLegalName, this.organization.legalName)) ||
        (!this.organization.legalName &&
          _.isEqual(invoicingLegalName, this.organization.name));
      if (
        invoicingLegalName &&
        !equalsLegalNameOrName &&
        !_.isEqual(
          invoicingLegalName,
          this.organizationSettings.sales?.invoicing?.invoicingLegalName
        )
      ) {
        organizationSettings['sales.invoicing.invoicingLegalName'] =
          invoicingLegalName;
      } else if (equalsLegalNameOrName || !invoicingLegalName) {
        organizationSettings['sales.invoicing.invoicingLegalName'] = null;
      }

      const invoicingContactInformation = this.invoicingContactForm.getRecord();
      if (
        !_.isEqual(
          invoicingContactInformation,
          this.organization.contactInfo
        ) &&
        !_.isEqual(
          invoicingContactInformation,
          this.organizationSettings.sales?.invoicing
            ?.invoicingContactInformation
        )
      ) {
        organizationSettings['sales.invoicing.invoicingContactInformation'] =
          invoicingContactInformation;
      } else if (
        _.isEqual(invoicingContactInformation, this.organization.contactInfo)
      ) {
        organizationSettings['sales.invoicing.invoicingContactInformation'] =
          null;
      }

      const invoicingAddress = this.invoicingAddressForm.getRecord();
      if (
        !_.isEqual(invoicingAddress, this.organization.address) &&
        !_.isEqual(
          invoicingAddress,
          this.organizationSettings.sales?.invoicing?.invoicingAddress
        )
      ) {
        organizationSettings['sales.invoicing.invoicingAddress'] =
          invoicingAddress;
      } else if (_.isEqual(invoicingAddress, this.organization.address)) {
        organizationSettings['sales.invoicing.invoicingAddress'] = null;
      }

      if (!isEqual(this.organization?.nim, this.nimControl.value)) {
        organization.nim = this.nimControl.value ? this.nimControl.value : null;
      }

      if (
        !isEqual(
          this.organizationSettings?.producers?.generalMessageForProducers,
          this.producerGeneralMessageControl.value.trim()
        )
      ) {
        organizationSettings['producers.generalMessageForProducers'] = this
          .producerGeneralMessageControl.value
          ? this.producerGeneralMessageControl.value.trim()
          : null;
      }
      if (
        !isEqual(
          this.organizationSettings?.producers?.commission?.value,
          this.generalCommissionForProducersControl.value
        )
      ) {
        organizationSettings['producers.commission'] = this
          .generalCommissionForProducersControl.value
          ? {
              value: this.generalCommissionForProducersControl.value,
              type: CommissionType.PERCENTAGE,
            }
          : null;
      }
    }

    return {
      organization,
      organizationSettings,
    };
  }

  private initializeForm() {
    const preferencesForm = this.fb.group({
      language: [null, Validators.required],
    });

    this.form.addControl('preferencesForm', preferencesForm);

    this.form.addControl('legalName', this.fb.control(null));
    this.form.addControl('yearOfCreation', this.fb.control(null));
    this.form.addControl('legalForm', this.fb.control(null));
    this.form.addControl('neq', this.fb.control(null));

    if (this.organization.isSeller) {
      const invoiceForm = this.fb.group({
        paymentMethods: [[]],
        paymentInstructions: [null],
        paymentTermsInDays: [
          null,
          Validators.compose([
            Validators.required,
            Validators.min(0),
            integerValidator,
          ]),
        ],
        invoiceNumberPrefix: [''],
        invoiceFooter: [''],
        invoicingLegalName: [''],
      });

      const producerForm = this.fb.group({
        generalMessageForProducers: [''],
        generalCommissionForProducers: [
          null,
          Validators.compose([Validators.min(0), Validators.max(100)]),
        ],
      });

      this.form.addControl('invoiceForm', invoiceForm);

      this.form.addControl('producerForm', producerForm);

      this.form.addControl('deliveryFeeApplicableTaxes', this.fb.control(null));

      const purchaseOrderForm = this.fb.group({
        autoApprovePurchaseOrder: [false],
        purchaseOrderPrefix: [''],
      });
      this.form.addControl('purchaseOrderForm', purchaseOrderForm);
      this.form.addControl('nim', this.fb.control(null));
    } else {
      this.form.addControl('deliveryInstructions', this.fb.control(null));
    }
  }

  private fillForm() {
    if (this.organizationSettings?.preferences?.language) {
      this.form
        .get('preferencesForm.language')
        .setValue(this.organizationSettings.preferences.language);
    }

    this.legalNameControl.setValue(this.organization?.legalName);
    this.yearOfCreationControl.setValue(
      this.organization?.legal?.yearOfCreation
    );
    this.legalFormControl.setValue(this.organization?.legal?.legalForm);
    this.neqControl.setValue(this.organization?.neq);

    if (this.organization.isSeller) {
      if (this.organizationSettings?.sales?.taxes) {
        this.taxes = [...this.organizationSettings?.sales?.taxes];
      }
      if (this.organizationSettings?.sales?.deliveryFeeApplicableTaxes) {
        this.form
          .get('deliveryFeeApplicableTaxes')
          .setValue(this.organizationSettings.sales.deliveryFeeApplicableTaxes);
      }
      if (this.organizationSettings?.sales?.paymentTermsInDays) {
        this.form
          .get('invoiceForm.paymentTermsInDays')
          .setValue(this.organizationSettings.sales.paymentTermsInDays);
      }
      if (
        !LangUtils.nullOrUndefined(
          this.organizationSettings?.sales?.paymentMethods
        )
      ) {
        this.form
          .get('invoiceForm.paymentMethods')
          .setValue(this.organizationSettings.sales.paymentMethods);
      }
      if (this.organizationSettings?.sales?.paymentInstructions) {
        this.form
          .get('invoiceForm.paymentInstructions')
          .setValue(
            this.languageService.getText(
              this.organizationSettings.sales.paymentInstructions
            )
          );
      }
      if (this.organizationSettings?.sales?.invoicing?.invoiceFooter) {
        this.form
          .get('invoiceForm.invoiceFooter')
          .setValue(this.organizationSettings.sales.invoicing.invoiceFooter);
      }
      if (this.organizationSettings?.preferences?.autoApprovePurchaseOrder) {
        this.form
          .get('purchaseOrderForm.autoApprovePurchaseOrder')
          .setValue(
            this.organizationSettings.preferences.autoApprovePurchaseOrder
          );
      }
      if (this.organizationSettings?.preferences?.purchaseOrderPrefix) {
        this.form
          .get('purchaseOrderForm.purchaseOrderPrefix')
          .setValue(this.organizationSettings.preferences.purchaseOrderPrefix);
      }

      this.form
        .get('invoiceForm.invoicingLegalName')
        .setValue(
          this.organizationSettings?.sales?.invoicing?.invoicingLegalName ??
            this.organization.legalName ??
            this.organization.name
        );

      this.nimControl.setValue(this.organization?.nim);

      if (this.organizationSettings?.producers?.generalMessageForProducers) {
        this.producerGeneralMessageControl.setValue(
          this.organizationSettings.producers.generalMessageForProducers
        );
      }

      if (this.organizationSettings?.producers?.commission?.value) {
        this.generalCommissionForProducersControl.setValue(
          this.organizationSettings.producers.commission.value
        );
      }
    }

    if (this.organization.isBuyer) {
      if (
        this.organizationSettings?.deliveries?.reception?.deliveryInstructions
      ) {
        this.deliveryInstructionsControl.setValue(
          this.languageService.getText(
            this.organizationSettings.deliveries.reception.deliveryInstructions
          )
        );
      }
    }
  }

  private initializeOtherEmailsAndPreferencesOptions() {
    this.otherEmails = this.organization.contactInfo.otherEmails
      ? this.organization.contactInfo.otherEmails
      : this.otherEmails;

    this.communicationPreferencesEmailsOptions$.next(
      _.uniq(
        _.filter(
          _.concat(
            this.organization.contactInfo.email,
            this.organizationSettings.preferences?.emailsPreferences
              ?.purchaseOrderPreference,
            this.organizationSettings.preferences?.emailsPreferences
              ?.priceListPreference,
            this.organizationSettings.preferences?.emailsPreferences
              ?.invoicePreference,
            this.organization.contactInfo.otherEmails
          ),
          (email) => !!email
        )
      )
    );
  }

  trackByIdNameIndex(index: number, item: TaxSetting) {
    return item.taxId ?? item.tax.name ?? index;
  }

  private get legalNameControl() {
    return this.form.get('legalName');
  }

  private get yearOfCreationControl() {
    return this.form.get('yearOfCreation');
  }

  private get legalFormControl() {
    return this.form.get('legalForm');
  }

  private get nimControl() {
    return this.form.get('nim');
  }

  private get neqControl() {
    return this.form.get('neq');
  }

  private get paymentTermsInDaysControl() {
    return this.form.get('invoiceForm.paymentTermsInDays');
  }

  private get paymentMethodsControl() {
    return this.form.get('invoiceForm.paymentMethods');
  }

  private get paymentInstructionsControl() {
    return this.form.get('invoiceForm.paymentInstructions');
  }

  private get languageControl() {
    return this.form.get('preferencesForm.language');
  }

  private get deliveryInstructionsControl() {
    return this.form.get('deliveryInstructions');
  }

  private get deliveryFeeApplicableTaxesControl() {
    return this.form.get('deliveryFeeApplicableTaxes');
  }

  private get invoiceFormControl() {
    return this.form.get('invoiceForm');
  }

  private get purchaseOrderPrefixControl() {
    return this.form.get('purchaseOrderForm.purchaseOrderPrefix');
  }

  private get autoApprovePurchaseOrderControl() {
    return this.form.get('purchaseOrderForm.autoApprovePurchaseOrder');
  }

  private get invoiceFooterControl() {
    return this.form.get('invoiceForm.invoiceFooter');
  }

  private get invoicingLegalNameControl() {
    return this.form.get('invoiceForm.invoicingLegalName');
  }

  private get producerControl() {
    return this.form.get('producerForm');
  }

  private get producerGeneralMessageControl() {
    return this.producerControl.get('generalMessageForProducers');
  }

  private get generalCommissionForProducersControl() {
    return this.producerControl.get('generalCommissionForProducers');
  }
}
