import _ from 'lodash';
import { Observable } from 'rxjs';

import { Injectable } from '@angular/core';
import {
  collection,
  collectionSnapshots,
  Firestore,
  orderBy,
  query,
  QueryFieldFilterConstraint,
  QueryOrderByConstraint,
  where,
} from '@angular/fire/firestore';

import { map } from 'rxjs/operators';

import {
  CreditAccountOperation,
  CreditAccountOperationType,
  WithId,
} from '@arrivage/model/dist/src/model';
import { CollectionNames } from '@arrivage/model/dist/src/utils';

import { CreditAccountsService } from './credit-accounts.service';

@Injectable({
  providedIn: 'root',
})
export class CreditAccountsOperationsService {
  static readonly CREDIT_ACCOUNT_OPERATIONS_COLLECTION_NAME =
    CollectionNames.OPERATION_COLLECTION;

  constructor(private firestore: Firestore) {}

  list(
    organizationId: string,
    interval: Interval,
    types: CreditAccountOperationType[] = []
  ): Observable<(CreditAccountOperation & WithId)[]> {
    const queryConstraints: (
      | QueryFieldFilterConstraint
      | QueryOrderByConstraint
    )[] = [
      where('timestamp', '>=', (interval.start as Date).getTime()),
      where('timestamp', '<=', (interval.end as Date).getTime()),
    ];

    if (types.length > 0) {
      queryConstraints.push(where('operation', 'in', types));
    }

    queryConstraints.push(orderBy('timestamp', 'desc'));

    return collectionSnapshots(
      query(
        collection(
          this.firestore,
          CreditAccountsService.CREDIT_ACCOUNTS_COLLECTION_NAME,
          organizationId,
          CreditAccountsOperationsService.CREDIT_ACCOUNT_OPERATIONS_COLLECTION_NAME
        ),
        ...queryConstraints
      )
    ).pipe(
      map((actions) => {
        return _.map(actions, (a) => {
          const data = a.data();
          const id = a.id;
          return { ...data, id: id } as CreditAccountOperation & WithId;
        });
      })
    );
  }
}
