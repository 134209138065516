<app-navigation>
  <div
    *ngIf="{
      isLoading: isLoading$ | async,
      isConnected: isConnected$ | async,
      screenSize: screenSize$ | async
    } as data"
    fxLayout="column"
    class="wrapper"
  >
    <div *ngIf="data.isLoading" class="overlay">
      <div>
        <app-loading-page></app-loading-page>
      </div>
    </div>

    <div
      fxFlex
      class="loaded-content"
      [class.main-content]="!data.screenSize?.small"
      [class.s-main-content]="data.screenSize?.small"
      [class.blurred]="data.isLoading"
      [@routeAnimations]="outlet.isActivated ? outlet.activatedRoute : ''"
    >
      <router-outlet #outlet="outlet"></router-outlet>
    </div>
  </div>
</app-navigation>
