<form
  [formGroup]="form"
  novalidate
  *transloco="let t; read: 'components.address-form'"
  [ngClass]="{ 'incomplete-address-error': showIncompleteAddressError }"
>
  <small *ngIf="showIncompleteAddressError" class="text-error">
    {{ t('incomplete-address-error') }}
  </small>
  <mat-form-field [floatLabel]="readonly ? 'always' : 'auto'">
    <mat-label>{{ t('address') }}</mat-label>
    <input
      id="address"
      [required]="required"
      matInput
      formControlName="address"
      data-testid="address-form-address-input-testid"
    />
  </mat-form-field>
  <div [class.form-row]="!isExtraSmallScreen">
    <mat-form-field
      [class.large]="!isExtraSmallScreen"
      [floatLabel]="readonly ? 'always' : 'auto'"
    >
      <mat-label>{{ t('city') }}</mat-label>
      <input
        id="city"
        [required]="required"
        matInput
        formControlName="city"
        data-testid="address-form-city-input-testid"
      />
    </mat-form-field>
    <mat-form-field
      [class.large]="!isExtraSmallScreen"
      [floatLabel]="readonly ? 'always' : 'auto'"
    >
      <mat-label>{{ t('postal-code') }}</mat-label>
      <input
        id="zipOrPostalCode"
        [required]="required"
        matInput
        formControlName="zipOrPostalCode"
        data-testid="address-form-zip-input-testid"
      />
    </mat-form-field>
  </div>
  <div [class.form-row]="!isExtraSmallScreen">
    <mat-form-field
      [class.large]="!isExtraSmallScreen"
      [floatLabel]="readonly ? 'always' : 'auto'"
    >
      <mat-label>{{ t('country') }}</mat-label>
      <mat-select
        [required]="required"
        formControlName="country"
        data-testid="address-form-country-select-testid"
      >
        <mat-option [value]="null"> </mat-option>
        <mat-option
          *ngFor="let countryName of countryNames"
          [value]="countryName"
        >
          {{ countryName }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field
      [class.large]="!isExtraSmallScreen"
      [floatLabel]="readonly ? 'always' : 'auto'"
    >
      <mat-label>{{ t('province') }}</mat-label>
      <mat-select
        [required]="required"
        formControlName="stateOrProvince"
        data-testid="address-form-province-select-testid"
      >
        <mat-option [value]="null"> </mat-option>
        <mat-option
          *ngFor="let ad of getAdministrativeDivisions()"
          [value]="ad"
        >
          {{ ad }}
        </mat-option>
      </mat-select>
      <mat-hint *ngIf="!readonly">{{ t('select-country-message') }}</mat-hint>
    </mat-form-field>
  </div>
</form>
