import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

import { HashMap } from '@jsverse/transloco';

@Component({
  selector: 'app-snackbar-success',
  templateUrl: './snackbar-success.component.html',
  styleUrls: ['../snackbar.style.scss', '../../styles/global/_colors.scss'],
})
export class SnackbarSuccessComponent {
  constructor(
    @Inject(MAT_SNACK_BAR_DATA)
    public data: {
      message: string;
      close: () => {};
      messageParameters?: HashMap;
    }
  ) {}

  dismiss() {
    this.data.close();
  }
}
