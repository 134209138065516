import { createFeatureSelector, createSelector } from '@ngrx/store';

import { ExternalCustomerInfoState } from './external-store.state';

export const selectExternalCustomerInfoState = createFeatureSelector<ExternalCustomerInfoState>(
  'externalInfo'
);

export const selectExternalCustomer = createSelector(
  selectExternalCustomerInfoState,
  (state) => state.item
);
