<ng-container *transloco="let t; scope: 'delivery'; read: 'delivery'">
  <section
    class="mat-dialog-title no-space-top"
    fxLayout="row"
    fxLayoutAlign="space-between baseline"
  >
    <!-- DIALOG TITLE -->
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="0.5rem">
      <h2 class="text-xl">
        {{ t('send-pick-split-list-dialog.title') }}
      </h2>
      <mat-icon
        class="material-icons-outlined"
        [customTooltip]="
          t('send-pick-split-list-dialog.send-pick-split-list-to-producer-help')
        "
        tooltipPosition="right"
        >help_outline</mat-icon
      >
    </div>
    <button mat-icon-button mat-dialog-close type="button" tabindex="-1">
      <mat-icon>close</mat-icon>
    </button>
  </section>

  <mat-card-content
    *transloco="let j; scope: 'priceLists'; read: 'priceLists'"
    class="mat-dialog-content space-bottom"
  >
    <mat-stepper
      #stepper
      [linear]="true"
      [labelPosition]="'bottom'"
      class="{{ 'last-edited-step-' + stepper.selectedIndex }}"
    >
      <!-- FIST STEPPER: EMAILS -->
      <mat-step
        [stepControl]="producerEmailForm"
        [label]="t('send-pick-split-list-dialog.email')"
      >
        <app-system-message
          type="warning"
          [message]="j('system-message.producers-pdf-warning')"
          [description]="j('system-message.producers-pdf-warning-description')"
        ></app-system-message>
        <form
          [formGroup]="producerEmailForm"
          fxLayout="column"
          fxLayoutGap="1rem"
        >
          <mat-table [dataSource]="dataSource" [trackBy]="trackByProducerId">
            <!-- MULTI-SELECT -->

            <ng-container matColumnDef="select">
              <mat-header-cell *matHeaderCellDef fxFlex="70px">
                <mat-checkbox
                  [checked]="isAllSelected()"
                  [indeterminate]="isSomeSelected() && !isAllSelected()"
                  [customTooltip]="
                    t('send-pick-split-list-dialog.checkbox-tooltip')
                  "
                  (change)="masterToggle()"
                  tooltipPosition="top"
                  data-testid="master-toggle-producers-test-id"
                ></mat-checkbox>
              </mat-header-cell>
              <mat-cell
                *matCellDef="let producer; dataSource: dataSource"
                [formGroupName]="producer.id"
                (click)="$event.stopPropagation()"
                fxFlex="70px"
              >
                <mat-checkbox
                  name="isSelected"
                  formControlName="isSelected"
                  data-testid="producers-checkbox-test-id"
                  [customTooltip]="
                    t('send-pick-split-list-dialog.checkbox-tooltip')
                  "
                  tooltipPosition="top"
                >
                </mat-checkbox>
              </mat-cell>
            </ng-container>

            <!-- NAME -->

            <ng-container matColumnDef="name">
              <mat-header-cell *matHeaderCellDef fxLayoutAlign="start center">
                {{ t('dialog-form.label-name') | uppercase }}
              </mat-header-cell>
              <mat-cell
                *matCellDef="let producer; dataSource: dataSource"
                [formGroupName]="producer.id"
                fxLayoutAlign="start center"
                class="producer-clickable"
              >
                <app-custom-text
                  [text]="producer.name | uppercaseFirst"
                  [tooltip]="'overflowOnly'"
                  [nbLine]="2"
                ></app-custom-text>
              </mat-cell>
            </ng-container>

            <!-- EMAIL -->

            <ng-container matColumnDef="email">
              <mat-header-cell *matHeaderCellDef fxLayoutAlign="start center">
                {{ t('send-pick-split-list-dialog.email') | uppercase }}
              </mat-header-cell>
              <mat-cell
                *matCellDef="let producer; dataSource: dataSource"
                [formGroupName]="producer.id"
                (click)="$event.stopPropagation()"
                fxLayoutAlign="start center"
              >
                <mat-form-field>
                  <input
                    matInput
                    name="producer-name"
                    type="email"
                    formControlName="email"
                    data-testid="email-input-test"
                  />
                  <mat-error *ngIf="producerEmailForm.hasError('required')">{{
                    t('send-pick-split-list-dialog.required-email')
                  }}</mat-error>
                </mat-form-field>
              </mat-cell>
            </ng-container>

            <mat-header-row
              *matHeaderRowDef="DISPLAYED_COLUMNS"
            ></mat-header-row>
            <mat-row
              *matRowDef="let producer; columns: DISPLAYED_COLUMNS"
              (click)="toggleCheckbox(producer.id)"
            ></mat-row>
          </mat-table>
          <mat-error *ngIf="!isProducerEmailFormValid">{{
            t('send-pick-split-list-dialog.no-email-selected-error')
          }}</mat-error>
        </form>

        <mat-dialog-actions align="end" class="table-action-button">
          <button
            mat-raised-button
            mat-dialog-close
            type="button"
            data-testid="cancel-button-test"
          >
            {{ t('send-pick-split-list-dialog.cancel') }}
          </button>
          <button
            mat-raised-button
            matStepperNext
            class="step-button"
            type="button"
            color="accent"
          >
            {{ t('send-pick-split-list-dialog.next-step') }}
          </button>
        </mat-dialog-actions>
      </mat-step>

      <!-- SECOND STEPPER: DELIVERY MESSAGE -->
      <mat-step
        [stepControl]="deliveryMessageForm"
        [label]="t('send-pick-split-list-dialog.message')"
      >
        <form [formGroup]="deliveryMessageForm">
          <mat-form-field appearance="outline">
            <mat-label>{{
              t('send-pick-split-list-dialog.message-label')
            }}</mat-label>
            <textarea
              matInput
              formControlName="message"
              rows="5"
              data-testid="delivery-message-input-test-id"
            ></textarea>
          </mat-form-field>

          <div fxLayout="column" fxLayoutGap="2rem">
            <!-- Instruction message -->
            <div fxLayout="column" class="text-m">
              <span class="medium-bold">{{
                t(
                  'send-pick-split-list-dialog.general-delivery-instruction-title'
                )
              }}</span>
              <span class="text-s light text-italic">{{
                t(
                  'send-pick-split-list-dialog.general-delivery-instruction-subtitle'
                )
              }}</span>
              <span
                *ngIf="
                  data.generalDeliveryMessageFromSettings;
                  else noGeneralDeliveryInstruction
                "
                class="light"
              >
                {{ data.generalDeliveryMessageFromSettings }}
              </span>
              <ng-template class="light" #noGeneralDeliveryInstruction>
                <span>{{
                  t(
                    'send-pick-split-list-dialog.no-general-delivery-instruction'
                  )
                }}</span>
              </ng-template>
            </div>

            <!-- Documents to include -->
            <mat-form-field appearance="outline" floatLabel="always">
              <mat-label>{{
                t('send-pick-split-list-dialog.include') + ':'
              }}</mat-label>
              <mat-select
                multiple
                matInput
                formControlName="includedDocuments"
                data-testid="included-documents-select-testid"
              >
                <mat-option
                  *ngFor="let doc_type of SORTED_SCHEDULED_DELIVERIES_PDF_TYPE"
                  [value]="doc_type"
                >
                  {{ t('send-pick-split-list-dialog.' + doc_type) }}
                </mat-option>
              </mat-select>
              <mat-error>
                {{
                  t('send-pick-split-list-dialog.no-documents-selected-error')
                }}
              </mat-error>
            </mat-form-field>
          </div>
        </form>

        <mat-dialog-actions align="end">
          <button mat-raised-button matStepperPrevious type="button">
            {{ t('send-pick-split-list-dialog.previous-step') }}
          </button>
          <button
            mat-raised-button
            matStepperNext
            class="step-button"
            type="button"
            color="accent"
            data-testid="send-button-test"
            (click)="sendPickSplitListToProducers()"
          >
            {{ t('send-pick-split-list-dialog.send') }}
          </button>
        </mat-dialog-actions>
      </mat-step>
    </mat-stepper>
  </mat-card-content>
</ng-container>
