import * as _ from 'lodash';

import { createFeatureSelector, createSelector } from '@ngrx/store';

import { createSelectors } from '@arrivage-store/generators';
import { Format, WithId } from '@arrivage/model/dist/src/model';

import { adapter } from './formats.reducer';
import { FormatsState, State } from './formats.state';

export const getFormatState = createFeatureSelector<FormatsState>('formats');

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
  connecting,
  connected,
  connectState,
  queryFailure,
  addFailure,
  setFailure,
  updateFailure,
  removeFailure,
  getById,
  selectActiveItem,
  isLoadingActiveItem,
  selectActiveItemState,
} = createSelectors<Format, State>(adapter, getFormatState);

export const getFormatsByProductId = (productId: string) =>
  createSelector(selectAll, (formats: (Format & WithId)[]) => {
    return _.filter(formats, (f) => f.productId === productId);
  });
