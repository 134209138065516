<ng-container *transloco="let t; scope: 'priceLists'; read: 'priceLists'">
  <h2 class="mat-dialog-title text-xxl">
    {{ t('share-to-customers-dialog.title') }}
  </h2>
  <div class="mat-dialog-content">
    <mat-card-content>
      <ng-container *ngIf="nothingIsEligibleForSharing; else sharingProcess">
        <app-system-message
          class="message"
          type="warning"
          [showBorder]="false"
          [message]="t('system-message.no-price-list-eligible-for-sharing')"
          [description]="
            t('system-message.no-price-list-eligible-for-sharing-description')
          "
        ></app-system-message>
      </ng-container>

      <ng-template #sharingProcess>
        <form [formGroup]="form">
          <mat-stepper
            #stepper
            [orientation]="'vertical'"
            [linear]="true"
            animationDuration="500"
          >
            <mat-step
              *ngFor="let item of dataValues; let i = index"
              [editable]="false"
            >
              <span *matStepLabel>{{ item.priceList.name }}</span>
              <div fxLayout="column" fxLayoutGap="16px">
                <mat-card-content
                  *ngIf="
                    item.customersAccess.allowed.length > 1;
                    else sendToOneCustomer
                  "
                  fxLayout="column"
                  fxLayoutGap="1rem"
                >
                  <app-system-message
                    *ngIf="someHaveDeliveryIssues(item.offer)"
                    data-testid="some-have-delivery-issues-testid"
                    class="message"
                    type="warning"
                    [message]="t('system-message.delivery-issues')"
                    [description]="
                      t('system-message.some-delivery-issues-description')
                    "
                  ></app-system-message>

                  <app-system-message
                    *ngIf="someHaveOnlyPickup(item.offer)"
                    class="message"
                    type="info"
                    [message]="t('system-message.some-have-pickup-only')"
                    [description]="
                      t('system-message.some-have-pickup-only-description')
                    "
                  ></app-system-message>

                  <p class="description">
                    {{
                      t(
                        'share-to-customers-dialog.share-to-customers-description'
                      )
                    }}
                  </p>
                  <mat-table
                    *ngIf="initialized"
                    matSort
                    fxFlex
                    [dataSource]="dataSourceByOffer[item.offer.id]"
                    [trackBy]="trackById"
                  >
                    <ng-container matColumnDef="select">
                      <mat-header-cell *matHeaderCellDef fxFlex="50px">
                        <mat-checkbox
                          [checked]="
                            dataSelectionByOffer[item.offer.id].hasValue() &&
                            isAllSelected(item.offer)
                          "
                          [indeterminate]="
                            dataSelectionByOffer[item.offer.id].hasValue() &&
                            !isAllSelected(item.offer)
                          "
                          (change)="masterToggle(item.offer)"
                        >
                        </mat-checkbox>
                      </mat-header-cell>
                      <mat-cell *matCellDef="let row" fxFlex="50px">
                        <mat-checkbox
                          data-testid="checkbox-testid"
                          [checked]="
                            dataSelectionByOffer[item.offer.id].isSelected(row)
                          "
                          [disabled]="!isEligibleForSharing(item.offer, row)"
                          (click)="$event.stopPropagation()"
                          (change)="
                            $event
                              ? dataSelectionByOffer[item.offer.id].toggle(row)
                              : null
                          "
                        >
                        </mat-checkbox>
                      </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="customers">
                      <mat-header-cell *matHeaderCellDef mat-sort-header>
                        {{ t('share-to-customers-dialog.customer-header') }}
                      </mat-header-cell>
                      <mat-cell *matCellDef="let row">
                        <section
                          fxLayout="row wrap"
                          fxLayoutAlign="space-between center"
                          fxLayoutGap="5px"
                        >
                          <div
                            fxLayout="row"
                            fxLayoutGap="1rem"
                            fxLayoutAlign="start center"
                            [class.disabled]="
                              !isEligibleForSharing(item.offer, row)
                            "
                          >
                            <img
                              *ngIf="row?.logoUrl"
                              class="img-extra-small img-logo"
                              [src]="row.logoUrl"
                            />
                            <div>{{ row.name }}</div>
                          </div>

                          <mat-icon
                            *ngIf="isOnlyPickupAvailable(item.offer, row)"
                            class="text-color-warning material-icons-outlined"
                            [customTooltip]="t('pickup-only')"
                            >shopping_bag</mat-icon
                          >

                          <button
                            *ngIf="!isEligibleForSharing(item.offer, row)"
                            mat-icon-button
                            class="text-error"
                            [customTooltip]="t('customer-tooltip-notice')"
                            tooltipPosition="right"
                            tooltipColor="error"
                            [routerLink]="[
                              '/vendor',
                              'relationships',
                              'offers'
                            ]"
                          >
                            <mat-icon>error_outline</mat-icon>
                          </button>
                        </section>
                      </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="lastShareOfferTimestamp">
                      <mat-header-cell *matHeaderCellDef mat-sort-header>
                        {{ t('list.last-sent') }}
                      </mat-header-cell>

                      <mat-cell *matCellDef="let row" fxLayoutGap="10px">
                        <span
                          *ngIf="row?.lastShareOfferTimestamp"
                          [customTooltip]="
                            row?.lastShareOfferTimestamp | translocoDate
                          "
                        >
                          {{
                            t('list.last-share-offer-time', {
                              time:
                                row?.lastShareOfferTimestamp
                                | approximativeTimeRemaining
                            })
                          }}
                        </span>
                        <mat-icon
                          *ngIf="
                            dataSelectionByOffer[item.offer.id].isSelected(
                              row
                            ) &&
                            elapsedTimeLessThanHour(
                              row?.lastShareOfferTimestamp
                            )
                          "
                          class="text-color-warning"
                          >error_outline</mat-icon
                        >
                      </mat-cell>
                    </ng-container>

                    <mat-header-row
                      *matHeaderRowDef="displayedColumns"
                    ></mat-header-row>
                    <mat-row
                      *matRowDef="let row; columns: displayedColumns"
                      (click)="select(item.offer, row)"
                      [class.warning-row]="
                        dataSelectionByOffer[item.offer.id].isSelected(row) &&
                        elapsedTimeLessThanHour(row?.lastShareOfferTimestamp)
                      "
                    ></mat-row>
                  </mat-table>

                  <mat-paginator
                    [id]="i"
                    showFirstLastButtons
                    [pageSizeOptions]="[10, 25, 50]"
                  ></mat-paginator>
                </mat-card-content>
                <mat-card-content class="message-container">
                  <mat-form-field appearance="outline">
                    <mat-label>{{
                      t('share-to-customers-dialog.message-label')
                    }}</mat-label>
                    <textarea
                      cdkTextareaAutosize
                      matInput
                      rows="4"
                      formControlName="message"
                      [value]="getMessageFromPreviousOffer()"
                    ></textarea>
                    <mat-hint>{{
                      t('share-to-customers-dialog.message-hint')
                    }}</mat-hint>
                  </mat-form-field>
                  <div
                    class="text-m"
                    fxLayout="row"
                    fxLayoutAlign="start center"
                  >
                    <mat-checkbox
                      [checked]="isPdfAttach[item.offer.id]"
                      (change)="
                        onAttachPdfChange(item.offer.id, $event.checked)
                      "
                    >
                      {{ t('share-to-customers-dialog.attach-pdf') }}
                    </mat-checkbox>
                    <button
                      mat-icon-button
                      class="pdfIcon"
                      (click)="onOpenPdf(item)"
                    >
                      <mat-icon>description</mat-icon>
                    </button>
                  </div>
                  <div
                    class="inactive-product-checkbox-container text-m"
                    fxLayout="row"
                    fxLayoutAlign="start center"
                  >
                    <mat-checkbox
                      [checked]="includeInactiveProducts[item.offer.id]"
                      [disabled]="!isPdfAttach[item.offer.id]"
                      (change)="
                        includeInactiveProducts[item.offer.id] = $event.checked
                      "
                    >
                      {{
                        t(
                          'share-to-customers-dialog.include-unavailable-products'
                        )
                      }}
                    </mat-checkbox>
                  </div>
                </mat-card-content>
              </div>
              <ng-template #sendToOneCustomer>
                <mat-card-content>
                  <p class="description">
                    {{
                      t(
                        'share-to-customers-dialog.share-to-one-customer-description',
                        {
                          customer: item.customersAccess?.allowed[0]?.name
                        }
                      )
                    }}
                  </p>
                  <small
                    *ngIf="
                      item.customersAccess?.allowed[0]?.lastShareOfferTimestamp
                    "
                  >
                    <span
                      *ngIf="
                        (item.customersAccess?.allowed[0]
                          ?.lastShareOfferTimestamp | countDaysBetween) > 0;
                        else updatedToday
                      "
                      class="text-light"
                      [customTooltip]="
                        item.customersAccess?.allowed[0]
                          ?.lastShareOfferTimestamp | translocoDate
                      "
                      >{{
                        t('list.last-sent-time-notice', {
                          days:
                            item.customersAccess?.allowed[0]
                              ?.lastShareOfferTimestamp | countDaysBetween
                        })
                      }}</span
                    >
                    <ng-template #updatedToday>
                      <span>{{ t('list.updated-today') }}</span>
                    </ng-template>
                  </small>

                  <div fxLayout="column">
                    <app-system-message
                      *ngIf="
                        isOnlyPickupAvailable(
                          item.offer,
                          item.customersAccess?.allowed[0]
                        )
                      "
                      class="message"
                      type="info"
                      [message]="t('pickup-only')"
                    >
                    </app-system-message>
                    <app-system-message
                      *ngIf="
                        !isEligibleForSharing(
                          item.offer,
                          item.customersAccess?.allowed[0]
                        )
                      "
                      class="message"
                      type="warning"
                      [message]="t('customer-tooltip-notice')"
                    ></app-system-message>
                  </div>
                </mat-card-content>
              </ng-template>
              <div fxLayout="column">
                <app-system-message
                  *ngIf="!isMoreThanHour(item.offer)"
                  fxFlex
                  type="warning"
                  [message]="
                    t(
                      'share-to-customers-dialog.share-to-selected-customer-less-an-hour'
                    )
                  "
                >
                </app-system-message>
              </div>
            </mat-step>
          </mat-stepper>
        </form>
      </ng-template>
    </mat-card-content>
  </div>
  <!-- Boutons -->
  <mat-dialog-actions align="end">
    <ng-container *ngIf="!nothingIsEligibleForSharing; else closeButton">
      <button
        mat-raised-button
        [mat-dialog-close]="false"
        data-testid="cancel-button-test-id"
      >
        {{ t('share-to-customers-dialog.cancel') }}
      </button>
      <button
        mat-raised-button
        color="accent"
        [disabled]="dataSelectionByOffer[currentOfferId]?.selected.length === 0"
        (click)="goForward()"
      >
        <span>
          {{
            selectedIndex === numberOfOffers
              ? t('share-to-customers-dialog.share-all')
              : t('share-to-customers-dialog.stepper-next')
          }}</span
        >
      </button>
    </ng-container>
    <ng-template #closeButton>
      <button mat-raised-button [mat-dialog-close]="false">
        {{ t('close') }}
      </button></ng-template
    >
  </mat-dialog-actions>
</ng-container>
