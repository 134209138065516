import { EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Action, createReducer } from '@ngrx/store';

import {
  createInitialState,
  createEntityReducer,
} from '@arrivage-store/generators';
import { PriceListItem, WithId } from '@arrivage/model/dist/src/model';

import * as PriceListItemAction from './price-list-item.actions';
import { PriceListItemState } from './price-list-item.state';

export const adapter: EntityAdapter<PriceListItem & WithId> =
  createEntityAdapter<PriceListItem & WithId>();

export const initialState: PriceListItemState = createInitialState(adapter);

const priceListItemReducer = createReducer(
  initialState,
  ...createEntityReducer(PriceListItemAction, adapter)
);

export function reducer(state: PriceListItemState | undefined, action: Action) {
  return priceListItemReducer(state, action);
}
