import * as _ from 'lodash';
import { combineLatest, from, iif, Observable, of, pipe } from 'rxjs';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import {
  catchError,
  delay,
  filter,
  map,
  mergeMap,
  switchMap,
  take,
  takeUntil,
  tap,
  withLatestFrom,
} from 'rxjs/operators';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import * as fromAuth from '@arrivage-auth/store/auth.actions';
import { getAuthUser } from '@arrivage-auth/store/auth.selectors';
import { LoadingFeedback } from '@arrivage-components/loading-dialog/loading-feedback.model';
import * as organizationSettingsActions from '@arrivage-organization-settings/store/organization-settings.actions';
import * as organizationSettingsSelectors from '@arrivage-organization-settings/store/organization-settings.selectors';
import { MemberService } from '@arrivage-organization/services/member.service';
import { OrganizationService } from '@arrivage-organization/services/organization.service';
import { reportError } from '@arrivage-sentry/report-error';
import { IdService } from '@arrivage-services/id.service';
import { MainPageLoadService } from '@arrivage-services/main-page-load.service';
import { PictureService } from '@arrivage-services/picture.service';
import { UserService } from '@arrivage-user/services/user.service';
import { FileStorage } from '@arrivage/model/dist/src/model/fileStorage';

import { DeliveryZonesService } from '@arrivage-services/delivery-zones.service';
import { showFeedback } from '../feedback/feedback.actions';
import { State } from '../state';
import { AccountCreateService } from './account-create.service';
import * as actions from './context.actions';
import * as contextSelectors from './context.selectors';
import { LoadSequenceService } from './load-sequence.service';

interface ContextFeedback {
  update_organization: string;
  update_organization_with_picture: string;
  // error only
  loading_failed: string;
  query_user_by_id: string;
}

export const ContextFeedback: ContextFeedback = {
  update_organization: 'update_organization',
  update_organization_with_picture: 'update_organization_with_picture',
  // error only
  loading_failed: 'loading_failed',
  query_user_by_id: 'query_user_by_id',
  // for external customer
};

export interface SequenceEndNavigation {
  force: boolean;
  url?: string;
}

@Injectable()
export class ContextEffects {
  constructor(
    private actions$: Actions,
    private store: Store<State>,
    private router: Router,
    private userService: UserService,
    private memberService: MemberService,
    private pictureService: PictureService,
    private organizationService: OrganizationService,
    private idService: IdService,
    private loadingService: MainPageLoadService,
    private loadSequenceService: LoadSequenceService,
    private accountCreateService: AccountCreateService,
    private deliveryZonesService: DeliveryZonesService
  ) {}

  private loadSequence = pipe(
    tap(() => this.loadingService.start()),
    mergeMap((loadContextData: actions.LoadContextData) => {
      return from(this.loadSequenceService.loadSequence(loadContextData));
    }),
    tap(() => this.loadingService.end())
  );

  onMainAppLoadStart$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          fromAuth.SignInSuccess,
          fromAuth.Logout,
          actions.LoadContext,
          actions.AddUserInfo
        ),
        tap(() => {
          this.loadingService.start(LoadingFeedback.LOADING);
        })
      ),
    { dispatch: false }
  );

  onMainAppLoadStop$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          fromAuth.LogoutFailure,
          actions.LoadContextFailure,
          actions.AddUserInfoFailure
        ),
        tap(() => {
          this.loadingService.end();
        })
      ),
    { dispatch: false }
  );

  onSignInSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAuth.SignInSuccess),
      map((action) => {
        return {
          userId: action.user.uid,
        };
      }),
      this.loadSequence
    )
  );

  onLoadContext$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.LoadContext),
      map((action) => action.loadContextData),
      this.loadSequence
    )
  );

  onLoadSelectedOrganization$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actions.LoadSelectedOrganization),
        tap((membership) => {
          this.store.dispatch(
            actions.LoadConnectedUserActiveMembership(membership)
          );
          this.store.dispatch(
            actions.LoadConnectedUserActiveOrganization({
              organizationId: membership.membership.organizationId,
            })
          );
        }),
        switchMap(() => {
          return combineLatest([
            this.store.select(contextSelectors.getMembership),
            this.store.select(contextSelectors.getOrganization),
          ]).pipe(
            filter(([member, organization]) => !!member && !!organization),
            take(1),
            mergeMap(() => {
              this.store.dispatch(organizationSettingsActions.query());
              return this.store.select(
                organizationSettingsSelectors.selectSettings
              );
            }),
            filter((settings) => !!settings),
            take(1),
            map((s) => this.router.navigate(['/']))
          );
        })
      ),
    { dispatch: false }
  );

  loadConnectedUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.LoadConnectedUser),
      switchMap((action) => {
        return this.userService.getUser(action.userId).pipe(
          takeUntil(this.actions$.pipe(ofType(fromAuth.Logout))),
          map((user) => {
            return actions.SetConnectedUser({ user: user });
          }),
          catchError((e) => {
            reportError(e);
            return of(
              actions.SetConnectedUserFailure({ message: e.message }),
              showFeedback({
                success: false,
                feedback: ContextFeedback.loading_failed,
              })
            );
          })
        );
      })
    )
  );

  addUserInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.AddUserInfo),
      switchMap((action) => {
        return from(
          this.accountCreateService.createAccount(action.accountCreationInfo)
        ).pipe(
          map((member) =>
            actions.LoadNewOrganization({
              membership: {
                ...member,
                id: member.userId,
              },
            })
          ),
          catchError((e) => {
            reportError(e);
            return of(
              actions.AddUserInfoFailure({ message: e.message }),
              showFeedback({
                success: false,
                feedback: ContextFeedback.loading_failed,
              })
            );
          })
        );
      })
    )
  );

  onLoadNewOrganization$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.LoadNewOrganization),
      tap(() => this.loadingService.start()),
      withLatestFrom(this.store.select(getAuthUser)),
      mergeMap(([action, authUser]) => {
        return from(
          this.loadSequenceService.loadFromMembership(
            action.membership,
            authUser
          )
        );
      }),
      tap(() => this.loadingService.end())
    )
  );

  loadConnectedUserMemberships$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.LoadConnectedUserMemberships),
      switchMap((action) => {
        return this.memberService.getUserMemberships(action.userId).pipe(
          takeUntil(this.actions$.pipe(ofType(fromAuth.Logout))),
          map((memberships) => {
            return actions.SetConnectedUserMemberships({
              memberships: memberships,
            });
          }),
          catchError((e) => {
            reportError(e);
            return of(
              actions.SetConnectedUserMembershipsFailure({
                message: e.message,
              }),
              showFeedback({
                success: false,
                feedback: ContextFeedback.loading_failed,
              })
            );
          })
        );
      })
    )
  );

  loadConnectedUserActiveMembership$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.LoadConnectedUserActiveMembership),
      switchMap((action) => {
        return this.memberService
          .getMember(action.membership.organizationId, action.membership.id)
          .pipe(
            takeUntil(this.actions$.pipe(ofType(fromAuth.Logout))),
            map((membership) => {
              if (membership) {
                return actions.SetConnectedUserActiveMembership({
                  membership: membership,
                });
              } else {
                throw new Error('Could not load membership');
              }
            }),
            catchError((e) => {
              reportError(e);

              return of(
                actions.SetConnectedUserActiveMembershipFailure({
                  message: e.message,
                }),
                showFeedback({
                  success: false,
                  feedback: ContextFeedback.loading_failed,
                })
              );
            })
          );
      })
    )
  );

  loadConnectedUserActiveOrganization$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.LoadConnectedUserActiveOrganization),
      switchMap((action) => {
        return combineLatest([
          this.organizationService.getOrganization(action.organizationId),
          this.deliveryZonesService.isLoaded(),
        ]).pipe(
          takeUntil(this.actions$.pipe(ofType(fromAuth.Logout))),
          map(([organization, isLoaded]) => organization),
          map((organization) => {
            if (organization) {
              return actions.SetConnectedUserActiveOrganization({
                organization: organization,
                zones: _.map(
                  this.deliveryZonesService.findZonesFor(organization.location),
                  (z) => z.code
                ),
              });
            } else {
              throw new Error('Could not load organization');
            }
          }),
          catchError((e) => {
            reportError(e);
            return of(
              actions.SetConnectedUserActiveOrganizationFailure({
                message: e.message,
              }),
              showFeedback({
                success: false,
                feedback: ContextFeedback.loading_failed,
              })
            );
          })
        );
      })
    )
  );

  loadContextFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.LoadContextFailure),
      delay(2000),
      map(() => fromAuth.Logout({}))
    )
  );

  updateOrganization$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.updateOrganization),
      mergeMap((update) => {
        const pictureUpload: Observable<string> = iif(
          () => !!update.record.newPictureFile,
          from(
            this.pictureService.uploadPicture(
              update.record.newPictureFile,
              'organizations/' +
                update.record.organization.id +
                '/pictureUrl' +
                this.idService.createId()
            )
          ),
          of(null)
        );
        const logoUpload: Observable<string> = iif(
          () => !!update.record.newLogoFile,
          from(
            this.pictureService.uploadPicture(
              update.record.newLogoFile,
              'organizations/' +
                update.record.organization.id +
                '/logoUrl' +
                this.idService.createId()
            )
          ),
          of(null)
        );
        const bannerUpload: Observable<string> = iif(
          () => !!update.record.newBannerFile,
          from(
            this.pictureService.uploadPicture(
              update.record.newBannerFile,
              'organizations/' +
                update.record.organization.id +
                '/bannerUrl' +
                this.idService.createId()
            )
          ),
          of(null)
        );
        const permitsUpload: Observable<FileStorage[]> = iif(
          () => update.record?.newPermits?.length > 0,
          from(
            Promise.all(
              update.record.newPermits.map(async (p) => {
                const split = p.name.split('.');
                const extension = split[split.length - 1];
                const url = await this.pictureService.uploadPicture(
                  p,
                  'organizations/' +
                    update.record.organization.id +
                    '/permits/' +
                    this.idService.createId() +
                    '.' +
                    extension
                );
                return { type: p.type, url: url };
              })
            )
          ),
          of(null)
        );

        return combineLatest([
          pictureUpload,
          logoUpload,
          bannerUpload,
          permitsUpload,
        ]).pipe(
          mergeMap(([pictureUrl, logoUrl, bannerUrl, permitsUrl]) => {
            let organizationUpdateRecord = { ...update.record.organization };
            if (pictureUrl) {
              organizationUpdateRecord.pictureUrl = pictureUrl;
            }

            if (logoUrl) {
              organizationUpdateRecord.logoUrl = logoUrl;
            }

            if (bannerUrl) {
              organizationUpdateRecord.bannerUrl = bannerUrl;
            }

            if (permitsUrl?.length > 0) {
              const updatePermitsUrl =
                update.record.organization?.vendorProfile?.permits?.length > 0
                  ? _.filter(
                      update.record.organization.vendorProfile.permits,
                      (f) => f.url.includes('firebasestorage')
                    )
                  : [];

              updatePermitsUrl.push(...permitsUrl);

              organizationUpdateRecord = {
                ...organizationUpdateRecord,
                vendorProfile: {
                  ...organizationUpdateRecord.vendorProfile,
                  permits: updatePermitsUrl,
                },
              };
            }

            return from(
              this.organizationService.updateOrganization(
                {
                  ...organizationUpdateRecord,
                },
                update.record.organization.id
              )
            ).pipe(
              map(() => {
                update.confirmation.resolve(update.record.organization.id);
                return actions.updateOrganizationSuccess({
                  id: update.record.organization.id,
                });
              }),
              catchError((e) => {
                reportError(e);
                update.confirmation.reject(e);
                return of(
                  actions.updateOrganizationFailure({
                    errorMessage: e,
                  })
                );
              })
            );
          })
        );
      })
    )
  );

  updateUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.updateUser),
      mergeMap((update) => {
        const pictureUpload: Observable<string> = iif(
          () => !!update.record.newPictureFile,
          from(
            this.pictureService.uploadPicture(
              update.record.newPictureFile,
              'users/' +
                update.record.user.id +
                '/pictureUrl' +
                this.idService.createId()
            )
          ),
          of(null)
        );

        return from(pictureUpload).pipe(
          mergeMap((pictureUrl) => {
            const userUpdateRecord = { ...update.record.user };
            if (pictureUrl) {
              userUpdateRecord.pictureUrl = pictureUrl;
            }

            return from(
              this.userService.updateUser(
                {
                  ...userUpdateRecord,
                },
                update.record.user.id
              )
            ).pipe(
              map(() => {
                update.confirmation.resolve(update.record.user.id);
                return actions.updateUserSuccess({
                  id: update.record.user.id,
                });
              }),
              catchError((e) => {
                reportError(e);
                update.confirmation.reject(e);
                return of(
                  actions.updateUserFailure({
                    errorMessage: e,
                  })
                );
              })
            );
          })
        );
      })
    )
  );
}
