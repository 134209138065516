import { Observable } from 'rxjs';

import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';

import { AdminPurchaseOrdersService } from '@arrivage-admin/services/admin-purchase-orders.service';
import {
  queryAuthorizations,
  queryOffers,
  queryOrganizations,
  queryPremiumAccounts,
} from '@arrivage-admin/store/admin.actions';
import {
  getAllAuthorizationsById,
  getAllBuyers,
  getAllOffers,
  getAllActiveOrganizations,
  getAllSellers,
  getAllPremiumAccountById,
} from '@arrivage-admin/store/admin.selectors';
import {
  Offer,
  Organization,
  OrganizationAuthorizations,
  PremiumAccount,
  PurchaseOrder,
  WithId,
} from '@arrivage/model/dist/src/model';

import { State as AdminState } from '../store/admin.state';

@Injectable({
  providedIn: 'root',
})
export class AdminFacade {
  organizations$: Observable<(Organization & WithId)[]>;
  sellers$: Observable<(Organization & WithId)[]>;
  buyers$: Observable<(Organization & WithId)[]>;
  authorizationsById$: Observable<{
    [key: string]: OrganizationAuthorizations & WithId;
  }>;
  premiumAccountsById$: Observable<{
    [key: string]: PremiumAccount & WithId;
  }>;
  offers$: Observable<(Offer & WithId)[]>;
  purchaseOrders$: Observable<(PurchaseOrder & WithId)[]>;

  constructor(
    private store: Store<AdminState>,
    private purchaseOrderService: AdminPurchaseOrdersService
  ) {
    this.organizations$ = this.store.select(getAllActiveOrganizations);
    this.sellers$ = this.store.select(getAllSellers);
    this.buyers$ = this.store.select(getAllBuyers);
    this.authorizationsById$ = this.store.select(getAllAuthorizationsById);
    this.premiumAccountsById$ = this.store.select(getAllPremiumAccountById);
    this.offers$ = this.store.select(getAllOffers);
    this.purchaseOrders$ = this.purchaseOrderService.getAllOpenPurchaseOrder();
  }

  loadAllOrganizations() {
    this.store.dispatch(queryOrganizations());
  }

  loadAllOrganizationAuthorizations() {
    this.store.dispatch(queryAuthorizations());
  }

  loadAllPremiumAccounts() {
    this.store.dispatch(queryPremiumAccounts());
  }

  loadAllOffers() {
    this.store.dispatch(queryOffers());
  }

  getAllOrganizations() {
    return this.organizations$;
  }

  getSellers() {
    return this.sellers$;
  }

  getBuyers() {
    return this.buyers$;
  }

  getAuthorizationsById() {
    return this.authorizationsById$;
  }

  getPremiumAccountsById() {
    return this.premiumAccountsById$;
  }

  getOffers() {
    return this.offers$;
  }

  getPurchaseOrders() {
    return this.purchaseOrders$;
  }

  getPurchaseOrderById(id: string): Observable<PurchaseOrder & WithId> {
    return this.purchaseOrderService.getPurchaseOrderById(id);
  }
}
