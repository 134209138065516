import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { AddEmailDialogData } from '@arrivage-components/communication-preference-table/communication-preference-table.component';
import {
  ALREADY_EXIST_ERROR_VALUE,
  notInList,
} from '@arrivage-validators/custom-validators';

@Component({
  selector: 'app-add-email-dialog',
  templateUrl: './add-email-dialog.component.html',
  styleUrls: ['./add-email-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddEmailDialogComponent implements OnInit {
  readonly ALREADY_EXISTS_ERROR = ALREADY_EXIST_ERROR_VALUE;
  addEmailForm: FormControl;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: AddEmailDialogData,
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<AddEmailDialogComponent>
  ) {}

  ngOnInit() {
    this.addEmailForm = this.formBuilder.nonNullable.control(
      '',
      Validators.compose([
        Validators.email,
        Validators.required,
        notInList(this.data.existingEmails),
      ])
    );
  }

  onSubmit() {
    if (this.addEmailForm.valid) {
      this.dialogRef.close(this.addEmailForm.value);
    }
  }
}
