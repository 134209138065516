import { endOfDay, isPast } from 'date-fns';

import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { translate } from '@jsverse/transloco';

import { LoadingFeedback } from '@arrivage-components/loading-dialog/loading-feedback.model';
import { CustomerInvoiceFacade } from '@arrivage-invoices/customer/api/customer-invoice.facade';
import { VendorInvoiceFacade } from '@arrivage-invoices/vendor/api/vendor-invoice.facade';
import {
  PublishInvoiceDialogComponent,
  PublishInvoiceDialogResponse,
} from '@arrivage-invoices/vendor/components/publish-invoice-dialog/publish-invoice-dialog.component';
import { SendInvoiceDialogComponent } from '@arrivage-invoices/vendor/components/send-invoice-dialog/send-invoice-dialog.component';
import {
  PublishInvoiceData,
  SendInvoiceData,
} from '@arrivage-invoices/vendor/model/invoice.model';
import { reportError } from '@arrivage-sentry/report-error';
import { MainPageLoadService } from '@arrivage-services/main-page-load.service';
import { SnackbarService } from '@arrivage-snackbar/snackbar.service';
import { DownloadFileUtils } from '@arrivage-util/download-file.utils';
import { DeleteInvoiceRequestData } from '@arrivage/model/dist/src/cloud-functions-api';
import { Invoice, InvoiceStatus, WithId } from '@arrivage/model/dist/src/model';

import { InvoiceApiService } from '../api/invoice-api.service';
import { InvoiceFeedback } from '../store/generator';

export namespace InvoicesUtils {
  export interface InvoicesFilters {
    relationshipName: string;
    statuses: InvoiceStatus[];
    dateRange: Interval;
  }

  export function isLate(invoice: Invoice & WithId): boolean {
    return isPast(invoice.dueDate) && invoice.status !== InvoiceStatus.PAID;
  }

  export function sortStatus(status: InvoiceStatus): number {
    switch (status) {
      case InvoiceStatus.DRAFT:
        return 0;
      case InvoiceStatus.PUBLISHED:
      case InvoiceStatus.SENT:
        return 1;
      case InvoiceStatus.PAYMENT_EMITTED:
        return 2;
      case InvoiceStatus.PAID:
        return 3;
      case InvoiceStatus.CANCELED:
        return 4;
    }
  }

  export function sortIsLate(date: Date) {
    return !date ? 2 : isPast(endOfDay(date)) ? 0 : 1;
  }

  export function customNaturalSort(data: Invoice & WithId): string {
    return '' + sortStatus(data.status) + sortIsLate(data.dueDate);
  }

  export function generateInvoicePdfFileName(invoiceNumber?: string) {
    return `${translate<string>('invoice')}${
      invoiceNumber ? '_' + invoiceNumber : ''
    }`;
  }

  export function isPublished(invoiceData: InvoiceStatus) {
    return (
      invoiceData === InvoiceStatus.PUBLISHED ||
      invoiceData === InvoiceStatus.SENT // remove InvoiceStatus.SENT later (related to WEB-2731)
    );
  }

  export function canPublish(invoice: Invoice): boolean {
    return (
      invoice.status !== InvoiceStatus.CANCELED && !invoice.history?.publishDate
    );
  }

  /**
   * Deletes the invoice from a purchase order, whether internal or external
   *
   * @param data invoiceId and organizationId
   */
  export async function deleteInvoice(
    data: DeleteInvoiceRequestData,
    invoiceApiService: InvoiceApiService,
    mainPageLoadService: MainPageLoadService,
    snackbarService: SnackbarService
  ) {
    const action = InvoiceFeedback.remove;
    mainPageLoadService.start(LoadingFeedback.ON_DELETE_INVOICE);
    try {
      await invoiceApiService.deleteInvoice(data);
      snackbarService.showSuccess(action);
    } catch (e) {
      reportError(e);
      snackbarService.showError(action);
    } finally {
      mainPageLoadService.end();
    }
  }

  export async function downloadInternalInvoice(
    invoice: Invoice & WithId,
    organizationId: string,
    invoiceFacade: CustomerInvoiceFacade | VendorInvoiceFacade,
    mainPageLoadService: MainPageLoadService,
    snackbarService: SnackbarService
  ) {
    mainPageLoadService.start(LoadingFeedback.ON_GENERATE_INVOICE_PDF);
    const action = InvoiceFeedback.download_invoice;
    try {
      const url = await invoiceFacade.downloadInvoice(
        invoice.id,
        organizationId,
        invoice
      );
      await DownloadFileUtils.downloadFile(
        url,
        InvoicesUtils.generateInvoicePdfFileName(invoice?.number)
      );
    } catch (e) {
      reportError(e);
      snackbarService.showError(action);
    } finally {
      mainPageLoadService.end();
    }
  }

  export async function sendInvoice(
    data: SendInvoiceData,
    invoiceFacade: VendorInvoiceFacade,
    mainPageLoadService: MainPageLoadService,
    snackbarService: SnackbarService
  ) {
    const action = InvoiceFeedback.send_invoice;
    mainPageLoadService.start(LoadingFeedback.ON_SEND_INVOICE);

    try {
      const sendInvoiceResponse = await invoiceFacade.sendInvoice(data);
      snackbarService.showSuccess(action);
      return sendInvoiceResponse;
    } catch (e) {
      reportError(e);
      snackbarService.showError(action);
    } finally {
      mainPageLoadService.end();
    }
  }

  export async function publishInvoice(
    data: PublishInvoiceData,
    invoiceFacade: VendorInvoiceFacade,
    mainPageLoadService: MainPageLoadService,
    snackbarService: SnackbarService
  ) {
    const action = InvoiceFeedback.publish_invoice;
    mainPageLoadService.start(LoadingFeedback.ON_PUBLISH_INVOICE);

    try {
      const publishInvoiceResponse = await invoiceFacade.publishInvoice(data);
      snackbarService.showSuccess(action);
      return publishInvoiceResponse;
    } catch (e) {
      reportError(e);
      snackbarService.showError(action);
    } finally {
      mainPageLoadService.end();
    }
  }

  export function openPublishInvoiceDialog(
    data: SendInvoiceData,
    dialog: MatDialog,
    invoiceFacade: VendorInvoiceFacade,
    mainPageLoadService: MainPageLoadService,
    snackbarService: SnackbarService
  ) {
    const dialogRef: MatDialogRef<
      PublishInvoiceDialogComponent,
      PublishInvoiceDialogResponse
    > = PublishInvoiceDialogComponent.openDialog(dialog, data.invoice);
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        await publishInvoice(
          data,
          invoiceFacade,
          mainPageLoadService,
          snackbarService
        );

        if (result.shouldSendInvoice) {
          const dataWithMessage: SendInvoiceData = {
            ...data,
            messageToCustomers: result.message,
          };
          await sendInvoice(
            dataWithMessage,
            invoiceFacade,
            mainPageLoadService,
            snackbarService
          );
        }
      }
    });
  }

  export async function openSendInvoiceDialog(
    data: SendInvoiceData,
    dialog: MatDialog,
    invoiceFacade: VendorInvoiceFacade,
    mainPageLoadService: MainPageLoadService,
    snackbarService: SnackbarService
  ) {
    const dialogRef = SendInvoiceDialogComponent.openDialog(
      dialog,
      data.invoice
    );

    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        if (canPublish(data.invoice)) {
          await publishInvoice(
            {
              organization: data.organization,
              invoice: data.invoice,
            },
            invoiceFacade,
            mainPageLoadService,
            snackbarService
          );
        }

        const dataWithMessage: SendInvoiceData = {
          ...data,
          messageToCustomers: result.message,
        };
        await sendInvoice(
          dataWithMessage,
          invoiceFacade,
          mainPageLoadService,
          snackbarService
        );
      }
    });
  }
}
