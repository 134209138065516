import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AnalyticsFacade } from '@arrivage-analytics/api/analytics.facade';

import {
  OfferItem,
  OrderItem,
  Product,
  WithId,
} from '@arrivage/model/dist/src/model';

@Component({
  selector: 'app-product-info-dialog',
  templateUrl: './product-info-dialog.component.html',
  styleUrls: ['./product-info-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductInfoDialogComponent implements OnInit {
  constructor(
    private analytics: AnalyticsFacade,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      product: Product & WithId;
      offerItems: OfferItem[];
      isExtraSmallScreen: boolean;
      showPriceAndStock: boolean;
      orderItem: OrderItem;
    }
  ) {}

  ngOnInit() {
    this.analytics.logClickOnProductDetails();
  }
}
