import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { SystemMessageType } from './system-message.component';

export interface SystemMessageDialogData {
  type: SystemMessageType;
  title: string;
  message: string;
  description?: string;
}

@Component({
  selector: 'app-message-dialog',
  templateUrl: './system-message-dialog.component.html',
  styleUrls: ['./system-message-dialog.component.scss'],
})
export class SystemMessageDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<SystemMessageDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: SystemMessageDialogData
  ) {}
}
