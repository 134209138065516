import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';

import {
  Notification,
  NotificationType,
  WithId,
} from '@arrivage/model/dist/src/model';

import { NotificationService } from '../../services/notifications.service';

@Component({
  selector: 'app-notif-dropdown',
  templateUrl: './notif-dropdown.component.html',
  styleUrls: ['./notif-dropdown.component.scss'],
})
export class NotifDropdownComponent {
  readonly MAX_NOTIFICATIONS = NotificationService.MAX_NOTIFICATIONS;
  private _notifications: (Notification & WithId)[];

  nbUnread = 0;

  @Input()
  set notifications(value: (Notification & WithId)[]) {
    this._notifications = value;
    this.nbUnread = this._notifications.filter(
      (notification) => !notification.isRead
    ).length;
  }

  get notifications(): (Notification & WithId)[] {
    return this._notifications;
  }

  @Output('notificationRead')
  notificationReadEmitter = new EventEmitter<string>();

  constructor(private router: Router) {}

  notificationRead(notification: Notification & WithId) {
    this.notificationReadEmitter.emit(notification.id);
    this.router.navigateByUrl(Notification.getRelatedUrl(notification));
  }

  getDefaultIcon(notificationType: NotificationType): string {
    switch (notificationType) {
      case NotificationType.FORMAT_OUT_OF_STOCK_AFTER_ORDER:
        return 'warning';
      default:
        return 'notifications';
    }
  }
}
