import { translate } from '@jsverse/transloco';

import { Platform } from '@angular/cdk/platform';
import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { reportError } from '@arrivage-sentry/report-error';
import { DialogUtils } from '@arrivage-util/dialog.utils';

@Component({
  selector: 'app-share-buttons',
  templateUrl: './share-buttons.component.html',
  styleUrls: ['./share-buttons.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShareButtonsComponent {
  @Input()
  isNavLink = false;

  @Input()
  isExpanded = true;

  @Output()
  click = new EventEmitter<void>();

  constructor(
    private platform: Platform,
    private dialog: MatDialog
  ) {}

  get canDefaultShare(): boolean {
    return !!navigator.share;
  }

  get isMobileOrTablet(): boolean {
    return this.platform.ANDROID || this.platform.IOS;
  }

  onDefaultShare() {
    navigator
      .share({
        title: translate('components.share-buttons.info-title'),
        text: translate('components.share-buttons.info-description'),
        url: 'https://arrivage.com/',
      })
      .then(() => this.emitClickEvent())
      .catch((e) => reportError(e));
  }

  onCustomShare() {
    this.emitClickEvent();
    DialogUtils.openShareButtonsDialog(this.dialog);
  }

  private emitClickEvent() {
    if (this.isNavLink) {
      this.click.emit();
    }
  }
}
