import { EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Action, createReducer } from '@ngrx/store';

import {
  createEntityReducer,
  createInitialState,
} from '@arrivage-store/generators';
import { Producer, WithId } from '@arrivage/model/dist/src/model';

import * as ProducersActions from './producers.actions';
import { ProducersState } from './producers.state';

export const adapter: EntityAdapter<Producer & WithId> = createEntityAdapter<
  Producer & WithId
>();

export const initialState: ProducersState = createInitialState(adapter);

const producersReducer = createReducer(
  initialState,
  ...createEntityReducer(ProducersActions, adapter)
);

export function reducer(state: ProducersState | undefined, action: Action) {
  return producersReducer(state, action);
}
