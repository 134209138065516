import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { ProductUtils } from '@arrivage-util/product.utils';
import { Format, Money } from '@arrivage/model/dist/src/model';

@Component({
  selector: 'app-format-price',
  templateUrl: './format-price.component.html',
  styleUrls: ['./format-price.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormatPriceComponent {
  @Input()
  price: Money;

  @Input()
  regularPrice: Money | null;

  @Input()
  format: Format;

  @Input()
  align: 'start' | 'end' = 'end';

  @Input()
  size: 'small' | 'large' = 'small';

  constructor() {}

  get reducedPricePercentage(): number {
    return ProductUtils.getReducedPricePercentage(
      this.regularPrice,
      this.price
    );
  }
}
