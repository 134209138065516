import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';

import { AnalyticsFacade } from '@arrivage-analytics/api/analytics.facade';
import { EntitiesFacade } from '@arrivage-store/api/entities.facade';
import { State } from '@arrivage-store/state';
import { ShareOfferRequestData } from '@arrivage/model/dist/src/cloud-functions-api';
import { Offer } from '@arrivage/model/dist/src/model';

import * as offersActions from '../store/offers.actions';
import * as offersSelector from '../store/offers.selectors';
import { VendorOffersApiService } from './vendor-offers.api.service';

@Injectable({
  providedIn: 'root',
})
export class VendorOfferFacade extends EntitiesFacade<Offer, State> {
  constructor(
    store: Store<State>,
    private vendorOffersApi: VendorOffersApiService,
    private analytics: AnalyticsFacade
  ) {
    super(store, offersActions, offersSelector);
  }

  shareOffer(shareOfferRequestData: ShareOfferRequestData): Promise<void> {
    return this.vendorOffersApi
      .shareOffer(shareOfferRequestData)
      .then(() => this.analytics.logSharePriceList());
  }
}
