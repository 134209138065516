import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'app-label-is-hundred-percent-local',
  templateUrl: './label-is-hundred-percent-local.component.html',
  styleUrls: ['./label-is-hundred-percent-local.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LabelIsHundredPercentLocalComponent {
  @Input()
  small = false;

  constructor() {}
}
