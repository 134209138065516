import { TranslocoModule } from '@jsverse/transloco';
import { MaterialModule } from 'src/app/material.module';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { YesNoDialogComponent } from './yes-no-dialog.component';

@NgModule({
  declarations: [YesNoDialogComponent],
  imports: [CommonModule, MaterialModule, TranslocoModule],
  exports: [YesNoDialogComponent],
})
export class YesNoDialogModule {}
