import { translate } from '@jsverse/transloco';
import { interval, Subject } from 'rxjs';

import { Injectable, OnDestroy } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SwUpdate } from '@angular/service-worker';

import { take, tap, takeUntil, filter } from 'rxjs/operators';

import { reportError } from '@arrivage-sentry/report-error';

@Injectable({
  providedIn: 'root',
})
export class SoftwareUpdateService implements OnDestroy {
  private readonly checkInterval = 1000 * 60 * 2; // 2 minutes
  private onDestroy = new Subject<void>();

  constructor(
    private swUpdate: SwUpdate,
    private snackbar: MatSnackBar
  ) {}

  initialize() {
    console.log(`Service worker enabled: ${this.swUpdate.isEnabled}`);

    if (this.swUpdate.isEnabled) {
      interval(this.checkInterval)
        .pipe(
          tap(() => console.log('Checking for application update...')),
          takeUntil(this.onDestroy)
        )
        .subscribe(() => this.swUpdate.checkForUpdate());

      this.swUpdate.versionUpdates
        .pipe(
          takeUntil(this.onDestroy),
          filter((versionEvent) => versionEvent?.type === 'VERSION_READY')
        )
        .subscribe((update) => {
          console.log(`Available update ${JSON.stringify(update)}`);
          const snack = this.snackbar.open(
            translate('software-update.available'),
            translate('software-update.apply')
          );

          snack
            .onAction()
            .pipe(take(1))
            .subscribe(() => {
              this.swUpdate.activateUpdate().then(() => {
                window.location.reload();
              });
              snack.dismiss();
            });
        });

      // Request an immediate page reload once an unrecoverable state has been detected.
      this.swUpdate.unrecoverable
        .pipe(
          takeUntil(this.onDestroy),
          tap((event) => reportError(`Unrecoverable state: ${event.reason}`))
        )
        .subscribe(() => {
          window.location.reload();
        });
    }
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
  }
}
