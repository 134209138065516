import { createAction, props } from '@ngrx/store';

import { Confirmation } from '@arrivage-store/generators';
import { Invoice, WithId } from '@arrivage/model/dist/src/model';

import {
  generateInvoiceActions,
  InvoiceActions,
} from '../../common/store/generator';
import {
  EditInvoiceData,
  PublishInvoiceData,
  ResetInvoiceData,
  SendInvoiceData,
} from '../model/invoice.model';

const featureName = 'VendorsInvoices';

export const VendorInvoicesActions: InvoiceActions =
  generateInvoiceActions(featureName);

export const updateInvoice = createAction(
  `[${featureName}] Update Invoice`,
  props<{
    invoice: Invoice & WithId;
    confirmation: Confirmation<string, any>;
  }>()
);

export const updateInvoiceSuccess = createAction(
  `[${featureName}] Update Invoice Success`
);

export const updateInvoiceFailure = createAction(
  `[${featureName}] Update Invoice Failure`,
  props<{
    error: any;
  }>()
);

export const sendInvoice = createAction(
  `[${featureName}] Send Invoice`,
  props<{
    record: SendInvoiceData;
    confirmation: Confirmation<string, any>;
  }>()
);

export const publishInvoice = createAction(
  `[${featureName}] Publish Invoice`,
  props<{
    record: PublishInvoiceData;
    confirmation: Confirmation<string, any>;
  }>()
);

export const sendInvoiceSuccess = createAction(
  `[${featureName}] Send Invoice Success`
);

export const sendInvoiceFailure = createAction(
  `[${featureName}] Send Invoice Failure`
);

export const publishInvoiceSuccess = createAction(
  `[${featureName}] Publish Invoice Success`
);

export const publishInvoiceFailure = createAction(
  `[${featureName}] Publish Invoice Failure`
);

export const cancelInvoice = createAction(
  `[${featureName}] Cancel Invoice`,
  props<{
    record: EditInvoiceData;
    confirmation: Confirmation<string, any>;
  }>()
);

export const cancelInvoiceSuccess = createAction(
  `[${featureName}] Cancel Invoice Success`
);

export const cancelInvoiceFailure = createAction(
  `[${featureName}] Cancel Invoice Failure`
);

export const resetInvoice = createAction(
  `[${featureName}] Reset Invoice`,
  props<{
    record: ResetInvoiceData;
    confirmation: Confirmation<string, any>;
  }>()
);

export const resetInvoiceSuccess = createAction(
  `[${featureName}] Reset Invoice Success`
);

export const resetInvoiceFailure = createAction(
  `[${featureName}] Reset Invoice Failure`
);

export const markInvoiceAsPaid = createAction(
  `[${featureName}] MarkAsPaid Invoice`,
  props<{
    record: EditInvoiceData;
    confirmation: Confirmation<string, any>;
  }>()
);

export const markInvoiceAsPaidSuccess = createAction(
  `[${featureName}] MarkAsPaid Invoice Success`
);

export const markInvoiceAsPaidFailure = createAction(
  `[${featureName}] MarkAsPaid Invoice Failure`
);

export const markInvoiceAsExported = createAction(
  `[${featureName}] MarkAsExported Invoice`,
  props<{
    organizationId: string;
    invoiceIds: string[];
    confirmation: Confirmation<void, any>;
  }>()
);

export const markInvoiceAsExportedSuccess = createAction(
  `[${featureName}] MarkAsExported Invoice Success`
);

export const markInvoiceAsExportedFailure = createAction(
  `[${featureName}] MarkAsExported Invoice Failure`
);
