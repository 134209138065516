import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { PurchaseOrderTotalPipe } from './purchase-order-total.pipe';

@NgModule({
  declarations: [PurchaseOrderTotalPipe],
  imports: [CommonModule],
  exports: [PurchaseOrderTotalPipe],
})
export class PurchaseOrderTotalPipeModule {}
