import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { CustomerDeliveryEffects } from './customer-delivery.effects';
import { reducer } from './customer-delivery.reducer';
import { CustomerDeliveryStateName } from './customer-delivery.state';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(CustomerDeliveryStateName, reducer),
    EffectsModule.forFeature([CustomerDeliveryEffects]),
  ],
})
export class CustomerDeliveryStoreModule {}
