/**
 * This file contains the selectors for the relationship infos.
 *
 * Every selector has two versions:
 * - The first one takes the informations from the RelationshipInfo, in the `relationship` sublevel collection.
 * - The second one takes the informations from the RelationshipInfo and update it with the organization summary from the relationship.
 *
 * We should always use the second version, except when we need to access the data entered by the user in the relationshipInfo.
 * Currently, the only place where we need to access the data entered by the user is in the form to edit the relationshipInfo.
 */
import _ from 'lodash';
import { LangUtils } from 'src/app/util/lang.utils';

import { createFeatureSelector, createSelector } from '@ngrx/store';

import { selectSettings } from '@arrivage-organization-settings/store/organization-settings.selectors';
import {
  selectAll as selectAllRelationships,
  selectCurrentRelationId,
} from '@arrivage-relationship/common/store/relationship.selectors';
import { createSelectors } from '@arrivage-store/generators';
import { State as RootState } from '@arrivage-store/state';
import {
  OrganizationSettings,
  Relationship,
  RelationshipInfo,
  WithId,
} from '@arrivage/model/dist/src/model';

import { PaymentTerms } from '../model/relationship.model';
import { adapter } from './relationship-infos.reducer';
import { RelationshipInfosState, State } from './relationship-infos.state';

export const getRelationshipInfoState =
  createFeatureSelector<RelationshipInfosState>('relationshipInfos');

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
  connecting,
  connected,
  connectState,
  queryFailure,
  addFailure,
  setFailure,
  updateFailure,
  removeFailure,
  getById,
  selectActiveItem,
  isLoadingActiveItem,
  selectActiveItemState,
} = createSelectors<RelationshipInfo, State>(adapter, getRelationshipInfoState);

/**
 * Return all the relationship infos updated with the organization summary from the relationship.
 */
export const selectAllUpdatedRelationshipInfos = createSelector<
  RootState,
  [(RelationshipInfo & WithId)[], (Relationship & WithId)[]],
  (RelationshipInfo & WithId)[]
>(selectAll, selectAllRelationships, (relationshipInfos, relationships) => {
  return relationshipInfos.map((relationshipInfo) => {
    const relationship = relationships.find(
      (relationship) => relationship.id === relationshipInfo.relationshipId
    );
    if (!relationship) {
      return relationshipInfo;
    }

    const organizationSummary =
      relationship.participantsData[relationshipInfo.organizationId];
    if (!organizationSummary) {
      return relationshipInfo;
    }

    return {
      ...relationshipInfo,
      ...organizationSummary,
    };
  });
});

export const selectCurrentRelationshipInfo = createSelector<
  RootState,
  [(RelationshipInfo & WithId)[], string],
  (RelationshipInfo & WithId) | undefined
>(selectAll, selectCurrentRelationId, (relationshipInfos, relationshipId) => {
  return relationshipInfos.find(
    (relationshipInfo) => relationshipInfo.relationshipId === relationshipId
  );
});

export const selectCurrentUpdatedRelationshipInfo = createSelector<
  RootState,
  [(RelationshipInfo & WithId)[], string],
  (RelationshipInfo & WithId) | undefined
>(
  selectAllUpdatedRelationshipInfos,
  selectCurrentRelationId,
  (relationshipInfos, relationshipId) => {
    return relationshipInfos.find(
      (relationshipInfo) => relationshipInfo.relationshipId === relationshipId
    );
  }
);

/**
 * Only used in this file.
 */
const selectAllCustomers = createSelector<
  RootState,
  [(RelationshipInfo & WithId)[]],
  (RelationshipInfo & WithId)[]
>(selectAll, (relationshipInfos) =>
  _.filter(relationshipInfos, (relationshipInfo) => relationshipInfo.isCustomer)
);

/**
 * Only used in this file.
 */
const selectAllUpdatedCustomers = createSelector<
  RootState,
  [(RelationshipInfo & WithId)[]],
  (RelationshipInfo & WithId)[]
>(selectAllUpdatedRelationshipInfos, (relationshipInfos) =>
  _.filter(relationshipInfos, (relationshipInfo) => relationshipInfo.isCustomer)
);

export const selectAllProviders = createSelector<
  RootState,
  [(RelationshipInfo & WithId)[]],
  (RelationshipInfo & WithId)[]
>(selectAll, (relationshipInfos) =>
  _.filter(relationshipInfos, (relationshipInfo) => relationshipInfo.isProvider)
);

export const selectAllUpdatedProviders = createSelector<
  RootState,
  [(RelationshipInfo & WithId)[]],
  (RelationshipInfo & WithId)[]
>(selectAllUpdatedRelationshipInfos, (relationshipInfos) =>
  _.filter(relationshipInfos, (relationshipInfo) => relationshipInfo.isProvider)
);

export const selectAllHubs = createSelector<
  RootState,
  [(RelationshipInfo & WithId)[]],
  (RelationshipInfo & WithId)[]
>(selectAll, (relationshipInfos) =>
  _.filter(relationshipInfos, (relationshipInfo) => relationshipInfo.isHub)
);

export const selectAllUpdatedHubs = createSelector<
  RootState,
  [(RelationshipInfo & WithId)[]],
  (RelationshipInfo & WithId)[]
>(selectAllUpdatedRelationshipInfos, (relationshipInfos) =>
  _.filter(relationshipInfos, (relationshipInfo) => relationshipInfo.isHub)
);

export const selectAllCustomersAndHubs = createSelector<
  RootState,
  [(RelationshipInfo & WithId)[], (RelationshipInfo & WithId)[]],
  (RelationshipInfo & WithId)[]
>(selectAllCustomers, selectAllHubs, (customers, hubs) =>
  _(customers).concat(hubs).uniqBy('relationshipId').value()
);

export const selectAllUpdatedCustomersAndHubs = createSelector<
  RootState,
  [(RelationshipInfo & WithId)[], (RelationshipInfo & WithId)[]],
  (RelationshipInfo & WithId)[]
>(selectAllUpdatedCustomers, selectAllUpdatedHubs, (customers, hubs) =>
  _(customers).concat(hubs).uniqBy('relationshipId').value()
);

export const selectAllProvidersOrganizationIds = createSelector(
  selectAllProviders,
  (relationshipInfos) =>
    _.map(
      relationshipInfos,
      (relationshipInfo) => relationshipInfo.organizationId
    )
);

export const selectAllUpdatedProvidersOrganizationIds = createSelector(
  selectAllUpdatedProviders,
  (relationshipInfos) =>
    _.map(
      relationshipInfos,
      (relationshipInfo) => relationshipInfo.organizationId
    )
);

export const selectAllRelationshipInfosByOrganizationId = createSelector(
  selectAll,
  (relationshipInfos) => {
    return _.keyBy(
      relationshipInfos.filter(
        (relationshipInfo) => !!relationshipInfo.organizationId
      ),
      'organizationId'
    );
  }
);

export const selectAllUpdatedRelationshipInfosByOrganizationId = createSelector(
  selectAllUpdatedRelationshipInfos,
  (relationshipInfos) => {
    return _.keyBy(
      relationshipInfos.filter(
        (relationshipInfo) => !!relationshipInfo.organizationId
      ),
      'organizationId'
    );
  }
);

export const selectAllRelationshipInfosByRelationshipId = createSelector(
  selectAll,
  (relationshipInfos) => {
    return _.keyBy(relationshipInfos, 'relationshipId');
  }
);

export const selectAllUpdatedRelationshipInfosByRelationshipId = createSelector(
  selectAllUpdatedRelationshipInfos,
  (relationshipInfos) => {
    return _.keyBy(relationshipInfos, 'relationshipId');
  }
);

export const selectAllPaymentTermsByRelationshipId = createSelector<
  RootState,
  [OrganizationSettings, _.Dictionary<RelationshipInfo & WithId>],
  PaymentTerms
>(
  selectSettings,
  selectAllRelationshipInfosByRelationshipId,
  extractPaymentTerms
);

export const selectAllUpdatedPaymentTermsByRelationshipId = createSelector<
  RootState,
  [OrganizationSettings, _.Dictionary<RelationshipInfo & WithId>],
  PaymentTerms
>(
  selectSettings,
  selectAllUpdatedRelationshipInfosByRelationshipId,
  extractPaymentTerms
);

function extractPaymentTerms(
  settings: OrganizationSettings,
  relationshipInfos: _.Dictionary<RelationshipInfo & WithId>
): PaymentTerms {
  if (settings && relationshipInfos) {
    const defaultTerms = settings?.sales?.paymentTermsInDays;
    const overrides = _.reduce(
      relationshipInfos,
      (acc, relationshipInfo) => {
        if (!LangUtils.nullOrUndefined(relationshipInfo.paymentTermsInDays)) {
          return {
            ...acc,
            [relationshipInfo.relationshipId]:
              relationshipInfo.paymentTermsInDays,
          };
        }
        return acc;
      },
      {}
    );
    return {
      defaultPaymentTermsInDays: defaultTerms,
      paymentTermsOverrides: overrides,
    };
  }
  return undefined;
}

export const selectAllProvidersLabels = createSelector<
  RootState,
  [(RelationshipInfo & WithId)[]],
  string[]
>(selectAllProviders, extractLabels);

export const selectAllUpdatedProvidersLabels = createSelector<
  RootState,
  [(RelationshipInfo & WithId)[]],
  string[]
>(selectAllUpdatedProviders, extractLabels);

export const selectAllCustomersAndHubsLabels = createSelector<
  RootState,
  [(RelationshipInfo & WithId)[]],
  string[]
>(selectAllCustomersAndHubs, extractLabels);

export const selectAllUpdatedCustomersAndHubsLabels = createSelector<
  RootState,
  [(RelationshipInfo & WithId)[]],
  string[]
>(selectAllUpdatedCustomersAndHubs, extractLabels);

function extractLabels(relationshipInfos: (RelationshipInfo & WithId)[]) {
  return _(relationshipInfos)
    .filter((relationshipInfo) => !!relationshipInfo.labels)
    .flatMap((relationshipInfo) => relationshipInfo.labels)
    .uniqBy((label) => label)
    .value();
}

export const selectAllCustomersAndHubsEmails = createSelector<
  RootState,
  [(RelationshipInfo & WithId)[]],
  string[]
>(selectAllCustomersAndHubs, (relationshipInfos) => {
  return relationshipInfos.map(
    (relationshipInfo) => relationshipInfo.contactInfo.email
  );
});

export const selectAllUpdatedCustomersAndHubsEmails = createSelector<
  RootState,
  [(RelationshipInfo & WithId)[]],
  string[]
>(selectAllUpdatedCustomersAndHubs, (relationshipInfos) => {
  return relationshipInfos.map(
    (relationshipInfo) => relationshipInfo.contactInfo.email
  );
});
