import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { DisplayMoneyAmountPipe } from './display-money-amount';

@NgModule({
  declarations: [DisplayMoneyAmountPipe],
  imports: [CommonModule],
  exports: [DisplayMoneyAmountPipe],
})
export class DisplayMoneyAmountPipeModule {}
