import _ from 'lodash';

import { translate } from '@jsverse/transloco';

import { SortOption } from '@arrivage-components/search-sort/search-sort.component';
import { ProductWithInventoryLines } from '@arrivage-inventory/model/inventory.model';
import { LanguageService } from '@arrivage-language/service/language.service';
import { Conservation, Product } from '@arrivage/model/dist/src/model';

export namespace LangUtils {
  export function nullOrUndefined(o: any): boolean {
    return o === null || o === undefined || o === 'null' || o === 'undefined';
  }

  export function isNotEmptyString(field: string): boolean {
    return !LangUtils.nullOrUndefined(field) && field.length > 0;
  }

  export function trimString(string: string): string {
    if (!string) {
      return string;
    }

    return string.trim();
  }

  export function normalizeString(string: string): string {
    if (!string) {
      return string;
    }

    if (!string.normalize) {
      return string.toLowerCase().trim();
    }

    // https://stackoverflow.com/questions/990904/remove-accents-diacritics-in-a-string-in-javascript
    return string
      .toLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .trim();
  }

  /**
   * Normalize string and remove invalid characters for a file name such as `<>:"/\\|?*`
   * @param string
   * @returns normalized string
   */
  export function normalizeFileName(string: string): string {
    if (!string) {
      return string;
    }

    const invalidChars = /[<>:"/\\|?*]/g; // Invalid Windows file name characters
    const reservedNames = /^(con|prn|aux|nul|com\d|lpt\d)$/i; // Reserved file names in Windows

    const normalizedString = normalizeString(string)
      .replace(invalidChars, '') // Remove invalid characters
      .trim();

    // Ensure the file name doesn't match reserved names like "CON", "PRN", etc.
    if (reservedNames.test(normalizedString)) {
      return `document_${normalizedString}`;
    }

    // Return "document" if the normalized string is empty
    if (normalizedString.length === 0) return 'document';

    return normalizedString;
  }

  export function compareString(a: string, b: string): number {
    if (a < b) {
      return -1;
    } else if (a > b) {
      return 1;
    } else {
      return 0;
    }
  }

  export function customSortBy<T>(
    collection: T[],
    by: SortOption,
    organizationName: string,
    languageService: LanguageService
  ): T[] {
    return collection.sort((a, b) => {
      if (
        isOfProductWithInventoryLines(a) &&
        isOfProductWithInventoryLines(b)
      ) {
        const translatedOptionA = translateOptionIn(
          a.product,
          by,
          organizationName,
          languageService
        );
        const translatedOptionB = translateOptionIn(
          b.product,
          by,
          organizationName,
          languageService
        );
        const comparedOption = compareString(
          normalizeString(translatedOptionA),
          normalizeString(translatedOptionB)
        );
        return (
          comparedOption ||
          compareString(
            normalizeString(languageService.getText(a.product.name)),
            normalizeString(languageService.getText(b.product.name))
          )
        );
      }
    });
  }

  function translateOptionIn(
    product: Product,
    option: SortOption,
    organizationName: string,
    languageService: LanguageService
  ): string {
    if (option === 'category') {
      return languageService.getText(product.category);
    } else if (option === 'department') {
      return translate<string>(`enums.department.${product.department}`);
    } else if (option === 'conservation') {
      return translate<string>('enums.conservation', {
        conservation: product?.conservation ?? Conservation.NON_REFRIGERATED,
      });
    } else if (option === 'resellingFor') {
      const defaultProducer = `${organizationName} (${translate<string>(
        'me'
      )})`;
      return product?.resellingFor ?? defaultProducer;
    }
  }

  function isOfProductWithInventoryLines(
    record: any
  ): record is ProductWithInventoryLines {
    return 'inventoryLines' in record && 'product' in record;
  }

  export function normalizedSortBy<T>(
    collection: T[],
    by: (e: T) => string
  ): T[] {
    return _.sortBy(collection, (c) => normalizeString(by(c)));
  }

  export function normalizedOrderBy<T>(
    collection: T[],
    by: (e: T) => string,
    order: 'asc' | 'desc'
  ): T[] {
    return _.orderBy(collection, (c) => normalizeString(by(c)), order);
  }

  export function normalizedIncludes(base: string, match: string): boolean {
    return normalizeString(base ?? '').includes(normalizeString(match));
  }
}
