import { createAction, props } from '@ngrx/store';

import { generateActions } from '@arrivage-store/generators';
import { ContactRequest, WithId } from '@arrivage/model/dist/src/model';

const featureName = 'Sent Contact Requests';

export const {
  query,
  connected,
  add,
  added,
  set,
  update,
  modified,
  remove,
  removed,
  queryFailure,
  addSuccess,
  addFailure,
  setSuccess,
  setFailure,
  updateSuccess,
  updateFailure,
  removeSuccess,
  removeFailure,
  getActiveItem,
  getActiveItemFailure,
  getActiveItemSuccess,
} = generateActions<ContactRequest>(featureName);

export const getContactRequest = createAction(
  `[${featureName}] Get Contact Request`,
  props<{
    id: string;
  }>()
);

export const getContactRequestSuccess = createAction(
  `[${featureName}] Get Contact Request Success`,
  props<{
    contactRequest: ContactRequest & WithId;
  }>()
);

export const getContactRequestFailure = createAction(
  `[${featureName}] Get Contact Request Failure`,
  props<{
    error: any;
  }>()
);
