import _ from 'lodash';
import { combineLatest, Observable } from 'rxjs';

import { Injectable } from '@angular/core';
import {
  DocumentData,
  Firestore,
  QueryDocumentSnapshot,
  SnapshotOptions,
  where,
} from '@angular/fire/firestore';

import { map } from 'rxjs/operators';

import { DeliveryUtils } from '@arrivage-distribution/common/utils/delivery.utils';
import { OfferService } from '@arrivage-offers/common/services/offers.service';
import { BaseService, PathSpec } from '@arrivage-services/base.service';
import { EntityChangeAction } from '@arrivage-services/organization-entity.service';
import { CustomerOrganizationType } from '@arrivage/model/dist/src/model';
import { PublicOffer } from '@arrivage/model/dist/src/search';
import { CollectionNames } from '@arrivage/model/dist/src/utils';

@Injectable({
  providedIn: 'root',
})
export class PublicOffersService extends BaseService<PublicOffer> {
  static readonly PUBLIC_OFFERS_COLLECTION =
    CollectionNames.PUBLIC_OFFERS_COLLECTION;
  static readonly converter = {
    toFirestore: (modelObject: PublicOffer): DocumentData => modelObject,
    fromFirestore: (
      snapshot: QueryDocumentSnapshot<PublicOffer>,
      options: SnapshotOptions
    ): PublicOffer => {
      const firestoreData = snapshot.data(options);
      if (!firestoreData) {
        return firestoreData;
      }

      const publicOffer: PublicOffer =
        OfferService.convertTimestampFields(firestoreData);

      publicOffer.deliveries = _.map(firestoreData.deliveries, (d) =>
        DeliveryUtils.convertTimestampFields(d)
      );

      return publicOffer;
    },
  };

  constructor(firestore: Firestore) {
    super(firestore);
  }

  connect(
    organizationType: CustomerOrganizationType,
    deliveryZoneCodes: string[]
  ): Observable<EntityChangeAction<PublicOffer>[]> {
    const deliveryZoneConstraints = [
      where('visibility', '==', organizationType),
      where('deliveryZoneCodes', 'array-contains-any', deliveryZoneCodes),
    ];

    const pickupConstraints = [
      where('visibility', '==', organizationType),
      where('pickups', '!=', []),
    ];

    const deliveryZoneQuery = this._connect(
      this.pathSpec(),
      (d) => d,
      PublicOffersService.converter,
      ...deliveryZoneConstraints
    );

    const pickupQuery = this._connect(
      this.pathSpec(),
      (d) => d,
      PublicOffersService.converter,
      ...pickupConstraints
    );

    const mergedQueries = combineLatest([deliveryZoneQuery, pickupQuery]).pipe(
      map(([d, p]) => {
        return [...d, ...p];
      })
    );

    return mergedQueries;
  }

  private pathSpec(): PathSpec[] {
    return [
      {
        collection: PublicOffersService.PUBLIC_OFFERS_COLLECTION,
      },
    ];
  }
}
