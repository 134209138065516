import { EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { createReducer, Action } from '@ngrx/store';

import {
  createEntityReducer,
  createInitialState,
} from '@arrivage-store/generators';
import { WithId, OrganizationSettings } from '@arrivage/model/dist/src/model';

import * as OrganizationSettingsActions from './organization-settings.actions';
import { OrganizationSettingsState } from './organization-settings.state';

export const adapter: EntityAdapter<
  OrganizationSettings & WithId
> = createEntityAdapter<OrganizationSettings & WithId>();

export const initialState: OrganizationSettingsState = createInitialState(
  adapter
);

const invoicesReducer = createReducer(
  initialState,
  ...createEntityReducer<OrganizationSettings>(
    OrganizationSettingsActions,
    adapter
  )
);

export function reducer(
  state: OrganizationSettingsState | undefined,
  action: Action
) {
  return invoicesReducer(state, action);
}
