import { EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Action, createReducer } from '@ngrx/store';

import { Relationship, WithId } from '@arrivage/model/dist/src/model';

import {
  createEntityReducer,
  createInitialState,
} from '../../../store/generators';
import * as relationshipsActions from './relationship.actions';
import { RelationshipsState } from './relationship.state';

export const adapter: EntityAdapter<Relationship & WithId> =
  createEntityAdapter<Relationship & WithId>();

export const initialState: RelationshipsState = createInitialState(adapter);

const relationshipsReducer = createReducer(
  initialState,
  ...createEntityReducer(relationshipsActions, adapter)
);

export function reducer(state: RelationshipsState | undefined, action: Action) {
  return relationshipsReducer(state, action);
}
