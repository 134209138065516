import { of } from 'rxjs';

import { Injectable } from '@angular/core';

import { switchMap, map, catchError } from 'rxjs/operators';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { ContactRequestsApi } from '@arrivage-contact-requests/common/api/contact-requests.api';
import { reportError } from '@arrivage-sentry/report-error';
import { SnackbarService } from '@arrivage-snackbar/snackbar.service';
import { EntityFeedback } from '@arrivage-store/feedback/feedback-params.model';
import { showFeedback } from '@arrivage-store/feedback/feedback.actions';
import { createBaseEffects } from '@arrivage-store/generators';

import { ReceivedContactRequestService } from '../service/received-contact-request.service';
import * as contactRequestsAction from './received-contact-requests.actions';
import * as actions from './received-contact-requests.actions';
import * as selectors from './received-contact-requests.selectors';
import { State } from './received-contact-requests.state';

interface ReceivedContactRequestFeedback extends EntityFeedback {
  accept: string;
  get: string;
  decline: string;
}
export const ReceivedContactRequestFeedback: ReceivedContactRequestFeedback = {
  add: 'send_contact_request',
  get: 'get_contact_request',
  accept: 'accept_contact_request',
  decline: 'decline_contact_request',
  query: 'query_received_contact_request',
  remove: '',
  update: '',
  get_active_item: '',
};
@Injectable()
export class ReceivedContactRequestsEffects {
  query$;
  update$;
  displayQueryFailure$;

  constructor(
    private actions$: Actions,
    private store: Store<State>,
    private service: ReceivedContactRequestService,
    private contactRequestApi: ContactRequestsApi,
    private snackbarService: SnackbarService
  ) {
    ({
      query: this.query$,
      update: this.update$,
      displayQueryFailure: this.displayQueryFailure$,
    } = createBaseEffects(
      this.actions$,
      this.store,
      contactRequestsAction,
      selectors,
      this.service,
      ReceivedContactRequestFeedback,
      this.snackbarService
    ));
  }

  getContactRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.getContactRequest),
      switchMap((getContactRequest) => {
        return this.service.get(getContactRequest.id).pipe(
          map((contactRequest) =>
            actions.getContactRequestSuccess({ contactRequest: contactRequest })
          ),
          catchError((e) => {
            reportError(e);
            return of(
              actions.getContactRequestFailure({ error: e }),
              showFeedback({
                success: false,
                feedback: ReceivedContactRequestFeedback.get,
              })
            );
          })
        );
      })
    )
  );
}
