import _ from 'lodash';

import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import { PublicOffer } from '@arrivage/model/dist/src/search';

import {
  added,
  connected,
  load,
  modified,
  query,
  queryFailure,
  removed,
} from './search.actions';
import { SearchState } from './search.state';

export const adapter: EntityAdapter<PublicOffer> = createEntityAdapter<PublicOffer>();

export const initialState: SearchState = adapter.getInitialState({
  loaded: false,
  error: undefined,
});

const searchReducer = createReducer(
  initialState,
  on(query, (state) => {
    if (state.loaded) {
      return state;
    }
    return {
      ...state,
      loaded: false,
      error: undefined,
    };
  }),
  on(load, (state) => {
    return {
      ...adapter.removeAll(state),
      loaded: false,
      error: undefined,
    };
  }),
  on(connected, (state) => {
    return {
      ...state,
      loaded: true,
      error: undefined,
    };
  }),
  on(added, (state, { records }) => {
    return {
      ...adapter.addMany(records, state),
      loaded: true,
      error: undefined,
    };
  }),
  on(modified, (state, { records }) => {
    // we can not use updateMany since this supports partial updates, meaning that a property that is removed
    // will not be removed from the store, so we replace all modified records
    return {
      ..._.reduce(records, (s, r) => adapter.setOne(r, s), state),
      loaded: true,
      error: undefined,
    };
  }),
  on(removed, (state, { records }) => {
    return {
      ...adapter.removeMany(_.map(records, 'id'), state),
      loaded: true,
      error: undefined,
    };
  }),
  on(queryFailure, (state, { errorMessage }) => {
    return {
      ...state,
      loaded: false,
      error: errorMessage,
    };
  })
);

export function reducer(state: SearchState | undefined, action: Action) {
  return searchReducer(state, action);
}
