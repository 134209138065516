import { from, of } from 'rxjs';

import { Injectable } from '@angular/core';

import {
  catchError,
  debounceTime,
  map,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { ConversationService } from '@arrivage-conversations/services/conversation.service';
import { SnackbarService } from '@arrivage-snackbar/snackbar.service';
import { getOrganization } from '@arrivage-store/context/context.selectors';
import { EntityFeedback } from '@arrivage-store/feedback/feedback-params.model';
import { createBaseEffects } from '@arrivage-store/generators';

import * as conversationActions from './conversations.actions';
import * as conversationSelectors from './conversations.selectors';
import { State, ConversationsState } from './conversations.state';

interface ConversationsFeedback extends EntityFeedback {}

export const ConversationsFeedback: ConversationsFeedback = {
  update: 'update_conversation',
  add: 'add_conversation',
  remove: 'remove_conversation',
  // Error only
  query: 'query_conversation',
  get_active_item: 'get_active_item_conversation',
};
@Injectable()
export class ConversationsEffects {
  query$;
  add$;
  update$;
  remove$;
  getActiveItem$;
  displayQueryFailure$;
  displayGetActiveItemFailure$;

  constructor(
    private actions$: Actions,
    private store: Store<State & ConversationsState>,
    private conversationService: ConversationService,
    private snackbarService: SnackbarService
  ) {
    ({
      query: this.query$,
      add: this.add$,
      update: this.update$,
      remove: this.remove$,
      getActiveItem: this.getActiveItem$,
      displayQueryFailure: this.displayQueryFailure$,
      displayGetActiveItemFailure: this.displayGetActiveItemFailure$,
    } = createBaseEffects(
      this.actions$,
      this.store,
      conversationActions,
      conversationSelectors,
      this.conversationService,
      ConversationsFeedback,
      this.snackbarService
    ));
  }

  updateConversationViewedByInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(conversationActions.updateConversationViewedByInfo),
      withLatestFrom(this.store.select(getOrganization)),
      debounceTime(3000),
      switchMap(([info, organization]) => {
        return from(
          this.conversationService.updateConversationViewedByInfo(
            info.conversationId,
            organization.id
          )
        ).pipe(
          map((x) => {
            info.confirmation.resolve(info.conversationId);
            return conversationActions.updateConversationViewedByInfoSuccess({
              conversationId: info.conversationId,
            });
          }),
          catchError((e) => {
            info.confirmation.reject(e);
            return of(
              conversationActions.updateConversationViewedByInfoFailure({
                error: e,
              })
            );
          })
        );
      })
    )
  );
}
