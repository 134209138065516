import { EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { createReducer, Action } from '@ngrx/store';

import { WithId, Notification } from '@arrivage/model/dist/src/model';

import {
  createEntityReducer,
  createInitialState,
} from '../../store/generators';
import * as notificationsActions from './notifications.actions';
import { NotificationState } from './notifications.state';

export const adapter: EntityAdapter<
  Notification & WithId
> = createEntityAdapter<Notification & WithId>();

export const initialState: NotificationState = createInitialState(adapter);

const notificationsReducer = createReducer(
  initialState,
  ...createEntityReducer<Notification>(notificationsActions, adapter)
);

export function reducer(state: NotificationState | undefined, action: Action) {
  return notificationsReducer(state, action);
}
