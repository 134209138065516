import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

import { DateRangeOption } from '@arrivage-components/date-range-picker/date-range-picker.component';
import { DateType } from '@arrivage-components/filtered-date-range-picker/filtered-date-range-picker.component';

export interface FilteredDateRangePickerDialogResponse {
  dateRange: Interval;
  dateType: DateType;
}

@Component({
  selector: 'app-filtered-date-range-picker-dialog',
  templateUrl: './filtered-date-range-picker-dialog.component.html',
  styleUrls: ['./filtered-date-range-picker-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilteredDateRangePickerDialogComponent {
  readonly DEFAULT_DATE_RANGE_OPTION: DateRangeOption = 'last-30-days';
  readonly DEFAULT_DATE_TYPE: DateType = 'deliveryOrPickup';

  private dateRange: Interval;
  private dateType: DateType;

  constructor(
    public dialogRef: MatDialogRef<
      FilteredDateRangePickerDialogComponent,
      FilteredDateRangePickerDialogResponse
    >
  ) {}

  onDateRangeChanges(dateRange: Interval) {
    this.dateRange = dateRange;
  }

  onDateTypeChanges(dateType: DateType) {
    this.dateType = dateType;
  }

  confirm() {
    this.dialogRef.close({
      dateRange: this.dateRange,
      dateType: this.dateType,
    });
  }
}
