import { Observable } from 'rxjs';

import { Injectable } from '@angular/core';
import {
  Auth,
  authState,
  User,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signInWithPopup,
  GoogleAuthProvider,
  sendPasswordResetEmail,
  signOut,
} from '@angular/fire/auth';

import { map } from 'rxjs/operators';

import { Store } from '@ngrx/store';

import { environment } from '@arrivage-environments/environment';
import { State } from '@arrivage-store/state';

import { AuthData, AuthUser } from '../model/auth-data.model';
import * as AuthActions from '../store/auth.actions';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  readonly userDefaultPhotoUrl: string = environment.default_photo_url;

  authenticatedUser$: Observable<User | null>;
  isAuthenticated$: Observable<boolean>;
  isNotAuthenticated$: Observable<boolean>;

  constructor(private auth: Auth, private store: Store<State>) {
    if (auth) {
      this.authenticatedUser$ = authState(this.auth);
      this.isAuthenticated$ = this.authenticatedUser$.pipe(map((u) => !!u));
      this.isNotAuthenticated$ = this.authenticatedUser$.pipe(map((u) => !u));
    }
  }

  initAuthListener() {
    this.authenticatedUser$.subscribe((user) => {
      if (user) {
        this.toAuthUser(user).then((authUser) =>
          this.store.dispatch(AuthActions.SetAuthUser({ user: authUser }))
        );
      } else {
        this.store.dispatch(AuthActions.UnsetAuthUser());
      }
    });
  }

  async registerUser(authData: AuthData): Promise<AuthUser> {
    return createUserWithEmailAndPassword(
      this.auth,
      authData.email,
      authData.password
    ).then((uc) => this.toAuthUser(uc.user));
  }

  async login(authData: AuthData): Promise<AuthUser> {
    return signInWithEmailAndPassword(
      this.auth,
      authData.email,
      authData.password
    ).then((uc) => this.toAuthUser(uc.user));
  }

  async googleSignIn(): Promise<AuthUser> {
    const provider = new GoogleAuthProvider();
    return signInWithPopup(this.auth, provider).then((uc) =>
      this.toAuthUser(uc.user)
    );
  }

  requestPassword(email: string) {
    return sendPasswordResetEmail(this.auth, email);
  }

  logout() {
    return signOut(this.auth);
  }

  private async toAuthUser(user: User): Promise<AuthUser> {
    const claims = await user.getIdTokenResult().then((r) => r.claims);
    return {
      user: {
        firstName: user.displayName ? user.displayName.split(' ')[0] : '',
        lastName: user.displayName ? user.displayName.split(' ')[1] : '',
        pictureUrl: user.photoURL ? user.photoURL : this.userDefaultPhotoUrl,
        contactInfo: { email: user.email },
      },
      uid: user.uid,
      claims: claims,
    };
  }
}
