import { Directive, EventEmitter, Output } from '@angular/core';

import { DirtyCheckService } from '@arrivage-services/dirty-check.service';

export abstract class FormDirtyClass {
  abstract onDirtyChanges(isDirty: boolean): void;
}
@Directive()
export class FormDirtyViewComponent extends FormDirtyClass {
  @Output()
  isDirtyChanges = new EventEmitter<boolean>();

  onDirtyChanges(isDirty: boolean) {
    this.isDirtyChanges.emit(isDirty);
  }
}

export class FormDirtyPageComponent extends FormDirtyClass {
  constructor(protected dirtyCheckService: DirtyCheckService) {
    super();
  }

  onDirtyChanges(isDirty: boolean): void {
    this.dirtyCheckService.markDirtyAs(isDirty);
  }
}
