import { Injectable } from '@angular/core';
import { Firestore } from '@angular/fire/firestore';

import { BasePurchaseOrderService } from './../../common/services/base-purchase-order.service';
import { TransactionDocumentIdFields } from './../../common/services/purchase-orders-id-fields';

@Injectable({
  providedIn: 'root',
})
export class CustomerPurchaseOrderService extends BasePurchaseOrderService {
  constructor(firestore: Firestore) {
    super(firestore, TransactionDocumentIdFields.ID_FIELDS.CUSTOMER);
  }
}
