import { Observable } from 'rxjs';

import { Injectable } from '@angular/core';
import { Firestore } from '@angular/fire/firestore';

import { PriceList, WithId } from '@arrivage/model/dist/src/model';
import { CollectionNames } from '@arrivage/model/dist/src/utils';

import { BaseService, PathSpec } from '../base.service';
import {
  EntityChangeAction,
  OrganizationEntityService,
} from '../organization-entity.service';

@Injectable({
  providedIn: 'root',
})
export class PriceListService
  extends BaseService<PriceList>
  implements OrganizationEntityService<PriceList>
{
  static readonly ORGANIZATIONS_COLLECTION =
    CollectionNames.ORGANIZATION_COLLECTION;
  static readonly PRICE_LIST_COLLECTION = CollectionNames.PRICE_LIST_COLLECTION;

  constructor(firestore: Firestore) {
    super(firestore);
  }

  get(
    organizationId: string,
    recordId: string
  ): Observable<PriceList & WithId> {
    return this._get(this.pathSpec(organizationId), recordId);
  }

  create(organizationId: string, priceList: PriceList): Promise<string> {
    return this._create(this.pathSpec(organizationId), priceList);
  }

  set(
    organizationId: string,
    recordId: string,
    record: PriceList
  ): Promise<void> {
    return this._set(this.pathSpec(organizationId), recordId, record);
  }

  update(
    organizationId: string,
    priceListId: string,
    priceList: PriceList
  ): Promise<void> {
    return this._update(this.pathSpec(organizationId), priceListId, priceList);
  }

  connect(organizationId: string): Observable<EntityChangeAction<PriceList>[]> {
    return this._connect(this.pathSpec(organizationId), (d) => d, null);
  }

  list(organizationId: string): Observable<(PriceList & WithId)[]> {
    return this._list(this.pathSpec(organizationId));
  }

  remove(organizationId: string, priceListId: string): Promise<void> {
    return this._delete(this.pathSpec(organizationId), priceListId);
  }

  private pathSpec(organizationId): PathSpec[] {
    return [
      {
        collection: PriceListService.ORGANIZATIONS_COLLECTION,
        id: organizationId,
      },
      { collection: PriceListService.PRICE_LIST_COLLECTION },
    ];
  }
}
