import { EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on, Action } from '@ngrx/store';

import { Organization, WithId } from '@arrivage/model/dist/src/model';

import {
  getPremiumAccount,
  getPremiumAccountFailure,
  getPremiumAccountSuccess,
} from './premium-accounts.actions';
import { PremiumAccountState } from './premium-accounts.state';

export const adapter: EntityAdapter<Organization & WithId> =
  createEntityAdapter<Organization & WithId>();

const initialState: PremiumAccountState = {
  loaded: false,
  error: undefined,
  premiumAccount: undefined,
};

const premiumAccountReducer = createReducer(
  initialState,
  on(getPremiumAccount, (state: PremiumAccountState) => {
    return {
      ...state,
      loaded: false,
      error: undefined,
      premiumAccount: undefined,
    };
  }),
  on(getPremiumAccountSuccess, (state: PremiumAccountState, premiumAccount) => {
    return {
      ...state,
      loaded: true,
      error: undefined,
      premiumAccount: premiumAccount.premiumAccount,
    };
  }),
  on(getPremiumAccountFailure, (state: PremiumAccountState, error) => {
    return {
      ...state,
      loaded: false,
      error: error.error,
      premiumAccount: undefined,
    };
  })
);

export function reducer(
  state: PremiumAccountState | undefined,
  action: Action
) {
  return premiumAccountReducer(state, action);
}
