import { FirestoreEntityState } from '@arrivage-store/generators';
import * as fromRoot from '@arrivage-store/state';
import { WithId } from '@arrivage/model/dist/src/model';
import { Delivery } from '@arrivage/model/dist/src/model';

export const CustomerDeliveryStateName = 'customerDeliveries';

export interface CustomerDeliveryState extends FirestoreEntityState<Delivery> {
  relationshipDeliveries: (Delivery & WithId)[];
  isLoadingRelationshipDeliveries: boolean;
  loadingRelationshipDeliveriesError: boolean;
}

export interface State extends fromRoot.State {
  [CustomerDeliveryStateName]: CustomerDeliveryState;
}
