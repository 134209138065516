import { Directive, ElementRef, Self } from '@angular/core';

@Directive({
  selector: 'input[type="number"]',
})
export class BlurOnWheelInputNumberDirective {
  constructor(@Self() private element: ElementRef<HTMLInputElement>) {
    this.element.nativeElement.addEventListener(
      'wheel',
      (e: WheelEvent) => {
        this.element.nativeElement.blur();
      },
      { passive: true }
    );
  }
}
