import { Observable, firstValueFrom } from 'rxjs';

import { filter, map, take } from 'rxjs/operators';

import { Store } from '@ngrx/store';

import { WithId } from '@arrivage/model/dist/src/model';

import {
  ConnectState,
  FirestoreEntityActions,
  FirestoreEntitySelectors,
  LoadingItemState,
} from '../generators';

export class EntitiesFacade<T, S> {
  private connectState$: Observable<ConnectState>;
  protected items$: Observable<(T & WithId)[]>;
  protected itemsById$: Observable<{ [id: string]: T & WithId }>;
  protected activeItem$: Observable<T & WithId>;
  protected isLoadingActiveItem$: Observable<boolean>;
  protected activeItemState$: Observable<LoadingItemState<T & WithId>>;

  constructor(
    protected store: Store<S>,
    protected actions: FirestoreEntityActions<T>,
    protected selectors: FirestoreEntitySelectors<T, S>
  ) {
    this.connectState$ = this.store.select(selectors.connectState);
    this.items$ = this.store.select(selectors.selectAll);
    this.itemsById$ = this.store.select(selectors.selectEntities);

    this.activeItem$ = this.store.select(selectors.selectActiveItem);
    this.isLoadingActiveItem$ = this.store.select(
      selectors.isLoadingActiveItem
    );
    this.activeItemState$ = this.store.select(selectors.selectActiveItemState);
  }
  // Connected state
  loadAll(parameter?: any) {
    this.store.dispatch(this.actions.query());
  }

  getConnectState(): Observable<ConnectState> {
    return this.connectState$;
  }

  waitForConnection(): Promise<boolean> {
    return this.getConnectState()
      .pipe(
        filter((s) => !s.connecting),
        take(1),
        map((s) => {
          if (s.queryFailure || !s.connected) {
            return false;
          } else {
            return true;
          }
        })
      )
      .toPromise();
  }

  getAll(): Observable<(T & WithId)[]> {
    return this.items$;
  }

  getAllById(): Observable<{ [id: string]: T & WithId }> {
    return this.itemsById$;
  }

  getById(id: string): Observable<T & WithId> {
    return this.store.select(this.selectors.getById(id));
  }

  loadActiveItem(itemId: string) {
    this.store.dispatch(this.actions.getActiveItem({ id: itemId }));
  }

  async loadAndGetActiveItem(itemId: string) {
    this.loadActiveItem(itemId);
    await firstValueFrom(
      this.getIsLoadingActiveItem().pipe(filter((isLoading) => !isLoading))
    );
    return firstValueFrom(this.getActiveItem());
  }

  getActiveItem(): Observable<T & WithId> {
    return this.activeItem$;
  }

  getActiveItemState(): Observable<LoadingItemState<T & WithId>> {
    return this.activeItemState$;
  }
  getIsLoadingActiveItem(): Observable<boolean> {
    return this.isLoadingActiveItem$;
  }
  addItem(item: T): Promise<string> {
    return new Promise((resolve, reject) => {
      this.store.dispatch(
        this.actions.add({
          record: item,
          confirmation: {
            resolve: resolve,
            reject: reject,
          },
        })
      );
    });
  }

  updateItem(item: Partial<T> & WithId): Promise<string> {
    return new Promise((resolve, reject) => {
      this.store.dispatch(
        this.actions.update({
          record: item,
          confirmation: {
            resolve: resolve,
            reject: reject,
          },
        })
      );
    });
  }

  setItem(item: T & WithId): Promise<string> {
    return new Promise((resolve, reject) => {
      this.store.dispatch(
        this.actions.set({
          record: item,
          confirmation: {
            resolve: resolve,
            reject: reject,
          },
        })
      );
    });
  }

  removeItem(itemId: string): Promise<string> {
    return new Promise((resolve, reject) => {
      this.store.dispatch(
        this.actions.remove({
          id: itemId,
          confirmation: {
            resolve: resolve,
            reject: reject,
          },
        })
      );
    });
  }
}
