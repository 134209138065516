<ng-container *transloco="let t; read: 'components.date-range-picker'">
  <button
    mat-stroked-button
    #menuTrigger="matMenuTrigger"
    [matMenuTriggerFor]="menu"
    [customTooltip]="
      customTooltipText ? label + ' (' + customTooltipText + ')' : label
    "
    (menuClosed)="resetRange()"
  >
    <article fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="baseline center">
      <span *ngIf="selectedOption === 'customized'; else presetRange"
        >{{
          selectedDateRange.start
            | translocoDate: { dateStyle: 'short' }:currentLocale
        }}
        -
        {{
          selectedDateRange.end
            | translocoDate: { dateStyle: 'short' }:currentLocale
        }}</span
      >
      <ng-template #presetRange>
        <span>{{ t(selectedOption) }}</span>
      </ng-template>
      <mat-icon>edit_calendar</mat-icon>
    </article>
  </button>
  <mat-menu
    #menu="matMenu"
    class="date-range-picker-menu"
    [overlapTrigger]="true"
  >
    <mat-card-content
      class="menu-container"
      (click)="$event.stopPropagation()"
      fxLayout="column"
      fxLayoutGap="16px"
    >
      <section
        class="menu-header"
        fxLayout="row"
        fxLayoutAlign="space-between center"
      >
        <mat-card-title>{{ label }}</mat-card-title>
        <article
          class="input-container"
          [ngClass]="!range.valid && range.dirty ? 'error' : 'no-error'"
          fxLayout="row"
          fxLayoutAlign="start center"
          fxLayoutGap="8px"
        >
          <mat-icon>date_range</mat-icon>
          <form [formGroup]="range">
            <mat-date-range-input [max]="maxDate">
              <input
                matStartDate
                formControlName="start"
                [placeholder]="t('start-date')"
                (dateInput)="updateSelectedOption('customized')"
              />
              <input
                matEndDate
                formControlName="end"
                [placeholder]="t('end-date')"
                (dateInput)="updateSelectedOption('customized')"
              />
            </mat-date-range-input>
          </form>
        </article>
      </section>
      <section
        class="menu-content"
        fxLayout="row"
        fxLayout.lt-sm="column"
        fxLayoutGap.gt-xs="16px"
      >
        <section class="sidebar-container" fxFlex.gt-xs="40">
          <div class="selection-overlay" #selectionOverlay></div>
          <mat-selection-list
            class="sidebar-content"
            [multiple]="false"
            (selectionChange)="onOptionSelectionChange($event.options[0].value)"
            (click)="calendar._goToDateInView(endControl.value, 'month')"
          >
            <mat-list-option
              *ngFor="let option of DATE_RANGE_OPTIONS"
              [disableRipple]="true"
              [value]="option"
              [selected]="selectedOption === option"
            >
              {{ t(option) }}
            </mat-list-option>
          </mat-selection-list>
        </section>
        <section class="calendar-content" fxFlex.gt-xs="60">
          <mat-calendar
            #calendar
            class="date-range-calendar"
            [selected]="selectedDateRange"
            [maxDate]="maxDate"
            (selectedChange)="onCalendarChange($event)"
          >
          </mat-calendar>
        </section>
      </section>
      <section
        class="menu-footer"
        fxLayout="row"
        fxLayoutGap="16px"
        fxLayoutAlign="space-between center"
      >
        <article class="bold" fxLayout="row" fxLayoutAlign="start">
          <span class="range-title">{{ t('range') }}:&nbsp;</span>
          <mat-error *ngIf="hasInvalidRangeError(); else invalidError"
            >{{ t('invalid-range') }}
          </mat-error>
          <ng-template #invalidError>
            <mat-error *ngIf="hasInvalidFormatError(); else displayedRange"
              >{{ t('invalid-format') }}
            </mat-error>
          </ng-template>
          <ng-template #displayedRange>
            <span>{{ dateRangeToString }}</span>
          </ng-template>
        </article>
        <section
          class="calendar-action"
          fxLayout="row"
          fxLayoutGap="16px"
          fxLayoutAlign="end"
        >
          <button mat-raised-button (click)="resetRange()">
            {{ t('cancel') }}
          </button>
          <button
            mat-raised-button
            color="accent"
            (click)="applySelectedRange()"
          >
            {{ t('apply') }}
          </button>
        </section>
      </section>
    </mat-card-content>
  </mat-menu>
</ng-container>
