import { of } from 'rxjs';

import { Injectable } from '@angular/core';

import { catchError, map, switchMap } from 'rxjs/operators';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { DeliveryZonesService } from '@arrivage-services/delivery-zones.service';
import { SnackbarService } from '@arrivage-snackbar/snackbar.service';
import { createBaseEffects } from '@arrivage-store/generators';

import { DeliveryFeedback } from '../../model/delivery.model';
import { CustomerDeliveryService } from '../services/customer-delivery.service';
import * as DeliveryActions from './customer-delivery.actions';
import * as selectors from './customer-delivery.selectors';
import { CustomerDeliveryState, State } from './customer-delivery.state';

@Injectable()
export class CustomerDeliveryEffects {
  query$;
  displayQueryFailure$;
  constructor(
    private actions$: Actions,
    private store: Store<State & CustomerDeliveryState>,
    private service: CustomerDeliveryService,
    private snackbarService: SnackbarService,
    private deliveryZoneService: DeliveryZonesService
  ) {
    ({ query: this.query$, displayQueryFailure: this.displayQueryFailure$ } =
      createBaseEffects(
        this.actions$,
        this.store,
        DeliveryActions,
        selectors,
        service,
        DeliveryFeedback,
        this.snackbarService
      ));
  }

  loadRelationshipDeliveries$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeliveryActions.loadRelationshipDeliveries),
      switchMap((loadAction) => {
        return this.service
          .getRelationshipDeliveries(loadAction.relationshipId)
          .pipe(
            map((deliveries) =>
              DeliveryActions.loadRelationshipDeliveriesSuccess({
                deliveries: deliveries,
              })
            ),
            catchError((e) =>
              of(
                DeliveryActions.loadRelationshipDeliveriesFailure({ error: e })
              )
            )
          );
      })
    )
  );
}
