<ng-container *transloco="let t; scope: 'notifications'; read: 'notifications'">
  <button
    mat-icon-button
    [customTooltip]="t('notifications')"
    [matMenuTriggerFor]="notificationMenu"
  >
    <mat-icon
      [ngClass]="{ noNotifications: nbUnread === 0 }"
      [matBadge]="nbUnread.toString()"
      [matBadgeHidden]="nbUnread === 0"
      matBadgeColor="accent"
      matBadgeSize="small"
      matBadgePosition="above after"
      >notifications</mat-icon
    >
  </button>
  <mat-menu #notificationMenu="matMenu" xPosition="before" yPosition="below">
    <div class="title">
      <span>{{ t('notifications') }}</span>
      <span *ngIf="notifications.length === MAX_NOTIFICATIONS"
        >&nbsp;{{
          t('max-notifications', { maxNotifications: MAX_NOTIFICATIONS })
        }}</span
      >
    </div>
    <button
      (click)="notificationRead(notification)"
      mat-menu-item
      [ngClass]="{ newNotification: !notification.isRead }"
      *ngFor="let notification of notifications"
    >
      <small class="label">
        {{ notification.date | translocoDate: { dateStyle: 'short' } }}</small
      >
      <div fxLayout="row">
        <img
          class="mat-icon logo"
          *ngIf="notification.imageURL"
          [src]="notification.imageURL"
        />

        <mat-icon *ngIf="!notification.imageURL">{{
          getDefaultIcon(notification.type)
        }}</mat-icon>
        <span class="notification-text">{{
          t(notification.type, notification.context)
        }}</span>
      </div>

      <br />
    </button>

    <button mat-menu-item disabled *ngIf="notifications.length < 1">
      {{ t('no-notification') }}
    </button>
  </mat-menu>
</ng-container>
