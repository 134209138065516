import { createAction, props } from '@ngrx/store';

import { Confirmation } from '@arrivage-store/generators';
import { Invoice, WithId } from '@arrivage/model/dist/src/model';

import {
  generateInvoiceActions,
  InvoiceActions,
} from '../../common/store/generator';

const featureName = 'CustomersInvoices';

export const CustomerInvoicesActions: InvoiceActions =
  generateInvoiceActions(featureName);

export const paymentEmitted = createAction(
  `[${featureName}] PaymentEmitted`,
  props<{
    invoice: Invoice & WithId;
    confirmation: Confirmation<string, any>;
  }>()
);

export const paymentEmittedSuccess = createAction(
  `[${featureName}] PaymentEmittedSuccess`
);

export const paymentEmittedFailure = createAction(
  `[${featureName}] PaymentEmittedFailure`,
  props<{
    error: any;
  }>()
);

export const getPaymentMethods = createAction(
  `[${featureName}] GetPaymentMethods`
);

export const getPaymentMethodsSuccess = createAction(
  `[${featureName}] GetPaymentMethodsSuccess`,
  props<{
    paymentMethodsResponse: any;
  }>()
);

export const getPaymentMethodsFailure = createAction(
  `[${featureName}] GetPaymentMethodsFailure`,
  props<{
    error: any;
  }>()
);

export const getRelatedInvoices = createAction(
  `[${featureName}] GetRelatedInvoices`,
  props<{
    purchaseOrderIds: string[];
  }>()
);

export const getRelatedInvoicesSuccess = createAction(
  `[${featureName}] GetRelatedInvoicesSuccess`,
  props<{
    relatedInvoicesResponse: (Invoice & WithId)[];
  }>()
);

export const getRelatedInvoicesFailure = createAction(
  `[${featureName}] GetRelatedInvoicesFailure`,
  props<{
    error: any;
  }>()
);
