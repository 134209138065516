import _ from 'lodash';
import { from, iif, of } from 'rxjs';

import {
  catchError,
  debounceTime,
  exhaustMap,
  filter,
  map,
  mergeMap,
  switchMap,
  takeUntil,
  tap,
  withLatestFrom,
} from 'rxjs/operators';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EntityAdapter, EntityState } from '@ngrx/entity';
import { Dictionary, EntitySelectors } from '@ngrx/entity/src/models';
import { Store, createAction, createSelector, on, props } from '@ngrx/store';
import { ActionCreator, TypedAction } from '@ngrx/store/src/models';

import { Logout } from '@arrivage-auth/store/auth.actions';
import { reportError } from '@arrivage-sentry/report-error';
import { SnackbarService } from '@arrivage-snackbar/snackbar.service';
import { getOrganization } from '@arrivage-store/context/context.selectors';
import { WithId } from '@arrivage/model/dist/src/model';

import { OrganizationEntityService } from '../services/organization-entity.service';
import { EntityFeedback } from './feedback/feedback-params.model';
import { State } from './state';

export interface ConnectState {
  connecting: boolean;
  connected: boolean;
  queryFailure: boolean;
}

export interface LoadingItemState<T> {
  item: T;
  isLoading: boolean;
  error: any;
}
export interface LoadingCollectionState<T> {
  items: T[];
  isLoading: boolean;
  error: any;
}

export interface Confirmation<T, E> {
  resolve: (response: T) => void;
  reject: (error: E) => void;
}

export interface FirestoreEntityState<T> extends EntityState<T & WithId> {
  connecting: boolean;
  connected: boolean;
  queryFailure: boolean;
  addFailure?: T;
  setFailure?: T & WithId;
  updateFailure?: Partial<T> & WithId;
  removeFailure?: string;
  activeItem?: LoadingItemState<T & WithId>;
}

export function createInitialState<T, S>(
  adapter: EntityAdapter<T & WithId>,
  additionalState = {} as S
): FirestoreEntityState<T> & S {
  return adapter.getInitialState({
    connecting: false,
    connected: false,
    queryFailure: false,
    ...additionalState,
  });
}

export interface FirestoreEntitySelectors<T, V>
  extends EntitySelectors<T & WithId, V> {
  connecting: (state: V) => boolean;
  connected: (state: V) => boolean;
  connectState: (state: V) => ConnectState;
  queryFailure: (state: V) => boolean;
  addFailure: (state: V) => T;
  setFailure: (state: V) => T & WithId;
  updateFailure: (state: V) => Partial<T> & WithId;
  removeFailure: (state: V) => string;
  getById: (id: string) => (state: V) => T & WithId;
  selectActiveItem?: (state: V) => T & WithId;
  selectActiveItemState?: (state: V) => LoadingItemState<T & WithId>;
  isLoadingActiveItem?: (state: V) => boolean;
}

export function createSelectors<T, V>(
  adapter: EntityAdapter<T & WithId>,
  featureSelector: (state) => FirestoreEntityState<T>
): FirestoreEntitySelectors<T, V> {
  const selectors = adapter.getSelectors(featureSelector);
  return {
    ...selectors,
    connecting: createSelector(featureSelector, (s) => {
      return s.connecting;
    }),
    connected: createSelector(featureSelector, (s) => {
      return s.connected;
    }),
    connectState: createSelector(featureSelector, (s) => {
      return {
        connecting: s.connecting,
        connected: s.connected,
        queryFailure: s.queryFailure,
      };
    }),
    queryFailure: createSelector(featureSelector, (s) => s.queryFailure),
    addFailure: createSelector(featureSelector, (s) => s.addFailure),
    setFailure: createSelector(featureSelector, (s) => s.setFailure),
    updateFailure: createSelector(featureSelector, (s) => s.updateFailure),
    removeFailure: createSelector(featureSelector, (s) => s.removeFailure),
    getById: (id: string) =>
      createSelector(
        selectors.selectEntities,
        (entities: Dictionary<T & WithId>) => {
          return entities[id];
        }
      ),
    selectActiveItem: createSelector(featureSelector, (s) => s.activeItem.item),
    isLoadingActiveItem: createSelector(
      featureSelector,
      (s) => s.activeItem.isLoading
    ),
    selectActiveItemState: createSelector(featureSelector, (s) => {
      return s.activeItem;
    }),
  };
}
export interface FirestoreEntityActions<T> {
  query: ActionCreator<string, () => TypedAction<string>>;
  connected: ActionCreator<string, () => TypedAction<string>>;
  add: ActionCreator<
    string,
    (props: { record: T; confirmation: Confirmation<string, any> }) => {
      record: T;
      confirmation: Confirmation<string, any>;
    } & TypedAction<string>
  >;
  added: ActionCreator<
    string,
    (props: {
      records: (T & WithId)[];
    }) => { records: (T & WithId)[] } & TypedAction<string>
  >;
  set: ActionCreator<
    string,
    (props: {
      record: T & WithId;
      confirmation: Confirmation<string, any>;
    }) => {
      record: T & WithId;
      confirmation: Confirmation<string, any>;
    } & TypedAction<string>
  >;
  update: ActionCreator<
    string,
    (props: {
      record: Partial<T> & WithId;
      confirmation: Confirmation<string, any>;
    }) => {
      record: Partial<T> & WithId;
      confirmation: Confirmation<string, any>;
    } & TypedAction<string>
  >;
  modified: ActionCreator<
    string,
    (props: {
      records: (T & WithId)[];
    }) => { records: (T & WithId)[] } & TypedAction<string>
  >;
  remove: ActionCreator<
    string,
    (props: { id: string; confirmation: Confirmation<string, any> }) => {
      id: string;
      confirmation: Confirmation<string, any>;
    } & TypedAction<string>
  >;
  removed: ActionCreator<
    string,
    (props: {
      records: (T & WithId)[];
    }) => { records: (T & WithId)[] } & TypedAction<string>
  >;
  queryFailure: ActionCreator<
    string,
    (props: {
      errorMessage: string;
    }) => { errorMessage: string } & TypedAction<string>
  >;
  addSuccess: ActionCreator<
    string,
    (props: { id: string }) => { id: string } & TypedAction<string>
  >;
  addFailure: ActionCreator<
    string,
    (props: {
      record: T;
      errorMessage: string;
    }) => { record: T; errorMessage: string } & TypedAction<string>
  >;
  setSuccess: ActionCreator<
    string,
    (props: { id: string }) => { id: string } & TypedAction<string>
  >;
  setFailure: ActionCreator<
    string,
    (props: {
      record: T & WithId;
      errorMessage: string;
    }) => { record: T & WithId; errorMessage: string } & TypedAction<string>
  >;
  updateSuccess: ActionCreator<
    string,
    (props: { id: string }) => { id: string } & TypedAction<string>
  >;
  updateFailure: ActionCreator<
    string,
    (props: { record: Partial<T> & WithId; errorMessage: string }) => {
      record: Partial<T> & WithId;
      errorMessage: string;
    } & TypedAction<string>
  >;
  removeSuccess: ActionCreator<
    string,
    (props: { id: string }) => { id: string } & TypedAction<string>
  >;
  removeFailure: ActionCreator<
    string,
    (props: {
      id: string;
      errorMessage: string;
    }) => { id: string; errorMessage: string } & TypedAction<string>
  >;
  getActiveItem: ActionCreator<
    string,
    (props: { id: string }) => { id: string } & TypedAction<string>
  >;
  getActiveItemSuccess: ActionCreator<
    string,
    (props: {
      record: T & WithId;
    }) => { record: T & WithId } & TypedAction<string>
  >;
  getActiveItemFailure: ActionCreator<
    string,
    (props: {
      errorMessage: string;
    }) => { errorMessage: string } & TypedAction<string>
  >;
}

export function generateActions<T>(
  featureName: string
): FirestoreEntityActions<T> {
  return {
    query: createAction(`[${featureName}] Query`),
    connected: createAction(`[${featureName}] Connected`),
    add: createAction(
      `[${featureName}] Add`,
      props<{ record: T; confirmation: Confirmation<string, any> }>()
    ),
    added: createAction(
      `[${featureName}] Added`,
      props<{ records: (T & WithId)[] }>()
    ),
    set: createAction(
      `[${featureName}] Set`,
      props<{
        record: T & WithId;
        confirmation: Confirmation<string, any>;
      }>()
    ),
    update: createAction(
      `[${featureName}] Update`,
      props<{
        record: Partial<T> & WithId;
        confirmation: Confirmation<string, any>;
      }>()
    ),
    modified: createAction(
      `[${featureName}] Modified`,
      props<{ records: (T & WithId)[] }>()
    ),
    remove: createAction(
      `[${featureName}] Remove`,
      props<{ id: string; confirmation: Confirmation<string, any> }>()
    ),
    removed: createAction(
      `[${featureName}] Removed`,
      props<{ records: (T & WithId)[] }>()
    ),
    queryFailure: createAction(
      `[${featureName}] Query failure`,
      props<{ errorMessage: string }>()
    ),
    addSuccess: createAction(
      `[${featureName}] Add success`,
      props<{ id: string }>()
    ),
    addFailure: createAction(
      `[${featureName}] Add failure`,
      props<{ record: T; errorMessage: string }>()
    ),
    setSuccess: createAction(
      `[${featureName}] Set success`,
      props<{ id: string }>()
    ),
    setFailure: createAction(
      `[${featureName}] Set failure`,
      props<{ record: T & WithId; errorMessage: string }>()
    ),
    updateSuccess: createAction(
      `[${featureName}] Update success`,
      props<{ id: string }>()
    ),
    updateFailure: createAction(
      `[${featureName}] Update failure`,
      props<{ record: T & WithId; errorMessage: string }>()
    ),
    removeSuccess: createAction(
      `[${featureName}] Remove success`,
      props<{ id: string }>()
    ),
    removeFailure: createAction(
      `[${featureName}] Remove failure`,
      props<{ id: string; errorMessage: string }>()
    ),
    getActiveItem: createAction(
      `[${featureName}] Get active item`,
      props<{
        id: string;
      }>()
    ),
    getActiveItemSuccess: createAction(
      `[${featureName}] Get active item success`,
      props<{
        record: T & WithId;
      }>()
    ),
    getActiveItemFailure: createAction(
      `[${featureName}] Get active item failure`,
      props<{
        errorMessage: string;
      }>()
    ),
  };
}

export function createEntityReducer<T>(
  actions: FirestoreEntityActions<T>,
  adapter: EntityAdapter<T & WithId>
) {
  return [
    on(actions.query, (state: FirestoreEntityState<T>) => {
      // if we are already connecting or connected, ignore
      if (state.connecting || state.connected) {
        return state;
      }
      return {
        ...state,
        connecting: true,
        connected: false,
        queryFailure: false,
      };
    }),
    on(actions.connected, (state: FirestoreEntityState<T>) => {
      return {
        ...state,
        connecting: false,
        connected: true,
        queryFailure: false,
      };
    }),
    on(actions.queryFailure, (state: FirestoreEntityState<T>) => {
      return {
        ...state,
        connecting: false,
        connected: false,
        queryFailure: true,
      };
    }),
    on(actions.added, (state: FirestoreEntityState<T>, { records }) => {
      return {
        ...adapter.addMany(records, state),
        connecting: false,
        connected: true,
        queryFailure: false,
        addFailure: undefined,
      };
    }),
    on(actions.modified, (state: FirestoreEntityState<T>, { records }) => {
      // we can not use updateMany since this supports partial updates, meaning that a property that is removed
      // will not be removed from the store, so we replace all modified records
      return {
        ..._.reduce(records, (s, r) => adapter.setOne(r, s), state),
        connecting: false,
        connected: true,
        queryFailure: false,
        addFailure: undefined,
      };
    }),
    on(actions.removed, (state: FirestoreEntityState<T>, { records }) => {
      return {
        ...adapter.removeMany(_.map(records, 'id'), state),
        connecting: false,
        connected: true,
        queryFailure: false,
        removeFailure: undefined,
      };
    }),
    on(actions.addFailure, (state: FirestoreEntityState<T>, { record }) => {
      return {
        ...state,
        addFailure: record,
      };
    }),
    on(actions.setFailure, (state: FirestoreEntityState<T>, { record }) => {
      return {
        ...state,
        setFailure: record,
      };
    }),
    on(actions.updateFailure, (state: FirestoreEntityState<T>, { record }) => {
      return {
        ...state,
        updateFailure: record,
      };
    }),
    on(actions.removeFailure, (state: FirestoreEntityState<T>, { id }) => {
      return {
        ...state,
        removeFailure: id,
      };
    }),
    on(actions.getActiveItem, (state: FirestoreEntityState<T>, { id }) => {
      return {
        ...state,
        activeItem: {
          isLoading: true,
          item: undefined,
          error: undefined,
        },
      };
    }),
    on(
      actions.getActiveItemSuccess,
      (state: FirestoreEntityState<T>, { record }) => {
        return {
          ...state,
          activeItem: {
            isLoading: false,
            item: record,
            error: undefined,
          },
        };
      }
    ),
    on(
      actions.getActiveItemFailure,
      (state: FirestoreEntityState<T>, { errorMessage }) => {
        return {
          ...state,
          activeItem: {
            isLoading: false,
            item: undefined,
            error: errorMessage,
          },
        };
      }
    ),
  ];
}

export function createBaseEffects<T, V extends State>(
  actions$: Actions,
  store: Store<V>,
  actions: FirestoreEntityActions<T>,
  selectors: FirestoreEntitySelectors<T, State>,
  service: OrganizationEntityService<T>,
  feedBack: EntityFeedback,
  snackbarService: SnackbarService
) {
  return {
    query: createQueryEffect(actions$, store, actions, service),
    set: createSetEffect(actions$, store, actions, service),
    add: createAddEffect(actions$, store, actions, service),
    update: createUpdateEffect(actions$, store, actions, service),
    remove: createRemoveEffect(actions$, store, actions, service),
    getActiveItem: createGetActiveItemEffect(
      actions$,
      store,
      actions,
      service,
      selectors
    ),
    displayQueryFailure: createDisplayQueryFailureEffect(
      actions$,
      actions,
      feedBack,
      snackbarService
    ),
    displayGetActiveItemFailure: createDisplayGetActveItemFailureEffect(
      actions$,
      actions,
      feedBack,
      snackbarService
    ),
  };
}

export function createQueryEffect<T, V extends State>(
  actions$: Actions,
  store: Store<V>,
  actions: FirestoreEntityActions<T>,
  service: OrganizationEntityService<T>
) {
  return createEffect(() =>
    actions$.pipe(
      ofType(actions.query),
      withLatestFrom(store.select(getOrganization)),
      map(([, organization]) => organization),
      filter((organization) => !!organization),
      // Use exhaust map here so that we ignore incoming query actions until this one is complete
      exhaustMap((organization) =>
        service.connect(organization.id).pipe(
          takeUntil(actions$.pipe(ofType(Logout))),
          mergeMap((changes) => {
            return changes;
          }),
          map((action) => {
            switch (action.type) {
              case 'empty':
                return actions.connected();
              case 'added':
                return actions.added({
                  records: action.entities,
                });
              case 'modified':
                return actions.modified({
                  records: action.entities,
                });
              case 'removed':
                return actions.removed({
                  records: action.entities,
                });
            }
          }),
          catchError((e) => {
            reportError(e);
            return of(actions.queryFailure(e));
          })
        )
      )
    )
  );
}

export function createAddEffect<T>(
  actions$: Actions,
  store: Store<State>,
  actions: FirestoreEntityActions<T>,
  service: OrganizationEntityService<T>
) {
  return createEffect(() =>
    actions$.pipe(
      ofType(actions.add),
      withLatestFrom(store.select(getOrganization)),
      mergeMap(([add, organization]) => {
        return from(
          service.create(organization ? organization.id : null, add.record)
        ).pipe(
          map((id) => {
            add.confirmation.resolve(id);
            return actions.addSuccess({ id });
          }),
          catchError((e) => {
            reportError(e);
            add.confirmation.reject(e);
            return of(
              actions.addFailure({ record: add.record, errorMessage: e })
            );
          })
        );
      })
    )
  );
}

export function createUpdateEffect<T>(
  actions$: Actions,
  store: Store<State>,
  actions: FirestoreEntityActions<T>,
  service: OrganizationEntityService<T>
) {
  return createEffect(() =>
    actions$.pipe(
      ofType(actions.update),
      withLatestFrom(store.select(getOrganization)),
      mergeMap(([update, organization]) => {
        return from(
          service.update(
            organization.id,
            update.record.id,
            removeIdFromRecord(update.record)
          )
        ).pipe(
          map(() => {
            update.confirmation.resolve(update.record.id);
            return actions.updateSuccess({ id: update.record.id });
          }),
          catchError((error) => {
            reportError(error);
            update.confirmation.reject(error);
            return of(
              actions.updateFailure({
                record: update.record,
                errorMessage: error,
              })
            );
          })
        );
      })
    )
  );
}

export function createSetEffect<T>(
  actions$: Actions,
  store: Store<State>,
  actions: FirestoreEntityActions<T>,
  service: OrganizationEntityService<T>
) {
  return createEffect(() =>
    actions$.pipe(
      ofType(actions.set),
      withLatestFrom(store.select(getOrganization)),
      mergeMap(([set, organization]) => {
        return from(
          service.set(
            organization.id,
            set.record.id,
            removeIdFromRecord(set.record)
          )
        ).pipe(
          map(() => {
            set.confirmation.resolve(set.record.id);
            return actions.setSuccess({ id: set.record.id });
          }),
          catchError((e) => {
            reportError(e);
            set.confirmation.reject(e);
            return of(
              actions.setFailure({
                record: set.record,
                errorMessage: e,
              })
            );
          })
        );
      })
    )
  );
}

export function createRemoveEffect<T>(
  actions$: Actions,
  store: Store<State>,
  actions: FirestoreEntityActions<T>,
  service: OrganizationEntityService<T>
) {
  return createEffect(() =>
    actions$.pipe(
      ofType(actions.remove),
      withLatestFrom(store.select(getOrganization)),
      mergeMap(([remove, organization]) => {
        return from(service.remove(organization.id, remove.id)).pipe(
          map(() => {
            remove.confirmation.resolve(remove.id);
            return actions.removeSuccess({ id: remove.id });
          }),
          catchError((e) => {
            reportError(e);
            remove.confirmation.reject(e);
            return of(
              actions.removeFailure({ id: remove.id, errorMessage: e })
            );
          })
        );
      })
    )
  );
}
export function createGetActiveItemEffect<T>(
  actions$: Actions,
  store: Store<State>,
  actions: FirestoreEntityActions<T>,
  service: OrganizationEntityService<T>,
  selectors: FirestoreEntitySelectors<T, State>
) {
  return createEffect(() =>
    actions$.pipe(
      ofType(actions.getActiveItem),
      withLatestFrom(store.select(getOrganization)),
      switchMap(([context, organization]) => {
        return store.select(selectors.selectEntities).pipe(
          debounceTime(300),
          switchMap((entities) => {
            return iif(
              () => !!entities[context.id],
              store.select(selectors.getById(context.id)),
              service
                .get(organization?.id, context.id)
                .pipe(takeUntil(actions$.pipe(ofType(Logout))))
            ).pipe(
              map((record) => {
                return actions.getActiveItemSuccess({ record: record });
              }),
              catchError((e) => {
                reportError(e);
                return of(actions.getActiveItemFailure({ errorMessage: e }));
              })
            );
          })
        );
      })
    )
  );
}

export function createDisplayQueryFailureEffect<T>(
  actions$: Actions,
  actions: FirestoreEntityActions<T>,
  feedBackType: EntityFeedback,
  snackbarService: SnackbarService
) {
  return createEffect(
    () =>
      actions$.pipe(
        ofType(actions.queryFailure),
        tap((x) => snackbarService.showError(feedBackType.query))
      ),
    { dispatch: false }
  );
}

export function createDisplayGetActveItemFailureEffect<T>(
  actions$: Actions,
  actions: FirestoreEntityActions<T>,
  feedBackType: EntityFeedback,
  snackbarService: SnackbarService
) {
  return createEffect(
    () =>
      actions$.pipe(
        ofType(actions.getActiveItemFailure),
        tap((x) => snackbarService.showError(feedBackType.get_active_item))
      ),
    { dispatch: false }
  );
}

export function removeIdFromRecord<T>(record: T & WithId): T {
  const recordWithoutId = { ...record };
  delete recordWithoutId.id;
  return recordWithoutId;
}
