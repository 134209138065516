import { from, of } from 'rxjs';

import { Injectable } from '@angular/core';

import { catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { reportError } from '@arrivage-sentry/report-error';
import { RelationshipInfosService } from '@arrivage-services/relationship-infos/relationship-infos.service';
import { SnackbarService } from '@arrivage-snackbar/snackbar.service';
import { getOrganization } from '@arrivage-store/context/context.selectors';
import { createBaseEffects } from '@arrivage-store/generators';
import {
  Organization,
  Relationship,
  WithId,
} from '@arrivage/model/dist/src/model';

import * as relationshipInfosActions from './relationship-infos.actions';
import * as selectors from './relationship-infos.selectors';
import { State } from './relationship-infos.state';
import { RelationshipFeedback } from './relationship.effects';

@Injectable()
export class RelationshipInfosEffects {
  query$;
  update$;
  remove$;

  constructor(
    private actions$: Actions,
    private store: Store<State>,
    private service: RelationshipInfosService,
    private snackbarService: SnackbarService
  ) {
    ({
      query: this.query$,
      update: this.update$,
      remove: this.remove$,
    } = createBaseEffects(
      this.actions$,
      this.store,
      relationshipInfosActions,
      selectors,
      this.service,
      RelationshipFeedback,
      this.snackbarService
    ));
  }

  add$ = createEffect(() =>
    this.actions$.pipe(
      ofType(relationshipInfosActions.add),
      withLatestFrom(this.store.select(getOrganization)),
      mergeMap(([infoAdd, organization]) => {
        const relationship: Relationship =
          createRelationFromVendorOrganization(organization);
        return from(
          this.service.createRelationAndRelationship(
            organization.id,
            infoAdd.record,
            relationship
          )
        ).pipe(
          map((id) => {
            infoAdd.confirmation.resolve(id);
            return relationshipInfosActions.addSuccess({ id: id });
          }),
          catchError((e) => {
            reportError(e);
            infoAdd.confirmation.reject(e);

            return of(
              relationshipInfosActions.addFailure({
                record: infoAdd.record,
                errorMessage: e,
              })
            );
          })
        );
      })
    )
  );

  updateMany$ = createEffect(() =>
    this.actions$.pipe(
      ofType(relationshipInfosActions.updateMany),
      withLatestFrom(this.store.select(getOrganization)),
      mergeMap(([update, organization]) => {
        return from(
          this.service.updateMany(organization.id, update.records)
        ).pipe(
          map(() => {
            update.confirmation.resolve();
            return relationshipInfosActions.updateManySuccess();
          }),
          catchError((error) => {
            reportError(error);
            update.confirmation.reject(error);
            return of(
              relationshipInfosActions.updateManyFailure({
                errorMessage: error,
              })
            );
          })
        );
      })
    )
  );
}

function createRelationFromVendorOrganization(
  organization: Organization & WithId
): Relationship {
  return {
    participants: [organization.id],
    participantsData: {
      [organization.id]: {
        name: organization.name,
        logoUrl: organization.logoUrl ? organization.logoUrl : '',
        contactInfo: organization.contactInfo,
        address: organization.address,
      },
    },
  };
}
