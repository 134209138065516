import { Address } from '@arrivage/model/dist/src/model';

import { GeoPoint } from 'firebase/firestore';
import { LangUtils } from './lang.utils';

export namespace AddressUtils {
  export function isCompleteAddress(address: Address) {
    return (
      !LangUtils.nullOrUndefined(address) &&
      LangUtils.isNotEmptyString(address.address) &&
      LangUtils.isNotEmptyString(address.city) &&
      LangUtils.isNotEmptyString(address.stateOrProvince) &&
      LangUtils.isNotEmptyString(address.zipOrPostalCode) &&
      LangUtils.isNotEmptyString(address.country)
    );
  }

  export function isEmptyAddress(address: Address) {
    return (
      !LangUtils.nullOrUndefined(address) &&
      address.address === '' &&
      address.city === '' &&
      address.stateOrProvince === '' &&
      address.zipOrPostalCode === '' &&
      address.country === ''
    );
  }

  export function formatAddress(address: Address, hideCountry?: boolean) {
    if (address) {
      const addressLine = address.address ? `${address.address},\n` : '';

      const printSecondLine =
        LangUtils.isNotEmptyString(address.city) &&
        LangUtils.isNotEmptyString(address.stateOrProvince) &&
        LangUtils.isNotEmptyString(address.zipOrPostalCode);

      const secondLine = printSecondLine
        ? `${address.city}, ${address.stateOrProvince}, ${address.zipOrPostalCode}\n`
        : '';

      const countryLine = `${address.country}` || '';

      return hideCountry
        ? `${addressLine}${secondLine}`.trim()
        : `${addressLine}${secondLine}${countryLine}`.trim();
    }
    return '';
  }

  export function formatAddressSingleLine(address?: Address): string {
    if (!address) return '';

    const addressLine = address.address ? `${address.address}, ` : '';
    const cityLine = address.city ? `${address.city}, ` : '';
    const stateLine = address.stateOrProvince
      ? `${address.stateOrProvince}, `
      : '';
    const zipLine = address.zipOrPostalCode
      ? `${address.zipOrPostalCode}, `
      : '';

    const countryLine = address.country || '';

    return `${addressLine}${cityLine}${stateLine}${zipLine}${countryLine}`;
  }

  export async function getGeoPointFromAddress(address: Address) {
    const geocoder = new google.maps.Geocoder();
    const formattedAddress = `${address.address}, ${address.city}, ${address.stateOrProvince}, ${address.zipOrPostalCode}, ${address.country}`;
    const geocodeResults = await geocoder.geocode({ address: formattedAddress });
    const point = geocodeResults.results[0].geometry.location;
    return new GeoPoint(point.lat(), point.lng());
  }
}
