import _ from 'lodash';

import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';

import { reportError } from '@arrivage-sentry/report-error';
import { State } from '@arrivage-store/state';
import { SendProducerOfferResponseData } from '@arrivage/model/dist/src/cloud-functions-api';
import {
  Money,
  Organization,
  PurchaseOrder,
  WithId,
} from '@arrivage/model/dist/src/model';
import { BillingUtils } from '@arrivage/model/dist/src/utils';

import {
  logEvent,
  setOrganizationProperties,
} from '../store/analytics.actions';

@Injectable({
  providedIn: 'root',
})
export class AnalyticsFacade {
  constructor(private store: Store<State>) {}

  setOrganizationUserProperties(organization: Organization & WithId) {
    this.store.dispatch(
      setOrganizationProperties({
        organization: organization,
      })
    );
  }

  logSetupStepCompleted(
    step:
      | 'profile_step_completed'
      | 'settings_step_completed'
      | 'seller_inventory_step_completed'
      | 'seller_price_list_step_completed'
      | 'seller_delivery_step_completed'
      | 'seller_customer_step_completed'
      | 'seller_offer_step_completed'
  ) {
    this.store.dispatch(
      logEvent({
        eventName: step,
      })
    );
  }

  logSendConversationMessage() {
    this.store.dispatch(
      logEvent({
        eventName: 'send_conversation_message',
      })
    );
  }

  logCreateAccount() {
    this.store.dispatch(
      logEvent({
        eventName: 'create_account',
      })
    );
  }

  logCreateProduct() {
    this.store.dispatch(
      logEvent({
        eventName: 'add_product',
      })
    );
  }

  logDeleteProduct() {
    this.store.dispatch(
      logEvent({
        eventName: 'delete_product',
      })
    );
  }

  logCreateFormat() {
    this.store.dispatch(
      logEvent({
        eventName: 'add_format',
      })
    );
  }

  logDeleteFormat() {
    this.store.dispatch(
      logEvent({
        eventName: 'delete_format',
      })
    );
  }

  logCreatePriceList() {
    this.store.dispatch(
      logEvent({
        eventName: 'add_price_list',
      })
    );
  }

  logUpdatePriceList() {
    this.store.dispatch(
      logEvent({
        eventName: 'update_price_list',
      })
    );
  }

  logDeletePriceList() {
    this.store.dispatch(
      logEvent({
        eventName: 'delete_price_list',
      })
    );
  }

  logDuplicatePriceList() {
    this.store.dispatch(
      logEvent({
        eventName: 'duplicate_price_list',
      })
    );
  }

  logCreateDelivery() {
    this.store.dispatch(
      logEvent({
        eventName: 'add_delivery',
      })
    );
  }

  logDeleteDelivery() {
    this.store.dispatch(
      logEvent({
        eventName: 'delete_delivery',
      })
    );
  }

  logSharePriceList() {
    this.store.dispatch(
      logEvent({
        eventName: 'share_price_list',
      })
    );
  }

  logSearch(query: string) {
    this.store.dispatch(
      logEvent({
        eventName: 'search',
        params: { search_term: query },
      })
    );
  }

  logViewOffer(type: 'private' | 'public' | 'external' | 'self') {
    this.store.dispatch(
      logEvent({
        eventName: 'view_item_list',
        params: { offer_type: type },
      })
    );
  }

  logCreatePurchaseOrder(newPurchaseOrder: PurchaseOrder) {
    try {
      const total = BillingUtils.computeTaxAndTotal(
        _.map(newPurchaseOrder.orderItems, (i) => {
          return {
            price: i.price,
            quantity: i.requestedQuantity,
            applicableTaxes: i.format.applicableTaxes,
          };
        }),
        newPurchaseOrder.taxSettings,
        newPurchaseOrder.delivery
          ? {
              applicableTaxes:
                newPurchaseOrder.deliveryFeeApplicableTaxes || [],
              ...newPurchaseOrder.delivery,
            }
          : null,
        newPurchaseOrder.volumeDiscounts,
        newPurchaseOrder.discounts,
        newPurchaseOrder.clientDiscount
      );

      this.store.dispatch(
        logEvent({
          eventName: 'purchase',
          params: {
            value: Money.display(total.total).amount,
            currency: newPurchaseOrder.currency,
            transaction_id: newPurchaseOrder.transactionId,
            tax: Money.display(
              Money.sum(_.map(total.taxes, (t) => t.taxAmount))
            ).amount,
            shipping: total.appliedDeliveryFee
              ? Money.display(total.appliedDeliveryFee).amount
              : 0,
          },
        })
      );
    } catch (e) {
      reportError(e);
    }
  }

  logCreateProducer() {
    this.store.dispatch(
      logEvent({
        eventName: 'add_producer',
      })
    );
  }

  logUpdateProducer() {
    this.store.dispatch(
      logEvent({
        eventName: 'update_producer',
      })
    );
  }

  logDeleteProducer() {
    this.store.dispatch(
      logEvent({
        eventName: 'delete_producer',
      })
    );
  }

  logSendProducerOffer(status: SendProducerOfferResponseData) {
    this.store.dispatch(
      logEvent({
        eventName: 'send_producer_offer',
        params: status,
      })
    );
  }

  logClickOnProducerProfile() {
    this.store.dispatch(
      logEvent({
        eventName: 'click_on_producer_profile',
      })
    );
  }

  logClickOnProductDetails() {
    this.store.dispatch(
      logEvent({
        eventName: 'click_on_product_details',
      })
    );
  }

  logClickOnFormatDetails() {
    this.store.dispatch(
      logEvent({
        eventName: 'click_on_format_details',
      })
    );
  }
}
