import { Observable } from 'rxjs';
import { PurchaseReportLine } from 'src/app/purchase-report/common/model/purchase-report.model';

import { Injectable } from '@angular/core';

import { Dictionary } from '@ngrx/entity';
import { Store } from '@ngrx/store';

import { AnalyticsFacade } from '@arrivage-analytics/api/analytics.facade';
import { State } from '@arrivage-store/state';
import {
  LocalDate,
  Offer,
  PurchaseOrder,
  WithId,
} from '@arrivage/model/dist/src/model';

import { ContextFacade } from '@arrivage-store/api/context.facade';
import { PurchaseOrderApiService } from '../../common/api/purchase-orders.api.service';
import { PurchaseOrdersFacade } from '../../common/api/purchase-orders.facade';
import {
  PurchaseOrderCreationCustomerInfo,
  PurchaseOrderCreationStoreInfo,
  PurchaseOrderDashboardMetrics,
  PurchaseOrderEditionStoreInfo,
} from '../../common/model/purchase-orders.model';
import {
  DELIVERY_DIALOG_RESULT_ON_TIME,
  DeliveryDialogResult,
} from '../components/confirm-delivery-dialog/confirm-delivery-dialog.component';
import { VendorPurchaseOrderService } from '../services/vendor-purchase-order.service';
import * as actions from '../store/vendor-purchase-orders.actions';
import { VendorPurchaseOrdersActions } from '../store/vendor-purchase-orders.actions';
import {
  VendorPurchaseOrdersSelectors,
  getCreatePurchaseOrderInfo,
  getEditPurchaseOrderInfo,
  getPurchaseOrderDashboardMetrics,
  selectCreatePurchaseOrderInfoForRelationship,
  selectCustomerInfo,
  selectOfferFromPurchaseOrder,
  selectPurchaseOrderByRelationshipFromPurchaseOrdersByDeliveryOrPickupDateRange,
  selectPurchaseOrderByRelationshipFromPurchaseOrdersBySentDateRange,
  selectSalesReportFromAllPurchaseOrderByDeliveryOrPickupDateRange,
  selectSalesReportFromAllPurchaseOrderBySentDateRange,
} from '../store/vendor-purchase-orders.selectors';

@Injectable({
  providedIn: 'root',
})
export class VendorPurchaseOrderFacade extends PurchaseOrdersFacade<VendorPurchaseOrderService> {
  creationInfo$: Observable<PurchaseOrderCreationStoreInfo>;
  editionInfo$: Observable<PurchaseOrderEditionStoreInfo>;
  purchaseOrderDashboardMetrics$: Observable<PurchaseOrderDashboardMetrics>;

  constructor(
    store: Store<State>,
    purchaseOrderApiService: PurchaseOrderApiService,
    analytics: AnalyticsFacade,
    purchaseOrderService: VendorPurchaseOrderService,
    contextFacade: ContextFacade
  ) {
    super(
      store,
      VendorPurchaseOrdersSelectors,
      VendorPurchaseOrdersActions,
      purchaseOrderApiService,
      analytics,
      purchaseOrderService,
      contextFacade
    );

    this.creationInfo$ = this.store.select(getCreatePurchaseOrderInfo);
    this.editionInfo$ = this.store.select(getEditPurchaseOrderInfo);
    this.purchaseOrderDashboardMetrics$ = this.store.select(
      getPurchaseOrderDashboardMetrics
    );
  }

  getCreatePurchaseOrderInfo(): Observable<PurchaseOrderCreationStoreInfo> {
    return this.creationInfo$;
  }

  getSalesReportFromAllPurchaseOrderBySentDateRange(): Observable<
    PurchaseReportLine[]
  > {
    return this.store.select(
      selectSalesReportFromAllPurchaseOrderBySentDateRange
    );
  }

  getSalesReportFromAllPurchaseOrderByDeliveryOrPickupDateRange(): Observable<
    PurchaseReportLine[]
  > {
    return this.store.select(
      selectSalesReportFromAllPurchaseOrderByDeliveryOrPickupDateRange
    );
  }

  getCreatePurchaseOrderInfoForRelationship(): Observable<PurchaseOrderCreationStoreInfo> {
    return this.store.select(selectCreatePurchaseOrderInfoForRelationship);
  }

  getEditPurchaseOrderInfo(): Observable<PurchaseOrderEditionStoreInfo> {
    return this.editionInfo$;
  }

  getOfferFromPurchaseOrder(): Observable<Offer & WithId> {
    return this.store.select(selectOfferFromPurchaseOrder);
  }

  getPurchaseOrderDashboardMetrics(): Observable<PurchaseOrderDashboardMetrics> {
    return this.purchaseOrderDashboardMetrics$;
  }

  getCustomerInfo(): Observable<PurchaseOrderCreationCustomerInfo> {
    return this.store.select(selectCustomerInfo);
  }

  getPurchaseOrdersByRelationshipFromPurchaseOrdersBySentDateRange(): Observable<
    Dictionary<(PurchaseOrder & WithId)[]>
  > {
    return this.store.select(
      selectPurchaseOrderByRelationshipFromPurchaseOrdersBySentDateRange
    );
  }

  getPurchaseOrdersByRelationshipFromPurchaseOrdersByDeliveryOrPickupDateRange(): Observable<
    Dictionary<(PurchaseOrder & WithId)[]>
  > {
    return this.store.select(
      selectPurchaseOrderByRelationshipFromPurchaseOrdersByDeliveryOrPickupDateRange
    );
  }

  deliveryHasOpenPurchaseOrders(
    organizationId: string,
    deliveryId: string
  ): Promise<boolean> {
    return this.purchaseOrderService.hasOpenPurchaseOrders(
      organizationId,
      deliveryId
    );
  }

  updatePurchaseOrderWithFile(data: {
    purchaseOrder: Partial<PurchaseOrder> & WithId;
    newExternalInvoice?: File;
    newExternalDeliveryNote?: File;
  }): Promise<string> {
    return new Promise((resolve, reject) => {
      this.store.dispatch(
        actions.updateWithExternalFile({
          record: {
            purchaseOrder: data.purchaseOrder,
            newExternalDeliveryNote: data.newExternalDeliveryNote,
            newExternalInvoice: data.newExternalInvoice,
          },
          confirmation: {
            resolve: resolve,
            reject: reject,
          },
        })
      );
    });
  }

  async markAsDelivered(
    purchaseOrder: PurchaseOrder & WithId,
    deliveredDate: DeliveryDialogResult
  ): Promise<string> {
    return deliveredDate === DELIVERY_DIALOG_RESULT_ON_TIME
      ? this.markAsDeliveredOnTime(purchaseOrder)
      : this.markAsDeliveredOnDate(purchaseOrder, deliveredDate);
  }

  async markAsUnDelivered(
    purchaseOrder: PurchaseOrder & WithId
  ): Promise<string> {
    if (!purchaseOrder.delivered)
      return Promise.reject('Already marked as undelivered.');
    if (purchaseOrder.invoicePaid)
      return Promise.reject('Cannot mark as undelivered if invoice is paid.');

    return this.updateItem({
      id: purchaseOrder.id,
      delivered: null,
    });
  }

  private markAsDeliveredOnTime(
    purchaseOrder: PurchaseOrder & WithId
  ): Promise<string> {
    if (purchaseOrder.delivered) return;
    if (purchaseOrder.deliveryDate) {
      return this.updateItem({
        id: purchaseOrder.id,
        delivered: purchaseOrder.deliveryDate.getTime(),
      });
    } else if (purchaseOrder.pickup?.selectedDay) {
      return this.updateItem({
        id: purchaseOrder.id,
        delivered: LocalDate.toDate(purchaseOrder.pickup.selectedDay).getTime(),
      });
    }
    return Promise.reject('No delivery date or pickup date.');
  }

  private markAsDeliveredOnDate(
    purchaseOrder: PurchaseOrder & WithId,
    deliveredDate: Date
  ): Promise<string> {
    if (purchaseOrder.delivered) return;
    const delivered = deliveredDate.getTime();
    const update: Partial<PurchaseOrder> & WithId = {
      id: purchaseOrder.id,
      delivered: delivered,
    };
    if (purchaseOrder.deliveryDate) {
      update['deliveryDate'] = deliveredDate;
    } else if (purchaseOrder.pickup?.selectedDay) {
      update['pickup.selectedDay'] = LocalDate.fromDate(deliveredDate);
    } else {
      return Promise.reject('No delivery date or pickup date.');
    }
    return this.updateItem(update);
  }
}
