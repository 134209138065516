import { EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { createReducer, Action } from '@ngrx/store';

import { Conversation, WithId } from '@arrivage/model/dist/src/model';

import * as ConversationsActions from './conversations.actions';
import { ConversationsState } from './conversations.state';
import { createEntityReducer, createInitialState } from '@arrivage-store/generators';

export const adapter: EntityAdapter<
  Conversation & WithId
> = createEntityAdapter<Conversation & WithId>();

export const initialState: ConversationsState = createInitialState(adapter);

const conversationsReducer = createReducer(
  initialState,
  ...createEntityReducer(ConversationsActions, adapter)
);

export function reducer(state: ConversationsState | undefined, action: Action) {
  return conversationsReducer(state, action);
}
