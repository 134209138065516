import { EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { createReducer, Action } from '@ngrx/store';

import {
  createEntityReducer,
  createInitialState,
} from '@arrivage-store/generators';
import { Format, WithId } from '@arrivage/model/dist/src/model';

import * as FormatsActions from './formats.actions';
import { FormatsState } from './formats.state';

export const adapter: EntityAdapter<Format & WithId> = createEntityAdapter<
  Format & WithId
>();

export const initialState: FormatsState = createInitialState(adapter);

const formatsReducer = createReducer(
  initialState,
  ...createEntityReducer(FormatsActions, adapter)
);

export function reducer(state: FormatsState | undefined, action: Action) {
  return formatsReducer(state, action);
}
