import * as _ from 'lodash';

import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';

import { selectEntities as selectEntitiesFormats } from '@arrivage-formats/store/formats.selectors';
import * as productSelectors from '@arrivage-products/store/products.selectors';
import { createSelectors } from '@arrivage-store/generators';
import { State as RootState } from '@arrivage-store/state';
import {
  Format,
  InventoryItem,
  Product,
  WithId,
} from '@arrivage/model/dist/src/model';

import {
  FormatWithInventoryItem,
  InventoryDashboardMetrics,
  ProductWithFormatsAndInventoryItems,
} from '../model/inventory.model';
import { adapter } from './inventory.reducer';
import { InventoryState, State } from './inventory.state';

export const getInventoryState =
  createFeatureSelector<InventoryState>('inventory');

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
  connecting,
  connected,
  connectState,
  queryFailure,
  addFailure,
  setFailure,
  updateFailure,
  removeFailure,
  getById,
  selectActiveItem,
  isLoadingActiveItem,
  selectActiveItemState,
} = createSelectors<InventoryItem, State>(adapter, getInventoryState);

export const getFormatWithInventoryItem = createSelector<
  RootState,
  [(InventoryItem & WithId)[], Dictionary<Format & WithId>],
  FormatWithInventoryItem[]
>(
  selectAll,
  selectEntitiesFormats,
  (
    inventoryItems: (InventoryItem & WithId)[],
    formats: Dictionary<Format & WithId>
  ) =>
    inventoryItems
      // return only inventory items for which the format exists
      // upon format/inventory item deletion it is possible that
      // the item has not been removed from the store but the format was
      .filter((i) => !!formats[i.formatId])
      .map((inventoryItem) => {
        return {
          format: formats[inventoryItem.formatId],
          inventoryItem: inventoryItem,
        };
      })
);

export const getProductWithFormatsAndInventoryItems = createSelector<
  RootState,
  [FormatWithInventoryItem[], (Product & WithId)[]],
  ProductWithFormatsAndInventoryItems[]
>(
  getFormatWithInventoryItem,
  productSelectors.selectAll,
  (
    formatWithInventoryItem: FormatWithInventoryItem[],
    products: (Product & WithId)[]
  ) =>
    products.map((product) => {
      return {
        product: product,
        formatsWithInventoryItems: formatWithInventoryItem.filter(
          (f) => f.inventoryItem.productId === product.id
        ),
      };
    })
);

export const selectInventoryDashboardMetrics = createSelector<
  RootState,
  [ProductWithFormatsAndInventoryItems[]],
  InventoryDashboardMetrics
>(getProductWithFormatsAndInventoryItems, (entities) => {
  const nbProducts = entities.length;
  const nbProductsWithoutFormat = entities.filter(
    (x) => x.formatsWithInventoryItems.length === 0
  ).length;
  const nbFormatsWithoutBasePrice = _.sum(
    entities
      .filter((x) => x.formatsWithInventoryItems.length !== 0)
      .map(
        (x) =>
          x.formatsWithInventoryItems.filter(
            (i) => i.inventoryItem.basePrice.amount === 0
          ).length
      )
  );
  return {
    nbProducts: nbProducts,
    nbProductsWithoutFormat: nbProductsWithoutFormat,
    nbFormatsWithoutBasePrice: nbFormatsWithoutBasePrice,
  };
});
