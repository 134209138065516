import distance from '@turf/distance';
import { addYears, isAfter, startOfDay, subHours } from 'date-fns';
import _ from 'lodash';

import {
  AbstractControl,
  FormControl,
  FormGroupDirective,
  NgForm,
  ValidatorFn,
} from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';

import { DeliveryUtils } from '@arrivage-distribution/common/utils/delivery.utils';
import {
  Bounds,
  CurrentDate,
} from '@arrivage-distribution/common/utils/model/delivery.utils.model';
import { DatesAvailability } from '@arrivage-price-lists/model/price-list.model';
import {
  LeadTimeHours,
  LocalDate,
  Money,
  Organization,
  Pickup,
  PickupOption,
  TimeOfDay,
  TimeRange,
  VendorDelivery,
  WeekDays,
  WithId,
} from '@arrivage/model/dist/src/model';

export interface PickupPartAsDelivery {
  delivery: VendorDelivery;
  selectedTime: TimeRange;
}

export namespace PickupUtils {
  export const TEN_YEARS = 10;
  export const MAX_HOURS = 24 * 365 * 10;

  export const ALL_WEEK_DAYS = [
    WeekDays.SUNDAY,
    WeekDays.MONDAY,
    WeekDays.TUESDAY,
    WeekDays.WEDNESDAY,
    WeekDays.THURSDAY,
    WeekDays.FRIDAY,
    WeekDays.SATURDAY,
  ];

  export class ScheduleErrorStateMatcher implements ErrorStateMatcher {
    isErrorState(
      control: FormControl | null,
      form: FormGroupDirective | NgForm | null
    ): boolean {
      return control.parent.hasError('invalidSchedule') && control.touched;
    }
  }

  export const timeRangeValidator: ValidatorFn = (
    control: AbstractControl
  ): { invalidSchedule: string } | null => {
    const to = control.get('to').value;
    const from = control.get('from').value;

    return to <= from ? { invalidSchedule: 'invalid Schedule' } : null;
  };

  export function getPickupOptionDate(
    pickupOption: Pick<PickupOption, 'selectedDay' | 'selectedTime'>,
    hoursToConvert: 'from' | 'to' = 'from'
  ): Date | null {
    if (pickupOption) {
      return new Date(
        LocalDate.toDate(pickupOption.selectedDay).setHours(
          pickupOption.selectedTime[hoursToConvert].hours,
          pickupOption.selectedTime[hoursToConvert].minutes
        )
      );
    }
    return null;
  }

  export function getPickupOptionString(p: PickupOption): string {
    if (p) {
      return (
        '' +
        p.selectedDay.year +
        '-' +
        p.selectedDay.month +
        '-' +
        p.selectedDay.day +
        '-' +
        p.selectedTime.from.hours +
        '-' +
        p.selectedTime.from.minutes +
        '-' +
        p.selectedTime.to.hours +
        '-' +
        p.selectedTime.to.minutes
      );
    }
    return null;
  }

  export function getPickupAvailability(
    pickups: Pickup[],
    options: Bounds & CurrentDate = {}
  ): DatesAvailability {
    if (!pickups) return DatesAvailability.NONE;
    if (pickups.length <= 0) return DatesAvailability.NONE;

    const pickupParts = _(pickups)
      .filter((pickup) => !!pickup)
      .flatMap((pickup) => PickupUtils.convertPickupToDeliveries(pickup))
      .value();

    if (pickupParts.length <= 0) return DatesAvailability.NONE;
    if (
      pickupParts.some((pickupPart) =>
        DeliveryUtils.hasOpenDeliveryDate(pickupPart.delivery, options)
      )
    )
      return DatesAvailability.AVAILABLE;
    if (
      pickupParts.some((pickupPart) =>
        DeliveryUtils.hasDeliveryDate(pickupPart.delivery, options)
      )
    )
      return DatesAvailability.NONE_WITH_DELAY;
    return DatesAvailability.NONE;
  }

  /**
   * Get the next open pickup option,
   * i.e the next pickup option, open or not, at currentDate
   * @param pickup
   * @param options
   * @returns
   */
  export function getNextPickupOption(
    pickup: Pickup,
    options: Bounds & CurrentDate = {}
  ): PickupOption {
    return PickupUtils.getNextPickupOptions(pickup, 1, options)[0];
  }

  /**
   * Get the next open pickup options,
   * i.e the next pickup options, open or not, at currentDate
   * @param pickup
   * @param numberOfOptions
   * @param options
   * @returns
   */
  export function getNextPickupOptions(
    pickup: Pickup,
    numberOfOptions: number,
    options: Bounds & CurrentDate = {}
  ): PickupOption[] {
    return PickupUtils.getAllNextPickupOptions(pickup, options).slice(
      0,
      numberOfOptions
    );
  }

  /**
   * Get all open pickup options,
   * i.e pickup options, open or not, at currentDate
   * @param pickup
   * @param options
   * @returns
   */
  export function getAllNextPickupOptions(
    pickup: Pickup,
    options: Bounds & CurrentDate = {}
  ): PickupOption[] {
    if (!pickup) return [];
    const currentDate = options.currentDate ?? new Date();
    const pickupParts = PickupUtils.convertPickupToDeliveries(pickup);
    return _(pickupParts)
      .flatMap((pickupPart) => {
        const lowerBoundWithLeadTime = DeliveryUtils.addLeadTime(
          currentDate,
          pickupPart.delivery.minimumLeadTimeHours
        );
        return DeliveryUtils.getAllNextDeliveryDates(
          pickupPart.delivery,
          options
        )
          .filter((date) => {
            return isAfter(date, lowerBoundWithLeadTime);
          })
          .map((date) => ({
            ...pickup,
            selectedDay: LocalDate.fromDate(date),
            selectedTime: pickupPart.selectedTime,
          }));
      })
      .sort(
        (a, b) =>
          LocalDate.toDate(a.selectedDay).getTime() +
          a.selectedTime.from.hours * 60 +
          a.selectedTime.from.minutes -
          LocalDate.toDate(b.selectedDay).getTime() -
          b.selectedTime.from.hours * 60 -
          b.selectedTime.from.minutes
      )
      .value();
  }

  /**
   * Get the next open pickup option,
   * i.e the next pickup option, open at currentDate
   * @param pickup
   * @param options
   * @returns
   */
  export function getNextOpenPickupOption(
    pickup: Pickup,
    options: Bounds & CurrentDate = {}
  ): PickupOption {
    return PickupUtils.getNextOpenPickupOptions(pickup, 1, options)[0];
  }

  /**
   * Get the next open pickup options,
   * i.e the next pickup options, open at currentDate
   * @param pickup
   * @param numberOfOptions
   * @param options
   * @returns
   */
  export function getNextOpenPickupOptions(
    pickup: Pickup,
    numberOfOptions: number,
    options: Bounds & CurrentDate = {}
  ): PickupOption[] {
    return PickupUtils.getAllNextOpenPickupOptions(pickup, options).slice(
      0,
      numberOfOptions
    );
  }

  /**
   * Get the next open pickup,
   * i.e the first next pickup, open at currentDate
   * @param pickups
   * @returns
   */
  export function getNextOpenPickup(
    pickups: (Pickup & WithId)[]
  ): Pickup & WithId {
    const pickupSortedByDate = pickups
      .filter((pickup) => PickupUtils.hasOpenPickupDate([pickup]))
      .sort((a, b) => {
        const aDate = PickupUtils.getPickupOptionDate(
          PickupUtils.getNextOpenPickupOption(a)
        );
        const bDate = PickupUtils.getPickupOptionDate(
          PickupUtils.getNextOpenPickupOption(b)
        );
        return aDate?.getTime() - bDate?.getTime();
      });
    return pickupSortedByDate[0];
  }

  /**
   * Get all next open pickup options,
   * i.e all next pickup options, open at currentDate
   * @param pickups
   * @returns
   */
  export function getAllNextOpenPickupOptions(
    pickup: Pickup,
    options: Bounds & CurrentDate = {}
  ): PickupOption[] {
    if (!pickup) return [];
    const pickupParts = convertPickupToDeliveries(pickup);
    return _(pickupParts)
      .flatMap((pickupPart) =>
        DeliveryUtils.getAllNextOpenDeliveryDates(
          pickupPart.delivery,
          options
        ).map((date) => ({
          ...pickup,
          selectedDay: LocalDate.fromDate(date),
          selectedTime: pickupPart.selectedTime,
        }))
      )
      .sort(
        (a, b) =>
          LocalDate.toDate(a.selectedDay).getTime() +
          a.selectedTime.from.hours * 60 +
          a.selectedTime.from.minutes -
          LocalDate.toDate(b.selectedDay).getTime() -
          b.selectedTime.from.hours * 60 -
          b.selectedTime.from.minutes
      )
      .value();
  }

  export function getNearestPickupFrom(
    organization: Organization & WithId,
    pickups: (Pickup & WithId)[]
  ): (Pickup & WithId) | undefined {
    return _.minBy(pickups, (pickup) => {
      return getPickupDistanceFrom(organization, pickup);
    });
  }

  export function getPickupDistanceFrom(
    organization: Organization & WithId,
    pickup: Pickup & WithId
  ) {
    if (pickup?.location && organization?.location) {
      return Math.round(
        distance(
          [organization.location.longitude, organization.location.latitude],
          [pickup.location.longitude, pickup.location.latitude]
        )
      );
    }
    return null;
  }

  // TODO: WEB-3119: Move into model in a future ticket
  export function valueToTimeOfDay(value: string): TimeOfDay {
    const [hours, minutes] = value.split(':');
    return {
      hours: +hours,
      minutes: +minutes,
    };
  }

  // TODO: WEB-3119: Move into model in a future ticket
  export function timeOfDayToValue(value: TimeOfDay): string {
    return `${value.hours.toString().padStart(2, '0')}:${value.minutes
      .toString()
      .padStart(2, '0')}`;
  }

  export function isOpenPickupOption(
    pickupOption: PickupOption,
    options: Bounds & CurrentDate = {}
  ): boolean {
    const currentDate = options.currentDate ?? new Date();

    let lowerBound: Date;
    if (pickupOption.maximumLeadTimeHours) {
      lowerBound = subHours(
        getPickupOptionDate(pickupOption, 'from'),
        pickupOption.maximumLeadTimeHours
      );
    } else {
      lowerBound = options.lowerBound ?? startOfDay(currentDate);
    }

    let upperBound: Date;
    if (pickupOption.minimumLeadTimeHours) {
      upperBound = subHours(
        getPickupOptionDate(pickupOption, 'from'),
        pickupOption.minimumLeadTimeHours
      );
    } else {
      upperBound = options.upperBound ?? addYears(lowerBound, TEN_YEARS);
    }

    return currentDate > lowerBound && currentDate < upperBound;
  }

  /**
   * Converts a pickup to a list of deliveries to use DeliveryUtils functions
   * This is a temporary solution until we have a better Delivery and Pickup model
   * @param {Pickup} pickup
   * @returns {PickupPartAsDelivery[]}
   */
  export function convertPickupToDeliveries(
    pickup: Pickup
  ): PickupPartAsDelivery[] {
    const deliveries: PickupPartAsDelivery[] = [];

    const leadTimeHours: LeadTimeHours = {
      minimumLeadTimeHours: pickup.minimumLeadTimeHours ?? 0,
      maximumLeadTimeHours:
        pickup.maximumLeadTimeHours ?? DeliveryUtils.MAX_HOURS,
    };

    const otherProperties = {
      deliveryZoneCodes: [],
      minimumOrder: pickup.minimumOrder,
      deliveryFee: Money.fromDecimal(0, pickup.minimumOrder.currency),
      freeDeliveryAbove: Money.fromDecimal(0, pickup.minimumOrder.currency),
    };

    // Fixed dates
    pickup.specificSchedules
      ?.filter((specificSchedule) => specificSchedule.timeSlots.length > 0)
      .forEach((specificSchedule) =>
        specificSchedule.timeSlots.forEach((timeSlot) =>
          deliveries.push({
            delivery: {
              ...leadTimeHours,
              ...otherProperties,
              deliveryDates: [LocalDate.toDate(specificSchedule.date)],
              expeditionTime: timeSlot.from,
              deliverySchedule: null,
            },
            selectedTime: timeSlot,
          })
        )
      );

    // Recurring dates
    const excludedDates =
      pickup.specificSchedules
        ?.filter((specificSchedule) => specificSchedule.timeSlots.length === 0)
        .map((specificSchedule) => LocalDate.toDate(specificSchedule.date)) ??
      [];
    pickup.recurringSchedules
      ?.filter((recurringSchedule) => recurringSchedule.timeSlots.length > 0)
      .forEach((recurringSchedule) =>
        recurringSchedule.timeSlots.forEach((timeSlot) =>
          deliveries.push({
            delivery: {
              ...leadTimeHours,
              ...otherProperties,
              deliveryDates: [],
              expeditionTime: timeSlot.from,
              deliverySchedule: {
                deliveryDays: [recurringSchedule.day],
                from: null, // For now, we don't use the from and to properties
                to: null, // For now, we don't use the from and to properties
                excludedDates,
              },
            },
            selectedTime: timeSlot,
          })
        )
      );

    return deliveries;
  }

  /**
   * Returns true if the pickups have an open date at currentDate
   * @param pickups
   * @param options
   * @returns
   */
  export function hasOpenPickupDate(
    pickups: Pickup[],
    options: Bounds & CurrentDate = {}
  ): boolean {
    return (
      PickupUtils.getPickupAvailability(pickups, options) ===
      DatesAvailability.AVAILABLE
    );
  }

  /**
   * Returns true if the pickups have not yet open dates at currentDate
   * @param pickups
   * @param options
   * @returns
   */
  export function hasUpcomingPickupDates(
    pickups: Pickup[],
    options: Bounds & CurrentDate = {}
  ): boolean {
    const deliveries = pickups.flatMap((pickup) =>
      PickupUtils.convertPickupToDeliveries(pickup).map(
        (pickupPart) => pickupPart.delivery
      )
    );
    return !!DeliveryUtils.hasUpcomingDeliveryDates(deliveries, options);
  }

  /**
   * Returns true if the pickups have pickup dates at currentDate, open or not
   * @param pickups
   * @param options
   * @returns
   */
  export function hasNextPickupDates(
    pickups: Pickup[],
    options: Bounds & CurrentDate = {}
  ): boolean {
    const deliveries = pickups.flatMap((pickup) =>
      PickupUtils.convertPickupToDeliveries(pickup).map(
        (pickupPart) => pickupPart.delivery
      )
    );
    return !!DeliveryUtils.hasNextDeliveryDates(deliveries, options);
  }

  /**
   * Returns true if the date is an open pickup date for the pickup
   * @param pickup - the pickup to look through
   * @param date - the date we want to verify is a pickup date
   * @param options
   * @returns {boolean}
   */
  export function isOpenPickupDate(
    pickup: Pickup,
    date: Date,
    options: Bounds & CurrentDate = {}
  ): boolean {
    const deliveries = PickupUtils.convertPickupToDeliveries(pickup).map(
      (pickupPart) => pickupPart.delivery
    );
    return (
      !!date &&
      _(deliveries)
        .filter((delivery) => !!delivery)
        .map((delivery) =>
          DeliveryUtils.getAllNextOpenDeliveryDates(delivery, options)
        )
        .flatMap()
        .uniqBy((deliveryDate) => deliveryDate.toISOString())
        .some((deliveryDate) =>
          _.isEqual(deliveryDate.toISOString(), date.toISOString())
        )
    );
  }
}
