import { AppUtilsModule } from 'src/app/app-utils.module';
import { MaterialModule } from 'src/app/material.module';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';

import { TranslocoModule } from '@jsverse/transloco';
import { TranslocoLocaleModule } from '@jsverse/transloco-locale';

import { LocalDatePipeModule } from '@arrivage-pipes/localdate-pipe/localdate-pipe.module';

import { DateRangePickerComponent } from './date-range-picker.component';

@NgModule({
  declarations: [DateRangePickerComponent],
  imports: [
    CommonModule,
    MaterialModule,
    AppUtilsModule,
    ReactiveFormsModule,
    TranslocoModule,
    FlexLayoutModule,
    TranslocoLocaleModule,
    LocalDatePipeModule,
  ],
  exports: [DateRangePickerComponent],
})
export class DateRangePickerModule {}
