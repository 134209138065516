import { Observable } from 'rxjs';

import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';

import { map } from 'rxjs/operators';

import {
  UnsavedChangesDialogComponent,
  UnsavedChangesDialogData,
} from '@arrivage-components/unsaved-changes-dialog/unsaved-changes-dialog.component';
import { FormDirtyPageComponent } from '@arrivage-forms/form-is-dirty/form-dirty.model';
import { DirtyCheckService } from '@arrivage-services/dirty-check.service';

@Injectable({ providedIn: 'root' })
export class DirtyCheckGuard  {
  constructor(
    public dialog: MatDialog,
    private dirtyCheckService: DirtyCheckService
  ) {}

  canDeactivate(
    component: FormDirtyPageComponent,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot
  ):
    | boolean
    | UrlTree
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree> {
    if (!this.dirtyCheckService.isDirty) {
      this.dirtyCheckService.resetDirtiness();
      return Promise.resolve(true);
    }

    const dialogRef = this.dialog.open<
      UnsavedChangesDialogComponent,
      UnsavedChangesDialogData,
      boolean
    >(UnsavedChangesDialogComponent);

    return dialogRef.afterClosed().pipe(
      map((navigate) => {
        if (navigate) {
          this.dirtyCheckService.resetDirtiness();
        }
        return navigate;
      })
    );
  }
}
