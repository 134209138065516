import { Observable } from 'rxjs';

import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';

import { update } from '@arrivage-organization-setup/store/organization-setup.actions';
import { selectOrganizationSetup } from '@arrivage-organization-setup/store/organization-setup.selectors';
import { State } from '@arrivage-store/state';
import { OrganizationAccountSetup } from '@arrivage/model/dist/src/model';

@Injectable({
  providedIn: 'root',
})
export class OrganizationSetupFacade {
  private organizationSetup$: Observable<OrganizationAccountSetup>;

  constructor(private store: Store<State>) {
    this.organizationSetup$ = this.store.select(selectOrganizationSetup);
  }

  getOrganizationSetup(): Observable<OrganizationAccountSetup> {
    return this.organizationSetup$;
  }

  updateOrganizationSetup(
    organizationSetup: Partial<OrganizationAccountSetup>
  ) {
    return new Promise((resolve, reject) => {
      this.store.dispatch(
        update({
          organizationSetup: organizationSetup,
          confirmation: {
            resolve: resolve,
            reject: reject,
          },
        })
      );
    });
  }
}
