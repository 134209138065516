export namespace DownloadFileUtils {
  export async function downloadFile(url: string, fileName: string) {
    const arrayBuffer = await fetch(url).then((res) => res.arrayBuffer());
    const pdfBytes = new Uint8Array(arrayBuffer);
    const file = new Blob([pdfBytes], { type: 'application/pdf' });
    const fileURL = window.URL.createObjectURL(file);
    const a = document.createElement('a');
    a.href = fileURL;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(fileURL);
  }
}
