import { createAction, props } from '@ngrx/store';

import { generateActions } from '@arrivage-store/generators';
import { WithId } from '@arrivage/model/dist/src/model';
import { Delivery } from '@arrivage/model/dist/src/model';

const featureName = 'Deliveries';

export const {
  query,
  connected,
  add,
  added,
  set,
  modified,
  update,
  remove,
  removed,
  queryFailure,
  addSuccess,
  addFailure,
  setSuccess,
  setFailure,
  updateSuccess,
  updateFailure,
  removeSuccess,
  removeFailure,
  getActiveItem,
  getActiveItemFailure,
  getActiveItemSuccess,
} = generateActions<Delivery>(featureName);

export const loadRelationshipDeliveries = createAction(
  `[${featureName}] Load relationships deliveries`,
  props<{
    relationshipId: string;
  }>()
);

export const loadRelationshipDeliveriesSuccess = createAction(
  `[${featureName}] Load relationships deliveries Success`,
  props<{
    deliveries: (Delivery & WithId)[];
  }>()
);

export const loadRelationshipDeliveriesFailure = createAction(
  `[${featureName}]  Load relationships deliveries Failure`,
  props<{
    error: any;
  }>()
);
