import { Observable } from 'rxjs';

import { Injectable } from '@angular/core';
import { Firestore } from '@angular/fire/firestore';

import { BaseService, PathSpec } from '@arrivage-services/base.service';
import {
  EntityChangeAction,
  OrganizationEntityService,
} from '@arrivage-services/organization-entity.service';
import { Format, WithId } from '@arrivage/model/dist/src/model';
import { CollectionNames } from '@arrivage/model/dist/src/utils';

@Injectable({
  providedIn: 'root',
})
export class FormatService
  extends BaseService<Format>
  implements OrganizationEntityService<Format>
{
  static readonly ORGANIZATIONS_COLLECTION =
    CollectionNames.ORGANIZATION_COLLECTION;
  static readonly FORMATS_COLLECTION = CollectionNames.FORMAT_COLLECTION;

  constructor(firestore: Firestore) {
    super(firestore);
  }

  get(organizationId: string, recordId: string): Observable<Format & WithId> {
    return this._get(this.pathSpec(organizationId), recordId);
  }

  create(organizationId: string, format: Format): Promise<string> {
    return this._create(this.pathSpec(organizationId), format);
  }

  set(organizationId: string, id: string, format: Format): Promise<void> {
    return this._set(this.pathSpec(organizationId), id, format);
  }

  update(
    organizationId: string,
    formatId: string,
    format: Partial<Format>
  ): Promise<void> {
    return this._update(this.pathSpec(organizationId), formatId, format);
  }

  connect(organizationId: string): Observable<EntityChangeAction<Format>[]> {
    return this._connect(this.pathSpec(organizationId), (d) => d, null);
  }

  list(organizationId: string): Observable<(Format & WithId)[]> {
    return this._list(this.pathSpec(organizationId));
  }

  remove(organizationId: string, formatId: string): Promise<void> {
    return this._delete(this.pathSpec(organizationId), formatId);
  }

  private pathSpec(organizationId: string): PathSpec[] {
    return [
      {
        collection: FormatService.ORGANIZATIONS_COLLECTION,
        id: organizationId,
      },
      { collection: FormatService.FORMATS_COLLECTION },
    ];
  }
}
