import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { FormatsEffects } from './formats.effects';
import { reducer } from './formats.reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('formats', reducer),
    EffectsModule.forFeature([FormatsEffects]),
  ],
})
export class FormatsStoreModule {}
