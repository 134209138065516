import { differenceInMinutes } from 'date-fns';

import { Component, ChangeDetectionStrategy, Inject } from '@angular/core';
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormGroup,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { Dictionary } from '@ngrx/entity';

import { RelationshipInfo, WithId } from '@arrivage/model/dist/src/model';

export interface MultiShareToCustomersDialogData {
  shareToCustomers: Dictionary<(RelationshipInfo & WithId)[]>;
  relationships: (RelationshipInfo & WithId)[];
}

export interface MultiShareToCustomersDialogResponse {
  message: string;
}

@Component({
  selector: 'app-multi-share-to-customers-dialog',
  templateUrl: './multi-share-to-customers-dialog.component.html',
  styleUrls: ['./multi-share-to-customers-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MultiShareToCustomersDialogComponent {
  form: UntypedFormGroup = this.fb.group({
    message: [null],
  });

  constructor(
    private fb: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: MultiShareToCustomersDialogData,
    public dialogRef: MatDialogRef<
      MultiShareToCustomersDialogComponent,
      MultiShareToCustomersDialogResponse
    >
  ) {}

  get messageControl(): AbstractControl {
    return this.form.get('message');
  }

  share() {
    this.dialogRef.close({
      message: this.messageControl.value ? this.messageControl.value : '',
    });
  }

  elapsedTimeInMinutes(lastShareOfferTimestamp: number) {
    return lastShareOfferTimestamp
      ? differenceInMinutes(new Date().getTime(), lastShareOfferTimestamp)
      : Number.MAX_SAFE_INTEGER;
  }

  elapsedTimeLessThanHour(lastShareOfferTimestamp: number) {
    return this.elapsedTimeInMinutes(lastShareOfferTimestamp) < 60;
  }

  isMoreThanHour() {
    return !this.data.relationships.find((row) =>
      this.elapsedTimeLessThanHour(row.lastShareOfferTimestamp)
    );
  }
}
