import { createAction, props } from '@ngrx/store';

import { Organization, WithId } from '@arrivage/model/dist/src/model';
import { PublicOffer } from '@arrivage/model/dist/src/search';

export const query = createAction(`[Search] Query`);
export const load = createAction(
  `[Search] Load`,
  props<{ organization: Organization & WithId }>()
);
export const connected = createAction(`[Search] Connected`);
export const added = createAction(
  `[Search] Added`,
  props<{ records: (PublicOffer & WithId)[] }>()
);
export const modified = createAction(
  `[Search] Modified`,
  props<{ records: (PublicOffer & WithId)[] }>()
);
export const removed = createAction(
  `[Search] Removed`,
  props<{ records: (PublicOffer & WithId)[] }>()
);

export const queryFailure = createAction(
  `[Search] Query failure`,
  props<{ errorMessage: string }>()
);
