import { Pipe, PipeTransform } from '@angular/core';

import { PurchaseOrder } from '@arrivage/model/dist/src/model';
import { BillingUtils } from '@arrivage/model/dist/src/utils';

@Pipe({
  name: 'purchaseOrderTotal',
})
export class PurchaseOrderTotalPipe implements PipeTransform {
  transform(value: PurchaseOrder, args?: any) {
    return BillingUtils.computeTaxAndTotal(
      value.orderItems.map((item) => {
        return {
          price: item.price,
          quantity: item.requestedQuantity,
          applicableTaxes: item.format.applicableTaxes,
        };
      }),
      value.taxSettings,
      value.deliveryDate && value.delivery && !value.pickup
        ? {
            ...value.delivery,
            applicableTaxes: value.deliveryFeeApplicableTaxes || [],
          }
        : null,
      value.volumeDiscounts,
      value.discounts,
      value.clientDiscount
    ).total;
  }
}
