export namespace InvoicesIdFields {
  export enum ID_FIELDS_ORGANIZATIONS {
    VENDOR = 'vendor.organizationId',
    CUSTOMER = 'customer.organizationId',
  }
  export enum ID_FIELDS_RELATIONS {
    VENDOR = 'vendor.relationshipId',
    CUSTOMER = 'customer.relationshipId',
  }
}
