import { Pipe, PipeTransform } from '@angular/core';

import { LanguageService } from '@arrivage-language/service/language.service';
import { Format, SellingUnitDescription } from '@arrivage/model/dist/src/model';

@Pipe({
  name: 'displayAsPriceUnit',
  pure: false,
})
export class DisplayAsPriceUnitPipe implements PipeTransform {
  constructor(private languageService: LanguageService) {}

  transform(sellingUnit: SellingUnitDescription, args?: any): string {
    if (sellingUnit) {
      return Format.displayAsPriceUnit(
        sellingUnit,
        this.languageService.getCurrentLanguage()
      );
    }
  }
}
