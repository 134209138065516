<form
  *transloco="let t; read: 'organization.settings.tax-form'"
  [formGroup]="form"
  autocomplete="off"
>
  <mat-form-field>
    <mat-label>{{ t('name') }}</mat-label>
    <input
      data-testid="tax-name-id"
      required
      matInput
      formControlName="taxName"
    />
  </mat-form-field>
  <mat-form-field>
    <mat-label>{{ t('rate') }}</mat-label>
    <input
      data-testid="tax-rate-id"
      type="number"
      min="0"
      matInput
      required
      formControlName="taxRate"
    />
    <p matTextSuffix>%</p>
    <mat-error>{{ t('rate-error') }}</mat-error>
  </mat-form-field>
  <mat-form-field>
    <mat-label>{{ t('number') }}</mat-label>
    <input data-testid="tax-number-id" matInput formControlName="taxNumber" />
  </mat-form-field>
</form>
