import { Subject } from 'rxjs';

import { Injectable } from '@angular/core';

import { takeUntil } from 'rxjs/operators';

import { Store } from '@ngrx/store';

import { AuthService } from '@arrivage-auth/services/auth.service';
import {
  LoadContext,
  LoadContextData,
} from '@arrivage-store/context/context.actions';
import { ContextFeedback } from '@arrivage-store/context/context.effect';
import * as fromContext from '@arrivage-store/context/context.selectors';
import { showFeedback } from '@arrivage-store/feedback/feedback.actions';
import { State } from '@arrivage-store/state';

@Injectable({
  providedIn: 'root',
})
/**
 * TODO Remove this class, should load only from the UID given by the authservice.
 */
export class LocalStorageService {
  private readonly ACTIVE_MEMBERSHIP_KEY = 'activeMembership';
  private readonly ORGANIZATION_OVERRIDE_KEY = 'ooverride';
  private readonly PURCHASE_REPORT_KEY = 'purchaseReport';
  private readonly SIDENAV_IS_EXPANDED_KEY = 'isExpanded';
  private readonly INVOICES_STATUS_FILTER = 'invoicesStatusFilter';

  private stop$ = new Subject<void>();

  constructor(private store: Store<State>, private authService: AuthService) {}

  loadContextFromLocalStorage(userId: string) {
    try {
      const contextData: LoadContextData = { userId: userId };

      const activeMembership: string = JSON.parse(
        localStorage.getItem(this.ACTIVE_MEMBERSHIP_KEY)
      );
      if (activeMembership) {
        contextData.activeMembershipId = activeMembership;
      }

      const organizationOverride: string = JSON.parse(
        localStorage.getItem(this.ORGANIZATION_OVERRIDE_KEY)
      );
      if (organizationOverride) {
        contextData.organizationOverride = organizationOverride;
      }
      this.store.dispatch(
        LoadContext({
          loadContextData: contextData,
        })
      );
    } catch (e) {
      this.store.dispatch(
        showFeedback({
          success: false,
          feedback: ContextFeedback.loading_failed,
        })
      );
      this.clearContextDataFromLocalStorage();
      this.authService.logout();
    }
  }

  saveOrganizationOverride(organizationId: string) {
    localStorage.setItem(
      this.ORGANIZATION_OVERRIDE_KEY,
      JSON.stringify(organizationId)
    );
  }

  set invoicesStatusFilter(status: string[]) {
    status
      ? localStorage.setItem(
          this.INVOICES_STATUS_FILTER,
          JSON.stringify(status)
        )
      : localStorage.removeItem(this.INVOICES_STATUS_FILTER);
  }

  get invoicesStatusFilter() {
    return JSON.parse(localStorage.getItem(this.INVOICES_STATUS_FILTER));
  }

  set purchaseReportConfig(config: string[]) {
    localStorage.setItem(this.PURCHASE_REPORT_KEY, config.join('\\'));
  }

  get purchaseReportConfig() {
    return localStorage.getItem(this.PURCHASE_REPORT_KEY)?.split('\\');
  }

  set sidenavState(isExpanded: boolean) {
    localStorage.setItem(
      this.SIDENAV_IS_EXPANDED_KEY,
      JSON.stringify(isExpanded)
    );
  }

  get sidenavState() {
    return JSON.parse(localStorage.getItem(this.SIDENAV_IS_EXPANDED_KEY));
  }

  startSync() {
    this.store
      .select(fromContext.getMembership)
      .pipe(takeUntil(this.stop$))
      .subscribe((membership) => {
        if (membership) {
          localStorage.setItem(
            this.ACTIVE_MEMBERSHIP_KEY,
            JSON.stringify(membership.id)
          );
        }
      });
  }

  stopSync() {
    this.stop$.next();
  }

  clearContextDataFromLocalStorage() {
    localStorage.removeItem(this.ACTIVE_MEMBERSHIP_KEY);
    localStorage.removeItem(this.ORGANIZATION_OVERRIDE_KEY);
  }
}
