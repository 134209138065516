import * as Sentry from '@sentry/angular-ivy';

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import packageJSON from '../package.json';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  Sentry.init({
    environment: environment.name ? environment.name : 'unknown',
    dsn: 'https://a3710b13dbe34a9387b0788d6d71f11e@o456375.ingest.sentry.io/5449377',
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    ignoreErrors: [
      'ResizeObserver loop limit exceeded', // see https://forum.sentry.io/t/resizeobserver-loop-limit-exceeded/8402/5
      'ResizeObserver loop completed with undelivered notifications', // firefox version of the error above
      'Non-Error promise rejection captured', // see https://forum.sentry.io/t/unhandledrejection-non-error-promise-rejection-captured-with-value/14062/17
    ],
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 0.5,
    release: packageJSON.version,
  });
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
