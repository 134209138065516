import { createFeatureSelector, createSelector } from '@ngrx/store';

import { Organization, WithId } from '@arrivage/model/dist/src/model';

import { ContextState } from './context.state';

export const getContextState = createFeatureSelector<ContextState>('context');

export const getUser = createSelector(
  getContextState,
  (state: ContextState) => state.user
);

export const getUserId = createSelector(
  getContextState,
  (state: ContextState) => {
    return state.user ? state.user.id : undefined;
  }
);

export const getUserConnectionState = createSelector(
  getContextState,
  (state: ContextState) => {
    return {
      user: state.user,
      userLoaded: state.userLoaded,
      userLoadFailure: state.userLoadFailure,
    };
  }
);

export const getAllUserMemberships = createSelector(
  getContextState,
  (state: ContextState) => state.allUserMemberships
);

export const getAllUserMembershipsConnectionState = createSelector(
  getContextState,
  (state: ContextState) => {
    return {
      allUserMemberships: state.allUserMemberships,
      allUserMembershipsLoaded: state.allUserMembershipsLoaded,
      allUserMembershipsLoadFailure: state.allUserMembershipsLoadFailure,
    };
  }
);

export const getMembership = createSelector(
  getContextState,
  (state: ContextState) => state.membership
);

export const getMembershipConnectionState = createSelector(
  getContextState,
  (state: ContextState) => {
    return {
      membership: state.membership,
      membershipLoaded: state.membershipLoaded,
      membershipLoadFailure: state.membershipLoadFailure,
    };
  }
);

export const getOrganization = createSelector(
  getContextState,
  (state: ContextState) => {
    return state.organization;
  }
);

export const getOrganizationZones = createSelector(
  getContextState,
  (state: ContextState) => {
    return state.organizationZones;
  }
);

export const getOrganizationId = createSelector(
  getContextState,
  (state: ContextState) => {
    return state.organization ? state.organization.id : undefined;
  }
);

export const getOrganizationConnectionState = createSelector(
  getContextState,
  (state: ContextState) => {
    return {
      organization: state.organization,
      organizationLoaded: state.organizationLoaded,
      organizationLoadFailure: state.organizationLoadFailure,
    };
  }
);

export const getOrganizationSummary = createSelector(
  getOrganization,
  (organization: Organization & WithId) => {
    return Organization.extractSummary(organization);
  }
);
