import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { ProducersEffects } from './producers.effects';
import { reducer } from './producers.reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('producers', reducer),
    EffectsModule.forFeature([ProducersEffects]),
  ],
})
export class ProducersStoreModule {}
