import { Injectable } from '@angular/core';

import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { NotificationService } from '@arrivage-notifications/services/notifications.service';
import { SnackbarService } from '@arrivage-snackbar/snackbar.service';
import { EntityFeedback } from '@arrivage-store/feedback/feedback-params.model';
import { createBaseEffects } from '@arrivage-store/generators';

import * as notificationsActions from './notifications.actions';
import * as selectors from './notifications.selectors';
import { State } from './notifications.state';

export const NotificationFeedback: EntityFeedback = {
  //error only
  query: 'query_notifications',
  update: '',
  add: '',
  remove: '',
  get_active_item: '',
};
@Injectable()
export class NotificationsEffects {
  query$;
  add$;
  update$;
  displayQueryFailure$;

  constructor(
    private actions$: Actions,
    private store: Store<State>,
    private service: NotificationService,
    private snackbarService: SnackbarService
  ) {
    ({
      query: this.query$,
      add: this.add$,
      update: this.update$,
      displayQueryFailure: this.displayQueryFailure$,
    } = createBaseEffects(
      this.actions$,
      this.store,
      notificationsActions,
      selectors,
      this.service,
      NotificationFeedback,
      this.snackbarService
    ));
  }
}
