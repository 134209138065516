import { Observable } from 'rxjs';
import { PurchaseReportLine } from 'src/app/purchase-report/common/model/purchase-report.model';

import { Injectable } from '@angular/core';

import { take, tap } from 'rxjs/operators';

import { Dictionary } from '@ngrx/entity';
import { Store } from '@ngrx/store';

import { AnalyticsFacade } from '@arrivage-analytics/api/analytics.facade';
import { State } from '@arrivage-store/state';
import {
  Message,
  Offer,
  PurchaseOrder,
  PurchaseOrderStatus,
  WithId,
} from '@arrivage/model/dist/src/model';

import { ContextFacade } from '@arrivage-store/api/context.facade';
import { PurchaseOrderApiService } from '../../common/api/purchase-orders.api.service';
import { PurchaseOrdersFacade } from '../../common/api/purchase-orders.facade';
import { PurchaseOrderDashboardMetrics } from '../../common/model/purchase-orders.model';
import { CustomerPurchaseOrderService } from '../services/customer-purchase-order.service';
import { CustomerPurchaseOrdersActions } from '../store/customer-purchase-orders.actions';
import {
  CustomerPurchaseOrdersSelectors,
  getPurchaseOrderDashboardMetrics,
  selectBuyingReportFromPurchaseOrderByDeliveryOrPickupDateRange,
  selectBuyingReportFromPurchaseOrderBySentDateRange,
  selectOfferFromPurchaseOrder,
  selectPurchaseOrderByRelationship,
} from '../store/customer-purchase-orders.selectors';

@Injectable({
  providedIn: 'root',
})
export class CustomerPurchaseOrderFacade extends PurchaseOrdersFacade<CustomerPurchaseOrderService> {
  private purchaseOrderDashboardMetrics: Observable<PurchaseOrderDashboardMetrics>;

  constructor(
    store: Store<State>,
    purchaseOrderApiService: PurchaseOrderApiService,
    analytics: AnalyticsFacade,
    purchaseOrderService: CustomerPurchaseOrderService,
    contextFacade: ContextFacade
  ) {
    super(
      store,
      CustomerPurchaseOrdersSelectors,
      CustomerPurchaseOrdersActions,
      purchaseOrderApiService,
      analytics,
      purchaseOrderService,
      contextFacade
    );

    this.purchaseOrderDashboardMetrics = this.store.select(
      getPurchaseOrderDashboardMetrics
    );
  }

  async createPurchaseOrderWithToken(
    purchaseOrder: PurchaseOrder,
    message?: Message,
    sendEmailToMyself?: boolean,
    pdfBase64?: string
  ): Promise<string> {
    const createPurchaseOrderExternal$ =
      await this.purchaseOrderApiService.createPurchaseOrderExternal(
        purchaseOrder,
        message,
        sendEmailToMyself,
        pdfBase64
      );

    return createPurchaseOrderExternal$
      .pipe(
        take(1),
        tap(() => this.analytics.logCreatePurchaseOrder(purchaseOrder))
      )
      .toPromise();
  }

  async createPurchaseOrderFromPublicOffer(
    purchaseOrder: PurchaseOrder,
    message?: Message,
    sendEmailToMyself?: boolean,
    pdfBase64?: string
  ): Promise<string> {
    const createPurchaseOrderFromPublicOffer$ =
      await this.purchaseOrderApiService.createPurchaseOrderFromPublicOffer(
        purchaseOrder,
        message,
        sendEmailToMyself,
        pdfBase64
      );

    return createPurchaseOrderFromPublicOffer$
      .pipe(
        take(1),
        tap(() => this.analytics.logCreatePurchaseOrder(purchaseOrder))
      )
      .toPromise();
  }

  changePurchaseOrderStatusExternal(
    purchaseOrderId: string,
    newStatus: PurchaseOrderStatus
  ): Promise<void> {
    return this.purchaseOrderApiService
      .changePurchaseOrderStatusExternal(purchaseOrderId, newStatus)
      .pipe(take(1))
      .toPromise();
  }

  getOfferFromPurchaseOrder(): Observable<Offer & WithId> {
    return this.store.select(selectOfferFromPurchaseOrder);
  }

  getPurchaseOrderDashboardMetrics(): Observable<PurchaseOrderDashboardMetrics> {
    return this.purchaseOrderDashboardMetrics;
  }

  getBuyingReportFromPurchaseOrderBySentDateRange(): Observable<
    PurchaseReportLine[]
  > {
    return this.store.select(
      selectBuyingReportFromPurchaseOrderBySentDateRange
    );
  }

  getBuyingReportFromPurchaseOrderByDeliveryOrPickupDateRange(): Observable<
    PurchaseReportLine[]
  > {
    return this.store.select(
      selectBuyingReportFromPurchaseOrderByDeliveryOrPickupDateRange
    );
  }

  getPurchaseOrdersByRelationshipIds(): Observable<
    Dictionary<(PurchaseOrder & WithId)[]>
  > {
    return this.store.select(selectPurchaseOrderByRelationship);
  }
}
