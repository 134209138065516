import { Observable } from 'rxjs';

import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

import { Store } from '@ngrx/store';

import { AuthFacade } from '@arrivage-auth/api/auth.facade';
import { AuthUser } from '@arrivage-auth/model/auth-data.model';
import { FormDirtyPageComponent } from '@arrivage-forms/form-is-dirty/form-dirty.model';
import { DirtyCheckService } from '@arrivage-services/dirty-check.service';
import { SnackbarService } from '@arrivage-snackbar/snackbar.service';
import { ContextFacade } from '@arrivage-store/api/context.facade';
import { ContextFeedback } from '@arrivage-store/context/context.effect';
import { getOrganization } from '@arrivage-store/context/context.selectors';
import { State } from '@arrivage-store/state';
import {
  Organization,
  OrganizationSettings,
  WithId,
} from '@arrivage/model/dist/src/model';

import { OrganizationSettingsFacade } from '../api/organization-settings.facade';

@Component({
  selector: 'app-organization-settings-page',
  templateUrl: './organization-settings-page.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrganizationSettingsPageComponent
  extends FormDirtyPageComponent
  implements OnInit
{
  organization$: Observable<Organization & WithId>;
  organizationSettings$: Observable<OrganizationSettings & WithId>;
  authUser$: Observable<AuthUser> = this.authFacade.getAuthUser();

  constructor(
    private store: Store<State>,
    private settingsFacade: OrganizationSettingsFacade,
    private contextFacade: ContextFacade,
    private snackbarService: SnackbarService,
    dirtyCheckService: DirtyCheckService,
    private readonly authFacade: AuthFacade
  ) {
    super(dirtyCheckService);
  }

  ngOnInit() {
    this.settingsFacade.loadOrganizationSettings();
    this.organization$ = this.store.select(getOrganization);
    this.organizationSettings$ = this.settingsFacade.getOrganizationSettings();
  }

  onUpdateOrganization(value: {
    organization: Partial<Organization> & WithId;
    organizationSettings: Partial<OrganizationSettings> & WithId;
  }) {
    const action = ContextFeedback.update_organization;
    Promise.all([
      this.settingsFacade.updateOrganizationSettings(
        value.organizationSettings
      ),
      this.contextFacade.onUpdateOrganization(value),
    ])
      .then((x) => this.snackbarService.showSuccess(action))
      .catch((e) => this.snackbarService.showError(action));
  }
}
