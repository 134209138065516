import { PlatformModule } from '@angular/cdk/platform';
import { NgModule } from '@angular/core';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';

import { DateFnsDateAdapter, MAT_DATE_FNS_DATE_FORMATS } from './date-adapter';

export * from './date-adapter';

@NgModule({
  imports: [PlatformModule],
  providers: [{ provide: DateAdapter, useClass: DateFnsDateAdapter }],
})
export class DateFnsModule {}

@NgModule({
  imports: [DateFnsModule],
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: MAT_DATE_FNS_DATE_FORMATS },
  ],
})
export class MatDateFnsModule {}
