import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { OrganizationEffects } from './organization.effects';
import { reducer } from './organization.reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('organization', reducer),
    EffectsModule.forFeature([OrganizationEffects]),
  ],
})
export class OrganizationStoreModule {}
