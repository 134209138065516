import { Injectable } from '@angular/core';

import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { SnackbarService } from '@arrivage-snackbar/snackbar.service';
import { createBaseEffects } from '@arrivage-store/generators';

import { DeliveryFeedback } from '../../../distribution/model/delivery.model';
import { VendorDeliveryService } from '../services/vendor-delivery.service';
import * as deliveryActions from './vendor-delivery.actions';
import * as selectors from './vendor-delivery.selectors';
import { State, VendorDeliveryState } from './vendor-delivery.state';

@Injectable()
export class VendorDeliveryEffects {
  query$;
  set$;
  add$;
  update$;
  remove$;
  getActiveItem$;
  displayQueryFailure$;
  displayGetActiveItemFailure$;

  constructor(
    private actions$: Actions,
    private store: Store<State & VendorDeliveryState>,
    private deliveryService: VendorDeliveryService,
    private snackbarService: SnackbarService
  ) {
    ({
      query: this.query$,
      set: this.set$,
      add: this.add$,
      update: this.update$,
      remove: this.remove$,
      getActiveItem: this.getActiveItem$,
      displayQueryFailure: this.displayQueryFailure$,
      displayGetActiveItemFailure: this.displayGetActiveItemFailure$,
    } = createBaseEffects(
      this.actions$,
      this.store,
      deliveryActions,
      selectors,
      this.deliveryService,
      DeliveryFeedback,
      this.snackbarService
    ));
  }
}
