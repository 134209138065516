import _ from 'lodash';

import { UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';

import { translate } from '@jsverse/transloco';

import { LoadingFeedback } from '@arrivage-components/loading-dialog/loading-feedback.model';
import { PriceListFacade } from '@arrivage-price-lists/api/price-list.facade';
import { AddPriceListDialogComponent } from '@arrivage-price-lists/components/add-price-list/add-price-list-dialog.component';
import {
  PriceListCSVExportData,
  PriceListItemLine,
  PriceListItemLineWithPriceList,
} from '@arrivage-price-lists/model/price-list.model';
import { PriceListFeedback } from '@arrivage-price-lists/store/price-list.effects';
import { reportError } from '@arrivage-sentry/report-error';
import { MainPageLoadService } from '@arrivage-services/main-page-load.service';
import { SnackbarService } from '@arrivage-snackbar/snackbar.service';
import { GroupData } from '@arrivage-util/data-group-by.utils';
import {
  InventoryItem,
  Money,
  PriceList,
  PriceListItem,
  WithId,
} from '@arrivage/model/dist/src/model';

/**
 * Function that recover the priceListItem and the form values and associated them
 * and return all the priceListItems with the values of the form
 */
export namespace PriceListUtils {
  export function getPriceListItemWithFormValues(
    priceListItemLinesGroupedByCategory: GroupData<
      Omit<PriceListItemLine, 'priceListItem'> | PriceListItemLine
    >[],
    priceListItemLinesControls: UntypedFormGroup,
    currency: string
  ): Omit<PriceListItem, 'priceListId'>[] | (PriceListItem & WithId)[] {
    return _.flatMap(
      priceListItemLinesGroupedByCategory.map((priceListCategory) => {
        const categoryFormGroup = priceListItemLinesControls.get(
          priceListCategory.groupName
        ) as UntypedFormGroup;

        return _.map(priceListCategory.data, (priceListLine) => {
          const priceListLineFormGroup = categoryFormGroup.get(
            priceListLine.inventoryItem.id
          );

          const priceListPrice = Money.fromDecimal(
            priceListLineFormGroup.value.priceListPrice,
            currency
          );

          /**
           * If the price in the form is the same as the reducedPrice than we presumed that it is a Discount
           */
          const isPromo =
            (priceListLine.inventoryItem.reducedPrice &&
              priceListLine.inventoryItem.reducedPrice.value.amount ===
                priceListPrice.amount) ??
            false;

          let priceListItem:
            | Omit<PriceListItem, 'priceListId' | 'id'>
            | (PriceListItem & WithId);

          if ('priceListItem' in priceListLine) {
            /**
             * If the priceListItem is present in the GroupData than we are in mode update
             * and we can return the full PriceListItem otherwise we are in create mode and
             * we return a PriceList item without its id and the priceListId
             */
            priceListItem = {
              isActive: priceListLineFormGroup.value.isActive,
              inventoryItemId: priceListLine.inventoryItem.id,
              isPromo: isPromo,
              price: isPromo
                ? priceListLine.priceListItem.price
                : priceListPrice,
              id: priceListLine.priceListItem.id,
              priceListId: priceListLine.priceListItem.priceListId,
            };
          } else {
            priceListItem = {
              isActive: priceListLineFormGroup.value.isActive,
              inventoryItemId: priceListLine.inventoryItem.id,
              isPromo: isPromo,
              price: isPromo
                ? priceListLine.inventoryItem.basePrice
                : priceListPrice,
            };
          }
          return priceListItem;
        });
      })
    );
  }

  export function isPriceListHasPublicVisibility(
    priceList: PriceList
  ): boolean {
    if (priceList && priceList.publicVisibility) {
      return priceList.publicVisibility.length > 0;
    }
    return false;
  }

  export function onDuplicatePriceList(
    priceList: PriceList & WithId,
    organizationId: string,
    facade: PriceListFacade,
    mainPageLoadService: MainPageLoadService,
    snackbarService: SnackbarService
  ) {
    const newPriceList: PriceList = {
      ...priceList,
      name: `${priceList.name} (${translate('priceLists.copy')})`,
    };
    delete newPriceList['id'];

    const action = PriceListFeedback.duplicate;
    mainPageLoadService.start(LoadingFeedback.ON_DUPLICATE_PRICE_LIST);
    facade
      .duplicatePriceList(organizationId, newPriceList, priceList.id)
      .then(() => {
        snackbarService.showSuccess(action);
      })
      .catch((e) => {
        reportError(e);
        snackbarService.showError(action);
      })
      .finally(() => {
        mainPageLoadService.end();
      });
  }

  export function onUpdatePriceList(
    priceList: Partial<PriceList> & WithId,
    facade: PriceListFacade,
    snackbarService: SnackbarService
  ) {
    const action = PriceListFeedback.update;
    facade
      .updateItem(priceList)
      .then(() => {
        snackbarService.showSuccess(action);
      })
      .catch((e) => {
        reportError(e);
        snackbarService.showError(action);
      });
  }

  export function onRemovePriceList(
    priceList: Partial<PriceList> & WithId,
    organizationId: string,
    facade: PriceListFacade,
    mainPageLoadService: MainPageLoadService,
    snackbarService: SnackbarService
  ) {
    const action = PriceListFeedback.remove;
    mainPageLoadService.start(LoadingFeedback.ON_DELETE_PRICE_LIST);
    facade
      .deletePriceList(organizationId, priceList.id)
      .then(() => {
        snackbarService.showSuccess(action);
      })
      .catch((e) => {
        reportError(e);
        snackbarService.showError(action);
      })
      .finally(() => {
        mainPageLoadService.end();
      });
  }

  export function onCreatePriceList(
    organizationId: string,
    dialog: MatDialog,
    mainPageLoadService: MainPageLoadService,
    snackbarService: SnackbarService,
    facade: PriceListFacade
  ) {
    const dialogRef = dialog.open<
      AddPriceListDialogComponent,
      PriceList,
      PriceList
    >(AddPriceListDialogComponent);

    dialogRef.afterClosed().subscribe((priceList) => {
      if (priceList) {
        mainPageLoadService.start(LoadingFeedback.ON_CREATE_PRICE_LIST);
        const action = PriceListFeedback.add;
        facade
          .createPriceList(organizationId, priceList)
          .then(() => {
            snackbarService.showSuccess(action);
          })
          .catch((e) => {
            reportError(e);
            snackbarService.showError(action);
          })
          .finally(() => {
            mainPageLoadService.end();
          });
      }
    });
  }

  export function isPriceListItemPromo(
    inventoryItem: InventoryItem & WithId,
    priceListItem: PriceListItem,
    newPriceListItemPrice: Money
  ): boolean {
    if (priceListItem.inventoryItemId != inventoryItem.id) {
      throw new Error(
        'The inventoryItem id and the priceListItem inventoryItemId are different'
      );
    }

    return (
      (inventoryItem.reducedPrice &&
        inventoryItem.reducedPrice.value.amount ===
          newPriceListItemPrice.amount) ??
      false
    );
  }

  export function getPriceListItemPrice(
    inventoryItem: InventoryItem & WithId,
    priceListItem: PriceListItem
  ): Money {
    if (priceListItem.inventoryItemId != inventoryItem.id) {
      throw new Error(
        'The inventoryItem id and the priceListItem inventoryItemId are different'
      );
    }

    return priceListItem.isPromo
      ? inventoryItem.reducedPrice.value
      : priceListItem.price;
  }

  export function getPriceListArrayForCsvExport(
    priceListItemLinesWithPriceList: PriceListItemLineWithPriceList[]
  ): PriceListCSVExportData[] {
    const priceListItemLineGroupedByPriceList =
      priceListItemLinesWithPriceList.reduce(
        (acc, priceListItemLineWithPriceList) => {
          const {
            name,
            id,
            volumeDiscounts,
            isPublic,
            publicVisibility,
            ...priceListItemLine
          } = priceListItemLineWithPriceList;
          if (acc[id]) {
            acc[id].priceListItemLines.push(priceListItemLine);
          } else {
            acc[id] = {
              priceList: {
                name,
                id,
                volumeDiscounts,
                isPublic,
                publicVisibility,
              },
              priceListItemLines: [priceListItemLine],
            };
          }
          return acc;
        },
        {} as Record<string, PriceListCSVExportData>
      );

    return Object.values(priceListItemLineGroupedByPriceList);
  }
}
