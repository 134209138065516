<form
  [formGroup]="form"
  *transloco="let t; read: 'components.contact-info-form'"
  fxLayout="column"
  fxLayoutGap="8px"
>
  <section
    *transloco="let g; read: 'components.contact-form'"
    fxLayout="column"
    fxLayout.gt-xs="row"
    fxLayoutGap="8px"
    fxLayoutGap.gt-xs="32px"
  >
    <mat-form-field
      *ngIf="displayContactInfoName"
      [floatLabel]="readonly ? 'always' : 'auto'"
    >
      <mat-label for="firstName">{{ g('first-name') }} </mat-label>
      <input matInput type="text" id="firstName" formControlName="firstName" />
    </mat-form-field>
    <mat-form-field
      *ngIf="displayContactInfoName"
      [floatLabel]="readonly ? 'always' : 'auto'"
    >
      <mat-label for="lastName">{{ g('last-name') }}</mat-label>
      <input matInput type="text" id="lastName" formControlName="lastName" />
    </mat-form-field>
  </section>
  <section
    fxLayout="column"
    fxLayout.gt-xs="row"
    fxLayoutGap="8px"
    fxLayoutGap.gt-xs="32px"
  >
    <mat-form-field [floatLabel]="readonly ? 'always' : 'auto'">
      <mat-label>
        {{ isOrganizationContactForm ? t('organization-email') : t('email') }}
      </mat-label>
      <input
        data-testid="email-input-id"
        required
        matInput
        formControlName="email"
        name="email"
        autocomplete="email"
        (blur)="onEmailChange()"
      />
      <mat-error *ngIf="form.get('email').hasError('email')">
        {{ t('invalid-email') }}
      </mat-error>
      <mat-error
        *ngIf="form.get('email').hasError('additionalValidationError')"
      >
        {{ emailAdditionalValidation?.errorLabel }}
      </mat-error>
    </mat-form-field>

    <mat-form-field [floatLabel]="readonly ? 'always' : 'auto'">
      <mat-label>
        {{ isOrganizationContactForm ? t('organization-phone') : t('phone') }}
      </mat-label>
      <input
        data-testid="phone-input-id"
        [required]="requireAtLeastOnePhoneNumber"
        matInput
        formControlName="phone"
        name="phone"
        autocomplete="phone"
        [errorStateMatcher]="phoneFieldErrorStateMatcher"
      />
      <mat-error *ngIf="form.get('phone').hasError('phoneNumber')">
        Format : 000-000-0000, 000 000 0000, 0000000000
      </mat-error>
      <mat-error *ngIf="form.get('phone').hasError('phoneNumber')">
        {{ t('phone-number-invalid') }}
      </mat-error>
      <mat-error
        *ngIf="form.hasError('phone')"
        data-testid="phone-error-phone-id"
      >
        {{ t('provide-at-least-one-phone-number') }}
      </mat-error>
    </mat-form-field>
  </section>
</form>
