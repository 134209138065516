import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';

import { PictureService } from '@arrivage-services/picture.service';
import { Format, OfferItem, Product } from '@arrivage/model/dist/src/model';

@Component({
  selector: 'app-format-list',
  templateUrl: './format-list.component.html',
  styleUrls: ['./format-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormatListComponent {
  readonly DEFAULT_PRODUCT_PICTURE = PictureService.defaultProductPicture;

  displayedColumns = ['format', 'price', 'unitPrice'];

  dataSource: MatTableDataSource<Omit<OfferItem, 'product'>> =
    new MatTableDataSource([]);

  @Input()
  set formats(value: Omit<OfferItem, 'product'>[]) {
    this.dataSource.data = value;
  }

  @Input()
  product: Product;

  @Input()
  selectedFormat: Format;

  @Input()
  isMediumOrSmallScreen: boolean = false;

  @Input()
  set showPriceAndStock(value: boolean) {
    if (!value) {
      this.displayedColumns = ['format'];
    }
  }

  constructor() {}
}
