import { AppUtilsModule } from 'src/app/app-utils.module';
import { MaterialModule } from 'src/app/material.module';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { UppercaseFirstPipeModule } from '@arrivage-pipes/uppercase-first-pipe/uppercase-first-pipe.module';

import { OrganizationMiniComponent } from './organization-mini.component';

@NgModule({
  declarations: [OrganizationMiniComponent],
  imports: [
    CommonModule,
    MaterialModule,
    AppUtilsModule,
    UppercaseFirstPipeModule,
  ],
  exports: [OrganizationMiniComponent],
})
export class OrganizationMiniModule {}
