import _ from 'lodash';
import { Subscription, distinctUntilChanged } from 'rxjs';
import { AddressUtils } from 'src/app/util/address.utils';

import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  ValidationErrors,
} from '@angular/forms';

import { Address, Country } from '@arrivage/model/dist/src/model';

export const completeAddressValidator = (
  control: AbstractControl
): ValidationErrors | null => {
  const address = AddressFormComponent.getAddressRecord(control);
  return AddressUtils.isCompleteAddress(address) ||
    AddressUtils.isEmptyAddress(address)
    ? null
    : { incompleteAddress: true };
};
@Component({
  selector: 'app-address-form',
  templateUrl: './address-form.component.html',
  styleUrls: ['./address-form.component.scss'],
})
export class AddressFormComponent implements OnInit, OnDestroy {
  readonly countryNames = _.map(Country.ALL, (c) => c.name);

  private _address: Address;
  private countryValueSubscription: Subscription;

  showIncompleteAddressError = false;

  form = this.fb.group(
    {
      address: [''],
      city: [''],
      stateOrProvince: [null],
      country: [null],
      zipOrPostalCode: [''],
    },
    { validators: completeAddressValidator }
  );

  @Input()
  required = true;

  @Input()
  readonly: boolean = false;

  @Input()
  isExtraSmallScreen: boolean;

  static getAddressRecord(control: AbstractControl): Address {
    return {
      address: control.get('address').value,
      city: control.get('city').value,
      country: control.get('country').value || '',
      stateOrProvince: control.get('stateOrProvince').value || '',
      zipOrPostalCode: control.get('zipOrPostalCode').value,
    };
  }

  @Input()
  set address(value: Address) {
    this._address = value;
    this.fillForm();
  }

  get address(): Address {
    return this._address;
  }

  @Input()
  set parentForm(value: UntypedFormGroup) {
    if (value) {
      value.setControl('addressForm', this.form);
    }
  }

  constructor(private fb: UntypedFormBuilder) {}

  ngOnInit(): void {
    if (this.readonly) {
      this.form.disable();
    }
    if (!this.readonly) {
      this.countryValueSubscription = this.form
        .get('country')
        .valueChanges.pipe(distinctUntilChanged())
        .subscribe(() => {
          this.form.get('stateOrProvince').setValue(null);
        });
    }
  }

  ngOnDestroy(): void {
    if (this.countryValueSubscription) {
      this.countryValueSubscription.unsubscribe();
    }
  }

  fillForm() {
    this.showIncompleteAddressError = false;
    if (this.address) {
      this.form.reset(
        {
          address: this.address.address,
          city: this.address.city,
          stateOrProvince: this.address.stateOrProvince || null,
          country: this.address.country || null,
          zipOrPostalCode: this.address.zipOrPostalCode,
        },
        { emitEvent: false }
      );
    } else {
      this.form.reset(
        {
          address: '',
          city: '',
          stateOrProvince: null,
          country: null,
          zipOrPostalCode: '',
        },
        { emitEvent: false }
      );
    }
  }

  getAdministrativeDivisions(): string[] {
    const countryName = this.form.controls['country'].value || '';
    const country = Country.findByName(countryName);
    return country ? _.map(country.administrativeDivisions, (a) => a.name) : [];
  }

  cancelChanges() {
    this.fillForm();
  }

  getRecord(): Address {
    if (this.form.valid || this.readonly) {
      this.showIncompleteAddressError = false;
      return AddressFormComponent.getAddressRecord(this.form);
    }
    return undefined;
  }

  showErrors() {
    this.form.markAllAsTouched();
    if (!this.required && this.form.errors?.['incompleteAddress']) {
      this.showIncompleteAddressError = true;
    }
  }
}
