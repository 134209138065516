import { Observable } from 'rxjs';

import { Injectable } from '@angular/core';
import { Firestore, where } from '@angular/fire/firestore';


import { OfferService } from '@arrivage-offers/common/services/offers.service';
import { EntityChangeAction } from '@arrivage-services/organization-entity.service';
import { Offer } from '@arrivage/model/dist/src/model';


@Injectable({
  providedIn: 'root',
})
export class VisibleOffersService extends OfferService {
  constructor(firestore: Firestore) {
    super(firestore);
  }

  override connect(organizationId: string): Observable<EntityChangeAction<Offer>[]> {
    return this._connect(
      this.pathSpec(),
      OfferService.convertTimestampFields,
      null,
      where('visibleForOrganizations', 'array-contains', organizationId),
      where('isActive', '==', true)
    );
  }
}
