<ng-container *transloco="let t; scope: 'priceLists'; read: 'priceLists'">
  <section fxLayout="column">
    <h2 class="mat-dialog-title space-bottom text-xl">
      {{
        data.isTestWorkflow
          ? t('share-to-customers-dialog.title-test')
          : t('share-to-customers-dialog.title')
      }}
    </h2>
    @if (everyShareIsLessThanHour()) {
      <app-system-message
        fxFlex
        type="warning"
        class="message-padding"
        [message]="
          t('share-to-customers-dialog.share-to-customer-less-an-hour', {
            timeLeft: remainingTimeToWait()
          })
        "
      />
    } @else {
      <div
        *ngIf="canShareOffer; else cantShareOfferContent"
        class="mat-dialog-content min-height"
      >
        <mat-card-content fxLayout="column" fxLayoutGap="1rem">
          <ng-container
            *ngIf="data.customers.allowed.length > 1; else sendToOneCustomer"
          >
            <app-system-message
              *ngIf="someHaveDeliveryIssues"
              data-testid="some-have-delivery-issues-testid"
              class="message"
              type="warning"
              [message]="t('system-message.delivery-issues')"
              [description]="
                t('system-message.some-delivery-issues-description')
              "
            ></app-system-message>

            <app-system-message
              *ngIf="someHaveOnlyPickup"
              class="message"
              type="info"
              [message]="t('system-message.some-have-pickup-only')"
              [description]="
                t('system-message.some-have-pickup-only-description')
              "
            ></app-system-message>

            <p class="description">
              {{
                t('share-to-customers-dialog.share-to-customers-description')
              }}
            </p>

            <div class="mat-table-container">
              <mat-table
                matSort
                fxFlex
                [dataSource]="dataSource"
                [trackBy]="trackById"
              >
                <ng-container matColumnDef="select" sticky>
                  <mat-header-cell *matHeaderCellDef fxFlex="50px">
                    <mat-checkbox
                      [checked]="selection.hasValue() && isAllSelected()"
                      [indeterminate]="selection.hasValue() && !isAllSelected()"
                      (change)="masterToggle()"
                    >
                    </mat-checkbox>
                  </mat-header-cell>
                  <mat-cell *matCellDef="let row" fxFlex="50px">
                    <mat-checkbox
                      data-testid="checkbox-test"
                      [checked]="selection.isSelected(row)"
                      [disabled]="!isEligibleForSharing(row)"
                      (click)="$event.stopPropagation()"
                      (change)="selection.toggle(row)"
                    >
                    </mat-checkbox>
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="customers" sticky>
                  <mat-header-cell *matHeaderCellDef mat-sort-header>
                    {{ t('share-to-customers-dialog.customer-header') }}
                  </mat-header-cell>
                  <mat-cell *matCellDef="let row">
                    <section
                      fxLayout="row wrap"
                      fxLayoutAlign="space-between center"
                      fxLayoutGap="5px"
                    >
                      <div
                        fxLayout="row"
                        fxLayoutGap="1rem"
                        fxLayoutAlign="start center"
                        [class.disabled]="!isEligibleForSharing(row)"
                      >
                        <div>{{ row.name }}</div>
                      </div>

                      <mat-icon
                        *ngIf="isOnlyPickupAvailable(row)"
                        class="text-color-warning material-icons-outlined"
                        [customTooltip]="t('pickup-only')"
                        >shopping_bag</mat-icon
                      >

                      <button
                        *ngIf="!isEligibleForSharing(row)"
                        mat-icon-button
                        class="text-error"
                        [customTooltip]="
                          noDeliveryDatesAvailable(row)
                            ? t('system-message.delivery-issues')
                            : t('system-message.pickup-issues')
                        "
                        tooltipPosition="right"
                        tooltipColor="error"
                        [routerLink]="['/vendor', 'offers']"
                      >
                        <mat-icon>error_outline</mat-icon>
                      </button>
                    </section>
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="lastShareOfferTimestamp" sticky>
                  <mat-header-cell *matHeaderCellDef mat-sort-header>
                    {{ t('list.last-sent') }}
                  </mat-header-cell>

                  <mat-cell *matCellDef="let row" fxLayoutGap="10px">
                    <span
                      *ngIf="row?.lastShareOfferTimestamp"
                      [customTooltip]="
                        row?.lastShareOfferTimestamp | translocoDate
                      "
                    >
                      {{
                        t('list.last-share-offer-time', {
                          time:
                            row?.lastShareOfferTimestamp
                            | approximativeTimeRemaining
                        })
                      }}
                    </span>
                    <mat-icon
                      *ngIf="
                        selection.isSelected(row) &&
                        elapsedTimeLessThanHour(row?.lastShareOfferTimestamp)
                      "
                      class="text-color-warning"
                      >error_outline</mat-icon
                    >
                  </mat-cell>
                </ng-container>

                <mat-header-row
                  *matHeaderRowDef="displayedColumns; sticky: true"
                ></mat-header-row>
                <mat-row
                  *matRowDef="let row; columns: displayedColumns"
                  (click)="select(row)"
                  [class.warning-row]="
                    selection.isSelected(row) &&
                    elapsedTimeLessThanHour(row?.lastShareOfferTimestamp)
                  "
                ></mat-row>
              </mat-table>
            </div>
          </ng-container>
        </mat-card-content>

        <mat-card-content class="message-container">
          <form [formGroup]="form">
            <mat-form-field appearance="outline">
              <mat-label>{{
                t('share-to-customers-dialog.message-label')
              }}</mat-label>
              <textarea
                cdkTextareaAutosize
                matInput
                rows="4"
                formControlName="message"
              ></textarea>
              <mat-hint>{{
                t('share-to-customers-dialog.message-hint')
              }}</mat-hint>
            </mat-form-field>
          </form>
          <div class="text-m" fxLayout="row" fxLayoutAlign="start center">
            <mat-checkbox
              [checked]="attachPdf"
              (change)="onAttachPdfChange($event.checked)"
            >
              {{ t('share-to-customers-dialog.attach-pdf') }}
            </mat-checkbox>
            <button mat-icon-button class="pdfIcon" (click)="onOpenPdf()">
              <mat-icon>description</mat-icon>
            </button>
          </div>
          <div
            class="inactive-product-checkbox-container text-m"
            fxLayout="row"
            fxLayoutAlign="start center"
          >
            <mat-checkbox
              [checked]="includeInactiveProducts"
              [disabled]="!attachPdf"
              (change)="includeInactiveProducts = $event.checked"
            >
              {{ t('share-to-customers-dialog.include-unavailable-products') }}
            </mat-checkbox>
          </div>
        </mat-card-content>

        <ng-template #sendToOneCustomer>
          <mat-card-content>
            <p *ngIf="!data.isTestWorkflow" class="description">
              {{
                t(
                  'share-to-customers-dialog.share-to-one-customer-description',
                  {
                    customer: data.customers?.allowed[0]?.name
                  }
                )
              }}
            </p>
            <small *ngIf="data.customers?.allowed[0]?.lastShareOfferTimestamp">
              <span
                *ngIf="
                  (data.customers?.allowed[0]?.lastShareOfferTimestamp
                    | countDaysBetween) > 0;
                  else updatedToday
                "
                class="text-light"
                [customTooltip]="
                  data.customers?.allowed[0]?.lastShareOfferTimestamp
                    | translocoDate
                "
                >{{
                  t('list.last-sent-time-notice', {
                    days:
                      data.customers?.allowed[0]?.lastShareOfferTimestamp
                      | countDaysBetween
                  })
                }}</span
              >
              <ng-template #updatedToday>
                <span>{{ t('list.updated-today') }}</span>
              </ng-template>
            </small>

            <ng-container *ngIf="!data.isTestWorkflow">
              <app-system-message
                *ngIf="isOnlyPickupAvailable(data.customers?.allowed[0])"
                class="message"
                type="info"
                [message]="t('pickup-only')"
              ></app-system-message>

              <app-system-message
                *ngIf="!isEligibleForSharing(data.customers?.allowed[0])"
                class="message"
                type="warning"
                [message]="t('customer-tooltip-notice')"
              ></app-system-message>
            </ng-container>
          </mat-card-content>
        </ng-template>
      </div>
      <ng-template #cantShareOfferContent>
        <div class="mat-dialog-content">
          <!-- SI aucun client n'a été associé alors :  -->
          <app-system-message
            *ngIf="!hasAtLeastOneCustomer"
            class="message"
            type="warning"
            [showBorder]="false"
            [message]="t('system-message.no-client')"
            [description]="t('system-message.no-client-description')"
          >
          </app-system-message>

          <!-- SI la liste de prix est inactive :  -->
          <app-system-message
            *ngIf="!isOfferActive"
            class="message"
            type="warning"
            [showBorder]="false"
            [message]="t('system-message.not-active')"
            [description]="t('system-message.not-active-description')"
          >
          </app-system-message>

          <!-- SI aucun produits :  -->
          <app-system-message
            *ngIf="!hasAtLeastOneProduct"
            class="message"
            type="warning"
            data-testid="no-product-error-message-test-id"
            [showBorder]="false"
            [message]="t('system-message.no-products')"
            [description]="t('system-message.no-products-description')"
          >
          </app-system-message>

          <app-system-message
            *ngIf="allHaveSharingIssues()"
            data-testid="specific-transport-issue-testid"
            class="message"
            type="warning"
            [showBorder]="false"
            [message]="t('system-message.general-issues')"
            [description]="
              errorMessageWhenAllHaveSharingIssues +
              t('system-message.transport-issues-description')
            "
          ></app-system-message>
        </div>
      </ng-template>

      @if (someShareIsLessThanHour()) {
        <app-system-message
          fxFlex
          type="warning"
          class="message-padding"
          [message]="
            t(
              'share-to-customers-dialog.share-to-selected-customer-less-an-hour'
            )
          "
        />
      }
    }

    <!-- Boutons -->
    <mat-dialog-actions fxLayoutGap="1em" align="end">
      <button
        mat-raised-button
        [mat-dialog-close]="false"
        data-testid="cancel-button-test-id"
      >
        {{
          everyShareIsLessThanHour()
            ? t('share-to-customers-dialog.close')
            : t('share-to-customers-dialog.cancel')
        }}
      </button>

      @if (!everyShareIsLessThanHour()) {
        <ng-container *ngIf="canShareOffer; else cantShareOfferButtons">
          <button
            mat-raised-button
            color="accent"
            data-testid="share-button-test-id"
            [disabled]="
              selection.selected?.length === 0 && !data.isTestWorkflow
            "
            (click)="share()"
          >
            {{ t('share-to-customers-dialog.share') }}
          </button>
        </ng-container>
        <ng-template #cantShareOfferButtons>
          <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="1em">
            <button
              *ngIf="allHaveSharingIssues()"
              mat-raised-button
              color="accent"
              data-testid="modify-clients-test-id"
              [routerLink]="['/vendor', 'offers']"
              [queryParams]="{ scrollKey: 'vendorRelationshipsOffers' }"
            >
              {{ t('share-to-customers-dialog.modify-dates') }}
            </button>
            <button
              *ngIf="!hasAtLeastOneCustomer && !data.isTestWorkflow"
              mat-raised-button
              color="accent"
              [routerLink]="['/vendor', 'offers']"
            >
              {{ t('add-client') }}
            </button>
          </div>
        </ng-template>
      }
    </mat-dialog-actions>
  </section>
</ng-container>
