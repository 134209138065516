import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

import { HashMap } from '@jsverse/transloco';

import { Errors } from '@arrivage-store/feedback/feedback-params.model';

@Component({
  selector: 'app-snackbar-error',
  templateUrl: './snackbar-error.component.html',
  styleUrls: ['../snackbar.style.scss', '../../styles/global/_colors.scss'],
})
export class SnackbarErrorComponent {
  constructor(
    @Inject(MAT_SNACK_BAR_DATA)
    public data: {
      message: string | Errors;
      close: () => {};
      messageParameters?: HashMap;
    }
  ) {}

  dismiss() {
    this.data.close();
  }
}
