import { from, Observable } from 'rxjs';

import { Injectable } from '@angular/core';
import { Functions, httpsCallable } from '@angular/fire/functions';

import { map, retryWhen, take } from 'rxjs/operators';

import { genericRetryStrategy } from '@arrivage-util/api.utils';
import {
  ChangePurchaseOrderStatusExternalRequestData,
  convertToCreatePurchaseOrderRequest,
  CreatePurchaseOrderRequest,
  SendPurchaseOrderRequestData,
  VerifyPurchaseOrderRequestData,
} from '@arrivage/model/dist/src/cloud-functions-api';
import {
  Message,
  PurchaseOrder,
  PurchaseOrderStatus,
  WithId,
} from '@arrivage/model/dist/src/model';
import { API_VERSION } from '@arrivage/model/dist/src/utils/api.utils';

@Injectable({
  providedIn: 'root',
})
export class PurchaseOrderApiService {
  private readonly createPurchaseOrderCloudFunction = httpsCallable<
    CreatePurchaseOrderRequest,
    string
  >(this.functions, 'createPurchaseOrder', { timeout: 110000 });
  private readonly createPurchaseOrderExternalCloudFunction = httpsCallable<
    CreatePurchaseOrderRequest,
    string
  >(this.functions, 'createPurchaseOrderExternal', { timeout: 110000 });
  private readonly createPurchaseOrderFromPublicOfferCloudFunction =
    httpsCallable<CreatePurchaseOrderRequest, string>(
      this.functions,
      'createPurchaseOrderFromPublicOffer',
      { timeout: 110000 }
    );
  private readonly updatePurchaseOrderCloudFunction = httpsCallable<
    {
      purchaseOrderRequestData: PurchaseOrder & WithId;
      modifiedByASeller: boolean;
      apiVersion: number;
    },
    string
  >(this.functions, 'updatePurchaseOrder');
  private readonly sendPurchaseOrderCloudFunction = httpsCallable<
    SendPurchaseOrderRequestData,
    string
  >(this.functions, 'sendPurchaseOrder');
  private readonly verifyPurchaseOrderCloudFunction = httpsCallable<
    VerifyPurchaseOrderRequestData,
    string
  >(this.functions, 'verifyPurchaseOrder');
  private readonly changePurchaseOrderStatusExternalCloudFunction =
    httpsCallable<ChangePurchaseOrderStatusExternalRequestData, void>(
      this.functions,
      'changePurchaseOrderStatusExternal'
    );

  constructor(private functions: Functions) {}

  async createPurchaseOrder(
    purchaseOrder: PurchaseOrder,
    message?: Message,
    sendEmailToMyself?: boolean,
    pdfBase64?: string
  ): Promise<Observable<string>> {
    const request: CreatePurchaseOrderRequest =
      convertToCreatePurchaseOrderRequest({
        po: purchaseOrder,
        message: message,
      });

    if (sendEmailToMyself) {
      request.sendEmailToMyself = sendEmailToMyself;
    }

    if (pdfBase64) {
      request.pdfBase64 = pdfBase64;
    }

    request.apiVersion = API_VERSION;

    return from(this.createPurchaseOrderCloudFunction(request)).pipe(
      retryWhen(genericRetryStrategy()),
      map((r) => r.data)
    );
  }

  async createPurchaseOrderExternal(
    purchaseOrder: PurchaseOrder,
    message?: Message,
    sendEmailToMyself?: boolean,
    pdfBase64?: string
  ): Promise<Observable<string>> {
    const request = convertToCreatePurchaseOrderRequest({
      po: purchaseOrder,
      message: message,
    });

    if (sendEmailToMyself) {
      request.sendEmailToMyself = sendEmailToMyself;
    }

    if (pdfBase64) {
      request.pdfBase64 = pdfBase64;
    }

    request.apiVersion = API_VERSION;

    return from(this.createPurchaseOrderExternalCloudFunction(request)).pipe(
      retryWhen(genericRetryStrategy()),
      map((r) => r.data)
    );
  }

  async createPurchaseOrderFromPublicOffer(
    purchaseOrder: PurchaseOrder,
    message?: Message,
    sendEmailToMyself?: boolean,
    pdfBase64?: string
  ): Promise<Observable<string>> {
    const request: CreatePurchaseOrderRequest =
      convertToCreatePurchaseOrderRequest({
        po: purchaseOrder,
        message: message,
      });

    if (sendEmailToMyself) {
      request.sendEmailToMyself = sendEmailToMyself;
    }

    if (pdfBase64) {
      request.pdfBase64 = pdfBase64;
    }

    request.apiVersion = API_VERSION;

    return from(
      this.createPurchaseOrderFromPublicOfferCloudFunction(request)
    ).pipe(
      retryWhen(genericRetryStrategy()),
      map((r) => r.data)
    );
  }

  async updatePurchaseOrder(
    purchaseOrder: PurchaseOrder & WithId,
    modifiedByASeller: boolean
  ): Promise<Observable<string>> {
    return from(
      this.updatePurchaseOrderCloudFunction({
        purchaseOrderRequestData: purchaseOrder,
        modifiedByASeller,
        apiVersion: API_VERSION,
      })
    ).pipe(
      retryWhen(genericRetryStrategy()),
      map((r) => r.data)
    );
  }

  async sendPurchaseOrder(
    purchaseOrder: PurchaseOrder & WithId,
    pdfBase64: string,
    messageToCustomers?: string
  ): Promise<string> {
    const request: SendPurchaseOrderRequestData = {
      purchaseOrder,
      pdfBase64,
      messageToCustomers,
    };

    return from(this.sendPurchaseOrderCloudFunction(request))
      .pipe(retryWhen(genericRetryStrategy()), take(1))
      .toPromise()
      .then((data) => data.data);
  }

  verifyPurchaseOrder(verificationId: string): Observable<string> {
    return from(
      this.verifyPurchaseOrderCloudFunction({
        verificationId: verificationId,
      })
    ).pipe(
      retryWhen(genericRetryStrategy()),
      map((r) => r.data)
    );
  }

  changePurchaseOrderStatusExternal(
    purchaseOrderId: string,
    newStatus: PurchaseOrderStatus
  ) {
    return from(
      this.changePurchaseOrderStatusExternalCloudFunction({
        purchaseOrderId: purchaseOrderId,
        newPurchaseOrderStatus: newStatus,
      })
    ).pipe(
      retryWhen(genericRetryStrategy()),
      map((r) => r.data)
    );
  }
}
