import { of } from 'rxjs';

import { Injectable } from '@angular/core';
import { FirebaseError } from '@angular/fire/app';

import {
  catchError,
  exhaustMap,
  filter,
  map,
  mergeMap,
  takeUntil,
  tap,
  withLatestFrom,
} from 'rxjs/operators';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { Logout } from '@arrivage-auth/store/auth.actions';
import { reportError } from '@arrivage-sentry/report-error';
import { SnackbarService } from '@arrivage-snackbar/snackbar.service';
import { getUser } from '@arrivage-store/context/context.selectors';
import { EntityFeedback } from '@arrivage-store/feedback/feedback-params.model';
import { UserSettings, WithId } from '@arrivage/model/dist/src/model';

import { UserSettingsService } from '../services/user-settings.service';
import * as userSettingsActions from './user-settings.actions';
import { State, UserSettingsState } from './user-settings.state';

export const UserSettingsFeedBack: EntityFeedback = {
  query: 'query_user_settings',
  update: 'update_user_settings',
  add: '',
  remove: '',
  get_active_item: '',
};

@Injectable()
export class UserSettingsEffects {
  query$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userSettingsActions.query),
      withLatestFrom(this.store.select(getUser)),
      map(([, user]) => user),
      filter((user) => !!user),
      exhaustMap((user) =>
        this.service.get(user.id).pipe(
          takeUntil(this.actions$.pipe(ofType(Logout))),
          map((userSettings) => {
            return userSettingsActions.querySuccess({
              userSettings: userSettings,
            });
          }),
          catchError((e: FirebaseError) => {
            reportError(e);
            return of(
              userSettingsActions.queryFailure({ errorMessage: e.message })
            );
          })
        )
      )
    )
  );

  queryFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(userSettingsActions.queryFailure),
        tap(() => this.snackbarService.showError(UserSettingsFeedBack.query))
      ),
    { dispatch: false }
  );

  update$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userSettingsActions.update),
      withLatestFrom(this.store.select(getUser)),
      mergeMap(([update, user]) => {
        const userSettings = this.removeIdFromRecord(update.userSettings);
        return of(
          this.service.update(user.id, update.userSettings.id, userSettings)
        ).pipe(
          map(() => {
            update.confirmation.resolve(update.userSettings.id);
            return userSettingsActions.updateSuccess({
              userSettings: userSettings,
            });
          }),
          catchError((error) => {
            reportError(error);
            update.confirmation.reject(error);
            return of(
              userSettingsActions.updateFailure({
                userSettings: update.userSettings,
                errorMessage: error,
              })
            );
          })
        );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private store: Store<State & UserSettingsState>,
    private service: UserSettingsService,
    private snackbarService: SnackbarService
  ) {}

  private removeIdFromRecord(record: UserSettings & WithId): UserSettings {
    const userSettings = { ...record };
    delete userSettings.id;
    return userSettings;
  }
}
