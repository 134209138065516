import { createAction, props } from '@ngrx/store';

import { Confirmation } from '@arrivage-store/generators';
import { OrganizationAccountSetup } from '@arrivage/model/dist/src/model';

export const query = createAction(`[OrganizationAccountSetup] Query`);
export const querySuccess = createAction(
  `[OrganizationAccountSetup] Query Success`,
  props<{ organizationSetup: OrganizationAccountSetup }>()
);
export const queryFailure = createAction(
  `[OrganizationAccountSetup] Query Failure`,
  props<{ errorMessage: string }>()
);

export const update = createAction(
  `[OrganizationAccountSetup] Update`,
  props<{
    organizationSetup: Partial<OrganizationAccountSetup>;
    confirmation: Confirmation<string, any>;
  }>()
);
export const updateSuccess = createAction(
  `[OrganizationAccountSetup] Update Success`,
  props<{ organizationSetup: OrganizationAccountSetup }>()
);
export const updateFailure = createAction(
  `[OrganizationAccountSetup] Update Failure`,
  props<{
    organizationSetup: Partial<OrganizationAccountSetup>;
    errorMessage: string;
  }>()
);
