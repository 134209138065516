import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';

import * as actions from '@arrivage-conversations/store/conversations.actions';
import * as selectors from '@arrivage-conversations/store/conversations.selectors';
import { State } from '@arrivage-conversations/store/conversations.state';
import { EntitiesFacade } from '@arrivage-store/api/entities.facade';
import { Conversation } from '@arrivage/model/dist/src/model';

@Injectable({
  providedIn: 'root',
})
export class ConversationFacade extends EntitiesFacade<Conversation, State> {
  constructor(store: Store<State>) {
    super(store, actions, selectors);
  }

  updateConversationViewedBy(conversationId: string): Promise<string> {
    return new Promise((resolve, reject) => {
      this.store.dispatch(
        actions.updateConversationViewedByInfo({
          conversationId: conversationId,
          confirmation: {
            resolve: resolve,
            reject: reject,
          },
        })
      );
    });
  }
}
