<ng-container *transloco="let t; read: 'components.system-message-dialog'">
  <h2 class="mat-dialog-title text-xxl">
    {{ data.title }}
  </h2>
  <div class="mat-typography mat-dialog-content">
    <app-system-message
      [type]="data.type"
      [message]="data.message"
      [description]="data.description"
      [showBorder]="false"
    ></app-system-message>
  </div>
  <mat-dialog-actions align="end">
    <button mat-button [mat-dialog-close]="false">
      {{ t('ok-button') }}
    </button>
  </mat-dialog-actions>
</ng-container>
