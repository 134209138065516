import * as Sentry from '@sentry/angular-ivy';
import _ from 'lodash';

import { Injectable } from '@angular/core';

import { filter, take } from 'rxjs/operators';

import { Action, Store } from '@ngrx/store';

import { AnalyticsFacade } from '@arrivage-analytics/api/analytics.facade';
import { AuthUser } from '@arrivage-auth/model/auth-data.model';
import { getAuthUser } from '@arrivage-auth/store/auth.selectors';
import { query as creditAccountQuery } from '@arrivage-credit-accounts/store/credit-account.actions';
import { getCreditAccountState } from '@arrivage-credit-accounts/store/credit-account.selectors';
import * as organizationSettingsActions from '@arrivage-organization-settings/store/organization-settings.actions';
import * as organizationSettingsSelectors from '@arrivage-organization-settings/store/organization-settings.selectors';
import { query as organizationSetupQuery } from '@arrivage-organization-setup/store/organization-setup.actions';
import { getOrganizationSetupState } from '@arrivage-organization-setup/store/organization-setup.selectors';
import { getAuthorizations } from '@arrivage-organization/store/organization-authorizations.actions';
import { getOrganizationAuthorizationsState } from '@arrivage-organization/store/organization-authorizations.selectors';
import { getPremiumAccount } from '@arrivage-premium-accounts/store/premium-accounts.actions';
import { getPremiumAccountState } from '@arrivage-premium-accounts/store/premium-accounts.selectors';
import { query as producersQuery } from '@arrivage-producers/store/producers.actions';
import { getProducerState } from '@arrivage-producers/store/producers.selectors';
import { reportError } from '@arrivage-sentry/report-error';
import { AuthenticationSequenceInitialUrlService } from '@arrivage-services/authentication-sequence-initial-url.service';
import { DeliveryZonesService } from '@arrivage-services/delivery-zones.service';
import { HubspotService } from '@arrivage-services/hubspot.service';
import { ContextFacade } from '@arrivage-store/api/context.facade';
import { query as userSettingsQuery } from '@arrivage-user-settings/store/user-settings.actions';
import { getUserSettingsState } from '@arrivage-user-settings/store/user-settings.selectors';
import { UserFacade } from '@arrivage-user/api/user.facade';
import { CustomerLinks } from '@arrivage-util/links.model';
import { Member, Organization, WithId } from '@arrivage/model/dist/src/model';

import { navigateTo } from '../router/router.actions';
import { State } from '../state';
import {
  LoadConnectedUser,
  LoadConnectedUserActiveMembership,
  LoadConnectedUserActiveOrganization,
  LoadConnectedUserMemberships,
  LoadContextData,
  LoadContextFailure,
} from './context.actions';
import {
  getAllUserMembershipsConnectionState,
  getMembershipConnectionState,
  getOrganizationConnectionState,
  getUserConnectionState,
} from './context.selectors';

@Injectable({
  providedIn: 'root',
})
export class LoadSequenceService {
  constructor(
    private store: Store<State>,
    private initialUrlService: AuthenticationSequenceInitialUrlService,
    private analyticsFacade: AnalyticsFacade,
    private hubspotService: HubspotService,
    private contextFacade: ContextFacade,
    private userFacade: UserFacade,
    private deliveryZonesService: DeliveryZonesService
  ) {}

  async loadSequence(loadContextData: LoadContextData): Promise<Action> {
    const authUser = await this.getAuthUser();
    if (!authUser || !authUser.uid) {
      return LoadContextFailure({
        message: 'User not logged in, can not load user data',
      });
    }

    // connect user
    const userConnectionResult = await this.loadUser(loadContextData.userId);
    if (userConnectionResult.user && userConnectionResult.userLoaded) {
      // load user settings
      await this.loadUserSettings();
    }
    if (userConnectionResult.userLoadFailure) {
      return LoadContextFailure({
        message: userConnectionResult.userLoadFailure,
      });
    }

    // if the user record is not defined, route to onboarding
    if (!userConnectionResult.user) {
      return navigateTo({
        commands: ['/onboarding'],
      });
    }

    const userMembershipsResult = await this.loadUserMemberships(
      loadContextData.userId
    );
    if (userMembershipsResult.allUserMembershipsLoadFailure) {
      return LoadContextFailure({
        message: userMembershipsResult.allUserMembershipsLoadFailure,
      });
    }

    // if there are more that one membership
    if (userMembershipsResult.allUserMemberships.length > 1) {
      return navigateTo({
        commands: ['/organization/selection'],
      });
    }

    // otherwise load active membership
    let activeMembership: Member & WithId;
    if (loadContextData.activeMembershipId) {
      activeMembership = _.find(
        userMembershipsResult.allUserMemberships,
        (m) => m.id === loadContextData.activeMembershipId
      );
    }
    const membership = activeMembership
      ? activeMembership
      : userMembershipsResult.allUserMemberships[0];

    return this.loadFromMembership(membership, authUser, loadContextData);
  }

  async loadFromMembership(
    membership: Member & WithId,
    authUser: AuthUser,
    loadContextData?: LoadContextData
  ): Promise<Action> {
    const membershipResult = await this.loadActiveMembership(membership);

    if (membershipResult.membershipLoadFailure) {
      return LoadContextFailure({
        message: membershipResult.membershipLoadFailure,
      });
    }

    // load active organization
    let organizationId = membership.organizationId;

    const adminUsingViewAs =
      loadContextData?.organizationOverride && authUser.claims['admin'];
    if (adminUsingViewAs) {
      // admin organization override
      organizationId = loadContextData.organizationOverride;
    }
    this.deliveryZonesService.loadZoneData();
    const organizationResult = await this.loadActiveOrganization(
      organizationId
    );
    if (organizationResult.organizationLoadFailure) {
      return LoadContextFailure({
        message: organizationResult.organizationLoadFailure,
      });
    }

    // load organization settings
    await this.loadOrganizationSettings();

    // load organization authorizations
    await this.loadOrganizationAuthorizations(organizationId);

    // load organization account setup
    await this.loadOrganizationAccountSetup();

    // load premium account
    await this.loadPremiumAccount(organizationId);

    // load credit account if vendor
    if (organizationResult.organization.isSeller) {
      await this.loadCreditAccount();
      await this.loadSellerProducers();
    }

    // set analytics context
    this.analyticsFacade.setOrganizationUserProperties(
      organizationResult.organization
    );

    // set sentry context
    Sentry.setContext('organization', {
      organizationId: organizationResult.organization.id,
    });
    Sentry.setContext('user', {
      userId: authUser.uid,
    });

    this.updateLastTimeAccess(authUser.uid, organizationId, adminUsingViewAs);

    // set hubspot context
    this.hubspotService.identify(
      organizationResult.organization.contactInfo.email
    );

    // this is important for external page routing after login or account creation
    // the external page will save the entry point URL so after login or account creation
    // the user can be redirected to the page he/she was looking at
    const initialUrl = this.initialUrlService.getInitialUrl();
    this.initialUrlService.resetInitialUrl();

    return navigateTo({
      commands: this.getStartUrlCommand(
        initialUrl,
        organizationResult.organization
      ),
    });
  }

  private async getAuthUser() {
    return this.store
      .select(getAuthUser)
      .pipe(
        filter((u) => !!u),
        take(1)
      )
      .toPromise();
  }

  private async loadUser(userId: string) {
    this.store.dispatch(LoadConnectedUser({ userId: userId }));

    return await this.store
      .select(getUserConnectionState)
      .pipe(
        filter((s) => s.userLoaded || !!s.userLoadFailure),
        take(1)
      )
      .toPromise();
  }

  private async loadUserMemberships(userId: string) {
    this.store.dispatch(LoadConnectedUserMemberships({ userId: userId }));

    return await this.store
      .select(getAllUserMembershipsConnectionState)
      .pipe(
        filter(
          (s) => s.allUserMembershipsLoaded || !!s.allUserMembershipsLoadFailure
        ),
        take(1)
      )
      .toPromise();
  }

  private async loadActiveMembership(membership: Member & WithId) {
    this.store.dispatch(
      LoadConnectedUserActiveMembership({
        membership: membership,
      })
    );

    return await this.store
      .select(getMembershipConnectionState)
      .pipe(
        filter((s) => s.membershipLoaded || !!s.membershipLoadFailure),
        take(1)
      )
      .toPromise();
  }

  private async loadActiveOrganization(organizationId: string) {
    this.store.dispatch(
      LoadConnectedUserActiveOrganization({
        organizationId: organizationId,
      })
    );

    return await this.store
      .select(getOrganizationConnectionState)
      .pipe(
        filter((s) => s.organizationLoaded || !!s.organizationLoadFailure),
        take(1)
      )
      .toPromise();
  }

  private async loadUserSettings() {
    this.store.dispatch(userSettingsQuery());
    return this.store
      .select(getUserSettingsState)
      .pipe(
        filter((s) => s.loaded || !!s.error),
        take(1)
      )
      .toPromise();
  }

  private async loadOrganizationSettings() {
    this.store.dispatch(organizationSettingsActions.query());
    return this.store
      .select(organizationSettingsSelectors.selectSettings)
      .pipe(
        filter((s) => !!s),
        take(1)
      )
      .toPromise();
  }

  private async loadOrganizationAuthorizations(id: string) {
    this.store.dispatch(getAuthorizations({ id: id }));
    return this.store
      .select(getOrganizationAuthorizationsState)
      .pipe(
        filter((a) => a.loaded || !!a.error),
        take(1)
      )
      .toPromise();
  }

  private async loadOrganizationAccountSetup() {
    this.store.dispatch(organizationSetupQuery());
    return this.store
      .select(getOrganizationSetupState)
      .pipe(
        filter((s) => s.loaded || !!s.error),
        take(1)
      )
      .toPromise();
  }

  private async loadPremiumAccount(organizationId: string) {
    this.store.dispatch(getPremiumAccount({ id: organizationId }));
    return this.store
      .select(getPremiumAccountState)
      .pipe(
        filter((a) => a.loaded || !!a.error),
        take(1)
      )
      .toPromise();
  }

  private async loadCreditAccount() {
    this.store.dispatch(creditAccountQuery());
    return this.store
      .select(getCreditAccountState)
      .pipe(
        filter((s) => s.loaded || !!s.error),
        take(1)
      )
      .toPromise();
  }

  private async loadSellerProducers() {
    this.store.dispatch(producersQuery());
    return this.store
      .select(getProducerState)
      .pipe(
        filter((s) => s.connected || s.queryFailure),
        take(1)
      )
      .toPromise();
  }

  private async updateLastTimeAccess(
    userId: string,
    organizationId: string,
    isAdminUsingViewAs: boolean
  ) {
    if (!isAdminUsingViewAs) {
      // do not update the organization access time if user is admin using view as
      try {
        this.contextFacade.onUpdateOrganization({
          organization: {
            id: organizationId,
            lastAccessTime: new Date().getTime(),
          },
        });
      } catch (e) {
        reportError(e);
      }
    }

    try {
      this.userFacade.updateUser({
        user: {
          id: userId,
          lastAccessTime: new Date().getTime(),
        },
      });
    } catch (e) {
      reportError(e);
    }
  }

  private getStartUrlCommand(
    initialUrl: string,
    organization: Organization
  ): string[] {
    if (organization.isBuyer && !organization.isSeller && initialUrl === '/') {
      // if is a buyer only and the initial URL is home, route to search
      return CustomerLinks.Search.URL;
    }
    // else route to initial URL
    return [initialUrl];
  }
}
