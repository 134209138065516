<mat-expansion-panel
  class="mat-elevation-z0 bg-inherit"
  [expanded]="isPanelExpanded"
  *transloco="let t; read: 'navigation'"
>
  <mat-expansion-panel-header *ngIf="isNavbarExpanded">
    <mat-panel-title fxLayout="row" fxLayoutAlign="start center">
      <span class="bold">{{ t(titleKey) }}</span>
      <mat-icon *ngIf="indicator" class="indicator" color="warn">
        fiber_manual_record
      </mat-icon>
    </mat-panel-title>
  </mat-expansion-panel-header>

  <ng-content></ng-content>
</mat-expansion-panel>
