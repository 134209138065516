import { Injectable } from '@angular/core';
import {
  LayoutGapParent,
  LayoutGapStyleBuilder, StyleDefinition
} from '@angular/flex-layout';

@Injectable({
  providedIn: 'root',
})
export class CustomLayoutGapStyleBuilder extends LayoutGapStyleBuilder {
  override buildStyles(gapValue: string, parent: LayoutGapParent): StyleDefinition {
    return super.buildStyles(gapValue || '0 px', parent);
  }

  override sideEffect(gapValue, _styles, parent) {
    return super.sideEffect(gapValue || '0 px', _styles, parent);
  }
}
