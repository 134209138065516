import _ from 'lodash';

import { Injectable } from '@angular/core';
import {
  doc,
  Firestore,
  getDoc,
  runTransaction,
  setDoc,
} from '@angular/fire/firestore';

import { AnalyticsFacade } from '@arrivage-analytics/api/analytics.facade';
import { OrganizationSetupService } from '@arrivage-organization-setup/services/organization-setup.service';
import { MemberService } from '@arrivage-organization/services/member.service';
import { OrganizationService } from '@arrivage-organization/services/organization.service';
import { reportError } from '@arrivage-sentry/report-error';
import { IdService } from '@arrivage-services/id.service';
import { OrganizationSettingsService } from '@arrivage-services/organization-settings/organization-settings.service';
import { PictureService } from '@arrivage-services/picture.service';
import { UserSettingsService } from '@arrivage-user-settings/services/user-settings.service';
import { UserService } from '@arrivage-user/services/user.service';
import {
  Country,
  Member,
  Organization,
  OrganizationAccountSetup,
  OrganizationSettings,
  RoleType,
  TaxDefinition,
  TaxSetting,
  UserSettings,
} from '@arrivage/model/dist/src/model';

import { PURCHASE_ORDER_PREFIX } from '@arrivage/model/dist/src/utils';
import { AccountCreationInfo } from './account-create.model';

@Injectable({
  providedIn: 'root',
})
export class AccountCreateService {
  readonly DEFAULT_PAYMENT_TERMS = 15;

  constructor(
    private firestore: Firestore,
    private pictureService: PictureService,
    private idService: IdService,
    private analyticsFacade: AnalyticsFacade
  ) {}

  async createAccount(
    accountCreationInfo: AccountCreationInfo
  ): Promise<Member> {
    const userId = accountCreationInfo.uid;
    const userInfo = accountCreationInfo.userInfo;
    const newUser = { ...userInfo.user };
    const newOrganization: Organization = {
      ...accountCreationInfo.organizationInfo.organization,
      vendorProfile: {
        displayedNames: {
          firstName: newUser.firstName,
          lastName: newUser.lastName,
        },
      },
    };

    // Upload user picture and set the attribute if needed
    if (userInfo.picture) {
      const pictureUrl = await this.pictureService.uploadPicture(
        userInfo.picture,
        'users/' + userId + '/profilePictures/' + userInfo.picture.name
      );
      newUser.pictureUrl = pictureUrl;
    }

    const userRef = (
      await getDoc(doc(this.firestore, UserService.USERS_COLLECTION, userId))
    ).ref;

    // Create new organization ref and upload picture if needed
    const organizationRef = doc(
      this.firestore,
      OrganizationService.ORGANIZATIONS_COLLECTION,
      this.idService.createId()
    );

    if (accountCreationInfo.organizationInfo.logo) {
      const organizationLogoUrl = await this.pictureService.uploadPicture(
        accountCreationInfo.organizationInfo.logo,
        'organizations/' +
          organizationRef.id +
          '/logo/' +
          accountCreationInfo.organizationInfo.logo.name
      );
      newOrganization.logoUrl = organizationLogoUrl;
    }

    if (accountCreationInfo.organizationInfo.picture) {
      const organizationPictureUrl = await this.pictureService.uploadPicture(
        accountCreationInfo.organizationInfo.picture,
        'organizations/' +
          organizationRef.id +
          '/picture/' +
          accountCreationInfo.organizationInfo.picture.name
      );
      newOrganization.pictureUrl = organizationPictureUrl;
    }

    if (accountCreationInfo.organizationInfo.organization.websiteUrl) {
      newOrganization.websiteUrl =
        accountCreationInfo.organizationInfo.organization.websiteUrl;
    }

    if (accountCreationInfo.organizationInfo.organization.shortDescription) {
      newOrganization.shortDescription =
        accountCreationInfo.organizationInfo.organization.shortDescription;
    }

    if (
      accountCreationInfo.organizationInfo.organization.socialNetworks
        ?.instagram ||
      accountCreationInfo.organizationInfo.organization.socialNetworks?.facebook
    ) {
      newOrganization.socialNetworks = {
        instagram:
          accountCreationInfo.organizationInfo.organization.socialNetworks
            .instagram,

        facebook:
          accountCreationInfo.organizationInfo.organization.socialNetworks
            .facebook,
      };
    }

    // create administrator membership
    const member = {
      organizationId: organizationRef.id,
      roles: [RoleType.ADMINISTRATOR],
      userId: userId,
    };
    const memberRef = doc(
      this.firestore,
      `${OrganizationService.ORGANIZATIONS_COLLECTION}/${organizationRef.id}/${MemberService.MEMBERS_COLLECTION}`,
      userId
    );

    // create default organization account setup
    const defaultOrganizationSetup: OrganizationAccountSetup = {};
    const organizationSetupRef = doc(
      this.firestore,
      `${OrganizationSetupService.ORGANIZATION_COLLECTION}/${organizationRef.id}/${OrganizationSetupService.ORGANIZATION_ACCOUNT_SETUP_COLLECTION_NAME}`,
      OrganizationSetupService.ORGANIZATION_ACCOUNT_SETUP_ID
    );

    // create default organization settings
    const defaultSettings = this.createDefaultOrganizationSettings(
      newOrganization,
      accountCreationInfo.language
    );
    if (accountCreationInfo.deliveryInstructions) {
      defaultSettings.deliveries = {
        reception: {
          deliveryInstructions: accountCreationInfo.deliveryInstructions,
        },
      };
    }

    const organizationSettingsRef = doc(
      this.firestore,
      `${OrganizationService.ORGANIZATIONS_COLLECTION}/${organizationRef.id}/${OrganizationSettingsService.SETTINGS_COLLECTION}`,
      OrganizationSettingsService.SETTINGS_ID
    );

    // create default user settings
    const defaultUserSettings: UserSettings = {};
    const userSettingsRef = doc(
      this.firestore,
      `${UserSettingsService.USER_COLLECTION}/${userId}/${UserSettingsService.USER_SETTINGS_COLLECTION_NAME}`,
      UserSettingsService.USER_SETTINGS_ID
    );

    try {
      // run transaction to write all record atomically
      await runTransaction(this.firestore, async (t) => {
        t.set(userRef, newUser);
        t.set(organizationRef, newOrganization);
        t.set(memberRef, member);
      })
        // then we create the initial settings and setup
        // we can't create them in the transaction since we need the user to already be an admin
        .then(() =>
          Promise.all([
            setDoc(organizationSettingsRef, defaultSettings),
            setDoc(userSettingsRef, defaultUserSettings),
            setDoc(organizationSetupRef, defaultOrganizationSetup),
          ])
        );

      this.analyticsFacade.logCreateAccount();
      return member;
    } catch (error) {
      reportError(error);
    }
  }

  private createDefaultOrganizationSettings(
    organization: Organization,
    languagePreference: string
  ): OrganizationSettings {
    const defaultSettings: OrganizationSettings = {};
    if (organization.isSeller) {
      defaultSettings.sales = {
        currency: Country.findByName(organization.address.country).currency,
        taxes: _.map(
          TaxDefinition.getTaxesByAddress(organization.address),
          (td) => {
            const taxSetting: TaxSetting = {
              taxId: this.idService.createId(),
              tax: td,
              taxNumber: null,
            };
            return taxSetting;
          }
        ),
        paymentTermsInDays: this.DEFAULT_PAYMENT_TERMS,
      };

      defaultSettings.preferences = {
        language: languagePreference,
        purchaseOrderPrefix: PURCHASE_ORDER_PREFIX,
      };
    } else {
      defaultSettings.preferences = { language: languagePreference };
    }
    return defaultSettings;
  }
}
