import { Observable } from 'rxjs';

import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';

import { State } from '@arrivage-store/state';
import {
  CreditAccount,
  CreditAccountOperationType,
} from '@arrivage/model/dist/src/model';

import { CreditAccountsOperationsService } from '../services/credit-accounts-operations.service';
import { selectCreditAccount } from '../store/credit-account.selectors';

@Injectable({
  providedIn: 'root',
})
export class CreditAccountsFacade {
  creditAccount$: Observable<CreditAccount>;

  constructor(
    private store: Store<State>,
    private creditAccountOperationsService: CreditAccountsOperationsService
  ) {
    this.creditAccount$ = this.store.select(selectCreditAccount);
  }

  getCreditAccount() {
    return this.creditAccount$;
  }

  getCreditAccountOperations(
    organizationId: string,
    interval: Interval,
    types: CreditAccountOperationType[] = []
  ) {
    return this.creditAccountOperationsService.list(
      organizationId,
      interval,
      types
    );
  }
}
