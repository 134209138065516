<app-view-navigation
  [authUser]="authUser$ | async"
  [user]="user$ | async"
  [activeOrganization]="activeOrganization$ | async"
  [allOrganizations]="organizations$ | async"
  [settings]="settings$ | async"
  [customerPurchaseOrderMetrics]="customerPurchaseOrderMetrics$ | async"
  [vendorPurchaseOrderMetrics]="vendorPurchaseOrderMetrics$ | async"
  [offerByPriceList]="offerByPriceList$ | async"
  [customersAccessByPriceList]="customersAccessByPriceList$ | async"
  [screenSize]="screenSize$ | async"
  [notifications]="notifications$ | async"
  [creditAccount]="creditAccount$ | async"
  [premiumAccount]="premiumAccount$ | async"
  [priceLists]="priceLists$ | async"
  [deliveries]="deliveries$ | async"
  [conversations]="conversations$ | async"
  [deliveryByRelationship]="deliveryByRelationship$ | async"
  (notificationRead)="markAsRead($event)"
>
  <ng-content></ng-content>
</app-view-navigation>
