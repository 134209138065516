import { NEVER, Observable, combineLatest, race } from 'rxjs';

import { Injectable } from '@angular/core';
import {
  Firestore,
  collection,
  collectionSnapshots,
  doc,
  docData,
  docSnapshots,
  query,
  updateDoc,
  where,
} from '@angular/fire/firestore';

import { filter, map, take, timeout } from 'rxjs/operators';

import { ConversationUtils } from '@arrivage-conversations/utils/conversations.utils';
import { User, WithId } from '@arrivage/model/dist/src/model';
import { CollectionNames } from '@arrivage/model/dist/src/utils';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  static readonly USER_GET_TIMEOUT_IN_MS = 10000;
  static readonly USERS_COLLECTION = CollectionNames.USER_COLLECTION;

  constructor(private firestore: Firestore) {}

  getUser(userId: string): Observable<User & WithId> {
    const docSnaps = docSnapshots(
      doc(this.firestore, UserService.USERS_COLLECTION, userId)
    );
    return race(
      docSnaps.pipe(
        // we don't want cache data for user data otherwise we might get an null record on loading sequence
        // which causes the app to wrongly show the onboarding page again
        filter((p) => !p.metadata.fromCache),
        map((p) => {
          if (p.exists()) {
            const data = p.data() as User;
            const id = p.id;
            return { id, ...data };
          } else {
            return null;
          }
        })
      ),
      // if we don't receive a value after some timeout,
      // throw an error as this means the network is down of firebase doesn't response
      NEVER.pipe(timeout(UserService.USER_GET_TIMEOUT_IN_MS))
    );
  }

  updateUser(user: Partial<User>, id: string): Promise<void> {
    return updateDoc(
      doc(this.firestore, UserService.USERS_COLLECTION, id),
      user
    );
  }

  getUserIdByEmail(email: string): Promise<string[]> {
    const users = collectionSnapshots(
      query(
        collection(this.firestore, UserService.USERS_COLLECTION),
        where('contactInfo.email', '==', email)
      )
    );
    return users
      .pipe(
        take(1),
        map((actions) => {
          return actions.map((a) => {
            return a.id;
          });
        })
      )
      .toPromise();
  }

  getUsersInfo(usersId: string[]): Observable<ConversationUtils.UserInfoMap> {
    const observablesUsersInfo = usersId.map((userId) =>
      docData(doc(this.firestore, UserService.USERS_COLLECTION, userId)).pipe(
        map((x) => {
          return {
            id: userId,
            info: {
              firstName: x['firstName'],
              lastName: x['lastName'],
              pictureUrl: x['pictureUrl'],
            },
          };
        })
      )
    );

    return combineLatest(observablesUsersInfo).pipe(
      map((result) =>
        result.reduce(function (map, obj) {
          map[obj.id] = obj.info;
          return map;
        }, {})
      )
    );
  }
}
