<ng-container
  *transloco="let t; read: 'components.communication-preference-form'"
>
  <section fxLayout="column" fxLayoutGap="2em">
    <mat-table [dataSource]="dataSource">
      <!-- EMAIL -->
      <ng-container matColumnDef="email">
        <mat-header-cell
          *matHeaderCellDef
          fxLayoutAlign="start center"
          class="large-cell"
          >{{ t('email') }}</mat-header-cell
        >

        <mat-cell
          *matCellDef="let email"
          fxLayoutAlign="start center"
          class="large-cell"
          [ngClass]="{
            disabled: buyerHasAccount || isMainEmail(email)
          }"
        >
          {{ email }}
        </mat-cell>
      </ng-container>

      <!-- PRICE LIST EMAIL CHECKBOX -->
      <ng-container matColumnDef="priceListEmailSelect">
        <mat-header-cell
          *matHeaderCellDef
          fxLayoutAlign="center center"
          class="extra-small-cell"
          >{{ t('price-list') }}</mat-header-cell
        >
        <mat-cell
          *matCellDef="let email"
          fxLayoutAlign="center center"
          class="extra-small-cell"
        >
          <mat-checkbox
            data-testid="price-list-checkbox-testid"
            [checked]="
              isMainEmail(email) || priceListEmailsSelection.isSelected(email)
            "
            [disabled]="isMainEmail(email) || buyerHasAccount"
            (click)="$event.stopPropagation()"
            (change)="selectionChange(email, priceListEmailsSelection)"
          ></mat-checkbox>
        </mat-cell>
      </ng-container>

      <!-- PURCHASE ORDER EMAIL CHECKBOX -->
      <ng-container matColumnDef="purchaseOrderEmailSelect">
        <mat-header-cell
          *matHeaderCellDef
          fxLayoutAlign="center center"
          class="extra-small-cell"
          >{{ t('order') }}</mat-header-cell
        >
        <mat-cell
          *matCellDef="let email"
          fxLayoutAlign="center center"
          class="extra-small-cell"
        >
          <mat-checkbox
            [checked]="
              isMainEmail(email) ||
              purchaseOrderEmailsSelection.isSelected(email)
            "
            [disabled]="isMainEmail(email) || buyerHasAccount"
            (click)="$event.stopPropagation()"
            (change)="selectionChange(email, purchaseOrderEmailsSelection)"
          ></mat-checkbox>
        </mat-cell>
      </ng-container>

      <!-- INVOICING EMAIL CHECKBOX -->
      <ng-container matColumnDef="invoicingEmailSelect">
        <mat-header-cell
          *matHeaderCellDef
          fxLayoutAlign="center center"
          class="extra-small-cell"
          >{{ t('invoicing') }}</mat-header-cell
        >
        <mat-cell
          *matCellDef="let email"
          fxLayoutAlign="center center"
          class="extra-small-cell"
        >
          <mat-checkbox
            [checked]="
              isMainEmail(email) || invoicingEmailsSelection.isSelected(email)
            "
            [disabled]="isMainEmail(email) || buyerHasAccount"
            (click)="$event.stopPropagation()"
            (change)="selectionChange(email, invoicingEmailsSelection)"
          ></mat-checkbox>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="deleteEmail">
        <mat-header-cell
          *matHeaderCellDef
          fxFlex="45px"
          class="extra-small-cell"
        ></mat-header-cell>
        <mat-cell
          *matCellDef="let email"
          fxFlex="45px"
          class="extra-small-cell"
          [ngClass]="{ clickable: !isMainEmail(email) }"
        >
          <mat-icon
            *ngIf="!isMainEmail(email)"
            mat-icon-button
            data-testid="delete-icon-testid"
            (click)="onDeleteEmail(email)"
            >delete_outline</mat-icon
          >
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let email; columns: displayedColumns"></mat-row>
    </mat-table>

    <section align="end">
      <button
        data-testid="add-new-email-button-testid"
        mat-stroked-button
        type="button"
        [disabled]="buyerHasAccount || !isMainEmailValid()"
        (click)="onAddEmail()"
      >
        <div fxLayoutGap="10px" fxLayoutAlign="space-between center">
          <img src="assets/icons/icons_add.svg" />
          <span>{{ t('add-new-email') }}</span>
        </div>
      </button>
    </section>
  </section>
</ng-container>
