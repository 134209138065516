import { EntityFeedback } from '@arrivage-store/feedback/feedback-params.model';
import { VendorDelivery } from '@arrivage/model/dist/src/model';

interface DeliveryFeedback extends EntityFeedback {
  set: string;
}

export const DeliveryFeedback: DeliveryFeedback = {
  query: 'query_delivery',
  add: 'add_delivery',
  set: 'set_delivery',
  remove: 'remove_delivery',
  update: '',
  get_active_item: '',
};

export interface DeliveryDashboardMetrics {
  nbDeliveryZones: number;
  nbDeliveryZonesWithLessThanFiveDates: number;
  nbDeliveryZonesWithoutZones: number;
}

export interface DeliveryOption
  extends Pick<
    VendorDelivery,
    | 'minimumOrder'
    | 'deliveryFee'
    | 'freeDeliveryAbove'
    | 'minimumLeadTimeHours'
    | 'maximumLeadTimeHours'
  > {
  date: Date;
}

export interface WithDeliveryId {
  deliveryId: string;
}
