import { of } from 'rxjs';

import { Injectable } from '@angular/core';
import { FirebaseError } from '@angular/fire/app/firebase';

import {
  catchError,
  exhaustMap,
  filter,
  map,
  mergeMap,
  takeUntil,
  tap,
  withLatestFrom,
} from 'rxjs/operators';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { Logout } from '@arrivage-auth/store/auth.actions';
import { OrganizationSetupService } from '@arrivage-organization-setup/services/organization-setup.service';
import { reportError } from '@arrivage-sentry/report-error';
import { SnackbarService } from '@arrivage-snackbar/snackbar.service';
import { getOrganization } from '@arrivage-store/context/context.selectors';
import { EntityFeedback } from '@arrivage-store/feedback/feedback-params.model';

import * as organizationSetupActions from './organization-setup.actions';
import { OrganizationSetupState, State } from './organization-setup.state';

export const OrganizationSetupFeedBack: EntityFeedback = {
  query: 'query_organization_setup',
  update: 'update_organization_setup',
  add: '',
  remove: '',
  get_active_item: '',
};

@Injectable()
export class OrganizationSetupEffects {
  query$ = createEffect(() =>
    this.actions$.pipe(
      ofType(organizationSetupActions.query),
      withLatestFrom(this.store.select(getOrganization)),
      map(([, organization]) => organization),
      filter((organization) => !!organization),
      exhaustMap((organization) =>
        this.service.get(organization.id).pipe(
          takeUntil(this.actions$.pipe(ofType(Logout))),
          map((organizationSetup) => {
            return organizationSetupActions.querySuccess({
              organizationSetup: organizationSetup,
            });
          }),
          catchError((e: FirebaseError) => {
            reportError(e);
            return of(
              organizationSetupActions.queryFailure({ errorMessage: e.message })
            );
          })
        )
      )
    )
  );

  queryFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(organizationSetupActions.queryFailure),
        tap(() =>
          this.snackbarService.showError(OrganizationSetupFeedBack.query)
        )
      ),
    { dispatch: false }
  );

  update$ = createEffect(() =>
    this.actions$.pipe(
      ofType(organizationSetupActions.update),
      withLatestFrom(this.store.select(getOrganization)),
      mergeMap(([update, organization]) => {
        return of(
          this.service.update(organization.id, update.organizationSetup)
        ).pipe(
          map(() => {
            update.confirmation.resolve(
              OrganizationSetupService.ORGANIZATION_ACCOUNT_SETUP_ID
            );
            return organizationSetupActions.updateSuccess({
              organizationSetup: update.organizationSetup,
            });
          }),
          catchError((error) => {
            reportError(error);
            update.confirmation.reject(error);
            return of(
              organizationSetupActions.updateFailure({
                organizationSetup: update.organizationSetup,
                errorMessage: error,
              })
            );
          })
        );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private store: Store<State & OrganizationSetupState>,
    private service: OrganizationSetupService,
    private snackbarService: SnackbarService
  ) {}
}
