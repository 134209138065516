import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { AppUtilsModule } from 'src/app/app-utils.module';
import { MaterialModule } from 'src/app/material.module';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { TranslocoModule } from '@jsverse/transloco';

import { OptionCreateInputComponent } from './option-create-input.component';

@NgModule({
  declarations: [OptionCreateInputComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MaterialModule,
    AppUtilsModule,
    TranslocoModule,
    NgxMatSelectSearchModule,
  ],
  exports: [OptionCreateInputComponent],
})
export class OptionCreateInputModule {}
