import { AppUtilsModule } from 'src/app/app-utils.module';
import { MaterialModule } from 'src/app/material.module';

import { LayoutModule } from '@angular/cdk/layout';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { TranslocoModule } from '@jsverse/transloco';

import { TagsAutoInputModule } from '@arrivage-components/tags-auto-input/tags-auto-input.module';
import { FormIsDirtyModule } from '@arrivage-forms/form-is-dirty/form-is-dirty.module';

import { ContactInfoFormComponent } from './contact-info-form.component';

@NgModule({
  declarations: [ContactInfoFormComponent],
  imports: [
    CommonModule,
    MaterialModule,
    FlexLayoutModule,
    LayoutModule,
    FormsModule,
    ReactiveFormsModule,
    TranslocoModule,
    FormIsDirtyModule,
    AppUtilsModule,
    TagsAutoInputModule,
  ],
  exports: [ContactInfoFormComponent],
})
export class ContactInfoFormModule {}
