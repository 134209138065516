import { TranslocoModule } from '@jsverse/transloco';
import { MaterialModule } from 'src/app/material.module';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';

import { SystemMessageDialogComponent } from './system-message-dialog.component';
import { SystemMessageComponent } from './system-message.component';

@NgModule({
  declarations: [SystemMessageComponent, SystemMessageDialogComponent],
  imports: [CommonModule, MaterialModule, FlexLayoutModule, TranslocoModule],
  exports: [SystemMessageComponent, SystemMessageDialogComponent],
})
export class SystemMessageModule {}
