import { Pipe, PipeTransform } from '@angular/core';

import { Money } from '@arrivage/model/dist/src/model';

@Pipe({
  name: 'displayMoneyAmount',
})
export class DisplayMoneyAmountPipe implements PipeTransform {
  transform(value: Money, args?: any): number {
    return value ? Money.display(value).amount : 0;
  }
}
