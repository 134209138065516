import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AnalyticsFacade } from '@arrivage-analytics/api/analytics.facade';

import { OfferItem, Product, WithId } from '@arrivage/model/dist/src/model';

@Component({
  selector: 'app-product-and-format-info-dialog',
  templateUrl: './product-and-format-info-dialog.component.html',
  styleUrls: ['./product-and-format-info-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductAndFormatInfoDialogComponent implements OnInit {
  constructor(
    private analytics: AnalyticsFacade,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      product: Product & WithId;
      currentFormatId: string;
      offerItems: OfferItem[];
      isExtraSmallScreen: boolean;
      showPriceAndStock: boolean;
    }
  ) {}

  ngOnInit() {
    this.analytics.logClickOnFormatDetails();
  }
}
