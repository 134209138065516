import _ from 'lodash';

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'uppercaseFirst',
})
export class UppercaseFirstPipe implements PipeTransform {
  transform(value: any, ...args: any[]): any {
    return _.upperFirst(value);
  }
}
