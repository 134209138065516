import { createAction, props } from '@ngrx/store';

import { Confirmation, generateActions } from '@arrivage-store/generators';
import { Product, WithId } from '@arrivage/model/dist/src/model';

export const {
  query,
  connected,
  add,
  added,
  set,
  modified,
  update,
  remove,
  removed,
  queryFailure,
  addSuccess,
  addFailure,
  setSuccess,
  setFailure,
  updateSuccess,
  updateFailure,
  removeSuccess,
  removeFailure,
  getActiveItem,
  getActiveItemFailure,
  getActiveItemSuccess,
} = generateActions<Product>('Products');

export const updateWithPicture = createAction(
  '[Product - UpdateProduct With Picture] UpdateProduct With Picture',
  props<{
    record: {
      product: Partial<Product> & WithId;
      newPictureFile: File;
      filesToAdd: File[];
    };
    confirmation: Confirmation<string, any>;
  }>()
);

export const addWithPicture = createAction(
  '[Product - AddProduct With Picture] AddProduct With Picture',
  props<{
    record: { product: Product; newPictureFile: Blob; newFiles: Blob[] };
    confirmation: Confirmation<string, any>;
  }>()
);
