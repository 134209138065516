import { createReducer, Action, on } from '@ngrx/store';

import * as CreateOfferActions from './external-store.actions';
import { ExternalCustomerInfoState } from './external-store.state';

export const initialState: ExternalCustomerInfoState = {
  item: undefined,
  isLoading: false,
  error: false,
};

const ExternalCustomerInfoReducer = createReducer(
  initialState,
  on(
    CreateOfferActions.getExternalCustomerInfo,
    (state: ExternalCustomerInfoState) => {
      return {
        item: undefined,
        isLoading: true,
        error: false,
      };
    }
  ),
  on(
    CreateOfferActions.getExternalCustomerInfoSuccess,
    (state: ExternalCustomerInfoState, success) => {
      return {
        item: success.customerInfo,
        isLoading: false,
        error: false,
      };
    }
  ),
  on(
    CreateOfferActions.getExternalCustomerInfoFailure,
    (state: ExternalCustomerInfoState, error) => {
      return {
        item: undefined,
        isLoading: false,
        error: true,
      };
    }
  )
);

export function reducer(
  state: ExternalCustomerInfoState | undefined,
  action: Action
) {
  return ExternalCustomerInfoReducer(state, action);
}
