import { Pipe, PipeTransform } from '@angular/core';

import { LanguageService } from '@arrivage-language/service/language.service';
import { Format } from '@arrivage/model/dist/src/model';

@Pipe({
  name: 'displayFormat',
  pure: false,
})
export class DisplayFormatPipe implements PipeTransform {
  constructor(private languageService: LanguageService) {}

  transform(format: Format, args?: any): any {
    return Format.display(format, this.languageService.getCurrentLanguage());
  }
}
