<section
  fxLayout="column"
  class="layout"
  [class]="{
    'no-padding-top': isHeaderSticky
  }"
>
  <header
    [class]="{
      'sticky-header': isHeaderSticky
    }"
    #header
  >
    <ng-content select="app-header"></ng-content>
  </header>

  <main fxFlex fxLayout="column">
    <section fxFlex>
      <ng-content></ng-content>
    </section>
  </main>
</section>
