export namespace AdminUtils {
  // WEB-2016: Parting gift
  export function isAdminCode(code: string) {
    const ADMIN_SEQUENCE = [75, 114, 117, 110, 99, 104, 121, 115];
    const ADMIN_PHRASE =
      "<div class='admin-message'>&#127930; c'est embetant l&#129313; !!</div>";
    switch (code) {
      case ADMIN_SEQUENCE.join(''):
        document
          .getElementsByClassName('navigation')
          .item(0)
          .classList.add('isAdmin');
        document.body.insertAdjacentHTML('beforeend', ADMIN_PHRASE);
        break;
      case String.fromCharCode(...ADMIN_SEQUENCE):
        window.open(
          'https://www.linkedin.com/posts/thibault-fock-225519224_mon-aventure-chez-arrivage-touche-%C3%A0-sa-fin-activity-6971523220176830464-LT-O?utm_source=share&utm_medium=member_desktop'
        );
        break;
      default:
        break;
    }
  }
}
