import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';

import { EntitiesFacade } from '@arrivage-store/api/entities.facade';
import { State } from '@arrivage-store/state';
import { Pickup } from '@arrivage/model/dist/src/model';

import * as selectors from '../../vendor/store/pickup.selectors';
import * as actions from '../store/pickup.actions';

@Injectable({
  providedIn: 'root',
})
export class PickupFacade extends EntitiesFacade<Pickup, State> {
  constructor(store: Store<State>) {
    super(store, actions, selectors);
  }
}
