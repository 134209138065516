import { Action } from '@ngrx/store';
import { createReducer, on } from '@ngrx/store';

import * as userSettingsActions from './user-settings.actions';
import { UserSettingsState } from './user-settings.state';

export const initialState: UserSettingsState = {
  loaded: false,
  userSettings: undefined,
  error: undefined,
};

const userSettingsReducer = createReducer(
  initialState,
  on(userSettingsActions.query, (state) => {
    return {
      ...state,
      loaded: false,
      userSettings: undefined,
      error: undefined,
    };
  }),
  on(userSettingsActions.querySuccess, (state, { userSettings }) => {
    return {
      ...state,
      loaded: true,
      userSettings: userSettings,
      error: undefined,
    };
  }),
  on(userSettingsActions.queryFailure, (state, { errorMessage }) => {
    return {
      ...state,
      loaded: false,
      userSettings: undefined,
      error: errorMessage,
    };
  }),
  on(userSettingsActions.update, (state) => {
    return {
      ...state,
      error: undefined,
      updateFailure: undefined,
    };
  }),
  on(
    userSettingsActions.updateFailure,
    (state, { userSettings, errorMessage }) => {
      return {
        ...state,
        error: errorMessage,
        updateFailure: userSettings,
      };
    }
  )
);

export function reducer(state: UserSettingsState | undefined, action: Action) {
  return userSettingsReducer(state, action);
}
