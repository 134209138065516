import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DirtyCheckService {
  private _isDirty = false;

  constructor() {}

  get isDirty(): boolean {
    return this._isDirty;
  }

  markDirtyAs(value: boolean) {
    this._isDirty = value;
  }

  resetDirtiness() {
    this._isDirty = false;
  }
}
