import { Component, Input } from '@angular/core';

export type SystemMessageType = 'success' | 'info' | 'warning' | 'error';

export interface Linkable {
  name: string;
  link: (args?: { [arg: string]: any }) => void;
}

@Component({
  selector: 'app-system-message',
  template: ` <mat-card
    appearance="raised"
    *ngIf="visible"
    fxLayout="row"
    fxLayoutGap="5rem"
    fxLayoutAlign="space-between start"
    class="bold"
    class="system-message"
    [class.no-border]="showBorder === false"
    [class.alert-success]="type === 'success' && showBorder === true"
    [class.text-color-success]="type === 'success'"
    [class.alert-info]="type === 'info' && showBorder === true"
    [class.text-color-info]="type === 'info'"
    [class.alert-warning]="type === 'warning' && showBorder === true"
    [class.text-color-warning]="type === 'warning'"
    [class.alert-error]="type === 'error' && showBorder === true"
    [class.text-color-error]="type === 'error'"
  >
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="2rem">
      <ng-container [ngSwitch]="type">
        <mat-icon *ngSwitchCase="'success'" class="icon"
          >check_circle_outline</mat-icon
        >
        <mat-icon *ngSwitchCase="'info'" class="material-icons-outlined icon"
          >info</mat-icon
        >
        <mat-icon *ngSwitchCase="'warning'" class="icon"
          >error_outline</mat-icon
        >
        <mat-icon *ngSwitchCase="'error'" class="icon">error_outline</mat-icon>
      </ng-container>
      <div>
        <p class="bold text-m message keep-text-formatting">{{ message }}</p>
        <p class="text-m text-light keep-text-formatting">{{ description }}</p>
        <div
          *ngIf="linkable"
          fxLayoutAlign="start center"
          class="clickable"
          (click)="linkable.link()"
        >
          <mat-icon>arrow_right</mat-icon>
          <a>
            {{ linkable.name }}
          </a>
        </div>
      </div>
    </div>
    <div *ngIf="removable">
      <button mat-icon-button type="button" (click)="remove()">
        <mat-icon>clear</mat-icon>
      </button>
    </div>
  </mat-card>`,
  styleUrls: ['./system-message.component.scss'],
})
export class SystemMessageComponent {
  visible = true;

  @Input()
  type: SystemMessageType = 'success';

  @Input()
  message: string;

  @Input()
  description: string;

  @Input()
  showBorder = true;

  @Input()
  removable = false;

  @Input()
  linkable: Linkable;

  constructor() {}

  remove() {
    this.visible = false;
  }
}
