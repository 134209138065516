import { createFeatureSelector, createSelector } from '@ngrx/store';

import { TransactionDocumentsMetrics } from '@arrivage-components/metric/metrics.model';
import { InvoiceStatus, Money } from '@arrivage/model/dist/src/model';

import {
  createSelectors as createCommonInvoiceSelectors,
  extractInvoicesMetrics,
  InvoiceSelectors,
} from '../../common/store/generator';
import { InvoicesUtils } from '../../common/util/invoices.utils';
import { VendorInvoicesState } from './vendor-invoices.state';

export const getInvoiceState =
  createFeatureSelector<VendorInvoicesState>('vendorInvoices');

export const VendorInvoicesSelectors: InvoiceSelectors =
  createCommonInvoiceSelectors(getInvoiceState);

export const selectScheduledDeliveriesInvoices = (organizationId: string) =>
  createSelector(VendorInvoicesSelectors.selectLoadedByDateRange, (invoices) =>
    invoices.filter(
      (invoice) =>
        invoice.vendor.organizationId === organizationId &&
        invoice.status !== InvoiceStatus.CANCELED
    )
  );

export const selectDraft = createSelector(
  VendorInvoicesSelectors.selectAll,
  (invoices) =>
    invoices.filter((invoice) => invoice.status === InvoiceStatus.DRAFT)
);

export const selectUnpaidLate = createSelector(
  VendorInvoicesSelectors.selectAll,
  (invoices) => invoices.filter((invoice) => InvoicesUtils.isLate(invoice))
);

export const selectDraftMetrics = createSelector(
  selectDraft,
  extractInvoicesMetrics
);

export const selectInvoiceDashboardMetrics = createSelector(
  selectDraft,
  selectUnpaidLate,
  VendorInvoicesSelectors.selectUnpaidMetrics,
  (draftInvoices, unpaidLateInvoices, unpaidMetrics) => {
    const totalDraft = Money.sum(draftInvoices.map((i) => i.total));
    const totalUnpaidLate = Money.sum(unpaidLateInvoices.map((i) => i.total));
    const unpaidInvoices: TransactionDocumentsMetrics = {
      nb: unpaidMetrics.nb + draftInvoices.length,
      total: Money.sum([unpaidMetrics.total, totalDraft]),
    };

    return {
      nbUnpaidInvoices: unpaidInvoices,
      nbUnpaidLateInvoices: {
        nb: unpaidLateInvoices.length,
        total: totalUnpaidLate,
      },
      nbUnsentInvoices: {
        nb: draftInvoices.length,
        total: totalDraft,
      },
    };
  }
);

export const isSendingInvoice = createSelector(
  getInvoiceState,
  (s) => s.sendingInvoice
);
