import { createReducer, on } from '@ngrx/store';

import * as AuthActions from './auth.actions';
import { defaultState } from './auth.state';

export const _authReducer = createReducer(
  defaultState,
  on(AuthActions.ResetError, (state) => {
    return Object.assign({}, state, {
      errorCode: undefined,
    });
  }),
  on(
    AuthActions.Login,
    AuthActions.Signup,
    AuthActions.GoogleSignIn,
    (state) => {
      return Object.assign({}, state, {
        isSignInInProgress: true,
        errorCode: undefined,
        triggeredlogout: false,
      });
    }
  ),
  on(AuthActions.SignInSuccess, (state) => {
    return Object.assign({}, state, {
      isSignInInProgress: false,
      errorCode: undefined,
    });
  }),
  on(
    AuthActions.LoginFailure,
    AuthActions.SignupFailure,
    AuthActions.GoogleSignInFailure,
    (state, action) => {
      return Object.assign({}, state, {
        isSignInInProgress: false,
        errorCode: action.errorCode,
      });
    }
  ),
  on(AuthActions.Logout, (state, action) => {
    return Object.assign({}, state, { triggeredlogout: true });
  }),
  on(AuthActions.SetAuthUser, (state, action) => {
    return Object.assign({}, state, { user: action.user });
  }),
  on(AuthActions.UnsetAuthUser, (state, action) => {
    return Object.assign({}, state, { user: undefined });
  })
);

export function authReducer(state, action) {
  return _authReducer(state, action);
}
