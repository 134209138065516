import { AppUtilsModule } from 'src/app/app-utils.module';
import { MaterialModule } from 'src/app/material.module';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';

import { TranslocoModule } from '@jsverse/transloco';
import { TranslocoLocaleModule } from '@jsverse/transloco-locale';

import { AddressFormModule } from '@arrivage-components/address-form/address-form.module';
import { CommunicationPreferenceTableModule } from '@arrivage-components/communication-preference-table/communication-preference-table.module';
import { ContactInfoFormModule } from '@arrivage-components/contact-info-forms/contact-info-form.module';
import { DateListModule } from '@arrivage-components/date-list/date-list.module';
import { SystemMessageModule } from '@arrivage-components/system-message/system-message.module';
import { FormIsDirtyModule } from '@arrivage-forms/form-is-dirty/form-is-dirty.module';
import { I18nModule } from '@arrivage-i18n/i18n.module';
import { LanguageSelectorModule } from '@arrivage-language/components/selector/language-selector.module';
import { CurrencySymbolPipeModule } from '@arrivage-pipes/currency-symbol-pipe/currency-symbol-pipe.module';
import { DisplayMoneyAmountPipeModule } from '@arrivage-pipes/display-money-amount-pipe/display-money-amount-pipe.module';
import { TranslationPipeModule } from '@arrivage-pipes/translation-pipe/translation-pipe.module';

import { AddTaxDialogComponent } from './add-tax-dialog/add-tax-dialog.component';
import { OrganizationSettingsViewComponent } from './organization-settings-view/organization-settings-view.component';
import { TaxSettingsFormComponent } from './tax-settings-form/tax-settings-form.component';

@NgModule({
  declarations: [
    OrganizationSettingsViewComponent,
    AddTaxDialogComponent,
    TaxSettingsFormComponent,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    TranslationPipeModule,
    AddressFormModule,
    ContactInfoFormModule,
    TranslocoModule,
    DisplayMoneyAmountPipeModule,
    TranslocoLocaleModule,
    CurrencySymbolPipeModule,
    DateListModule,
    I18nModule,
    LanguageSelectorModule,
    FormIsDirtyModule,
    AppUtilsModule,
    SystemMessageModule,
    CommunicationPreferenceTableModule,
  ],
  exports: [OrganizationSettingsViewComponent],
})
export class OrganizationSetttingsComponentModule {}
