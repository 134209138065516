import { Observable, Subscription } from 'rxjs';

import { Location, registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
} from '@angular/router';

import {
  distinctUntilChanged,
  filter,
  map,
  pairwise,
  startWith,
  take,
} from 'rxjs/operators';

import { fadeAnimation } from '@arrivage-animations/router-transition';
import { NetworkStatusService } from '@arrivage-services/network-status.service';
import {
  ScreenSize,
  ScreenSizeService,
} from '@arrivage-services/screen-size.service';
import { SnackbarService } from '@arrivage-snackbar/snackbar.service';
import { SoftwareUpdateService } from '@arrivage-sw-update/software-update.service';

import { AuthFacade } from './auth/api/auth.facade';
import { AuthService } from './auth/services/auth.service';
import { LanguageService } from './language/service/language.service';
import { OrganizationSettingsFacade } from './organization-settings/api/organization-settings.facade';
import { AuthenticationSequenceInitialUrlService } from './services/authentication-sequence-initial-url.service';
import { LocalStorageService } from './services/local-storage.service';
import { MainPageLoadService } from './services/main-page-load.service';

registerLocaleData(localeFr, 'fr');

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [fadeAnimation],
})
export class AppComponent implements OnInit, OnDestroy {
  private routerSubscription: Subscription;
  private settingsSubscription: Subscription;

  isLoading$: Observable<boolean>;
  isConnected$: Observable<[boolean, boolean]>;
  screenSize$: Observable<ScreenSize>;

  constructor(
    private router: Router,
    private location: Location,
    private settingsFacade: OrganizationSettingsFacade,
    private authFacade: AuthFacade,
    private screenSizeService: ScreenSizeService,
    private localStorageService: LocalStorageService,
    private authService: AuthService,
    private mainPageLoadService: MainPageLoadService,
    private languageService: LanguageService,
    private networkStatusService: NetworkStatusService,
    private snackbarService: SnackbarService,
    private softwareUpdateService: SoftwareUpdateService,
    private initialUrlService: AuthenticationSequenceInitialUrlService
  ) {
    // private option not yet exposed for public use
    router['canceledNavigationResolution'] = 'computed';
  }

  ngOnInit() {
    this.authFacade.monitorForcedLogout();
    this.softwareUpdateService.initialize();
    this.languageService.initFromLocalStorage();

    // store the initial page URL
    this.initialUrlService.setInitialUrl(this.location.path());

    // navigate temporarily to root so guards on the initial URL will be executed
    // when navigating to the initial URL after the context is loaded
    this.router.navigate(['/']);

    this.authService.initAuthListener();
    this.localStorageService.startSync();

    this.isLoading$ = this.mainPageLoadService.isLoading();
    this.isConnected$ = this.networkStatusService.isConnected().pipe(
      startWith(true),
      pairwise(),
      map(([statusBefore, statusAfter]) => {
        if (!statusBefore && statusAfter) {
          this.snackbarService.showSnackbar(
            'network-status.online',
            'wifi',
            SnackbarService.DEFAULT_DURATION,
            ['snackbar-success']
          );
        }

        if (!statusAfter) {
          this.snackbarService.showSnackbar(
            'network-status.offline',
            'wifi_off',
            null,
            ['snackbar-error']
          );
        }

        return [statusBefore, statusAfter];
      })
    );
    this.screenSize$ = this.screenSizeService.getScreenSizeObserver();

    this.routerSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.mainPageLoadService.start();
      } else if (
        event instanceof NavigationCancel ||
        event instanceof NavigationEnd ||
        event instanceof NavigationError
      ) {
        this.mainPageLoadService.end();
      }
    });

    this.authService.authenticatedUser$
      .pipe(take(1))
      .toPromise()
      .then((user) => {
        if (user) {
          this.localStorageService.loadContextFromLocalStorage(user.uid);
        } else {
          this.router.navigateByUrl(this.initialUrlService.getInitialUrl());
        }
      });

    this.settingsSubscription = this.settingsFacade
      .getOrganizationSettings()
      .pipe(
        filter((s) => !!s && !!s.preferences?.language),
        map((s) => s.preferences.language),
        distinctUntilChanged()
      )
      .subscribe((language) => this.languageService.setLanguage(language));
  }

  ngOnDestroy() {
    this.localStorageService.stopSync();
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
    if (this.settingsSubscription) {
      this.settingsSubscription.unsubscribe();
    }
  }
}
