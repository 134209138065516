import { createAction, props } from '@ngrx/store';

import { OrganizationAuthorizations } from '@arrivage/model/dist/src/model';

const featureName = 'OrganizationAuthorizations';

export const getAuthorizations = createAction(
  `[${featureName}] getAuthorizations`,
  props<{
    id: string;
  }>()
);

export const getAuthorizationsSuccess = createAction(
  `[${featureName}] getAuthorizationsSuccess`,
  props<{
    authorizations: OrganizationAuthorizations;
  }>()
);

export const getAuthorizationsFailure = createAction(
  `[${featureName}] getAuthorizationsFailure`,
  props<{
    error: any;
  }>()
);
