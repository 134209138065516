import { createFeatureSelector } from '@ngrx/store';

import { createSelectors } from '@arrivage-store/generators';
import { Offer } from '@arrivage/model/dist/src/model';

import { adapter } from './offers.reducer';
import { State, VendorOfferState } from './offers.state';

export const getVendorOfferState =
  createFeatureSelector<VendorOfferState>('vendorOffer');

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
  connecting,
  connected,
  connectState,
  queryFailure,
  addFailure,
  setFailure,
  updateFailure,
  removeFailure,
  getById,
  selectActiveItem,
  selectActiveItemState,
  isLoadingActiveItem,
} = createSelectors<Offer, State>(adapter, getVendorOfferState);
