import _ from 'lodash';

import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';

import {
  SendPickSplitListDialogData,
  SendPickSplitListResponseData,
} from '@arrivage-scheduled-deliveries/common/model/scheduled-deliveries.model';
import { ScheduledDeliveriesUtils } from '@arrivage-scheduled-deliveries/common/utils/scheduled-deliveries.utils';
import { ScheduledDeliveriesPdfType } from '@arrivage-services/pdf/model/pdf.model';
import { WithIsSelected } from '@arrivage-util/selection.model';
import { atLeastOneSelectedMailRequired } from '@arrivage-validators/custom-validators';
import { Producer, WithId } from '@arrivage/model/dist/src/model';

@Component({
  selector: 'app-send-pick-split-list-dialog',
  templateUrl: './send-pick-split-list-dialog.component.html',
  styleUrls: ['./send-pick-split-list-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SendPickSplitListDialogComponent implements OnInit {
  readonly DISPLAYED_COLUMNS = ['select', 'name', 'email'];
  readonly ScheduledDeliveriesPdfType = ScheduledDeliveriesPdfType;
  // This will define the order of the pdf types in the dialog and during the generation
  readonly SORTED_SCHEDULED_DELIVERIES_PDF_TYPE =
    ScheduledDeliveriesUtils.getSortedDeliveriesPdfTypes();

  producerEmailForm: FormGroup = this.fb.group({});
  deliveryMessageForm: FormGroup = this.fb.group({
    message: [null],
    includedDocuments: [
      this.SORTED_SCHEDULED_DELIVERIES_PDF_TYPE,
      Validators.required,
    ],
  });

  dataSource: MatTableDataSource<Producer & WithId> = new MatTableDataSource(
    []
  );

  static openDialog(
    dialog: MatDialog,
    isSmallScreen: boolean,
    data: SendPickSplitListDialogData
  ) {
    return dialog.open<
      SendPickSplitListDialogComponent,
      SendPickSplitListDialogData,
      SendPickSplitListResponseData
    >(SendPickSplitListDialogComponent, {
      panelClass: isSmallScreen ? 'full-dialog' : '',
      width: '600px',
      minHeight: '434px',
      data: data,
    });
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: SendPickSplitListDialogData,
    private dialogRef: MatDialogRef<
      SendPickSplitListDialogComponent,
      SendPickSplitListResponseData
    >,
    private fb: FormBuilder
  ) {
    this.dataSource.data = data.producers;
  }

  get isProducerEmailFormValid(): boolean {
    return this.producerEmailForm.valid;
  }

  get isDeliveryMessageFormValid(): boolean {
    return this.deliveryMessageForm.valid;
  }

  get includedDocumentsControl() {
    return this.deliveryMessageForm.get('includedDocuments');
  }

  ngOnInit(): void {
    this.initializeForm();
  }

  trackByProducerId(index: number, producer: Producer & WithId) {
    return producer.id;
  }

  sendPickSplitListToProducers() {
    if (!this.isProducerEmailFormValid || !this.isDeliveryMessageFormValid)
      return;
    const response: SendPickSplitListResponseData = {
      producers: this.getResponseRecord(),
      deliveryMessage: this.deliveryMessageForm.get('message').value,
      includedDocuments: this.includedDocumentsControl.value,
    };

    this.dialogRef.close(response);
  }

  toggleCheckbox(producerId: string): void {
    const checkboxControl = this.producerEmailForm.get(
      `${producerId}.isSelected`
    );
    if (checkboxControl) {
      checkboxControl.setValue(!checkboxControl.value);
    }
  }

  masterToggle() {
    const newValue = !this.isAllSelected();
    this.data.producers.forEach((producer) => {
      this.producerEmailForm
        .get([producer.id, 'isSelected'])
        .setValue(newValue);
    });
  }

  isAllSelected() {
    return _.every(this.getResponseRecord(), ['isSelected', true]);
  }

  isSomeSelected() {
    return _.some(this.getResponseRecord(), ['isSelected', true]);
  }

  private getResponseRecord(): (Producer & WithId & WithIsSelected)[] {
    return this.data.producers.map((producer) => {
      const updatedProducer = {
        ...producer,
        isSelected: this.producerEmailForm.get([producer.id, 'isSelected'])
          .value as boolean,
      };
      const emailValue = this.producerEmailForm.get([producer.id, 'email'])
        .value as string;
      updatedProducer.email = _.isEmpty(emailValue) ? null : emailValue;

      return updatedProducer;
    });
  }

  private initializeForm() {
    this.data.producers.map((producer) => {
      if (producer) {
        const subForm = this.fb.group({
          email: [producer.email, [Validators.email]],
          isSelected: [true],
        });

        this.producerEmailForm.addControl(producer.id, subForm);
      }

      this.producerEmailForm.addValidators(atLeastOneSelectedMailRequired);
    });
  }
}
