<ng-container *transloco="let t; read: 'components.yes-no-dialog'">
  <section>
    <h2 class="mat-dialog-title space-bottom text-l">
      {{ data.title }}
    </h2>

    <div class="mat-typography mat-dialog-content text-align-justify">
      <p class="text-subtitle-1 light">{{ data.message }}</p>
    </div>

    <mat-dialog-actions align="end">
      @if (data.canCancel) {
        <button
          data-testid="cancel-button-test-id"
          mat-stroked-button
          color="warn"
          mat-dialog-close
        >
          {{ t('cancel-button') }}
        </button>
      }

      <!-- If we can't cancel, return null so dialog response can be binary (truthy/falsy) -->
      <button
        data-testid="no-button-test-id"
        mat-stroked-button
        [mat-dialog-close]="data.canCancel ? 'no' : null"
      >
        {{ t('no-button') }}
      </button>
      <button
        data-testid="yes-button-test-id"
        mat-raised-button
        color="accent"
        mat-dialog-close="yes"
      >
        {{ t('yes-button') }}
      </button>
    </mat-dialog-actions>
  </section>
</ng-container>
