import { NgModule } from '@angular/core';

import {
  routerReducer,
  StoreRouterConnectingModule,
  NavigationActionTiming,
  RouterState,
  MinimalRouterStateSerializer,
} from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';

@NgModule({
  imports: [
    StoreModule.forFeature('router', routerReducer),
    StoreRouterConnectingModule.forRoot({
      routerState: RouterState.Minimal,
      serializer: MinimalRouterStateSerializer,
      navigationActionTiming: NavigationActionTiming.PostActivation,
    }),
  ],
})
export class RouterStoreModule {}
