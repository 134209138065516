import { Injectable } from '@angular/core';

import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { SnackbarService } from '@arrivage-snackbar/snackbar.service';
import { EntityFeedback } from '@arrivage-store/feedback/feedback-params.model';
import { createBaseEffects } from '@arrivage-store/generators';

import { PickupService } from '../services/pickup.service';
import * as pickupActions from './pickup.actions';
import * as pickupSelectors from './pickup.selectors';
import { State } from './pickup.state';

export const PickupFeedback: EntityFeedback = {
  add: 'add_pickup',
  update: 'update_pickup',
  remove: 'remove_pickup',
  //error
  query: 'query_pickup',
  get_active_item: 'get_active_pickup',
};

@Injectable()
export class PickupEffects {
  query$;
  add$;
  set$;
  update$;
  remove$;
  getActiveItem$;
  displayQueryFailure$;
  displayGetActiveItemFailure$;

  constructor(
    private actions$: Actions,
    private store: Store<State>,
    private service: PickupService,
    private snackbarService: SnackbarService
  ) {
    ({
      query: this.query$,
      add: this.add$,
      set: this.set$,
      update: this.update$,
      remove: this.remove$,
      getActiveItem: this.getActiveItem$,
      displayQueryFailure: this.displayQueryFailure$,
      displayGetActiveItemFailure: this.displayGetActiveItemFailure$,
    } = createBaseEffects(
      this.actions$,
      this.store,
      pickupActions,
      pickupSelectors,
      this.service,
      PickupFeedback,
      this.snackbarService
    ));
  }
}
