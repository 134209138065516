import { createFeatureSelector, createSelector } from '@ngrx/store';

import { createSelectors } from '@arrivage-store/generators';
import {
  LinkedProducer,
  Producer,
  toLinkedProducerWithId,
  WithId,
} from '@arrivage/model/dist/src/model';

import { adapter } from './producers.reducer';
import { ProducersState, State } from './producers.state';

export const getProducerState =
  createFeatureSelector<ProducersState>('producers');

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
  connecting,
  connected,
  connectState,
  queryFailure,
  addFailure,
  setFailure,
  updateFailure,
  removeFailure,
  getById,
  selectActiveItem,
  isLoadingActiveItem,
  selectActiveItemState,
} = createSelectors<Producer, State>(adapter, getProducerState);

/**
 * This selector get all producers that have a relationshipId and organizationId and
 * cast them to LinkedProducer.
 *
 * The only difference between Producer and LinkedProducer is that relationshipId and
 * organizationId are optional in Producer and required in LinkedProducer.
 *
 * So the map operator is used to inform TypeScript that the Producer object is a viable
 * LinkedProducer.
 */
export const selectAllLinkedProducers = createSelector<
  State,
  (Producer & WithId)[],
  (LinkedProducer & WithId)[]
>(selectAll, (producers) => {
  return producers
    .map(toLinkedProducerWithId)
    .filter((producer) => producer !== null);
});
