import { EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on, Action } from '@ngrx/store';

import { Organization, WithId } from '@arrivage/model/dist/src/model';

import {
  getAuthorizations,
  getAuthorizationsFailure,
  getAuthorizationsSuccess,
} from './organization-authorizations.actions';
import { OrganizationAuthorizationsState } from './organization-authorizations.state';

export const adapter: EntityAdapter<
  Organization & WithId
> = createEntityAdapter<Organization & WithId>();

const initialState: OrganizationAuthorizationsState = {
  loaded: false,
  error: undefined,
  authorizations: undefined,
};

const organizationAuthorizationsReducer = createReducer(
  initialState,
  on(getAuthorizations, (state: OrganizationAuthorizationsState) => {
    return {
      ...state,
      loaded: false,
      error: undefined,
      authorizations: undefined,
    };
  }),
  on(
    getAuthorizationsSuccess,
    (state: OrganizationAuthorizationsState, authorizations) => {
      return {
        ...state,
        loaded: true,
        error: undefined,
        authorizations: authorizations.authorizations,
      };
    }
  ),
  on(
    getAuthorizationsFailure,
    (state: OrganizationAuthorizationsState, error) => {
      return {
        ...state,
        loaded: false,
        error: error.error,
        authorizations: undefined,
      };
    }
  )
);

export function reducer(
  state: OrganizationAuthorizationsState | undefined,
  action: Action
) {
  return organizationAuthorizationsReducer(state, action);
}
