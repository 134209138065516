import { TranslocoModule } from '@jsverse/transloco';
import { ImageCropperModule } from 'ngx-image-cropper';
import { AppUtilsModule } from 'src/app/app-utils.module';
import { MaterialModule } from 'src/app/material.module';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';

import { PictureCropperEditorDialogComponent } from './picture-cropper-editor-dialog.component';

@NgModule({
  declarations: [PictureCropperEditorDialogComponent],
  imports: [
    CommonModule,
    MaterialModule,
    FlexLayoutModule,
    TranslocoModule,
    ImageCropperModule,
    AppUtilsModule,
  ],
  exports: [PictureCropperEditorDialogComponent],
})
export class PictureCropperEditorDialogModule {}
