import { createFeatureSelector, createSelector } from '@ngrx/store';

import { OrganizationAuthorizationsState } from './organization-authorizations.state';

export const getOrganizationAuthorizationsState = createFeatureSelector<OrganizationAuthorizationsState>(
  'organizationAuthorizations'
);

export const getAuthorizations = createSelector(
  getOrganizationAuthorizationsState,
  (s) => s.authorizations
);
