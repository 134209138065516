import { createAction, props } from '@ngrx/store';

import { PremiumAccount } from '@arrivage/model/dist/src/model';

const featureName = 'PremiumAccount';

export const getPremiumAccount = createAction(
  `[${featureName}] getPremiumAccount`,
  props<{
    id: string;
  }>()
);

export const getPremiumAccountSuccess = createAction(
  `[${featureName}] getPremiumAccountSuccess`,
  props<{
    premiumAccount: PremiumAccount;
  }>()
);

export const getPremiumAccountFailure = createAction(
  `[${featureName}] getPremiumAccountFailure`,
  props<{
    error: any;
  }>()
);
