import { createFeatureSelector, createSelector } from '@ngrx/store';

import { PremiumAccountState } from './premium-accounts.state';

export const getPremiumAccountState =
  createFeatureSelector<PremiumAccountState>('premiumAccount');

export const getPremiumAccount = createSelector(
  getPremiumAccountState,
  (s) => s.premiumAccount
);
