import { EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { createReducer, Action } from '@ngrx/store';

import {
  createInitialState,
  createEntityReducer,
} from '@arrivage-store/generators';
import { Offer, WithId } from '@arrivage/model/dist/src/model';

import * as OfferActions from './offers.actions';
import { VendorOfferState } from './offers.state';

export const adapter: EntityAdapter<Offer & WithId> = createEntityAdapter<
  Offer & WithId
>();

export const initialState: VendorOfferState = createInitialState(adapter);

const vendorOfferReducer = createReducer(
  initialState,
  ...createEntityReducer(OfferActions, adapter)
);

export function reducer(state: VendorOfferState | undefined, action: Action) {
  return vendorOfferReducer(state, action);
}
