import { Injectable } from '@angular/core';

import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { createBasePurchaseOrderEffects } from '@arrivage-purchase-orders/common/store/base-purchase-orders.effects';
import { CustomerPurchaseOrderService } from '@arrivage-purchase-orders/customer/services/customer-purchase-order.service';
import { SnackbarService } from '@arrivage-snackbar/snackbar.service';

import { CustomerPurchaseOrdersActions } from './customer-purchase-orders.actions';
import { CustomerPurchaseOrdersSelectors } from './customer-purchase-orders.selectors';
import { State } from './customer-purchase-orders.state';

@Injectable()
export class CustomerPurchaseOrdersEffects {
  query$;
  displayQueryFailure$;

  add$;
  update$;
  remove$;

  getActiveItem$;
  displayGetActiveItemFailure$;

  queryByDateRange$;
  queryByDateRangeGuard$;
  displayQueryByDateRangeFailure$;

  constructor(
    private actions$: Actions,
    private store: Store<State>,
    private service: CustomerPurchaseOrderService,
    private snackbarService: SnackbarService
  ) {
    ({
      query: this.query$,
      displayQueryFailure: this.displayQueryFailure$,

      add: this.add$,
      update: this.update$,
      remove: this.remove$,

      getActiveItem: this.getActiveItem$,
      displayGetActiveItemFailure: this.displayGetActiveItemFailure$,

      queryByDateRange: this.queryByDateRange$,
      queryByDateRangeGuard: this.queryByDateRangeGuard$,
      displayQueryByDateRangeFailure: this.displayQueryByDateRangeFailure$,
    } = createBasePurchaseOrderEffects(
      this.actions$,
      this.store,
      CustomerPurchaseOrdersActions,
      CustomerPurchaseOrdersSelectors,
      this.service,
      this.snackbarService
    ));
  }
}
