import { Injectable } from '@angular/core';

import { tap, debounceTime } from 'rxjs/operators';

import { createEffect, Actions, ofType } from '@ngrx/effects';

import { SnackbarService } from '@arrivage-snackbar/snackbar.service';

import * as actions from './feedback.actions';

@Injectable()
export class FeedbackEffect {
  constructor(
    private actions$: Actions,
    private snackbarService: SnackbarService
  ) {}

  showFeedback$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actions.showFeedback),
        debounceTime(500),
        tap((feedback) => {
          if (feedback.success) {
            return this.snackbarService.showSuccess(feedback.feedback);
          }
          return this.snackbarService.showError(feedback.feedback);
        })
      ),
    { dispatch: false }
  );
}
