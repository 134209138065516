import { Injectable } from '@angular/core';
import { Firestore, collection, doc } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root',
})
export class IdService {
  constructor(protected firestore: Firestore) {}

  createId(): string {
    return doc(collection(this.firestore, '_')).id;
  }
}
