import { Component, Inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { PriceList } from '@arrivage/model/dist/src/model';

@Component({
  selector: 'app-add-price-list-dialog',
  templateUrl: './add-price-list-dialog.component.html',
  styleUrls: ['./add-price-list-dialog.component.scss'],
})
export class AddPriceListDialogComponent {
  form: UntypedFormGroup = this.fb.group({
    name: [null, Validators.required],
  });

  constructor(
    private fb: UntypedFormBuilder,
    public dialogRef: MatDialogRef<AddPriceListDialogComponent, PriceList>,
    @Inject(MAT_DIALOG_DATA)
    public data: PriceList
  ) {
    if (data) {
      this.form.get('name').setValue(data.name);
    }
  }

  onOkClick(): void {
    if (this.form.valid) {
      const priceList: PriceList = {
        name: this.form.get('name').value,
        publicVisibility: this.data ? this.data.publicVisibility : [],
      };
      this.dialogRef.close(priceList);
    }
  }
}
