import { createAction, props } from '@ngrx/store';

import { Confirmation, generateActions } from '@arrivage-store/generators';
import { Conversation } from '@arrivage/model/dist/src/model';

const featureName = 'Conversations';

export const {
  query,
  connected,
  add,
  added,
  set,
  modified,
  update,
  remove,
  removed,
  queryFailure,
  addSuccess,
  addFailure,
  setSuccess,
  setFailure,
  updateSuccess,
  updateFailure,
  removeSuccess,
  removeFailure,
  getActiveItem,
  getActiveItemFailure,
  getActiveItemSuccess,
} = generateActions<Conversation>(featureName);

export const updateConversationViewedByInfo = createAction(
  `[${featureName}] Update Conversation viewedByInfo`,
  props<{
    conversationId: string;
    confirmation: Confirmation<string, any>;
  }>()
);

export const updateConversationViewedByInfoSuccess = createAction(
  `[${featureName}] Update Conversation viewedByInfo Success`,
  props<{ conversationId: string }>()
);

export const updateConversationViewedByInfoFailure = createAction(
  `[${featureName}]Update Conversation viewedByInfo Failure`,
  props<{ error: string }>()
);
