import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { FEATURE_NAME } from './visible-offers-store.constants';
import { VisibleOffersEffects } from './visible-offers.effects';
import { reducer } from './visible-offers.reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(FEATURE_NAME, reducer),
    EffectsModule.forFeature([VisibleOffersEffects]),
  ],
})
export class VisibleOffersStoreModule {}
