import { Observable } from 'rxjs';

import { Injectable } from '@angular/core';
import {
  Firestore,
  limit,
  orderBy,
  Timestamp,
  where,
} from '@angular/fire/firestore';

import { BaseService, PathSpec } from '@arrivage-services/base.service';
import {
  EntityChangeAction,
  OrganizationEntityService,
} from '@arrivage-services/organization-entity.service';
import { WithId } from '@arrivage/model/dist/src/model';
import { Notification } from '@arrivage/model/dist/src/model/notification';
import { CollectionNames } from '@arrivage/model/dist/src/utils';

@Injectable({ providedIn: 'root' })
export class NotificationService
  extends BaseService<Notification>
  implements OrganizationEntityService<Notification>
{
  static readonly NOTIFICATIONS_COLLECTION =
    CollectionNames.NOTIFICATION_COLLECTION;
  static readonly MAX_NOTIFICATIONS = 20;

  static convertTimestampFields(
    firebaseNotification: any
  ): Notification & WithId {
    if (!firebaseNotification) {
      return undefined;
    }

    const notification: Notification & WithId = {
      ...firebaseNotification,
    };

    if (firebaseNotification.date) {
      notification.date = (
        firebaseNotification.date as any as Timestamp
      ).toDate();
    }

    return notification;
  }

  constructor(firestore: Firestore) {
    super(firestore);
  }

  get(
    organizationId: string,
    recordId: string
  ): Observable<Notification & WithId> {
    throw new Error('Method not implemented.');
  }

  private pathSpec(): PathSpec[] {
    return [{ collection: NotificationService.NOTIFICATIONS_COLLECTION }];
  }

  connect(
    organizationId: string
  ): Observable<EntityChangeAction<Notification>[]> {
    return this._connect(
      this.pathSpec(),
      NotificationService.convertTimestampFields,
      null,
      where('organizationId', '==', organizationId),
      orderBy('date', 'desc'),
      limit(NotificationService.MAX_NOTIFICATIONS)
    );
  }

  list(organizationId: string) {
    throw new Error('Method not implemented.');
  }

  create(organizationId: string, record: Notification): Promise<string> {
    return this._create(this.pathSpec(), record);
  }

  update(
    organizationId: string,
    recordId: string,
    record: Partial<Notification>
  ): Promise<void> {
    return this._update(this.pathSpec(), recordId, record);
  }

  set(
    organizationId: string,
    recordId: string,
    record: Notification
  ): Promise<void> {
    throw new Error('Method not implemented.');
  }

  remove(organizationId: string, recordId: string): Promise<void> {
    throw new Error('Method not implemented.');
  }
}
