import { Observable } from 'rxjs';

import { Injectable } from '@angular/core';
import { Firestore, where } from '@angular/fire/firestore';

import { EntityChangeAction } from '@arrivage-services/organization-entity.service';
import { Delivery, WithId } from '@arrivage/model/dist/src/model';

import { DeliveryService } from '../../../distribution/common/services/delivery.service';

@Injectable({
  providedIn: 'root',
})
export class CustomerDeliveryService extends DeliveryService {
  constructor(firestore: Firestore) {
    super(firestore);
  }

  override connect(organizationId: string): Observable<EntityChangeAction<Delivery>[]> {
    return this._connect(
      this.pathSpec(),
      DeliveryService.convertTimestampFields,
      null,
      where('visibleForOrganizations', 'array-contains', organizationId)
    );
  }

  override get(organizationId: string, recordId: string): Observable<Delivery & WithId> {
    return this._get(
      this.pathSpec(),
      recordId,
      DeliveryService.convertTimestampFields
    );
  }

  getRelationshipDeliveries(
    relationshipId: string
  ): Observable<(Delivery & WithId)[]> {
    return this._list(
      this.pathSpec(),
      DeliveryService.convertTimestampFields,
      where('visibleForRelations', 'array-contains', relationshipId)
    );
  }
}
