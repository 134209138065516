import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import {
  FormIsDirtyDirective,
  RegisterFormDirective,
} from './form-is-dirty.directive';

@NgModule({
  declarations: [FormIsDirtyDirective, RegisterFormDirective],
  imports: [CommonModule],
  exports: [FormIsDirtyDirective, RegisterFormDirective],
})
export class FormIsDirtyModule {}
