import { Observable } from 'rxjs';

import { Injectable } from '@angular/core';

import { take, tap } from 'rxjs/operators';

import { Store } from '@ngrx/store';

import { AnalyticsFacade } from '@arrivage-analytics/api/analytics.facade';
import { IdService } from '@arrivage-services/id.service';
import { PictureService } from '@arrivage-services/picture.service';
import { EntitiesFacade } from '@arrivage-store/api/entities.facade';
import {
  CreateFormatRequestData,
  DeleteFormatRequestData,
} from '@arrivage/model/dist/src/cloud-functions-api';
import { Format, InventoryItem, WithId } from '@arrivage/model/dist/src/model';

import * as actions from '../store/formats.actions';
import * as selectors from '../store/formats.selectors';
import { State } from '../store/formats.state';
import { FormatApiService } from './format.api.service';

@Injectable({
  providedIn: 'root',
})
export class FormatFacade extends EntitiesFacade<Format, State> {
  constructor(
    store: Store<State>,
    private formatApiService: FormatApiService,
    private analytics: AnalyticsFacade,
    private pictureService: PictureService,
    private idService: IdService
  ) {
    super(store, actions, selectors);
  }

  override addItem(item: Format): Promise<string> {
    throw new Error(
      'Unsupported, use the method CreateFormatAndInventoryItem in InventoryFacade.'
    );
  }

  override getById(id: string): Observable<Format & WithId> {
    return this.store.select(selectors.getById(id));
  }

  getByProduct(productId: string): Observable<(Format & WithId)[]> {
    return this.store.select(selectors.getFormatsByProductId(productId));
  }

  async createFormat(
    organizationId: string,
    format: Format,
    inventoryItem?: InventoryItem,
    newPictureFile?: Blob,
    includedInPriceListIds?: string[]
  ): Promise<string> {
    if (newPictureFile) {
      return this.pictureService
        .uploadPicture(
          newPictureFile,
          'organizations/' +
            organizationId +
            '/formats/' +
            this.idService.createId()
        )
        .then((url) => {
          const newFormat: Format = { ...format, photoUrl: url };
          const request: CreateFormatRequestData = {
            format: newFormat,
            inventoryItem: inventoryItem,
            organizationId: organizationId,
            includedInPriceListIds: includedInPriceListIds,
          };
          return this.formatApiService
            .createFormat(request)
            .pipe(
              take(1),
              tap(() => this.analytics.logCreateFormat())
            )
            .toPromise();
        });
    } else {
      const request: CreateFormatRequestData = {
        format: format,
        inventoryItem: inventoryItem,
        organizationId: organizationId,
        includedInPriceListIds: includedInPriceListIds,
      };
      return this.formatApiService
        .createFormat(request)
        .pipe(
          take(1),
          tap(() => this.analytics.logCreateFormat())
        )
        .toPromise();
    }
  }

  deleteFormat(organizationId: string, formatId: string): Promise<string> {
    const request: DeleteFormatRequestData = {
      formatId: formatId,
      organizationId: organizationId,
    };
    return this.formatApiService
      .deleteFormat(request)
      .pipe(
        take(1),
        tap(() => this.analytics.logDeleteFormat())
      )
      .toPromise();
  }

  updateItemWithPicture(data: {
    format: Partial<Format> & WithId;
    newPictureFile: File;
  }): Promise<string> {
    return new Promise((resolve, reject) => {
      this.store.dispatch(
        actions.updateWithPicture({
          record: {
            format: data.format,
            newPictureFile: data.newPictureFile,
          },
          confirmation: {
            resolve: resolve,
            reject: reject,
          },
        })
      );
    });
  }
}
