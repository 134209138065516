import { AppUtilsModule } from 'src/app/app-utils.module';
import { MaterialModule } from 'src/app/material.module';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';

import { TranslocoModule } from '@jsverse/transloco';

import { CustomTooltipDirectiveModule } from '@arrivage-directives/custom-tooltip-directive/custom-tooltip-directive.module';

import { HubLabelComponent } from './hub-label/hub-label.component';
import { LabelCertifiedOrganicsOrganizationComponent } from './label-certified-organics-organization/label-certified-organics-organization.component';
import { LabelIsHundredPercentLocalComponent } from './label-is-hundred-percent-local/label-is-hundred-percent-local.component';
import { LabelIsOrganicComponent } from './label-is-organic/label-is-organic.component';
import { LabelNotLocalComponent } from './label-not-local/label-not-local.component';
import { LabelOutOfStockComponent } from './label-out-of-stock/label-out-of-stock.component';
import { LabelUnavailableFormatComponent } from './label-unavailable-format/label-unavailable-format.component';
import { LabelComponent } from './label.component';

@NgModule({
  declarations: [
    LabelComponent,
    LabelCertifiedOrganicsOrganizationComponent,
    LabelIsHundredPercentLocalComponent,
    LabelNotLocalComponent,
    LabelIsOrganicComponent,
    HubLabelComponent,
    LabelUnavailableFormatComponent,
    LabelOutOfStockComponent,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    AppUtilsModule,
    FlexLayoutModule,
    TranslocoModule,
    CustomTooltipDirectiveModule,
  ],
  exports: [
    LabelComponent,
    LabelCertifiedOrganicsOrganizationComponent,
    LabelIsHundredPercentLocalComponent,
    LabelNotLocalComponent,
    LabelIsOrganicComponent,
    HubLabelComponent,
    LabelUnavailableFormatComponent,
    LabelOutOfStockComponent,
  ],
})
export class LabelModule {}
