import { TranslocoModule } from '@jsverse/transloco';
import { AppUtilsModule } from 'src/app/app-utils.module';

import { LayoutModule } from '@angular/cdk/layout';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { FormIsDirtyModule } from '@arrivage-forms/form-is-dirty/form-is-dirty.module';

import { MaterialModule } from '../../material.module';
import { AddressFormComponent } from './address-form.component';

@NgModule({
  declarations: [AddressFormComponent],
  imports: [
    CommonModule,
    AppUtilsModule,
    MaterialModule,
    FlexLayoutModule,
    LayoutModule,
    FormsModule,
    ReactiveFormsModule,
    TranslocoModule,
    FormIsDirtyModule,
  ],
  exports: [AddressFormComponent],
})
export class AddressFormModule {}
