import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { AdminEffects } from './admin.effects';
import {
  adminAuthorizationsReducer,
  adminOffersReducer,
  adminOrganizationsReducer,
  adminPremiumAccountsReducer,
} from './admin.reducers';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('adminOrganizations', adminOrganizationsReducer),
    StoreModule.forFeature('adminAuthorizations', adminAuthorizationsReducer),
    StoreModule.forFeature('adminPremiumAccounts', adminPremiumAccountsReducer),
    StoreModule.forFeature('adminOffers', adminOffersReducer),
    EffectsModule.forFeature([AdminEffects]),
  ],
})
export class AdminStoreModule {}
