import { Observable } from 'rxjs';

import { Injectable } from '@angular/core';
import { Firestore } from '@angular/fire/firestore';

import { OrganizationSettings, WithId } from '@arrivage/model/dist/src/model';
import { CollectionNames } from '@arrivage/model/dist/src/utils';

import { BaseService, PathSpec } from '../base.service';
import {
  EntityChangeAction,
  OrganizationEntityService,
} from '../organization-entity.service';

@Injectable({
  providedIn: 'root',
})
export class OrganizationSettingsService
  extends BaseService<OrganizationSettings>
  implements OrganizationEntityService<OrganizationSettings>
{
  static readonly ORGANIZATIONS_COLLECTION =
    CollectionNames.ORGANIZATION_COLLECTION;
  static readonly SETTINGS_COLLECTION =
    CollectionNames.ORGANIZATION_SETTINGS_COLLECTION;
  static readonly SETTINGS_ID = 'SETTINGS';

  constructor(firestore: Firestore) {
    super(firestore);
  }

  get(organizationId: string): Observable<OrganizationSettings & WithId> {
    return this._get(
      this.pathSpec(organizationId),
      OrganizationSettingsService.SETTINGS_ID
    );
  }

  create(
    organizationId: string,
    settings: OrganizationSettings
  ): Promise<string> {
    return this._create(this.pathSpec(organizationId), settings);
  }

  set(
    organizationId: string,
    recordId: string,
    record: OrganizationSettings
  ): Promise<void> {
    return this._set(this.pathSpec(organizationId), recordId, record);
  }

  update(
    organizationId: string,
    settingsId: string,
    settings: OrganizationSettings
  ): Promise<void> {
    return this._update(this.pathSpec(organizationId), settingsId, settings);
  }

  connect(organizationId: string): Observable<EntityChangeAction<{}>[]> {
    return this._connect(this.pathSpec(organizationId), (d) => d, null);
  }

  list(organizationId: string): Observable<(OrganizationSettings & WithId)[]> {
    return this._list(this.pathSpec(organizationId));
  }

  remove(organizationId: string, settingsId: string): Promise<void> {
    return this._delete(this.pathSpec(organizationId), settingsId);
  }

  private pathSpec(settingsId): PathSpec[] {
    return [
      {
        collection: OrganizationSettingsService.ORGANIZATIONS_COLLECTION,
        id: settingsId,
      },
      { collection: OrganizationSettingsService.SETTINGS_COLLECTION },
    ];
  }
}
